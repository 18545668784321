const slideInbserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("slide");
      slideInbserver.unobserve(entry.target);
    }
  });
});
export default {
mounted(el){
    el.classList.add("before-slide");
    slideInbserver.observe(el);
}
}
