<template>
  <div id="promotionPage">
    <div
      class="container-fluid container-top accounts promotionPage d-flex justify-content-center align-items-center"
    >
      <div class="container-accounts-info">
        <div class="row">
          <div
            class="col-12 px-5 text-center text"
            v-for="(slide, idx) in mobileFilterUAE"
            :key="idx"
          >
            <div class="text-white promotion-slide-text pt-4">
              <div class="d-flex justify-content-center align-items-end">
                <h2 class="mb-0">{{ slide.promTrade }}</h2>
                <span class="fs-1 ms-2 mb-md-1 fw-bold">{{
                  slide.promTo
                }}</span>
              </div>
              <h1 class="mb-0">{{ slide.promWin }}!</h1>
              <img src="/img/slider/dashedLine.svg" :class="`img-fluid  ${checkArb ? 'mt-5' : ''}`" alt="" />
              <p
                class="text-white mb-0 fs-4 mt-2 px-sm-auto px-0"
                v-html="slide.subTitle"
              ></p>
              <a
                :href="registerURL" 
                class="btn btn-blue my-5"
                >{{ $t("joinNow") }}</a
              >
              <p
                class="text-white fs-6 mb-0 text-center px-0 mb-md-0 mb-5" 
                v-html="slide.promDesc"
              ></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title text-trio fw-bold">
          {{ $t("prizeCategories") }}
        </h2>
        <hr class="blue" />
      </div>
      <div
        v-for="(item, idx) in content"
        :key="idx"
        class="row d-flex promotion-row mt-sm-0 mt-3"
      >
        <div
          v-if="item.imgR"
          :class="`col-6 px-md-auto px-0 ${checkArb ? '' : 'text-end'}`"
        >
          <img
            :src="`/img/promotionPage/${item.imgR}.png`"
            class="img-fluid prom-img"
            alt=""
          />
        </div>
        <div
          class="col-6 prom-text d-flex align-items-center"
          v-for="(i, index) in item.name"
          :key="index"
        >
          <div :class="`forex-box ${checkArb ? 'text-right' : ''}`">
            <strong class="fs-6"
              ><span class="fs-4 me-sm-2 me-1">{{ i.brand }}</span
              >{{ i.type }}</strong
            >
            <p class="fs-5 mt-sm-3 mt-2 mb-1">
              {{ $t("tradingLots") }}: <span class="fw-bold">{{ i.lots }}</span>
            </p>
            <p class="fs-5">
              {{ $t("depositPromotion") }}:
              <span class="fw-bold">${{ i.deposit }}</span>
            </p>
          </div>
        </div>
        <div v-if="item.imgL" :class="`col-6 ${checkArb ? 'text-end' : ''}`">
          <img
            :src="`/img/promotionPage/${item.imgL}.png`"
            class="img-fluid prom-img"
            alt=""
          />
        </div>
        <div class="col-12 text-center">
          <img
            :src="`/img/promotionPage/${item.line}.svg`"
            class="dashed-line img-fluid"
            alt=""
          />
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12 text-center">
          <a
            target="_blank"
            href="/legal-documents/promotion/TermsandConditions-TradetoWinPromotion.pdf"
            class="btn btn-blue prom"
            >{{$t('termsConditions')}}</a
          >
        </div>
      </div>
    </div>
    <div class="container-fluid trio-box py-3">
      <div class="row d-flex justify-content-center pt-4">
        <h2 class="col-12 text-center title text-white fw-bold">
          {{ $t("howToJoin") }}
        </h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="w-auto">
          <p
            :class="`fs-3 text-white ${checkArb? 'text-end':''}`"
            v-for="(step, idx) in $tm('howToJoinSteps')"
            :key="idx"
          >
            <span class="text-primary me-2">{{ step.num }}. </span
            >{{ step.name }}
          </p>
        </div>
        <div class="text-center pb-4">
          <a :target="_blank" :href="registerURL" class="btn btn-blue prom">{{
            $t("joinNow")
          }}</a>
        </div>
      </div>
    </div>
    <Platforms v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "Promotions Page",
  data: () => ({
    content: [
      {
        name: [
          {
            brand: "Apple Airpods",
            type: "3rd Generation",
            lots: "80",
            deposit: "5,000",
          },
        ],
        imgR: "airpods",
        line: "line1",
      },
      {
        name: [
          {
            brand: "Apple Watch",
            type: "Series 8",
            lots: "130",
            deposit: "10,000",
          },
        ],
        imgL: "watch",
        line: "line2",
      },
      {
        name: [
          {
            brand: "Apple iPad Air",
            lots: "160",
            deposit: "15,000",
          },
        ],
        imgR: "ipad",
        line: "line3",
      },
      {
        name: [
          {
            brand: "Apple iPhone 14",
            lots: "180",
            deposit: "20,000",
          },
        ],
        imgL: "iphone14",
        line: "line4",
      },
      {
        name: [
          {
            brand: "Apple iPhone 14 Pro",
            lots: "220",
            deposit: "25,000",
          },
        ],
        imgR: "iphone14pro",
        line: "line5",
      },
      {
        name: [
          {
            brand: "Apple MacBook Air",
            type: "M2 Chip",
            lots: "80",
            deposit: "50,000",
          },
        ],
        imgL: "macair",
      },
    ],
  }),
  computed: {
    mobileFilterUAE() {
      return this.$tm("slides").filter((slide) => {
        return slide.license === "uaeLang";
      });
    },
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
