<template>
  <div>
    <MarketsTop
      :market="{ video: 'metals', title: $t('metals'), desc: $t('metalsDesc') }"
    />
    <div class="container-fluid">
      <div class="row d-flex justify-content-center mt-5">
        <div class="col-xl-9 col-12">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-8 col-11 px-sm-3 px-0">
              <Quotes
                v-slidein
                :symbols="['XAUUSD', 'XPDUSD', 'XPTUSD', 'XAGUSD']"
                :product="{ popular: $t('metalsPopular') }"
              />
            </div>
            <div class="col-xl-4 col-11">
              <QuoteDetails v-slideinright />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, idx) in $tm('metalsInfo')" :key="idx" class="container">
      <div class="row d-flex justify-content-center my-5">
        <h2 class="col-12 title text-center">{{ item.moreInfo }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          v-for="(desc, idx) in item.points"
          :key="idx"
          v-scrollanimation
          class="col-md-6 col-sm-10 col-12 d-flex mb-4"
        >
          <div class="forex-box">
            <h3 class="text-center">{{ desc.title }}</h3>
            <p class="text-center" v-html="desc.text"></p>
          </div>
        </div>
        <div class="col-12 text-center my-5">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{ $t("tradeNow") }}</a>
        </div>
      </div>
    </div>
    <Accounts />
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation stepStyle="dark" />
  </div>
</template>
<script>
import { getters } from "../store";
import MarketsTop from "../components/MarketsTop.vue";
import Quotes from "../components/Quotes.vue";
import QuoteDetails from "../components/QuoteDetails.vue";
export default {
  name: "Metals",
  components: {
    MarketsTop,
    Quotes,
    QuoteDetails,
  },
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
};
</script>
