import App from "./App.vue";
import { createApp } from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./assets/scss/main.scss";

import i18n from "./translations/i18n";
import VueScrollTo from "vue-scrollto";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import VueNumber from "vue-number-animation";
// directives
import ScrollAnimation from "./directives/scrollanimation.js";
import SlideIn from "./directives/slidein.js";
import SlideInRight from "./directives/slideinright";

import Acccounts from "./components/Accounts.vue";
import Platforms from "./components/Platforms.vue";
import Steps from "./components/Steps.vue";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";

const app = createApp(App);
app.component("Accounts", Acccounts);
app.component("Platforms", Platforms);
app.component("Steps", Steps);
app.use(router);
app.use(createMetaManager());
app.use(metaPlugin);
app.use(i18n);
app.use(VueApexCharts);
app.use(VueNumber);
app.use(VueScrollTo, {
  container: "body",
  duration: 200,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});
app.directive("slideinright", SlideInRight);
app.directive("slidein", SlideIn);
app.directive("scrollanimation", ScrollAnimation);
app.config.globalProperties.$apiURL = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000";
  } else {
    return "https://api.triomarkets.com";
  }
};
app.mount("#app");
