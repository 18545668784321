<template>
  <div>
    <div class="container-fluid container-top accounts">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("accountTypes") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div v-for="(item, idx) in $tm('accountsOverviewInfo')" :key="idx" class="row d-flex justify-content-center">
          <p class="col-12 text-white text-center" v-html="item.text"></p>
          <strong class="col-12 text-white text-center" v-html="item.textTwo"></strong>
          <div class="col-12 d-flex justify-content-center">
            <img src="/img/accounts/$0.png" alt="$0 image" class="img-fluid mt-md-5 mb-md-4 mt-3" />
          </div>
          <h4 class="col-12 fs-3 text-white text-center" v-html="item.zeroCom"></h4>
          <div class="col-6 d-flex justify-content-end accountTypes-btn">
            <a class="btn border-gold text-white fs-5" v-scroll-to="{
              el: '#accountTypes',
              offset: -210,
              onDone: onBasic,
            }">{{ item.basic }}</a>
          </div>
          <div :class="`col-6 accountTypes-btn ${checkArb ? 'text-end' : ''}`">
            <a class="btn border-gold text-white fs-5" v-scroll-to="{
              el: '#accountTypes',
              offset: -210,
              onDone: onStandart,
            }">{{ item.standard }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row d-flex justify-content-center my-5">
        <h2 class="col-12 title text-center">{{ $t("accountTypes") }}</h2>
        <hr class="blue" />
      </div>
    </div>
    <div class="container-fluid my-5 d-none d-lg-block" id="accountTypes">
      <div class="container scroll">
        <div class="row d-flex justify-content-center">
          <div class="col-table d-flex flex-column justify-content-end px-0 acc-head py-4">
            <p class="gray-box">{{ $t("speed") }}:</p>
            <p>{{ $t("minDeposit") }}:</p>
            <p class="gray-box">{{ $t("commission") }}:</p>
            <p>{{ $t("leverage") }}:</p>
            <p class="gray-box">{{ $t("tradeVol") }}:</p>
            <p>{{ $t("execution") }}:</p>
            <p class="gray-box">{{ $t("spread") }}:</p>
            <p>{{ $t("currency") }}:</p>
            <p class="gray-box">{{ $t("instruments") }}:</p>
            <p v-if="license === 'global'">{{ $t("marginCall") }}:</p>
            <p v-if="license === 'eu'">{{ $t("platforms") }}:</p>
            <p></p>
          </div>
          <div v-for="(point, idx) in $tm('commissions')" :key="idx" :class="`col-table text-center ps-0 pe-2`">
            <div :class="`commissions ${point.class} pb-4`">
              <p :class="`fw-bold fs-4 pt-4 pb-3 acc-title text-white ${point.class}`">
                {{ point.name }}
              </p>
              <p v-if="selectedLang !== 'vnm'" class="gray-box">
                0.1 {{ $t("second") }}
              </p>
              <p v-if="selectedLang == 'vnm'" class="gray-box">
                {{ point.time }} {{ point.seconds }}
              </p>
              <p>${{ point.minDeposit }}</p>
              <p class="gray-box">{{ point.commission }}</p>
              <p>
                {{ point.leverage }}
              </p>
              <p class="gray-box">0.01 {{ $t("lot") }}</p>
              <p>{{ point.execution }}</p>
              <p class="gray-box">{{ point.spread }}</p>
              <p v-if="license === 'eu'">{{ point.currencyEU }}</p>
              <p v-else>{{ point.currency }}</p>
              <!-- <p v-if="selectedLang !== 'cn' && selectedLang !== 'vnm'">
                {{ license == "global" ? "USD-EUR" : "USD-EUR-GBP-CHF" }}
              </p>
              <p v-if="selectedLang == 'cn' || selectedLang == 'vnm'">USD</p> -->
              <p class="gray-box">
                {{ $t("forex") }},{{ $t("indices") }},{{
                  $t("metals")
                }},<br />{{ $t("commodities") }},{{ $t("cryptos") }},
              </p>
              <p v-if="license === 'global'">{{ point.marginCall }}</p>
              <p v-if="license === 'eu'">
                MT4 Terminal / MT4 Mobile / WebTrader
              </p>
              <div class="acc d-flex align-items-center justify-content-center">
                <a :href="registerURL" target="_blank" class="btn trio text-white">{{ $t("openAccount") }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container d-lg-none" id="accountTypesMobile">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-12 col-lg-9 col-md-10 col-sm-7 col-12">
          <div class="row d-flex justify-content-center">
            <div v-for="(account, idx) in $tm('commissions')" :key="idx"
              class="col-xl-3 col-md-6 col-12 d-flex justify-content-center mb-xl-0 mb-4">
              <div :class="`account-card ${account.class} col-12`">
                <div :class="`text-center row py-3 account-header ${account.class}`">
                  <span class="fs-4 fw-bold text-capitalize text-white">{{
                    account.name
                  }}</span>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("speed") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    <span v-if="selectedLang !== 'vnm'">0.1 {{ $t("second") }}</span>
                    <span v-if="selectedLang == 'vnm'">
                      {{ account.time }} {{ account.seconds }}
                    </span>
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("minDeposit") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    ${{ account.minDeposit }}
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("commission") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    {{ account.commission }}
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("leverage") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    {{ account.leverage }}
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("tradeVol") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    0.01 {{ $t("lot") }}
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("execution") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    {{ account.execution }}
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("spread") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    {{ account.spread }}
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("currency") }}
                  </div>
                  <div v-if="selectedLang !== 'cn' && selectedLang !== 'vnm'" :class="`col-6 ${textEnd} fw-bold`">
                    {{ license == "global" ? "USD-EUR" : "USD-EUR-GBP-CHF" }}
                  </div>
                  <div v-if="selectedLang == 'cn' || selectedLang == 'vnm'" :class="`col-6 ${textEnd} fw-bold`">
                    USD
                  </div>
                </div>
                <div class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("instruments") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    {{ $t("forex") }},{{ $t("indices") }},{{
                      $t("metals")
                    }},<br />{{ $t("commodities") }},{{ $t("cryptos") }}
                  </div>
                </div>
                <div v-if="license == 'global'" class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("marginCall") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    {{ account.marginCall }}
                  </div>
                </div>
                <div v-if="license == 'eu'" class="row account-table">
                  <div :class="`col-6 ${textEnd}`">
                    {{ $t("platforms") }}
                  </div>
                  <div :class="`col-6 ${textEnd} fw-bold`">
                    MT4 Terminal / MT4 Mobile / WebTrader
                  </div>
                </div>
                <div class="text-center mb-4 mt-2">
                  <a :href="registerURL" target="_blank" class="btn trio text-white">{{ $t("openAccount") }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "AccountsOverview",
  methods: {
    onBasic() {
      const el = document.getElementById("basic");
      el.style.cssText = " box-shadow: 0 0 20px #00162c; border-radius:25px;";
      setTimeout(() => {
        el.style.cssText = "border: 0;";
      }, 3000);
    },
    onStandart() {
      const el = document.getElementById("standart");
      el.style.cssText = " box-shadow: 0 0 20px #023a73; border-radius:25px;";
      setTimeout(() => {
        el.style.cssText = "border: 0;";
      }, 3000);
    },
  },
  computed: {
    license() {
      return getters.license();
    },
    registerURL() {
      return getters.registerURL();
    },
    selectedLang() {
      return getters.selectedLang();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped>
.accountTypes-btn {
  @media screen and (max-width: 992px) {
    display: none !important;
  }
}
</style>
