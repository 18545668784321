<template>
  <div v-for="(item, idx) in $tm('fixApiInfo')" :key="idx">
    <div class="container-fluid container-top accounts half">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5 mb-2">
          <h2 class="col-12 text-center title text-white">
            {{ $t("fixApi") }}
          </h2>
          <hr class="gold" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-8 col-md-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
          <div class="col-12 text-center my-4">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{
              $t("openLiveAccount")
            }}</a>
          </div>
          <div class="col-12 text-center d-md-block d-none mb-5">
            <img
              src="/img/platforms-menu/server.png"
              alt="imac"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">
          {{ $t("advantages") }}
        </h2>
        <hr class="blue mbhr" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          class="col-lg-8 col-md-10 col-12 text-center"
          v-html="item.textTwo"
        ></div>
      </div>
      <div class="row d-flex justify-content-center" v-scrollanimation>
        <div class="col-lg-10 offset-lg-1">
          <div class="row d-flex justify-content-center my-lg-5 my-4">
            <div
              v-for="(point, idx) in item.points"
              :key="idx"
              class="col-md-6 col-sm-10 col-11 mb-lg-2 mb-4 points"
            >
              <div class="feature-box d-flex points align-items-center">
                <img
                  src="/img/check.svg"
                  :class="`img-fluid check mb-lg-0 mb-2 mt-lg-0 mt-3 ${
                    checkArb ? ' ms-lg-3' : ' me-lg-3'
                  }`"
                  alt="check"
                />
                <p class="mb-lg-0 fs-5 lh-sm" v-html="point"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center mb-5 mt-3">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{
            $t("openLiveAccount")
          }}</a>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps stepStyle="dark" v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "FixAPI",
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped></style>
