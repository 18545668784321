let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : "global";
export default {
  en: {
    moreInfo: "More Info",
    meetUsInfo: [
      {
        team: "Our Team",
        history: "Corporate History",
        timeline: [
          [
            { date: "Sep 2014", text: "Date of Incorporation" },
            { date: "Jan 2016", text: "Financial Advice License" },
            { date: "Jan 2017", text: "Portfolio Management License" },
            { date: "Sep 2019", text: "5 Year Anniversary" },
          ],
          [
            { date: "Feb 2015", text: "RTO License" },
            { date: "Feb 2016", text: "1st Forex Expo Booth in London (UK)" },
            { date: "Jun 2018", text: "Milestone of 15 personnel" },
            { date: "Feb 2020", text: "Dealing on own Account License" },
          ],
        ],
        aboutUs: "About Us",
        whoWeAre: [
          {
            q: "Who We Are ?",
            a: "At TrioMarkets™, we believe there is a more authentic way to trade the financial markets. We provide Trustworthy, Transparent Trading to grant the best possible experience for our clients. Our company was established by three financial professionals with over 20 years' experience in both the European and Swiss Financial Market. This Trio are highly skilled and efficacious entrepreneurs with a successful background history in online Forex. The Trio has a principle that our customers come first, second, and third on our priority list.",
          },
          {
            q: "What We Do ?",
            a: "Here at TrioMarkets™ it is vital to provide you with the absolute best in technology, our major priority is to help you stay connected from anywhere at any time and on any device.We believe it is imperative to value and support our clients in addition to providing the best trading environment to help everyone feel confident and satisfied. Our mission is to provide accurate, accessible, and obtainable information about Forex. TrioMarkets™ platform  has been uniquely designed to suit every level of trading experience whether you are new to trading or have a vast trading background.",
          },
          {
            q: "Why Choose TrioMarkets™ ?",
            a: "Customer care is at the core of what we do here at TrioMarkets™ and your needs are our top priority. If you happen to experience any technical or account difficulties, you can easily get in touch with our knowledgeable multilingual team who will be happy to assist you. Our philosophy is to provide you with Trustworthy, Transparent Trading and our team is in place to always assist you. The true philosophy in business is that you must offer customers value in order to retain customer loyalty and achieve mutual success.",
          },
        ],
        ourValues: "Our Values",
        threeTs: [
          {
            name: "Trustworthy",
            desc: "It’s safe to say that our platform is committed to ensuring a good trading environment, faithful to its clients by presenting highly qualified, secure software, and devoted to presenting the best services and products.",
            img: "trust",
          },
          {
            name: "Transparent",
            desc: "TrioMarkets™ aims to give a transparent experience to the clients and guarantees to grant a secure platform for safe trading. In terms of customer support, you’ll get the most help from an experienced and supportive team in addition to getting all your questions answered through a secure and transparent website.",
            img: "trans",
          },
          {
            name: "Trading",
            desc: "Trading runs in our blood since 2014, and for that, we ensure to give a head-to-toe unique trading journey for all kinds of customers and guarantees their satisfaction and loyalty.",
            img: "trade",
          },
        ],
        businessConcept: "Business Concept",
        textFour:
          "Our Company is owned and operated by Traders for Traders. <br> Our Shareholders come from investment banking where they honed their skills and expertise in top financial hubs of world such as Geneva and London.<br>We know what you need and what you want from an online broker.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Deep Liquidity",
        text: "We have several liquidity providers whom constantly are competing between one another to provide the best pricing. We don't tolerate any drastic deviation from pricing from our execution venues and immediately change them if needed.",
      },
      {
        img: "2",
        title: "Security and Stability",
        text: `Goal of the company is to establish a relationship build on mutual trust, We have been in the market since 2015 and went through rigorous regulatory changes and economical upheaval  and we are here to say " WE ARE STAYING ".`,
      },
      {
        img: "3",
        title: "Execution Speed",
        text: "We are constantly on our toes to keep our execution speed to a minimum and are constantly adjusting our infrastructure to make sure it does not change for our traders.",
      },
      {
        img: "4",
        title: "Pricing",
        text: "Requotes is a thing of the past, as technology advances so does our product offering to you, so we try to give you only the best, market execution and pricing.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"A segregated account is used to keep the customers' funds separate from the Company Funds. This is done for a few reasons, including creating a clear separation between the two groups of money so neither is used for the wrong purpose, and also to make sure that the money can be easily identified as belonging to customers in case anything happens to the firm."</p>
        <p class="text-white text-center">All clients with TrioMarkets™ will have a segregated account whereby your funds are secure and kept separate from our own.</p>`,
        textTwo: `<p class="fw-bold fs-5">At TrioMarkets™ all funds are held specifically within the Swissquote headquarters in Switzerland.</p><p class="text-justify fs-6">Swissquote Bank is one of the most reputable online trading firms/banks in Europe. Swissquote has over 500 employees and operates in over 7 countries with 10 branches worldwide, generating a revenue of around 150 million (CHF) and currently works with over 250,000 clients worldwide.</p><p class="text-justify fs-6">As a company TrioMarkets™ only works with the best in order to provide our clients with the best service possible. TrioMarkets™ will never use clients' funds in its operation or for any other investment, we ensure protection at all times.</p>`,
        textThree: `<p class="fw-bold fs-5">Investor Compensation Fund:</p><p class="text-justify fs-6">The Investor Compensation Fund (ICF) was established under the Investment Firms (IF) Law 2002 as amended (hereinafter called “the Law”) and the Establishment and Operation of an Investor Compensation Fund for customers of ICFs Regulations of 2004 (hereinafter called “the Regulations”) which were issued under the Law.</p><p class="text-justify fs-6">The objective of the ICF is to secure any claims of covered clients against members of the ICF by paying these clients compensation in the case that the company is unable due to financial circumstances that are not likely to be improved in the near future.</p>`,
        points: [
          "The ICF serves to cover Retail Clients of CIFs. This excludes Professional Clients and Eligible Counterparties.",
          "Covered Clients of an ICF's member company can receive payable compensation regardless of the number of accounts held, currency and place of offering the investment service.",
        ],
        textFour: `Due to this policy, as a client with TrioMarkets™, you can feel confident when trading that all of your trades will be completed under the highest level of security, integrity and transparency.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> is registered as an Investment Dealer (Broker) with registration number 160496. Licensed by the Financial Services Commission (Mauritius) under license number <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> and has the license to perform operations under the trading name TrioMarkets™.</p><p class="text-justify fs-6 mb-0">We are authorized to trade internationally, specializing in CFD online trading on currencies, commodities, indices, stocks and forward contracts. We are committed to the utmost in business standards and consider our dedication to ethics a cornerstone of our philosophy.</p>`,
        eu: `<strong>TrioMarkets™</strong> is a trading name 
        owned and operated by <strong>EDR Financial Ltd</strong> and is registered as a Cyprus Investment Firm (CIF) with the registration number <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0">This <strong>ECN/STP</strong> Forex broker is regulated by the Cyprus Securities and Exchange commission <strong>(CySEC)</strong> under the license number <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, which falls under the umbrella of the <strong>Markets in Financial Instruments Directive II (MIFID II)</strong>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> is a representative office/Branch of Benor Capital Ltd. (Mauritius) located in the United Arab Emirates - Dubai under the DED License number<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Registered Office: The Bayswater Tower , Office 1212, Marasi Drive, Business Bay, Dubai, United Arab Emirates and has the license to perform marketing and commercial activities under the trading name TrioMarkets&trade;. `,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number <a target='_blank' href=https://www.fsca.co.za/Fais/Search_FSP.htm> <strong class='text-primary'>53065</strong></a>), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "Important Links",
        importantLinks: [
          "Investor Compensation Fund",
          "Privacy Policy",
          "Conflicts of Interest Policy",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs",
      },
    ],
    contactInfo: [
      {
        text: `Have a question or require specialist assistance? <br />
        Contact our dedicated customer service team who are available
        24/5 to assist you!`,
        points: [
          {
            img: "phone",
            name: "Phone Support",
            text: license == "global" ? "+44 20 376 936 49" : "+357 22 222737",
            link: "",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "Email Support",
            text: "Email us",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "Chat Support",
            text: "Start Live Chat",
            link: "",
          },
        ],
        pointsTwo: [
          { img: "en", name: "English Desk" },
          { img: "fr", name: "French Desk" },
          { img: "it", name: "Italian Desk" },
          { img: "de", name: "German Desk" },
          { img: "uae", name: "Arabic Desk" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Where are your offices located?",
            a:
              license == "global"
                ? "Our headquarters are based in Mauritius."
                : "Our headquarters are based in Limassol, Cyprus.",
          },
          {
            open: false,
            q: "What type of broker are you?",
            a: "TrioMarkets™ is an ECN/STP Forex broker.",
          },
          {
            open: false,
            q: "What regulation do you hold?",
            a:
              license == "global"
                ? "TrioMarkets ™ is regulated by the Financial Services Commission (FSC) Mauritius, under the license number C118023678."
                : `"TrioMarkets ™ is regulated by the Cyprus Securities and Exchange Commission (CySEC) under the license number 268/15 which falls under the umbrella of the Markets in Financial Instruments Directive II (MIFID II)."`,
          },
        ],
      },
      {
        faqSection: 2,
        title: "Funding and Withdrawals",
        faqContent: [
          {
            open: false,
            q: "How do I deposit money?",
            a:
              license == "global"
                ? "We accept a wide range of deposit methods to fund your trading account. You can use bank to bank wire transfer, credit card, and Netteller deposits."
                : "We accept a wide range of deposit methods to fund your trading account. You can use bank to bank wire transfer, credit card, Skrill, and Netteller deposits.",
          },
          {
            open: false,
            q: "What is the minimum deposit?",
            a: "To open your account at Trio Markets™, you must fund the account with $/€100.",
          },
          {
            open: false,
            q: "Can I fund an account in a different currency than the base currency?",
            a: "The currency in which you open your account with is the currency your deposit will be made in. Therefore, if you have a USD account the deposit (if made with another currency) will automatically be converted to USD.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Our Accounts",
        faqContent: [
          // {
          //   open: false,
          //   q: "How do I withdraw money?",
          //   a: "We pay via the method used to fund your account. All withdrawals will incur a fee of 1.5% of the withdrawal amount.",
          // },
          {
            open: false,
            q: "How do I close my account?",
            a: "To close your account please contact your customer support directly.",
          },
          {
            open: false,
            q: "How long does it take to verify my account?",
            a: "Depending on the documentation submitted, if all is valid and approved your account will be verified immediately by our back office. Once verified and the deposit has been made, your account will be activated for trading.",
          },
          {
            open: false,
            q: "What kind of documents are needed to verify my trading account?",
            a: `We require Proof of Identity: a clear colored copy of one of the following:
              <ul>
                  <li>Passport</li>
                  <li>Identity card</li>
              </ul>
              Proof of Residence:
              <ul>
                  <li>Bank statement or bank reference letter</li>
                  <li>Utility bill</li>
                  <li>Any document confirming the permanent address which is verified via local authority (tax bill, municipality bill)</li>
                  <li>All documents confirming the residential address must have been issued within the last 6 months and confirm the address provided in the application.</li>
              </ul>`,
          },
          {
            open: false,
            q: "How do I apply for a joint account?",
            a: "Both joint account holders shall open a trading account with TrioMarkets and submit the required documents with the joint account application form. Joint account can be opened between 1st degree relatives only (proof required in case the last names are different) or in case the 2 clients are joint bank account holders (proof required).",
          },
          {
            open: false,
            q: "What type of account is suitable for me?",
            a: `Depending on the level of experience you have will determine which account is best suitable for your needs. See our account types to choose. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">Click here.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "General",
        faqContent: [
          {
            open: false,
            q: "Which trading products can I trade?",
            a: `At TrioMarkets™; We offer a wide range of asset classes. From your favorite Forex pairs like the EUR/USD, GBP/USD to commodities and CFDs trading your favorite indices like the Dow Jones, S&P 500 and the FTSE. <br> For all of our asset classes, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">click here.</a> To deposit money into your trading account, please <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login">click here.</a>`,
          },
          {
            open: false,
            q: "How do I trade?",
            a: "Here at TrioMarkets™; You can trade all of our asset classes through our state of the art Meta Trader 4 (MT4) platform. We offer our clients a wealth of trading information form real time quotes to charts, trading analysis and more.",
          },
          {
            open: false,
            q: "What is forex?",
            a: "Forex, also known as Foreign exchange is a global market for the trading of currency pairs. Initially it involves the buying or selling of one currency against another, i.e. EUR/USD (buying the EUR to increase in value against the USD).",
          },
        ],
      },
    ],
  },
  fr: {
    moreInfo: "Plus d'Info",
    meetUsInfo: [
      {
        team: "Notre Équipe",
        history: "Histoire de la Société",
        timeline: [
          [
            { date: "Sept 2014", text: "Date de Création" },
            { date: "Janv 2016", text: "Licence pour Conseils Financiers " },
            { date: "Janv 2017", text: "Licence de Gestion de Portefeuilles" },
            { date: "Sept 2019", text: "5ème Anniversaire" },
          ],
          [
            { date: "Févr 2015", text: "License de Réception et Transfert" },
            {
              date: "Févr 2016",
              text: "1er participation au Forex Expo de Londres",
            },
            {
              date: "Juin 2018",
              text: "Atteinte de l'objectif de 15 salariés",
            },
            { date: "Févr 2020", text: "Licence de Trading sur Compte propre" },
          ],
        ],
        aboutUs: "À propos de nous",
        whoWeAre: [
          {
            q: "Qui sommes-nous ?",
            a: "Chez TrioMarkets™, nous pensons qu'il existe une manière plus authentique de négocier sur les marchés financiers. Nous fournissons un trading fiable et transparent pour offrir la meilleure expérience possible à nos clients. Notre société a été créée par trois professionnels de la finance avec plus de 20 ans d'expérience sur le marché financier européen et suisse. Ce trio est composé d'entrepreneurs hautement qualifiés et efficaces avec une expérience réussie dans le Forex en ligne. Le Trio a pour principe que nos clients viennent en premier, deuxième et troisième sur notre liste de priorités.",
          },
          {
            q: "Que faisons-nous ?",
            a: "Chez TrioMarkets ™, il est essentiel de vous fournir le meilleur de la technologie, notre principale priorité est de vous aider à rester connecté de n'importe où, à tout moment et sur n'importe quel appareil. Nous pensons qu'il est impératif de valoriser et de soutenir nos clients en plus de fournir le meilleur environnement commercial pour aider tout le monde à se sentir en confiance et satisfait. Notre mission est de fournir des informations précises et accessibles sur le Forex. La plateforme TrioMarkets™ a été spécialement conçue pour s'adapter à tous les niveaux d'expérience de trading, que vous soyez nouveau dans le trading ou que vous ayez une vaste expérience en trading.",
          },
          {
            q: "Pourquoi choisir TrioMarkets™ ?",
            a: "Le service client est au cœur de ce que nous faisons ici chez TrioMarkets™ et vos besoins sont notre priorité absolue. Si vous rencontrez des difficultés techniques ou de compte, vous pouvez facilement entrer en contact avec notre équipe multilingue compétente qui se fera un plaisir de vous aider. Notre philosophie est de vous fournir un trading fiable et transparent et notre équipe est en place pour toujours vous aider. La véritable philosophie des affaires est que vous devez offrir de la valeur aux clients afin de les fidéliser et d'atteindre un succès mutuel.",
          },
        ],
        ourValues: "Nos Valeurs",
        threeTs: [
          {
            name: "La Confiance",
            desc: "Il est sûr de dire que notre plateforme s'engage à assurer un bon environnement de trading, fidèle à ses clients en présentant des logiciels hautement qualifiés et sécurisés, et consacrée à présenter les meilleurs services et produits.",
            img: "trust",
          },
          {
            name: "Transparent",
            desc: "TrioMarkets™ vise à offrir une expérience transparente aux clients et garantit d'accorder une plate-forme sécurisée pour un trading en toute sécurité. En termes de support client, vous obtiendrez de l'aide d'une équipe expérimentée et solidaire en plus d'obtenir des réponses à toutes vos questions via un site Web sécurisé et transparent.",
            img: "trans",
          },
          {
            name: "Trading",
            desc: "Le trading coule dans nos veines depuis 2014, et pour cela, nous nous assurons d'offrir une expérience de trading unique de la tête aux pieds à tous les types de clients et garantissons leur satisfaction et leur fidélité.",
            img: "trade",
          },
        ],
        businessConcept: "Modèle Économique",
        textFour:
          "Notre société est détenue et exploitée par des Traders pour des Traders.<br>Nos actionnaires viennent du monde financier où ils ont perfectionné leurs compétences et leur expertise dans les plus grands pôles financiers du monde tels que Genève et Londres.<br>Nous savons ce dont vous avez besoin et ce que vous attendez d'un courtier en ligne.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Profondeur de Marché",
        text: "Nous avons plusieurs fournisseurs de liquidités qui se font constamment concurrence pour offrir la meilleure tarification. Nous ne tolérons aucun écart drastique par rapport aux prix d'exécution et les modifions immédiatement si nécessaire.",
      },
      {
        img: "2",
        title: "Sécurité & Stabilité",
        text: `L'objectif de la société est d'établir une relation fondée sur la confiance mutuelle. Nous sommes présents sur le marché depuis 2015 et avons intégré des changements réglementaires rigoureux et subi des bouleversements économiques. Nous sommes ici pour dire " NOUS RESTONS ".`,
      },
      {
        img: "3",
        title: "Vitesse d'Execution",
        text: "Nous sommes constamment sur nos gardes pour maintenir notre vitesse d'exécution au minimum et ajustons constamment notre infrastructure pour nous assurer la stabilité pour nos traders.",
      },
      {
        img: "4",
        title: "Prix",
        text: "À mesure que la technologie évolue, notre offre de produits évolue également.Nous essayons donc de vous offrir uniquement la meilleure exécution du marché et les meilleurs prix.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"Un compte ségrégué a pour but de garder les fonds des clients strictement séparés des comptes de la société. La société ne peut utiliser les fonds du client dans le cadre de la gestion de son entreprise. Grâce à un compte ségrégué, l'argent est identifié comme appartenant au client. Les fonds peuvent peut être facilement identifiés comme appartenant aux clients en cas de défaillance de l'entreprise."</p>
        <p class="text-white text-center">Tous les clients de TrioMarkets™ ont un compte distinct dans lequel leurs fonds sont sécurisés et conservés séparément de ceux du broker STP.</p>`,
        textTwo: `<p class="fw-bold fs-5">Chez TrioMarkets™, tous les fonds sont détenus spécifiquement au siège de Swissquote en Suisse.</p><p class="text-justify fs-6">Swissquote Bank est l'une des sociétés de trading / banque en ligne les plus réputées d'Europe. Swissquote compte plus de 500 employés et opère dans plus de 7 pays avec 10 succursales dans le monde, générant un chiffre d'affaires d'environ 150 millions (CHF) et travaillant actuellement avec plus de 250 000 clients dans le monde.</p><p class="text-justify fs-6">En tant qu'entreprise, TrioMarkets™ ne travaille qu'avec les meilleurs afin de fournir à nos clients le meilleur service possible. TrioMarkets™ n'utilisera jamais les fonds des clients dans son fonctionnement ou pour tout autre investissement, nous assurons la protection à tout moment.</p>`,
        textThree: `<p class="fw-bold fs-5">Fonds d'indemnisation des Investisseurs:</p><p class="text-justify fs-6">Le fond d'indemnisation des investisseurs (ICF) a été créé en vertu de la Loi de 2002 (ci-après dénommée « la Loi ») sur les entreprises d'investissement (IF), amendée suite à la création et la mise en place d'un Fond d'indemnisation des investisseurs pour les clients des IFs, règlement de 2004, (ci-après dénommé « le Règlements »), qui ont été publiés en vertu de la loi.</p><p class="text-justify fs-6">L'ICF a pour fonction de garantir les créances des clients couverts et leur assure une indemnisation financière en cas de manquement ou faillite d'une société membre de l'ICF.</p>`,
        points: [
          "L'ICF couvre les clients particuliers, à l'exception des clients ayant opté pour un statut de client professionnel, et les contreparties éligibles.",
          "Les clients couverts par une société membre de l'ICF peuvent recevoir une indemnisation quel que soit le pays où le compte de trading a été ouvert.",
        ],
        textFour: `En vertu de ces dispositions, les clients de TrioMarkets™ sont assurés de travailler dans des conditions de confiance et de sécurité.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd </strong> è registrato come Investment Dealer (Broker) con numero di registrazione 160496. Autorizzato dalla Financial Services Commission (Mauritius) con numero di licenza <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> e ha la licenza per eseguire operazioni con il nome commerciale TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Siamo autorizzati a negoziare a livello internazionale, specializzandoci nel trading online di CFD su valute, materie prime, indici, azioni e contratti a termine. Ci impegniamo al massimo per quanto riguarda gli standard aziendali e consideriamo la nostra dedizione all'etica una pietra angolare della nostra filosofia.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™ </strong> è un nome commerciale di proprietà e gestito da <strong> EDR Financial Ltd </strong> ed è registrato come Cyprus Investment Firm (CIF) con il numero di registrazione <strong>(HE336081). </strong></p><p class="text-justify fs-6 mb-0">Questo broker Forex <span class="fw-bold">ECN/STP</span> è regolamentato dalla Cyprus Securities and Exchange Commission <span class="fw-bold">(CySEC)</span> con il numero di licenza <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, che rientra nell'ambito della <span class="fw-bold">Direttiva sui mercati degli strumenti finanziari II (MIFID II)</span>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> est un bureau de représentation/succursale de Benor Capital Ltd. (Maurice) situé aux Émirats Arabes Unis - Dubaï, sous le numéro de licence DED<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Siège social: The Bayswater Tower, Office 1212, Marasi Drive, Business Bay, Dubaï, Émirats Arabes Unis, et détient la licence pour exercer des activités de marketing et commerciales sous le nom commercial TrioMarkets&trade;.`,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "Liens Importants",
        importantLinks: [
          "Fonds d'Indemnisation des Investisseurs",
          "Politique de Confidentialité",
          "Politique sur les Conflits d'Intérêts",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(FRENCH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(FRENCH)",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(FRENCH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(FRENCH)",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(FRENCH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(FRENCH)",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(FRENCH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(FRENCH)",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(FRENCH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(FRENCH)",
      },
    ],
    contactInfo: [
      {
        text: `Pour toute question ou pour une assistance?<br>Contactez votre service client qui vous assistera.`,
        points: [
          {
            img: "phone",
            name: "Téléphone du service Support",
            text:
              license == "global" ? "+33 3 75 77 40 77" : "+33 1 89 00 64 81",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "Email du service Support",
            text: "Envoyez nous un Email",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "LiveChat du service Support",
            text: "Démarrer le LiveChat",
          },
        ],
        pointsTwo: [
          { img: "en", name: "Support en Anglais" },
          { img: "fr", name: "Support en Français" },
          { img: "it", name: "Support en Italien" },
          { img: "de", name: "Support en Allemand" },
          { img: "uae", name: "Support en Arabe" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Où sont situés vos bureaux?",
            a:
              license == "global"
                ? "Notre siège social est basé à Maurice."
                : "Notre siège social est basé à Limassol, à Chypre.",
          },
          {
            open: false,
            q: "Quel type de courtier êtes-vous ?",
            a: "TrioMarkets™ est un courtier Forex ECN/STP.",
          },
          {
            open: false,
            q: "Quelle réglementation tenez-vous ?",
            a:
              license == "global"
                ? "TrioMarkets™ est réglementé par la Commission des services financiers (FSC) Maurice, sous le numéro de licence C118023678."
                : "TrioMarkets™ est réglementé par la Cyprus Securities and Exchange Commission (CySEC) sous le numéro de licence 268/15 qui relève de la directive sur les marchés d'instruments financiers II (MIFID II).",
          },
        ],
      },
      {
        faqSection: 2,
        title: "Financement et retraits",
        faqContent: [
          {
            open: false,
            q: "Comment déposer de l'argent ?",
            a:
              license == "global"
                ? "Nous acceptons un large éventail de méthodes de dépôt pour financer votre trading Compte. Vous pouvez utiliser un virement bancaire, une carte de crédit, et les dépôts Netteller."
                : "Nous acceptons un large éventail de méthodes de dépôt pour financer votre trading Compte. Vous pouvez utiliser un virement bancaire, une carte de crédit, Skrill, et les dépôts Netteller.",
          },
          {
            open: false,
            q: "Quel est le dépôt minimum ?",
            a: "Pour ouvrir votre compte chez TrioMarkets™, vous devez approvisionner le compte avec 100 $/€.",
          },
          {
            open: false,
            q: "Puis-je approvisionner un compte dans une devise différente de la devise de base ?",
            a: "La devise dans laquelle vous ouvrez votre compte est la devise votre dépôt sera effectué en. Par conséquent, si vous avez un compte en USD le dépôt (s'il est effectué avec une autre devise) sera automatiquement converti en USD.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Nos comptes",
        faqContent: [
          // {
          //   open: false,
          //   q: "Comment retirer de l'argent ?",
          //   a: "Nous payons via la méthode utilisée pour approvisionner votre compte. Tous les virements bancaires les retraits entraîneront des frais de 1,5% du montant du retrait. Cliquez ici pour plus d'informations.",
          // },
          {
            open: false,
            q: "Comment fermer mon compte ?",
            a: "Pour fermer votre compte, veuillez contacter directement votre soutien à la clientèle.",
          },
          {
            open: false,
            q: "Combien de temps faut-il pour vérifier votre compte ?",
            a: "Selon la documentation soumise, si tout est valide approuvévotre compte sera vérifié immédiatement par notre back office. Une fois vérifié et le dépôt effectué, votre compte sera activé pour le commerce.",
          },
          {
            open: false,
            q: "Quels types de documents sont nécessaires pour vérifier mon compte de trading ?",
            a: `Nous avons besoin d'une preuve d'identité : une copie de couleur claire de l'un des éléments suivants :
              <ul>
                  <li>Passeport</li>
                  <li>Carte d'identité</li>
              </ul>
              Justificatif de domicile:
              <ul>
                  <li>Relevé bancaire ou lettre de référence bancaire</li>
                  <li>Facture de services publics</li>
                  <li>Tout document confirmant l'adresse permanente qui est vérifié via l'autorité locale (compte de taxes, facture de la commune)</li>
                  <li> Tous les documents confirmant l'adresse résidentielle doivent avoir été délivré au cours des 6 derniers mois et confirmer l'adresse fournie Dans l'application.</li>
              </ul>`,
          },
          {
            open: false,
            q: "Comment demander un compte joint ?",
            a: "Les deux co-titulaires ouvrent un compte de trading auprès de TrioMarkets et soumettre les documents requis avec le formulaire de demande de compte. Un compte joint peut être ouvert entre Parents au 1er degré uniquement (preuve requise si les noms de famille sont différent) ou dans le cas où les 2 clients sont cotitulaires de comptes bancaires (preuve requise).",
          },
          {
            open: false,
            q: "Quel type de compte me convient ?",
            a: `Selon votre niveau d'expérience, vous déterminerez quel compte est le mieux adapté à vos besoins. Voir nos types de comptes pour choisir. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview"Cliquez ici.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "Général",
        faqContent: [
          {
            open: false,
            q: "Quels produits de trading puis-je trader ?",
            a: `Chez TrioMarkets™ ; Nous proposons une large gamme de classes d'actifs. De votre paires Forex préférées comme l'EUR/USD, GBP/USD aux matières premières et CFD négociant vos indices préférés comme le Dow Jones, le S&P 500 et le FTSE. <br> Pour toutes nos classes d'actifs, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview"cliquer ici.</a> Pour déposer de l'argent dans votre compte de trading, veuillez <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login">cliquer ici.</a>`,
          },
          {
            open: false,
            q: "Comment puis-je trader ?",
            a: "Ici chez TrioMarkets™ ; Vous pouvez trader toutes nos classes d'actifs via  notre plateforme de pointe Meta Trader 4 (MT4). Nous offrons notre clients une mine d'informations commerciales sous forme de cotations en temps réel pour graphiques, analyse de trading et plus encore.",
          },
          {
            open: false,
            q: "Qu'est-ce que le forex ?",
            a: "Le Forex, également connu sous le nom de Forex est un marché mondial pour le trading de paires de devises. Initialement, il s'agit de l'achat ou de la vente de une devise contre une autre, c'est-à-dire EUR/USD (acheter l'EUR à augmentation de la valeur par rapport à l'USD).",
          },
        ],
      },
    ],
  },
  it: {
    moreInfo: "`Piu Info",
    meetUsInfo: [
      {
        team: "Il nostro Team",
        history: "Storia della Societa'",
        timeline: [
          [
            { date: "Sett 2014", text: "Data di Creazione" },
            { date: "Genn 2016", text: "Licenza per Consigli Finanziari" },
            { date: "Genn 2017", text: "Licenza di Gestione del Portafoglio" },
            { date: "Sett 2019", text: "5° Anniversario" },
          ],
          [
            { date: "Febbr 2015", text: "Licenza di Ricezione e Trasmissione" },
            {
              date: "Febbr 2016",
              text: "Prima parteci- <br>pazione al Forex Expo de Londra",
            },
            {
              date: "Giugno 2018",
              text: "Raggiungimento dell'obiettivo di 15 dipendenti",
            },
            {
              date: "Febbr 2020",
              text: "Licenza di Trading per Conto proprio ",
            },
          ],
        ],
        aboutUs: "Su di Noi",
        whoWeAre: [
          {
            q: "Chi siamo?",
            a: "Noi di TrioMarkets™ crediamo che esista un modo più autentico di fare trading sui mercati finanziari. Forniamo trading affidabile e trasparente per garantire la migliore esperienza possibile ai nostri clienti. La nostra azienda è stata fondata da tre professionisti finanziari con oltre 20 anni di esperienza nel mercato finanziario europeo e svizzero. Questo trio è costituito da imprenditori altamente qualificati ed efficaci con una storia di successo nel Forex online. Il trio ha un principio secondo cui i nostri clienti vengono prima, seconda e terza nella nostra lista di priorità.",
          },
          {
            q: "Cosa facciamo?",
            a: "Qui a TrioMarkets™ è fondamentale fornirti il meglio in assoluto della tecnologia, la nostra priorità principale è aiutarti a rimanere connesso da qualsiasi luogo, in qualsiasi momento e su qualsiasi dispositivo. Riteniamo che sia imperativo valorizzare e supportare i nostri clienti in aggiunta fornire il miglior ambiente di trading per aiutare tutti a sentirsi sicuri e soddisfatti.La nostra missione è fornire informazioni accurate, accessibili e ottenibili sul Forex.La piattaforma TrioMarkets™  è stata progettata in modo univoco per adattarsi a qualsiasi livello di esperienza di trading, sia che tu sia un principiante del trading o che tu abbia una vasta esperienza nel trading.",
          },
          {
            q: "Perche scegliere Triomarkets ?",
            a: "L'assistenza clienti è al centro di ciò che facciamo qui a TrioMarkets™ e le tue esigenze sono la nostra massima priorità. Se ti capita di incontrare difficoltà tecniche o relative all'account, puoi facilmente metterti in contatto con il nostro esperto team multilingue che sarà lieto di aiutarti. La nostra filosofia è quella di fornirti un trading affidabile e trasparente e il nostro team è pronto ad assisterti sempre. La vera filosofia negli affari è che devi offrire valore ai clienti per mantenere la fedeltà dei clienti e raggiungere il successo reciproco.",
          },
        ],
        ourValues: "I Nostri Valori",
        threeTs: [
          {
            name: "Trasparenza",
            desc: "È sicuro dire che la nostra piattaforma si impegna a garantire un buon ambiente di trading, fedele ai propri clienti presentando software altamente qualificato e sicuro e dedicato a presentare i migliori servizi e prodotti.",
            img: "trust",
          },
          {
            name: "Trasparente",
            desc: "TrioMarkets™ mira a offrire un'esperienza trasparente ai clienti e garantisce di fornire una piattaforma sicura per un trading sicuro. In termini di assistenza clienti, riceverai il massimo aiuto da un team esperto e di supporto oltre a ricevere risposta a tutte le tue domande attraverso un sito Web sicuro e trasparente.",
            img: "trans",
          },
          {
            name: "Trading",
            desc: "Il trading scorre nel nostro sangue dal 2014 e, per questo, ci assicuriamo di offrire un viaggio di trading unico dalla testa ai piedi per tutti i tipi di clienti e garantiamo la loro soddisfazione e lealtà.",
            img: "trade",
          },
        ],
        businessConcept: "Modello Economico",
        textFour:
          "La nostra azienda è di proprietà e gestita da Traders per Traders.<br>I nostri azionisti provengono dal mondo finanziario dove hanno perfezionato le loro capacità e competenze nei più grandi centri finanziari del mondo come Ginevra e Londra.<br>Sappiamo cosa ti serve e cosa vuoi da un broker online.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Profonditàc di Mercato",
        text: "Abbiamo diversi fornitori di liquidità che competono costantemente tra loro per fornire il miglior prezzo. Non tolleriamo alcuna deviazione drastica dai prezzi dalle nostre sedi di esecuzione e li cambiamo immediatamente se necessario.",
      },
      {
        img: "2",
        title: "Sicurezza e Stabilità",
        text: `L'obiettivo dell'azienda è stabilire una relazione basata sulla fiducia reciproca, siamo presenti sul mercato dal 2015 e abbiamo attraversato rigorosi cambiamenti normativi e aggiornamenti economici e siamo qui per dire "RESTIAMO".`,
      },
      {
        img: "3",
        title: "Velocità di Esecuzione",
        text: "Siamo costantemente all'erta per mantenere la nostra velocità di esecuzione al minimo e adeguiamo costantemente la nostra infrastruttura per assicurarci che non cambi per i nostri trader.",
      },
      {
        img: "4",
        title: "Prezzi",
        text: "Man mano che la tecnologia si evolve, così fa la nostra offerta di prodotti, quindi cerchiamo di darti solo la migliore esecuzione sul mercato e i migliori prezzi.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"Un conto separato viene utilizzato per mantenere separati i fondi dei clienti dai Fondi della Società. Ciò viene fatto per alcuni motivi, tra cui la creazione di una netta separazione tra i due gruppi di denaro in modo che nessuno dei due venga utilizzato per lo scopo sbagliato, e affinche  il denaro possa essere facilmente identificato come appartenente ai clienti nel caso in cui accada qualcosa all'azienda."</p>
        <p class="text-white text-center">Tutti i clienti con TrioMarkets™ avranno un conto separato in cui i tuoi fondi sono al sicuro e tenuti separati dai nostri.</p>`,
        textTwo: `<p class="fw-bold fs-5">In TrioMarkets™, tutti i fondi sono detenuti specificamente presso la sede di Swissquote in Svizzera.</p><p class="text-justify fs-6">Swissquote Bank è una delle società di banking / trading online più affidabili in Europa. Swissquote ha più di 500 dipendenti e opera in più di 7 paesi con 10 filiali in tutto il mondo, generando un fatturato di circa 150 milioni (CHF) e attualmente lavora con oltre 250.000 clienti in tutto il mondo.</p><p class="text-justify fs-6">Come azienda, TrioMarkets™ lavora solo con il meglio per fornire ai nostri clienti il   miglior servizio possibile. TrioMarkets™ non utilizzerà mai i fondi dei clienti nelle sue operazioni o per qualsiasi altro investimento, forniamo protezione in ogni momento.</p>`,
        textThree: `<p class="fw-bold fs-5">Fondo di Compensazione degli Investitori:</p><p class="text-justify fs-6">Il Fondo di Indennizzo degli Investitori (ICF) è stato istituito ai sensi della Legge sulle imprese di investimento (IF) del 2002 e successive modifiche (di seguito denominata "la Legge") e l'istituzione e il funzionamento di un Fondo di indennizzo degli investitori per i clienti dei Regolamenti ICF del 2004 (di seguito denominato "il Regolamento”) che sono stati emanati ai sensi della Legge.</p><p class="text-justify fs-6">La funzione dell'ICF è quella di garantire i crediti dei clienti coperti e fornire loro un risarcimento finanziario in caso di inadempienza o fallimento di una società membro di ICF.</p>`,
        points: [
          "L'ICF copre i clienti individuali, ad eccezione dei clienti che hanno optato per lo status di cliente professionale e le controparti qualificate.",
          "I clienti coperti da una società membro di ICF possono ricevere un risarcimento indipendentemente dal paese in cui è stato aperto il conto di trading.",
        ],
        textFour: `In virtù di queste disposizioni, ai clienti di TrioMarkets™ è garantito di lavorare in condizioni di fiducia e sicurezza.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> è registrato come Investment Dealer (Broker) con numero di registrazione 160496. Autorizzato dalla Financial Services Commission (Mauritius) con numero di licenza <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> e ha la licenza per eseguire operazioni con il nome commerciale TrioMarkets™.</p><p class="text-justify  mb-0">Siamo autorizzati a negoziare a livello internazionale, specializzandoci nel trading online di CFD su valute, materie prime, indici, azioni e contratti a termine. Ci impegniamo al massimo per quanto riguarda gli standard aziendali e consideriamo la nostra dedizione all'etica una pietra angolare della nostra filosofia.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> è un nome commerciale di proprietà e gestito da EDR Financial Ltd ed è registrato come Cyprus Investment Firm (CIF) con il numero di registrazione <strong> (HE336081). </strong></p><p class="text-justify fs-6 mb-0">Questo broker Forex <span class="fw-bold">ECN/STP</span> è regolamentato dalla Cyprus Securities and Exchange Commission <span class="fw-bold">(CySEC)</span> con il numero di licenza <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, che rientra nell'ambito della <span class="fw-bold">Direttiva sui mercati degli strumenti finanziari II (MIFID II)(MIFID II).</span></p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> è un ufficio di rappresentanza/filiale di Benor Capital Ltd. (Mauritius) situato negli Emirati Arabi Uniti - Dubai con il numero di licenza DED<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Sede legale: The Bayswater Tower, Office 1212, Marasi Drive, Business Bay, Dubai, Emirati Arabi Uniti e ha la licenza per svolgere attività di marketing e commerciali con il nome commerciale TrioMarkets&trade;.`,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "Collegamenti Importanti",
        importantLinks: [
          "Fondo di Compensazione degli Investitori",
          "Politica sulla Riservatezza",
          "Politica sui Conflitti di Interesse",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(ITALIAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(ITALIAN)",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(ITALIAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(ITALIAN)",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(ITALIAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(ITALIAN)",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(ITALIAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(ITALIAN)",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(ITALIAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(ITALIAN)",
      },
    ],
    contactInfo: [
      {
        text: `Hai una domanda o hai bisogno di assistenza speciale?<br />Contatta il nostro team di assistenza clienti dedicato disponibile 24/5 per aiutarti!`,
        points: [
          {
            img: "phone",
            name: "Servizio Supporto Telefonico",
            text:
              license == "global" ? "+39 03 321 320 002" : "+39 03 321 320 001",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "E-mail del Servizio di Supporto",
            text: "Mandaci un Email",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "Servizio Supporto LiveChat",
            text: "Avvia LiveChat",
          },
        ],
        pointsTwo: [
          { img: "en", name: "Supporto in Inglese" },
          { img: "fr", name: "Supporto in Francese" },
          { img: "it", name: "Supporto in Italiano" },
          { img: "de", name: "Supporto in Tedesco" },
          { img: "uae", name: "Supporto in Araba" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Dove si trovano i tuoi uffici?",
            a:
              license == "global"
                ? "La nostra sede si trova a Mauritius."
                : "La nostra sede si trova a Limassol, Cipro.",
          },
          {
            open: false,
            q: "Che tipo di Broker sei?",
            a: "TrioMarkets™ è un broker Forex ECN/STP.",
          },
          {
            open: false,
            q: "Che regolamento hai?",
            a:
              license == "global"
                ? "TrioMarkets ™ è regolato dalla Financial Services Commission (FSC) Mauritius, con il numero di licenza C118023678."
                : "TrioMarkets ™ è regolamentato dalla Cyprus Securities and Exchange Commission (CySEC) con il numero di licenza 268/15 che rientra nell'ambito della Markets in Financial Instruments Directive II (MIFID II).",
          },
        ],
      },
      {
        faqSection: 2,
        title: "Depositi e Prelievi",
        faqContent: [
          {
            open: false,
            q: "Come deposito denaro?",
            a:
              license == "global"
                ? "Accettiamo un'ampia gamma di metodi di deposito per finanziare il tuo trading account. Puoi utilizzare bonifico bancario, carta di credito, e depositi Netteller."
                : "Accettiamo un'ampia gamma di metodi di deposito per finanziare il tuo trading account. Puoi utilizzare bonifico bancario, carta di credito, Skrill, e depositi Netteller.",
          },
          {
            open: false,
            q: "Qual è il deposito minimo?",
            a: "Per aprire il tuo conto su TrioMarkets™, devi finanziare il conto con $/€ 100.",
          },
          {
            open: false,
            q: "Posso finanziare un conto in una valuta diversa dalla valuta di base?",
            a: "La valuta in cui apri il tuo conto è la valuta con la quale il tuo deposito verrà effettuato. Pertanto, se hai un conto in USD, il deposito (se effettuato con un'altra valuta) sarà automaticamente convertito in USD.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "I nostri Conti",
        faqContent: [
          // {
          //   open: false,
          //   q: "Come posso prelevare denaro?",
          //   a: "Paghiamo tramite il metodo utilizzato per finanziare il tuo conto. I prelievi con bonifico bancario comporteranno una commissione dell'1,5% dell'importo del prelievo. Clicca qui per maggiori informazioni.",
          // },
          {
            open: false,
            q: "Come chiudo il mio account?",
            a: "Per chiudere il tuo account, contatta direttamente il tuo assistenza clienti.",
          },
          {
            open: false,
            q: "Quanto tempo ci vuole per verificare il tuo account?",
            a: "A seconda della documentazione presentata, se tutto è approvato, il tuo account verrà verificato immediatamente dal nostro back office. Una volta verificato e il deposito è stato effettuato, il tuo account sarà attivato per il trading.",
          },
          {
            open: false,
            q: "Che tipo di documenti sono necessari per verificare il mio conto di trading?",
            a: `Richiediamo una prova di identità: una copia a colori chiara di uno dei seguenti:
              <ul>
                  <li>Passaporto</li>
                  <li>Carta d'identità</li>
              </ul>
              Prova di residenza:
              <ul>
                  <li>Estratto conto o lettera di referenza bancaria</li>
                  <li>Bolletta</li>
                  <li>Qualsiasi documento che confermi l'indirizzo permanente verificato tramite ente locale (bolletta fiscale, bolletta comunale)</li>
                  <li>Tutti i documenti che confermano l'indirizzo di residenza devono essere stati rilasciati negli ultimi 6 mesi e confermare l'indirizzo fornito nell'applicazione.</li>
              </ul>`,
          },
          {
            open: false,
            q: "Come posso richiedere un conto cointestato?",
            a: "Entrambi i titolari di conti cointestati devono aprire un conto di trading con TrioMarkets e presentare i documenti richiesti con l modulo di richiesta conto comune. Il conto cointestato può essere aperto Solo tra parenti di 1° grado ( richiesta prova nel caso in cui i cognomi siano diversi) o nel caso in cui i 2 clienti siano cointestatari di un conto bancario ( richiesta prova ).",
          },
          {
            open: false,
            q: "Che tipo di conto è adatto a me?",
            a: `A seconda del livello di esperienza che hai , si determinerà quale account è più adatto alle tue esigenze. Guarda i nostri tipi di account per scegliere. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview"> Clicca qui.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "Generale",
        faqContent: [
          {
            open: false,
            q: "Quali prodotti di trading posso scambiare?",
            a: `A TrioMarkets™; Offriamo una vasta gamma di classi di asset. Dale l tue coppie Forex preferite come EUR/USD, GBP/USD alle materie prime e CFD che negoziano i tuoi indici preferiti come Dow Jones, S&P 500 e il FSE. <br> Per tutte le nostre classi di asset, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">fai clic qui.</a> Per depositare denaro nel tuo conto di trading, <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login">fai clic qui.</a>`,
          },
          {
            open: false,
            q: "Come faccio trading?",
            a: "Qui a TrioMarkets™; puoi negoziare tutte le nostre classi di asset attraverso la nostra piattaforma all'avanguardia Meta Trader 4 (MT4). Offriamo ai nostri clienti una vasta gamma di informazioni di trading, da quotazioni in tempo reale ai grafici, analisi di trading e altro ancora.",
          },
          {
            open: false,
            q: "Che cos'è il forex?",
            a: "Il Forex, noto anche come Foreign Exchange, è un mercato globale per il negoziazione di coppie di valute. Inizialmente comporta l'acquisto o la vendita di una valuta contro un'altra, ovvero EUR/USD (acquistando l'EUR a aumento di valore rispetto all'USD).",
          },
        ],
      },
    ],
  },
  de: {
    moreInfo: "Mehr Infos",
    meetUsInfo: [
      {
        team: "Unser Team",
        history: "Historie des Unternehmens",
        timeline: [
          [
            { date: "Sep 2014", text: "Gründungsdatum" },
            { date: "Jan 2016", text: "Finanzberatungs- <br>lizenz" },
            { date: "Jan 2017", text: "Portfolio-Management-Lizenz" },
            { date: "Sep 2019", text: "5. Firmenjubileum" },
          ],
          [
            { date: "Feb 2015", text: "Annahme- und Übermittlungslizenz" },
            { date: "Feb 2016", text: "1. Forex Expo Stand in London (UK)" },
            { date: "Jun 2018", text: "Meilenstein 15 Mitarbeiter" },
            { date: "Feb 2020", text: "Lizenz für Handel auf eigene Rechnung" },
          ],
        ],
        aboutUs: "Über uns",
        whoWeAre: [
          {
            q: "Wer sind wir?",
            a: "Bei TrioMarkets™ glauben wir, dass es einen authentischeren Weg gibt, um mit den Finanzmärkten zu handeln. Wir bieten vertrauenswürdigen, transparenten Handel, um unseren Kunden die bestmögliche Erfahrung zu bieten. Unser Unternehmen wurde von drei Finanzexperten gegründet, die über 20 Jahre Erfahrung auf dem europäischen und schweizerischen Finanzmarkt haben. Das Trio besteht aus hochqualifizierten und effizienten Unternehmern mit einer erfolgreichen Geschichte im Online-Devisenhandel. Das Trio hat den Grundsatz, dass unsere Kunden an erster, zweiter und dritter Stelle auf unserer Prioritätenliste stehen.",
          },
          {
            q: "Was wir tun?",
            a: "Hier bei TrioMarkets™ ist es uns wichtig, Ihnen das absolut Beste an Technologie zu bieten. Unsere Hauptpriorität ist es, Ihnen zu helfen, von überall, zu jeder Zeit und auf jedem Gerät in Verbindung zu bleiben. Wir glauben, dass es unerlässlich ist, unsere Kunden zu wertschätzen und zu unterstützen, zusätzlich zur Bereitstellung der besten Handelsbedingungen, damit sich jeder sicher und zufrieden fühlt. Unser Ziel ist es, genaue, zugängliche und verfügbare Informationen über Forex bereitzustellen. Die TrioMarkets™-Plattform wurde speziell entwickelt, um jedem Level an Trading-Erfahrung gerecht zu werden, ganz gleich, ob Sie neu im Traden sind oder bereits über eine umfangreiche Trading-Erfahrung verfügen.",
          },
          {
            q: "Warum TrioMarkets™ wählen?",
            a: "Die Kundenbetreuung ist das Herzstück unserer Arbeit bei TrioMarkets™ und Ihre Bedürfnisse haben für uns oberste Priorität. Sollten Sie technische Probleme oder Probleme mit Ihrem Konto haben, können Sie sich ganz einfach an unser kompetentes, mehrsprachiges Team wenden, das Ihnen gerne weiterhelfen wird. Unsere Philosophie ist es, Ihnen einen vertrauenswürdigen, transparenten Handel zu bieten, und unser Team steht Ihnen jederzeit zur Seite. Die wahre Philosophie im Geschäftsleben ist, dass man seinen Kunden einen Mehrwert bieten muss, um sie an sich zu binden und einen gemeinsamen Erfolg zu erzielen.",
          },
        ],
        ourValues: "Unsere Werte",
        threeTs: [
          {
            name: "Vertraunswürdig",
            desc: "Man kann mit Sicherheit sagen, dass unsere Plattform sich für ein gutes Handelsumfeld einsetzt, unseren Kunden treu bleibt, indem sie hochqualifizierte und sichere Software anbietet, und sich der Präsentation der besten Dienstleistungen und Produkte widmet.",
            img: "trust",
          },
          {
            name: "Transparent",
            desc: "TrioMarkets™ zielt darauf ab, den Kunden eine transparente Erfahrung  zu bieten und garantiert, eine sichere Trading-Plattform zu sein. In Bezug auf die Kundenbetreuung, erhalten Sie die meiste Hilfe von einem erfahrenen und unterstützenden Team, zusätzlich zu der Beantwortung  Ihrer Fragen auf unserer sicheren und transparenten Webseite.",
            img: "trans",
          },
          {
            name: "Trading",
            desc: "Das Traden liegt uns seit 2014 im Blut, und deshalb sorgen wir dafür, dass all unsere Kunden eine von Kopf bis Fuß einzigartige Handelerfahrung erfahren, um ihre Zufriedenheit und Loyalität zu garantieren.",
            img: "trade",
          },
        ],
        businessConcept: "Business Konzept",
        textFour:
          "Unser Unternehmen ist im Besitz und wird betrieben von Tradern für Trader.<br>Unsere Anteilseigner kommen aus dem Investmentbanking, wo sie ihre Fähigkeiten und ihr Fachwissen in den wichtigsten Finanzzentren der Welt wie Genf und London verfeinert haben.<br>Wir wissen was Sie brauchen und was Sie von einem Online-Broker erwarten.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Hohe Liquidität",
        text: "Wir haben mehrere Liquiditätsanbieter, die ständig miteinander konkurrieren, um die besten Preise zu erzielen. Wir dulden keine drastischen Abweichungen von den Preisen unserer Ausführungsplätze und ändern diese bei Bedarf sofort.",
      },
      {
        img: "2",
        title: "Sicherheit & Stabilität",
        text: `Das Ziel des Unternehmens ist es, eine Beziehung aufzubauen, die auf gegenseitigem Vertrauen beruht. Wir sind seit 2015 auf dem Markt und haben rigorose Änderungen der Richtlinien und wirtschaftliche Umwälzungen durchlaufen, und wir sind hier, um zu sagen: "WIR BLEIBEN".`,
      },
      {
        img: "3",
        title: "Ausführungsge-schwindigkeit",
        text: "Wir sind ständig bemüht, die Ausführungsgeschwindigkeit so gering wie möglich zu halten und passen unsere Infrastruktur ständig an, um sicherzustellen, dass sich für unsere Trader keine Änderungen ergeben.",
      },
      {
        img: "4",
        title: "Preise",
        text: "Mit dem rasanten Fortschritt der Technologie, entwicklen sich auch unsere Produktangebote weiter. Wir versuchen, Ihnen nur das Beste anzubieten, sowohl in der Ausführung als auch in der Preisgestaltung.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"Ein segregiertes Konto wird verwendet, um die Kundengelder von den Firmengeldern getrennt zu halten. Dies geschieht aus mehreren Gründen, einschließlich der Schaffung einer klaren Trennung zwischen den beiden Gruppen von Geld, so dass es weder für den falschen Zweck verwendet werden kann, und auch um sicherzustellen, dass das Geld leicht als Eigentum der Kunden identifiziert werden kann, falls etwas mit der Firma passiert."</p>
        <p class="text-white text-center"> Alle Kunden von TrioMarkets™ haben ein getrenntes Konto, auf dem ihre Gelder sicher und getrennt von unseren eigenen verwahrt werden.</p>`,
        textTwo: `<p class="fw-bold fs-5">Bei TrioMarkets™ sind alle Gelder spezifisch gelagert bei der Swissquote Bank, die Ihren Hauptsitz in der Schweiz hat.</p><p class="text-justify fs-6">Die Swissquote Bank ist eine der renommiertesten Online Handelsbanken in Europa. Swissquote hat über 500 Mitarbeiter und operiert in über 7 Ländern mit weltweit 10 Filialen. Swissquote generiert ein Einkommen von ungefähr 150 Millionen (CHF) und arbeitet zur Zeit weltweit mitüber 250.000 Kunden.</p><p class="text-justify fs-6">Als Unternehmen arbeitet TrioMarkets™ nur mit den besten Partnern um den best möglichen Kundenservice anbieten zu können. TrioMarkets™ benutzt nie die Kundengelder weder für den Betrieb noch für andere Investitionen, wir gewährleisten Sicherheit rund um die Uhr.</p>`,
        textThree: `<p class="fw-bold fs-5">Entschädigungsfonds für die Investoren:</p><p class="text-justify fs-6">Der Anlegerentschädigungsfonds (ICF) wurde gemäß dem Gesetz über Investmentgesellschaften (IF) aus dem Jahr 2002 in seiner geänderten Fassung (im Folgenden "das Gesetz") und der Verordnung über die Einrichtung und den Betrieb eines Anlegerentschädigungsfonds für Kunden von ICFs aus dem Jahr 2004 (im Folgenden "die Verordnung"), die gemäß dem Gesetz erlassen wurde, eingerichtet.</p><p class="text-justify fs-6">Ziel des ICF ist es, etwaige Ansprüche gedeckter Kunden gegen Mitglieder des ICF zu sichern, indem diesen Kunden eine Entschädigung gezahlt wird, falls das Unternehmen aufgrund finanzieller Umstände, die sich in naher Zukunft wahrscheinlich nicht verbessern werden, nicht in der Lage ist.</p>`,
        points: [
          "Das ICF dient dazu, Privatkunden von CIFs abzudecken. Dies schließt jedoch die professionellen Trader und geeignete Gegenparteien aus.",
          "Abgedeckte Kunden eines ICF-Mitgliedsunternehmens können unabhängig von der Anzahl der geführten Konten, der Währung und dem Ort der Erbringung der Anlagedienstleistung eine zahlbare Vergütung erhalten.",
        ],
        textFour: `Aufgrund dieser Richtlinie, können Sie sich als Kunde bei TrioMarkets™ sicher sein, dass alle Ihre Trades unter dem höchsten Maß an Sicherheit, Integrität und Transparenz abgewickelt werden.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> ist als Investment Dealer (Broker) mit der Registrierungsnummer 160496 registriert. Lizenziert von der Financial Services Commission (Mauritius) unter der Lizenznummer <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> und verfügt über die Lizenz zur Durchführung von Operationen unter dem Handelsnamen TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Wir sind für den internationalen Handel zugelassen und auf den CFD-Online-Handel mit Währungen, Rohstoffen, Indizes, Aktien und Terminkontrakten spezialisiert. Wir verpflichten uns zu den höchsten Geschäftsstandards und betrachten unser Engagement für Ethik als Eckpfeiler unserer Philosophie.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> ist ein Handelsname, der EDR Financial Ltd gehört und von <strong>EDR Financial Ltd </strong> betrieben wird und als zypriotische Investmentfirma (CIF) mit der Registrierungsnummer <strong> (HE336081). </strong> registriert ist.</p><p class="text-justify fs-6 mb-0">Dieser <span class="fw-bold">ECN/STP</span> Forex-Broker wird von der Cyprus Securities and Exchange Commission <span class="fw-bold">(CySEC)</span> unter der Lizenznummer <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a> reguliert, die unter das Haftungsdach der <span class="fw-bold">Markets in Financial Instruments Directive II (MIFID II) fällt.</span></p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> ist eine Repräsentanz/Zweigstelle von Benor Capital Ltd. (Mauritius) mit Sitz in den Vereinigten Arabischen Emiraten - Dubai unter der DED-Lizenznummer<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Eingetragener Sitz: The Bayswater Tower , Office 1212, Marasi Drive, Business Bay, Dubai, Vereinigte Arabische Emirate und hat die Lizenz, Marketing- und Handelsaktivitäten unter dem Handelsnamen TrioMarkets&trade; durchzuführen. `,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "Wichtige Links",
        importantLinks: [
          "Anlegerentschädigungsfonds",
          "Datenschutz-Bestimmungen",
          "Richtlinie zu Interessenkonflikten",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(GERMAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(GERMAN)",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(GERMAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(GERMAN)",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(GERMAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(GERMAN)",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(GERMAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(GERMAN)",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(GERMAN).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(GERMAN)",
      },
    ],
    contactInfo: [
      {
        text: `Haben Sie eine Frage oder benötigen fachliche Unterstützung?<br>Wenden Sie sich an unser engagiertes Kundendienst Team, das 24/5 für Sie da ist!`,
        points: [
          {
            img: "phone",
            name: "Telefonischer Support",
            text:
              license == "global" ? "+49 61 718 913 051" : "+49 61 718 913 050",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "E-Mail Support",
            text: "Mailen Sie uns",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          { img: "chat", name: "Chat Support", text: "Live-Chat Starten" },
        ],
        pointsTwo: [
          { img: "en", name: "Englischer Support" },
          { img: "fr", name: "Französischer Support" },
          { img: "it", name: "Italienischer Support" },
          { img: "de", name: "Deutscher Support" },
          { img: "uae", name: "Arabischer Support" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Wo befinden sich Ihre Büros?",
            a:
              license == "global"
                ? "Unser Hauptsitz befindet sich auf Mauritius."
                : "Unser Hauptsitz befindet sich in Limassol, Zypern.",
          },
          {
            open: false,
            q: "Welche Art von Broker sind Sie?",
            a: "TrioMarkets™ ist ein ECN/STP Forex-Broker.",
          },
          {
            open: false,
            q: "Wie werden Sie reguliert?",
            a:
              license == "global"
                ? "TrioMarkets™ wird von der Financial Services Commission (FSC) Mauritius, unter der Lizenznummer C118023678, reguliert."
                : "TrioMarkets™ wird von der Cyprus Securities and Exchange Commission (CySEC) unter der Lizenznummer 268/15 reguliert, die unter das Dach der Markets in Financial Instruments Directive II (MIFID II) fällt.",
          },
        ],
      },
      {
        faqSection: 2,
        title: "Einzahlung und Abhebungen ",
        faqContent: [
          {
            open: false,
            q: "Wie zahle ich Geld ein?",
            a:
              license == "global"
                ? "Wir akzeptieren eine breite Palette von Einzahlungsmethoden, um auf Ihr Trading-Konto einzuzahlen. Sie können hierfür eine Überweisung, Kreditkarte,  und Netteller-Einzahlungen nutzen."
                : "Wir akzeptieren eine breite Palette von Einzahlungsmethoden, um auf Ihr Trading-Konto einzuzahlen. Sie können hierfür eine Überweisung, Kreditkarte, Skrill, und Netteller-Einzahlungen nutzen.",
          },
          {
            open: false,
            q: "Wie hoch ist die Mindesteinlage?",
            a: "Um Ihr Konto bei TrioMarkets™ zu eröffnen, müssen Sie $/€100 auf das Konto einzahlen.",
          },
          {
            open: false,
            q: "Kann ich auf das Konto in einer anderen Währung als der Basiswährung einzahlen?",
            a: "Die Währung, mit der Sie Ihr Konto eröffnen, ist die Währung in der Ihre Einzahlung erfolgt. Wenn Sie also ein USD-Konto haben wird die Einzahlung (falls sie in einer anderen Währung erfolgt) automatisch in USD umgerechnet.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Unsere Konten",
        faqContent: [
          // {
          //   open: false,
          //   q: "Wie kann ich Geld abheben?",
          //   a: "Wir zahlen über die Methode, mit der Sie Ihr Konto aufgeladen haben. Alle Banküberweisungen werden mit einer Gebühr von 1,5 % des Abhebungsbetrags belastet. Klicken Sie hier für weitere Informationen.",
          // },
          {
            open: false,
            q: "Wie kann ich mein Konto schließen?",
            a: "Um Ihr Konto zu schließen, wenden Sie sich bitte direkt an Ihren Kundenbetreuer.",
          },
          {
            open: false,
            q: "Wie lange dauert es, Ihr Konto zu verifizieren?",
            a: "Abhängig von den eingereichten Unterlagen, wenn alles gültig und bestätigt ist, wird Ihr Konto sofort von unserem Back Office verifiziert. Nach der Verifizierung und der Einzahlung wird Ihr Konto für das Traden aktiviert.",
          },
          {
            open: false,
            q: "Welche Dokumente werden zur Verifizierung meines Tradingkontos benötigt?",
            a: `Wir benötigen einen Identitätsnachweis: eine klare Farbkopie eines der folgenden Dokumente:
              <ul>
                  <li>Reisepass</li>
                  <li>Personalausweis</li>
              </ul>
              Nachweis des Wohnsitzes:
              <ul>
                  <li>Kontoauszug oder Referenzschreiben der Bank</li>
                  <li>Rechnung eines Versorgungsunternehmens</li>
                  <li>Jedes Dokument, das die permanente Adresse über eine lokale Behörde (Steuerbescheid, Gemeinderechnung) bestätigt</li>
                  <li>Alle Dokumente zur Bestätigung der Wohnanschrift müssen innerhalb der letzten 6 Monate ausgestellt worden sein und die im Antrag angegebene Adresse bestätigen.</li>
              </ul>`,
          },
          {
            open: false,
            q: "Wie beantrage ich ein Gemeinschaftskonto?",
            a: "Beide Inhaber eines Gemeinschaftskontos müssen ein Handelskonto bei TrioMarkets™ eröffnen und die erforderlichen Unterlagen mit dem Antragsformular für das Gemeinschaftskonto einreichen. Ein Gemeinschaftskonto kann zwischen Verwandten 1. Grades eröffnet werden (Nachweis erforderlich, wenn die Nachnamen unterschiedlich sind) oder wenn die 2 Kunden Inhaber eines gemeinsamen Bankkontos sind (Nachweis erforderlich).",
          },
          {
            open: false,
            q: "Welcher Kontotyp ist für mich geeignet?",
            a: `Je nachdem, wie viel Erfahrung Sie haben, können Sie entscheiden, welches Konto am besten für Ihre Bedürfnisse geeignet ist. Siehe unsere Kontotypen zur Auswahl. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">Klicken Sie hier.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "Allgemein",
        faqContent: [
          {
            open: false,
            q: "Mit welchen Trading-Produkten kann ich traden?",
            a: `Bei TrioMarkets™ bieten wir eine breite Palette von Anlageklassen an. Von Ihren bevorzugten Devisenpaaren wie EUR/USD, GBP/USD bis hin zu Rohstoffen und CFDs, Ihren Lieblingsindizes wie den Dow Jones, S&P 500 und dem FTSE. <br>Für alle unsere Anlageklassen <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview"> klicken Sie bitte hier.</a> Um Geld auf Ihr Handelskonto einzuzahlen, <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login"> klicken Sie bitte hier.</a>`,
          },
          {
            open: false,
            q: "Wie kann ich traden?",
            a: "Hier bei TrioMarkets™; Sie können alle unsere Anlageklassen über unsere hochmoderne Meta Trader 4 (MT4) Plattform traden. Wir bieten unseren Kunden eine Fülle von Handelsinformationen, von Echtzeit-Updates zu Charts, Handelsanalysen und mehr.",
          },
          {
            open: false,
            q: "Was ist Forex?",
            a: "Forex, auch bekannt als Foreign Exchange, ist ein globaler Markt für den Handel von Währungspaaren. Ursprünglich handelte es sich dabei um den Kauf oder Verkauf einer Währung gegen eine andere, z. B. EUR/USD (Kauf des EUR zur Wertsteigerung gegenüber dem USD).",
          },
        ],
      },
    ],
  },
  cn: {
    moreInfo: "更多信息",
    meetUsInfo: [
      {
        aboutUs: "关于我们",
        whoWeAre: [
          {
            q: "我们是谁？",
            a: "在TrioMarkets™, 我们相信有一种更真实的方式来交易金融市场。我们提供值得信赖的、透明的交易, 为我们的客户提供最好的体验。我们的公司是由三位在欧洲和瑞士金融市场拥有超过20年经验的金融专业人士建立的, 三人组由拥有成功的在线外汇背景的高技能企业家组成。三人组的原则是, 我们的客户在我们的优先名单上排第一、第二和第三。",
          },
          {
            q: "我们做什么？",
            a: "在TrioMarkets™, 为您提供绝对最好的技术是至关重要的, 我们的主要任务是帮助您在任何地方、任何时间、任何设备上保持联系。我们认为, 除了提供最好的交易环境, 还必须重视和支持我们的客户, 以帮助每个人感到自信和满意。我们的使命是提供准确的、可获取的外汇信息。TrioMarkets™平台设计独特, 适合每个级别的交易经验, 无论你是交易新手还是有丰富的交易背景。",
          },
          {
            q: "为什么选择TrioMarkets™？",
            a: "客户服务是我们在TrioMarkets™ 所做工作的核心，您的需求是我们的首要任务。如果您碰巧遇到任何技术或帐户困难，您可以轻松地与我们知识渊博的多语种团队取得联系，他们将很乐意为您提供帮助。我们的理念是为您提供值得信赖、透明的交易，我们的团队随时准备为您提供帮助。真正的商业哲学是，您必须为客户提供价值，以保持客户忠诚度并实现共同成功。",
          },
        ],
        ourValues: "我们的价值观",
        threeTs: [
          {
            name: "值得信赖：",
            desc: "可以肯定地说，我们的平台致力于确保良好的交易环境，通过提供高质量、安全的软件忠实于客户，并致力于提供最好的服务和产品。",
            img: "trust",
          },
          {
            name: "高度透明：",
            desc: "TrioMarkets™ 旨在为客户提供透明的体验，并保证为安全交易提供安全平台。在客户支持方面，除了通过安全透明的网站回答您的所有问题外，您还将从经验丰富的支持团队那里获得最大帮助。",
            img: "trans",
          },
          {
            name: "交易：",
            desc: "自2014年以来, 交易一直流淌在我们的血液中, 为此, 我们确保为所有类型的客户提供一个从头到脚的独特交易之旅, 并保证他们的满意度和忠诚度。",
            img: "trade",
          },
        ],
        businessConcept: "经营理念",
        textFour:
          "我们公司由交易者拥有，并为交易者经营。<br>我们的股东来自投资银行, 他们在日内瓦和伦敦等世界顶级金融中心磨练了自己的技能和专业知识。<br>我们知道您需要什么以及您想从在线经纪商那里得到什么。",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "深度流动性",
        text: "我们有多个流动性提供商，不断相互竞争以提供最佳报价。我们不容忍执行报价偏差过大，在必要时我们会立即更换它们。",
      },
      {
        img: "2",
        title: "安全稳定",
        text: "公司的目标是在相互信任的基础上建立关系，我们自 2015 年进入市场，经历了严格的监管变化和经济动荡，我们在这里说“我们很坚持”。",
      },
      {
        img: "3",
        title: "执行速度",
        text: "我们一直在努力将我们的执行速度保持在最低限度，并不断调整我们的基础设施以确保它不会为我们的交易者改变。",
      },
      {
        img: "4",
        title: "报价",
        text: "重新报价已成为过去，随着技术的进步，我们为您提供的产品也在不断进步，因此我们努力只为您提供最好的市场执行和报价。",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">“使用隔离帐户将客户资金与公司资金分开。这样做有几个原因，包括在两组资金之间建立明确的分离，这样任何一方都不会被用于错误的目的，并且还可以确保在公司发生任何问题时，可以很容易地将资金识别为属于客户。”</p>
        <p class="text-white text-center">TrioMarkets™ 的所有客户都将拥有一个隔离账户，您的资金是安全且的，且与我们自己的资金分开存放。</p>`,
        textTwo: `<p class="fw-bold fs-5">TrioMarkets™ 所有资金都专门存放在 Swissquote银行瑞士总部。</p><p class="text-justify fs-6">Swissquote 银行是欧洲最负盛名的在线交易公司/银行之一。 Swissquote 拥有 500 多名员工, 在 7 个国家/地区开展业务, 在全球设有 10 个分支机构, 收入约为 1.5 亿瑞士法郎, 目前与全球超过 250,000 名客户合作。</p><p class="text-justify fs-6">作为一家公司，TrioMarkets™ 只与最好的公司合作，以便为我们的客户提供最好的服务。TrioMarkets™ 绝不会将客户的资金用于其运营或任何其他投资，我们始终确保客户资金安全。</p>`,
        textThree: `<p class="fw-bold fs-5">投资者赔偿基金：</p><p class="text-justify fs-6">投资者赔偿基金 (ICF) 是根据经修订的2002年投资公司 (IF) 法（以下简称“该法”）设立的，客户投资者赔偿基金的设立和运作是根据该法颁布的2004年ICF条例（以下简称“条例”）。</p><p class="text-justify fs-6">ICF的目标是在公司因近期不太可能改善的财务状况而无法支付的情况下，通过向这些客户支付赔偿金来确保涵盖客户对 ICF 成员的任何索赔。</p>`,
        points: [
          "ICF 服务于 CIF 的零售客户。 这不包括专业客户和合格的交易对手。",
          "无论持有的账户数量、货币和提供投资服务的地点如何, ICF 成员公司的涵盖客户都可以获得应付补偿。",
        ],
        textFour: `由于这项政策，作为TrioMarkets™的客户，您在交易时可以放心，您的所有交易都将在最高级别的安全性、完整性和透明度下完成。`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong>注册为投资交易商（经纪商），注册号为160496。获得金融服务委员会(毛里求斯)的许可,许可号为<a  target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a>，并有权以交易名称TrioMarkets™ 开展业务。</p><p class="text-justify fs-6 mb-0">我们被授权进行国际交易, 专门从事货币、商品、指数、股票和远期合约的差价合约在线交易。 我们致力于最高的商业标准, 并将我们对道德的奉献视为我们理念的基石。</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> 是 <strong>EDR Financial Ltd </strong> 拥有和运营的交易名称, 注册为塞浦路斯投资公司 (CIF), 注册号为 <strong> (HE336081)。</strong></p><p class="text-justify fs-6 mb-0">该 <span class="fw-bold">ECN/STP</span> 外汇经纪商受塞浦路斯证券交易委员会 <span class="fw-bold">(CySEC)</span> 监管, 许可证号为 <a  target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, <strong>属于金融工具市场指令 II (MIFID II) 的范畴</strong></p>`,
        dubai: `<strong>Benor capital Ltd-代表处</strong>是Benor Capital Ltd.(Mauritius)在阿拉伯联合酋长国迪拜的代表机构/分公司，DED许可证号为  <a target="_blank"> <strong class="text-primary">1082819</strong></a>。注册办事处：The Bayswater Tower，Office 1212，Marasi Drive，Business Bay，迪拜，阿拉伯联合酋长国，拥有以TrioMarkets™交易名称开展营销和商业活动的许可。`,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "重要链接",
        importantLinks: ["投资者赔偿基金", "隐私政策", "利益冲突政策"],
      },
    ],
    contactInfo: [
      {
        text: `有疑问或需要专家帮助？<br>请联系我们的24/5专业客户服务团队为您提供帮助！`,
        points: [
          {
            img: "phone",
            name: "电话支持",
            text: license == "global" ? "+44 20 376 936 49" : "+357 22 222737",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "电子邮件支持",
            text: "给我们发电子邮件",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          { img: "chat", name: "聊天支持", text: "开始实时聊天" },
        ],
        pointsTwo: [
          { img: "en", name: "英语服务台" },
          { img: "fr", name: "法语服务台" },
          { img: "it", name: "意大利语服务台" },
          { img: "de", name: "德语服务台" },
          { img: "uae", name: "阿拉伯语服务台" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "你们的办公室在哪里？",
            a: "我们的总部设在毛里求斯。",
          },
          {
            open: false,
            q: "你是什么类型的经纪商？",
            a: "TrioMarkets™ 是 ECN/STP 外汇经纪商。",
          },
          {
            open: false,
            q: "你有什么监管？",
            a: "TrioMarkets™ 受金融服务委员会监管 (FSC) 毛里求斯, 许可证号为 C118023678。",
          },
        ],
      },
      {
        faqSection: 2,
        title: "入金和出金",
        faqContent: [
          {
            open: false,
            q: "我如何存钱？",
            a: "我们接受多种入金方式来为您的交易帐户注资，您可以使用银行电汇、信用卡和Netteller存款。",
          },
          {
            open: false,
            q: "最低入金是多少？",
            a: "在TrioMarkets™ 开设您的账户，您必须为账户注资最低 $100。",
          },
          {
            open: false,
            q: "我可以使用与基础货币不同的货币为账户注资吗？",
            a: "您开户时使用的货币是您入金的货币。因此，如果您有美元账户，入金（如果使用其他货币）将自动转换为美元。",
          },
        ],
      },
      {
        faqSection: 3,
        title: "我们的账户",
        faqContent: [
          {
            open: false,
            q: "我如何出金？",
            a: "我们通过您入金的方式给您出金。所有提款将收取提款金额的 1.5% 的费用。",
          },
          {
            open: false,
            q: "如何关闭我的帐户？",
            a: "要关闭您的帐户, 请直接联系您的客户经理。",
          },
          {
            open: false,
            q: "验证我的帐户需要多长时间？",
            a: "取决于提交的文件，如果所有文件均有效并获得批准，我们的后台将立即验证您的帐户。一旦验证并存入资金，您的账户将被激活进行交易。",
          },
          {
            open: false,
            q: "需要什么样的文件来验证我的交易账户？",
            a: `我们需要身份证明：以下其中一项的清晰彩色副本：
              <ul>
                  <li>护照</li>
                  <li>身份证</li>
              </ul>
              居住证明：
              <ul>
                  <li>银行对账单或信用卡对账单</li>
                  <li>水电煤账单</li>
                  <li>任何通过地方当局核实的永久地址文件（税单、市政单据）</li>
                  <li>所有文件确认居住地址必须是最近6个月内签发的，并与申请中提供的地址一致。</li>
              </ul>`,
          },
          {
            open: false,
            q: "如何申请联名账户？",
            a: "联名账户持有人双方都应在TrioMarkets开立交易账户，并连同联名账户申请表提交所需文件。联名账户只能在一级亲属之间开设（如果姓氏不同，则需要证明），或者如果2位客户是联名银行账户持有人（需要证明）。",
          },
          {
            open: false,
            q: "什么类型的账户适合我？",
            a: `根据您的经验水平，您将决定哪个帐户类型最适合您的需求。查看我们的帐户类型。<a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">请点击此处。</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "一般的",
        faqContent: [
          {
            open: false,
            q: "我可以交易哪些产品？",
            a: `在 TrioMarkets™;我们提供广泛的资产类别。从你最喜欢的外汇货币对,如欧元/美元、英镑/美元到商品和差价合约，交易您最喜欢的指数，如道琼斯、标准普尔500和富时指数。<br> 查看我们所有的资产类别，<a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">请点击此处。</a> 将钱存入您的交易账户, <a href="https://secure.triomarkets.${
              location.host.includes(".com") || location.host.includes("8081")
                ? "com"
                : "eu"
            }/en/login">请点击这里。</a>`,
          },
          {
            open: false,
            q: "我如何交易？",
            a: "在TrioMarkets™；您可以通过最先进的 MetaTrader 4 (MT4) 平台交易我们所有的资产类别。我们为客户提供丰富的交易信息，包括实时报价、图表、交易分析等。",
          },
          {
            open: false,
            q: "什么是外汇？",
            a: "Forex，也称为外汇，是一个全球性的货币对交易市场。最初它涉及一种货币对另一种货币的买卖，即欧元/美元（买入欧元以增加其对美元的价值）。",
          },
        ],
      },
    ],
  },
  es: {
    moreInfo: "Más Información",
    meetUsInfo: [
      {
        team: "Nuestro Equipo",
        history: "Historia de la Empresa",
        timeline: [
          [
            { date: "Sep 2014", text: "Fecha de Incorporación" },
            { date: "Jan 2016", text: "Licencia de Asesoramiento Financiero" },
            { date: "Jan 2017", text: "Licencia de Gestión de Cartera" },
            { date: "Sep 2019", text: "5 años de Aniversario" },
          ],
          [
            { date: "Feb 2015", text: "Licencia RTO" },
            {
              date: "Feb 2016",
              text: "1er stand de Forex Expo en Londres (Reino Unido)",
            },
            { date: "Jun 2018", text: "Hito de 15 empleados" },
            { date: "Feb 2020", text: "Licencia de Cuenta propia" },
          ],
        ],
        aboutUs: "Quiénes somos",
        whoWeAre: [
          {
            q: "¿Quiénes somos?",
            a: "En TrioMarkets™, creemos que hay una forma más auténtica de operar en los mercados financieros. Proporcionamos operaciones fiables y transparentes para garantizar la mejor experiencia posible a nuestros clientes. Nuestra empresa fue fundada por tres profesionales financieros con más de 20 años de experiencia en el mercado financiero europeo y suizo. El trío está formado por empresarios altamente cualificados con una exitosa trayectoria en el mercado Forex en línea. El Trio tiene como principio que nuestros clientes son lo primero, lo segundo y lo tercero en nuestra lista de prioridades.",
          },
          {
            q: "¿Qué hacemos?",
            a: "Aquí en TrioMarkets™ es vital proporcionarle lo mejor en tecnología, nuestra mayor prioridad es ayudarle a mantenerse conectado desde cualquier lugar en cualquier momento y en cualquier dispositivo. Creemos que es imperativo valorar y apoyar a nuestros clientes además de proporcionar el mejor entorno de trading para ayudar a que todos se sientan confiados y satisfechos. Nuestra misión es proporcionar información precisa, accesible y obtenible sobre Forex. La plataforma de TrioMarkets™ ha sido diseñada de forma única para adaptarse a cada nivel de experiencia de trading, tanto si eres nuevo en el trading como si tienes una amplia experiencia.",
          },
          {
            q: "¿Por qué elegir TrioMarkets™?",
            a: "La atención al cliente es el núcleo de lo que hacemos aquí en TrioMarkets™ y sus necesidades son nuestra máxima prioridad. Si experimenta cualquier dificultad técnica o con su cuenta, puede ponerse fácilmente en contacto con nuestro equipo multilingüe que estará encantado de ayudarle. Nuestra filosofía es ofrecerle un comercio fiable y transparente, y nuestro equipo está preparado para ayudarle en todo momento. La verdadera filosofía en los negocios es que hay que ofrecer valor a los clientes para fidelizarlos y lograr el éxito mutuo.",
          },
        ],
        ourValues: "Nuestros valores",
        threeTs: [
          {
            name: "Confianza",
            desc: "Es seguro decir que nuestra plataforma se compromete a garantizar un buen entorno de trading, fiel a sus clientes presentando un software altamente cualificado y seguro, y dedicada a presentar los mejores servicios y productos.",
            img: "trust",
          },
          {
            name: "Transparente",
            desc: "TrioMarkets™ tiene como objetivo dar una experiencia transparente a los clientes y garantiza otorgar una plataforma segura para un trading seguro. En cuanto a la atención al cliente, obtendrá la máxima ayuda de un equipo experimentado y solidario, además de obtener respuesta a todas sus preguntas a través de un sitio web seguro y transparente.",
            img: "trans",
          },
          {
            name: "Trading",
            desc: "Retrieving data. Wait a few seconds and try to cut or copy again.",
            img: "trade",
          },
        ],
        businessConcept: "Concepto de Negocio",
        textFour:
          "Nuestra empresa es propiedad y está operada por Traders para Traders.<br>Nuestros accionistas provienen de la banca de inversión donde perfeccionaron sus habilidades y experiencia en los principales centros financieros del mundo, como Ginebra y Londres.<br>Sabemos lo que necesitas y lo que quieres de un traderr en línea.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Profundo Liquidez",
        text: "Tenemos varios proveedores de liquidez a los que compiten constantemente entre sí para ofrecer el mejor precio. No toleramos ninguna desviación drástica de los precios de nuestros lugares de ejecución y los cambiamos de inmediato si es necesario.",
      },
      {
        img: "2",
        title: "Seguridad y Estabilidad",
        text: `El objetivo de la empresa es establecer un relación basada en la confianza mutua. Hemos estado en el mercado desde 2015 y pasamos por rigurosos cambios regulatorios y trastornos económicos, y estamos aquí para decir " NOS QUEDAMOS "`,
      },
      {
        img: "3",
        title: "Ejecución Velocidad",
        text: "Estamos constantemente alerta para mantener nuestros velocidad de ejecución al mínimo y ajustamos constantemente nuestra infraestructura para asegurarnos de que no cambie para nuestros comerciantes.",
      },
      {
        img: "4",
        title: "Precios",
        text: "Requotes es una cosa del pasado, ya que la tecnología avances también lo hace nuestra oferta de productos para usted, por lo que tratamos de brindarle solo lo mejor, la ejecución y los precios del mercado.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"Se utiliza una cuenta segregada para mantener los fondos de los clientes separada de los Fondos de la Compañía. Esto se hace por varias razones, incluida la creación de una separación clara entre los dos grupos de dinero para que ninguno se use para el propósito incorrecto, y también para asegurarse de que el dinero se pueda identificar fácilmente como perteneciente a los clientes en caso de que algo le suceda firma."</p>
        <p class="text-white text-center">Todos los clientes con TrioMarkets™ tendrán una cuenta segregada por lo que sus fondos están seguros y se mantienen separados de los nuestros.</p>`,
        textTwo: `<p class="fw-bold fs-5">En TrioMarkets™ todos los fondos se mantienen específicamente dentro la sede de Swissquote en Suiza.</p><p class="text-justify fs-6">Swissquote Bank es uno de los Brokers en línea de mayor reputación empresas/bancos en Europa. Swissquote tiene más de 500 empleados y opera en más de 7 países con 10 sucursales en todo el mundo, generando unos ingresos de alrededor de 150 millones (CHF) y actualmente trabaja con más de 250 000 clientes en todo el mundo.</p><p class="text-justify fs-6">Como empresa, TrioMarkets™ solo trabaja con los mejores para para brindar a nuestros clientes el mejor servicio posible. TrioMarkets™ nunca utilizará los fondos de los clientes en su operación o para cualquier otra inversión, aseguramos la protección en todo momento.</p>`,
        textThree: `<p class="fw-bold fs-5">Fondo de Compensación de Inversores:</p><p class="text-justify fs-6">El Fondo de Compensación para Inversionistas (ICF) fue establecido bajo el Ley de Empresas de Inversión (IF) de 2002 y sus enmiendas (en lo sucesivo, la Ley") y el Establecimiento y Operación de un Fondo de Compensación para Inversores para clientes de las Regulaciones de 2004 de ICF (en lo sucesivo, "las Regulaciones") que se emitieron bajo la Ley".</p><p class="text-justify fs-6">El objetivo del ICF es asegurar cualquier reclamo de cobertura clientes contra los miembros de la ICF mediante el pago de una compensación a estos clientes en caso de que la empresa no pueda debido a circunstancias financieras que no es probable que mejoren en un futuro próximo.</p>`,
        points: [
          "El ICF sirve para cubrir Clientes Minoristas de CIFs. Esto excluye Clientes Profesionales y Contrapartes Elegibles.",
          "Los Clientes cubiertos de una empresa miembro de ICF pueden recibir compensación independientemente del número de cuentas mantenidas, moneda y lugar de prestación del servicio de inversión.",
        ],
        textFour: `Debido a esta política, como cliente de TrioMarkets™, puede sentirse confiado al operar que todas sus operaciones se completarán bajo el más alto nivel de seguridad, integridad y transparencia.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> está registrada como agente de inversiones (corredor) con el número de registro 160496. Con licencia de la Comisión de Servicios Financieros (Mauricio) con el número de licencia <a  target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> y tiene la licencia para realizar operaciones con el nombre comercial TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Estamos autorizados para operar internacionalmente, y nos especializamos en el comercio en línea de CFD sobre divisas, materias primas, índices, acciones y contratos a plazo. Estamos comprometidos con los máximos estándares comerciales y consideramos que nuestra dedicación a la ética es la piedra angular de nuestra filosofía.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> es un nombre comercial que pertenece y es operado por <strong>EDR Financial Ltd</strong> y está registrado como una empresa de inversión de Chipre (CIF) con el número de registro <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0">Este corredor de Forex <strong>ECN/STP</strong> está regulado por la Comisión de Bolsa y Valores de Chipre <strong>(CySEC)</strong> con el número de licencia <a  target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, que se encuentra bajo el paraguas de la Directiva de <strong>Mercados de Instrumentos Financieros II (MIFID II)</strong>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> es una oficina de representación/sucursal de Benor Capital Ltd. (Mauricio) situada en los Emiratos Árabes Unidos - Dubái con el número de licencia DED <a target="_blank"> <strong class="text-primary">1082819</strong></a>. Domicilio social: The Bayswater Tower , Oficina 1212, Marasi Drive, Business Bay, Dubai, Emiratos Árabes Unidos y tiene la licencia para realizar actividades comerciales y de marketing bajo el nombre comercial TrioMarkets&trade;.`,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "Links Importantes",
        importantLinks: [
          "Fondo de Compensación de Inversores",
          "Política de Privacidad",
          "Política de Conflictos de Intereses",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(SPANISH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs(SPANISH)",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(SPANISH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs(SPANISH)",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(SPANISH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs(SPANISH)",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(SPANISH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS(SPANISH)",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(SPANISH).pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs(SPANISH)",
      },
    ],
    contactInfo: [
      {
        text: `Tiene alguna pregunta o necesita asistencia especializada? <br /> Póngase en contacto con nuestro equipo de servicio al cliente dedicado que está Disponible 24/5 para ayudarte!`,
        points: [
          {
            img: "phone",
            name: "Soporte Telefónico",
            text: license == "global" ? "+34 51 88 90 216" : "+34 51 88 90 215",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "Soporte de correo Electrónico",
            text: "Envíenos un correo Electrónico",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "Chat de Soporte",
            text: "Iniciar Chat en Vivo",
          },
        ],
        pointsTwo: [
          { img: "en", name: "Desk Inglés" },
          { img: "fr", name: "Desk Francese" },
          { img: "it", name: "Desk Italiano" },
          { img: "de", name: "Desk Alemán" },
          { img: "uae", name: "Desk Árabe" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Donde están las nostras oficinas?",
            a:
              license == "global"
                ? "Nuestra sede se encuentra en Mauritius."
                : "Nuestra sede se encuentra en Limassol, Chipre.",
          },
          {
            open: false,
            q: "Qué tipo de Broker es usted?",
            a: "TrioMarkets™ es un bróker de Forex ECN/STP.",
          },
          {
            open: false,
            q: "Qué reglamento tienes?",
            a:
              license == "global"
                ? "TrioMarkets™ está regulado por la Financial Comisión de Servicios (FSC) de Mauricio, bajo la número de licencia C118023678."
                : "TrioMarkets ™ está regulado por el Chipre Securities and Exchange Commission (CySEC) bajo el número de licencia 268/15 que se encuentra bajo el paraguas de la Directiva de Mercados de Instrumentos Financieros II (MIFID II).",
          },
        ],
      },
      {
        faqSection: 2,
        title: "Depositos y Retiros",
        faqContent: [
          {
            open: false,
            q: "Cómo depositar dinero?",
            a:
              license == "global"
                ? "Aceptamos una amplia gama de métodos de depósito para financiar su cuenta comercial. Puede utilizar transferencias bancarias, tarjetas de crédito y depósitos de Netteller."
                : "Aceptamos una amplia gama de métodos de depósito para financiar su cuenta comercial. Puede utilizar transferencias bancarias, tarjetas de crédito, Skrill, and Netteller deposits.",
          },
          {
            open: false,
            q: "Cuál es el depósito mínimo?",
            a: "Para abrir su cuenta en Trio Markets™, usted debe depositar  $/€100.",
          },
          {
            open: false,
            q: "Puedo depositar fondos en una cuenta en una moneda diferente que la moneda base?",
            a: "La moneda en la que abre su cuenta with es la moneda en la que se realizará su depósito. Por lo tanto, si tiene una cuenta en USD, el depósito (si se realiza con otra moneda) se convertirá automáticamente a USD.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Nuestras Cuentas",
        faqContent: [
          // {
          //   open: false,
          //   q: "Cómo retirar dinero?",
          //   a: "Pagamos a través del método utilizado para depositar su cuenta. Todos los retiros incurrirán en una tarifa del 1,5% del monto del retiro.",
          // },
          {
            open: false,
            q: "Cómo cierro mi cuenta?",
            a: "Para cerrar su cuenta, póngase en contacto directamente con el servicio de atención al cliente.",
          },
          {
            open: false,
            q: "Cuánto tiempo se tarda en verificar mi cuenta?",
            a: "Dependiendo de la documentación presentada, si todo es válido y aprobado, su cuenta será verificada inmediatamente por nuestra oficina administrativa. Una vez verificado y realizado el depósito, su cuenta se activará para operar.",
          },
          {
            open: false,
            q: "¿Qué tipo de documentos se necesitan para verificar mi cuenta ?",
            a: `Requerimos Prueba de Identidad: una copia color clara de uno de los siguientes:
              <ul>
                  <li>Pasaporte</li>
                  <li>Tarjeta de Identificación</li>
              </ul>
              Prueba de Residencia:
              <ul>
                  <li>Extracto bancario o carta de referencia bancaria</li>
                  <li>Factura de servicios públicos</li>
                  <li>Cualquier documento que confirme la dirección permanente que se verifica a través de la autoridad local (factura de impuestos, factura municipal)</li>
                  <li>Todos los documentos que confirmen la residencia la dirección debe haber sido emitida dentro de los últimos 6 meses y confirmar la dirección provista en la solicitud.</li>
              </ul>`,
          },
          {
            open: false,
            q: "Cómo solicito una cuenta conjunta?",
            a: "Ambos titulares de cuentas conjuntas deberán abrir una cuenta Trading con TrioMarkets y envíe los documentos requeridos con el formulario de solicitud de cuenta conjunta. La cuenta conjunta se puede abrir solo entre parientes de primer grado (se requiere prueba en caso de que los apellidos sean diferentes) o en caso de que los 2 clientes sean titulares de cuentas bancarias conjuntas (se requiere prueba).",
          },
          {
            open: false,
            q: "Qué tipo de cuenta es adecuada para mí?",
            a: `Dependiendo del nivel de experiencia que tengas determinará qué cuenta se adapta mejor a sus necesidades. Consulte nuestros tipos de cuenta para elegir. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">Haga clic aquí.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "General",
        faqContent: [
          {
            open: false,
            q: "Qué productos de trading puedo negociar?",
            a: `En TrioMarkets™; ofrecemos una amplia gama de clases de activos. Desde sus pares de divisas favoritos, como EUR/USD, GBP/USD, hasta materias primas y CFD, negocie sus índices favoritos, como Dow Jones, S&P 500 y FTSE. <br>
            Para todas nuestras clases de activos, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">haga clic aquí.</a>
            Para depositar dinero en su cuenta de trading, por favor <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login">haga clic aquí.</a>`,
          },
          {
            open: false,
            q: "Cómo hacer trading?",
            a: "Aquí en TrioMarkets™; puede operar con todos nuestras clases de activos a través de nuestra plataforma Meta Trader 4 (MT4) de última generación. Ofrecemos a nuestros clientes una gran cantidad de información comercial desde cotizaciones en tiempo real hasta gráficos, análisis comercial y más.",
          },
          {
            open: false,
            q: "Qué es el Forex?",
            a: "Forex, también conocido como Divisas, es un mercado global para el comercio de pares de divisas. Inicialmente implica la compra o venta de una divisa frente a otra, es decir, EUR/USD (comprar EUR para aumentar su valor frente al USD).",
          },
        ],
      },
    ],
  },
  vnm: {
    moreInfo: "Thêm thông tin",
    meetUsInfo: [
      {
        aboutUs: "Về chúng tôi",
        whoWeAre: [
          {
            q: "Chúng ta là ai ?",
            a: "Tại TrioMarkets™, chúng tôi tin rằng có một cách xác thực hơn để giao dịch trên thị trường tài chính. Chúng tôi cung cấp Giao dịch đáng tin cậy, minh bạch để mang lại trải nghiệm tốt nhất có thể cho khách hàng của chúng tôi. Công ty của chúng tôi được thành lập bởi ba chuyên gia tài chính có hơn 20 năm kinh nghiệm ở cả Thị trường tài chính Châu Âu và Thụy Sĩ. Bộ ba bao gồm các doanh nhân có tay nghề cao với lịch sử nền tảng thành công trong lĩnh vực Forex trực tuyến. Bộ ba có một nguyên tắc rằng khách hàng của chúng tôi đến trước, thứ hai và thứ ba trong danh sách ưu tiên của chúng tôi.",
          },
          {
            q: "Chúng ta làm gì ?",
            a: "Tại TrioMarkets™, điều quan trọng là cung cấp cho bạn công nghệ tuyệt đối tốt nhất, ưu tiên chính của chúng tôi là giúp bạn duy trì kết nối từ mọi nơi, mọi lúc và trên mọi thiết bị. Chúng tôi tin rằng điều bắt buộc là phải coi trọng và hỗ trợ khách hàng của mình ngoài ra để cung cấp môi trường giao dịch tốt nhất giúp mọi người cảm thấy tự tin và hài lòng.Sứ mệnh của chúng tôi là cung cấp thông tin chính xác, có thể truy cập và thu được về Forex. Nền tảng TrioMarkets™ đã được thiết kế độc đáo để phù hợp với mọi cấp độ kinh nghiệm giao dịch cho dù bạn là người mới giao dịch hay đã có kiến thức giao dịch rộng lớn.",
          },
          {
            q: "Tại sao chọn TrioMarkets™ ?",
            a: "Chăm sóc khách hàng là cốt lõi của những gì chúng tôi làm tại TrioMarkets™ và nhu cầu của bạn là ưu tiên hàng đầu của chúng tôi. Nếu bạn gặp phải bất kỳ khó khăn nào về kỹ thuật hoặc tài khoản, bạn có thể dễ dàng liên hệ với nhóm đa ngôn ngữ am hiểu của chúng tôi, họ sẽ sẵn lòng hỗ trợ bạn. Triết lý của chúng tôi là cung cấp cho bạn Giao dịch đáng tin cậy, minh bạch và nhóm của chúng tôi luôn sẵn sàng hỗ trợ bạn. Triết lý thực sự trong kinh doanh là bạn phải cung cấp cho khách hàng giá trị để giữ lòng trung thành của khách hàng và đạt được thành công chung.",
          },
        ],
        ourValues: "Giá trị của chúng tôi",
        threeTs: [
          {
            name: "Đáng tin cậy",
            desc: "Thật an toàn khi nói rằng nền tảng của chúng tôi cam kết đảm bảo một môi trường giao dịch tốt, trung thành với khách hàng của mình bằng cách giới thiệu phần mềm an toàn, chất lượng cao và tận tâm giới thiệu các dịch vụ và sản phẩm tốt nhất.",
            img: "trust",
          },
          {
            name: "Trong suốt",
            desc: "TrioMarkets™ nhằm mục đích cung cấp trải nghiệm minh bạch cho khách hàng và đảm bảo cung cấp một nền tảng an toàn để giao dịch an toàn. Về hỗ trợ khách hàng, bạn sẽ nhận được sự trợ giúp nhiều nhất từ một nhóm hỗ trợ và có kinh nghiệm ngoài việc nhận được câu trả lời cho tất cả các câu hỏi của bạn thông qua một trang web an toàn và minh bạch.",
            img: "trans",
          },
          {
            name: "Thương mại",
            desc: "Giao dịch đã ăn sâu vào máu của chúng tôi kể từ năm 2014, và vì điều đó, chúng tôi đảm bảo mang đến một hành trình giao dịch độc đáo từ đầu đến chân cho tất cả các loại khách hàng và đảm bảo sự hài lòng cũng như lòng trung thành của họ.",
            img: "trade",
          },
        ],
        businessConcept: "Khái niệm kinh doanh",
        textFour:
          "Công ty của chúng tôi được sở hữu và điều hành bởi Trader và cho Traders.<br>Các Cổ đông của chúng tôi đến từ lĩnh vực ngân hàng đầu tư, nơi họ đã trau dồi kỹ năng và chuyên môn của mình tại các trung tâm Tài chính hàng đầu trên thế giới như Geneva và London.<br>Chúng tôi biết bạn cần gì và muốn gì từ một nhà môi giới trực tuyến.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Thanh khoản sâu",
        text: "Chúng tôi có một số nhà cung cấp thanh khoản liên tục cạnh tranh với nhau để đưa ra mức giá tốt nhất. Chúng tôi không chấp nhận bất kỳ sự sai lệch mạnh mẽ nào từ việc định giá từ các địa điểm thực hiện của chúng tôi và ngay lập tức thay đổi chúng nếu cần.",
      },
      {
        img: "2",
        title: "Bảo mật và ổn định",
        text: `Mục tiêu của công ty là thiết lập một mối quan hệ xây dựng trên sự tin tưởng lẫn nhau, Chúng tôi đã có mặt trên thị trường từ năm 2015 và đã trải qua những thay đổi nghiêm ngặt về quy định và biến động kinh tế và chúng tôi ở đây để nói "CHÚNG TÔI ĐANG Ở LẠI"`,
      },
      {
        img: "3",
        title: "Tốc độ thực thi",
        text: "Chúng tôi không ngừng cố gắng để giữ tốc độ khớp lệnh ở mức tối thiểu và liên tục điều chỉnh cơ sở hạ tầng của mình để đảm bảo nó không thay đổi đối với các nhà giao dịch của chúng tôi",
      },
      {
        img: "4",
        title: "Định giá",
        text: "Yêu cầu đã là dĩ vãng, khi công nghệ tiến bộ, sản phẩm của chúng tôi cung cấp cho bạn cũng vậy, vì vậy chúng tôi chỉ cố gắng cung cấp cho bạn mức giá và thực thi tốt nhất trên thị trường.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center"> "Một tài khoản tách biệt được sử dụng để giữ tiền của khách hàng tách biệt với Quỹ của công ty. Điều này được thực hiện vì một số lý do, bao gồm cả việc tạo ra sự tách biệt rõ ràng giữa hai nhóm tiền để không bị sử dụng sai mục đích và cũng để đảm bảo rằng số tiền đó có thể dễ dàng được xác định là thuộc về khách hàng trong trường hợp có bất kỳ điều gì xảy ra với công ty. "</p>
        <p class="text-white text-center">Tất cả khách hàng sử dụng TrioMarkets ™ sẽ có một tài khoản riêng biệt, nhờ đó tiền của bạn được bảo mật và tách biệt với tài khoản của chúng tôi.</p>`,
        textTwo: `<p class="fw-bold fs-5">Tại TrioMarkets ™, tất cả các quỹ được tổ chức đặc biệt trong trụ sở Swissquote ở Thụy Sĩ.</p><p class="text-justify fs-6">Ngân hàng Swissquote là một trong những ngân hàng / công ty kinh doanh trực tuyến uy tín nhất ở Châu Âu. Swissquote có hơn 500 nhân viên và hoạt động tại hơn 7 quốc gia với 10 chi nhánh trên toàn thế giới, tạo ra doanh thu khoảng 150 triệu (CHF) và hiện đang làm việc với hơn 250.000 khách hàng trên toàn thế giới. </p><p class="text-justify fs-6">Là một công ty, TrioMarkets ™ chỉ làm việc với những gì tốt nhất để cung cấp cho khách hàng của chúng tôi dịch vụ tốt nhất có thể. TrioMarkets ™ sẽ không bao giờ sử dụng tiền của khách hàng trong hoạt động của mình hoặc cho bất kỳ khoản đầu tư nào khác, chúng tôi đảm bảo bảo vệ mọi lúc.</p>`,
        textThree: `<p class="fw-bold fs-5">Quỹ bồi thường nhà đầu tư:</p><p class="text-justify fs-6">Quỹ Bồi thường Nhà đầu tư (ICF) được thành lập theo Luật Doanh nghiệp Đầu tư (IF) năm 2002 được sửa đổi (sau đây gọi là Luật ") và Việc thành lập và hoạt động của Quỹ Bồi thường Nhà đầu tư cho khách hàng theo Quy định của ICFs năm 2004 (sau đây gọi là" các Quy định ") đã được ban hành theo Luật.</p><p class="text-justify fs-6">Mục tiêu của ICF là đảm bảo mọi khiếu nại của các khách hàng được bảo hiểm chống lại các thành viên của ICF bằng cách trả tiền bồi thường cho những khách hàng này trong trường hợp công ty không thể do hoàn cảnh tài chính không có khả năng cải thiện trong tương lai gần.</p>`,
        points: [
          "ICF phục vụ để bao trả cho Khách hàng bán lẻ của CIF. Điều này không bao gồm Khách hàng chuyên nghiệp và Đối tác đủ điều kiện.",
          "ICF phục vụ để bao trả cho Khách hàng bán lẻ của CIF. Điều này không bao gồm Khách hàng chuyên nghiệp và Đối tác đủ điều kiện.",
        ],
        textFour: `Do chính sách này, với tư cách là khách hàng của TrioMarkets ™, bạn có thể cảm thấy tự tin khi giao dịch rằng tất cả các giao dịch của bạn sẽ được hoàn thành ở mức độ bảo mật, tính toàn vẹn và minh bạch cao nhất.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd </strong> được đăng ký là Đại lý Đầu tư (Nhà môi giới) với số đăng ký 160496. Được cấp phép bởi Ủy ban Dịch vụ Tài chính (Mauritius) theo giấy phép số <a  target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> và có giấy phép thực hiện các hoạt động dưới tên giao dịch TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Chúng tôi được phép giao dịch quốc tế, chuyên giao dịch trực tuyến CFD về tiền tệ, hàng hóa, chỉ số, cổ phiếu và hợp đồng kỳ hạn. Chúng tôi cam kết tuân thủ tối đa các tiêu chuẩn kinh doanh và coi sự cống hiến cho đạo đức là nền tảng trong triết lý của chúng tôi.</p>`,
        eu: `<p class="text-justify fs-6"><strong>TrioMarkets™</strong> là tên giao dịch được sở hữu và điều hành bởi <strong>EDR Financial Ltd</strong> và được đăng ký là Công ty Đầu tư Síp (CIF) với số đăng ký <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0">Nhà môi giới ngoại hối <strong>ECN / STP</strong> này được quản lý bởi Ủy ban Chứng khoán và Giao dịch Síp <strong>(CySEC)</strong> theo giấy phép số <a  target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, thuộc phạm vi của <strong>Chỉ thị II về Thị trường trong Công cụ Tài chính (MIFID II)</strong>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> là văn phòng đại diện / Chi nhánh của Benor Capital Ltd. (Mauritius) đặt tại Các Tiểu vương quốc Ả Rập Thống nhất - Dubai theo số Giấy phép DED<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Văn phòng đã đăng ký: The Bayswater Tower, Office 1212, Marasi Drive, Business Bay, Dubai, United Arab Emirates và có giấy phép thực hiện các hoạt động tiếp thị và thương mại dưới tên giao dịch TrioMarkets&trade;.`,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "Các liên kết quan trọng",
        importantLinks: [
          "Quỹ bồi thường nhà đầu tư",
          "Chính sách bảo mật",
          "Chính sách xung đột lợi ích",
        ],
      },
    ],
    contactInfo: [
      {
        text: `Có một câu hỏi hoặc yêu cầu chuyên gia hỗ trợ?<br>Hãy liên hệ với đội ngũ dịch vụ khách hàng tận tâm của chúng tôi, những người luôn sẵn sàng hỗ trợ bạn 24/5!`,
        points: [
          {
            img: "phone",
            name: "Hỗ trợ qua điện thoại",
            text: license == "global" ? "+44 20 376 936 49" : "+357 22 222737",
            link: "",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "Hỗ trợ email",
            text: "Gửi email cho chúng tôi",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "Hỗ trợ trò chuyện",
            text: "Bắt đầu trò chuyện trực tiếp",
            link: "",
          },
        ],
        pointsTwo: [
          { img: "en", name: "Bàn tiếng Anh" },
          { img: "fr", name: "Bàn tiếng Pháp" },
          { img: "it", name: "Bàn Ý" },
          { img: "de", name: "Bàn tiếng Đức" },
          { img: "uae", name: "Bàn tiếng Ả Rập" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Văn phòng của bạn nằm ở đâu?",
            a:
              license == "global"
                ? "Trụ sở chính của chúng tôi có trụ sở ở Mauritius. "
                : "Trụ sở chính của chúng tôi có trụ sở tại Limassol, Síp. ",
          },
          {
            open: false,
            q: "Bạn là loại nhà môi giới nào?",
            a: "TrioMarkets ™ là một ECN / STP Nhà môi giới ngoại hối. ",
          },
          {
            open: false,
            q: "Bạn nắm giữ quy định nào?",
            a:
              license == "global"
                ? "TrioMarkets ™ được quản lý bởi Ủy ban Dịch vụ Tài chính (FSC) Mauritius, theo số giấy phép C118023678. "
                : "TrioMarkets ™ được quản lý bởi Ủy ban Chứng khoán và Giao dịch Síp (CySEC) theo giấy phép số 268/15, thuộc phạm vi điều chỉnh của Chỉ thị II về Thị trường trong Công cụ Tài chính (MIFID II).",
          },
        ],
      },
      {
        faqSection: 2,
        title: "Nạp tiền và Rút tiền",
        faqContent: [
          {
            open: false,
            q: "Làm cách nào để gửi tiền?",
            a:
              license == "global"
                ? "Chúng tôi chấp nhận nhiều loại phương thức gửi tiền để nạp tiền vào tài khoản giao dịch của bạn. Bạn có thể sử dụng ngân hàng để chuyển khoản ngân hàng, thẻ tín dụng và tiền gửi Netteller. "
                : "Chúng tôi chấp nhận nhiều loại phương thức gửi tiền để nạp tiền vào tài khoản giao dịch của bạn. Bạn có thể sử dụng ngân hàng để chuyển khoản ngân hàng, thẻ tín dụng, Skrill và tiền gửi Netteller. ",
          },
          {
            open: false,
            q: "Số tiền gửi tối thiểu là bao nhiêu?",
            a: "Để mở tài khoản của bạn tại TrioMarkets™, bạn phải nạp tiền vào tài khoản bằng $/€ 100. ",
          },
          {
            open: false,
            q: "Tôi có thể nạp tiền vào tài khoản trong một tiền tệ khác với tiền tệ cơ sở? ",
            a: "Đơn vị tiền tệ mà bạn mở tài khoản của bạn bằng đơn vị tiền tệ mà bạn sẽ ký gửi. Do đó, nếu bạn có tài khoản USD, khoản ký gửi (nếu được thực hiện bằng đơn vị tiền tệ khác) sẽ tự động được chuyển đổi sang USD. ",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Tài khoản của chúng tôi",
        faqContent: [
          {
            open: false,
            q: "Làm cách nào để rút tiền?",
            a: "Chúng tôi thanh toán qua phương thức được sử dụng để nạp tiền vào tài khoản của bạn. Tất cả các lần rút tiền sẽ phải chịu một khoản phí là 1,5% số tiền rút. ",
          },
          {
            open: false,
            q: "Làm cách nào để đóng tài khoản của tôi?",
            a: "Để đóng tài khoản của bạn, vui lòng liên hệ trực tiếp với người quản lý tài khoản của bạn. ",
          },
          {
            open: false,
            q: "Mất bao lâu để xác minh tài khoản của tôi? ",
            a: "Tùy thuộc vào tài liệu được gửi, nếu tất cả đều hợp lệ và được chấp thuận, tài khoản của bạn sẽ được văn phòng hỗ trợ của chúng tôi xác minh ngay lập tức. Sau khi xác minh và khoản tiền gửi đã được thực hiện, tài khoản của bạn sẽ được kích hoạt để giao dịch. ",
          },
          {
            open: false,
            q: "Loại tài liệu nào cần xác minh tài khoản giao dịch của tôi không? ",
            a: `Chúng tôi yêu cầu Bằng chứng Nhận dạng: một bản sao có màu rõ ràng của một trong những thứ sau: 
              <ul>
                  <li>Hộ chiếu</li>
                  <li>Chứng minh nhân dân</li>
              </ul>
              Bằng chứng cư trú:
              <ul>
                  <li>Bảng sao kê ngân hàng hoặc ngân hàng thư giới thiệu</li>
                  <li>Hóa đơn điện nước</li>
                  <li>Bất kỳ tài liệu nào xác nhận địa chỉ thường trú được xác minh qua chính quyền địa phương (hóa đơn thuế, hóa đơn đô thị)</li>
                  <li>Tất cả các tài liệu xác nhận địa chỉ cư trú phải được cấp trong vòng 6 tháng qua và xác nhận địa chỉ đã cung cấp trong đơn.</li>
              </ul>`,
          },
          {
            open: false,
            q: "Làm cách nào để đăng ký một tài khoản chung?",
            a: "Cả hai chủ tài khoản chung sẽ mở một tài khoản giao dịch với TrioMarkets và nộp các tài liệu được yêu cầu cùng với mẫu đơn đăng ký tài khoản chung. Tài khoản chung chỉ có thể được mở giữa những người thân cấp 1 (yêu cầu bằng chứng trong trường hợp họ khác nhau) hoặc trong trường hợp cả 2 khách hàng là chủ tài khoản ngân hàng chung (cần có bằng chứng). ",
          },
          {
            open: false,
            q: "Loại tài khoản nào phù hợp với tôi?",
            a: `Tùy thuộc vào mức độ của kinh nghiệm bạn có sẽ xác định tài khoản nào là phù hợp nhất cho nhu cầu của bạn. Xem các loại tài khoản của chúng tôi để chọn. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">Bấm vào đây</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "Chung",
        faqContent: [
          {
            open: false,
            q: "Tôi có thể kinh doanh những sản phẩm giao dịch nào?",
            a: `Tại TrioMarkets ™; Chúng tôi cung cấp nhiều loại tài sản khác nhau. Từ các cặp Forex yêu thích của bạn như EUR / USD, GBP / USD đến hàng hóa và CFD giao dịch các chỉ số yêu thích của bạn như Dow Jones, S&P 500 và FTSE. <br> Đối với tất cả các loại tài sản của chúng tôi, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">bấm vào đây.</a> Để gửi tiền vào tài khoản giao dịch, vui lòng nhấp <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login"> vào đây.</a>`,
          },
          {
            open: false,
            q: "Làm cách nào để giao dịch?",
            a: "Đây tại TrioMarkets ™; Bạn có thể giao dịch tất cả các loại tài sản của chúng tôi thông qua nền tảng Meta Trader 4 (MT4) hiện đại của chúng tôi. Chúng tôi cung cấp cho khách hàng vô số thông tin giao dịch từ các báo giá thời gian thực đến biểu đồ, phân tích giao dịch và hơn thế nữa. ",
          },
          {
            open: false,
            q: "Forex là gì?",
            a: "Forex, còn được gọi là Ngoại hối là một thị trường toàn cầu để giao dịch các cặp tiền tệ. Ban đầu nó liên quan đến việc mua hoặc bán một loại tiền tệ này so với một loại tiền tệ khác, tức là EUR / USD (mua EUR để tăng giá trị so với USD).",
          },
        ],
      },
    ],
  },
  enUae: {
    moreInfo: "More Info",
    meetUsInfo: [
      {
        aboutUs: "About Us",
        whoWeAre: [
          {
            q: "Who We Are ?",
            a: "At TrioMarkets™, we believe there is a more authentic way to trade the financial markets. We provide Trustworthy, Transparent Trading to grant the best possible experience for our clients. Our company was established by three financial professionals with over 20 years' experience in both the European and Swiss Financial Market. This Trio are highly skilled and efficacious entrepreneurs with a successful background history in online Forex. The Trio has a principle that our customers come first, second, and third on our priority list.",
          },
          {
            q: "What We Do ?",
            a: "Here at TrioMarkets™ it is vital to provide you with the absolute best in technology, our major priority is to help you stay connected from anywhere at any time and on any device.We believe it is imperative to value and support our clients in addition to providing the best trading environment to help everyone feel confident and satisfied. Our mission is to provide accurate, accessible, and obtainable information about Forex. TrioMarkets™ platform  has been uniquely designed to suit every level of trading experience whether you are new to trading or have a vast trading background.",
          },
          {
            q: "Why Choose TrioMarkets™ ?",
            a: "Customer care is at the core of what we do here at TrioMarkets™ and your needs are our top priority. If you happen to experience any technical or account difficulties, you can easily get in touch with our knowledgeable multilingual team who will be happy to assist you. Our philosophy is to provide you with Trustworthy, Transparent Trading and our team is in place to always assist you. The true philosophy in business is that you must offer customers value in order to retain customer loyalty and achieve mutual success.",
          },
        ],
        ourValues: "Our Values",
        threeTs: [
          {
            name: "Trustworthy",
            desc: "It’s safe to say that our platform is committed to ensuring a good trading environment, faithful to its clients by presenting highly qualified, secure software, and devoted to presenting the best services and products.",
            img: "trust",
          },
          {
            name: "Transparent",
            desc: "TrioMarkets™ aims to give a transparent experience to the clients and guarantees to grant a secure platform for safe trading. In terms of customer support, you’ll get the most help from an experienced and supportive team in addition to getting all your questions answered through a secure and transparent website.",
            img: "trans",
          },
          {
            name: "Trading",
            desc: "Trading runs in our blood since 2014, and for that, we ensure to give a head-to-toe unique trading journey for all kinds of customers and guarantees their satisfaction and loyalty.",
            img: "trade",
          },
        ],
        businessConcept: "Business Concept",
        textFour:
          "Our Company is owned and operated by Traders for Traders. <br> Our Shareholders come from investment banking where they honed their skills and expertise in top Financial hubs of world such as Geneva and London.<br>We know what you need and what you want from an online broker.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Deep Liquidity",
        text: "We have several liquidity providers whom constantly are competing between one another to provide the best pricing. We don't tolerate any drastic deviation from pricing from our execution venues and immediately change them if needed.",
      },
      {
        img: "2",
        title: "Security and Stability",
        text: `Goal of the company is to establish a relationship build on mutual trust, We have been in the market since 2015 and went through rigorous regulatory changes and economical upheaval  and we are here to say " WE ARE STAYING ".`,
      },
      {
        img: "3",
        title: "Execution Speed",
        text: "We are constantly on our toes to keep our execution speed to a minimum and are constantly adjusting our infrastructure to make sure it does not change for our traders.",
      },
      {
        img: "4",
        title: "Pricing",
        text: "Requotes is a thing of the past, as technology advances so does our product offering to you, so we try to give you only the best, market execution and pricing.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"A segregated account is used to keep the customers' funds separate from the Company Funds. This is done for a few reasons, including creating a clear separation between the two groups of money so neither is used for the wrong purpose, and also to make sure that the money can be easily identified as belonging to customers in case anything happens to the firm."</p>
        <p class="text-white text-center">All clients with TrioMarkets™ will have a segregated account whereby your funds are secure and kept separate from our own.</p>`,
        textTwo: `<p class="fw-bold fs-5">At TrioMarkets™ all funds are held specifically within the Swissquote headquarters in Switzerland.</p><p class="text-justify fs-6">Swissquote Bank is one of the most reputable online trading firms/banks in Europe. Swissquote has over 500 employees and operates in over 7 countries with 10 branches worldwide, generating a revenue of around 150 million (CHF) and currently works with over 250,000 clients worldwide.</p><p class="text-justify fs-6">As a company TrioMarkets™ only works with the best in order to provide our clients with the best service possible. TrioMarkets™ will never use clients' funds in its operation or for any other investment, we ensure protection at all times.</p>`,
        textThree: `<p class="fw-bold fs-5">Investor Compensation Fund:</p><p class="text-justify fs-6">The Investor Compensation Fund (ICF) was established under the Investment Firms (IF) Law 2002 as amended (hereinafter called “the Law”) and the Establishment and Operation of an Investor Compensation Fund for customers of ICFs Regulations of 2004 (hereinafter called “the Regulations”) which were issued under the Law.</p><p class="text-justify fs-6">The objective of the ICF is to secure any claims of covered clients against members of the ICF by paying these clients compensation in the case that the company is unable due to financial circumstances that are not likely to be improved in the near future.</p>`,
        points: [
          "The ICF serves to cover Retail Clients of CIFs. This excludes Professional Clients and Eligible Counterparties.",
          "Covered Clients of an ICF's member company can receive payable compensation regardless of the number of accounts held, currency and place of offering the investment service.",
        ],
        textFour: `Due to this policy, as a client with TrioMarkets™, you can feel confident when trading that all of your trades will be completed under the highest level of security, integrity and transparency.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> is registered as an Investment Dealer (Broker) with registration number 160496. Licensed by the Financial Services Commission (Mauritius) under license number <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> and has the license to perform operations under the trading name TrioMarkets™.</p><p class="text-justify fs-6 mb-0">We are authorized to trade internationally, specializing in CFD online trading on currencies, commodities, indices, stocks and forward contracts. We are committed to the utmost in business standards and consider our dedication to ethics a cornerstone of our philosophy.</p>`,
        eu: `<strong>TrioMarkets™</strong> is a trading name 
        owned and operated by <strong>EDR Financial Ltd</strong> and is registered as a Cyprus Investment Firm (CIF) with the registration number <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0">This <strong>ECN/STP</strong> Forex broker is regulated by the Cyprus Securities and Exchange commission <strong>(CySEC)</strong> under the license number <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, which falls under the umbrella of the <strong>Markets in Financial Instruments Directive II (MIFID II)</strong>.</p>`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> is a representative office/Branch of Benor Capital Ltd. (Mauritius) located in the United Arab Emirates - Dubai under the DED License number<a target="_blank"> <strong class="text-primary">1082819</strong></a>. Registered Office: The Bayswater Tower , Office 1212, Marasi Drive, Business Bay, Dubai, United Arab Emirates and has the license to perform marketing and commercial activities under the trading name TrioMarkets&trade;. `,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "Important Links",
        importantLinks: [
          "Investor Compensation Fund",
          "Privacy Policy",
          "Conflicts of Interest Policy",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs",
      },
    ],
    contactInfo: [
      {
        text: `Have a question or require specialist assistance? <br />
        Contact our dedicated customer service team who are available
        24/5 to assist you!`,
        points: [
          {
            img: "phone",
            name: "Phone Support",
            text: license == "global" ? "+44 20 376 936 49" : "+357 22 222737",
            link: "",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "Email Support",
            text: "Email us",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "Chat Support",
            text: "Start Live Chat",
            link: "",
          },
        ],
        pointsTwo: [
          { img: "en", name: "English Desk" },
          { img: "fr", name: "French Desk" },
          { img: "it", name: "Italian Desk" },
          { img: "de", name: "German Desk" },
          { img: "uae", name: "Arabic Desk" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Where are your offices located?",
            a:
              license == "global"
                ? "Our headquarters are based in Mauritius."
                : "Our headquarters are based in Limassol, Cyprus.",
          },
          {
            open: false,
            q: "What type of broker are you?",
            a: "TrioMarkets™ is an ECN/STP Forex broker.",
          },
          {
            open: false,
            q: "What regulation do you hold?",
            a:
              license == "global"
                ? "TrioMarkets ™ is regulated by the Financial Services Commission (FSC) Mauritius, under the license number C118023678."
                : `"TrioMarkets ™ is regulated by the Cyprus Securities and Exchange Commission (CySEC) under the license number 268/15 which falls under the umbrella of the Markets in Financial Instruments Directive II (MIFID II)."`,
          },
        ],
      },
      {
        faqSection: 2,
        title: "Funding and Withdrawals",
        faqContent: [
          {
            open: false,
            q: "How do I deposit money?",
            a:
              license == "global"
                ? "We accept a wide range of deposit methods to fund your trading account. You can use bank to bank wire transfer, credit card, and Netteller deposits."
                : "We accept a wide range of deposit methods to fund your trading account. You can use bank to bank wire transfer, credit card, Skrill, and Netteller deposits.",
          },
          {
            open: false,
            q: "What is the minimum deposit?",
            a: "To open your account at Trio Markets™, you must fund the account with $/€100.",
          },
          {
            open: false,
            q: "Can I fund an account in a different currency than the base currency?",
            a: "The currency in which you open your account with is the currency your deposit will be made in. Therefore, if you have a USD account the deposit (if made with another currency) will automatically be converted to USD.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Our Accounts",
        faqContent: [
          {
            open: false,
            q: "How do I withdraw money?",
            a: "We pay via the method used to fund your account. All withdrawals will incur a fee of 1.5% of the withdrawal amount.",
          },
          {
            open: false,
            q: "How do I close my account?",
            a: "To close your account please contact your account manager directly.",
          },
          {
            open: false,
            q: "How long does it take to verify my account?",
            a: "Depending on the documentation submitted, if all is valid and approved your account will be verified immediately by our back office. Once verified and the deposit has been made, your account will be activated for trading.",
          },
          {
            open: false,
            q: "What kind of documents are needed to verify my trading account?",
            a: `We require Proof of Identity: a clear colored copy of one of the following:
              <ul>
                  <li>Passport</li>
                  <li>Identity card</li>
              </ul>
              Proof of Residence:
              <ul>
                  <li>Bank statement or bank reference letter</li>
                  <li>Utility bill</li>
                  <li>Any document confirming the permanent address which is verified via local authority (tax bill, municipality bill)</li>
                  <li>All documents confirming the residential address must have been issued within the last 6 months and confirm the address provided in the application.</li>
              </ul>`,
          },
          {
            open: false,
            q: "How do I apply for a joint account?",
            a: "Both joint account holders shall open a trading account with TrioMarkets and submit the required documents with the joint account application form. Joint account can be opened between 1st degree relatives only (proof required in case the last names are different) or in case the 2 clients are joint bank account holders (proof required).",
          },
          {
            open: false,
            q: "What type of account is suitable for me?",
            a: `Depending on the level of experience you have will determine which account is best suitable for your needs. See our account types to choose. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">Click here.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "General",
        faqContent: [
          {
            open: false,
            q: "Which trading products can I trade?",
            a: `At TrioMarkets™; We offer a wide range of asset classes. From your favorite Forex pairs like the EUR/USD, GBP/USD to commodities and CFDs trading your favorite indices like the Dow Jones, S&P 500 and the FTSE. <br> For all of our asset classes, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">click here.</a> To deposit money into your trading account, please <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login">click here.</a>`,
          },
          {
            open: false,
            q: "How do I trade?",
            a: "Here at TrioMarkets™; You can trade all of our asset classes through our state of the art Meta Trader 4 (MT4) platform. We offer our clients a wealth of trading information form real time quotes to charts, trading analysis and more.",
          },
          {
            open: false,
            q: "What is forex?",
            a: "Forex, also known as Foreign exchange is a global market for the trading of currency pairs. Initially it involves the buying or selling of one currency against another, i.e. EUR/USD (buying the EUR to increase in value against the USD).",
          },
        ],
      },
    ],
  },
  arb: {
    moreInfo: "مزيد من المعلومات",
    meetUsInfo: [
      {
        aboutUs: "معلومات عنا",
        whoWeAre: [
          {
            q: "من نحن ؟",
            a: "في TrioMarkets ™ ، نعتقد أن هناك طريقة أكثر واقعية لتداول الأسواق المالية. نحن نقدم تداولًا جديرًا بالثقة وشفافًا لمنح عملائنا أفضل تجربة ممكنة. تم تأسيس شركتنا من قبل ثلاثة متخصصين ماليين يتمتعون بخبرة تزيد عن 20 عامًا في السوق المالية الأوروبية والسويسرية . هذا الثلاثي هم رواد أعمال يتمتعون بمهارات عالية وفاعلية ولديهم خلفية تاريخية ناجحة في الفوركس عبر الإنترنت. لدى الثلاثي مبدأ أن عملائنا يأتي في المرتبة الأولى والثانية والثالثة في قائمة أولوياتنا.",
          },
          {
            q: "ما نفعله ؟",
            a: "هنا في TrioMarkets ™ من الضروري تزويدك بأفضل التقنيات على الإطلاق ، وأولويتنا الرئيسية هي مساعدتك على البقاء على اتصال من أي مكان وفي أي وقت وعلى أي جهاز. نعتقد أنه من الضروري تقدير ودعم عملائنا بالإضافة إلى توفير أفضل بيئة تداول لمساعدة الجميع على الشعور بالثقة والرضا. مهمتنا هي توفير معلومات دقيقة ويمكن الوصول إليها ويمكن الحصول عليها عن الفوركس.s تم تصميم منصة TrioMarkets ™ بشكل فريد لتناسب كل مستوى من مستويات الخبرة في التداول سواء كنت جديدًا في التداول أو لديك خلفية تداول واسعة.",
          },
          {
            q: "لماذا عليك اختيار TrioMarkets ™؟",
            a: "تعتبر رعاية العملاء من صميم ما نقوم به هنا في TrioMarkets ™ واحتياجاتك هي أولويتنا القصوى. إذا صادفتك أي صعوبات تقنية أو متعلقة بالحساب ، يمكنك بسهولة الاتصال بفريقنا متعدد اللغات واسع المعرفة والذي سيكون سعيدًا بمساعدتك. فلسفتنا هي أن نقدم لك تداولًا جديرًا بالثقة وشفافًا ، وفريقنا جاهز لمساعدتك دائمًا. الفلسفة الحقيقية في العمل هي أنه يجب عليك تقديم قيمة للعملاء من أجل الحفاظ على ولاء العملاء وتحقيق النجاح المتبادل.",
          },
        ],
        ourValues: "قيمنا",
        threeTs: [
          {
            name: "جديرين بالثقة:",
            desc: "من الآمن أن نقول إن منصتنا ملتزمة بضمان بيئة تجارية جيدة ، وفية لعملائها من خلال تقديم برامج مؤهلة وآمنة للغاية ، ومكرسة لتقديم أفضل الخدمات والمنتجات.",
            img: "trust",
          },
          {
            name: "الشفافية:",
            desc: "تهدف TrioMarkets ™ إلى تقديم تجربة شفافة للعملاء وضمانات لمنح منصة آمنة للتداول الآمن. فيما يتعلق بدعم العملاء ، ستحصل على أكبر قدر من المساعدة من فريق داعم وذو خبرة بالإضافة إلى الإجابة على جميع أسئلتك من خلال موقع ويب آمن وشفاف.",
            img: "trans",
          },
          {
            name: "تجارة:",
            desc: "يجري التداول في دمائنا منذ عام 2014 ، ولهذا ، فإننا نضمن توفير رحلة تداول فريدة وجهاً لوجه لجميع أنواع العملاء ونضمن رضاهم وولائهم.",
            img: "trade",
          },
        ],
        businessConcept: "مفهوم الأعمال",
        textFour:
          "شركتنا مملوكة ومدارة من قبل Traders for Traders. <br> يأتي مساهمونا من الخدمات المصرفية الاستثمارية حيث صقلوا مهاراتهم وخبراتهم في أهم المراكز المالية في العالم مثل جنيف ولندن.<br>نحن نعلم ما تحتاجه وما تريده من وسيط عبر الإنترنت.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "سيولة عميقة",
        text: "لدينا العديد من مزودي السيولة الذين يتنافسون باستمرار مع بعضهم البعض لتقديم أفضل الأسعار. نحن لا نتسامح مع أي انحراف كبير عن التسعير عن أماكن التنفيذ لدينا ونقوم بتغييرها فورًا إذا لزم الأمر.",
      },
      {
        img: "2",
        title: "الأمن والاستقرار",
        text: `هدف الشركة هو إقامة علاقة مبنية على الثقة المتبادلة ، لقد كنا في السوق منذ عام 2015 وخضعنا لتغييرات تنظيمية صارمة واضطرابات اقتصادية ونحن هنا لنقول "نحن باقون"`,
      },
      {
        img: "3",
        title: "سرعة التنفيذ",
        text: "نحن دائمًا على أهبة الاستعداد للحفاظ على سرعة التنفيذ لدينا إلى الحد الأدنى ونقوم باستمرار بتعديل بنيتنا التحتية للتأكد من أنها لا تتغير لمتداولينا",
      },
      {
        img: "4",
        title: "التسعير",
        text: "تعد إعادة التسعير شيئًا من الماضي ، حيث تقدم التكنولوجيا تقدم منتجنا لك ، لذلك نحاول أن نقدم لك الأفضل ، وتنفيذ السوق والأسعار.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"يتم استخدام الحساب المنفصل للاحتفاظ بأموال العملاء منفصلة عن أموال الشركة. ويتم ذلك لعدة أسباب ، بما في ذلك إنشاء فصل واضح بين مجموعتي الأموال بحيث لا يتم استخدام أي منهما لغرض خاطئ ، وأيضًا لإجراء التأكد من إمكانية تحديد الأموال بسهولة على أنها تخص العملاء في حالة حدوث أي شيء للشركة ".</p>
        <p class="text-white text-center">سيكون لدى جميع العملاء مع TrioMarkets™ حساب منفصل حيث تكون أموالك آمنة ويتم الاحتفاظ بها منفصلة عن حساباتنا.</p>`,
        textTwo: `<p class="fw-bold fs-5">في TrioMarkets ™ ، يتم الاحتفاظ بجميع الأموال على وجه التحديد داخل المقر الرئيسي لشركة Swissquote في سويسرا.</p><p class="text-justify fs-6">يعد Swissquote Bank أحد أكثر البنوك / شركات التداول عبر الإنترنت شهرة في أوروبا. Swissquote لديها أكثر من 500 موظف وتعمل في أكثر من 7 دول مع 10 فروع في جميع أنحاء العالم ، وتدر إيرادات تبلغ حوالي 150 مليون فرنك سويسري وتعمل حاليًا مع أكثر من 250.000 عميل حول العالم.</p><p class="text-justify fs-6">كشركة ، تعمل TrioMarkets ™ فقط مع الأفضل من أجل تزويد عملائنا بأفضل خدمة ممكنة. لن تستخدم TrioMarkets ™ أموال العملاء مطلقًا في عملياتها أو لأي استثمار آخر ، فنحن نضمن الحماية في جميع الأوقات.</p>`,
        textThree: `<p class="fw-bold fs-5">صندوق تعويض المستثمر::</p><p class="text-justify fs-6">تم إنشاء صندوق تعويض المستثمر (ICF) بموجب قانون شركات الاستثمار (IF) لعام 2002 بصيغته المعدلة (المشار إليه فيما يلي باسم "القانون") وإنشاء وتشغيل صندوق تعويض المستثمرين لعملاء لوائح ICFs لعام 2004 (يُشار إليها فيما يلي باسم " اللوائح ") التي صدرت بموجب القانون.</p><p class="text-justify fs-6">الهدف من ICF هو تأمين أي مطالبات للعملاء المشمولين ضد أعضاء ICF من خلال دفع تعويضات لهؤلاء العملاء في حالة عدم قدرة الشركة بسبب الظروف المالية التي لا يحتمل أن تتحسن في المستقبل القريب.</p>`,
        points: [
          "يخدم ICF لتغطية عملاء التجزئة من CIFs. هذا لا يشمل العملاء المحترفين والأطراف المؤهلة.",
          "يمكن للعملاء المشمولين بشركة عضو في ICF الحصول على تعويض مستحق الدفع بغض النظر عن عدد الحسابات المحتفظ بها والعملة ومكان تقديم خدمة الاستثمار.",
        ],
        textFour: `نتيجة لهذه السياسة ، كعميل لدى TrioMarkets™ ، يمكنك أن تشعر بالثقة عند التداول من أن جميع صفقاتك ستكتمل في ظل أعلى مستوى من الأمان والنزاهة والشفافية.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> مسجلة بصفتها تاجر استثمار (وسيط) برقم تسجيل 160496. مرخصة من قبل لجنة الخدمات المالية (موريشيوس) بموجب ترخيص رقم  <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> ولديها ترخيص لإجراء العمليات تحت الاسم التجاري TrioMarkets™ ️.
        </p><p class="text-justify fs-6 mb-0">مصرح لنا بالتداول دوليًا ، متخصصون في تداول العقود مقابل الفروقات عبر الإنترنت على العملات والسلع والمؤشرات والأسهم والعقود الآجلة. نحن ملتزمون بأقصى معايير العمل ونعتبر تفانينا في الأخلاق حجر الزاوية في فلسفتنا.</p>`,
        eu: `<strong>TrioMarkets™</strong> هو اسم تجاري تملكه وتديره <strong>EDR Financial Ltd</strong> ومسجلة كشركة استثمار قبرصية (CIF) برقم التسجيل <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0"> يخضع وسيط الفوركس <strong>ECN / STP</strong> للتنظيم من قبل لجنة الأوراق المالية والبورصة القبرصية <strong>(CySEC)</strong> بموجب ترخيص رقم <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"><strong class="text-primary">268/15</strong></a> ، والذي يندرج تحت مظلة توجيه الأسواق في الأدوات المالية II (MIFID II).</p>`,
        dubai: `<strong>Benor Capital Ltd- Rep. Office</strong> هو مكتب تمثيلي / فرع لشركة Benor Capital Ltd. (موريشيوس) يقع في الإمارات العربية المتحدة - دبي بموجب ترخيص دائرة التنمية الاقتصادية رقم <a target="_blank"> <strong class="text-primary">1082819</strong></a>. المكتب المسجل: برج بايزووتر ، مكتب 1212 ، مراسي درايف ، الأعمال باي ، دبي ، الإمارات العربية المتحدة ولديها ترخيص لأداء الأنشطة التسويقية والتجارية تحت الاسم التجاري TrioMarkets™.`,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        title: "روابط مهمة",
        importantLinks: [
          "صندوق تعويض المستثمر",
          "سياسة الخصوصية",
          "سياسة تضارب المصالح",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs",
      },
    ],
    contactInfo: [
      {
        text: `هل لديك سؤال أو تحتاج إلى مساعدة متخصصة؟`,
        points: [
          // {
          //   img: "phone",
          //   name: "الدعم عبر الهاتف",
          //   text: license == "global" ? "+44 20 376 936 49" : "+357 22 222737",
          //   link: "",
          //   fax: "FAX: +357 25 250310",
          // },
          {
            img: "mail",
            name: "دعم البريد الإلكتروني",
            text: "ارسل لنا عبر البريد الإلكتروني",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "الدعم عبر الدردشة",
            text: "ابدأ الدردشة الحية",
            link: "",
          },
        ],
        pointsTwo: [
          { img: "en", name: "مكتب اللغة الإنجليزية" },
          { img: "fr", name: "المكتب الفرنسي" },
          { img: "it", name: "المكتب الإيطالي" },
          { img: "de", name: "المكتب الألماني" },
          { img: "uae", name: "المكتب العربي" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Where are your offices located?",
            a:
              license == "global"
                ? "Our headquarters are based in Mauritius."
                : "Our headquarters are based in Limassol, Cyprus.",
          },
          {
            open: false,
            q: "What type of broker are you?",
            a: "TrioMarkets™ is an ECN/STP Forex broker.",
          },
          {
            open: false,
            q: "What regulation do you hold?",
            a:
              license == "global"
                ? "TrioMarkets ™ is regulated by the Financial Services Commission (FSC) Mauritius, under the license number C118023678."
                : `"TrioMarkets ™ is regulated by the Cyprus Securities and Exchange Commission (CySEC) under the license number 268/15 which falls under the umbrella of the Markets in Financial Instruments Directive II (MIFID II)."`,
          },
        ],
      },
      {
        faqSection: 2,
        title: "Funding and Withdrawals",
        faqContent: [
          {
            open: false,
            q: "How do I deposit money?",
            a:
              license == "global"
                ? "We accept a wide range of deposit methods to fund your trading account. You can use bank to bank wire transfer, credit card, and Netteller deposits."
                : "We accept a wide range of deposit methods to fund your trading account. You can use bank to bank wire transfer, credit card, Skrill, and Netteller deposits.",
          },
          {
            open: false,
            q: "What is the minimum deposit?",
            a: "To open your account at Trio Markets™, you must fund the account with $/€100.",
          },
          {
            open: false,
            q: "Can I fund an account in a different currency than the base currency?",
            a: "The currency in which you open your account with is the currency your deposit will be made in. Therefore, if you have a USD account the deposit (if made with another currency) will automatically be converted to USD.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Our Accounts",
        faqContent: [
          {
            open: false,
            q: "How do I withdraw money?",
            a: "We pay via the method used to fund your account. All withdrawals will incur a fee of 1.5% of the withdrawal amount.",
          },
          {
            open: false,
            q: "How do I close my account?",
            a: "To close your account please contact your account manager directly.",
          },
          {
            open: false,
            q: "How long does it take to verify my account?",
            a: "Depending on the documentation submitted, if all is valid and approved your account will be verified immediately by our back office. Once verified and the deposit has been made, your account will be activated for trading.",
          },
          {
            open: false,
            q: "What kind of documents are needed to verify my trading account?",
            a: `We require Proof of Identity: a clear colored copy of one of the following:
              <ul>
                  <li>Passport</li>
                  <li>Identity card</li>
              </ul>
              Proof of Residence:
              <ul>
                  <li>Bank statement or bank reference letter</li>
                  <li>Utility bill</li>
                  <li>Any document confirming the permanent address which is verified via local authority (tax bill, municipality bill)</li>
                  <li>All documents confirming the residential address must have been issued within the last 6 months and confirm the address provided in the application.</li>
              </ul>`,
          },
          {
            open: false,
            q: "How do I apply for a joint account?",
            a: "Both joint account holders shall open a trading account with TrioMarkets and submit the required documents with the joint account application form. Joint account can be opened between 1st degree relatives only (proof required in case the last names are different) or in case the 2 clients are joint bank account holders (proof required).",
          },
          {
            open: false,
            q: "What type of account is suitable for me?",
            a: `Depending on the level of experience you have will determine which account is best suitable for your needs. See our account types to choose. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">Click here.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "General",
        faqContent: [
          {
            open: false,
            q: "Which trading products can I trade?",
            a: `At TrioMarkets™; We offer a wide range of asset classes. From your favorite Forex pairs like the EUR/USD, GBP/USD to commodities and CFDs trading your favorite indices like the Dow Jones, S&P 500 and the FTSE. <br> For all of our asset classes, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">click here.</a> To deposit money into your trading account, please <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login">click here.</a>`,
          },
          {
            open: false,
            q: "How do I trade?",
            a: "Here at TrioMarkets™; You can trade all of our asset classes through our state of the art Meta Trader 4 (MT4) platform. We offer our clients a wealth of trading information form real time quotes to charts, trading analysis and more.",
          },
          {
            open: false,
            q: "What is forex?",
            a: "Forex, also known as Foreign exchange is a global market for the trading of currency pairs. Initially it involves the buying or selling of one currency against another, i.e. EUR/USD (buying the EUR to increase in value against the USD).",
          },
        ],
      },
    ],
  },
  my: {
    moreInfo: "More Info",
    meetUsInfo: [
      {
        team: "Pasukan kami",
        history: "Sejarah korporat",
        timeline: [
          [
            { date: "Sep 2014", text: "Tarikh Penubuhan" },
            { date: "Jan 2016", text: "Lesen Nasihat Kewangan" },
            { date: "Jan 2017", text: "Lesen Pengurusan Portfolio" },
            { date: "Sep 2019", text: "Ulang Tahun 5 Tahun" },
          ],
          [
            { date: "Feb 2015", text: "Lesen RTO" },
            {
              date: "Feb 2016",
              text: "Booth Ekspo Forex pertama di London (UK)",
            },
            { date: "Jun 2018", text: "Tonggak 15 kakitangan" },
            { date: "Feb 2020", text: "Berurusan dengan Lesen Akaun sendiri" },
          ],
        ],
        aboutUs: "Tentang Kami",
        whoWeAre: [
          {
            q: "Siapa Kita ?",
            a: "Di TrioMarkets™, kami percaya ada cara yang lebih sahih untuk memperdagangkan pasaran kewangan. Kami menyediakan Perdagangan yang Boleh Dipercayai dan Telus untuk memberikan pengalaman terbaik bagi pelanggan kami. Syarikat kami ditubuhkan oleh tiga profesional kewangan dengan lebih dari 20 tahun' pengalaman di Pasaran Kewangan Eropah dan Swiss. Trio terdiri daripada usahawan berkemahiran tinggi dengan sejarah latar belakang yang berjaya dalam Forex dalam talian. Trio mempunyai prinsip bahawa pelanggan kami menduduki tempat pertama, kedua, dan ketiga dalam senarai keutamaan kami.",
          },
          {
            q: "Apa yang kita buat ?",
            a: "penting untuk memberikan anda teknologi terbaik, keutamaan utama kami adalah membantu anda terus berhubung dari mana sahaja pada bila-bila masa dan pada peranti mana pun. Kami percaya adalah mustahak untuk menghargai dan menyokong pelanggan kami selain menyediakan persekitaran perdagangan terbaik untuk membantu semua orang merasa yakin dan puas. Misi kami adalah untuk memberikan maklumat yang tepat, mudah diakses, dan dapat diperoleh mengenai Forex. Platform TrioMarkets ™ telah dirancang secara unik untuk memenuhi setiap tahap pengalaman perdagangan sama ada anda baru berniaga atau mempunyai latar belakang perdagangan yang luas.",
          },
          {
            q: "Mengapa Memilih TrioMarkets™ ?",
            a: "Penjagaan pelanggan adalah teras dari apa yang kami lakukan di TrioMarkets ™ dan keperluan anda adalah keutamaan kami. Sekiranya anda mengalami masalah teknikal atau akaun, anda boleh menghubungi pasukan berbilang bahasa kami yang berpengetahuan dan dengan senang hati akan membantu anda. Falsafah kami adalah untuk memberi anda Perdagangan yang Boleh Dipercayai, Telus dan pasukan kami sentiasa membantu anda. Falsafah sebenar dalam perniagaan adalah bahawa anda mesti menawarkan nilai pelanggan untuk mengekalkan kesetiaan pelanggan dan mencapai kejayaan bersama.",
          },
        ],
        ourValues: "Nilai Kami",
        threeTs: [
          {
            name: "Boleh dipercayai",
            desc: "Adalah selamat untuk mengatakan bahawa platform kami komited untuk memastikan persekitaran perdagangan yang baik, setia kepada pelanggannya dengan mempersembahkan perisian yang sangat berkualiti dan selamat, dan menumpukan untuk memberikan perkhidmatan dan produk terbaik.",
            img: "trust",
          },
          {
            name: "Telus",
            desc: "TrioMarkets™ bertujuan untuk memberikan pengalaman yang telus kepada pelanggan dan menjamin untuk memberikan platform yang selamat untuk perdagangan yang selamat. Dari segi sokongan pelanggan, anda akan mendapat bantuan paling banyak dari pasukan yang berpengalaman dan menyokong selain mendapatkan semua soalan anda dijawab melalui laman web yang selamat dan telus.",
            img: "trans",
          },
          {
            name: "Perdagangan",
            desc: "Perdagangan berjalan dalam darah kita sejak 2014, dan untuk itu, kita memastikan untuk memberikan perjalanan perdagangan yang unik untuk semua jenis pelanggan dan menjamin kepuasan dan kesetiaan mereka.",
            img: "trade",
          },
        ],
        businessConcept: "Konsep perniagaan",
        textFour:
          "Syarikat kami dimiliki dan dikendalikan oleh Traders for Traders.<br> Pemegang Saham kami berasal perbankan pelaburan di mana mereka mengasah kemahiran dan kepakaran mereka dalam hab kewangan teratas di dunia seperti Geneva dan London.<br>Kami tahu apa yang anda perlukan dan apa yang anda mahukan dari broker dalam talian.",
      },
    ],
    meetUsFeatures: [
      {
        img: "1",
        title: "Kecairan Dalam",
        text: "Kami mempunyai beberapa kecairan penyedia yang sentiasa bersaing antara satu sama lain untuk memberikan harga terbaik. Kami tidak bertolak ansur dengan penyimpangan drastik dari harga dari tempat pelaksanaan kami dan segera mengubahnya jika diperlukan.",
      },
      {
        img: "2",
        title: "Keselamatan dan Kestabilan",
        text: `Matlamat syarikat adalah untuk 
        menjalin hubungan yang dibina berdasarkan kepercayaan bersama, Kami telah berada di pasaran sejak 2015 dan mengalami perubahan peraturan yang ketat dan pergolakan ekonomi dan kami di sini untuk mengatakan " KAMI TINGGAL "`,
      },
      {
        img: "3",
        title: "Kelajuan Pelaksanaan",
        text: "Kami sentiasa berada di tahap kami jari kaki untuk memastikan kelajuan pelaksanaan kita minimum dan sentiasa menyesuaikan infrastruktur kita untuk memastikan ia tidak berubah untuk peniaga kita",
      },
      {
        img: "4",
        title: "Harga",
        text: "Sebut harga adalah perkara yang penting masa lalu, seiring kemajuan teknologi, begitu juga dengan penawaran produk kami kepada anda, jadi kami berusaha memberikan yang terbaik, pelaksanaan dan harga pasaran.",
      },
    ],
    segregatedFundsInfo: [
      {
        text: `<p class="text-white text-center">"Akaun berasingan digunakan untuk memastikan pelanggan ' dana terpisah dari Dana Syarikat. Ini dilakukan kerana beberapa sebab, termasuk mewujudkan pemisahan yang jelas antara kedua-dua kumpulan wang sehingga tidak ada yang digunakan untuk tujuan yang salah, dan juga untuk memastikan bahawa wang itu dapat dengan mudah dikenali sebagai milik pelanggan sekiranya berlaku sesuatu kepada firma tersebut."</p>
        <p class="text-white text-center">Semua pelanggan dengan TrioMarkets™ akan mempunyai akaun berasingan di mana dana anda selamat dan disimpan terpisah dari akaun kami sendiri.</p>`,
        textTwo: `<p class="fw-bold fs-5">Di TrioMarkets™ semua dana ada diadakan secara khusus di ibu pejabat Swissquote di Switzerland.</p><p class="text-justify fs-6">Swissquote Bank adalah salah satu daripada firma / bank perdagangan dalam talian yang paling terkenal di Eropah. Swissquote mempunyai lebih daripada 500 pekerja dan beroperasi di lebih dari 7 negara dengan 10 cawangan di seluruh dunia, menghasilkan pendapatan sekitar 150 juta ( CHF ) dan kini bekerja dengan lebih daripada 250,000 pelanggan di seluruh dunia.</p><p class="text-justify fs-6">Sebagai syarikat TrioMarkets ™ sahaja berfungsi dengan yang terbaik untuk memberikan perkhidmatan terbaik kepada pelanggan kami. TrioMarkets ™ tidak akan pernah menggunakan dana ’ pelanggan dalam operasinya atau untuk pelaburan lain, kami memastikan perlindungan setiap saat.</p>`,
        textThree: `<p class="fw-bold fs-5">Dana Pampasan Pelabur:</p><p class="text-justify fs-6">Pampasan Pelabur Dana (ICF) ditubuhkan di bawah Firma Pelaburan (IF) Undang-undang 2002 seperti yang diubah (selanjutnya disebut “Undang-Undang”) dan Penubuhan dan Operasi Dana Pampasan Pelabur untuk pelanggan ICF Peraturan 2004 (selanjutnya disebut “Peraturan”) yang dikeluarkan berdasarkan Undang-Undang.</p><p class="text-justify fs-6">Objektif ICF adalah untuk mendapatkan sebarang tuntutan pelanggan yang dilindungi terhadap anggota ICF dengan membayar pampasan kepada pelanggan ini sekiranya syarikat tidak dapat disebabkan oleh keadaan kewangan yang tidak mungkin berlaku bertambah baik dalam masa terdekat.</p>`,
        points: [
          "ICF berfungsi untuk merangkumi Runcit Pelanggan CIF. Ini tidak termasuk Pelanggan Profesional dan Rakan Kongsi yang Layak.",
          "Pelanggan tertutup ICF syarikat ahli boleh menerima pampasan yang perlu dibayar tanpa mengira jumlah akaun yang dipegang, mata wang dan tempat menawarkan perkhidmatan pelaburan.",
        ],
        textFour: `Kerana polisi ini, sebagai pelanggan dengan TrioMarkets ™, anda boleh merasa yakin ketika berdagang bahawa semua perdagangan anda akan selesai di bawah tahap keselamatan, integriti dan ketelusan tertinggi.`,
      },
    ],
    licenseRegulationInfo: [
      {
        global: `<p class="text-justify fs-6"><strong>BENOR Capital Ltd</strong> adalah didaftarkan sebagai Peniaga Pelaburan (Broker) dengan nombor pendaftaran 160496. Dilesenkan oleh Suruhanjaya Perkhidmatan Kewangan (Mauritius) dengan nombor lesen <a target="_blank" href="https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code="> <strong class="text-primary">C118023678</strong></a> dan mempunyai lesen untuk melakukan operasi dengan nama dagang TrioMarkets™.</p><p class="text-justify fs-6 mb-0">Kami diberi kuasa untuk berdagang di peringkat antarabangsa, yang mengkhususkan diri dalam perdagangan dalam talian CFD pada mata wang, komoditi, indeks, saham dan kontrak hadapan. Kami berkomitmen semaksimum mungkin dalam standard perniagaan dan menganggap dedikasi kami terhadap etika sebagai tonggak falsafah kami.</p>`,
        eu: `<strong>TrioMarkets™</strong> adalah nama perdagangan 
        dimiliki dan dikendalikan oleh <strong>EDR Financial Ltd</strong>  dan didaftarkan sebagai Firma Pelaburan Cyprus (CIF) dengan nombor pendaftaran <strong>(HE336081)</strong>.</p><p class="text-justify fs-6 mb-0">Pakro <strong>ECN/STP</strong> Forex ini adalah dikawal selia oleh komisen Sekuriti dan Bursa Cyprus <strong>(CySEC)</strong> di bawah nombor lesen <a target="_blank" href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/"> <strong class="text-primary">268/15</strong></a>, yang berada di bawah payung <strong>Arahan Instrumen Kewangan Pasaran II (MIFID II)</strong>.</p>`,
        africa: `<strong>Darom Investment (Pty) Ltd</strong> provides execution only services and does so upon a strictly principal-to-principal trade transaction basis. Such trades are not on exchange and whilst Darom Investment (Pty) Ltd is a regulated FSP (number 53065), 
                 CFDs issued by TrioMarkets are not regulated by the FAIS Act as they are undertaken on a principal-to-principal basis.
                 TrioMarkets™ is both the trading name of Benor Capital Limited and Darom Investment (Pty) Ltd.`,
        dubai: `<strong>Benor capital Ltd- Rep. Office </strong> adalah pejabat perwakilan / Branch of Benor Capital Ltd. (Mauritius) yang terletak di Emiriah Arab Bersatu - Dubai di bawah nombor Lesen DED <a target="_blank"> <strong class="text-primary">1082819</strong></a>. Pejabat Berdaftar: Menara Bayswater, Pejabat 1212, Marasi Drive, Business Bay, Dubai, Emiriah Arab Bersatu dan mempunyai lesen untuk melakukan aktiviti pemasaran dan komersial dengan nama dagang TrioMarkets™. `,
        title: "Pautan Penting",
        importantLinks: [
          "Dana Pampasan Pelabur",
          "Dasar Privasi",
          "Dasar Konflik Kepentingan",
        ],
      },
    ],
    legalKIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KIDS/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs",
      },
    ],
    contactInfo: [
      {
        text: `Mempunyai soalan atau keperluan bantuan pakar? <br />
        Hubungi pelanggan kami yang berdedikasi pasukan perkhidmatan yang ada 24/5 untuk membantu anda!`,
        points: [
          {
            img: "phone",
            name: "Sokongan Telefon",
            text: license == "global" ? "+44 20 376 936 49" : "+357 22 222737",
            link: "",
            fax: "FAX: +357 25 250310",
          },
          {
            img: "mail",
            name: "Sokongan E-mel",
            text: "E-mel Kami",
            link:
              license == "global" || license === "africa"
                ? "mailto:info@triomarketsglobal.com"
                : "mailto:info@triomarkets.eu",
          },
          {
            img: "chat",
            name: "Sokongan Sembang",
            text: "Mulakan Sembang Langsung",
            link: "",
          },
        ],
        pointsTwo: [
          { img: "en", name: "Meja Bahasa Inggeris" },
          { img: "fr", name: "Meja Perancis" },
          { img: "it", name: "Meja Itali" },
          { img: "de", name: "Meja Jerman" },
          { img: "uae", name: "Meja Arab" },
        ],
      },
    ],
    faqInfo: [
      {
        faqSection: 1,
        title: "TrioMarkets™",
        faqContent: [
          {
            open: false,
            q: "Di manakah pejabat anda berada?",
            a:
              license == "global"
                ? "Ibu pejabat kami berpusat di Mauritius."
                : "Ibu pejabat kami berpusat di Limassol, Cyprus.",
          },
          {
            open: false,
            q: "Apakah jenis broker anda?",
            a: "TrioMarkets™ adalah ECN / STP Broker forex.",
          },
          {
            open: false,
            q: "Peraturan apa yang anda pegang?",
            a:
              license == "global"
                ? "TrioMarkets ™ diatur oleh Suruhanjaya Perkhidmatan Kewangan (FSC) Mauritius, di bawah nombor lesen C118023678."
                : `TrioMarkets ™ diatur oleh Suruhanjaya Sekuriti dan Bursa Cyprus (CySEC) di bawah nombor lesen 268/15 yang berada di bawah payung Arahan Instrumen Kewangan Pasaran II (MIFID II).`,
          },
        ],
      },
      {
        faqSection: 2,
        title: "Pembiayaan dan Pengeluaran",
        faqContent: [
          {
            open: false,
            q: "Bagaimana saya menyimpan wang?",
            a:
              license == "global"
                ? "Kami menerima pelbagai kaedah deposit untuk membiayai akaun perdagangan anda. Anda boleh menggunakan bank ke bank transfer wang, kad kredit, dan deposit Netteller."
                : "Kami menerima pelbagai kaedah deposit untuk membiayai akaun perdagangan anda. Anda boleh menggunakan bank ke bank transfer wang, kad kredit, Skrill, dan deposit Netteller.",
          },
          {
            open: false,
            q: "Berapakah deposit minimum?",
            a: "Untuk membuka akaun anda di TrioMarkets™, anda mesti membiayai akaun dengan $/€100.",
          },
          {
            open: false,
            q: "Bolehkah saya membiayai akaun dalam mata wang yang berbeza daripada mata wang asas?",
            a: "Mata wang di mana anda buka akaun anda dengan mata wang yang akan dibuat deposit anda. Oleh itu, jika anda mempunyai akaun USD, deposit ( jika dibuat dengan mata wang lain ) secara automatik akan ditukar menjadi USD.",
          },
        ],
      },
      {
        faqSection: 3,
        title: "Akaun kami",
        faqContent: [
          {
            open: false,
            q: "Bagaimana saya mengeluarkan wang?",
            a: "Kami membayar melalui kaedah yang digunakan untuk dana akaun anda. Semua pengeluaran akan dikenakan bayaran sebanyak 1.5% dari jumlah pengeluaran.",
          },
          {
            open: false,
            q: "Bagaimana saya menutup akaun saya?",
            a: "Untuk menutup akaun anda hubungi pengurus akaun anda secara langsung.",
          },
          {
            open: false,
            q: "Berapa lama masa yang diperlukan sahkan akaun saya?",
            a: "Bergantung pada dokumentasi yang dihantar, jika semuanya sah dan diluluskan akaun anda akan disahkan dengan segera oleh pejabat belakang kami. Setelah disahkan dan deposit telah dibuat, akaun anda akan diaktifkan untuk diperdagangkan.",
          },
          {
            open: false,
            q: "Apa jenis dokumen diperlukan untuk mengesahkan akaun perdagangan saya?",
            a: `Kami memerlukan Bukti Identiti: salinan berwarna yang jelas dari salah satu daripada yang berikut:
              <ul>
                  <li>Pasport</li>
                  <li>Kad pengenalan</li>
              </ul>
              Bukti Kediaman:
              <ul>
                  <li>Penyata bank atau bank surat rujukan</li>
                  <li>Bil utiliti</li>
                  <li>Mana-mana dokumen yang mengesahkan alamat tetap yang disahkan melalui pihak berkuasa tempatan (bil cukai, bil perbandaran)</li>
                  <li>Semua dokumen yang mengesahkan alamat kediaman mesti dikeluarkan dalam 6 bulan terakhir dan mengesahkan alamat yang diberikan dalam permohonan.</li>
              </ul>`,
          },
          {
            open: false,
            q: "Bagaimana saya memohon sendi akaun?",
            a: "Kedua-dua pemegang akaun bersama akan membuka akaun perdagangan dengan TrioMarkets dan menyerahkan dokumen yang diperlukan dengan borang permohonan akaun bersama. Akaun bersama boleh dibuka antara saudara darjah 1 hanya bukti (yang diperlukan sekiranya nama belakangnya berbeza) atau sekiranya 2 pelanggan adalah pemegang akaun bank bersama (bukti diperlukan).",
          },
          {
            open: false,
            q: "Apa jenis akaun sesuai untuk saya?",
            a: `Bergantung pada tahap pengalaman yang anda ada akan menentukan akaun mana yang paling sesuai untuk keperluan anda. Lihat jenis akaun kami untuk dipilih. <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">Klik di sini.</a>`,
          },
        ],
      },
      {
        faqSection: 4,
        title: "Am",
        faqContent: [
          {
            open: false,
            q: "Produk perdagangan mana yang boleh saya perdagangan?",
            a: `Di TrioMarkets™; Kami menawarkan a pelbagai kelas aset. Dari pasangan Forex kegemaran anda seperti EUR / USD, GBP / USD hingga komoditi dan CFD yang memperdagangkan indeks kegemaran anda seperti Dow Jones, S&P 500 dan FTSE. <br> Untuk semua kelas aset kami, <a href="https://www.triomarkets.${
              license == "global" ? "com" : "eu"
            }/accounts/accounts-overview">klik di sini.</a> Untuk memasukkan wang ke dalam wang anda akaun perdagangan, sila <a href="https://secure.triomarkets.${
              license == "global" ? "com" : "eu"
            }/en/login">klik di sini.</a>`,
          },
          {
            open: false,
            q: "Bagaimana saya berniaga?",
            a: "Di sini di TrioMarkets™; Anda boleh berniaga semua kelas aset kami melalui platform Meta Trader 4 (MT4) canggih kami. Kami menawarkan banyak maklumat perdagangan kepada pelanggan kami untuk sebut harga masa nyata ke carta, analisis perdagangan dan banyak lagi.",
          },
          {
            open: false,
            q: "Apa itu forex?",
            a: "Forex, juga dikenali sebagai Asing pertukaran adalah pasaran global untuk perdagangan pasangan mata wang. Pada mulanya ia melibatkan pembelian atau penjualan satu mata wang berbanding mata wang yang lain, iaitu. EUR/USD (membeli EUR untuk kenaikan nilai berbanding USD).",
          },
        ],
      },
    ],
  },
};
