<template>
  <div v-if="license !== 'africa'" id="language">
    <a class="nav-link pointer d-flex justify-content-between" @click="showModal = true">
      <div class="d-flex align-items-center">
        <img class="mx-1 align-text-bottom nav" :src="`/img/lang/${selectedLang}.svg`" :alt="selectedLang" />
        <span :class="`${(scrolled < 200 && !$route.meta.bg) || menu ? '' : 'dark'
          } ms-2 fs-6`">{{ selectedLangName }}</span>
      </div>
      <div class="d-xl-none">
        <img src="/img/navbar-mobile/menu-arrow-down.svg" alt="open arrow" class="lang-arrow" />
      </div>
    </a>
  </div>
  <div v-if="showModal" class="language-modal position-absolute row py-xl-3 ps-xl-3">
    <div class="language-box" v-click-outside="onClickOutside">
      <div class="d-xl-none choose-lang py-5">
        <div class="d-flex align-items-center justify-content-between">
          <p class="fs-5 text-white fw-bold mb-0">
            {{ $t("selectYourLanguage") }}
          </p>
          <img @click="this.showModal = false" src="/img/navbar-mobile/menu-close.svg" alt="close-menu"
            class="close-land-choice" />
        </div>
      </div>
      <div class="row lang-row px-0">
        <div :class="[
          'lang col-xl-6',
          lang.code === selectedLang ? 'selected' : '',
        ]" v-for="(lang, id) in translationLanguages" :key="id" @click="changeLanguage(lang)">
          <div class="d-flex language-tab py-2">
            <img class="d-block" :src="`/img/lang/${lang.code}.svg`" :alt="lang.name" @click="showModal = false" />
            <span :class="`mb-0  text-black text-white ${checkArb ? 'me-2 text-end' : 'ms-2'
              }`">
              {{ lang.translateName }}
            </span>
          </div>
          <img v-if="lang.code === selectedLang" src="/img/navbar-mobile/tick.svg" alt="tick" class="d-xl-none tick" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from "click-outside-vue3";
import { getters, mutations } from "../store";
export default {
  name: "Language",
  data() {
    return {
      name: "Language",
      scrolled: 0,
      showModal: false,
      selectedLangName: "English",
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    menu: Boolean,
  },
  computed: {
    license() {
      return getters.license();
    },
    languages() {
      return getters.languages();
    },
    translationLanguages() {
      if (this.license === "eu" && window.innerWidth > 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "cn" &&
            language.code !== "vnm" &&
            language.code !== "enUae" &&
            language.code !== "arb" &&
            language.code !== "my" &&
            language.code !== this.selectedLang
          );
        });
      } else if (this.license === "eu" && window.innerWidth <= 1200) {
        return this.languages.filter((language) => {
          return (
            language.code !== "cn" &&
            language.code !== "vnm" &&
            language.code !== "enUae" &&
            language.code !== "arb" &&
            language.code !== "my"
          );
        });
      } else if (window.innerWidth <= 1200) {
        return this.languages.filter((language) => {
          return language.code;
        });
      } else {
        return this.languages.filter((language) => {
          return language.code !== this.selectedLang;
        });
      }
    },
    selectedLang() {
      return getters.selectedLang();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    selectedLang: {
      handler() {
        if (this.selectedLang === "arb") {
          document.documentElement.setAttribute("dir", "rtl");
          document.body.style.fontFamily = "frutigerArb";
        } else {
          document.documentElement.setAttribute("dir", "ltr");
          document.body.style.fontFamily = "Gilroy-Regular";
        }
        this.selectedLangName = this.languages.find(({ code }) => {
          return code === this.selectedLang;
        }).translateName;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    changeLanguage(lang) {
      this.showModal = false;
      this.$root.$i18n.locale = lang.code;
      mutations.changeLanguage(lang.code);
      this.$router.replace({
        name: this.$route.name,
        params: { lang: lang.code },
        query: this.$route.query,
      });
      this.selectedLangName = lang.translateName;
    },
    handleScroll() {
      this.scrolled = window.pageYOffset;
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    onClickOutside() {
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
