<template>
  <div id="legalDocs">
    <div class="container-fluid container-top accounts legal-docs">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("legalDocuments") }}
          </h2>
          <hr class="gold" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            :class="`col-lg-3 col-md-7 col-sm-10 search ${
              checkArb ? 'rtl' : ''
            }`"
          >
            <input
              type="text"
              v-model="search"
              name="search"
              placeholder="i.e. Policy"
            />
            <img src="/img/search.svg" alt="Search Legal Docs" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">Policies</h2>
        <hr class="blue" />
      </div>
      <div
        v-if="license == 'eu'"
        class="row d-flex justify-content-center my-md-5 my-4"
      >
        <div
          v-for="(policy, idx) in filteredPolicies"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-else class="row d-flex justify-content-center my-md-5 my-4">
        <div
          v-for="(policy, idx) in filteredPoliciesB"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">Terms & Conditions</h2>
        <hr class="blue" />
      </div>
      <div
        v-if="license == 'eu'"
        class="row d-flex justify-content-center my-md-5 my-4"
      >
        <div
          v-for="(policy, idx) in filteredTerms"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
      <div v-else class="row d-flex justify-content-center my-md-5 my-4">
        <div
          v-for="(policy, idx) in filteredTermsB"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">Other Documentation</h2>
        <hr class="blue" />
      </div>
      <div
        v-if="license == 'eu'"
        class="row d-flex justify-content-center my-md-5 my-4"
      >
        <div
          v-for="(policy, idx) in filteredOthers"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
        <!-- <div
          v-for="(policy, idx) in $tm('legalKIDS')"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div> -->
      </div>
      <div v-else class="row d-flex justify-content-center my-md-5 my-4">
        <div
          v-for="(policy, idx) in filteredOthersB"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="license == 'eu'" class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">Risk Warnings</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center my-md-5 my-4">
        <div
          v-for="(policy, idx) in filteredRisks"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="policy.link" class="body">
              <h3 class="fw-bold fs-5" v-html="policy.name"></h3>
              <div class="buttonset">
                <a
                  :href="policy.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Steps stepStyle="dark" />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "LegalDocuments",
  computed: {
    license() {
      return getters.license();
    },
    filteredPolicies() {
      return this.legalDocuments.policies.filter((policy) => {
        return policy.name.match(new RegExp(this.search, "i"));
      });
    },
    filteredPoliciesB() {
      // return this.legalDocumentsB.policies.filter((policy) => {
      //   return policy.name.match(new RegExp(this.search, "i"));
      // });
      if (this.license === "global") {
        return this.legalDocumentsB.policies.filter((policy) => {
          return policy.entity === "global";
        });
      }
      return this.legalDocumentsB.policies.filter((policy) => {
        return policy.name.match(new RegExp(this.search, "i"));
      });
    },
    filteredTerms() {
      return this.legalDocuments.terms.filter((term) => {
        return term.name.match(new RegExp(this.search, "i"));
      });
    },
    filteredTermsB() {
      return this.legalDocumentsB.terms.filter((term) => {
        return term.name.match(new RegExp(this.search, "i"));
      });
    },
    filteredOthers() {
      return this.legalDocuments.others.filter((other) => {
        return other.name.match(new RegExp(this.search, "i"));
      });
    },
    filteredOthersB() {
      return this.legalDocumentsB.others.filter((other) => {
        return other.name.match(new RegExp(this.search, "i"));
      });
    },
    filteredRisks() {
      return this.legalDocuments.risks.filter((risk) => {
        return risk.name.match(new RegExp(this.search, "i"));
      });
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  data: () => ({
    search: "",
    legalDocuments: {
      policies: [
        {
          name: "Privacy Policy",
          link: "/legal-documents/EDR/Privacy-Policy.pdf",
          down: "/legal-documents/EDR/Privacy-Policy",
        },
        {
          name: "Risk Disclosure Policy",
          link: "/legal-documents/EDR/Risk-Disclosure-Policy.pdf",
          down: "/legal-documents/EDR/Risk-Disclosure-Policy",
        },
        {
          name: "Order Execution Policy",
          link: "/legal-documents/EDR/Order-Execution-Policy.pdf",
          down: "/legal-documents/EDR/Order-Execution-Policy",
        },
        {
          name: "Complaints Handling Policy",
          link: "/legal-documents/EDR/Complaints-Handling-Policy.pdf",
          down: "/legal-documents/EDR/Complaints-Handling-Policy",
        },
        {
          name: "Client Categorization Policy",
          link: "/legal-documents/EDR/Client-Categorization-Policy.pdf",
          down: "/legal-documents/EDR/Client-Categorization-Policy",
        },
        {
          name: "Investor Compensation Fund",
          link: "/legal-documents/EDR/Investor-Compensation-Fund.pdf",
          down: "/legal-documents/EDR/Investor-Compensation-Fund",
        },
        {
          name: "Conflicts of Interest Policy",
          link: "/legal-documents/EDR/Conflicts-Of-Interest-Policy.pdf",
          down: "/legal-documents/EDR/Conflicts-Of-Interest-Policy",
        },
        {
          name: "Anti Money Laundering Policy",
          link: "/legal-documents/EDR/Anti_Money_Laundering_policy.pdf",
          down: "/legal-documents/EDR/Anti_Money_Laundering_policy",
        },
      ],
      terms: [
        {
          name: "Portfolio Management Agreement",
          link: "/legal-documents/EDR/Portfolio-Management.pdf",
          down: "/legal-documents/EDR/Portfolio-Management",
        },
        {
          name: "Client Service Agreement",
          link: "/legal-documents/EDR/Client-Service-Agreement.pdf",
          down: "/legal-documents/EDR/Client-Service-Agreement",
        },
      ],
      others: [
        {
          name: "Company Information",
          link: "/legal-documents/EDR/Company-Information.pdf",
          down: "/legal-documents/EDR/Company-Information",
        },
        {
          name: "RTS 28 2020 - Retail Clients",
          link: "/legal-documents/EDR/RTS/RTS-28-2020-Retail-Clients.xlsx",
          down: "/legal-documents/EDR/RTS/RTS-28-2020-Retail-Clients",
          download: true,
        },
        {
          name: "RTS 28 2020 - Professional Clients",
          link: "/legal-documents/EDR/RTS/RTS-28-2020-Professional-Clients.xlsx",
          down: "/legal-documents/EDR/RTS/RTS-28-2020-Professional-Clients",
          download: true,
        },
        {
          name: "Top 5 Execution Venues for Professional clients RTS 28 for 2021",
          link: "/legal-documents/EDR/RTS/Top5-Execution-Venues-for-Professional-clients-RTS-28-for-2021.csv",
          down: "/legal-documents/EDR/RTS/RTS-28-2021-Top5-Professional",
          download: true,
        },
        {
          name: "Top 5 Execution Venues for Retail clients RTS 28 for 2021",
          link: "/legal-documents/EDR/RTS/Top-5-Execution-Venues-for-Retail-clients-RTS-28-for-2021.csv",
          down: "/legal-documents/EDR/RTS/RTS-28-2021-Top5-Retail",
          download: true,
        },
        {
          name: "Top 5 Execution Venues for Professional clients RTS 28 for 2022",
          link: "/legal-documents/EDR/RTS/Top-5-Execution-Venues-for-Professional-clients-RTS-28-for-2022.csv",
          down: "/legal-documents/EDR/RTS/Top-5-Execution-Venues-for-Professional-clients-RTS-28-for-2022",
          download: true,
        },
        {
          name: "Top 5 Execution Venues for Retail clients RTS 28 for 2022",
          link: "/legal-documents/EDR/RTS/Top-5-Execution-Venues-for-Retail-clients-RTS-28-for-2022.csv",
          down: "/legal-documents/EDR/RTS/Top-5-Execution-Venues-for-Retail-clients-RTS-28-for-2022",
          download: true,
        },
        {
          name: "RTS 27 Q1 2022",
          link: "/legal-documents/EDR/RTS/RTS-27-Q1-2022.zip",
          down: "/legal-documents/EDR/RTS/RTS-27-Q1-2022",
          download: true,
        },
        {
          name: "RTS 27 Q2 2022",
          link: "/legal-documents/EDR/RTS/RTS-27-Q2-2022.zip",
          down: "/legal-documents/EDR/RTS/RTS-27-Q2-2022",
          download: true,
        },
        {
          name: "RTS 27 Q3 2022",
          link: "/legal-documents/EDR/RTS/RTS-27-Q3-2022.zip",
          down: "/legal-documents/EDR/RTS/RTS-27-Q3-2022",
          download: true,
        },
        {
          name: "RTS 27 Q4 2022",
          link: "/legal-documents/EDR/RTS/RTS-27-Q4-2022.zip",
          down: "/legal-documents/EDR/RTS/RTS-27-Q4-2022",
          download: true,
        },
        {
          name: "RTS 27 Q1 2023",
          link: "/legal-documents/EDR/RTS/RTS-27-Q1-2023.zip",
          down: "/legal-documents/EDR/RTS/RTS-27-Q1-2023",
          download: true,
        },
        {
          name: "Execution Venues and Summary Statement 2017",
          link: "/legal-documents/EDR/Execution-Venues-2017.pdf",
          down: "/legal-documents/EDR/Execution-Venues-2017",
        },
        {
          name: "Execution Venues and Summary Statement 2018",
          link: "/legal-documents/EDR/Execution-Venues-2018.pdf",
          down: "/legal-documents/EDR/Execution-Venues-2018",
        },
        {
          name: "Execution Quality Summary Statement for 2019",
          link: "/legal-documents/EDR/Execution-Quality-Summary-2019.pdf",
          down: "/legal-documents/EDR/Execution-Quality-Summary-2019",
        },
        {
          name: "Pillar III Disclosure Requirements 2015",
          link: "/legal-documents/EDR/Edr-Pillar-2015.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2015",
        },
        {
          name: "Pillar III Disclosure Requirements 2016",
          link: "/legal-documents/EDR/Edr-Pillar-2016.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2016",
        },
        {
          name: "Pillar III Disclosure Requirements 2017",
          link: "/legal-documents/EDR/Edr-Pillar-2017.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2017",
        },
        {
          name: "Pillar III Disclosure Requirements 2018",
          link: "/legal-documents/EDR/Edr-Pillar-2018.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2018",
        },
        {
          name: "Pillar III Disclosure Requirements 2019",
          link: "/legal-documents/EDR/Edr-Pillar-2019.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2019",
        },
        {
          name: "Pillar III Disclosure Requirements 2020",
          link: "/legal-documents/EDR/Edr-Pillar-2020.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2020",
        },
        {
          name: "Pillar III Disclosure Requirements 2022",
          link: "/legal-documents/EDR/Edr-Pillar-2022.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2022",
        },
        {
          name: "Pillar III Disclosure Requirements 2023",
          link: "/legal-documents/EDR/Edr-Pillar-2023.pdf",
          down: "/legal-documents/EDR/Edr-Pillar-2023",
        },
        // {
        //   name: "PRIIP KID (CFD on Forex)",
        //   link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs.pdf",
        //   down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs",
        // },
        // {
        //   name: "PRIIP KID (CFD on Indices)",
        //   link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs.pdf",
        //   down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs",
        // },
        // {
        //   name: "PRIIP KID (CFD on Commodities)",
        //   link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs.pdf",
        //   down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs",
        // },
        // {
        //   name: "PRIIP KID (CFD on Cryptos)",
        //   link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS.pdf",
        //   down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS",
        // },
        // {
        //   name: "PRIIP KID (CFD on Equities)",
        //   link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs.pdf",
        //   down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs",
        // },
        {
          name: "Costs and Charges for Retail and Professional Clients per account type",
          link: "/legal-documents/EDR/Costs-and-Charges-for-Retail-and-Professional-Clients-per-account-type.pdf",
          down: "/legal-documents/EDR/Costs-and-Charges-for-Retail-and-Professional-Clients-per-account-type",
        },
        {
          name: "Client Segregated Account Details",
          link: "/legal-documents/EDR/Client_Segregated_Account_details.pdf",
          down: "/legal-documents/EDR/Client_Segregated_Account_details",
        },
        {
          name: "EDR Financial Limited-Disclosure & Market Discipline Report for 2021",
          link: "/legal-documents/EDR/EDR Financial Limited-Disclosure & Market Discipline Report for 2021-final signed.pdf",
          down: "/legal-documents/EDR/EDR Financial Limited-Disclosure & Market Discipline Report for 2021-final signed",
        },
      ],
      risks: [
        {
          name: "Risk Warning Statement on CFDs in Cryptocurrencies",
          link: "/legal-documents/EDR/RISK-WARNING-STATEMENT-ON-CFDS-IN-CRYPTO.pdf",
          down: "/legal-documents/EDR/RISK-WARNING-STATEMENT-ON-CFDS-IN-CRYPTO",
        },
      ],
    },
    legalDocumentsB: {
      policies: [
        {
          name: "Privacy Policy",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Privacy_Policy.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Privacy_Policy",
          entity: "global",
        },
        {
          name: "Risk Disclosure Statement",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Risk_Disclosure_Statement.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Risk_Disclosure_Statement",
          entity: "global",
        },
        {
          name: "Treating Customers Fairly Policy Statement",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Treating_Customers_Fairly_Policy_Statement.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Treating_Customers_Fairly_Policy_Statement",
          entity: "global",
        },
        {
          name: "Client Categorisation Policy",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Client_Categorisation_Policy.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Client_Categorisation_Policy",
          entity: "global",
        },
        {
          name: "Best Execution Policy",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Best_Execution_Policy.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Best_Execution_Policy",
          entity: "global",
        },
        {
          name: "Conflicts of Interest Policy",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Conflicts_of_Interest_Policy.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Conflicts_of_Interest_Policy",
          entity: "global",
        },
        {
          name: "Statutory Fais Disclosure",
          link: "/legal-documents/benor/Triomarkets-statutory_fais_disclosure.pdf",
          down: "/legal-documents/benor/Triomarkets-statutory_fais_disclosure",
          entity: "africa",
        },
      ],
      terms: [
        {
          name: "Terms of Business",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Terms_of_Business.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Terms_of_Business",
        },
        {
          name: "Client Service Agreement",
          link: "/legal-documents/benor/Benor_Capital_Ltd-Client__Services_Agreement.pdf",
          down: "/legal-documents/benor/Benor_Capital_Ltd-Client__Services_Agreement",
        },
        {
          name: "Cashback",
          link: "/legal-documents/benor/TrioMarkets_Cashback.pdf",
          down: "/legal-documents/benor/TrioMarkets_Cashback",
        },
        {
          name: "Refer a Friend",
          link: "/legal-documents/benor/TrioMarkets_RaF_TnC.pdf",
          down: "/legal-documents/benor/TrioMarkets_RaF_TnC",
        },
      ],
      others: [
        {
          name: "Client Segregated Account Details",
          link: "/legal-documents/benor/Client_Segregated_Account_details.pdf",
          down: "/legal-documents/benor/Client_Segregated_Account_details",
        },
      ],
      risks: [],
    },
    KIDS: [
      {
        name: "PRIIP KID (CFD on Forex)",
        link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs.pdf",
        down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_FOREX_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Indices)",
        link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs.pdf",
        down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_INDICES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Commodities)",
        link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_COMMODITIES_CFDs",
      },
      {
        name: "PRIIP KID (CFD on Cryptos)",
        link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS.pdf",
        down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_CFDs_CRYPTOS",
      },
      {
        name: "PRIIP KID (CFD on Equities)",
        link: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs.pdf",
        down: "/legal-documents/EDR/KEY_INFORMATION_DOCUMENT_-_EQUITIES_CFDs",
      },
    ],
  }),
};
</script>
<style lang="scss" scoped></style>
