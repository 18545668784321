<template>
  <div
    class="container-fluid d-flex flex-column justify-content-center position-relative px-0"
    id="markets-top"
    :style="`background-image:url('/img/markets/${market.video}.jpg')`"
  >
    <div class="container-top">
      <div class="markets-top-content d-flex align-items-center w-100 h-100">
        <div class="row d-flex justify-content-start">
          <div
            :class="`col-xl-7 col-lg-8 col-md-9 ${checkArb ? 'text-end' : ''}`"
          >
            <h1 :class="`text-white ${checkArb ? 'rtl' : ''}`">
              {{ market.title }}
            </h1>
            <p class="text-white fs-md-5 fs-6">{{ market.desc }}</p>
            <div>
              <a :href="registerURL" class="btn btn-blue mt-4 py-3">{{
                $t("tradeNow")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5 markets-features mb-3 px-3">
      <div
        v-scrollanimation
        v-for="(item, idx) in $tm('marketsTop')"
        :key="idx"
        class="col-md-3 col-6 px-2 my-xl-0 my-2"
      >
        <div
          class="d-flex flex-column justify-content-center align-items-center pt-1 pb-4"
        >
          <img
            class="img-fluid p-3 icon markets-top-icon"
            :src="`/img/markets/${item.img}.svg`"
            alt="icon"
          />
          <span class="text-center text-white fs-6" v-html="item.desc"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "MarketsTop",
  props: {
    market: Object,
  },
  computed: {
    loginURL() {
      return getters.loginURL();
    },
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss"></style>
