<template>
  <div class="container-fluid" id="market-quotes">
    <div class="row d-flex justify-content-center my-5">
      <h2 class="col-12 text-center title">{{ product.popular }}</h2>
      <hr class="blue" />
    </div>
    <div class="row">
      <div class="col-12 search">
        <input
          v-model="search"
          type="text"
          placeholder="i.e. EURUSD"
          class="px-3 py-0 text-center"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 p-0">
        <table class="w-100">
          <thead>
            <tr>
              <th v-for="(col, idx) in $tm('liveQuotesRow')" :key="idx">
                {{ col.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(quote, index) in searchQuotes"
              :key="index"
              @click="selectQuote(quote, index)"
              :class="
                selectedSymbolIndex === index
                  ? 'pointer selectedQuote'
                  : 'pointer'
              "
            >
              <td>
                <strong>{{
                  quote.ticker.includes("_")
                    ? quote.ticker.split("_")[0]
                    : quote.ticker
                }}</strong>
              </td>
              <td :style="`color:${quote.trend == 'up' ? 'green' : 'red'};`">
                {{ quote.bid }}
              </td>
              <td :style="`color:${quote.trend == 'up' ? 'green' : 'red'};`">
                {{ quote.ask }}
              </td>
              <td>
                <img
                  :src="`/img/livequotes/arr-${quote.trend}.svg`"
                  alt="ArrowUp"
                />
              </td>
              <td :style="`color:${quote.trend == 'up' ? 'green' : 'red'};`">
                {{ quote.spread }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getters, mutations } from "../store";
export default {
  props: {
    symbols: Array,
    product: Object,
  },
  data() {
    return {
      wss: null,
      search: "",
      quotes: [],
      selectedSymbolIndex: 0,
    };
  },
  created() {
    this.addSymbols();
    this.getConnection();
  },
  computed: {
    searchQuotes() {
      return this.quotes.filter((quote) => {
        return quote.ticker.match(new RegExp(this.search, "i"));
      });
    },
    currentSymbol() {
      return getters.currentSymbol();
    },
    isCurrentSymbol() {
      return (
        Object.keys(this.currentSymbol).length === 0 &&
        this.currentSymbol.constructor === Object
      );
    },
    isForexPage() {
      return this.$route.path.includes("/markets/forex");
    },
  },
  methods: {
    addSymbols() {
      this.symbols.map((symbol) => {
        this.quotes.push({
          ticker: symbol,
          bid: "...",
          ask: "...",
          trend: "up",
          spread: "...",
        });
      });
    },
    getConnection() {
      axios
        .get(
          "https://trio-rd.triomarkets.com/signalr/negotiate?clientProtocol=2.0&connectionData=%5B%7B%22name%22%3A%22myhub%22%7D%5D&_=1606214640841"
        )
        .then(({ data }) => {
          this.getQuotes(encodeURIComponent(data.ConnectionToken));
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    getQuotes(token) {
      this.wss = new WebSocket(
        `wss://trio-rd.triomarkets.com/signalr/connect?transport=webSockets&clientProtocol=2.0&connectionToken=${token}&connectionData=%5B%7B%22name%22%3A%22myhub%22%7D%5D&tid=2`
      );
      this.wss.onmessage = (event) => {
        let data = JSON.parse(event.data);
        if (data.M && data.M.length > 0) {
          data.M.map((el) => {
            let fetchedQuote = el.A[0];
            this.quotes.map((quote) => {
              if (quote.ticker === fetchedQuote.symbol) {
                quote.bid = fetchedQuote.bid.toFixed(fetchedQuote.digits);
                quote.ask = fetchedQuote.ask.toFixed(fetchedQuote.digits);
                quote.trend =
                  fetchedQuote.direction == 1
                    ? "up"
                    : fetchedQuote.direction == 0
                    ? "dn"
                    : "";
                if (this.isCurrentSymbol) {
                  mutations.setCurrentSymbol(this.quotes[0]);
                }
                this.convertDigits(fetchedQuote, quote);
              }
            });
          });
        }
      };
    },
    selectQuote(symbol, index) {
      this.selectedSymbolIndex = index;
      mutations.setCurrentSymbol(symbol);
    },
    convertDigits({ ask, bid, digits }, quote) {
      if (this.isForexPage) {
        let qd = ((ask - bid).toFixed(digits) * 10000).toFixed(1);
        quote.spread = quote.ticker == "USDJPY" ? qd / 100 : qd;
      } else {
        quote.spread = (quote.ask - quote.bid).toFixed(1);
      }
    },
  },
};
</script>