<template>
  <div class="container-fluid p-0" id="live-quotes">
    <div class="row my-5">
      <div class="col-12 d-flex flex-column align-items-center">
        <h2 class="title">{{ $t("liveQuotes") }}</h2>
        <hr class="blue" />
      </div>
    </div>
    <div class="row">
      <div class="d-flex w-100 px-0">
        <div
          v-for="(tab, idx) in $tm('liveQuotesTabs')"
          :key="idx"
          @click="changeTab(idx)"
          :class="[`tab ${checkArb ? 'rtl':''}`, idx === current ? 'active' : '']"
        >
          <img :src="`/img/livequotes/${tab.icon}.svg`" :alt="tab.title" />
          <span>{{ tab.title }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <transition>
        <table>
          <thead>
            <tr>
              <th v-for="(col, idx) in $tm('liveQuotesRow')" :key="idx">
                {{ col.name }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(content, idx) in liveQuotesContent[current].content"
              :key="idx"
            >
              <td class="text-start">
                <img
                  v-if="content.img1"
                  :class="`mr-md-2 mr-1 check ${
                    content.class == 'two' ? 'bigger' : ''
                  }`"
                  :src="`/img/livequotes/${content.img1}.svg`"
                  :alt="content.ticker"
                />
                <strong class="mr-1">{{ content.ticker }}</strong>
              </td>
              <td :style="`color:${content.trend == 'up' ? 'green' : 'red'};`">
                {{ content.bid }}
              </td>
              <td :style="`color:${content.trend == 'up' ? 'green' : 'red'};`">
                {{ content.ask }}
              </td>
              <td>
                <img
                  class="arrow"
                  :src="`/img/livequotes/arr-${content.trend}.svg`"
                  alt="ArrowUp"
                />
              </td>
              <td :style="`color:${content.trend == 'up' ? 'green' : 'red'};`">
                {{ content.spread }}
              </td>
            </tr>
          </tbody>
        </table>
      </transition>
    </div>
    <div class="row">
      <p class="p-md-0 px-1 disclaimer">{{ $t("liveQuotesDisclaimer") }}</p>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
import axios from "axios";
export default {
  name: "LiveQuotes",
  data: () => ({
    current: 0,
    liveQuotesContent: [
      {
        content: [
          {
            ticker: "EURUSD",
            img1: "EURUSD",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.3",
            class: "two",
          },
          {
            ticker: "GBPUSD",
            img1: "GBPUSD",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.7",
            class: "two",
          },
          {
            ticker: "AUDUSD",
            img1: "AUDUSD",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.5",
            class: "two",
          },
          {
            ticker: "USDJPY",
            img1: "USDJPY",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
            class: "two",
          },
          {
            ticker: "EURGBP",
            img1: "EURGBP",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.7",
            class: "two",
          },
          // {
          //   ticker: "USDCHF",
          //   img1: "us",
          //   img2: "ch1",
          //   bid: "...",
          //   ask: "...",
          //   trend: "up",
          //   spread: "0.8",
          // },
          // {
          //   ticker: "NZDUSD",
          //   img1: "nz",
          //   img2: "us",
          //   bid: "...",
          //   ask: "...",
          //   trend: "up",
          //   spread: "1.1",
          // },
        ],
      },
      {
        content: [
          {
            ticker: "UK100",
            img1: "uk",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.3",
          },
          {
            ticker: "DOWJONES30",
            img1: "us",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.7",
          },
          {
            ticker: "SPX500",
            img1: "us",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.5",
          },
          {
            ticker: "FRANCE40",
            img1: "fr",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
          {
            ticker: "GERMANY30",
            img1: "de",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.7",
          },
        ],
      },
      {
        content: [
          {
            ticker: "XAUUSD",
            img1: "gold",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.3",
          },
          {
            ticker: "XAGUSD",
            img1: "silver",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.7",
          },
          // {
          //   ticker: "XPTUSD",
          //   bid: "...",
          //   img1: "platinum",
          //   ask: "...",
          //   trend: "dn",
          //   spread: "0.5",
          // },
          // {
          //   ticker: "XPDUSD",
          //   bid: "...",
          //   img1: "paladium",
          //   ask: "...",
          //   trend: "up",
          //   spread: "0.5",
          // },
          {
            ticker: "BRENTOIL",
            bid: "...",
            img1: "oil",
            ask: "...",
            trend: "up",
            spread: "0.3",
          },
          {
            ticker: "CRUDEOIL",
            bid: "...",
            img1: "oil",
            ask: "...",
            trend: "dn",
            spread: "0.7",
          },
          {
            ticker: "NATURALGAS",
            bid: "...",
            img1: "gas",
            ask: "...",
            trend: "dn",
            spread: "0.5",
          },
        ],
      },
      {
        content: [
          {
            ticker: "BTCUSD",
            img1: "bit",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.3",
          },
          {
            ticker: "ETHUSD",
            img1: "ethereum",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.7",
          },
          {
            ticker: "LTCUSD",
            img1: "litercoin",
            bid: "...",
            ask: "...",
            trend: "dn",
            spread: "0.5",
          },
          {
            ticker: "XRPUSD",
            img1: "xrp",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
          {
            ticker: "BCHUSD",
            img1: "bit-green",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
        ],
      },
      {
        content: [
          {
            ticker: "AAPL_US",
            img1: "app",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
          {
            ticker: "TSLA_US",
            img1: "tes",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
          {
            ticker: "FB_US",
            img1: "fb",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
          // {
          //   ticker: "NFLX_US",
          //   img1: "netflix",
          //   bid: "...",
          //   ask: "...",
          //   trend: "up",
          //   spread: "0.5",
          // },
          {
            ticker: "PFE_US",
            img1: "pfizer",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
          {
            ticker: "AMZN_US",
            img1: "amaz",
            bid: "...",
            ask: "...",
            trend: "up",
            spread: "0.5",
          },
          // {
          //   ticker: "MSFT_US",
          //   img1: "microsoft",
          //   bid: "...",
          //   ask: "...",
          //   trend: "up",
          //   spread: "0.5",
          // },
          // {
          //   ticker: "NVDA_US",
          //   img1: "nvidia",
          //   bid: "...",
          //   ask: "...",
          //   trend: "up",
          //   spread: "0.5",
          // },
        ],
      },
    ],
  }),
  created() {
    axios
      .get(
        "https://trio-rd.triomarkets.com/signalr/negotiate?clientProtocol=2.0&connectionData=%5B%7B%22name%22%3A%22myhub%22%7D%5D&_=1606214640841"
      )
      .then((resp) => {
        this.getRTQ(encodeURIComponent(resp.data.ConnectionToken));
      })
      .catch((err) => {
        console.log(err.message);
      });
  },
  computed:{
     checkArb() {
      return getters.checkArb();
    },
  },
  methods: {
    float(number, decimals) {
      return Number.parseFloat(number).toFixed(decimals);
    },
    showTicker(ticker) {
      this.$router.push(`/ticker?symbol=${ticker}`);
    },
    changeTab(idx) {
      this.current = idx;
    },
    getRTQ(token) {
      this.wss = new WebSocket(
        `wss://trio-rd.triomarkets.com/signalr/connect?transport=webSockets&clientProtocol=2.0&connectionToken=${token}&connectionData=%5B%7B%22name%22%3A%22myhub%22%7D%5D&tid=2`
      );
      this.wss.onmessage = (event) => {
        if (JSON.parse(event.data).M && JSON.parse(event.data).M.length > 0) {
          for (let idx = 0; idx < JSON.parse(event.data).M.length; idx++) {
            let quote = JSON.parse(event.data).M[idx].A[0];
            this.liveQuotesContent.forEach((livequote) => {
              for (let i = 0; i < livequote.content.length; i++) {
                if (livequote.content[i].ticker == quote.symbol) {
                  livequote.content[i].bid = quote.bid.toFixed(quote.digits);
                  livequote.content[i].ask = quote.ask.toFixed(quote.digits);
                  livequote.content[i].trend =
                    quote.direction == 1 ? "up" : "dn";
                  livequote.content[i].spread =
                    Math.round(
                      (quote.ask - quote.bid) * Math.pow(10, quote.digits)
                    ) / 1;
                }
              }
            });
          }
        }
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
