<template>
  <div v-for="(item, idx) in $tm('vpsInfo')" :key="idx">
    <div class="container-fluid container-top accounts half vps">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5 mb-2">
          <h2 class="col-12 text-center title text-white">
            {{ $t("vps") }} Trading
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-8 col-md-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
          <div class="col-12 text-center my-4">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{ $t("openLiveAccount") }}</a>
          </div>
          <div class="col-sm-8 text-center">
            <img
              src="/img/platforms-menu/vps/vps1.png"
              alt="vps image"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-md-0 mt-4">
        <div
          class="col-lg-8 col-md-10 col-12 text-center"
          v-html="item.textTwo"
        ></div>
      </div>
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">
          {{ item.title }}
        </h2>
        <hr class="blue mbhr" />
      </div>
      <div class="row d-flex justify-content-center my-lg-5 my-4" v-scrollanimation>
        <div class="col-12">
          <div class="row d-flex justify-content-center">
            <div
              v-for="(point, idx) in item.points"
              :key="idx"
              class="col-lg-4 col-sm-10 col-11 mb-lg-2 mb-4 points"
            >
              <div class="feature-box d-flex points">
                <img
                  src="/img/check.svg"
                  :class="`img-fluid check mb-lg-0 mb-2 mt-lg-0 mt-3  ${checkArb ? ' ms-lg-3':' me-lg-3'}`"
                  alt="check"
                  style="height: 30px"
                />
                <p class="mb-lg-0 fs-5 text-justify lh-sm" v-html="point"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center my-5">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{ $t("openLiveAccount") }}</a>
        </div>
      </div>
    </div>
    <div class="container-fluid black-box vps py-5 my-3">
      <div class="container">
        <div class="row d-flex justify-content-center">
          <div class="col-xl-6 col-lg-8">
            <div class="row d-flex justify-content-center">
              <div class="col-md-6 text-center box-logo mb-md-0 mb-4 d-flex align-items-center">
                <img
                  src="/img/platforms-menu/vps/hoko-cloud.svg"
                  alt="hokocloud-logo"
                  class="img-fluid"
                />
              </div>
              <div class="col-md-6 col-11 text-center box-logo d-flex align-items-center">
                <img
                  src="/img/platforms-menu/vps/forex-vps.svg"
                  alt="forexVps-logo"
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-md-5 mt-4">
          <div class="col-lg-8 col-md-10 col-12" v-html="item.textThree"></div>
          <div class="col-12 text-center mt-3">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{ $t("openLiveAccount") }}</a>
          </div>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps stepStyle="dark" v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "VPS",
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped></style>
