<template>
  <div v-for="(item, idx) in $tm('metaTraderInfo')" :key="idx">
    <div class="container-fluid container-top accounts">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("metaTrader") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-md-8 col-lg-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
          <div class="col-12 text-center my-4">
            <a
              href="https://download.mql5.com/cdn/web/edr.financial.limited/mt4/triomarkets4setup.exe"
              class="btn btn-blue"
              >{{ $t("downloadNow") }}</a
            >
          </div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8 col-md-10 col-12">
            <div class="row d-flex justify-content-center">
              <div
                v-for="(point, idx) in item.points"
                :key="idx"
                class="col-sm-4 d-flex flex-column text-center"
              >
                <img
                  :src="`/img/platforms-menu/${point.img}.svg`"
                  class="img-fluid icon"
                  alt="icon"
                />
                <p
                  class="text-center text-white fs-5 lh-sm"
                  v-html="point.name"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center d-none d-xl-block">
      <img
        src="/img/platforms-menu/MT4.png"
        alt="ipad"
        class="img-fluid"
        id="mt4Img"
      />
    </div>
    <!-- <div class="text-center justify-content-center" v-scrollanimation>
      <img
        src="/img/platforms-menu/MT4.png"
        alt="Logo Dark"
        class="img-fluid"
        id="mt4Img"
      />
    </div> -->
    <div class="container">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">
          {{ $t("keyFeatures") }}
        </h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center" v-scrollanimation>
        <div class="col-lg-10">
          <div class="row d-flex justify-content-center my-lg-5 my-4">
            <div
              v-for="(point, idx) in item.pointsTwo"
              :key="idx"
              class="col-md-6 col-sm-10 col-11 mb-lg-2 mb-4 points"
            >
              <div
                :class="`feature-box d-flex points align-items-center  ${
                  checkArb ? 'text-end' : ' '
                }`"
              >
                <img
                  src="/img/check.svg"
                  :class="`img-fluid check mb-lg-0 mb-2 mt-lg-0 mt-3 ${
                    checkArb ? ' ms-lg-3' : ' me-lg-3'
                  }`"
                  alt="check"
                />
                <p class="mb-lg-0 fs-5 lh-sm" v-html="point"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <a
            target="_blank"
            :href="registerURL"
            class="btn btn-blue mb-5 mt-md-4"
            >{{ $t("openLiveAccount") }}</a
          >
        </div>
      </div>
    </div>
    <!---->
    <div class="gray-box p-5">
      <div
        :class="`container  ${checkArb ? 'flip-content' : ''}`"
        v-scrollanimation
      >
        <div class="row d-flex justify-content-center">
          <h2 class="text-center">{{ $t("TitleNewSection") }}</h2>
          <hr class="blue" />
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-12">
            <img
              src="/img/platforms-menu/Mt4Mac.png"
              :class="`rounded float-left img-fluid d-none d-xl-block  ${
                checkArb ? 'flip-content' : ''
              }`"
              id="MacImg"
            />
            <div
              class="row d-flex justify-content-center my-lg-5 p-5 feature-box"
            >
              <div
                :class="`col-md-12 col-sm-10 col-11 mb-4 points  ${
                  checkArb ? 'flip-content' : ''
                }`"
                v-for="(text, id) in Pc"
                :key="id"
              >
                <p
                  :class="`mb-lg-0 fs-3 lh-sm text-primary p-3 ml-5 px-5 d-none d-xl-block ${
                    checkArb ? 'flip-content ' : ''
                  }`"
                  v-html="$t(text.title)"
                ></p>

                <div class="d-flex points align-items-center px-4">
                  <a :href="`${text.link}`">
                    <img
                      :src="`/img/platforms-menu/${text.img}.svg`"
                      :class="`img-fluid symbol mb-lg-0 mb-2 mt-lg-0 mt-3 me-lg-5 ${
                        checkArb ? 'flip-content ml-5' : ''
                      }`"
                  /></a>
                  <p class="mb-lg-0 fs-5 lh-sm ml-4" v-html="$t(text.text)"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="/img/platforms-menu/Iphone2.png"
          :class="`rounded float-right img-fluid d-none d-xl-block`"
          id="IphoneImg"
        />
        <div
          :class="`row d-flex justify-content-center my-lg-5 my-4 p-5 feature-box  ${
            checkArb ? 'flip-content' : ''
          }`"
        >
          <div
            class="col-md-12 col-sm-10 col-11 mb-lg-2 mb-4 points"
            v-for="(text, id) in Phone"
            :key="id"
          >
            <p
              class="mb-lg-0 fs-3 lh-sm text-primary p-3 ml-5 d-none d-xl-block"
              v-html="$t(text.title)"
            ></p>

            <div :class="`d-flex points align-items-center  `">
              <a :href="`${text.link}`">
                <img
                  :src="`/img/platforms-menu/${text.img}.svg`"
                  :class="`img-fluid symbol1 mb-lg-0 mb-2 mt-lg-0 mt-3 me-lg-5 ${
                    checkArb ? 'flip-content ml-5' : ''
                  }`"
              /></a>

              <p class="mb-lg-0 fs-5 lh-sm ml-4" v-html="$t(text.text)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps stepStyle="dark" v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  data() {
    return {
      Pc: [
        {
          title: "NewSectionTitle1",
          text: "NewSectionText1",
          img: "windows",

          link: "https://www.metatrader4.com/en/download",
        },
        {
          title: "NewSectionTitle2",
          text: "NewSectionText2",
          img: "apple",
          link: "https://www.metatrader4.com/en/download",
        },
      ],
      Phone: [
        {
          title: "PhoneTitle1",
          text: "PhoneText1",
          img: "android",
          link: "https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4&hl=fr&gl=US&pli=1",
        },
        {
          title: "PhoneTitle2",
          text: "PhoneText2",
          img: "apple",
          link: "https://apps.apple.com/fr/app/metatrader-4/id496212596",
        },
      ],
    };
  },
  name: "MetaTrader",
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped></style>
