let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : "global";
export default {
  en: {
    markets: `Markets`,
    forex: `Forex`,
    indices: `Indices`,
    metals: `Metals`,
    cryptos: `Cryptos`,
    energies: `Energies`,
    shares: `Shares`,
    tradingConditions: `Trading Conditions`,
    accounts: `Accounts`,
    accountsOverview: `Accounts Overview`,
    hni: `HNI & Institutional Clients`,
    proTraders: `Professional Traders`,
    fundingWithdrawals: `Funding & Withdrawals`,
    platforms: `Platforms`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Mobile Trading`,
    webTrader: `WebTrader`,
    socialTrading: `Social Trading`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `FAQ`,
    glossary: `Glossary`,
    company: `Company`,
    aboutUs: "About Us",
    segregatedFunds: `Segregated Funds`,
    licenseRegulations: `License & Regulations`,
    legalDocuments: `Legal Documents`,
    kidsDocuments: `Key Information Documents (KIDs)`,
    contact: `Contact`,
    partnerships: `Partnerships`,
    introducingBroker: `Introducing Broker`,
    cpa: `CPA Affiliates`,
    promotions: `Promotions`,
    news: "News",

    // mobile menu

    menuItems: [
      {
        title: "Markets",
        submenu: [
          {
            title: "Forex",
            link: `markets/forex`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Indices",
            link: `markets/indices`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Metals",
            link: `markets/metals`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "Cryptos",
            link: `markets/cryptos`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "Energies",
            link: `markets/energies`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "Shares",
            link: `markets/shares`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "Trading Conditions",
            link: `markets/trading-conditions`,
            license: "",
            animationDelay: "0.35s",
          },
        ],
      },
      {
        title: "Accounts",
        submenu: [
          {
            title: "Accounts Overview",
            link: `accounts/accounts-overview`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "HNI & Institutional Clients",
            link: `accounts/hni`,
            license: "",
            animationDelay: "0.10s",
          },
          //only eu
          {
            title: "Professionatl Traders",
            link: `accounts/professional-traders`,
            license: "eu",
            animationDelay: `0.15s`,
          },
          {
            title: "Funding & Withdrawals",
            link: `accounts/funding-and-withdrawals`,
            license: "",
            animationDelay: `${license == "global" ? "0.15" : "0.20"}s`,
          },
        ],
      },
      {
        title: "Platforms",
        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: "",
            animationDelay: "0.10s",
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "Mobile Trading",
          //   link: `platforms/mobile-trading`,
          //   license: "",
          //   animationDelay: "0.15s",
          // },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: "",
            animationDelay: "0.20s",
          },
          // {
          //   title: "Social Trading",
          //   link: `platforms/social-trading`,
          //   license: "",
          //   animationDelay: "0.25s",
          // },
          {
            title: "FIX API",
            link: `platforms/fix-api`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: "",
            animationDelay: "0.35s",
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: "",
            animationDelay: "0.40s",
          },
        ],
      },
      {
        title: "Company",
        submenu: [
          {
            title: "About Us",
            link: `company/meet-us`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Segregated Funds",
            link: `company/segregated-funds`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Licenses & Regulations",
            link: `company/license-and-regulations`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "Legal Documents",
            link: `company/legal-documents`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "Key Information Documents (KIDs)",
            link: `company/kids`,
            license: "eu",
            animationDelay: "0.20s",
          },
          {
            title: "Contact",
            link: `company/contact`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "Glossary",
            link: `company/glossary`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "FAQ",
            link: `company/faq`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
      {
        title: "Partnerships",
        submenu: [
          {
            title: "Introducing Brokers",
            link: `partnerships/introducing-brokers`,
            license: "global",
            animationDelay: "0.05s",
          },
          {
            title: "CPA Affiliates",
            link: `partnerships/cpa-affiliates`,
            license: "global",
            animationDelay: "0.10s",
          },
          // only benor
          {
            title: "Promotions",
            link: `partnerships/promotions`,
            license: "global",
            animationDelay: "0.15s",
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "global",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "global",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "global",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Select Your Language",
  },
  fr: {
    markets: `Marchés`,
    forex: `Forex`,
    indices: `Indices`,
    metals: `Métaux`,
    cryptos: `Cryptos`,
    energies: `Énergies`,
    shares: `Actions`,
    tradingConditions: `Conditions de Trading`,
    accounts: `Comptes`,
    accountsOverview: `Types de Comptes`,
    hni: `HNI & Clients Institutionnels`,
    proTraders: `Traders Professionnels`,
    fundingWithdrawals: `Dépôts & Retraits`,
    platforms: `Plateformes `,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Trading Mobile`,
    webTrader: `WebTrader`,
    socialTrading: `Trading Social`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `FAQ`,
    glossary: `Glossaire`,
    company: `Entreprise`,
    aboutUs: "À propos de nous",
    segregatedFunds: `Comptes Ségrégués`,
    licenseRegulations: `Licence & Régulations`,
    legalDocuments: `Documents Légaux`,
    kidsDocuments: `Documents d'information clés (KIDs)`,
    contact: `Contactez-nous`,
    partnerships: `Partenaires`,
    introducingBroker: `Apporteur d'Affaires`,
    cpa: `CPA Affiliates`,
    promotions: `Promotions
    `,
    news: `News`,
    menuItems: [
      {
        title: "Marchés",
        submenu: [
          {
            title: "Forex",
            link: `markets/forex`,
            license: ``,
            animationDelay: "0.05s",
          },
          {
            title: "Indices",
            link: `markets/indices`,
            license: ``,
            animationDelay: "0.10s",
          },
          {
            title: "Métaux",
            link: `markets/metals`,
            license: ``,
            animationDelay: "0.15s",
          },
          {
            title: "Cryptos",
            link: `markets/cryptos`,
            license: ``,
            animationDelay: "0.20s",
          },
          {
            title: "Énergies",
            link: `markets/energies`,
            license: ``,
            animationDelay: "0.25s",
          },
          {
            title: "Actions",
            link: `markets/shares`,
            license: ``,
            animationDelay: "0.30s",
          },
          {
            title: "Conditions de Trading",
            link: `markets/trading-conditions`,
            license: ``,
            animationDelay: "0.35s",
          },
        ],
      },
      {
        title: "Comptes",
        submenu: [
          {
            title: "Types de Comptes",
            link: `accounts/accounts-overview`,
            license: ``,
            animationDelay: "0.05s",
          },
          {
            title: "HNI & Clients Institutionnels",
            link: `accounts/hni`,
            license: ``,
            animationDelay: "0.10s",
          },
          {
            title: "Traders Professionnels",
            link: `accounts/professional-traders`,
            license: `eu`,
            animationDelay: "0.15s",
          },
          {
            title: "Dépôts & Retraits",
            link: `accounts/funding-and-withdrawals`,
            license: ``,
            animationDelay: "0.20s",
          },
        ],
      },
      {
        title: "Plateformes",

        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: ``,
            animationDelay: "0.05s",
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: ``,
            animationDelay: "0.10s",
          },
          // {
          //   title: "Mobile App",
          //   link: `platforms/mobile-app`,
          //   license: ``,
          //   animationDelay: "0.10s",
          // },
          {
            title: "Mobile Trading",
            link: `platforms/mobile-trading`,
            license: ``,
            animationDelay: "0.15s",
          },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: ``,
            animationDelay: "0.20s",
          },
          // {
          //   title: "Social Trading",
          //   link: `platforms/social-trading`,
          //   license: ``,
          //   animationDelay: "0.25s",
          // },
          {
            title: "FIX API",
            link: `platforms/fix-api`,
            license: ``,
            animationDelay: "0.30s",
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: ``,
            animationDelay: "0.35s",
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: ``,
            animationDelay: "0.40s",
          },
        ],
      },
      {
        title: "Entreprise",
        submenu: [
          {
            title: "À propos de nous",
            link: `company/meet-us`,
            license: ``,
            animationDelay: "0.05s",
          },
          {
            title: "Comptes Ségrégués",
            link: `company/segregated-funds`,
            license: ``,
            animationDelay: "0.10s",
          },
          {
            title: "Licence & Régulations",
            link: `company/license-and-regulations`,
            license: ``,
            animationDelay: "0.15s",
          },
          {
            title: "Documents Légaux",
            link: `company/legal-documents`,
            license: ``,
            animationDelay: "0.20s",
          },
          {
            title: "Documents d'information clés (KIDs)",
            link: `company/kids`,
            license: "eu",
            animationDelay: "0.20s",
          },
          {
            title: "Contactez-nous",
            link: `company/contact`,
            license: ``,
            animationDelay: "0.25s",
          },

          {
            title: "Glossaire",
            link: `company/glossary`,
            license: ``,
            animationDelay: "0.15s",
          },
          {
            title: "FAQ",
            link: `company/faq`,
            license: ``,
            animationDelay: "0.10s",
          },
        ],
      },
      {
        title: "Partenaires",
        submenu: [
          {
            title: "Apporteur d'Affaires",
            link: `partnerships/introducing-brokers`,
            license: ``,
            animationDelay: "0.05s",
          },
          {
            title: "CPA Affiliates",
            link: `partnerships/cpa-affiliates`,
            license: ``,
            animationDelay: "0.10s",
          },
          {
            title: "Promotions",
            link: `partnerships/promotions`,
            license: `global`,
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Sélectionnez votre langue",
  },
  it: {
    markets: `Mercati`,
    forex: `Forex`,
    indices: `Indici`,
    metals: `Metalli `,
    cryptos: `Crypto`,
    energies: `Energie`,
    shares: `Azioni`,
    tradingConditions: `Condizioni di Trading`,
    accounts: `Conti`,
    accountsOverview: `Tipi di Conto`,
    hni: `HNI & Clienti Istituzionali`,
    proTraders: `Traders Professionisti`,
    fundingWithdrawals: `Depositi e Prelievi`,
    platforms: `Plattaforme`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Trading Mobile`,
    webTrader: `WebTrader`,
    socialTrading: `Social Trading`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `FAQ`,
    glossary: `Glossario`,
    company: `Compagnia`,
    aboutUs: "Su di Noi",
    segregatedFunds: `Fondi Segregati`,
    licenseRegulations: `Licenze e Regolamentazioni`,
    legalDocuments: `Documenti Legali`,
    kidsDocuments: `Documenti di informazione chiave (KIDs)`,
    contact: `Contatti`,
    partnerships: `Partners`,
    introducingBroker: `Presenta il Broker`,
    cpa: `CPA Affiliates`,
    promotions: `Promozioni
    `,
    news: `News`,
    menuItems: [
      {
        title: "Mercati",
        submenu: [
          {
            title: "Forex",
            link: `markets/forex`,
            license: ``,
          },
          {
            title: "Indici",
            link: `markets/indices`,
            license: ``,
          },
          {
            title: "Metalli",
            link: `markets/metals`,
            license: ``,
          },
          {
            title: "Crypto",
            link: `markets/cryptos`,
            license: ``,
          },
          {
            title: "Energie",
            link: `markets/energies`,
            license: ``,
          },
          {
            title: "Azioni",
            link: `markets/shares`,
            license: ``,
          },
          {
            title: "Condizioni di trading",
            link: `markets/trading-conditions`,
            license: ``,
          },
        ],
      },
      {
        title: "Conti",
        submenu: [
          {
            title: "Tipi di Conto",
            link: `accounts/accounts-overview`,
            license: ``,
          },
          {
            title: "HNI & Clienti Istituzionali",
            link: `accounts/hni`,
            license: ``,
          },
          {
            title: "Traders Professionisti",
            link: `accounts/professional-traders`,
            license: `eu`,
          },
          {
            title: "Depositi e Prelievi",
            link: `accounts/funding-and-withdrawals`,
            license: ``,
          },
        ],
      },
      {
        title: "Piattaforme",
        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: ``,
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: ``,
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "Trading Mobile",
          //   link: `platforms/mobile-trading`,
          //   license: ``,
          // },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: ``,
          },
          // {
          //   title: "Social Trading",
          //   link: `platforms/social-trading`,
          //   license: ``,
          // },
          {
            title: "FIX API",
            link: `platforms/fix-api`,
            license: ``,
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: ``,
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: ``,
          },
        ],
      },
      {
        title: "Compagnia",
        submenu: [
          {
            title: "Su di Noi",
            link: `company/meet-us`,
            license: ``,
          },
          {
            title: "Fondi Segregati",
            link: `company/segregated-funds`,
            license: ``,
          },
          {
            title: "Licenze e regolamentazioni",
            link: `company/license-and-regulations`,
            license: ``,
          },
          {
            title: "Documenti Legali",
            link: `company/legal-documents`,
            license: ``,
          },
          {
            title: "Documenti di informazione chiave (KIDs)",
            link: `company/kids`,
            license: "eu",
            animationDelay: "0.20s",
          },
          {
            title: "Contatti",
            link: `company/contact`,
            license: ``,
          },
          {
            title: "Glossario",
            link: `company/glossary`,
            license: ``,
          },
          {
            title: "FAQ",
            link: `company/faq`,
            license: ``,
          },
        ],
      },
      {
        title: "Partners",
        submenu: [
          {
            title: "Presenta il Broker ",
            link: `partnerships/introducing-brokers`,
            license: ``,
          },
          {
            title: "CPA Affiliates",
            link: `partnerships/cpa-affiliates`,
            license: ``,
          },
          {
            title: "Promotions",
            link: `partnerships/promotions`,
            license: `global`,
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Choisissez votre langue",
  },
  de: {
    markets: `Märkte`,
    forex: `Forex`,
    indices: `Indizes`,
    metals: `Edelmetalle`,
    cryptos: `Kryptos`,
    energies: `Energien`,
    shares: `Aktien`,
    tradingConditions: `Handelsbedingungen`,
    accounts: `Konten`,
    accountsOverview: `Kontotypen`,
    hni: `HNI & Institutionelle Kunden`,
    proTraders: `Professionelle Trader`,
    fundingWithdrawals: `Einzahlungen & Auszahlungen`,
    platforms: `Plattformen`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Mobiles Traden`,
    webTrader: `WebTrader`,
    socialTrading: `Social Trading`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `FAQ`,
    glossary: `Lexikon`,
    company: `Unternehmen`,
    aboutUs: "Über uns",
    segregatedFunds: `Segregierte Konten`,
    licenseRegulations: `Lizenzen & Regulierungen`,
    legalDocuments: `Rechtliche Dokumente`,
    kidsDocuments: `Wichtige Informationsdokumente (KIDs)`,
    contact: `Kontakt`,
    partnerships: `Partner`,
    introducingBroker: `IB Introducing Broker`,
    cpa: `CPA Affiliates`,
    promotions: `Promotions
    `,
    news: `News`,
    menuItems: [
      {
        title: "Märkte",
        submenu: [
          {
            title: "Forex",
            link: `markets/forex`,
            license: ``,
          },
          {
            title: "Indizes",
            link: `markets/indices`,
            license: ``,
          },
          {
            title: "Edelmetalle",
            link: `markets/metals`,
            license: ``,
          },
          {
            title: "Kryptos",
            link: `markets/cryptos`,
            license: ``,
          },
          {
            title: "Energien",
            link: `markets/energies`,
            license: ``,
          },
          {
            title: "Aktien",
            link: `markets/shares`,
            license: ``,
          },
          {
            title: "Handelsbedingungen",
            link: `markets/trading-conditions`,
            license: ``,
          },
        ],
      },
      {
        title: "Konten",
        submenu: [
          {
            title: "Kontotypen",
            link: `accounts/accounts-overview`,
            license: ``,
          },
          {
            title: "HNI & Institutionelle Kunden",
            link: `accounts/hni`,
            license: ``,
          },
          {
            title: "Professionelle Trader",
            link: `accounts/professional-traders`,
            license: `eu`,
          },
          {
            title: "Einzahlungen & Auszahlungen",
            link: `accounts/funding-and-withdrawals`,
            license: ``,
          },
        ],
      },
      {
        title: "Plattformen",
        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: ``,
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: ``,
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "Mobiles Traden",
          //   link: `platforms/mobile-trading`,
          //   license: ``,
          // },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: ``,
          },
          // {
          //   title: "Social Trading",
          //   link: `platforms/social-trading`,
          //   license: ``,
          // },
          {
            title: "FIX API",
            link: `platforms/fix-api`,
            license: ``,
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: ``,
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: ``,
          },
        ],
      },
      {
        title: "Unternehmen",
        submenu: [
          {
            title: "Über uns",
            link: `company/meet-us`,
            license: ``,
          },
          {
            title: "Getrennte Konten",
            link: `company/segregated-funds`,
            license: ``,
          },
          {
            title: "Lizenzen & Regulierungen",
            link: `company/license-and-regulations`,
            license: ``,
          },
          {
            title: "Rechtliche Dokumente",
            link: `company/legal-documents`,
            license: ``,
          },
          {
            title: "Wichtige Informationsdokumente (KIDs)",
            link: `company/kids`,
            license: "eu",
            animationDelay: "0.20s",
          },
          {
            title: "Kontakt",
            link: `company/contact`,
            license: ``,
          },
          {
            title: "Lexikon",
            link: `company/glossary`,
            license: ``,
          },
          {
            title: "FAQ",
            link: `company/faq`,
            license: ``,
          },
        ],
      },
      {
        title: "Partner",
        submenu: [
          {
            title: "Einführender Broker",
            link: `partnerships/introducing-brokers`,
            license: ``,
          },
          {
            title: "CPA Affiliates",
            link: `partnerships/cpa-affiliates`,
            license: ``,
          },
          {
            title: "Promotions",
            link: `partnerships/promotions`,
            license: `global`,
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Wählen Sie Ihre Sprache",
  },
  cn: {
    markets: `市场`,
    forex: `外汇`,
    indices: `指数`,
    metals: `金属`,
    cryptos: `加密货币`,
    energies: `能源`,
    shares: `股票`,
    tradingConditions: `交易条件`,
    accounts: `帐户`,
    accountsOverview: `账户概览`,
    hni: `高净值和机构客户`,
    proTraders: `专业交易员`,
    fundingWithdrawals: `入金和出金`,
    platforms: `平台`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `移动交易`,
    webTrader: `网页交易`,
    socialTrading: `社交交易`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `常问问题`,
    glossary: `词汇表`,
    company: `公司`,
    aboutUs: "关于我们",
    segregatedFunds: `资金隔离`,
    licenseRegulations: `牌照与监管`,
    legalDocuments: `法律文件`,
    contact: `联系我们`,
    partnerships: `合作伙伴`,
    introducingBroker: `介绍经纪商`,
    cpa: `CPA联盟营销计划`,
    promotions: `促销活动`,
    news: `消息`,
    menuItems: [
      {
        title: "市场",
        submenu: [
          {
            title: "外汇",
            link: `markets/forex`,
            license: ``,
          },
          {
            title: "指数",
            link: `markets/indices`,
            license: ``,
          },
          {
            title: "金属",
            link: `markets/metals`,
            license: ``,
          },
          {
            title: "加密货币",
            link: `markets/cryptos`,
            license: ``,
          },
          {
            title: "能源",
            link: `markets/energies`,
            license: ``,
          },
          {
            title: "股票",
            link: `markets/shares`,
            license: ``,
          },
          {
            title: "交易条件",
            link: `markets/trading-conditions`,
            license: ``,
          },
        ],
      },
      {
        title: "帐户",
        submenu: [
          {
            title: "账户概览",
            link: `accounts/accounts-overview`,
            license: ``,
          },
          {
            title: "高净值和机构客户",
            link: `accounts/hni`,
            license: ``,
          },
          {
            title: "专业交易员",
            link: `accounts/professional-traders`,
            license: `eu`,
          },
          {
            title: "入金和出金",
            link: `accounts/funding-and-withdrawals`,
            license: ``,
          },
        ],
      },
      {
        title: "平台",
        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: ``,
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: ``,
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "移动交易",
          //   link: `platforms/mobile-trading`,
          //   license: ``,
          // },
          {
            title: "网页交易",
            link: `platforms/webtrader`,
            license: ``,
          },
          {
            title: "社交交易",
            link: `platforms/social-trading`,
            license: ``,
          },
          {
            title: "Fix API",
            link: `platforms/fix-api`,
            license: ``,
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: ``,
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: ``,
          },
        ],
      },
      {
        title: "公司",
        submenu: [
          {
            title: "关于我们",
            link: `company/meet-us`,
            license: ``,
          },
          {
            title: "资金隔离",
            link: `company/segregated-funds`,
            license: ``,
          },
          {
            title: "牌照与监管",
            link: `company/license-and-regulations`,
            license: ``,
          },
          {
            title: "法律文件",
            link: `company/legal-documents`,
            license: ``,
          },
          {
            title: "联系我们",
            link: `company/contact`,
            license: ``,
          },
          {
            title: "词汇表",
            link: `company/glossary`,
            license: ``,
          },
          {
            title: "常问问题",
            link: `company/faq`,
            license: ``,
          },
        ],
      },
      {
        title: "合作伙伴",
        submenu: [
          {
            title: "介绍经纪商",
            link: `partnerships/introducing-brokers`,
            license: ``,
          },
          {
            title: "CPA联盟营销计划",
            link: `partnerships/cpa-affiliates`,
            license: ``,
          },
          {
            title: "促销活动",
            link: `partnerships/promotions`,
            license: `global`,
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "选择您的语言",
  },
  es: {
    markets: `Mercados`,
    forex: `Forex`,
    indices: `Índices`,
    metals: `Metales`,
    cryptos: `Criptos`,
    energies: `Energías`,
    shares: `Acciones`,
    tradingConditions: `Condiciones de Trading`,
    accounts: `Cuentas`,
    accountsOverview: `Resumen de Cuentas`,
    hni: `HNI y Clientes Institucionales`,
    proTraders: `Traders Profesionales`,
    fundingWithdrawals: `Depositos y Retiros`,
    platforms: `Plataformas`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Trading Móvil`,
    webTrader: `WebTrader`,
    socialTrading: `Social Trading`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `Perguntas Frequentes`,
    glossary: `Glosario`,
    company: `Empresa`,
    aboutUs: "Quiénes somos",
    segregatedFunds: `Fondos Segregados`,
    licenseRegulations: `Licencia y Reglamento`,
    legalDocuments: `Documentos Legales`,
    kidsDocuments: `Documentos de información clave (KIDs)`,
    contact: `Contacto`,
    partnerships: `Asociaciones`,
    introducingBroker: `Presentador de Broker`,
    cpa: `Afiliado CPA`,
    promotions: `Promociones`,
    news: `News`,
    menuItems: [
      {
        title: "Mercados",
        submenu: [
          {
            title: "Forex",
            link: `markets/forex`,
            license: ``,
          },
          {
            title: "Índices",
            link: `markets/indices`,
            license: ``,
          },
          {
            title: "Metales",
            link: `markets/metals`,
            license: ``,
          },
          {
            title: "Criptos",
            link: `markets/cryptos`,
            license: ``,
          },
          {
            title: "Energías",
            link: `markets/energies`,
            license: ``,
          },
          {
            title: "Acciones",
            link: `markets/shares`,
            license: ``,
          },
          {
            title: "Condiciones de trading",
            link: `markets/trading-conditions`,
            license: ``,
          },
        ],
      },
      {
        title: "Cuentas",
        submenu: [
          {
            title: "Resumen de cuentas",
            link: `accounts/accounts-overview`,
            license: ``,
          },
          {
            title: "HNI y clientes institucionales",
            link: `accounts/hni`,
            license: ``,
          },
          {
            title: "Traders profesionales",
            link: `accounts/professional-traders`,
            license: `eu`,
          },
          {
            title: "Depositos y Retiros",
            link: `accounts/funding-and-withdrawals`,
            license: ``,
          },
        ],
      },
      {
        title: "Plataformas",
        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: ``,
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: ``,
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "Trading Móvil",
          //   link: `platforms/mobile-trading`,
          //   license: ``,
          // },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: ``,
          },
          // {
          //   title: "Social Trading",
          //   link: `platforms/social-trading`,
          //   license: ``,
          // },
          {
            title: "FIX API",
            link: `platforms/fix-api`,
            license: ``,
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: ``,
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: ``,
          },
        ],
      },
      {
        title: "Empresa",
        submenu: [
          {
            title: "Quiénes somos",
            link: `company/meet-us`,
            license: ``,
          },
          {
            title: "Fondos Segregados",
            link: `company/segregated-funds`,
            license: ``,
          },
          {
            title: "Licencia y Reglamento",
            link: `company/license-and-regulations`,
            license: ``,
          },
          {
            title: "Documentos Legales",
            link: `company/legal-documents`,
            license: ``,
          },
          {
            title: "Documentos de información clave (KIDs)",
            link: `company/kids`,
            license: "eu",
            animationDelay: "0.20s",
          },
          {
            title: "Contacto",
            link: `company/contact`,
            license: ``,
          },
          {
            title: "Glosario",
            link: `company/glossary`,
            license: ``,
          },
          {
            title: "Perguntas Frequentes",
            link: `company/faq`,
            license: ``,
          },
        ],
      },
      {
        title: "Asociaciones",
        submenu: [
          {
            title: "Presentador de Broker",
            link: `partnerships/introducing-brokers`,
            license: ``,
          },
          {
            title: "Afiliado CPA",
            link: `partnerships/cpa-affiliates`,
            license: ``,
          },
          {
            title: "Promociones",
            link: `partnerships/promotions`,
            license: `global`,
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Seleccione su idioma",
  },
  vnm: {
    markets: `Thị trường`,
    forex: `Ngoại hối`,
    indices: `Chỉ số`,
    metals: `Kim loại`,
    cryptos: `Tiền điện tử`,
    energies: `Năng lượng`,
    shares: `Cổ phiếu`,
    tradingConditions: `Điều kiện giao dịch`,
    accounts: `Tài khoản`,
    accountsOverview: `Tổng quan về tài khoản`,
    hni: `HNI & Khách hàng tổ chức`,
    proTraders: `Nhà giao dịch chuyên nghiệp`,
    fundingWithdrawals: `Nạp tiền & Rút tiền`,
    platforms: `Nền tảng`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Giao dịch di động`,
    webTrader: `WebTrader`,
    socialTrading: `Giao dịch xã hội`,
    fixApi: `Sửa lỗi API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `Câu hỏi thường gặp`,
    glossary: `Bảng chú giải`,
    company: `Công ty`,
    aboutUs: "Về chúng tôi",
    segregatedFunds: `Quỹ tách biệt`,
    licenseRegulations: `Giấy phép & Quy định`,
    legalDocuments: `Tài liệu pháp lý`,
    contact: `Liên hệ`,
    partnerships: `Quan hệ đối tác`,
    introducingBroker: `Giới thiệu nhà môi giới`,
    cpa: `Liên kết CPA`,
    promotions: `Khuyến mại
    `,
    news: `News`,
    // mobile menu

    menuItems: [
      {
        title: "Thị trường",
        submenu: [
          {
            title: "Ngoại hối",
            link: `markets/forex`,
            license: "",
          },
          {
            title: "Chỉ số",
            link: `markets/indices`,
            license: "",
          },
          {
            title: "Kim loại",
            link: `markets/metals`,
            license: "",
          },
          {
            title: "Tiền điện tử",
            link: `markets/cryptos`,
            license: "",
          },
          {
            title: "Năng lượng",
            link: `markets/energies`,
            license: "",
          },
          {
            title: "Cổ phiếu",
            link: `markets/shares`,
            license: "",
          },
          {
            title: "Điều kiện giao dịch",
            link: `markets/trading-conditions`,
            license: "",
          },
        ],
      },
      {
        title: "Tài khoản",
        submenu: [
          {
            title: "Tổng quan về tài khoản",
            link: `accounts/accounts-overview`,
            license: "",
          },
          {
            title: "HNI & Khách hàng tổ chức",
            link: `accounts/hni`,
            license: "",
          },
          {
            title: "Nhà giao dịch chuyên nghiệp",
            link: `accounts/professional-traders`,
            license: "eu",
          },
          {
            title: "Nạp tiền & Rút tiền",
            link: `accounts/funding-and-withdrawals`,
            license: "",
          },
        ],
      },
      {
        title: "Nền tảng",
        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: "",
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: "",
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "Giao dịch di động",
          //   link: `platforms/mobile-trading`,
          //   license: "",
          // },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: "",
          },
          {
            title: "Giao dịch xã hội",
            link: `platforms/social-trading`,
            license: "",
          },
          {
            title: "Sửa lỗi API",
            link: `platforms/fix-api`,
            license: "",
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: "",
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: "",
          },
        ],
      },
      {
        title: "Công ty",
        submenu: [
          {
            title: "Về chúng tôi",
            link: `company/meet-us`,
            license: "",
          },
          {
            title: "Quỹ tách biệt",
            link: `company/segregated-funds`,
            license: "",
          },
          {
            title: "Giấy phép & Quy định",
            link: `company/license-and-regulations`,
            license: "",
          },
          {
            title: "Tài liệu pháp lý",
            link: `company/legal-documents`,
            license: "",
          },
          {
            title: "Liên hệ",
            link: `company/contact`,
            license: "",
          },
          {
            title: "Bảng chú giải",
            link: `company/glossary`,
            license: "",
          },
          {
            title: "Câu hỏi thường gặp",
            link: `company/faq`,
            license: "",
          },
        ],
      },
      {
        title: "Quan hệ đối tác",
        submenu: [
          {
            title: "Giới thiệu nhà môi giới",
            link: `partnerships/introducing-brokers`,
            license: "",
          },
          {
            title: "Liên kết CPA",
            link: `partnerships/cpa-affiliates`,
            license: "",
          },
          {
            title: "Khuyến mại",
            link: `partnerships/promotions`,
            license: "global",
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Chọn ngôn ngữ của bạn",
  },
  enUae: {
    markets: `Markets`,
    forex: `Forex`,
    indices: `Indices`,
    metals: `Metals`,
    cryptos: `Cryptos`,
    energies: `Energies`,
    shares: `Shares`,
    tradingConditions: `Trading Conditions`,
    accounts: `Accounts`,
    accountsOverview: `Accounts Overview`,
    hni: `HNI & Institutional Clients`,
    proTraders: `Professional Traders`,
    fundingWithdrawals: `Funding & Withdrawals`,
    swappFree: `Islamic Swap Free Account`,
    platforms: `Platforms`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Mobile Trading`,
    webTrader: `WebTrader`,
    socialTrading: `Social Trading`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `FAQ`,
    glossary: `Glossary`,
    company: `Company`,
    aboutUs: "About Us",
    segregatedFunds: `Segregated Funds`,
    licenseRegulations: `License & Regulations`,
    legalDocuments: `Legal Documents`,
    contact: `Contact`,
    partnerships: `Partnerships`,
    introducingBroker: `Introducing Broker`,
    cpa: `CPA Affiliates`,
    promotions: `Promotions
    `,
    news: "News",
    // mobile menu

    menuItems: [
      {
        title: "Markets",
        submenu: [
          {
            title: "Forex",
            link: `markets/forex`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Indices",
            link: `markets/indices`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Metals",
            link: `markets/metals`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "Cryptos",
            link: `markets/cryptos`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "Energies",
            link: `markets/energies`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "Shares",
            link: `markets/shares`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "Trading Conditions",
            link: `markets/trading-conditions`,
            license: "",
            animationDelay: "0.35s",
          },
        ],
      },
      {
        title: "Accounts",
        submenu: [
          {
            title: "Accounts Overview",
            link: `accounts/accounts-overview`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "HNI & Institutional Clients",
            link: `accounts/hni`,
            license: "",
            animationDelay: "0.10s",
          },
          //only eu
          {
            title: "Professionatl Traders",
            link: `accounts/professional-traders`,
            license: "eu",
            animationDelay: `0.15s`,
          },
          {
            title: "Funding & Withdrawals",
            link: `accounts/funding-and-withdrawals`,
            license: "",
            animationDelay: `${license == "global" ? "0.15" : "0.20"}s`,
          },
          {
            title: "Islamic Swap Free Account",
            link: `accounts/swap-free`,
            license: "",
            animationDelay: `${license == "global" ? "0.20" : "0.25"}s`,
          },
        ],
      },
      {
        title: "Platforms",
        submenu: [
          // temporary hidden on benor
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: "",
            animationDelay: "0.10s",
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "Mobile Trading",
          //   link: `platforms/mobile-trading`,
          //   license: "",
          //   animationDelay: "0.15s",
          // },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: "",
            animationDelay: "0.20s",
          },
          // {
          //   title: "Social Trading",
          //   link: `platforms/social-trading`,
          //   license: "",
          //   animationDelay: "0.25s",
          // },
          {
            title: "FIX API",
            link: `platforms/fix-api`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: "",
            animationDelay: "0.35s",
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: "",
            animationDelay: "0.40s",
          },
        ],
      },
      {
        title: "Company",
        submenu: [
          {
            title: "About Us",
            link: `company/meet-us`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Segregated Funds",
            link: `company/segregated-funds`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Licenses & Regulations",
            link: `company/license-and-regulations`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "Legal Documents",
            link: `company/legal-documents`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "Contact",
            link: `company/contact`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "Glossary",
            link: `company/glossary`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "FAQ",
            link: `company/faq`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
      {
        title: "Partnerships",
        submenu: [
          {
            title: "Introducing Brokers",
            link: `partnerships/introducing-brokers`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "CPA Affiliates",
            link: `partnerships/cpa-affiliates`,
            license: "",
            animationDelay: "0.10s",
          },
          // only benor
          {
            title: "Promotions",
            link: `partnerships/promotions`,
            license: "global",
            animationDelay: "0.15s",
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Select Your Language",
  },
  arb: {
    markets: `الأسواق`,
    forex: `فوركس`,
    indices: `المؤشرات`,
    metals: `المعادن`,
    cryptos: `العملات المشفرة`,
    energies: `الطاقة`,
    shares: `الأسهم`,
    tradingConditions: `شروط التداول`,
    accounts: `الحسابات`,
    accountsOverview: `أنواع الحسابات`,
    hni: `الأثرياء & عملاء المؤسسات`,
    proTraders: `المتداولون المحترفون`,
    fundingWithdrawals: `التمويل والسحب`,
    swappFree: `حساب إسلامي خالي من الفوائد`,
    platforms: `المنصات`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `تداول عبر الهواتف`,
    webTrader: `التداول عبر الويب`,
    socialTrading: `التداول الاجتماعي`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `الأسئلة المتكررة`,
    glossary: `قاموس المصطلحات`,
    company: `الشركة`,
    aboutUs: "معلومات عنا",
    segregatedFunds: `الصناديق المنفصلة`,
    licenseRegulations: `التراخيص واللوائح`,
    legalDocuments: `الوثائق القانونية`,
    contact: `التواصل`,
    partnerships: `الشراكة`,
    introducingBroker: `الوسيط المعرف`,
    cpa: `تكلفة الاكتساب`,
    promotions: `الترقيات`,
    news: "الأخبار",
    // mobile menu

    menuItems: [
      {
        title: "الأسواق",
        submenu: [
          {
            title: "فوركس",
            link: `markets/forex`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "المؤشرات",
            link: `markets/indices`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "المعادن",
            link: `markets/metals`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "العملات المشفرة",
            link: `markets/cryptos`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "الطاقة",
            link: `markets/energies`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "الأسهم",
            link: `markets/shares`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "شروط التداول",
            link: `markets/trading-conditions`,
            license: "",
            animationDelay: "0.35s",
          },
        ],
      },
      {
        title: "الحسابات",
        submenu: [
          {
            title: "أنواع الحسابات",
            link: `accounts/accounts-overview`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "الأثرياء & عملاء المؤسسات",
            link: `accounts/hni`,
            license: "",
            animationDelay: "0.10s",
          },
          //only eu
          {
            title: "المتداولون المحترفون",
            link: `accounts/professional-traders`,
            license: "eu",
            animationDelay: `0.15s`,
          },
          {
            title: "التمويل والسحب",
            link: `accounts/funding-and-withdrawals`,
            license: "",
            animationDelay: `${license == "global" ? "0.15" : "0.20"}s`,
          },
          {
            title: "حساب إسلامي خالي من الفوائد",
            link: `accounts/swap-free`,
            license: "",
            animationDelay: `${license == "global" ? "0.20" : "0.25"}s`,
          },
        ],
      },
      {
        title: "المنصات",
        submenu: [
          // temporary hidden on benor
          {
            title: "منصة ميتاتريدر 4",
            link: `platforms/meta-trader`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: "",
            animationDelay: "0.10s",
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "تداول عبر الهواتف",
          //   link: `platforms/mobile-trading`,
          //   license: "",
          //   animationDelay: "0.15s",
          // },
          {
            title: "التداول عبر الويب",
            link: `platforms/web-trader`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "التداول الاجتماعي",
            link: `platforms/social-trading`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "FIX API",
            link: `platforms/fix-api`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: "",
            animationDelay: "0.35s",
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: "",
            animationDelay: "0.40s",
          },
        ],
      },
      {
        title: "معلومات عنا",
        submenu: [
          {
            title: "قابلنا",
            link: `company/meet-us`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "الصناديق المنفصلة",
            link: `company/segregated-funds`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "التراخيص واللوائح",
            link: `company/license-and-regulations`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "الوثائق القانونية",
            link: `company/legal-documents`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "التواصل",
            link: `company/contact`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "الأسئلة المتكررة",
            link: `company/glossary`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "قاموس المصطلحات",
            link: `company/faq`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
      {
        title: "الشراكة",
        submenu: [
          {
            title: "الوسيط المعرف",
            link: `partnerships/introducing-brokers`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "تكلفة الاكتساب",
            link: `partnerships/cpa-affiliates`,
            license: "",
            animationDelay: "0.10s",
          },
          // only benor
          {
            title: "الترقيات",
            link: `partnerships/promotions`,
            license: "global",
            animationDelay: "0.15s",
          },
        ],
      },
      {
        title: "الأخبار",
        submenu: [
          {
            title: "مقالات عامة",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "تحليل السوق",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "اختر لغتك",
  },
  my: {
    markets: `Pasaran`,
    forex: `Forex`,
    indices: `Indeks`,
    metals: `Logam`,
    cryptos: `Cryptos`,
    energies: `Tenaga`,
    shares: `Saham`,
    tradingConditions: `Syarat Perdagangan`,
    accounts: `Akaun`,
    accountsOverview: `Gambaran Keseluruhan Akaun`,
    hni: `Pelanggan HNI & Institusi`,
    proTraders: `Peniaga Profesional`,
    fundingWithdrawals: `Pembiayaan & Pengeluaran`,
    platforms: `Platforms`,
    metaTrader: `MetaTrader 4`,
    xTend: `MT4 TrioXtend`,
    mApp: `Mobile App`,
    mobileTrading: `Perdagangan Mudah Alih`,
    webTrader: `WebTrader`,
    socialTrading: `Perdagangan Sosial`,
    fixApi: `Fix API`,
    vps: `VPS`,
    mam: `PAMM/MAM`,
    faq: `Soalan Lazim`,
    glossary: `Glosari`,
    company: `Syarikat`,
    aboutUs: "Tentang Kami",
    segregatedFunds: `Dana Terpisah`,
    licenseRegulations: `Lesen & Peraturan`,
    legalDocuments: `Dokumen Undang-Undang`,
    contact: `Hubungi`,
    partnerships: `Perkongsian`,
    introducingBroker: `Memperkenalkan Broker`,
    cpa: `Gabungan CPA`,
    promotions: `Promosi`,
    news: "Berita",

    // mobile menu

    menuItems: [
      {
        title: "Pasaran",
        submenu: [
          {
            title: "Forex",
            link: `markets/forex`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Indeks",
            link: `markets/indices`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Logam",
            link: `markets/metals`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "Cryptos",
            link: `markets/cryptos`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "Tenaga",
            link: `markets/energies`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "Saham",
            link: `markets/shares`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "Syarat Perdagangan",
            link: `markets/trading-conditions`,
            license: "",
            animationDelay: "0.35s",
          },
        ],
      },
      {
        title: "Akaun",
        submenu: [
          {
            title: "Gambaran Keseluruhan Akaun",
            link: `accounts/accounts-overview`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Pelanggan HNI & Institusi",
            link: `accounts/hni`,
            license: "",
            animationDelay: "0.10s",
          },
          //only eu
          {
            title: "Peniaga Profesional",
            link: `accounts/professional-traders`,
            license: "eu",
            animationDelay: `0.15s`,
          },
          {
            title: "Pembiayaan & Pengeluaran",
            link: `accounts/funding-and-withdrawals`,
            license: "",
            animationDelay: `${license == "global" ? "0.15" : "0.20"}s`,
          },
        ],
      },
      {
        title: "Platforms",
        submenu: [
          {
            title: "MetaTrader 4",
            link: `platforms/meta-trader`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "MT4 TrioXtend",
            link: `platforms/trio-xtend`,
            license: "",
            animationDelay: "0.10s",
          },
          // {
          //title: "Mobile App",
          //  link: `platforms/mobile-app`,
          //  license: "",
          //   animationDelay: "0.15s",
          //   },
          // {
          //   title: "Perdagangan Mudah Alih",
          //   link: `platforms/mobile-trading`,
          //   license: "",
          //   animationDelay: "0.15s",
          // },
          {
            title: "WebTrader",
            link: `platforms/web-trader`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "Perdagangan Sosial",
            link: `platforms/social-trading`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "Fix API",
            link: `platforms/fix-api`,
            license: "",
            animationDelay: "0.30s",
          },
          {
            title: "VPS",
            link: `platforms/vps`,
            license: "",
            animationDelay: "0.35s",
          },
          {
            title: "PAMM/MAM",
            link: `platforms/mam`,
            license: "",
            animationDelay: "0.40s",
          },
        ],
      },
      {
        title: "Syarikat",
        submenu: [
          {
            title: "Tentang Kami",
            link: `company/meet-us`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Dana Terpisah",
            link: `company/segregated-funds`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Lesen & Peraturan",
            link: `company/license-and-regulations`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "Dokumen Undang-Undang",
            link: `company/legal-documents`,
            license: "",
            animationDelay: "0.20s",
          },
          {
            title: "Hubungi",
            link: `company/contact`,
            license: "",
            animationDelay: "0.25s",
          },
          {
            title: "Glosari",
            link: `company/glossary`,
            license: "",
            animationDelay: "0.15s",
          },
          {
            title: "Soalan Lazim",
            link: `company/faq`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
      {
        title: "Perkongsian",
        submenu: [
          {
            title: "Memperkenalkan Broker",
            link: `partnerships/introducing-brokers`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Gabungan CPA",
            link: `partnerships/cpa-affiliates`,
            license: "",
            animationDelay: "0.10s",
          },
          // only benor
          {
            title: "Promosi",
            link: `partnerships/promotions`,
            license: "global",
            animationDelay: "0.15s",
          },
        ],
      },
      {
        title: "News",
        submenu: [
          {
            title: "General Arcticles",
            link: `news/articles`,
            license: "",
            animationDelay: "0.05s",
          },
          {
            title: "Market Analysis",
            link: `news/marketAnalysis`,
            license: "",
            animationDelay: "0.10s",
          },
          {
            title: "Economic Calendar",
            link: `news/economicCalendar`,
            license: "",
            animationDelay: "0.10s",
          },
        ],
      },
    ],
    selectYourLanguage: "Pilih Bahasa Anda",
  },
};
