<template>
  <div>
    <MarketsTop
      :market="{
        video: 'cryptos',
        title: $t('cryptos'),
        desc: $t('cryptosDesc'),
      }"
    />
    <div class="container-fluid">
      <div class="row d-flex justify-content-center mt-5">
        <div class="col-xl-9 col-12">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-8 col-11 px-sm-3 px-0">
              <Quotes
                v-slidein
                :symbols="['BTCUSD', 'ETHUSD', 'LTCUSD', 'XRPUSD']"
                :product="{ popular: $t('cryptosPopular') }"
              />
            </div>
            <div class="col-xl-4 col-11">
              <QuoteDetails v-slideinright />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(item, idx) in $tm('cryptosInfo')"
      :key="idx"
      class="container mb-5"
    >
      <div class="row d-flex justify-content-center my-5">
        <h3 class="col-12 title text-center" v-html="item.why"></h3>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          v-for="(desc, idx) in item.points"
          :key="idx"
          v-scrollanimation
          class="col-lg-3 col-md-6 col-sm-10 col-12 d-flex flex-column align-items-center mb-lg-0 mb-3"
        >
          <img
            :src="`/img/markets/cryptos/${desc.img}.svg`"
            alt="icon"
            class="img-fluid icon mb-2"
          />
          <p class="text-center" v-html="desc.text"></p>
        </div>
      </div>
      <div class="row d-flex justify-content-center my-5">
        <h2 class="col-12 title text-center">{{ item.moreInfo }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          v-for="(desc, idx) in item.pointsTwo"
          :key="idx"
          v-scrollanimation
          class="col-lg-3 col-md-6 col-sm-10 col-12 d-flex flex-column align-items-center"
        >
          <div
            class="forex-box boxes-cryptos text-center position-relative px-2 mb-lg-0 mb-4 d-flex flex-column justify-content-between"
          >
            <div>
              <img
                :src="`/img/markets/cryptos/${desc.img}.svg`"
                alt="icon"
                class="img-fluid icon bit mb-2"
              />
              <h3 class="text-center fw-bold fs-5 text-uppercase mt-3 mb-1">
                {{ desc.title }}
              </h3>
              <p class="text-center" v-html="desc.text"></p>
            </div>
            <div><a target="_blank" :href="registerURL" class="btn btn-blue">
              {{ $t("tradeNow") }}
            </a></div>
            
          </div>
        </div>
      </div>
    </div>
    <Accounts />
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation stepStyle="dark" />
  </div>
</template>
<script>
import { getters,mutations } from "../store";
import MarketsTop from "../components/MarketsTop.vue";
import Quotes from "../components/Quotes.vue";
import QuoteDetails from "../components/QuoteDetails.vue";
export default {
  name: "Cryptos",
  components: {
    MarketsTop,
    Quotes,
    QuoteDetails,
  },
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
  mounted() {
    mutations.setBoxHeight("boxes-cryptos","2");
    window.onresize = () => {
      mutations.setBoxHeight("boxes-cryptos","2");
    };
  },
};
</script>
<style lang="scss" scoped></style>
