<template>
  <div
    :class="`container-fluid pb-5 pt-1 ${
      platformStyle == 'dark' ? 'dark' : ''
    }`"
    id="platforms"
  >
    <div class="row d-flex justify-content-center my-5">
      <h2 class="col-12 title text-center p-0">{{ $t("choosePlatform") }}</h2>
      <hr class="blue" />
    </div>
    <div class="container">
      <div class="row">
        <div
          v-for="(platform, idx) in $tm('platform')"
          :key="idx"
          class="col-md-3 col-sm-6 d-flex flex-column align-items-center platform"
        >
          <img
            :src="`/img/platforms/${
              platformStyle == 'dark' ? platform.imgDark : platform.img
            }.svg`"
            class="img-fluid"
            alt="platform icon"
          />
          <span class="text-center my-3">{{ platform.name }}</span>
          <router-link
            :to="`/${$route.params.lang}/platforms/${platform.link}`"
            class="btn"
            >{{ $t("readMore") }}</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Platforms",
  props: {
    platformStyle: String,
  },
};
</script>
<style lang="scss" scoped></style>
