<template>
  <div>
    <div class="container my-5">
      <div class="row d-flex justify-content-center">
        <h2 class="title text-center">{{ $t("partnerFormTitle") }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          class="col-lg-8 col-md-10 col-12 text-center"
          v-html="$t('partnerFormText', { license: license })"
        ></div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-7 col-sm-10">
          <div
            v-for="(item, idx) in $tm('partnerFormInputs')"
            :key="idx"
            class="row d-flex justify-content-center inputs"
          >
            <div class="row col-12 d-flex justify-content-center mb-3">
              <input
                type="text"
                name="First Name"
                :placeholder="item.firstName"
                class="form-input text-center m-0"
                @blur="errors.fname = ''"
                v-model="contact_data.fname"
              />
              <div class="text-danger">
                {{ errors.fname }}
              </div>
            </div>
            <div class="row col-12 d-flex justify-content-center mb-3">
              <input
                type="text"
                name="Last Name"
                :placeholder="item.lastName"
                class="form-input text-center m-0"
                @blur="errors.lname = ''"
                v-model="contact_data.lname"
              />
              <div class="text-danger">
                {{ errors.lname }}
              </div>
            </div>
            <div class="row col-12 d-flex justify-content-center mb-3">
              <input
                type="text"
                name="Email"
                :placeholder="item.email"
                class="form-input text-center m-0"
                @blur="errors.email = ''"
                v-model="contact_data.email"
              />
              <div class="text-danger">
                {{ errors.email }}
              </div>
            </div>
            <div class="row col-12 d-flex justify-content-center">
              <textarea
                class="text-center m-0"
                name="message"
                cols="30"
                rows="10"
                :placeholder="item.message"
                v-model="contact_data.message"
              ></textarea>
              <div class="text-danger">
                {{ errors.message }}
              </div>
            </div>
            <div class="col-12 d-flex justify-content-center mt-2">
              <a @click.prevent="sendMail()" class="btn btn-blue text-white">{{
                $t("submitYourMessage")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getters } from "../store";
export default {
  name: "Form",
  data: () => ({
    contact_data: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      department: "",
      message: "",
    },
    errors: {},
  }),
  computed: {
    license() {
      return getters.license();
    },
  },
  methods: {
    sendMail() {
      axios({
        url: `${this.$apiURL()}/email/contact`,
        method: "post",
        data: this.contact_data,
      })
        .then(({ data }) => {
          this.errors = {};
          if (data.errors) {
            data.errors.map(({ name, message }) => {
              this.errors[name] = message;
            });
          } else {
            alert(data.message);
            if (data.success) {
              this.contact_data = {
                fname: "",
                lname: "",
                email: "",
                phone: "",
                department: "",
                message: "",
              };
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
$primary: #007bff;
.inputs {
  .form-input,
  textarea {
    border: 1px solid $primary;
    border-radius: 10px;
    padding: 0.5rem 2rem;
    margin-bottom: 1rem;
    width: 100%;
  }
}
</style>
