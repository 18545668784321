<template>
  <div>
    <MarketsTop
      :market="{
        video: 'indicesNEW',
        title: $t('indices'),
        desc: $t('indicesDesc'),
      }"
    />
    <div class="container-fluid">
      <div class="row d-flex justify-content-center mt-5">
        <div class="col-xl-9 col-12">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-8 col-11 px-sm-3 px-0">
              <Quotes
                v-slidein
                :symbols="[
                  'AUS200',
                  'DOWJONES30',
                  'SPX500',
                  'FRANCE40',
                  'GERMANY30',
                ]"
                :product="{ popular: $t('indicesPopular') }"
              />
            </div>
            <div class="col-xl-4 col-11">
              <QuoteDetails v-slideinright />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(item, idx) in $tm('indicesInfo')"
      :key="idx"
      class="container-fluid"
    >
      <div class="row d-flex justify-content-center my-5">
        <h2 class="col-12 title text-center">{{ item.moreInfo }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-11">
          <div class="row d-flex justify-content-center">
            <div
              v-for="(desc, idx) in item.points"
              :key="idx"
              v-scrollanimation
              class="col-xl-3 col-lg-4 col-md-6 col-sm-10 col-12 mb-5"
              id="indicesBoxes"
            >
              <div class="forex-box indices">
                <h3 class="text-center">{{ desc.title }}</h3>
                <p
                  class="text-center d-flex align-items-center"
                  v-html="desc.text"
                ></p>
              </div>
            </div>
            <div class="col-12 text-center my-5">
              <a target="_blank" :href="registerURL" class="btn btn-blue">{{
                $t("tradeNow")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation stepStyle="dark" />
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import MarketsTop from "../components/MarketsTop.vue";
import Quotes from "../components/Quotes.vue";
import QuoteDetails from "../components/QuoteDetails.vue";
export default {
  name: "Indices",
  components: {
    MarketsTop,
    Quotes,
    QuoteDetails,
  },
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
  mounted() {
    mutations.setBoxHeight("indices", "7");
    window.onresize = () => {
      mutations.setBoxHeight("indices", "7");
    };
  },
};
</script>
<style lang="scss" scoped>
#indicesBoxes:nth-child(odd) {
  .forex-box.indices {
    background-color: #e0dfe1;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  #indicesBoxes:nth-child(odd) {
    .forex-box.indices {
      background-color: #fff;
    }
  }
  #indicesBoxes:nth-child(4n),
  #indicesBoxes:nth-child(1),
  #indicesBoxes:nth-child(5) {
    .forex-box.indices {
      background-color: #e0dfe1;
    }
  }
}
</style>
