let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : "global";
export default {
  en: {
    tradingConditionsColsGlobal: [
      "Instrument",
      "Description",
      "Max Leverage",
      "Minimum Trade Size",
      "Minimum Step Size",
      "Contract Size",
      "Swap Long",
      "Swap Short",
      "Trading hours GMT+2",
      "Today Dividend long",
      "Today Dividend short",
    ],
    tradingConditionsColsEu: [
      "Instrument",
      "Description",
      "Max Leverage Retail",
      "Max Leverage Professional",
      "Minimum Trade Size",
      "Minimum Step Size",
      "Contract Size",
      "Swap Long",
      "Swap Short",
      "Trading hours GMT+2",
      "Today Dividend long",
      "Today Dividend short",
    ],
    tradingConditionsTabs: [
      {
        name: "Forex",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Indices",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Metals",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Cryptos",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Energies",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Shares",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  fr: {
    tradingConditionsCols: [
      "Produits",
      "Beschreibung",
      license == "global"
        ? "Taille Minimim de la Position"
        : "Effet de Levier Max pour les Particuliers",
      license == "global" ? "" : "Effet de Levier Max pour les Professionnels",
      "Taille Minimim de la Position",
      "Incrément Minimum",
      "Taille du Contrat",
      "Swap Long",
      "Swap Short",
      "Horaires de négociation GMT +2",
    ],
    tradingConditionsTabs: [
      {
        name: "Forex",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Indices",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Métaux",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "10:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Cryptos",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Énergies",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Actions",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  it: {
    tradingConditionsCols: [
      "Strumento",
      "Descrizione",
      license == "global" ? "Leva Massima" : "Leva Massima Retail",
      license == "global" ? "" : "Leva Massima Professional",
      "Misura Minima Trade",
      "Misura Minima Step",
      "Misura del Contratto",
      "Swap Long",
      "Swap Short",
      "Orari di trading GMT+2",
    ],
    tradingConditionsTabs: [
      {
        name: "Forex",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Indici",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Metalli ",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Cryptos",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Energie",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Azioni",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  de: {
    tradingConditionsCols: [
      "Instrument",
      "Beschreibung",
      license == "global" ? "Max Leverage" : "Max Leverage Retail",
      license == "global" ? "" : "Max Leverage Professional",
      "Min Handelsvolumen",
      "Min Schrittgröße",
      "Vertragsgröße",
      "Swap Long",
      "Swap Short",
      "Trading-Zeiten GMT+2",
    ],
    tradingConditionsTabs: [
      {
        name: "Forex",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Indizes",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Edelmetalle",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Kryptos",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Energien",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Aktien",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  cn: {
    tradingConditionsCols: [
      "产品",
      "描述",
      license == "global" ? "最大杠杆" : "最大杠杆零售",
      license == "global" ? "" : "最大杠杆专业",
      "最小交易手数",
      "最小递增",
      "合约大小",
      "多头隔夜利息",
      "空头隔夜利息",
      "交易时间 GMT+2",
    ],
    tradingConditionsTabs: [
      {
        name: "外汇",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "指数",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "金属",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "加密货币",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "能源",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "股票",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  es: {
    tradingConditionsCols: [
      "Instrumento",
      "Descripción",
      license == "global"
        ? "Apalancamiento Máximo"
        : "Apalancamiento Máximo Minorista",
      license == "global" ? "" : "Apalancamiento Máximo Profesional",
      "Tamaño Mínimo de Trading",
      "Tamaño de Paso Mínimo",
      "Tamaño del Contrato",
      "Swap Largo",
      "Swap Corto",
      "Horario de Trading GMT+2",
    ],
    tradingConditionsTabs: [
      {
        name: "Forex",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Índices",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Metales",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Criptos",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Energías",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Acciones",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  vnm: {
    tradingConditionsCols: [
      "Công cụ",
      "Sự miêu tả",
      license == "global" ? "Đòn bẩy tối đa" : "Đòn bẩy tối đa Bán lẻ",
      license == "global" ? "" : "Đòn bẩy tối đa Chuyên nghiệp",
      "Quy mô giao dịch tối thiểu",
      "Bước giá tối thiểu",
      "Quy mô hợp đồng",
      "Swap Long",
      "Swap Short",
      "Giờ giao dịch GMT + 2",
    ],
    tradingConditionsTabs: [
      {
        name: "Ngoại hối",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Chỉ số",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Kim loại",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Tiền điện tử",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Năng lượng",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Cổ phiếu",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  enUae: {
    tradingConditionsColsGlobal: [
      "Instrument",
      "Description",
      "Max Leverage",
      "Minimum Trade Size",
      "Minimum Step Size",
      "Contract Size",
      "Swap Long",
      "Swap Short",
      "Trading hours GMT+2",
    ],
    tradingConditionsColsEu: [
      "Instrument",
      "Description",
      "Max Leverage Retail",
      "Max Leverage Professional",
      "Minimum Trade Size",
      "Minimum Step Size",
      "Contract Size",
      "Swap Long",
      "Swap Short",
      "Trading hours GMT+2",
    ],
    tradingConditionsTabs: [
      {
        name: "Forex",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Indices",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Metals",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Cryptos",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Energies",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Shares",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  arb: {
    tradingConditionsColsGlobal: [
      "الأدوات",
      "الوصف",
      "أقصى رافعة",
      "الحد الأدنى لحجم التداول",
      "الحد الأدنى لحجم الخطوة",
      "حجم العقد",
      "مبادلة طويلة",
      "مبادلة قصيرة",
      "ساعات التداول GMT + 2",
    ],
    tradingConditionsColsEu: [
      "Instrument",
      "Description",
      "Max Leverage Retail",
      "Max Leverage Professional",
      "Minimum Trade Size",
      "Minimum Step Size",
      "Contract Size",
      "Swap Long",
      "Swap Short",
      "Trading hours GMT+2",
    ],
    tradingConditionsTabs: [
      {
        name: "فوركس",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "المؤشرات",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "المعادن",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",

            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "العملات المشفرة",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "الطاقة",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "الأسهم",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
  my: {
    tradingConditionsColsGlobal: [
      "Instrumen",
      "Instrumen",
      "Instrumen",
      "Saiz Perdagangan Minimum",
      "Saiz Langkah Minimum",
      "Saiz Kontrak",
      "Swap Long",
      "Swap Short",
      "Waktu perdagangan GMT+2",
    ],
    tradingConditionsColsEu: [
      "Instrumen",
      "Penerangan",
      "Runcit leverage maksimum",
      "Leverage profesional",
      "Saiz Perdagangan Minimum",
      "Saiz Langkah Minimum",
      "Saiz Kontrak",
      "Swap Long",
      "Swap Short",
      "Waktu perdagangan GMT+2",
    ],
    tradingConditionsTabs: [
      {
        name: "Forex",
        conditionInfo: [
          {
            ticker: "AUDCAD",
            desc: "AUD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,25",
            swapShort: "2,15",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDCHF",
            desc: "AUD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,36",
            swapShort: "-5,07",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDJPY",
            desc: "AUD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,86",
            swapShort: "-3,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDNZD",
            desc: "AUD vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,8",
            swapShort: "-2,09",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADCHF",
            desc: "CAD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,42",
            swapShort: "-3,211",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CADJPY",
            desc: "CAD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,872",
            swapShort: "-3,484",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "CHFJPY",
            desc: "CHF vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-8,593",
            swapShort: "0,3832",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURAUD",
            desc: "EUR vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,669",
            swapShort: "0,259",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCAD",
            desc: "EUR vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,382",
            swapShort: "0,364",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCHF",
            desc: "EUR vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,845",
            swapShort: "-2,704",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURCZK",
            desc: "EUR vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-88,4728",
            swapShort: "4,851",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURDKK",
            desc: "EUR vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,8584",
            swapShort: "-18,6004",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURGBP",
            desc: "EUR vs GBP",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,108",
            swapShort: "-0,416",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURHUF",
            desc: "EUR vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,623",
            swapShort: "6,433",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURJPY",
            desc: "EUR vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,159",
            swapShort: "-0,234",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURMXN",
            desc: "EUR vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-993,265",
            swapShort: "238,98",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNOK",
            desc: "EUR vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,638",
            swapShort: "1,4266",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURNZD",
            desc: "EUR vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-9,022",
            swapShort: "0,532",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURPLN",
            desc: "EUR vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-27,677",
            swapShort: "6,2881",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURRUB",
            desc: "EUR vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-216,39",
            swapShort: "108,66",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSEK",
            desc: "EUR vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,575",
            swapShort: "-3,198",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURSGD",
            desc: "EUR vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,878",
            swapShort: "0,0175",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURTRY",
            desc: "EUR vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-358,4906",
            swapShort: "-128,8144",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURZAR",
            desc: "EUR vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-373,23",
            swapShort: "133,147",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPAUD",
            desc: "GBP vs AUD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,12",
            swapShort: "-2,99",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCAD",
            desc: "GBP vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,97",
            swapShort: "-2,81",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPCHF",
            desc: "GBP vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,231",
            swapShort: "-20,54",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPDKK",
            desc: "GBP vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,2971",
            swapShort: "-37,5518",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPJPY",
            desc: "GBP vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,304",
            swapShort: "-9,126",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNOK",
            desc: "GBP vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-29,471",
            swapShort: "-27,001",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPNZD",
            desc: "GBP vs NZD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-6,201",
            swapShort: "-4,29",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPPLN",
            desc: "GBP vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-25,571",
            swapShort: "-0,13",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSEK",
            desc: "GBP vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-19,487",
            swapShort: "-41,327",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPSGD",
            desc: "GBP vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,174",
            swapShort: "-3,4307",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPTRY",
            desc: "GBP vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-372,0782",
            swapShort: "-143,533",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPZAR",
            desc: "GBP vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-423,371",
            swapShort: "124,754",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NOKSEK",
            desc: "NOK vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-13,26",
            swapShort: "-11,336",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCAD",
            desc: "NZD vs CAD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,209",
            swapShort: "-2,665",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDCHF",
            desc: "NZD vs CHF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,504",
            swapShort: "-3,224",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDJPY",
            desc: "NZD vs JPY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,04",
            swapShort: "-3,809",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDSGD",
            desc: "NZD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,392",
            swapShort: "-4,472",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCZK",
            desc: "USD vs CZK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-16,237",
            swapShort: "-10,4624",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDDKK",
            desc: "USD vs DKK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0",
            swapShort: "-27,0517",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHKD",
            desc: "USD vs HKD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-40,937",
            swapShort: "-7,0928",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDHUF",
            desc: "USD vs HUF",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-14,456",
            swapShort: "2,597",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDILS",
            desc: "USD vs ILS",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-7,592",
            swapShort: "-18,954",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDMXN",
            desc: "USD vs MXN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-858,676",
            swapShort: "190,337",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDNOK",
            desc: "USD vs NOK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-51,844",
            swapShort: "-148,044",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDPLN",
            desc: "USD vs PLN",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-22,451",
            swapShort: "-2,925",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDRUB",
            desc: "USD vs RUB",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-162,6222",
            swapShort: "60,8342",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSEK",
            desc: "USD vs SEK",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-4,264",
            swapShort: "-24,349",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDSGD",
            desc: "USD vs SGD",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,952",
            swapShort: "-5,499",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDTRY",
            desc: "USD vs TRY",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-400,959",
            swapShort: "-273,819",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDZAR",
            desc: "USD vs ZAR",
            lev: license == "global" ? "500:1" : "20:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-330,59",
            swapShort: "99,792",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "AUDUSD",
            desc: "AUD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,01",
            swapShort: "-2,69",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "EURUSD",
            desc: "EUR vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-5,18",
            swapShort: "0,63",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "GBPUSD",
            desc: "GBP vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-2,93",
            swapShort: "-2,01",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "NZDUSD",
            desc: "NZD vs US Dollar",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-1,2",
            swapShort: "-2,64",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCAD",
            desc: "US Dollar vs CAD",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-3,33",
            swapShort: "-2,6",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDCHF",
            desc: "US Dollar vs CHF",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "0,51",
            swapShort: "-5,71",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
          {
            ticker: "USDJPY",
            desc: "US Dollar vs JPY",
            lev: license == "global" ? "500:1" : "30:1",
            stopout: "300:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100,000",
            swapLong: "-0,91",
            swapShort: "-4,02",
            tradingHours: "Mon to Fri 0:00-24:00",
          },
        ],
      },
      {
        name: "Indeks",
        conditionInfo: [
          {
            ticker: "SPAIN35",
            desc: "SPAIN35 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "10:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-4,8916",
            swapShort: "-6,9985",
            tradingHours: "Mon to Fri 09:00-21:00",
          },
          {
            ticker: "AUS200",
            desc: "AUS200 Spot Index vs AUS Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-6,34",
            swapShort: "-5,94",
            tradingHours: "Mon to Fri 00:50-07:30, 08:10-23:00",
          },
          {
            ticker: "DOWJONES30",
            desc: "DOWJONES30 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-29,69",
            swapShort: "-26,16",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "EUROPE50",
            desc: "EUROPE50 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-2,58",
            swapShort: "-3,99",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "FRANCE40",
            desc: "FRANCE40 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,74",
            swapShort: "-6,01",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "GERMANY30",
            desc: "GERMANY30 Spot Index vs Euro",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-10,1",
            swapShort: "-15,66",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "SPX500",
            desc: "US 500 Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-3,62",
            swapShort: "-3,19",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
          {
            ticker: "UK100",
            desc: "UK 100 Spot Index vs UK Pound",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-5,5297",
            swapShort: "-4,9482",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "USTECH100",
            desc: "US Nasdaq Spot Index vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "50:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "10",
            swapLong: "-12,29",
            swapShort: "-10,83",
            tradingHours: "Mon to Fri 01:00-23:15, 23:30-24:00",
          },
        ],
      },
      {
        name: "Logam",
        conditionInfo: [
          {
            ticker: "XPDUSD",
            desc: "Palladium vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-19,058",
            swapShort: "-19,682",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XPTUSD",
            desc: "Platinum vs US Dollar",
            lev: "10:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "100 ounces",
            swapLong: "-3,7821",
            swapShort: "-9,6292",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAUUSD",
            desc: "Gold vs US Dollar",
            lev: license == "global" ? "100:1" : "20:1",
            stopout: "100:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "100 ounces",
            swapLong: "-7,54",
            swapShort: "-0,87",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "XAGUSD",
            desc: "Silver vs US Dollar",
            lev: "50:1",
            stopout: license == "global" ? "50:1" : "10:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "5000 ounces",
            swapLong: "-3,28",
            swapShort: "-1,67",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Cryptos",
        conditionInfo: [
          {
            ticker: "BTCUSD",
            desc: "BTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-4,7748",
            swapShort: "-4,2202",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "LTCUSD",
            desc: "LTC vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,0284",
            swapShort: "-0,0251",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "XRPUSD",
            desc: "XRP vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
          {
            ticker: "ETHUSD",
            desc: "ETH vs US Dollar",
            lev: "2:1",
            stopout: "2:1",
            minTradeSize: "0,01",
            minStepSize: "0,01",
            contractSize: "1",
            swapLong: "-0,1152",
            swapShort: "-0,1018",
            tradingHours: "Mon to Fri 00:00-24:00",
          },
        ],
      },
      {
        name: "Tenaga",
        conditionInfo: [
          {
            ticker: "NATURALGAS",
            desc: "NaturalGas vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "10,000 mmBtu",
            swapLong: "-10,1336",
            swapShort: "5,3644",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
          {
            ticker: "BRENTOIL",
            desc: "Brent CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-58,62",
            swapShort: "2,71",
            tradingHours: "Mon to Fri 03:00-23:55",
          },
          {
            ticker: "CRUDEOIL",
            desc: "CrudeOil vs US Dollar",
            lev: "50:1",
            stopout: "10:1",
            minTradeSize: "0,1",
            minStepSize: "0,1",
            contractSize: "1000 barrels",
            swapLong: "-27,63",
            swapShort: "1,1",
            tradingHours: "Mon to Fri 01:00-24:00",
          },
        ],
      },
      {
        name: "Saham",
        conditionInfo: [
          {
            ticker: "AAPL.US",
            desc: "Apple vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-3",
            swapShort: "-3",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADBE.US",
            desc: "Adobe Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,52",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ADP.US",
            desc: "Automatic Data Processin vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMGN.US",
            desc: "Amgen Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AMZN.US",
            desc: "Amazon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,31",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AVGO.US",
            desc: "Broadcom Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,56",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "AXP.US",
            desc: "American Expres vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BA.US",
            desc: "Boeing vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,64",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BAC.US",
            desc: "Bank of America Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,58",
            swapShort: "-0,1",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BKNG.US",
            desc: "Booking Holdings Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,33",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "BRKb.US",
            desc: "Berkshire Hathaway Inc - Class B vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CAT.US",
            desc: "CAT vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CHTR.US",
            desc: "Charter Communications Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,85",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CMCSA.US",
            desc: "Comcast Corporation - Class A vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,8",
            swapShort: "-0,14",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "COST.US",
            desc: "Costco Wholesale Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,54",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSCO.US",
            desc: "Cisco Systems vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,86",
            swapShort: "-0,15",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CSX.US",
            desc: "CSX Corp vs US Dollar",
            leverage: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,28",
            swapShort: "-0,22",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "CVX.US",
            desc: "Chevron vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DD.US",
            desc: "DuPont de Nemours Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,25",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "DIS.US",
            desc: "Walt Disney vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "FB.US",
            desc: "Facebook vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,34",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GILD.US",
            desc: "Gilead Sciences Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,13",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOG.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GOOGL.US",
            desc: " Alphabet Inc - Class C vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "GS.US",
            desc: "Goldman Sachs vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,39",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "HD.US",
            desc: "Home Depot  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,42",
            swapShort: "-0,07",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "IBM.US",
            desc: "IBM vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTC.US",
            desc: "Intel vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,03",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "INTU.US",
            desc: "Intuit Inc  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,47",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JNJ.US",
            desc: "Johnson & Johnson vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "JPM.US",
            desc: "JP Morgan vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,23",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "KO.US",
            desc: "Coca-Cola vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,93",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MA.US",
            desc: "Mastercard Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,49",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MCD.US",
            desc: "McDonald's vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,35",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MDLZ.US",
            desc: "Mondelez International Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,94",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MMM.US",
            desc: "3M Company  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,3",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MRK.US",
            desc: "Merck vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,51",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "MSFT.US",
            desc: "Microsoft  vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,26",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NFLX.US",
            desc: "Netflix Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,5",
            swapShort: "-0,09",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NKE.US",
            desc: "Nike vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,62",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "NVDA.US",
            desc: "NVIDIA Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,37",
            swapShort: "-0,06",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "ORCL.US",
            desc: "Oracle Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1",
            swapShort: "-0,17",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PEP.US",
            desc: "PepsiCo Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PFE.US",
            desc: "Pfizer vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,65",
            swapShort: "-0,11",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PG.US",
            desc: "Procter & Gamble vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "PYPL.US",
            desc: "PayPal vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,18",
            swapShort: "-0,03",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "QCOM.US",
            desc: "Qualcomm Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,61",
            swapShort: "-0,28",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "SBUX.US",
            desc: "Starbucks Corp vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,49",
            swapShort: "-0,26",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "T.US",
            desc: "AT&T vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,7",
            swapShort: "-0,12",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TMUS.US",
            desc: "T-Mobile US Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,39",
            swapShort: "-0,24",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TRV.US",
            desc: "The Travelers Companies Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,24",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "TXN.US",
            desc: "Texas Instruments Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,21",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "UNH.US",
            desc: "United Health Group vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,45",
            swapShort: "-0,08",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "V.US",
            desc: "Visa vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,32",
            swapShort: "-0,05",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "VZ.US",
            desc: "Verizon vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,06",
            swapShort: "-0,18",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WBA.US",
            desc: "Walgreens Boots Alliance Inc vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,11",
            swapShort: "-0,19",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WFC.US",
            desc: "Wells Fargo vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,95",
            swapShort: "-0,16",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "WMT.US",
            desc: "Wal-Mart vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-0,22",
            swapShort: "-0,04",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
          {
            ticker: "XOM.US",
            desc: "Exxon Mobil vs US Dollar",
            lev: "5:1",
            stopout: "5:1",
            minTradeSize: "1",
            minStepSize: "1",
            contractSize: "1",
            swapLong: "-1,22",
            swapShort: "-0,21",
            tradingHours: "Mon to Fri 16:30-23:00",
          },
        ],
      },
    ],
  },
};
