<template>
  <div class="container mb-5" id="tradingConditions">
    <div class="row d-flex justify-content-center my-md-5 my-3">
      <h2 class="col-12 title text-center">{{ $t("tradingConditions") }}</h2>
      <hr class="blue" />
    </div>
    <div class="row">
      <div class="col-12">
        <div v-for="(condition, idx) in swapsList" :key="idx">
          <TradingCondition :condition="condition" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getters } from "../store";
import TradingCondition from "../components/TradingCondition";
export default {
  name: "TradingConditions",
  data() {
    return {
      swapsList: [],
    };
  },
  components: {
    TradingCondition,
  },
  mounted() {
    this.getSwaps();
  },
  computed: {
    license() {
      return getters.license();
    },
  },
  methods: {
    getSwaps() {
      let license = this.license === "eu" ? "edr" : "benor";
      axios({
        method: "GET",
        url: `${this.$apiURL()}/hub/swaps/${license}`,
      })
        .then(({ data }) => {
          if (data.success) {
            this.swapsList = data.swapsList;
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
