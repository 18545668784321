let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : "global";
export default {
  en: {
    accountsOverviewInfo: [
      {
        text: "Here at TrioMarkets™ whether you're novice in trading or you have a long journey with it, you'll get a suitable account for all your trading needs.",
        textTwo:
          "Our accounts have been carefully designed to suit all types of traders.",
        zeroCom: "Zero Commission Accounts",
        basic: "Basic",
        standard: "Standard",
      },
    ],
    hniInfo: [
      {
        title: "Institutional Trading",
        text: "Corporate Accounts are highly valued within TrioMarkets™.",
        textTwo: `We offer corporate and institutional clients a trading solution tailored to their profile, needs and requirements, based on powerful technology, a wide range of assets and dedicated support. <br> We give you the professionalism and quality you expect and provide from your own entity. You may use Forex and CFD trading, either as another element in your investment strategy or to hedge against your exposure.`,
        points: [
          { text: "Investment Managers" },
          { text: "Hedge Funds" },
          { text: "Individual Corporate Entities" },
        ],
        titleTwo: "High Net Worth Individuals",
        textTree: `<p class="text-center mb-0">If you fall into this category and believe you are entitled to the best services and support available, TrioMarkets™ is the place to be. We make certain that our HNI clients receive the best service possible with the least amount of effort on their part. We will make every effort to speak with you in person, face to face, and understand your market needs in order to create the best solution in either self-trading or fund management.</p> <br> <p class="text-center">We will make sure to talk to you in person, face to face and understand your needs in the market to create the best solution in either self-trading or anagement of funds.</p>`,
        pointsTwo: [
          { text: "Personnel Contact with our Chief Market Analyst" },
          { text: "Introduction to our Executive Directors" },
          { text: "Highest Level of Discretion" },
          { text: "Private Segregated Account" },
          { text: "Automated Withdrawals" },
          { text: "Funds Held in Switzerland" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `Following the European Securities and Markets Authority (ESMA) new protection measures on contracts for difference (CFDs) to retail traders, whereby among others, consist of restrictions on distribution, leverage limits and margin close out rules, professional traders are treated differently. By requesting to become a Professional Client, you are agreeing to waive some of the protections that would normally be afforded to you as a Retail Client.`,
        textOne: `However, you reserve the right to request non-professional treatment at any time and TrioMarkets™ shall agree to offer you a higher level of protection. You are advised to revert back to retail client status, if at any point you believe that you are unable to properly assess or manage the risks involved with trading. Special terms and conditions apply regarding Portfolio Management.`,
        unwaived: "Unwaived",
        points: [
          {
            img: "best-execition",
            name: "Best Execution",
            text: "We will continue to take all reasonable steps to ensure the best order execution for our clients while remaining fair, honest, and professional.",
          },
          {
            img: "pro-status",
            name: "Pro Status Reversion",
            text: "You retain the right to change your client status back to non-professional in order to receive a higher level of protection, such as negative balance protection and coverage by the Investor Compensation Fund (ICF) of up to Euro 20K.",
          },
          {
            img: "account-seg",
            name: "Account Segregation",
            text: "To safeguard our clients' funds, we request that our Bank keeps our clients' accounts separate from other accounts.",
          },
        ],
        waived: "Waived",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Leverage Restriction",
            text: "Leverage and margin restrictions will not apply to you if you can demonstrate relevant knowledge and experience, as well as your understanding of the risks associated with trading complex financial products.",
          },
          {
            img: "balance-protection",
            name: "Negative Balance Protection",
            text: "Professional clients do not have rights to negative balance protection. If your trading balance falls below zero, you will be required to make additional payments, resulting in losses greater than your deposits.",
          },
          {
            img: "compensation-fund",
            name: "Investor Compensation Fund",
            text: "You are no longer covered by the Investor Compensation Fund (ICF). Any application to secure claims arising from covered services will be denied.",
          },
          {
            img: "risk-warnings",
            name: "Risk Warnings",
            text: "We are not required to provide you with the risk warnings that are normally provided to retail clients because you will demonstrate your knowledge and experience, as well as your ability to make informed decisions while bearing your risk.",
          },
        ],
        title: "Eligibility Criteria to Become a Professional Trader",
        textTwo:
          "To qualify for an Elective Professional Client Status you are required to satisfy at least two of the following minimum requirements applied to CFDs:",
        pointsTree: [
          "Investment portfolio, including cash deposits and financial instruments that exceeds 500,000 EUR in value.",
          "1 + year(s) of working experience in the financial sector in a professional role requiring knowledge of trading leveraged products.",
          "Trading volumes of transactions of leveraged products of significant size equal or greater to 10 trades per quarter over the past four quarters, or, 40 trades per year. The volume will be counted towards that goal in a combined forma, concluded from trading in any forex broker.",
        ],
        textThree:
          "* Significant size: Minimum notional value of at least 100,000 Euros for FX majors, 50,000 for FX minors, major indexes and gold, 10,000 Euros on minor indexes, other commodities and equities.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "You can fund your account using the TrioMarkets™ Wallet. The TrioMarkets™ Wallet makes it easier to fund and withdraw funds. You can now transfer money between your TrioMarkets™ Wallet and your trading accounts whenever you want.",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Lithuania",
            regulator: "Bank of Lithuania",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "United Kingdom",
            regulator: "Financial Conduct Authority (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "United Kingdom",
            regulator: "Financial Conduct Authority (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estonia",
          //   regulator: "Estonian Financial Intelligence Unit",
          // },
          {
            img: ["wire-transfer"],
            psp: "",
            country: "",
            regulator: `<p class="text-primary mb-0 fs-6">We also accept wire transfer</p>
          <p class="mb-0 fs-6">Transfer funds bank-to-bank or person to institution.<br>A wire transfer can be made from one bank account to another bank account.<br>At all times, your private financial details are kept safe between you and your financial institution.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">We also accept wire transfer</p>
          <p class="mb-0 fs-6">Transfer funds bank-to-bank or person to institution.<br>A wire transfer can be made from one bank account to another bank account.<br>At all times, your private financial details are kept safe between you and your financial institution.</p>`,
          },
        ],
        country: "Country of License",
        regulator: "Regulator",
        beneficiary: "Beneficiary",
        code: "Code",
        reference: "Reference: *Please Specify your TA Number (optional)",
        bankAddress: "Bank Address",
        bank: "Bank",
        switzerland: "Switzerland",
        title: "Withdrawing your Funds",
        textTwo: `<p>We believe that withdrawing funds should be as straightforward as depositing them. Therefore it should be simple and hassle free. Here at TrioMarkets™ we guarantee you a fixed process time. The process time varies depending on the type of account you have, from 24 hours to as little as 6 hours.</p><p>TrioMarkets™  offers a variety of payment options based on the deposit method used to fund your account. The initial deposit payment method must be used to withdraw the funds. If the withdrawal amount exceeds the initial deposit amount, the difference will be transferred via bank wire.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™ charges a fee of 1% of the withdraw amount for Visa / Mastercard / Neteller"
                : "TrioMarkets™ charges a fee of 1% of the withdraw amount for Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "Funds will only be transferred into an account that is registered and in the same name of the TrioMarkets™ account holder.",
          },
          {
            img: "3",
            text: "Wire Transfer Fee is 1.5% with a minimum charge of 25.00 and maximum 50.00 (Base Currency). SEPA Transfer will be charged a fixed fee of 7€",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Deposits",
        withdrawals: "Withdrawals",
        fundingWithRow: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Minimum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
          [
            { name: "<strong>Online Payment Solutions</strong>" },
            { name: "Minimum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Minimum Amount" },
            { name: "Maximum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
          [
            { name: "<strong>Online Payment Solutions</strong>" },
            { name: "Minimum Amount" },
            { name: "Maximum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
        ],
        fundingWithTable: [
          "* Per transaction",
          "** Transfer conditions for account closures can be negotiated with the customer support.",
        ],
      },
    ],
    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Unlimited",
          currency: "USD, EUR, GBP, CHF",
          fees: "Free",
          time: "1-3 Working Days",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Free",
          time: "Instant",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 per day, per card",
          currency: "USD, EUR",
          fees: "Free",
          time: "Instant",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Free",
          time: "Instant",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Free",
          time: "Instant",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Unlimited",
        //   currency: "USD, EUR",
        //   fees: `Network Fees<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Instant",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "Wire Transfer Fee is 1.5% with a minimum charge of 25.00 and maximum 50.00 (Base Currency). ",
          time: "1-3 Working Days",
        },
        {
          img: ["sepa"],
          min: "50",
          currency: "EUR",
          fees: "5€",
          time: "1-3 Working Days",
        },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% of the withdraw amount",
          time: "1 Working Day",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% of the withdraw amount`,
          time: "1 Working Day",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "1% of the withdraw amount",
          time: "1 Working Day",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% of the withdraw amount`,
          time: "1 Working Day",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Network Fees</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Withdrawal fees</strong><br>1.5% of the withdraw amount`,
        //   time: "1 Working Day",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  fr: {
    accountsOverviewInfo: [
      {
        text: "Chez TrioMarkets™, que vous soyez novice dans le trading ou professionel, <br> vous obtiendrez un compte adapté à tous vos besoins de trading.",
        textTwo:
          "Nos comptes ont été soigneusement conçus pour convenir à tous les types de traders.",
        zeroCom: "Comptes Sans Commissions",
        basic: "Basic",
        standard: "Standard",
      },
    ],
    hniInfo: [
      {
        title: "Comptes Institutionnels",
        text: "Les comptes Corporates sont hautement appréciés chez TrioMarkets™.",
        textTwo: `Nous offrons aux clients entreprises et institutionnels une solution de trading adaptée à leur profil, leurs besoins et à leurs exigeances, reposant sur une technologie puissante, une vaste gamme d'actifs et une assistance dédiée. <br> Nous vous apportons le professionnalisme et la qualité que vous attendez. Vous pouvez utiliser le trading sur Forex et CFD, soit comme un élément de votre stratégie d'investissement, soit pour vous protéger contre une exposition trop importante.`,
        points: [
          { text: "Gestionnaires de Portefeuilles" },
          { text: "Fonds Spéculatifs" },
          { text: "Entités Corporate Individuelles" },
        ],
        titleTwo: "Particuliers À Hauts Revenus",
        textTree: `<p class="text-center mb-0">Si vous êtes un particulier disposant d'un patrimoine conséquent ou de hauts revenus, TrioMarkets™ vous propose des services dédiés, à même de satisfaire toutes vos exigences. Nos clients classifié HNWI (High Net Worth Individuals ou HNI) bénéficient d'une qualité de service répondant à leurs attentes, les libérant de la gestion au quotidien de leur portefeuille.</p> <br> <p class="text-center">Nous veillons à établir une communication en directe, en face à face, afin de comprendre et définir vos besoins en termes d'investissement et créer la meilleure solution en trading ou gestion d'actifs.</p>`,
        pointsTwo: [
          {
            text: "Contact Personnel avec notre <br> Analyste Responsable des Marchés",
          },
          { text: "Présentation de nos Directeurs  <br> Généraux" },
          { text: "Discrétion Totale Assurée" },
          { text: "Comptes Ségrégués Privés" },
          { text: "Retraits Automatiques" },
          { text: "Fonds Détenus en Suisse" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `L'Autorité européenne des marchés financiers (ESMA) a adopté de nouvelles mesures de protection concernant les contrats sur différence (CFD) conclus avec les clients particuliers. Ces nouvelles mesures relatives à ces produits consistent notamment en des restrictions de distribution, des limites de levier et des règles de fermeture de marge. Les modifications de l'ESMA s'appliquent uniquement aux traders particuliers. En demandant à devenir un client professionnel, vous acceptez de renoncer à certaines des protections qui vous seraient normalement accordées en tant que client particulier.`,
        textOne: `Cependant, vous vous réservez le droit de demander à tout moment un retour au statut de client non professionnel et Triomarkets s'engage dans ce cas à vous offrir un niveau de protection supérieur. Il vous est conseillé de revenir au statut de client particulier si, à un moment quelconque, vous estimez que vous n'êtes pas en mesure d'évaluer ou de gérer correctement les risques liés au trading. Des conditions spéciales s'appliquent à la gestion de portefeuilles.`,
        unwaived: "Protections Inchangées",
        points: [
          {
            img: "best-execition",
            name: "Meilleure Execution",
            text: "Nous continuerons à prendre toutes les mesures nécessaires pour assurer à nos clients la meilleure exécution des ordres, en agissant de manière équitable, honnête et professionnelle.",
          },
          {
            img: "pro-status",
            name: "Retour au Statut Non-Professionnel",
            text: "Vous conservez le droit de revenir au statut de client particulier afin d'obtenir un niveau de protection supérieur, incluant une protection de votre solde négatif et une couverture par le Fonds d'indemnisation des investisseurs (ICF) jusqu'à 20 000 euros.",
          },
          {
            img: "account-seg",
            name: "Ségrégations des Comptes Clients",
            text: "Nous demandons à notre banque de garder les comptes de nos clients ségrégués des autres comptes afin de protéger les fonds de nos clients.",
          },
        ],
        waived: "Protections Auxquelles vous Renoncez",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Restriction sur l'Effet de Levier",
            text: "Les restrictions concernant les effets de levier et les marges ne vous sont pas applicables si vous êtes en mesure de prouver les connaissances, l'expérience et la compréhension des risques liés au trading de produits financiers complexes.",
          },
          {
            img: "balance-protection",
            name: "Protection de Balance Négative",
            text: "La protection de solde négatif n'est pas offerte aux clients professionnels. Par conséquent, si votre solde devient négatif, vous devrez effectuer des paiements supplémentaires, ce qui entraînera des pertes plus importantes que vos dépôts.",
          },
          {
            img: "compensation-fund",
            name: "Fonds d'Indemnisation des Investisseurs",
            text: "En tant que client professionnel, vous n'êtes plus couvert par le Fond d'indemnisation des investisseurs (ICF). Toute demande visant à garantir des créances provenant de services couverts, ne sera pas satisfaite.",
          },
          {
            img: "risk-warnings",
            name: "Avertissements Relatifs aux Risques",
            text: "Nous ne sommes pas tenus de vous communiquer les avertissements sur les risques qui sont normalement obligatoires pour les clients particuliers, car vous démontrerez vos connaissances, votre expérience et votre capacité à prendre des décisions éclairées concernant les risques que vous prenez en investissant.",
          },
        ],
        title: "Criteres d'Eligibilite pour devenir un Client Professionnel",
        textTwo:
          "Pour être éligible au statut optionnel de client professionnel, vous devez satisfaire à au moins deux des exigences suivantes applicables aux CFD:",
        pointsTree: [
          "Vous détenez un portefeuille d'investissement, incluant les dépôts en espèces et les instruments financiers, d'une valeur supérieure à 500 000 EUR.",
          "Vous avez travaillé pendant au moins 1 an dans le secteur de la finance, à un poste nécessitant une connaissance du trading des produits financiers à effet de levier.",
          "Votre volume de transaction sur les produits à effet de levier est de taille significative égale ou supérieure à 10 transactions par trimestre au cours des 4 derniers trimestres, ou 40 transactions par an. Le volume sera comptabilisé à cet effet en prenant en compte les transactions effectuées par l'intermédiaire de tout courtier Forex.",
        ],
        textThree:
          "* Taille significative: valeur nominale minimale d'au moins 100 000 Euros sur le Forex pour les devises majeures, 50 000 euros pour les devises mineures, les principaux indices et l'or, 10 000 Euros sur les indices mineurs, les autres matières premières et les actions.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "Effectuez un dépôt via le portefeuille TrioMarkets™. Le dépôt et le retrait de fonds sont encore plus facile avec le portefeuille TrioMarkets™. Vous pouvez maintenant virer des fonds entre votre portefeuille TrioMarkets™ et vos comptes de trading à tout moment.",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Lituanie",
            regulator: "Banque de Lituanie",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "Royaume-Uni",
            regulator: "Autorité de Conduite Financière (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "Royaume-Uni",
            regulator: "Autorité de Conduite Financière (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estonie",
          //   regulator: "Cellule de Renseignement Financier Estonienne",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">Nous acceptons aussi les virements bancaires</p>
          <p class="mb-0 fs-6">Transférez des fonds de banque à banque ou de personne à institution.<br>Les transferts bancaires depuis votre compte personnel ou depuis un compte société sont également acceptés.<br>Nous nous engageons à protéger vos renseignements personnels et financiers.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">Nous acceptons aussi les virements bancaires</p>
            <p class="mb-0 fs-6">Transférez des fonds de banque à banque ou de personne à institution.<br>Les transferts bancaires depuis votre compte personnel ou depuis un compte société sont également acceptés.<br>Nous nous engageons à protéger vos renseignements personnels et financiers.</p>`,
          },
        ],
        country: "Pays de License",
        regulator: "Régulateur",
        beneficiary: "Bénéficiaire",
        code: "Code",
        reference:
          "Référence : *Veuillez spécifier votre numéro TA (facultatif)",
        bankAddress: "Adresse de la Banque",
        bank: "Banque",
        switzerland: "la Suisse",
        title: "Retraits de vos Fonds",
        textTwo: `<p>Nous pensons que le retrait de vos fonds devrait être aussi facile que le dépôt. Par conséquent, il doit être simple et sans tracas. Chez TrioMarkets™, nous vous garantissons un temps de traitement fixe. Selon le type de compte que vous détenez, le temps de traitement de votre demande est de 24 heures et peut être réduit à 6 heures.</p><p>TrioMarkets™ offre un certain nombre d'options de paiement en fonction de votre méthode de dépôt utilisée pour financer initialement votre compte. Pour retirer les fonds, le même mode de paiement qui a été utilisé pour effectuer le dépôt initial doit être utilisé. Si le montant du retrait est plus élevé que le montant du dépôt initial, la différence doit être transférée par virement bancaire.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™ facture des frais de 1% du montant du retrait pour Visa / Mastercard / Neteller"
                : "TrioMarkets™ facture des frais de 3 % du montant du retrait pour Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "Les fonds ne seront transférés que dans un compte bancaire enregistré au même nom que le titulaire du compte TrioMarkets™.",
          },
          {
            img: "3",
            text: "Les frais de virement bancaire sont de 1,5% avec un minimum de 25,00 et un maximum de 50,00 (devise de base). Le virement SEPA sera facturé à un tarif fixe de 7€.",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Dépôts",
        withdrawals: "Retraits",
        fundingWithRow: [
          [
            { name: "<strong>Virements Bancaires</strong>" },
            { name: "Dépôt Minimal" },
            { name: "Devise de référence" },
            { name: "Frais" },
            { name: "Délai de traitement" },
          ],
          [
            { name: "<strong>Solutions de paiement en ligne</strong>" },
            { name: "Dépôt Minimal" },
            { name: "Devise de référence" },
            { name: "Frais" },
            { name: "Délai de traitement" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Virements Bancaires</strong>" },
            { name: "Dépôt Minimal" },
            { name: "Dépôt Maximum" },
            { name: "Devise de référence" },
            { name: "Frais" },
            { name: "Délai de traitement" },
          ],
          [
            { name: "<strong>Solutions de paiement en ligne</strong>" },
            { name: "Dépôt Minimal" },
            { name: "Dépôt Maximum" },
            { name: "Devise de référence" },
            { name: "Frais" },
            { name: "Délai de traitement" },
          ],
        ],
        fundingWithTable: [
          "* Par Transactions",
          "** Les conditions de transfert pour les clôtures de compte peuvent être négociées avec le service clientèle.",
        ],
      },
    ],
    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Illimité",
          currency: "USD, EUR, GBP, CHF",
          fees: "Gratuit",
          time: "1-3 Jours ouvrables",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Gratuit",
          time: "Instantané",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 par jour, par carte",
          currency: "USD, EUR",
          fees: "Gratuit",
          time: "Instantané",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Gratuit",
          time: "Instantané",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Gratuit",
          time: "Instantané",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Illimité",
        //   currency: "USD, EUR",
        //   fees: `Frais de Réseau<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Instantané",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "Les frais de virement bancaire sont de 1,5 % avec un minimum de 25,00 et un maximum de 50,00 (devise de base).",
          time: "1-3 Jours ouvrables",
        },
        {
          img: ["sepa"],
          min: "50",
          currency: "EUR",
          fees: "5€",
          time: "1-3 Jours ouvrables",
        },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% du montant du retrait",
          time: "1 Jour ouvrable",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% du montant du retrait`,
          time: "1 Jour ouvrable",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "3% du montant du retrait",
          time: "1 Jour ouvrable",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% du montant du retrait`,
          time: "1 Jour ouvrable",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Frais de Réseau</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Frais de retrait</strong><br>1.5% du montant du retrait`,
        //   time: "1 Jour ouvrable",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  it: {
    accountsOverviewInfo: [
      {
        text: "Qui su TrioMarkets™, che tu sia un principiante nel trading o che tu abbia un lungo percorso, <br> otterrai un conto adatto a tutte le tue esigenze di trading.",
        textTwo:
          "I nostri conti sono stati progettati con cura per adattarsi a tutti i tipi di trader.",
        zeroCom: "Conti Senza Commissione",
        basic: "Basic",
        standard: "Standard",
      },
    ],
    hniInfo: [
      {
        title: "Trading Istituzionale",
        text: "I conti aziendali sono i benvenuti in TrioMarkets™.",
        textTwo: `Offriamo ai clienti aziendali e istituzionali una soluzione di trading su misura per il loro profilo, esigenze e requisiti, supportata da una potente tecnologia, un'ampia gamma di risorse e supporto dedicato. <br> Ti offriamo la professionalità e la qualità che ti aspetti. Puoi utilizzare il trading Forex e CFD, sia come parte della tua strategia di investimento, sia per proteggerti da un'eccessiva esposizione.`,
        points: [
          { text: "Gestione Portafogli" },
          { text: "Fondi Speculativi" },
          { text: "Enti Aziendali Individuali" },
        ],
        titleTwo: "Individui Con Alto Patrimonio",
        textTree: `<p class="text-center mb-0">Se sei un individuo che rientra in questa categoria e ritieni di meritare la qualità ineguagliabile di servizi e supporto, questo è esattamente ciò che otterrai in TrioMarkets™. Ci assicuriamo che i nostri clienti HNI ottengano il meglio con il minimo sforzo da parte loro.</p> <br> <p class="text-center">Parlaremo di persona, faccia a faccia per comprendere le tue esigenze nel mercato e creare la migliore soluzione sia nell'auto-negoziazione che nella gestione dei fondi.</p>`,
        pointsTwo: [
          {
            text: "Contatto Personale con il nostro Capo <br> Analista di Mercato",
          },
          { text: "Introduzione ai nostri Executive Directors" },
          { text: "Il piu Alto Livello di Discrezione" },
          { text: "Conto Privato Separato" },
          { text: "Prelievi Automatici" },
          { text: "Fondi Detenuti in Svizzera" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `L'Autorità europea degli strumenti finanziari e dei mercati (ESMA) ha adottato nuove misure di protezione sui contratti per differenza (CFD) per i Clienti Retail. Le nuove misure di intervento sul prodotto si applicheranno dal 1 agosto 2018 e, tra le altre, consistono in restrizioni sulla distribuzione, limiti di leva finanziaria e regole di chiusura del margine. Le modifiche ESMA sono applicabili solo ai Clienti Retail. Pertanto, gli operatori professionali sono trattati in modo diverso rispetto agli Operatori retail. Richiedendo di diventare un cliente professionale, accetti di rinunciare ad alcune delle protezioni che ti sarebbero normalmente concesse come cliente Retail.`,
        textOne: `Tuttavia, ti riservi il diritto di richiedere un trattamento non professionale in qualsiasi momento e Triomarkets si impegna a offrirti un livello più elevato di protezione. Ti consigliamo di tornare allo stato di cliente Retail, se in qualsiasi momento ritieni di non essere in grado di valutare o gestire correttamente i rischi associati al trading. Si applicano termini e condizioni speciali per la gestione del portafoglio.`,
        unwaived: "Non Rinunciato",
        points: [
          {
            img: "best-execition",
            name: "Migliore Esecuzione",
            text: "Continueremo a prendere tutte le misure ragionevoli per garantire la migliore esecuzione degli ordini per i nostri clienti, agendo in modo equo, onesto e professionale.",
          },
          {
            img: "pro-status",
            name: "Reversione Status Professionale",
            text: "L'utente si riserva il diritto di ripristinare lo stato dei propri clienti a livello non professionale al fine di ottenere un maggiore livello di protezione, compresa la protezione del saldo negativo e la copertura da parte dell'Investor Compensation Fund (ICF) fino a Euro 20.000.",
          },
          {
            img: "account-seg",
            name: "Segregazione Account Cliente",
            text: "Chiediamo alla nostra banca di mantenere i conti dei nostri clienti separati da altri conti per salvaguardare i fondi dei nostri clienti.",
          },
        ],
        waived: "Rinunciato",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Restrizioni Alla Leva",
            text: "Le restrizioni sulla leva e sui margini non saranno applicabili all'utente, nel caso in cui siate in grado di dimostrare le conoscenze e l'esperienza rilevanti e la vostra comprensione dei rischi associati alla negoziazione di prodotti finanziari complessi.",
          },
          {
            img: "balance-protection",
            name: "Protezione del Saldo Negativo",
            text: "La protezione del saldo negativo non è offerta ai clienti professionali. Nel caso in cui il saldo commerciale dovesse risultare negativo, dovrai effettuare pagamenti aggiuntivi, con conseguenti perdite maggiori rispetto ai tuoi depositi.",
          },
          {
            img: "compensation-fund",
            name: "Fondo di Compensazione Degli Investitori",
            text: "Non sei più coperto dall'Investor Compensation Fund (ICF). Non sarà soddisfatta alcuna richiesta di protezione delle richieste derivanti da servizi coperti.",
          },
          {
            img: "risk-warnings",
            name: "Avvertenze Di Rischio",
            text: "Non siamo tenuti a fornirti le avvertenze sui rischi che sono normalmente obbligatorie da fornire ai clienti al dettaglio, dal momento che dimostrerai le tue conoscenze, la tua esperienza e la tua capacità di prendere decisioni informate, correndo il rischio.",
          },
        ],
        title:
          "Criteri di Ammissibilità per Diventare un Operatore Professionista",
        textTwo:
          "Per ottenere lo status di Elective Professional Client, è necessario soddisfare almeno due dei seguenti requisiti minimi applicati ai CFD:",
        pointsTree: [
          "Portafoglio di investimenti, inclusi depositi in contanti e strumenti finanziari che superano il valore di 500.000 EUR.",
          "1  o + anni  di esperienza lavorativa nel settore finanziario in un ruolo professionale che richiede la conoscenza del trading di prodotti con leva finanziaria.",
          "Volumi negoziati di transazioni di prodotti con leva di dimensioni significative pari o superiori a 10 scambi al trimestre negli ultimi quattro trimestri, ovvero, 40 scambi all'anno. Il volume verrà conteggiato verso tale obiettivo in una forma combinata, conclusa dalla negoziazione in qualsiasi broker forex.",
        ],
        textThree:
          "* Dimensioni significative: valore nozionale minimo di almeno 100.000 euro per le major FX, 50.000 per i minori FX, indici maggiori e oro, 10.000 euro su indici minori, altre materie prime e azioni.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "Effettua un deposito tramite il portafoglio TrioMarkets™. Depositare e prelevare fondi è ancora più semplice con il portafoglio TrioMarkets™. Ora puoi trasferire fondi tra il tuo portafoglio TrioMarkets™ e i tuoi conti di trading in qualsiasi momento.",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Lithuania",
            regulator: "Banca di Lituania",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "Regno Unito",
            regulator: "Autorità di Condotta Finanziaria (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "Regno Unito",
            regulator: "Autorità di Condotta Finanziaria (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estonia",
          //   regulator: "Unità di Informazione Finanziaria Estone",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">Accettiamo anche Bonifici Bancari</p>
          <p class="mb-0 fs-6">Trasferire fondi da banca a banca o da persona a istituto.<br>Un bonifico bancario può essere effettuato da un conto bancario ad un altro conto bancario.<br>In ogni momento, i tuoi dati bancari privati sono al sicuro tra te e il tuo istituto di credito.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">Accettiamo anche Bonifici Bancari</p>
            <p class="mb-0 fs-6">Trasferire fondi da banca a banca o da persona a istituto.<br>Un bonifico bancario può essere effettuato da un conto bancario ad un altro conto bancario.<br>In ogni momento, i tuoi dati bancari privati sono al sicuro tra te e il tuo istituto di credito.</p><p class="text-primary mb-0 fs-6">Accettiamo anche Bonifici Bancari</p>
            <p class="mb-0 fs-6">Trasferire fondi da banca a banca o da persona a istituto.<br>Un bonifico bancario può essere effettuato da un conto bancario ad un altro conto bancario.<br>In ogni momento, i tuoi dati bancari privati sono al sicuro tra te e il tuo istituto di credito.</p>`,
          },
        ],
        country: "Paese di Licenza",
        regulator: "Regolatore",
        beneficiary: "Beneficiario",
        code: "Codice",
        reference:
          "Riferimento: *Si prega di specificare il numero TA (opzionale)",
        bankAddress: "Indirizzo Bancario",
        bank: "Banca",
        switzerland: "la Svizzera",
        title: "Prelevare dal tuo Conto",
        textTwo: `<p>Noi crediamo che prelevare i vostri fondi dovrebbe essere facile quanto il deposito. Pertanto, deve essere semplice e senza problemi. Qui in TrioMarkets™ vi garantiamo un tempo di processo fisso. A seconda del tipo di conto che si detiene, il tempo di processo è da 24 ore a meno di 6 ore.</p><p>TrioMarkets™ offre una serie di opzioni di pagamento in base al metodo di deposito utilizzato per finanziare inizialmente il conto. Per ritirare i fondi deve essere utilizzato il metodo di pagamento del deposito iniziale. Se la quantità di ritiro è superiore al versamento iniziale, la differenza viene trasferito tramite Bonifico Bancario.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™ addebita una commissione del 1% dell'importo di prelievo per Visa / Mastercard / Neteller"
                : "TrioMarkets™ addebita una commissione del 3% dell'importo di prelievo per Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "Fondi saranno trasferiti in un conto registrato con lo stesso nome del titolare del conto TrioMarkets™ soltanto.",
          },
          {
            img: "3",
            text: "La commissione di bonifico è dell'1,5% con una spesa minima di 25,00 e massima di 50,00 (valuta di base). Il bonifico SEPA sarà soggetto a una commissione fissa di 7€",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Depositi",
        withdrawals: "Prelievi",
        fundingWithRow: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Importo minimo" },
            { name: "Valuta di base" },
            { name: "Commissioni" },
            { name: "Tempo di elaborazione" },
          ],
          [
            { name: "<strong>Soluzioni di pagamento online</strong>" },
            { name: "Importo minimo" },
            { name: "Valuta di base" },
            { name: "Commissioni" },
            { name: "Tempo di elaborazione" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Importo minimo" },
            { name: "Importo Massimo" },
            { name: "Valuta di base" },
            { name: "Commissioni" },
            { name: "Tempo di elaborazione" },
          ],
          [
            { name: "<strong>Soluzioni di pagamento online</strong>" },
            { name: "Importo minimo" },
            { name: "Importo Massimo" },
            { name: "Valuta di base" },
            { name: "Commissioni" },
            { name: "Tempo di elaborazione" },
          ],
        ],
        fundingWithTable: [
          "* Per transazioni",
          "** Le condizioni di trasferimento per la chiusura del conto possono essere negoziate con l'assistenza clienti.",
        ],
      },
    ],

    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Illimitato",
          currency: "USD, EUR, GBP, CHF",
          fees: "Gratis",
          time: "1-3 Giorni lavorativi",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Gratis",
          time: "Instantaneo",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 per giorno. per carta",
          currency: "USD, EUR",
          fees: "Gratis",
          time: "Instantaneo",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Gratis",
          time: "Instantaneo",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Gratis",
          time: "Instantaneo",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Illimitato",
        //   currency: "USD, EUR",
        //   fees: `Commissioni di rete<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Instantaneo",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "La commissione per il bonifico bancario è dell'1,5% con una commissione minima di 25,00 e massima di 50,00 (valuta di base).",
          time: "1-3 Giorni lavorativi",
        },
        {
          img: ["sepa"],
          min: "50",
          currency: "EUR",
          fees: "5€",
          time: "1-3 Giorni lavorativi",
        },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% dell'importo prelevato",
          time: "1 Giorno lavorativo",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% dell'importo prelevato`,
          time: "1 Giorno lavorativo",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "3% dell'importo prelevato",
          time: "1 Giorno lavorativo",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% dell'importo prelevato`,
          time: "1 Giorno lavorativo",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Commissioni di rete</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Commissioni di prelievo</strong><br>1.5% dell'importo prelevato`,
        //   time: "1 Giorno lavorativo",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  de: {
    accountsOverviewInfo: [
      {
        text: "Hier bei TrioMarkets™ erhalten Sie ein passendes Konto für alle Ihre Trading-Bedürfnisse, <br> egal ob Sie Trading-Anfänger sind oder schon auf eine lange Erfahrung damit zurückblicken.",
        textTwo:
          "Unsere Kontos wurden sorgfältig entworfen, um für jeden Tradertyp geeignet zu sein.",
        zeroCom: "Null Kommission Konten",
        basic: "Basic",
        standard: "Standard",
      },
    ],
    hniInfo: [
      {
        title: "Institutionelles Traden",
        text: "Firmenkonten werden sehr geschätzt bei TrioMarkets™.",
        textTwo: `TrioMarkets™ wurde mit den bestmöglichen Technologien aufgebaut, um auch die anspruchsvollsten Kunden zufrieden zu stellen. In einem Umfeld, wo Firmengelder verwaltet werden, darf man sich keine Fehler erlauben. <br> Wir bieten Ihnen die Professionalität und Qualität, die Sie erwarten und an die sie auch selbst als Unternehmen gewohnt sind. Sie können Ihre Devisen und Ihr CFD-Trading entweder als einen weiteren Baustein für Ihre Investment-Strategie nutzen oder auch um Ihre Risikoposition abzusichern.`,
        points: [
          { text: "Vermögensverwalter" },
          { text: "Hedge Funds" },
          { text: "Individuelle Firmenkunden" },
        ],
        titleTwo: "Vermögende Privatpersonen",
        textTree: `<p class="text-center mb-0">Falls Sie in diese Kategorie fallen und der Meinung sind, dass Ihnen ein Service von unvergleichlicher Qualität zusteht, dann erhalten Sie auch genau diesen Service bei TrioMarkets™. Wir stellen sicher, dass unsere vermögenden Privatkunden den allerbesten Service erhalten, ohne dass viel Aufwand für Sie anfällt.</p> <br> <p class="text-center">Wir stellen sicher, dass Sie persönliche Gespräche erhalten, direkt und persönlich um Ihre Bedürfnisse am Markt zu verstehen. Somit können wir für Sie die beste Trading Lösung bieten, sei es für Ihren eigenen Handel oder bei einer Vermögensverwaltung.</p>`,
        pointsTwo: [
          { text: "Persönlicher Kontakt mit unserem Chef-Marktanalysten" },
          { text: "Vorstellung bei unseren Geschäftsführern" },
          { text: "Höchstes Level an Diskretion" },
          { text: "Private Getrennte Konten" },
          { text: "Automatisierte Auszahlungen" },
          { text: "Besitz von Schweizer Fonds" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `Die Behörde der Europäischen Finanzmärkte (ESMA), hat Schutzmaßnahmen für Differenzkontrakte (CFD) mit Einzelhändlern beschlossen. Diese neuen Richtlinien beinhalten Einschränkungen zur Verteilung, Hebelgesetzen und zu den Marktschließungen, wobei nur die professionellen Trader davon betroffen sind.  Indem Sie beantragen, ein professioneller Kunde zu werden, erklären Sie sich damit einverstanden, auf einige der Schutzmaßnahmen zu verzichten, die Ihnen normalerweise als Privatkunde zustehen würden.`,
        textOne:
          "Sie haben jedoch zur jederzeit das Recht anzufragen, wieder als privaten Händler eingestuft zu werden. Hierbei erklärt sich TrioMarkets bereit, Ihnen einen höheres Maß an Schutz zu bieten. Wir raten Ihnen an, wieder als privater Händler tätig zu werden, sobald Sie der Auffassung sind, die Risiken des Traden nicht mehr einschätzen und verwalten zu können. Spezielle Richtlinien greifen bei der Portfolio-Verwaltung.",
        unwaived: "Unveränderter Schutz",
        points: [
          {
            img: "best-execition",
            name: "Beste Umsetzung",
            text: "Wir kümmern uns ehrlich, professionell und fair um jeden notwendigen Schritt, der zur besten Umsetzung der Auftragsdurchführung führt.",
          },
          {
            img: "pro-status",
            name: "Rückkehr vom Professionellem Status",
            text: "Sie behalten jederzeit das Recht Ihren Kundenstatus wieder auf den eines privaten Händlers zurückzusetzen, um ein höhreres Maß an Schutz zu erhalten. Die inklusiven Sicherheiten sind zum Beispiel, Absicherung bei einem negativen Kontosaldo und die Abdeckung von Fonds von bis zu 20.000 Euro durch den Anlegerentschädigungsfonds (ICF). ",
          },
          {
            img: "account-seg",
            name: "Getrennte Konten",
            text: "Wir bitten unsere Bank, die Konten unserer Kunden von anderen Konten getrennt zu halten, um die Gelder unserer Kunden zu schützen.",
          },
        ],
        waived: "Verzichtserklärung",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Einschränkung der Hebelwirkung",
            text: "Hebel- und Margenbeschränkungen gelten für Sie nicht, wenn Sie die entsprechenden Kenntnisse und Erfahrungen sowie Ihr Verständnis für die mit dem Handel komplexer Finanzprodukte verbundenen Risiken nachweisen können.",
          },
          {
            img: "balance-protection",
            name: "Absicherung bei Negativem Kontostand",
            text: "Die Absicherung bei einem negativen Kontostand wird nicht für professionelle Kunden angeboten. Sollte Ihr Kontostand ins Negative fallen, werden Sie weitere Einzahlungen tätigen müssen, was zu größeren Verluste führen wird als Ihre erste Einzahlung.",
          },
          {
            img: "compensation-fund",
            name: "Fondsanlegerentschädigung",
            text: "Als professioneller Händler sind Sie nicht mehr von dem Anlegerentschädigungsfonds (ICF) gedeckt. Einem Antrag auf Sicherung von Ansprüchen aus gedeckten Leistungen wird nicht entsprochen.",
          },
          {
            img: "risk-warnings",
            name: "Risikowarnungen",
            text: "Wir müssen Ihnen keine Risikowarnungen mitteilen, die wir normalerweise an die privaten Händler übermitteln müssten. Der Grund hierfür ist, dass Sie bewiesen haben die notwendigen Kenntnisse und Erfahrung zu besitzen, sowie Ihre Fähigkeit fundierte Entscheidung zu treffen, wohlwissend des bestehenden Risikos.",
          },
        ],
        title: "Auswahlverfahren zur Einstufung eines professionellen Traders",
        textTwo:
          "Um als professionellen Trader eingestuft zu werden müssen Sie mindestens zwei der folgenden Bediengungen, der CFD, erfüllen:",
        pointsTree: [
          "Ihr Investment Portfolio, inklusive Bareinlagen und Finanzinstrumente, übersteigt den Wert von 500.000 EUR.",
          " Sie haben mindestens 1 Jahr Berufserfahrung im Finanzsektor in einer professionellen Rolle, die Kenntnisse im Traden mit Hebelprodukten erfordert.",
          "Ihr Trading Volumen von Produkten mit Hebelgesetz hat eine maßgebliche Größe von entweder 10 Trades per Trimester, über eine Zeitspanne von 4 Trimestern oder aber 40 Trades pro Jahr. Das Volumen wird mit einer kombinierten Formel errechnet, die aus dem Traden von einem beliebigen Forex Broker stammt. ",
        ],
        textThree:
          "* Maßgebliche Größe: Minimaler Betrag für große FX Währungen von 100.000 EUR, 50.000 EUR für kleine FX Währungen, große Indize und Gold sowie 10.000 EUR für kleine Indize, andere Rohstoffe und Eigenkapital.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "Zahlen Sie Ihr Konto über die TrioMarkets™ Wallet-Option ein. Ein- und Auszahlungen sind einfacher mit dem TrioMarkets™-Wallet. Sie können jetzt Ihre Gelder zwischen Ihrem TrioMarkets™-Wallet und den Trading-Konten hin- und herbewegen. ",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Litauen",
            regulator: "Bank von Litauen",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "Vereinigtes Königreich",
            regulator: "Financial Conduct Authority (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "Vereinigtes Königreich",
            regulator: "Financial Conduct Authority (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estland",
          //   regulator: "Estnische Financial Intelligence Unit",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">Wir akzeptieren auch Banküberweisungen</p>
          <p class="mb-0 fs-6">Sie können Gelder zwischen Banken überweisen oder von Person zu Firma.<br>Eine Banküberweisung kann von einem Bankkonto auf ein anderes getätigt werden.<br>Ihre privaten finanziellen Informationen werden zu jeder Zeit zwischen Ihnen und Ihrem Finanzinstitut aufbewahrt.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">Wir akzeptieren auch Banküberweisungen</p>
            <p class="mb-0 fs-6">Sie können Gelder zwischen Banken überweisen oder von Person zu Firma.<br>Eine Banküberweisung kann von einem Bankkonto auf ein anderes getätigt werden.<br>Ihre privaten finanziellen Informationen werden zu jeder Zeit zwischen Ihnen und Ihrem Finanzinstitut aufbewahrt.</p>`,
          },
        ],
        country: "Land der Lizenz",
        regulator: "Regulierer",
        beneficiary: "Nutznießer",
        code: "Code",
        reference:
          "Verwendungszweck: *Bitte geben Sie Ihre TA-Nummer an (optional)",
        bankAddress: "Bankadresse",
        bank: "Bank",
        switzerland: "Schweiz",
        title: "Gelder auszahlen lassen",
        textTwo: `<p>Wir sind der Überzeugung, dass die Auszahlung von Geldern genauso einfach sein sollte wie die Einzahlung. Dies sollte daher einfach und problemlos funktionieren. Bei TrioMarkets™ garantieren wir Ihnen eine festgesetzte Zeit für Ihre Auszahlung. Abhängig davon, welchen Kontotypen Sie haben, dauert der Vorgang zwischen 6 und 24 Stunden.</p><p>TrioMarkets™ bietet Ihnen eine Reihe von möglichen Auszahlungsmethoden an, je nachdem, welche Methode Sie zur Einzahlung des Kontos genutzt hatten. Um eine Auszahlung zu tätigen, muss dieselbe Methode wie bei der Einzahlung verwendet werden. Falls der Auszahlungsbetrag höher ist als der Einzahlungsbetrag, dann muss die Differenz per Banküberweisung ausgezahlt werden.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™ berechnet eine Gebühr von 1% des Auszahlungsbetrages für Visa / Mastercard / Neteller"
                : "TrioMarkets™ berechnet eine Gebühr von 3% des Auszahlungsbetrages für Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "Das Geld wird nur auf ein Konto überwiesen, das unter dem gleichen Namen des TrioMarkets™-Kontoinhabers registriert ist.",
          },
          {
            img: "3",
            text: "Die Überweisungsgebühr beträgt 1,5% mit einer Mindestgebühr von 25,00 und höchstens 50,00 (Basiswährung). Für SEPA-Überweisungen wird eine feste Gebühr von 7€ berechnet",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Einzahlugen",
        withdrawals: "Abhebungen",
        fundingWithRow: [
          [
            { name: "<strong>Überweisungen</strong>" },
            { name: "Mindestbetrag" },
            { name: "Basiswährung" },
            { name: "Gebühren" },
            { name: "Bearbeitungszeit" },
          ],
          [
            { name: "<strong>Online-Zahlungslösungen</strong>" },
            { name: "Mindestbetrag" },
            { name: "Basiswährung" },
            { name: "Gebühren" },
            { name: "Bearbeitungszeit" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Überweisungen</strong>" },
            { name: "Mindestbetrag" },
            { name: "Höchstbetrag" },
            { name: "Basiswährung" },
            { name: "Gebühren" },
            { name: "Bearbeitungszeit" },
          ],
          [
            { name: "<strong>Online-Zahlungslösungen</strong>" },
            { name: "Mindestbetrag" },
            { name: "Höchstbetrag" },
            { name: "Basiswährung" },
            { name: "Gebühren" },
            { name: "Bearbeitungszeit" },
          ],
        ],
        fundingWithTable: [
          "* Pro Transaktion",
          "** Überweisungsbedingungen für Kontoauflösungen können mit dem Kundensupport ausgehandelt werden.",
        ],
      },
    ],
    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Unbegrenzt",
          currency: "USD, EUR, GBP, CHF",
          fees: "Kostenlos",
          time: "1-3 Werktage",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Kostenlos",
          time: "Sofort",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 pro Tag, pro Karte",
          currency: "USD, EUR",
          fees: "Kostenlos",
          time: "Sofort",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Kostenlos",
          time: "Sofort",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Kostenlos",
          time: "Sofort",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Unbegrenzt",
        //   currency: "USD, EUR",
        //   fees: `Netzwerkgebühren<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Sofort",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "Die Gebühr für Überweisungen beträgt 1,5 % mit einer Mindestgebühr von 25,00 und höchstens 50,00 (Basiswährung).",
          time: "1-3 Werktage",
        },
        {
          img: ["sepa"],
          min: "50",
          currency: "EUR",
          fees: "5€",
          time: "1-3 Werktage",
        },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% des Auszahlungsbetrags",
          time: "1 Werktag",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% des Auszahlungsbetrags`,
          time: "1 Werktag",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "3 % des Auszahlungsbetrags",
          time: "1 Werktag",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% des Auszahlungsbetrags`,
          time: "1 Werktag",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Netzwerkgebühren</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Abhebegebühren</strong><br>1.5% des Auszahlungsbetrags`,
        //   time: "1 Werktag",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  cn: {
    accountsOverviewInfo: [
      {
        text: "在TrioMarkets™, 无论你是交易新手还是有长期的交易经验, 你都会得到一个适合你所有交易需要的账户。",
        textTwo: "我们的账户经过精心设计, 适合所有类型的交易者。",
        zeroCom: "零佣金账户",
        basic: "标准",
        standard: "ECN",
        // standard: "高级",
      },
    ],
    hniInfo: [
      {
        title: "机构交易",
        text: "公司账户在 TrioMarkets™ 中受到高度重视。",
        textTwo: `TrioMarkets™ 建立在尖端技术，以满足即使是最苛刻的客户的需求。在涉及企业资金的情况下，不容有失。<br>我们为您提供专业和高质量服务。 您可以使用外汇和差价合约交易作为您投资策略的另一个要素，或者对冲您的交易风险。`,
        points: [
          { text: "投资经理" },
          { text: "对冲基金" },
          { text: "个人法人实体" },
        ],
        titleTwo: "高净值客户",
        textTree: `<p class="text-center mb-0">如果您属于这一类别, 并且您觉得自己应该得到无与伦比的服务质量, 那么这正是您在TrioMarkets™所能获得的。我们确保我们的高净值客户(HNI)以最少的努力获得最好的结果。我们将尽一切努力与您面对面交谈，了解您的市场需求，以便在自营交易或资金管理方面创造最佳解决方案。</p><br><p class="text-center">我们将确保与您面对面交谈并了解您在市场上的需求, 以在自营交易或资金管理方面创造最佳解决方案。</p>`,
        pointsTwo: [
          { text: "联系我们的首席市场分析师" },
          { text: "我们的执行董事简介" },
          { text: "最高级别的自由裁量权" },
          { text: "私人隔离账户" },
          { text: "自动提款" },
          { text: "资金储存在瑞士" },
        ],
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "通过 TrioMarkets™ 钱包为您的账户存款。使用 TrioMarkets™ 钱包可以使存款和取款变得容易。您现在可以随时在您的 TrioMarkets™ 钱包和交易账户之间转移资金。",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "立陶宛",
            regulator: "立陶宛银行",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "英国",
            regulator: "金融行为监管局 (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "英国",
            regulator: "金融行为监管局 (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "爱沙尼亚",
          //   regulator: "爱沙尼亚金融情报机构",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">我们也接受电汇</p>
          <p class="mb-0 fs-6">银行到银行或者个人到机构资金转账。<br>可以进行电汇，从一个银行账户到另一个银行账户。<br>任何时候，您的私人财务细节在您和您的金融机构之间保持安全。</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">我们也接受电汇</p>
            <p class="mb-0 fs-6">将资金转移到银行或个人到机构。<br>电汇可以从一个银行账户到另一个银行账户。<br>在任何时候, 您和您的金融机构之间的私人财务细节都是安全的。</p>`,
          },
        ],
        country: "执牌国家",
        regulator: "监管机构",
        beneficiary: "受益人",
        code: "代码",
        reference: "参考：*请指定您的 TA 编号 (可选）",
        bankAddress: "银行地址",
        bank: "银行",
        switzerland: "瑞士",
        title: "提取您的资金",
        textTwo: `<p>我们相信提取资金应该和存入资金一样容易。因此, 它应该简单且轻松。在 TrioMarkets™, 我们保证为您提供固定的处理时间。根据您持有的账户类型, 处理时间从 6小时到24小时不等。</p><p>TrioMarkets™ 提供了多种付款方式, 具体取决于您最初用于为帐户注资的存款方式。提取资金应使用初始存款支付方式。如果提款金额高于初始存款金额, 则差额将通过银行电汇转移。</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™对Visa / Mastercard / Neteller收取提款金额的1%的费用, 对Crypto收取1.5%的费用。"
                : "TrioMarkets™ 的 Visa / Mastercard / Neteller / Skrill / 加密货币 提款金额 收取3% 的费用。",
          },
          {
            img: "2",
            text: "资金只会转入与 TrioMarkets™ 账户持有人注册且名称相同的账户。",
          },
          {
            img: "3",
            text: "电汇费用为1.5%, 最低收费25.00, 最高50.00 (基础货币）。",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "存款",
        withdrawals: "提款",
        fundingWithRow: [
          [
            { name: "<strong>电汇</strong>" },
            { name: "最低金额" },
            { name: "基础货币" },
            { name: "费用" },
            { name: "处理时间" },
          ],
          [
            { name: "<strong>在线支付解决方案</strong>" },
            { name: "最低金额" },
            { name: "基础货币" },
            { name: "费用" },
            { name: "处理时间" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>电汇</strong>" },
            { name: "最低金额" },
            { name: "最大金额" },
            { name: "基础货币" },
            { name: "费用" },
            { name: "处理时间" },
          ],
          [
            { name: "<strong>在线支付解决方案</strong>" },
            { name: "最低金额" },
            { name: "最大金额" },
            { name: "基础货币" },
            { name: "费用" },
            { name: "处理时间" },
          ],
        ],
        fundingWithTable: [
          "* 每笔交易",
          "** 关闭账户的转账条件可与账户经理协商。",
        ],
      },
    ],
    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "无限",
          currency: "USD, EUR, GBP, CHF",
          fees: "免费",
          time: "1-3个工作日",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "免费",
          time: "立即的",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "每张卡每天10,000",
          currency: "USD, EUR",
          fees: "免费",
          time: "立即的",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "免费",
          time: "立即的",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "免费",
          time: "立即的",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "无限",
        //   currency: "USD, EUR",
        //   fees: `网络费用<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "立即的",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "电汇费用为 1.5%, 最低收费为 25.00, 最高为 50.00 (基础货币）。",
          time: "1-3个工作日",
        },
        // {
        //   img: ["sepa"],
        //   min: "50",
        //   currency: "USD, EUR, GBP, CHF",
        //   fees: "电汇费用为 1.5%, 最低收费 25.00 最高 50.00 (基础货币）。<br> 仅限 SEPA 转账, 低于 100 欧元的提款请求将收取 5 欧元的固定费用。**",
        //   time: "1-3个工作日",
        // },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "取款金额的1%",
          time: "1 个工作日",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `取款金额的1%`,
          time: "1 个工作日",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "取款金额的3%",
          time: "1 个工作日",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `取款金额的1%`,
          time: "1 个工作日",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>网络费用</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>提款费用</strong><br>取款金额的1.5%`,
        //   time: "1 个工作日",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  es: {
    accountsOverviewInfo: [
      {
        text: "Aquí en TrioMarkets™ tanto si eres novato en el trading como si tienes una larga trayectoria en él, <br> obtendrás una cuenta adecuada para todas tus necesidades de trading.",
        textTwo:
          "Nuestras cuentas han sido cuidadosamente diseñadas para adaptarse a todo tipo de comerciantes.",
        zeroCom: "Cuentas de Comisión Cero",
        basic: "Básica",
        standard: "Estándar",
      },
    ],
    hniInfo: [
      {
        title: "Negociación Institucional",
        text: "Las cuentas corporativas son muy valoradas en TrioMarkets™.",
        textTwo: `TrioMarkets™ se planteó sobre la mejor de las tecnologías para adaptarse incluso a los clientes más exigentes. No hay posibilidad de error en un entorno cuando se trata de fondos corporativos. <br> Le brindamos la profesionalidad y la calidad que espera y brindamos de su propia entidad. Puede utilizar el comercio de Forex y CFD, ya sea como otro elemento en su estrategia de inversión o para protegerse contra su exposición.`,
        points: [
          { text: "Gestores de Inversiones" },
          { text: "Los Fondos de Cobertura" },
          { text: "Entidades Corporativas Individuales" },
        ],
        titleTwo: "Individuos de Alto Valor Neto",
        textTree: `<p class="text-center mb-0">Si usted es una persona que pertenece a esta categoría y siente que merece la calidad inigualable de los servicios y el soporte, bueno, eso es exactamente lo que obtendrá en Triomarkets. Nos aseguramos de que nuestros clientes de HNI obtengan lo mejor con el menor esfuerzo de su parte.</p> <br> <p class="text-center">Nos aseguraremos de hablar con usted en persona, cara a cara y comprender sus necesidades en el mercado para crear la mejor solución, ya sea en el comercio propio o en la gestión de fondos.</p>`,
        pointsTwo: [
          { text: "Personal Contacto con nuestro Chief Market Analyst" },
          { text: "Introducción a nuestros Directores Ejecutivos" },
          { text: "Nivel más Alto de Discreción" },
          { text: "Cuenta Privada Segregada" },
          { text: "Retiros Automatizados" },
          { text: "Fondos Retenidos en Suiza" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `A raíz de la nueva Autoridad Europea de Valores y Mercados (ESMA) medidas de protección de los contratos por diferencia (CFD) al por menor comerciantes, que entre otros, consisten en restricciones a distribución, límites de apalancamiento y reglas de cierre de margen, profesional son tratados de manera diferente. Al solicitar convertirse en Cliente Profesional, usted acepta renunciar a algunas de las protecciones que normalmente se le otorgarían como Cliente Minorista.`,
        textOne: `Se reserva el derecho de solicitar un trato no profesional en cualquier momento y TrioMarkets aceptara ofrecerle un mayor nivel de proteccion. Se le recomienda volver al estado de cliente minorista, si en algun momento cree que no puede evaluar o gestionar adecuadamente los riesgos relacionados con el trading.`,
        unwaived: "No Renunciado",
        points: [
          {
            img: "best-execition",
            name: "Mejor Ejecución",
            text: "Seguiremos tomando todas las medidas razonables para garantizar el mejor orden ejecución para nuestros clientes sin dejar de ser justos, honestos, y profesional.",
          },
          {
            img: "pro-status",
            name: "Reversión de Estado Profesional",
            text: "Usted conserva el derecho de cambiar su estado de cliente de nuevo a no profesional para recibir un mayor nivel de protección,tales como protección de saldo negativo y cobertura por parte del Inversor Fondo de Compensación (ICF) de hasta 20K Euros.",
          },
          {
            img: "account-seg",
            name: "Segregación de Cuentas",
            text: "Para salvaguardar los fondos de nuestros clientes, solicitamos que nuestro Banco mantenga nuestros las cuentas de los clientes separadas de otras cuentas.",
          },
        ],
        waived: "Renunciado",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Restricción <br> de Apalancamiento",
            text: "Las restricciones de apalancamiento y margen no se aplicarán a usted si puede demostrar conocimientos y experiencia relevantes, así como su comprensión de los riesgos asociados con el comercio financiero complejo productos.",
          },
          {
            img: "balance-protection",
            name: "Protección <br> de Saldo Negativo",
            text: "Los clientes profesionales no tienen derecho a saldo negativo proteccion. Si su saldo comercial cae por debajo de cero, se le obligados a hacer pagos adicionales, lo que resulta en pérdidas mayores que sus depósitos.",
          },
          {
            img: "compensation-fund",
            name: "Fondo de Compensación <br> de Inversores",
            text: "Ya no está cubierto por el Fondo de Compensación de Inversores (ICF). Cualquier aplicación para asegurar reclamos que surjan de los servicios cubiertos será negado.",
          },
          {
            img: "risk-warnings",
            name: "Advertencias <br> de Riesgo",
            text: "No estamos obligados a proporcionarle las advertencias de riesgo que son normalmente se proporciona a los clientes minoristas porque demostrará su conocimiento y experiencia, así como su capacidad para hacer decisiones informadas mientras asume su riesgo.",
          },
        ],
        title:
          "Criterios de Elegibilidad para Convertirse en un Trader Profesional",
        textTwo:
          "Para calificar para un estatus de cliente profesional electivo, debe cumplir al menos dos de los siguientes requisitos mínimos que se aplican a los CFD:",
        pointsTree: [
          "Cartera de inversión, incluidos depósitos en efectivo e instrumentos financieros que supere los 500.000 EUR de valor.",
          "Más de 1 año(s) de experiencia laboral en el sector financiero en un puesto profesional que requiera conocimientos sobre la negociación de productos apalancados.",
          "Volúmenes de negociación de transacciones de productos apalancados de tamaño significativo igual o superior a 10 transacciones por trimestre durante los últimos cuatro trimestres, o 40 transacciones por año. El volumen contará para ese objetivo en una forma combinada, concluida de la negociación en cualquier corredor de divisas.",
        ],
        textThree:
          "* Tamaño significativo: valor nocional mínimo de al menos 100 000 euros para divisas principales, 50 000 para divisas secundarias, índices principales y oro, 10 000 euros para índices menores, otras materias primas y acciones.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "Deposite fondos en su cuenta a través de TrioMarkets™ Wallet. La financiación y el retiro de fondos son más fáciles con TrioMarkets™ Wallet. Ahora puede transferir fondos entre su Monedero de TrioMarkets™ y las cuentas comerciales en cualquier momento que desee.",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Lituania",
            regulator: "Banco de Lituania",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "Reino Unido",
            regulator: "Autoridad de Conducta Financiera (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "Reino Unido",
            regulator: "Autoridad de Conducta Financiera (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estonia",
          //   regulator: "Unidad de Inteligencia Financiera de Estonia",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">También aceptamos transferencia bancaria</p>
          <p class="mb-0 fs-6">Transferir fondos de banco a banco o de persona a institución.<br>Se puede realizar una transferencia bancaria de una cuenta bancaria a otra cuenta bancaria.<br>En todo momento, sus detalles financieros privados se mantienen seguros entre usted y su institución financiera.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">También aceptamos transferencia bancaria</p>
            <p class="mb-0 fs-6">Transferir fondos de banco a banco o de persona a institución.<br>Se puede realizar una transferencia bancaria de una cuenta bancaria a otra cuenta bancaria.<br>En todo momento, sus detalles financieros privados se mantienen seguros entre usted y su institución financiera.</p>`,
          },
        ],
        country: "País de licencia",
        regulator: "Regulador",
        beneficiary: "Beneficiario",
        code: "Código",
        reference: "Referencia: *Especifique su número de TA (opcional)",
        bankAddress: "Dirección del Banco",
        bank: "Banco",
        switzerland: "Suiza",
        title: "Retirar sus Fondos",
        textTwo: `<p>Creemos que retirar sus fondos debería ser tan fácil como depositarlos. Por lo tanto, debe ser simple y sin complicaciones. Aquí en Triomarkets le garantizamos un tiempo de proceso fijo. Según el tipo de cuenta que tenga, el tiempo de proceso es de 24 horas a tan solo 6 horas.</p><p>TrioMarkets™ ofrece una serie de opciones de pago basadas en el método de depósito utilizado para financiar inicialmente su cuenta. Para retirar los fondos se utilizará el método de pago del depósito inicial. Si el monto del retiro es superior al monto del depósito inicial, la diferencia se transferirá mediante transferencia bancaria.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™ cobra una tarifa del 1% del monto del retiro para Visa / Mastercard / Neteller"
                : "TrioMarkets™ cobra una tarifa del 3 % del monto del retiro para Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "Los fondos solo se transferirán a una cuenta que esté registrada y a nombre del titular de la cuenta de Triomarkets.",
          },
          {
            img: "3",
            text: "La tarifa de transferencia bancaria es del 1,5 % con un cargo mínimo de 25,00 y un máximo de 50,00 (moneda base). La transferencia SEPA tendrá un cargo fijo de 7€",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Depósitos",
        withdrawals: "Retiros",
        fundingWithRow: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Monto minimo" },
            { name: "Moneda base" },
            { name: "Tarifa" },
            { name: "Tiempo de procesamiento" },
          ],
          [
            { name: "<strong>Soluciones de pago en línea</strong>" },
            { name: "Monto minimo" },
            { name: "Moneda base" },
            { name: "Tarifa" },
            { name: "Tiempo de procesamiento" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Monto minimo" },
            { name: "Importe Máximo" },
            { name: "Moneda base" },
            { name: "Tarifa" },
            { name: "Tiempo de procesamiento" },
          ],
          [
            { name: "<strong>Soluciones de pago en línea</strong>" },
            { name: "Monto minimo" },
            { name: "Importe Máximo" },
            { name: "Moneda base" },
            { name: "Tarifa" },
            { name: "Tiempo de procesamiento" },
          ],
        ],
        fundingWithTable: [
          "* Por transacción",
          "** Las condiciones de transferencia para el cierre de cuentas pueden negociarse con el servicio de atención al cliente.",
        ],
      },
    ],

    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Ilimitado",
          currency: "USD, EUR, GBP, CHF",
          fees: "Libre",
          time: "1-3 Días laborables",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Libre",
          time: "Instantáneo",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 por día, por tarjeta",
          currency: "USD, EUR",
          fees: "Libre",
          time: "Instantáneo",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Libre",
          time: "Instantáneo",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Libre",
          time: "Instantáneo",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Ilimitado",
        //   currency: "USD, EUR",
        //   fees: `Tarifas de red<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Instantáneo",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "La tarifa de transferencia bancaria es del 1,5 % con un cargo mínimo de 25,00 y un máximo de 50,00 (moneda base). ",
          time: "1-3 Días laborables",
        },
        {
          img: ["sepa"],
          min: "50",
          currency: "EUR",
          fees: "5€",
          time: "1-3 Días laborables",
        },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% del monto del retiro",
          time: "1 Día laborable",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% del monto del retiro`,
          time: "1 Día laborable",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "3% del monto del retiro",
          time: "1 Día laborable",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% del monto del retiro`,
          time: "1 Día laborable",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Tarifas de red</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Tarifas de retiro</strong><br>1.5% del monto del retiro`,
        //   time: "1 Día laborable",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  vnm: {
    accountsOverviewInfo: [
      {
        text: "Tại TrioMarkets™, cho dù bạn là người mới giao dịch hay bạn đã có một hành trình dài với nó, <br> bạn sẽ có được một tài khoản phù hợp cho mọi nhu cầu giao dịch của mình.",
        textTwo:
          "Các tài khoản của chúng tôi đã được thiết kế cẩn thận để phù hợp với tất cả các loại nhà giao dịch.",
        zeroCom: "Tài khoản không phí hoa hồng",
        basic: "Xu",
        standard: "Tiêu chuẩn",
      },
    ],
    hniInfo: [
      {
        title: "Giao dịch tổ chức",
        text: "Tài khoản công ty rất có giá trị trong TrioMarkets™.",
        textTwo: `Chúng tôi cung cấp cho khách hàng doanh nghiệp và tổ chức một giải pháp giao dịch phù hợp với hồ sơ, nhu cầu và yêu cầu của họ, dựa trên công nghệ mạnh mẽ, nhiều loại tài sản và hỗ trợ tận tâm. <br> Chúng tôi cung cấp cho bạn tính chuyên nghiệp và chất lượng mà bạn mong đợi và cung cấp từ tổ chức của riêng bạn. Bạn có thể sử dụng giao dịch Forex và CFD, như một yếu tố khác trong chiến lược đầu tư của bạn hoặc để phòng ngừa rủi ro của bạn.`,
        points: [
          { text: "Giám đốc đầu tư" },
          { text: "Quỹ đầu tư" },
          { text: "Các tổ chức công ty cá nhân" },
        ],
        titleTwo: "Cá nhân có giá trị ròng cao",
        textTree: `<p class="text-center mb-0">Nếu bạn thuộc loại này và tin rằng bạn được hưởng các dịch vụ và hỗ trợ tốt nhất hiện có, TrioMarkets ™ là nơi dành cho bạn. Chúng tôi đảm bảo rằng khách hàng HNI của chúng tôi nhận được dịch vụ tốt nhất có thể mà họ phải nỗ lực ít nhất. Chúng tôi sẽ cố gắng hết sức để nói chuyện trực tiếp với bạn, trực tiếp và hiểu nhu cầu thị trường của bạn để tạo ra giải pháp tốt nhất trong việc tự doanh hoặc quản lý quỹ. </p> <br> <p class="text-center">Chúng tôi sẽ đảm bảo nói chuyện với bạn trực tiếp, và hiểu nhu cầu của bạn trên thị trường để tạo ra giải pháp tốt nhất trong việc tự kinh doanh hoặc quản lý quỹ.</p>`,
        pointsTwo: [
          {
            text: "Liên hệ trực tiếp với Nhà phân tích thị trường <br> chính của chúng tôi",
          },
          {
            text: "Giới thiệu về Giám đốc điều hành của <br> chúng tôi Đạo diễn",
          },
          { text: "Mức độ quyết định cao nhất" },
          { text: "Tài khoản riêng tư tách biệt" },
          { text: "Rút tiền tự động" },
          { text: "Quỹ được tổ chức ở Thụy Sĩ" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `Theo các biện pháp bảo vệ mới của Cơ quan Thị trường và Chứng khoán Châu Âu (ESMA) đối với các hợp đồng chênh lệch (CFD) đối với các nhà giao dịch bán lẻ, theo đó các nhà giao dịch khác bao gồm các hạn chế về phân phối, giới hạn đòn bẩy và quy tắc đóng ký quỹ, các nhà giao dịch chuyên nghiệp được đối xử khác nhau. Bởi yêu cầu trở thành Khách hàng Chuyên nghiệp, bạn đồng ý từ bỏ một số biện pháp bảo vệ mà thông thường sẽ dành cho bạn với tư cách là Khách hàng Bán lẻ. `,
        textOne: `Tuy nhiên, bạn có quyền yêu cầu đối xử không chuyên nghiệp bất cứ lúc nào và TrioMarkets ™ sẽ đồng ý cung cấp cho bạn mức bảo vệ cao hơn. Bạn nên trở lại trạng thái khách hàng bán lẻ, nếu tại bất kỳ thời điểm nào bạn tin rằng mình đang không thể đánh giá hoặc quản lý đúng các rủi ro liên quan đến giao dịch. Các điều khoản và điều kiện đặc biệt được áp dụng liên quan đến Quản lý danh mục đầu tư.`,
        unwaived: "Chưa được bảo tồn",
        points: [
          {
            img: "best-execition",
            name: "Thực thi tốt nhất",
            text: "Chúng tôi sẽ tiếp tục thực hiện tất cả các bước hợp lý để đảm bảo thực hiện đơn hàng tốt nhất cho khách hàng của mình trong khi vẫn công bằng, trung thực và chuyên nghiệp.",
          },
          {
            img: "pro-status",
            name: "Chuyển đổi trạng thái chuyên nghiệp",
            text: "Bạn có quyền thay đổi trạng thái khách hàng của mình trở lại không chuyên nghiệp để nhận được mức bảo vệ cao hơn, chẳng hạn như bảo vệ số dư âm và bảo hiểm bởi Quỹ bồi thường nhà đầu tư (ICF) lên đến 20 nghìn Euro.",
          },
          {
            img: "account-seg",
            name: "Tách biệt tài khoản",
            text: "Để bảo vệ khách hàng của chúng tôi ', chúng tôi yêu cầu Ngân hàng của chúng tôi giữ tài khoản của khách hàng tách biệt với các tài khoản khác. ",
          },
        ],
        waived: "Được miễn",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Hạn chế đòn bẩy",
            text: "Các hạn chế về đòn bẩy và ký quỹ sẽ không áp dụng cho bạn nếu bạn có thể chứng minh kiến   thức và kinh nghiệm có liên quan, cũng như hiểu biết của bạn về những rủi ro liên quan đến giao dịch các sản phẩm tài chính phức tạp.",
          },
          {
            img: "balance-protection",
            name: "Bảo vệ số dư âm",
            text: "Khách hàng chuyên nghiệp không có quyền bảo vệ số dư âm. Nếu số dư giao dịch của bạn giảm xuống dưới 0, bạn sẽ được yêu cầu thanh toán bổ sung, dẫn đến thua lỗ lớn hơn số tiền gửi của bạn. ",
          },
          {
            img: "compensation-fund",
            name: "Quỹ bồi thường nhà đầu tư",
            text: "Bạn không còn được bảo hiểm bởi Quỹ Bồi thường Nhà đầu tư (ICF). Mọi đơn xin bảo đảm yêu cầu bồi thường phát sinh từ các dịch vụ được bảo hiểm sẽ bị từ chối.",
          },
          {
            img: "risk-warnings",
            name: "Cảnh báo rủi ro",
            text: "Chúng tôi không bắt buộc phải cung cấp cho bạn những cảnh báo rủi ro thường được cung cấp cho khách hàng bán lẻ bởi vì bạn sẽ chứng minh kiến   thức và kinh nghiệm của mình, cũng như khả năng đưa ra quyết định sáng suốt trong khi chịu rủi ro.",
          },
        ],
        title: "Tiêu chí đủ điều kiện để trở thành nhà giao dịch chuyên nghiệp",
        textTwo:
          "Để đủ điều kiện cho Trạng thái khách hàng chuyên nghiệp được lựa chọn, bạn phải đáp ứng ít nhất hai trong số các yêu cầu tối thiểu sau được áp dụng cho CFD:",
        pointsTree: [
          "Danh mục đầu tư, bao gồm tiền gửi tiền mặt và các công cụ tài chính có giá trị vượt quá 500.000 EUR.",
          "Hơn 1 năm kinh nghiệm làm việc trong lĩnh vực tài chính với vai trò chuyên môn, yêu cầu kiến thức về kinh doanh các sản phẩm đòn bẩy.",
          "Khối lượng giao dịch các giao dịch của các sản phẩm đòn bẩy có quy mô đáng kể bằng hoặc lớn hơn 10 giao dịch mỗi quý trong bốn quý qua, hoặc 40 giao dịch mỗi năm. Khối lượng sẽ được tính cho mục tiêu đó theo một hình thức kết hợp, được kết thúc từ giao dịch trong bất kỳ nhà môi giới ngoại hối nào.",
        ],
        textThree:
          "* Quy mô đáng kể: Giá trị danh nghĩa tối thiểu ít nhất 100.000 Euro đối với chuyên ngành FX, 50.000 đối với tỷ giá hối đoái trẻ vị thành niên FX, chỉ số chính và vàng, 10.000 Euro đối với chỉ số phụ, hàng hóa và cổ phiếu khác.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "Bạn có thể nạp tiền vào tài khoản của mình bằng Ví TrioMarkets ™. Ví TrioMarkets ™ giúp nạp tiền và rút tiền dễ dàng hơn. Giờ đây, bạn có thể chuyển tiền giữa Ví TrioMarkets ™ và các tài khoản giao dịch của mình bất cứ khi nào bạn muốn. ",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Lithuania",
            regulator: "Ngân hàng Litva",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "Vương quốc Anh",
            regulator: "Cơ quan quản lý tài chính (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "Vương quốc Anh",
            regulator: "Cơ quan quản lý tài chính (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estonia",
          //   regulator: "Đơn vị tình báo tài chính Estonian",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">Chúng tôi cũng chấp nhận chuyển khoản</p>
          <p class="mb-0 fs-6">Chuyển tiền từ ngân hàng này sang ngân hàng khác hoặc người đến tổ chức. <br>Có thể thực hiện chuyển khoản ngân hàng từ tài khoản ngân hàng này sang tài khoản ngân hàng khác.<br>Mọi lúc, chi tiết tài chính riêng tư của bạn được giữ an toàn giữa bạn và tổ chức tài chính của bạn.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">Chúng tôi cũng chấp nhận chuyển khoản</p>
            <p class="mb-0 fs-6">Chuyển tiền từ ngân hàng này sang ngân hàng khác hoặc người đến tổ chức. <br>Có thể thực hiện chuyển khoản ngân hàng từ tài khoản ngân hàng này sang tài khoản ngân hàng khác.<br>Mọi lúc, chi tiết tài chính riêng tư của bạn được giữ an toàn giữa bạn và tổ chức tài chính của bạn.</p>`,
          },
        ],
        country: "Quốc gia của Giấy phép",
        regulator: "Bộ điều chỉnh",
        beneficiary: "Người thụ hưởng",
        code: "Mã số",
        reference: "Tham khảo: * Vui lòng ghi rõ Số TA của bạn (tùy chọn)",
        bankAddress: "Địa chỉ ngân hàng",
        bank: "Ngân hàng",
        switzerland: "Thụy sĩ",
        title: "Rút tiền của bạn",
        textTwo: `<p>Chúng tôi tin rằng việc rút tiền phải đơn giản như gửi tiền. Do đó, nó phải đơn giản và không phức tạp. Tại TrioMarkets ™, chúng tôi đảm bảo cho bạn một thời gian xử lý cố định. Thời gian xử lý thay đổi tùy thuộc vào loại tài khoản bạn có, từ 24 giờ đến ít nhất là 6 giờ.</p><p>TrioMarkets ™ cung cấp nhiều loại tùy chọn thanh toán dựa trên phương thức gửi tiền được sử dụng để nạp tiền vào tài khoản của bạn. Phương thức thanh toán tiền gửi ban đầu phải được sử dụng để rút tiền. Nếu số tiền rút vượt quá số tiền gửi ban đầu, phần chênh lệch sẽ được chuyển qua chuyển khoản ngân hàng.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™  tính 1% phí rút tiền qua Visa / Mastercard / Neteller"
                : "TrioMarkets™  tính 3% phí rút tiền qua Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "Tiền sẽ chỉ được chuyển vào tài khoản đã được đăng ký và đứng tên chủ tài khoản TrioMarkets ™.",
          },
          {
            img: "3",
            text: "Phí chuyển khoản là 1,5% với mức phí tối thiểu là 25,00 và tối đa là 50,00 (Tiền tệ cơ sở).",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Tiền gửi",
        withdrawals: "Rút tiền",
        fundingWithRow: [
          [
            { name: "<strong>Chuyển dây</strong>" },
            { name: "Số tiền tối thiểu" },
            { name: "Tiền tệ cơ bản" },
            { name: "Phí" },
            { name: "Thời gian xử lý" },
          ],
          [
            { name: "<strong>Giải pháp thanh toán trực tuyến</strong>" },
            { name: "Số tiền tối thiểu" },
            { name: "Tiền tệ cơ bản" },
            { name: "Phí" },
            { name: "Thời gian xử lý" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Chuyển dây</strong>" },
            { name: "Số tiền tối thiểu" },
            { name: "Số tiền tối đa" },
            { name: "Tiền tệ cơ bản" },
            { name: "Phí" },
            { name: "Thời gian xử lý" },
          ],
          [
            { name: "<strong>Giải pháp thanh toán trực tuyến</strong>" },
            { name: "Số tiền tối thiểu" },
            { name: "Số tiền tối đa" },
            { name: "Tiền tệ cơ bản" },
            { name: "Phí" },
            { name: "Thời gian xử lý" },
          ],
        ],
        fundingWithTable: [
          "* Mỗi giao dịch",
          "** Các điều kiện chuyển khoản để đóng tài khoản có thể được thương lượng với người quản lý tài khoản.",
        ],
      },
    ],

    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Vô hạn",
          currency: "USD, EUR, GBP, CHF",
          fees: "Tự do",
          time: "1-3 Ngày làm việc",
        },
        {
          img: ["asia"],
          in:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Tự do",
          time: "Lập tức",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 mỗi ngày, mỗi thẻ",
          currency: "USD, EUR",
          fees: "Tự do",
          time: "Lập tức",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Tự do",
          time: "Lập tức",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Tự do",
          time: "Lập tức",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Vô hạn",
        //   currency: "USD, EUR",
        //   fees: `Phí mạng<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Lập tức",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "Phí chuyển khoản là 1,5% với mức phí tối thiểu là 25,00 và tối đa là 50,00 (Tiền tệ cơ sở). ",
          time: "1-3 Ngày làm việc",
        },
        // {
        //   img: ["sepa"],
        //   min: "50",
        //   currency: "USD, EUR, GBP, CHF",
        //   fees: "Phí chuyển khoản là 1,5% với mức phí tối thiểu là 25,00 và tối đa là 50,00 (Đơn vị tiền tệ cơ sở). <br> Chỉ đối với Chuyển khoản SEPA, yêu cầu rút tiền dưới 100€ sẽ bị tính phí cố định là 5€. **",
        //   time: "1-3 Ngày làm việc",
        // },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% số tiền rút",
          time: "1 Ngày làm việc",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% số tiền rút`,
          time: "1 Ngày làm việc",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "3% số tiền rút",
          time: "1 Ngày làm việc",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% số tiền rút`,
          time: "1 Ngày làm việc",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Phí mạng</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Phí rút tiền</strong><br>1.5% số tiền rút`,
        //   time: "1 Ngày làm việc",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  enUae: {
    accountsOverviewInfo: [
      {
        text: "Here at TrioMarkets™ whether you're novice in trading or you have a long journey with it, you'll get a suitable account for all your trading needs.",
        textTwo:
          "Our accounts have been carefully designed to suit all types of traders.",
        zeroCom: "Zero Commission Accounts",
        basic: "Basic",
        standard: "Standard",
      },
    ],
    hniInfo: [
      {
        title: "Institutional Trading",
        text: "Corporate Accounts are highly valued within TrioMarkets™.",
        textTwo: `We offer corporate and institutional clients a trading solution tailored to their profile, needs and requirements, based on powerful technology, a wide range of assets and dedicated support. <br> We give you the professionalism and quality you expect and provide from your own entity. You may use Forex and CFD trading, either as another element in your investment strategy or to hedge against your exposure.`,
        points: [
          { text: "Investment Managers" },
          { text: "Hedge Funds" },
          { text: "Individual Corporate Entities" },
        ],
        titleTwo: "High Net Worth Individuals",
        textTree: `<p class="text-center mb-0">If you fall into this category and believe you are entitled to the best services and support available, TrioMarkets™ is the place to be. We make certain that our HNI clients receive the best service possible with the least amount of effort on their part. We will make every effort to speak with you in person, face to face, and understand your market needs in order to create the best solution in either self-trading or fund management.</p> <br> <p class="text-center">We will make sure to talk to you in person, face to face and understand your needs in the market to create the best solution in either self-trading or anagement of funds.</p>`,
        pointsTwo: [
          { text: "Personnel Contact with our Chief Market Analyst" },
          { text: "Introduction to our Executive Directors" },
          { text: "Highest Level of Discretion" },
          { text: "Private Segregated Account" },
          { text: "Automated Withdrawals" },
          { text: "Funds Held in Switzerland" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `Following the European Securities and Markets Authority (ESMA) new protection measures on contracts for difference (CFDs) to retail traders, whereby among others, consist of restrictions on distribution, leverage limits and margin close out rules, professional traders are treated differently. By requesting to become a Professional Client, you are agreeing to waive some of the protections that would normally be afforded to you as a Retail Client.`,
        textOne: `However, you reserve the right to request non-professional treatment at any time and TrioMarkets™ shall agree to offer you a higher level of protection. You are advised to revert back to retail client status, if at any point you believe that you are unable to properly assess or manage the risks involved with trading. Special terms and conditions apply regarding Portfolio Management.`,
        unwaived: "Unwaived",
        points: [
          {
            img: "best-execition",
            name: "Best Execution",
            text: "We will continue to take all reasonable steps to ensure the best order execution for our clients while remaining fair, honest, and professional.",
          },
          {
            img: "pro-status",
            name: "Pro Status Reversion",
            text: "You retain the right to change your client status back to non-professional in order to receive a higher level of protection, such as negative balance protection and coverage by the Investor Compensation Fund (ICF) of up to Euro 20K.",
          },
          {
            img: "account-seg",
            name: "Account Segregation",
            text: "To safeguard our clients' funds, we request that our Bank keeps our clients' accounts separate from other accounts.",
          },
        ],
        waived: "Waived",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Leverage Restriction",
            text: "Leverage and margin restrictions will not apply to you if you can demonstrate relevant knowledge and experience, as well as your understanding of the risks associated with trading complex financial products.",
          },
          {
            img: "balance-protection",
            name: "Negative Balance Protection",
            text: "Professional clients do not have rights to negative balance protection. If your trading balance falls below zero, you will be required to make additional payments, resulting in losses greater than your deposits.",
          },
          {
            img: "compensation-fund",
            name: "Investor Compensation Fund",
            text: "You are no longer covered by the Investor Compensation Fund (ICF). Any application to secure claims arising from covered services will be denied.",
          },
          {
            img: "risk-warnings",
            name: "Risk Warnings",
            text: "We are not required to provide you with the risk warnings that are normally provided to retail clients because you will demonstrate your knowledge and experience, as well as your ability to make informed decisions while bearing your risk.",
          },
        ],
        title: "Eligibility Criteria to Become a Professional Trader",
        textTwo:
          "To qualify for an Elective Professional Client Status you are required to satisfy at least two of the following minimum requirements applied to CFDs:",
        pointsTree: [
          "Investment portfolio, including cash deposits and financial instruments that exceeds 500,000 EUR in value.",
          "1 + year(s) of working experience in the financial sector in a professional role requiring knowledge of trading leveraged products.",
          "Trading volumes of transactions of leveraged products of significant size equal or greater to 10 trades per quarter over the past four quarters, or, 40 trades per year. The volume will be counted towards that goal in a combined forma, concluded from trading in any forex broker.",
        ],
        textThree:
          "* Significant size: Minimum notional value of at least 100,000 Euros for FX majors, 50,000 for FX minors, major indexes and gold, 10,000 Euros on minor indexes, other commodities and equities.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "You can fund your account using the TrioMarkets™ Wallet. The TrioMarkets™ Wallet makes it easier to fund and withdraw funds. You can now transfer money between your TrioMarkets™ Wallet and your trading accounts whenever you want.",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Lithuania",
            regulator: "Bank of Lithuania",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "United Kingdom",
            regulator: "Financial Conduct Authority (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "United Kingdom",
            regulator: "Financial Conduct Authority (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estonia",
          //   regulator: "Estonian Financial Intelligence Unit",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">We also accept wire transfer</p>
          <p class="mb-0 fs-6">Transfer funds bank-to-bank or person to institution.<br>A wire transfer can be made from one bank account to another bank account.<br>At all times, your private financial details are kept safe between you and your financial institution.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">We also accept wire transfer</p>
          <p class="mb-0 fs-6">Transfer funds bank-to-bank or person to institution.<br>A wire transfer can be made from one bank account to another bank account.<br>At all times, your private financial details are kept safe between you and your financial institution.</p>`,
          },
        ],
        country: "Country of License",
        regulator: "Regulator",
        beneficiary: "Beneficiary",
        code: "Code",
        reference: "Reference: *Please Specify your TA Number (optional)",
        bankAddress: "Bank Address",
        bank: "Bank",
        switzerland: "Switzerland",
        title: "Withdrawing your Funds",
        textTwo: `<p>We believe that withdrawing funds should be as straightforward as depositing them. Therefore it should be simple and hassle free. Here at TrioMarkets™ we guarantee you a fixed process time. The process time varies depending on the type of account you have, from 24 hours to as little as 6 hours.</p><p>TrioMarkets™  offers a variety of payment options based on the deposit method used to fund your account. The initial deposit payment method must be used to withdraw the funds. If the withdrawal amount exceeds the initial deposit amount, the difference will be transferred via bank wire.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™ charges a fee of 1% of the withdraw amount for Visa / Mastercard / Neteller"
                : "TrioMarkets™ charges a fee of 3% of the withdraw amount for Visa / Mastercard / Neteller / Skrill.",
          },
          {
            img: "2",
            text: "Funds will only be transferred into an account that is registered and in the same name of the TrioMarkets™ account holder.",
          },
          {
            img: "3",
            text: "Wire Transfer Fee is 1.5% with a minimum charge of 25.00 and maximum 50.00 (Base Currency).",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Deposits",
        withdrawals: "Withdrawals",
        fundingWithRow: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Minimum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
          [
            { name: "<strong>Online Payment Solutions</strong>" },
            { name: "Minimum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Wire Transfers</strong>" },
            { name: "Minimum Amount" },
            { name: "Maximum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
          [
            { name: "<strong>Online Payment Solutions</strong>" },
            { name: "Minimum Amount" },
            { name: "Maximum Amount" },
            { name: "Base Currency" },
            { name: "Fees" },
            { name: "Processing Time" },
          ],
        ],
        fundingWithTable: [
          "* Per transaction",
          "** Transfer conditions for account closures can be negotiated with the account manager.",
        ],
      },
    ],
    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Unlimited",
          currency: "USD, EUR, GBP, CHF",
          fees: "Free",
          time: "1-3 Working Days",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Free",
          time: "Instant",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 per day, per card",
          currency: "USD, EUR",
          fees: "Free",
          time: "Instant",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Free",
          time: "Instant",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Free",
          time: "Instant",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Unlimited",
        //   currency: "USD, EUR",
        //   fees: `Network Fees<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Instant",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "Wire Transfer Fee is 1.5% with a minimum charge of 25.00 and maximum 50.00 (Base Currency). ",
          time: "1-3 Working Days",
        },
        // {
        //   img: ["sepa"],
        //   min: "50",
        //   currency: "USD, EUR, GBP, CHF",
        //   fees: "Wire Transfer Fee is 1.5% with a minimum charge of 25.00 and maximum 50.00 (Base Currency).<br> For SEPA Transfer only, withdrawal request below 100€ will be charged a fixed fee of 5€.**",
        //   time: "1-3 Working Days",
        // },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% of the withdraw amount",
          time: "1 Working Day",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% of the withdraw amount`,
          time: "1 Working Day",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "3% of the withdraw amount",
          time: "1 Working Day",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% of the withdraw amount`,
          time: "1 Working Day",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Network Fees</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Withdrawal fees</strong><br>1.5% of the withdraw amount`,
        //   time: "1 Working Day",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
  arb: {
    accountsOverviewInfo: [
      {
        text: "هنا في TrioMarkets ™ سواء كنت مبتدئًا في التداول أو كانت لديك رحلة طويلة معه ، ستحصل على حساب مناسب لجميع احتياجات التداول الخاصة بك.",
        textTwo: "تم تصميم حساباتنا بعناية لتناسب جميع أنواع المتداولين.",
        zeroCom: "حسابات بدون عمولة",
        // standard
        basic: "اساسي",
        // advanced
        standard: "متقدم",
      },
    ],
    hniInfo: [
      {
        title: "التداول المؤسسي",
        text: "تحظى حسابات الشركات بتقدير كبير في TrioMarkets™",
        textTwo: `تم بناء TrioMarkets™ على أحدث التقنيات لتلبية احتياجات العملاء الأكثر تطلبًا. في حالة وجود أموال الشركات ، لا يوجد مجال للخطأ. <br> نقدم لك الاحترافية والجودة التي تتوقعها وتقدمها من كيانك الخاص. يمكنك استخدام تداول الفوركس والعقود مقابل الفروقات ، إما كعنصر آخر في إستراتيجية الاستثمار الخاصة بك أو للتحوط من تعرضك.`,
        points: [
          { text: "مدراء الاستثمار" },
          { text: "صناديق التحوط" },
          { text: "الكيانات الفردية للشركات" },
        ],
        titleTwo: "الأفراد من أصحاب الثروات العالية",
        textTree: `<p class="text-center mb-0">إذا كنت تندرج في هذه الفئة وتعتقد أنه يحق لك الحصول على أفضل الخدمات والدعم المتاح ، فإن TrioMarkets ™ هي المكان المناسب لك. نتأكد من أن عملاء HNI يتلقون أفضل خدمة ممكنة بأقل قدر من الجهد من جانبهم. سنبذل قصارى جهدنا للتحدث معك شخصيًا وجهاً لوجه وفهم احتياجات السوق الخاصة بك من أجل إنشاء أفضل حل سواء في التداول الذاتي أو إدارة الأموال.</p> <br> <p class="text-center">سنحرص على التحدث إليك شخصيًا ، وجهاً لوجه ، وفهم احتياجاتك في السوق لإنشاء أفضل حل سواء في التداول الذاتي أو إدارة الأموال.</p>`,
        pointsTwo: [
          { text: "تواصل الموظفين مع كبير محللي السوق لدينا" },
          { text: "مقدمة لمديرينا التنفيذيين" },
          { text: "أعلى مستوى من التكتم" },
          { text: "حساب منفصل خاص" },
          { text: "السحب الآلي" },
          { text: "الأموال المودعة في سويسرا" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `Following the European Securities and Markets Authority (ESMA) new protection measures on contracts for difference (CFDs) to retail traders, whereby among others, consist of restrictions on distribution, leverage limits and margin close out rules, professional traders are treated differently. By requesting to become a Professional Client, you are agreeing to waive some of the protections that would normally be afforded to you as a Retail Client.`,
        textOne: `However, you reserve the right to request non-professional treatment at any time and TrioMarkets™ shall agree to offer you a higher level of protection. You are advised to revert back to retail client status, if at any point you believe that you are unable to properly assess or manage the risks involved with trading. Special terms and conditions apply regarding Portfolio Management.`,
        unwaived: "Unwaived",
        points: [
          {
            img: "best-execition",
            name: "Best Execution",
            text: "We will continue to take all reasonable steps to ensure the best order execution for our clients while remaining fair, honest, and professional.",
          },
          {
            img: "pro-status",
            name: "Pro Status Reversion",
            text: "You retain the right to change your client status back to non-professional in order to receive a higher level of protection, such as negative balance protection and coverage by the Investor Compensation Fund (ICF) of up to Euro 20K.",
          },
          {
            img: "account-seg",
            name: "Account Segregation",
            text: "To safeguard our clients' funds, we request that our Bank keeps our clients' accounts separate from other accounts.",
          },
        ],
        waived: "Waived",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Leverage Restriction",
            text: "Leverage and margin restrictions will not apply to you if you can demonstrate relevant knowledge and experience, as well as your understanding of the risks associated with trading complex financial products.",
          },
          {
            img: "balance-protection",
            name: "Negative Balance Protection",
            text: "Professional clients do not have rights to negative balance protection. If your trading balance falls below zero, you will be required to make additional payments, resulting in losses greater than your deposits.",
          },
          {
            img: "compensation-fund",
            name: "Investor Compensation Fund",
            text: "You are no longer covered by the Investor Compensation Fund (ICF). Any application to secure claims arising from covered services will be denied.",
          },
          {
            img: "risk-warnings",
            name: "Risk Warnings",
            text: "We are not required to provide you with the risk warnings that are normally provided to retail clients because you will demonstrate your knowledge and experience, as well as your ability to make informed decisions while bearing your risk.",
          },
        ],
        title: "Eligibility Criteria to Become a Professional Trader",
        textTwo:
          "To qualify for an Elective Professional Client Status you are required to satisfy at least two of the following minimum requirements applied to CFDs:",
        pointsTree: [
          "Investment portfolio, including cash deposits and financial instruments that exceeds 500,000 EUR in value.",
          "1 + year(s) of working experience in the financial sector in a professional role requiring knowledge of trading leveraged products.",
          "Trading volumes of transactions of leveraged products of significant size equal or greater to 10 trades per quarter over the past four quarters, or, 40 trades per year. The volume will be counted towards that goal in a combined forma, concluded from trading in any forex broker.",
        ],
        textThree:
          "* Significant size: Minimum notional value of at least 100,000 Euros for FX majors, 50,000 for FX minors, major indexes and gold, 10,000 Euros on minor indexes, other commodities and equities.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "يمكنك تمويل حسابك باستخدام محفظة TrioMarkets ™. تعمل محفظة TrioMarkets™ Wallet على تسهيل تمويل وسحب الأموال. يمكنك الآن تحويل الأموال بين محفظة TrioMarkets ™ وحسابات التداول الخاصة بك وقتما تشاء.",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "ليتوانيا",
            regulator: "بنك ليتوانيا",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "المملكة المتحدة",
            regulator: "Financial Conduct Authority (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "المملكة المتحدة",
            regulator: "Financial Conduct Authority (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "إستونيا",
          //   regulator: "وحدة الاستخبارات المالية الإستونية",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">كما اننا نقبل التحويل المصرفي</p>
          <p class="mb-0 fs-6">تحويل الأموال من بنك إلى بنك أو شخص إلى المؤسسة.<br>يمكن إجراء التحويل البنكي من حساب مصرفي إلى حساب مصرفي آخر.<br>في جميع الأوقات ، يتم الاحتفاظ ببياناتك المالية الخاصة بأمان بينك وبين مؤسستك المالية.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">كما اننا نقبل التحويل المصرفي</p>
            <p class="mb-0 fs-6">تحويل الأموال من بنك إلى بنك أو شخص إلى المؤسسة.<br>يمكن إجراء التحويل البنكي من حساب مصرفي إلى حساب مصرفي آخر.<br>في جميع الأوقات ، يتم الاحتفاظ ببياناتك المالية الخاصة بأمان بينك وبين مؤسستك المالية.</p>`,
          },
        ],
        country: "بلد الترخيص",
        regulator: "المنظم",
        beneficiary: "المستفيد",
        code: "الرمز",
        reference: "المرجع: * يرجى تحديد رقم TA الخاص بك (اختياري)",
        bankAddress: "عنوان البنك",
        bank: "البنك",
        switzerland: "سويسرا",
        title: "سحب أموالك",
        textTwo: `<p>نعتقد أن سحب الأموال يجب أن يكون مباشرًا مثل إيداعها. لذلك يجب أن تكون بسيطة وخالية من المتاعب. هنا في TrioMarkets™ نضمن لك وقت معالجة ثابتًا. يختلف وقت المعالجة حسب نوع الحساب لديك ، من 24 ساعة إلى أقل من 6 ساعات.</p><p>تقدم TrioMarkets™ مجموعة متنوعة من خيارات الدفع بناءً على طريقة الإيداع المستخدمة لتمويل حسابك. يجب استخدام طريقة دفع الإيداع الأولي لسحب الأموال. إذا تجاوز مبلغ السحب مبلغ الإيداع الأولي ، فسيتم تحويل الفرق عبر التحويل المصرفي.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "تتقاضى TrioMarkets ™ رسومًا بنسبة 3٪ من مبلغ السحب مقابل Visa / Mastercard / Neteller"
                : "TrioMarkets™ charges a fee of 3% of the withdraw amount for Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "سيتم تحويل الأموال فقط إلى حساب مسجل وبنفس اسم صاحب حساب ™ TrioMarkets.",
          },
          {
            img: "3",
            // text: "رسوم التحويل البنكي هي 1.5٪ بحد أدنى 25.00 و 50.00 كحد أقصى (العملة الأساسية). سيتم فرض رسوم ثابتة على طلب السحب تحت 100،00 (العملة الأساسية).",
            text: "رسوم التحويل البنكي هي 1.5٪ بحد أدنى 25.00 و 50.00 كحد أقصى (العملة الأساسية). سيتم فرض",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "الودائع",
        withdrawals: "عمليات السحب",
        fundingWithRow: [
          [
            { name: "<strong>التحويلات البنكية</strong>" },
            { name: "الحد الأدنى للمبلغ" },
            { name: "العملة الأساسية" },
            { name: "الرسوم" },
            { name: "وقت المعالجة" },
          ],
          [
            { name: "<strong>حلول الدفع عبر الإنترنت</strong>" },
            { name: "الحد الأدنى للمبلغ" },
            { name: "العملة الأساسية" },
            { name: "الرسوم" },
            { name: "وقت المعالجة" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>التحويلات البنكية</strong>" },
            { name: "الحد الأدنى للمبلغ" },
            { name: "الحد الأقصى للمبلغ" },
            { name: "العملة الأساسية" },
            { name: "الرسوم" },
            { name: "وقت المعالجة" },
          ],
          [
            { name: "<strong>حلول الدفع عبر الإنترنت</strong>" },
            { name: "الحد الأدنى للمبلغ" },
            { name: "الحد الأقصى للمبلغ" },
            { name: "العملة الأساسية" },
            { name: "الرسوم" },
            { name: "وقت المعالجة" },
          ],
        ],
        fundingWithTable: [
          "* لكل معاملة",
          "** يمكن التفاوض على شروط التحويل لإغلاق الحساب مع مدير الحساب.",
        ],
      },
    ],
    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "غير محدود",
          currency: "USD, EUR, GBP, CHF",
          fees: "مجانا",
          time: "1-3 أيام عمل",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "مجانا",
          time: "فوري",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 يوميا لكل بطاقة",
          currency: "USD, EUR",
          fees: "مجانا",
          time: "فوري",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "مجانا",
          time: "فوري",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "مجانا",
          time: "فوري",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "غير محدود",
        //   currency: "USD, EUR",
        //   fees: `رسوم الشبكة<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "فوري",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "رسوم التحويل البنكي هي 1.5٪ بحد أدنى 25.00 و 50.00 كحد أقصى (العملة الأساسية).",
          time: "1-3 أيام عمل",
        },
        // {
        //   img: ["sepa"],
        //   min: "50",
        //   currency: "USD, EUR, GBP, CHF",
        //   fees: "رسوم التحويل البنكي هي 1.5٪ بحد أدنى 25.00 وحد أقصى 50.00 (العملة الأساسية). <br> لتحويل SEPA فقط ، سيتم فرض رسوم ثابتة على طلب السحب أقل من 100 يورو بقيمة 5 يورو. **",
        //   time: "1-3 أيام عمل",
        // },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1٪ من المبلغ المسحوب",
          time: "يوم عمل واحد",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `${license == "global" ? "1" : "3"}٪ من المبلغ المسحوب`,
          time: "يوم عمل واحد",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "3٪ من المبلغ المسحوب",
          time: "يوم عمل واحد",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `${license == "global" ? "1" : "3"}٪ من المبلغ المسحوب`,
          time: "يوم عمل واحد",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>رسوم الشبكة</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>رسوم السحب</strong><br>1.5٪ من المبلغ المسحوب`,
        //   time: "يوم عمل واحد",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "حساب إسلامي خالي من الفوائد",
        text: "حل الحساب للمتداولين الذين لا يريدون أن يتم تحميلهم أو إضافة فوائد التبييت إلى الحساب الجاري. مصمم خصيصًا للتجار المسلمين ، يسمح لك هذا الحساب بالتداول وفقًا لمعتقداتك الدينية.",
        noCharge: "لا توجد رسوم إدارية لأول 6 أيام من التداولات المفتوحة",
        tradingSwapp:
          "لا يتحمل التداول على حساب بدون فوائد التبييت أي رسوم إدارية للأيام الستة الأولى التي تكون فيها الصفقة مفتوحة. ومع ذلك ، بدءًا من اليوم السابع ، يتم تطبيق رسوم إدارية يومية على كل يوم تظل فيه التجارة مفتوحة ، بما في ذلك عطلات نهاية الأسبوع.",
        admn: "يتم احتساب الرسوم الإدارية لكل لوت يتم تداوله وتختلف حسب الأداة. يمكن العثور على الرسوم في الرسم البياني أدناه.",
        table: "جدول الرسوم الإدارية",
        symbols: "رموز",
        charge: "المسؤول  (EUR / USD)",
        items: [
          { s: "فوريكس *", num: "15" },
          { s: "العملات المشفرة", num: "35" },
          { s: "المؤشرات", num: "35" },
          { s: "المعادن", num: "35" },
          { s: "الأسهم", num: "35" },
          { s: "الطاقات", num: "35" },
        ],
        exotic: ". العملات النادرة لن تكون متاحة للتداول في أي مجموعة إسلامية*",
        offer:
          "تقدم تريوماركيتس حسابات خالية من الفوائد للبلدان التالية لمدة 7 أيام:",
        countries:
          "أذربيجان ، بنغلاديش ، بوركينا فاسو ، البحرين ، بروناي ، بروناي دار السلام ، الجزائر ، مصر ، غينيا ، إندونيسيا ، العراق ، الأردن ، قيرغيزستان ، الكويت ، لبنان ، ليبيا ، المغرب ، موريتانيا ، جزر المالديف ، ماليزيا ، النيجر ، عمان ، باكستان ، قطر ، المملكة العربية السعودية ، سييرا ليونا ، تشاد ، طاجيكستان ، تركمانستان ، تونس ، تركيا ، الإمارات العربية المتحدة ، أوزبكستان.",
        clients:
          "سيكون لجميع العملاء المقيمين خارج البلدان المذكورة أعلاه إمكانية التقدم للحصول على حساب إسلامي مجاني مبادلة ليوم واحد.",
      },
    ],
  },
  my: {
    accountsOverviewInfo: [
      {
        text: "Di sini di TrioMarkets ™ sama ada anda baru dalam perdagangan atau anda mempunyai perjalanan panjang dengannya, anda akan mendapat akaun yang sesuai untuk semua keperluan perdagangan anda.",
        textTwo:
          "Akaun kami telah direka dengan teliti untuk memenuhi semua jenis peniaga.",
        zeroCom: "Akaun Suruhanjaya Sifar",
        basic: "Standard",
        standard: "Cent Trio",
      },
    ],
    hniInfo: [
      {
        title: "Perdagangan Institusi",
        text: "Akaun Korporat sangat tinggi dinilai dalam TrioMarket™.",
        textTwo: `TrioMarkets ™ dibina di teknologi canggih untuk memenuhi keperluan pelanggan yang paling menuntut. Dalam situasi yang melibatkan dana korporat, tidak ada ruang untuk kesalahan.  <br> Kami memberi anda profesionalisme dan kualiti yang anda harapkan dan berikan dari entiti anda sendiri. Anda mungkin menggunakan perdagangan Forex dan CFD, sama ada sebagai elemen lain dalam strategi pelaburan anda atau untuk melindungi daripada pendedahan anda.`,
        points: [
          { text: "Pengurus Pelaburan" },
          { text: "Dana Lindung Nilai" },
          { text: "Entiti Korporat Individu" },
        ],
        titleTwo: "Individu Bernilai Tinggi",
        textTree: `<p class="text-center mb-0">Sekiranya anda tergolong dalam kategori ini dan yakin anda berhak mendapat perkhidmatan dan sokongan terbaik yang ada, TrioMarkets ™ adalah tempat yang sesuai. Kami memastikan bahawa pelanggan HNI kami menerima perkhidmatan terbaik dengan sedikit usaha dari pihak mereka. Kami akan berusaha sedaya upaya untuk berbicara dengan anda secara peribadi, bersemuka, dan memahami keperluan pasaran anda untuk mewujudkan penyelesaian terbaik dalam perdagangan diri atau pengurusan dana.</p> <br> <p class="text-center">Kami akan memastikan untuk bercakap dengan anda secara peribadi, bersemuka dan memahami keperluan anda di pasaran untuk mencipta penyelesaian terbaik dalam perdagangan diri atau pengurusan dana.</p>`,
        pointsTwo: [
          { text: "Hubungan Personel dengan kami Ketua Penganalisis Pasaran" },
          { text: "Pengenalan kepada Eksekutif kami Pengarah" },
          { text: "Tahap Kebijaksanaan Tertinggi" },
          { text: "Akaun Terpisah Peribadi" },
          { text: "Pengeluaran Automatik" },
          { text: "Dana Dipegang di Switzerland" },
        ],
      },
    ],
    proTradersInfo: [
      {
        text: `Mengikuti orang Eropah Pihak Berkuasa Sekuriti dan Pasaran ( ESMA ) langkah perlindungan baru terhadap kontrak untuk perbezaan ( CFD ) kepada peniaga runcit, di mana antara lain, terdiri daripada sekatan pengedaran, had leverage dan peraturan margin close, peniaga profesional dilayan secara berbeza. Dengan meminta untuk menjadi Pelanggan Profesional, anda setuju untuk mengetepikan beberapa perlindungan yang biasanya diberikan kepada anda sebagai Pelanggan Runcit.`,
        textOne: `Namun, anda menempah hak untuk meminta rawatan bukan profesional pada bila-bila masa dan TrioMarkets ™ akan bersetuju untuk menawarkan tahap perlindungan yang lebih tinggi kepada anda. Anda dinasihatkan untuk kembali ke status pelanggan runcit, jika pada bila-bila masa anda percaya bahawa anda tidak dapat menilai atau mengurus risiko yang terlibat dengan perdagangan dengan betul. Terma dan syarat khas berlaku mengenai Pengurusan Portofolio.`,
        unwaived: "Tidak dibebaskan",
        points: [
          {
            img: "best-execition",
            name: "Pelaksanaan Terbaik",
            text: "Kami akan terus mengambil semua langkah yang wajar untuk memastikan pelaksanaan pesanan terbaik untuk pelanggan kami sambil tetap adil, jujur, dan profesional.",
          },
          {
            img: "pro-status",
            name: "Penukaran Status Pro",
            text: "Anda mempertahankan hak untuk berubah status pelanggan anda kembali kepada bukan profesional untuk mendapat tahap perlindungan yang lebih tinggi, seperti perlindungan dan perlindungan keseimbangan negatif oleh Dana Pampasan Pelabur ( ICF ) hingga Euro 20K.",
          },
          {
            img: "account-seg",
            name: "Pengasingan Akaun",
            text: "Untuk melindungi pelanggan kami' dana, kami meminta agar Bank kami menyimpan akaun pelanggan kami dari akaun lain.",
          },
        ],
        waived: "Dihapus",
        pointsTwo: [
          {
            img: "leverage-restriction",
            name: "Sekatan Leverage",
            text: "Leverage dan margin sekatan tidak akan berlaku untuk anda jika anda dapat menunjukkan pengetahuan dan pengalaman yang relevan, serta pemahaman anda mengenai risiko yang berkaitan dengan perdagangan produk kewangan yang kompleks.",
          },
          {
            img: "balance-protection",
            name: "Perlindungan Baki Negatif",
            text: "Pelanggan profesional tidak mempunyai hak untuk perlindungan keseimbangan negatif. Sekiranya baki perdagangan anda jatuh di bawah sifar, anda akan diminta untuk membuat pembayaran tambahan, mengakibatkan kerugian lebih besar daripada deposit anda.",
          },
          {
            img: "compensation-fund",
            name: "Dana Pampasan Pelabur",
            text: "Anda tidak lagi dilindungi oleh Dana Pampasan Pelabur ( ICF ). Segala permohonan untuk mendapatkan tuntutan yang timbul dari perkhidmatan yang dilindungi akan ditolak.",
          },
          {
            img: "risk-warnings",
            name: "Amaran Risiko",
            text: "Kami tidak diminta untuk menyediakan anda dengan amaran risiko yang biasanya diberikan kepada pelanggan runcit kerana anda akan menunjukkan pengetahuan dan pengalaman anda, serta kemampuan anda untuk membuat keputusan yang tepat sambil menanggung risiko anda.",
          },
        ],
        title: "Kriteria Kelayakan Menjadi Pedagang Profesional",
        textTwo:
          "Untuk memenuhi syarat untuk Status Pelanggan Profesional Elektif, anda diminta untuk memenuhi sekurang-kurangnya dua syarat minimum berikut yang diterapkan pada CFD:",
        pointsTree: [
          "Portfolio pelaburan, termasuk deposit tunai dan instrumen kewangan yang melebihi nilai 500,000 EUR.",
          "1 + tahun ( s ) pengalaman kerja di sektor kewangan dalam peranan profesional yang memerlukan pengetahuan mengenai perdagangan produk leverage.",
          "Jumlah dagangan urus niaga produk leverage dengan ukuran yang signifikan sama atau lebih besar daripada 10 perdagangan per suku selama empat suku terakhir, atau, 40 perdagangan per tahun. Jumlah dagangan akan dikira untuk mencapai tujuan tersebut dalam bentuk gabungan, yang disimpulkan dari perdagangan di mana-mana broker forex.",
        ],
        textThree:
          "* Saiz yang ketara: Nilai notional minimum sekurang-kurangnya 100,000 Euro untuk jurusan FX, 50,000 untuk kanak-kanak bawah umur FX, indeks utama dan emas, 10,000 Euro pada indeks kecil, komoditi dan ekuiti lain.",
      },
    ],
    fundingWithdrawalsInfo: [
      {
        text: "Anda boleh membiayai akaun anda menggunakan Dompet TrioMarkets ™. Dompet TrioMarkets ™ menjadikannya lebih mudah untuk membiayai dan mengeluarkan dana. Anda kini boleh memindahkan wang antara Dompet TrioMarkets ™ dan akaun perdagangan anda bila-bila masa yang anda mahukan.",
        cardsEU: [
          {
            img: ["visa", "master-card"],
            psp: "E-Comprocessing",
            country: "Lithuania",
            regulator: "Bank Lithuania",
          },
          {
            img: ["skrill"],
            psp: "Skrill Limited",
            country: "Inggeris",
            regulator: "Lembaga Kelakuan Kewangan (FCA)",
          },
          {
            img: ["neteller"],
            psp: "Neteller",
            country: "Inggeris",
            regulator: "Lembaga Kelakuan Kewangan (FCA)",
          },
          // {
          //   img: ["xrp", "ethereum", "bitcoin"],
          //   psp: "LetKnow",
          //   country: "Estonia",
          //   regulator: "Unit Perisikan Kewangan Estonia",
          // },
          {
            img: ["wire-transfer"],
            regulator: `<p class="text-primary mb-0 fs-6">Kami juga menerima pemindahan wayar</p>
          <p class="mb-0 fs-6">Pindahkan dana bank ke bank 
          atau orang ke institusi.<br>Pemindahan wang boleh dilakukan dari satu akaun bank ke akaun bank yang lain.<br>Pada setiap masa, peribadi anda maklumat kewangan disimpan dengan selamat antara anda dan institusi kewangan anda.</p>`,
          },
        ],
        cardsGlobal: [
          { img: ["visa", "master-card", "neteller"] },
          // { img: ["xrp", "ethereum", "bitcoin"] },
          {
            img: ["wire-transfer"],
            text: `<p class="text-primary mb-0 fs-6">Kami juga menerima pemindahan wayar</p>
          <p class="mb-0 fs-6">Pindahkan dana bank ke bank 
          atau orang ke institusi.<br>Pemindahan wang boleh dilakukan dari satu akaun bank ke akaun bank yang lain.<br>Pada setiap masa, peribadi anda maklumat kewangan disimpan dengan selamat antara anda dan institusi kewangan anda.</p>`,
          },
        ],
        country: "Negara Lesen",
        regulator: "Pengatur",
        beneficiary: "Waris",
        code: "Kod",
        reference: "Rujukan: *Sila Nyatakan Nombor TA anda (pilihan)",
        bankAddress: "Alamat Bank",
        bank: "Bank",
        switzerland: "Switzerland",
        title: "Mengeluarkan dana anda",
        textTwo: `<p>Kami percaya bahawa menarik diri dana harus semudah mendepositkannya. Oleh itu, ia harus sederhana dan bebas dari masalah. Di sini di TrioMarkets ™ kami menjamin masa proses yang tetap. Masa proses berbeza-beza bergantung pada jenis akaun yang anda miliki, dari 24 jam hingga hanya 6 jam.</p><p>TrioMarkets™ menawarkan pelbagai pilihan pembayaran berdasarkan kaedah deposit yang digunakan untuk membiayai akaun anda. Kaedah pembayaran deposit awal mesti digunakan untuk mengeluarkan dana. Sekiranya jumlah pengeluaran melebihi jumlah deposit awal, perbezaan akan dipindahkan melalui bank wire.</p>`,
        points: [
          {
            img: "1",
            text:
              license == "global"
                ? "TrioMarkets™ mengenakan bayaran sebanyak 3% dari jumlah pengeluaran untuk Visa / Mastercard / Neteller"
                : "TrioMarkets™ mengenakan bayaran sebanyak 3% dari jumlah pengeluaran untuk Visa / Mastercard / Neteller / Skrill",
          },
          {
            img: "2",
            text: "Dana hanya akan dipindahkan ke dalam akaun yang didaftarkan dan dengan nama yang sama pemegang akaun TrioMarkets ™.",
          },
          {
            img: "3",
            text: "Yuran Pemindahan Wire adalah 1.5% dengan caj minimum 25.00 dan maksimum 50.00 (Mata Wang Asas).",
          },
          // {
          //   img: "4",
          //   text: `BTC - 0.0005 <br>
          //   ETH - 0.006<br>
          //   XRP - 0.02<br>
          //   BCH, LTC - 0.001<br>
          //   USDT (ERC20) - 10<br>
          //   USDT (TRC20) - 2.5`,
          // },
        ],
        deposits: "Deposit",
        withdrawals: "Pengeluaran",
        fundingWithRow: [
          [
            { name: "<strong>Pemindahan Kawat</strong>" },
            { name: "Jumlah minimum" },
            { name: "Mata Wang Asas" },
            { name: "Yuran" },
            { name: "Masa Proses" },
          ],
          [
            { name: "<strong>Penyelesaian Pembayaran Dalam Talian</strong>" },
            { name: "Jumlah minimum" },
            { name: "Mata Wang Asas" },
            { name: "Yuran" },
            { name: "Masa Proses" },
          ],
        ],
        fundingWithRowBenorDeposits: [
          [
            { name: "<strong>Pemindahan Kawat</strong>" },
            { name: "Jumlah minimum" },
            { name: "Jumlah maksimum" },
            { name: "Mata Wang Asas" },
            { name: "Yuran" },
            { name: "Masa Proses" },
          ],
          [
            { name: "<strong>Penyelesaian Pembayaran Dalam Talian</strong>" },
            { name: "Jumlah minimum" },
            { name: "Jumlah maksimum" },
            { name: "Mata Wang Asas" },
            { name: "Yuran" },
            { name: "Masa Proses" },
          ],
        ],
        fundingWithTable: [
          "* Setiap transaksi",
          "** Syarat pemindahan untuk penutupan akaun dapat dirundingkan dengan pengurus akaun.",
        ],
      },
    ],
    funWithTableContentDeposits: [
      [
        {
          img: ["wire"],
          min: "100",
          max: "Tidak terhad",
          currency: "USD, EUR, GBP, CHF",
          fees: "Percuma",
          time: "1-3 Hari Bekerja",
        },
        {
          img: ["asia"],
          min:
            license == "eu"
              ? `*THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`
              : `THB 500 <br> THB QR 300 <br> VND 100,000 <br> VND QR 100,000 <br> PHP 1000 <br> MYR 50`,
          max: "500,000.00 <br>950,000<br>300,000,000.00<br>20,000,000<br>1,000,000<br>50,000.00",
          currency: "VND/VND QR, MYR,<br> THB/ THB QR, PHP",
          fees: "Percuma",
          time: "Sekejap",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "5",
          max: "10.000 per day, per card",
          currency: "USD, EUR",
          fees: "Percuma",
          time: "Sekejap",
        },
        {
          img: ["skrill"],
          min: "5",
          currency: "USD, EUR",
          fees: "Percuma",
          time: "Sekejap",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "5",
          max: "500,000",
          currency: "USD, EUR",
          fees: "Percuma",
          time: "Sekejap",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   max: "Tidak terhad",
        //   currency: "USD, EUR",
        //   fees: `Bayaran Rangkaian<br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5`,
        //   time: "Sekejap",
        // },
      ],
    ],
    funWithTableContentWithdrawals: [
      [
        {
          img: ["wire"],
          min: "100",
          currency: "USD, EUR, GBP, CHF",
          fees: "Bayaran Pindahan Kawat adalah 1.5% dengan caj minimum 25.00 dan maksimum 50.00 (Mata Wang Asas). ",
          time: "1-3 Hari Bekerja",
        },
        // {
        //   img: ["sepa"],
        //   min: "50",
        //   currency: "USD, EUR, GBP, CHF",
        //   fees: "Bayaran Pindahan Kawat adalah 1.5% dengan caj minimum 25.00 dan maksimum 50.00 (Mata Wang Asas). <br> Untuk Pemindahan SEPA sahaja, permintaan pengeluaran di bawah 100 € akan dikenakan bayaran tetap 5€.**",
        //   time: "1-3 Hari Bekerja",
        // },
        {
          img: ["asia"],
          min: `THB 500 - 500,000.00<br>THB QR 300 - 950,000<br>VND 100,000 - 300,000,000.00<br>VND QR 100,000 - 20,000,000<br>PHP 1000 - 1,000,000<br>MYR 50 - 50,000.00`,
          currency: "VND/VND QR, MYR, THB/ THB QR, PHP",
          fees: "1% daripada jumlah pengeluaran",
          time: "1 Hari bekerja",
          name: "asia",
        },
      ],
      [
        {
          img: ["visa", "master"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% daripada jumlah pengeluaran`,
          time: "1 Hari bekerja",
        },
        {
          img: ["skrill"],
          min: "50",
          currency: "USD, EUR",
          fees: "1% daripada jumlah pengeluaran",
          time: "1 Hari bekerja",
          name: "skrill",
        },
        {
          img: ["neteller"],
          min: "50",
          currency: "USD, EUR",
          fees: `1% daripada jumlah pengeluaran`,
          time: "1 Hari bekerja",
        },
        // {
        //   img: ["xrp", "ethereum", "bitcoin"],
        //   min: "50",
        //   currency: "USD, EUR",
        //   fees: `<strong>Bayaran Rangkaian</strong><br>BTC - 0.0005<br>ETH - 0.006<br>XRP - 0.02<br>BCH, LTC - 0.001<br>USDT (ERC20) - 10<br>USDT (TRC20) - 2.5<br><strong>Bayaran pengeluaran</strong><br>1.5% daripada jumlah pengeluaran`,
        //   time: "1 Hari bekerja",
        // },
      ],
    ],
    swappFreeInfo: [
      {
        title: "Islamic Swap Free Account",
        text: "An Account Solution for traders who do not want to be charged or credited overnight rollover interest. Specifically designed for Muslim Traders, this account allows you to trade in accordance with your religious beliefs.",
        noCharge: "No admin charge for first 6 days of open trades",
        tradingSwapp:
          "Trading on a swap-free account incurs no administration charge for the first six days that the trade is open. However, starting on the 7th day, a daily administration fee is applied for each day that the trade remains open, including weekends.",
        admn: "The admin fee is calculated per lot traded and varies by instrument.  The fee can be found in the chart below.",
        table: "Admin Charge Table",
        symbols: "Symbols",
        charge: "Admin Charge (EUR/USD)",
        items: [
          { s: "Forex*", num: "15" },
          { s: "Cryptos", num: "35" },
          { s: "Indices", num: "35" },
          { s: "Metals", num: "35" },
          { s: "Shares", num: "35" },
          { s: "Energies", num: "35" },
        ],
        exotic:
          "*Exotic currencies will not be available for trading in any Islamic groups.",
        offer:
          "TrioMarkets offers swap free accounts to the following countries for a period of 7 days:",
        countries:
          "Azerbaijan, Bangladesh, Burkina Faso, Bahrain, Brunei, Brunei Darussalam, Algeria, Egypt, Guinea, Indonesia, Iraq, Jordan, Kyrgyzstan, Kuwait, Lebanon, Libia, Morocco, Mauritania, Maldives, Malaysia, Niger, Oman, Pakistan, Qatar, Saudi Arabia, Sierra Leona, Chad, Tajikistan, Turkmenistan, Tunisia, Turkey, United Arab Emirates, Uzbekistan.",
        clients:
          "All clients residing outside the above countries will have the possibility to apply for an Islamic Swap Free account for 1 day.",
      },
    ],
  },
};
