let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : "global";
export default {
  en: {
    marketsTop: [
      { desc: "Spread from 0 pips", img: "spread" },
      {
        desc: `Leverage up to ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "High Execution Speed", img: "execution" },
      { desc: "130+ Instruments", img: "instruments" },
    ],
    forexDesc: `Foreign exchange market, often called FX which is the process of buying one currency against the selling of the other. Until very recently, FX trading was only available for large financial institutions, central banks, corporations, the very wealthy and hedge funds. Now, thanks to the internet anyone can trade currencies.`,
    forexPopular: `Popular FX Pairs`,
    forexInfo: [
      {
        moreInfo: "More info about Forex",
        why: "Why Trade Currencies?",
        points: [
          {
            text: "Most traded market in the world with a daily volume of $6,6 trillion as of 2020",
            img: "1",
          },
          { text: "Tight spreads / Lightning fast execution", img: "2" },
          {
            text: "Forex Market presents many trading opportunities in rising and falling market price",
            img: "3",
          },
        ],
        text: "<strong>Forex is known to be the most common form of online trading and at TrioMarkets™, we offer <br> you 60+ of your favorite currency pairs tradable on your customizable MT4 platform.</strong>",
        pointsTwo: [
          {
            title: "Major",
            text: "Major currency pairs are the most liquid pairs to trade which include the US Dollar. Such as the EUR/ USD and the USD/JPY.",
          },
          {
            title: "Minor",
            text: "Minor currency pairs do not have the USD and are known as crosses. Such as the EUR/GBP or the EUR/JPY.",
          },
          {
            title: "Exotic",
            text: "Exotic currency pairs can also be traded with us such as, the USD/NOK. These currency pairs are made up of a major currency pair coupled with the currency of a developing economy.",
          },
        ],
        pointsThree: [
          {
            text: "Trading on FX means that you are buying the difference or spread between the two currencies.",
          },
          {
            text: "Daily volatility for most currencies is usually small. In general, world currencies move less than a percentage point during a trading day. This makes the FX market one of the least volatile markets in the world.",
          },
          {
            text: "With <strong>TrioMarkets™</strong> we ensure that all transactions are fully automated which means your trades are executed efficiently and settled instantly. We call this “fast reliable trading”.",
          },
          {
            text: "100% automated with no re-quotes or rejections unless upper and lower bounds are set to protect against price volatility. No dealing desk or algorithmic software intervention to push trades to the broker’s favor.",
          },
        ],
      },
    ],

    indicesDesc: `An index is a portfolio of top company stocks from a particular country that track the performance of their market or exchange. A stock index is a very important part of our financial world.`,
    indicesPopular: `Popular Indices`,
    indicesInfo: [
      {
        moreInfo: "More info about Indices",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor’s 500 is an American stock market index which is an index of 500 stocks. <br> The S&P 500 was developed and continues to be maintained by S&P Dow Jones. It is designed to be a leading indicator of US equities.`,
          },
          {
            title: `FTSE 100`,
            text: `The Financial Times Stock Exchange 100 Index represents the largest 100 qualifying UK companies traded on the London Stock Exchange with the highest market capitalization.`,
          },
          {
            title: `DOW JONES`,
            text: `The Dow Jones Industrial Average was created to provide a clear view of the stock market and therefore the US economy. <br> It consists of 30 component companies that are manufacturers of industrial and consumer goods.`,
          },
          {
            title: `DAX`,
            text: `One of the most popular indices worldwide, DAX is a German stock index which consists of the 30 main German companies which are trading on the Frankfurt stock exchange. <br> DAX is mainly calculated as a performance index, making the DAX index unique.`,
          },
          {
            title: `NASDAQ`,
            text: `The National Association of Securities Dealers Automated Quotations (known as Nasdaq) is an American stock exchange that is ranked the second largest exchange in the world by market capitalization.`,
          },
          {
            title: `CAC40`,
            text: `CAC 40 Is a benchmark index for the Paris stock exchange as it reflects the performance of the 40 largest and most actively traded shares listed on Euronext Paris. <br> It accounts for 20% of market capitalization in the Eurozone and is the most widely used Index.`,
          },
          {
            title: `EUROPE50`,
            text: `The STOXX Europe 50 is a stock index of European stocks designed by STOXX Ltd., an index provider owned by Deutsche Börse Group and SIX Group. The index was introduced in February 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `The IBEX 35 (IBerian IndEX) is the benchmark stock market index of the Bolsa de Madrid, Spain's principal stock exchange. Initiated in 1992, the index is administered and calculated by Sociedad de Bolsas. It is a market capitalization weighted index comprising the 35 most liquid Spanish stocks traded in the Madrid Stock Exchange General Index and is reviewed twice.`,
          },
        ],
      },
    ],

    metalsDesc: `"A precious metal is deemed precious because it is rare and has a high economic value." Metals are a physical commodity that have always played an important role in history and will continue to be valued. Demand for these metals has increased around the world, driving prices up in recent years`,
    metalsPopular: `Popular Metals`,
    metalsInfo: [
      {
        moreInfo: `More info about Metals`,
        points: [
          {
            title: `Silver`,
            text: `Silver is an extremely volatile metal. <br> Because silver is widely used in jewellery and consumer goods, supply and demand have a significant impact on price movement in this commodity.`,
          },
          {
            title: `Gold`,
            text: `Gold is the most popular commodity traded. <br> Gold has always been a favourite asset among investors during times of market uncertainty and financial crisis.`,
          },
          {
            title: `Palladium`,
            text: `Palladium is recognised as a chemically stable metal. <br> It is one of the most important international commodities and is up to 30 times rarer than gold.`,
          },
          {
            title: `Platinum`,
            text: `Platinum is one of the world's rarest metals. <br> Platinum has very special properties that are in high demand in a variety of industries. This has resulted in unpredictable supply and demand fluctuations.`,
          },
        ],
      },
    ],

    cryptosDesc: `Trade the most popular CFDs in Cryptos today! TrioMarkets™ is a licensed and regulated broker that offers CFDs in the Cryptos trade.`,
    cryptosPopular: `Popular Cryptocurrencies`,
    cryptosInfo: [
      {
        why: `Why <strong>CFDs</strong> on <strong>Cryptos</strong> is a good alternative <br> to physically buying cryptos?`,
        points: [
          {
            img: `shield`,
            text: `No Chance of <br> Cryptocurrency <br> <strong>Wallet Hacks</strong>`,
          },
          {
            img: `cloud`,
            text: `No Need of <br> <strong>Actual Cold Storage</strong> <br> for the Cryptos`,
          },
          {
            img: `portfolio`,
            text: `A <strong>Reliable</strong> and <strong>Regulated</strong> <br> broker pays out profits for its <br> clients`,
          },
          {
            img: `security`,
            text: `Providing <strong>Security</strong> <br> of the clients’ funds. <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> is a member of the <br>Investor Compensation Fund</span>`,
          },
        ],
        moreInfo: `More info about Cryptos`,
        pointsTwo: [
          {
            img: `btc`,
            title: `Bitcoin CFD`,
            text: `The first and largest cryptocurrency, Bitcoin paved the way for all the rest of hundreds of cryptocurrencies with a market cap of over $100 billion.`,
          },
          {
            img: `eth`,
            title: `Ethereum CFD`,
            text: `The undoubted world’s second-largest cryptocurrency, following Bitcoin. A large number of established well-known organizations have actively supported Ethereum few of which are Microsoft, JP Morgan, and Intel.`,
          },
          {
            img: `ltc`,
            title: `Litecoin CFD`,
            text: `Designed by a former Google engineer to improve upon Bitcoin’s technology, based on an open-source cryptographic protocol is ultimately decentralized, offers quicker processing using the SegWit speeding method for transaction times without compromising the underlying blockchain technology.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple is based on a distributed open-source protocol that supports tokens and functions as both a transaction network and a crypto token. The most popular cryptocurrency among banks and a means of global money transfers has experienced a period of growth.`,
          },
        ],
      },
    ],

    energiesDesc: `A commodity is a basic good that is interchangeable with other commodities of the same type. Commodities are often inputs used in production for other goods and services. Another definition for a commodity is any good exchanged for commerce. This includes any product that is traded on a commodity exchange like the CBOE (Chicago Board Options Exchange).`,
    energiesPopular: `Popular Energies`,
    energiesInfo: [
      {
        moreInfo: `More info about Energies`,
        points: [
          {
            title: `Crude Oil`,
            text: `Oil holds a significant position in the global economic and political systems. Because of the high volume of trading in crude oil, traders have more opportunities to enter a trade. Crude oil, like many other assets, is influenced by supply and demand.`,
          },
          {
            title: `Brent`,
            text: `Brent is a sweet, light crude oil derived from the North Sea. It is also known as Brent petroleum and serves as a major benchmark price for purchasing oil around the world.`,
          },
          {
            title: `Natural Gas`,
            text: `Natural gas is one of the world's cleanest and safest energy sources. During the winter, natural gas is in high demand to meet commercial and residential heating needs. It is one of the most appealing globally traded commodities on the market.`,
          },
        ],
      },
    ],

    sharesDesc: `Trade the stock prices of major corporations on the world's leading exchanges. Contracts For Difference (CFDs) are traded on margin which allow traders to multiply their respective returns. Even minor market movements can result in profit or loss. When the markets are trending downward, traders can go short. TrioMarkets ™ currently provides 1:5 leverage on Equity CFDs upon sign-up.`,
    sharesPopular: `Popular Shares`,
    sharesInfo: [
      {
        moreInfo: `More info about Shares`,
        text: `Share prices are largely determined by the forces of supply and demand. A company that exhibits potential long-term earnings may attract more investors to buy their shares, thereby having an increase in share prices. A company with a bearish outlook, on the other hand, may entice more sellers than buyers, which can result in downward pressure on their prices.`,
        textTwo: `Other factors can cause price volatility and cause sudden or brief changes in price. Some examples of this include political events, financial reports and economic news. However, not all news or reports affect all securities. For example, the stocks of companies engaged in the automobile industry, may suffer from a lack of inventory to meet buyers demand and/or a price increase in steel, which will cause an unexpected increase in the production costs. Stock prices can also be driven by what is known as herd behaviour, which is the tendency for people to copycat the action of a larger group.`,
      },
    ],
  },
  fr: {
    marketsTop: [
      { desc: "Spread à partir de 0 pips", img: "spread" },
      {
        desc: `Levier jusqu'à ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "Vitesse d'exécution élevée", img: "execution" },
      { desc: "130+ Produits", img: "instruments" },
    ],
    forexDesc: `Le marché des changes, souvent appelé FX, consiste à acheter une devise contre la vente de l'autre. Jusque très récemment, le trading de devises n'était disponible que pour les grandes institutions financières, les banques centrales, les entreprises et les fonds spéculatifs. Maintenant, grâce à Internet, tout le monde peut trader des devises.`,
    forexPopular: `Paires FX Populaires`,
    forexInfo: [
      {
        moreInfo: "Plus d'info sur le Forex",
        why: "Pourquoi trader sur le Forex?",
        points: [
          {
            text: "Marché le plus négocié au monde avec un volume quotidien de $6,6 billions de dollars en 2020",
            img: "1",
          },
          { text: "Spread serrés / Exécution ultra-rapide", img: "2" },
          {
            text: "Le marché Forex présente de nombreuses opportunités de trading en hausse et en baisse des prix du marché",
            img: "3",
          },
        ],
        text: "<strong>Le Forex est connu pour être la forme la plus courante de trading en ligne. Chez TrioMarkets™, nous <br> vous proposons plus de 60 paires de devises négociables sur notre plateforme MT4.</strong>",
        pointsTwo: [
          {
            title: "Major",
            text: "Les principales paires de devises sont les paires les plus liquides à négocier comprenant le dollar américain; telles que l'EUR / USD et l'USD / JPY.",
          },
          {
            title: "Minor",
            text: "Les paires mineures n'ayant pas l'USD, sont appelées les pairs Cross; telles que l'EUR/GBP ou l'EUR/JPY.",
          },
          {
            title: "Exotic",
            text: "Les paires exotiques peuvent également être échangées avec nous, telles que l'USD/NOK. Ces paires de devises sont constituées d'une paire de devises majeure couplée à la devise d'une économie en développement.",
          },
        ],
        pointsThree: [
          {
            text: "Le trading sur FX signifie que vous achetez la différence ou le spread entre les deux devises.",
          },
          {
            text: "La volatilité quotidienne de la plupart des devises est généralement faible. En général, les devises mondiales évoluent de moins d'un point de pourcentage au cours d'une journée de négociation. Cela fait du marché des changes l'un des marchés les moins volatils au monde.",
          },
          {
            text: "Chez <strong>TrioMarkets™</strong>, nous nous assurons que toutes les transactions sont entièrement automatisées, ce qui signifie que vos transactions sont exécutées efficacement et réglées instantanément. Nous appelons cela «un trading rapide et fiable».",
          },
          {
            text: "100% automatisé sans re-cotation ni rejet, à moins que les limites supérieures et inférieures ne soient fixées pour se protéger contre la volatilité des prix. Aucun bureau de négociation ou intervention logicielle algorithmique pour pousser les transactions en faveur du courtier.",
          },
        ],
      },
    ],
    indicesDesc: `Un indice boursier est un indicateur clé pour déterminer la performance d’un marché. Il est composé des meilleures actions d’un pays donné. Il suit leurs performances en terme de cours et leurs échanges sur le marché. Généralement, un indice s’exprime en point.`,
    indicesPopular: `Indices Populaires`,
    indicesInfo: [
      {
        moreInfo: "Plus d'info sur les Indices",
        points: [
          {
            title: `S&P 500`,
            text: `Le Standard & Poor’s 500 est l’un des plus importants indices américains. Il prend en compte les actions des 500 plus importantes entreprises cotées à la Bourse de New-York. <br> En raison du large panel d’entreprises qui le compose, le S&P500 constitue le principal indice de la performance de l’économie américaine.`,
          },
          {
            title: `FTSE 100`,
            text: `Le Financial Times Stock Exchange 100 est composé des actions des plus grandes sociétés cotées à la Bourse de Londres, les plus fortes capitalisations boursières.`,
          },
          {
            title: `DOW JONES`,
            text: `Créé en 1928, le Dow Jones Industriel Average (DJIA) est le plus ancien des indices américains. <br> Il est composé des 30 principales valeurs cotées sur la place new-yorkaise.`,
          },
          {
            title: `DAX`,
            text: `Le Dax est composé des 30 plus importantes entreprises allemandes cotées à la Bourse de Francfort. C’est un indice très populaire parmi les traders du monde entier. <br> Le DAX est principalement calculé comme un indice de performance, ce qui le rend unique.`,
          },
          {
            title: `NASDAQ`,
            text: `Le Nasdaq représente le second plus important marché d’actions en volume aux Etats-Unis et le plus grand marché électronique d’actions du monde. <br> Le Nasdaq est composé des plus importantes entreprises des secteurs technologiques, d’internet et des télécom.`,
          },
          {
            title: `CAC40`,
            text: `Le CAC 40 est l’indice de référence de la Bourse de Paris. <br> Il est composé des 40 plus grandes sociétés françaises cotées sur Euronext Paris, celles dotées des plus fortes capitalisations boursières.`,
          },
          {
            title: `EUROPE50`,
            text: `Le STOXX Europe 50 est un indice boursier d'actions européennes conçu par STOXX Ltd., un fournisseur d'indices détenu par Deutsche Börse Group et SIX Group. L'indice a été introduit en février 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `L'IBEX 35 (IBerian IndEX) est l'indice boursier de référence de la Bolsa de Madrid, la principale bourse d'Espagne. Lancé en 1992, l'indice est administré et calculé par la Sociedad de Bolsas. Il s'agit d'un indice pondéré par la capitalisation boursière comprenant les 35 actions espagnoles les plus liquides négociées dans l'indice général de la Bourse de Madrid et est révisé deux fois par an.`,
          },
        ],
      },
    ],
    metalsDesc: `Les métaux sont une marchandise physique et ont toujours joué un rôle important dans l'histoire et continueront d'être valorisés. La demande de ces métaux a augmenté dans le monde entier et a donc fait grimper les prix au cours des dernières années.`,
    metalsPopular: `Métaux Populaires`,
    metalsInfo: [
      {
        moreInfo: `Plus d'info sur les Métaux`,
        points: [
          {
            title: `Argent`,
            text: `L’argent est un métal dont l’extraction est en baisse depuis plusieurs années. <br> La demande est soutenue par le secteur de l’industrie, très demandeur de ce métal de plus en plus utilisé dans les processus industriels modernes.`,
          },
          {
            title: `Or`,
            text: `C’est la matière première la plus tradée. <br> L’or est considéré comme une valeur refuge permettant de se prémunir des incertitudes des marchés boursiers.`,
          },
          {
            title: `Palladium`,
            text: `Le palladium est un métal précieux, très rare, hautement stratégique pour l’industrie. <br> La production peine à couvrir la très forte demande du secteur automobile. De ce fait, cet actif est devenu très attractif pour nombre de traders.`,
          },
          {
            title: `Platine`,
            text: `Le platine est l’un des métaux les plus rares au monde. Ses propriétés très spéciales en font un métal très demandé dans l’industrie. <br> Le prix très volatil de cet actif est susceptible de créer grandes opportunités en termes d’investissement.`,
          },
        ],
      },
    ],
    cryptosDesc: `Tradez les Cryptomonnaies les plus demandees via CFD! TrioMarkets™ est un courtier agréé et réglementé qui propose des CFD sur les Cryptomonnaies.`,
    cryptosPopular: `Cryptomonnaies Populaires`,
    cryptosInfo: [
      {
        why: `Pourquoi les <strong>CFDs</strong> sur <strong>Cryptos</strong> sont une bonne alternative <br> à l’achat physique des crypto-devises?`,
        points: [
          {
            img: `shield`,
            text: `Aucune possibilité de <br><strong> Piratage de Votre Portefeuille </strong> <br> de Cryptomonnaies`,
          },
          {
            img: `cloud`,
            text: `Pas besoin de <strong> Stockage à Froid </strong> <br> réel pour les Cryptos`,
          },
          {
            img: `portfolio`,
            text: `Un courtier<strong> Fiable</strong> et<br> <strong>Réglementé</strong> qui reverse les<br> bénéfices à ses clients`,
          },
          {
            img: `security`,
            text: `<strong>Sécurité </strong> des fonds des clients. <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> est membre du fond de <br> Compensation des Investisseurs</span>`,
          },
        ],
        moreInfo: `Plus d'info sur les Cryptos`,
        pointsTwo: [
          {
            img: `btc`,
            title: `Bitcoin CFD`,
            text: `La première et la plus célèbre des cryptomonnaies, le bitcoin, a ouvert la voie à des centaines de crypto-devises, avec une capitalisation boursière de plus de 100 milliards de dollars.`,
          },
          {
            img: `eth`,
            title: `Ethereum CFD`,
            text: `Sans doute la seconde plus grande cryptomonnaie du monde, après le bitcoin. Un grand nombre d’entreprises très connues ont soutenu activement l’Ethereum, dont Microsoft, JP Morgan et Intel.`,
          },
          {
            img: `ltc`,
            title: `Litecoin CFD`,
            text: `Conçu par un ancien ingénieur de Google pour améliorer la technologie du Bitcoin. Basé sur un protocole cryptographique open-source entièrement décentralisé, le Litecoin offre un traitement plus rapide, en utilisant la méthode d’accélération SegWit pour les temps de transaction, sans compromettre la technologie de la blockchain.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple, est construit sur un protocole en open source, qui supporte également les tokens. C’est à la fois un réseau de transactions et une cryptodevise. Cette monnaie cryptographique est la préférée des banques qui l’utilisent pour les transferts d’argent. Elle a connu une forte période de croissance.`,
          },
        ],
      },
    ],
    energiesDesc: `"Une matière première est un produit de base servant à la production ou à la fabrication de produits finis ou manufacturés. Les matières premières sont la base de l’économie mondiale et constituent un enjeu majeur pour tous les pays. Les transactions sur les matières premières se font sur des Bourses qui leurs sont dédiées comme la Bourse de Chicago (CBOE)"`,
    energiesPopular: `Énergies Populaires`,
    energiesInfo: [
      {
        moreInfo: `Plus d'info sur les Énergies`,
        points: [
          {
            title: `Pétrole Brut`,
            text: `Le pétrole occupe une place prépondérante dans les systèmes économiques et politiques mondiaux. Le pétrole brut offre une liquidité élevée et crée donc d’excellentes opportunités de trading. Le pétrole brut, comme beaucoup d’autres actifs, est affecté par l’offre et la demande. La politique commerciales des pays producteurs, et les décisions affectant leur production ont des répercussions directes sur les prix. Le prix du pétrole brut est en dollar américain.`,
          },
          {
            title: `Brent`,
            text: `Le Brent est un pétrole brut léger non corrosif provenant de la mer du Nord. Aussi connu sous le nom de pétrole Brent, il sert un prix de référence majeur pour les achats de pétrole dans le monde entier.`,
          },
          {
            title: `Gaz Naturel`,
            text: `Le gaz naturel est l’une des sources d’énergie les plus propres et les plus sûres au monde. La demande de gaz naturel est la plus forte pendant la saison hivernale pour répondre aux besoins en chauffage domestique et commercial. C’est une matière première très apprécié des traders.`,
          },
        ],
      },
    ],

    sharesDesc: `Négociez les cours des actions des grandes entreprises sur les principales places boursières du monde. Les contrats de différence (CFD) sont négociés sur marge, ce qui permet aux traders de multiplier leurs rendements respectifs. Même des mouvements de marché mineurs peuvent entraîner des profits ou des pertes. Lorsque les marchés ont une tendance à la baisse, les commerçants peuvent prendre une position courte. TrioMarkets ™ offre actuellement un effet de levier de 1:5 sur les CFD sur actions lors de l'inscription.`,
    sharesPopular: `Actions Populaires`,
    sharesInfo: [
      {
        moreInfo: `Plus d'info sur les Actions`,
        text: `Le cours d’une action est déterminé selon la loi de l’offre et de la demande. Une entreprise ayant de bonnes perspectives de revenus à long terme est plus susceptible d’attirer les investisseurs, qui, en achetant ses actions, concourent à faire monter le cours. A l’inverse, dans le cas d’une entreprise en difficulté, les actionnaires sont tentés de vendre les actions, faisant ainsi baisser les cours. Le prix se fixe par la confrontation des ordres d’achat et de vente.`,
        textTwo: `Certains facteurs peuvent peuvent causer une volatilité sur le marché et provoquer des variations soudaines du prix d’une action, et ce, tant à la hausse qu’à la baisse. Il peut s’agir d’un événement politique, économique, ou plus généralement pouvant affecter les finances de l’entreprise : la signature d’un nouveau contrat ou au contraire sa perte sont des évènements pouvant avoir une incidence sur le cours de l’action. Pour autant, toutes les nouvelles du marché n’ont pas forcément d’incidence.Le cours des actions peut également être affecté par un mouvement de masse sur les marché, lorsque de nombreux investisseurs suivent la même position à la hausse ou à la baisse.`,
      },
    ],
  },
  it: {
    marketsTop: [
      { desc: "Spread da 0 pips", img: "spread" },
      {
        desc: `Leva fino a to ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "Alta Velocita' di Esecuzione", img: "execution" },
      { desc: "130+ Strumenti", img: "instruments" },
    ],
    forexDesc: `Mercato dei cambi, spesso chiamato FX, che è il processo di acquisto di una valuta contro la vendita dell'altra. Fino a poco tempo fa, il trading FX era disponibile solo per grandi istituzioni finanziarie, banche centrali, società, i più ricchi e gli hedge fund. Ora, grazie a Internet, chiunque può scambiare valute.`,
    forexPopular: `Coppie FX piu' Popolari`,
    forexInfo: [
      {
        moreInfo: "Piu' Info su Forex",
        why: "Perche' negoziare le Valute?",
        points: [
          {
            text: "Il mercato più scambiato al mondo con un volume giornaliero di $6,6 trilioni a partire dal 2020",
            img: "1",
          },
          { text: "Spread stretti / Esecuzione ultra-rapida", img: "2" },
          {
            text: "Il mercato Forex offre molte opportunità di trading al rialzo e ribasso del prezzo di mercato",
            img: "3",
          },
        ],
        text: "<strong>Il Forex è noto per essere la forma più comune di trading online. Su TrioMarkets™, ti offriamo <br>oltre 60 coppie di valute negoziabili sulla nostra piattaforma MT4.</strong>",
        pointsTwo: [
          {
            title: "Major",
            text: "Le principali coppie di valute sono le coppie più liquide su cui fare trading, incluso il dollaro USA; come EUR / USD e USD / JPY.",
          },
          {
            title: "Minor",
            text: "Le coppie di valute minori non hanno l'USD e sono note come cross. Come l'EUR / GBP o l'EUR / JPY.",
          },
          {
            title: "Exotic",
            text: "Le coppie esotiche possono anche essere scambiate con noi, come USD / NOK. Queste coppie di valute sono costituite da una delle principali coppie di valute accoppiate con la valuta di un'economia in via di sviluppo.",
          },
        ],
        pointsThree: [
          {
            text: "Il trading FX significa che acquisti la differenza o lo spread tra le due valute.",
          },
          {
            text: "La volatilità giornaliera della maggior parte delle valute è generalmente bassa. In generale, le valute globali si muovono meno di un punto percentuale durante una giornata di negoziazione. Questo rende il mercato forex uno dei mercati meno volatili al mondo.",
          },
          {
            text: `In <strong>TrioMarkets™</strong> , ci assicuriamo che tutte le transazioni siano completamente automatizzate, il che significa che le tue operazioni vengono eseguite in modo efficiente e regolate all'istante. Chiamiamo questo "trading veloce e affidabile.`,
          },
          {
            text: "Automatizzato al 100% senza riquotazioni o rifiuto, a meno che i limiti superiore e inferiore non siano impostati per proteggere dalla volatilità dei prezzi. Nessun intervento di trading desk o software algoritmico per spingere gli scambi a favore del broker.",
          },
        ],
      },
    ],
    indicesDesc: `Un indice è un portafoglio di azioni delle migliori società di un determinato paese che traccia la performance del loro mercato o borsa. Un indice azionario è una parte molto importante del nostro mondo finanziario.`,
    indicesPopular: `Metalli piu' Popolari`,
    indicesInfo: [
      {
        moreInfo: "Piu' Info su Indici",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor 500 è un indice del mercato azionario americano, che è un indice di 500 titoli. <br> L’indice S & P 500 è stato sviluppato e continua ad essere mantenuto da S & P Dow Jones. È stato progettato per essere un indicatore importante di titoli azionari degli Stati Uniti.`,
          },
          {
            title: `FTSE 100`,
            text: `L'indice Financial Times Stock Exchange 100 rappresenta le 100 maggiori società britanniche qualificate negoziate alla Borsa di Londra con la più alta capitalizzazione di mercato.`,
          },
          {
            title: `DOW JONES`,
            text: `Il Dow Jones Industrial Average è stato creato per fornire una chiara visione del mercato azionario e quindi l’economia degli Stati Uniti. <br> Esso è costituito da 30 principali aziende  che sono produttori di beni industriali e di consumo.`,
          },
          {
            title: `DAX`,
            text: `Uno degli indici più popolari in tutto il mondo, DAX è un indice azionario tedesco che è costituita dalle 30 principali compagnie tedesche negoziate in Borsa di Francoforte. <br> DAX è calcolato principalmente come indice di performance, rendendo l’indice DAX unico.`,
          },
          {
            title: `NASDAQ`,
            text: `La National Association of Quotations Securities Dealers Automated (noto come Nasdaq) è uno American Stock Exchange che è classificato il secondo più grande scambio in tutto il mondo per capitalizzazione di mercato.`,
          },
          {
            title: `CAC40`,
            text: `CAC 40 è un indice di riferimento per la Borsa di Parigi in quanto riflette la performance delle 40 azioni più grandi e più attivamente negoziate quotate su Euronext Paris. <br> Esso rappresenta il 20% della capitalizzazione di mercato nella zona euro ed è l’indice più utilizzato.`,
          },
          {
            title: `EUROPE50`,
            text: `Lo STOXX Europe 50 è un indice azionario di azioni europee ideato da STOXX Ltd., un fornitore di indici di proprietà del Gruppo Deutsche Börse e del Gruppo SIX. L'indice è stato introdotto nel febbraio 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `L'IBEX 35 (IBerian IndEX) è l'indice di riferimento del mercato azionario della Bolsa de Madrid, la principale borsa valori spagnola. Avviato nel 1992, l'indice è amministrato e calcolato dalla Sociedad de Bolsas. È un indice ponderato per capitalizzazione di mercato che comprende i 35 titoli spagnoli più liquidi quotati nell'indice generale della Borsa di Madrid ed è rivisto due volte l'anno.`,
          },
        ],
      },
    ],
    metalsDesc: `Un metallo prezioso è considerato prezioso perché è raro e ha un alto valore economico. I metalli sono una merce fisica e hanno sempre svolto un ruolo importante nella storia e continueranno ad essere apprezzati. La domanda di questi metalli è aumentata in tutto il mondo e quindi ha fatto salire i prezzi negli ultimi anni.`,
    metalsPopular: `Métaux Populaires`,
    metalsInfo: [
      {
        moreInfo: `Maggiori informazioni sui Metalli`,
        points: [
          {
            title: `Argento`,
            text: `L'argento è un metallo altamente volatile. <br> L'argento e' di uso comune nei gioielli e prodotti di consumo, domanda e offerta influenzano pesantemente i movimenti in questa materia prima.`,
          },
          {
            title: `Oro`,
            text: `L'oro è il bene scambiato più famoso. <br> E 'considerato come un rifugio sicuro durante l'incertezza economica, politica o sociale.`,
          },
          {
            title: `Palladium`,
            text: `Palladio è considerato un metallo chimicamente stabile. <br> Si tratta di una delle principali materie prime scambiate a livello internazionale ed è noto per essere fino a 30 volte più raro dell'oro.`,
          },
          {
            title: `Platinum`,
            text: `Il platino è uno dei metalli più rari al mondo. <br> Il platino è costituito da proprietà molto speciali che sono molto richieste in vari settori. Ciò ha portato a fluttuazioni selvagge nella domanda e nell'offerta.`,
          },
        ],
      },
    ],
    cryptosDesc: `Fai trading sulle criptovalute più richieste tramite CFD! TrioMarkets™ è un broker autorizzato e regolamentato che offre CFD su Criptovalute.`,
    cryptosPopular: `Cryptovalute piu' Popolari`,
    cryptosInfo: [
      {
        why: `Perché i <strong>CFDs</strong> sulle <strong>Cryptos</strong> sono una buona alternativa <br> all’acquisto fisico delle monete?`,
        points: [
          {
            img: `shield`,
            text: `Nessuna possibilità di <br> <strong> Attacchi al Portafoglio </strong> <br> di criptovaluta`,
          },
          {
            img: `cloud`,
            text: `Non c'è bisogno di una <br> vera <strong> Conservazione a Freddo </strong> <br> per Cryptos`,
          },
          {
            img: `portfolio`,
            text: `Un broker <strong> Affidabile </strong> e <br> <strong> Regolamentato </strong> paga i <br> profitti ai suoi clienti`,
          },
          {
            img: `security`,
            text: `<strong>Sicurezza</strong> dei fondi dei clienti. <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> è membro del Fondo di <br> compensazione degli investitori</span>`,
          },
        ],
        moreInfo: `Piu' Info su Crypto`,
        pointsTwo: [
          {
            img: `btc`,
            title: `Bitcoin CFD`,
            text: `La prima e più grande criptovaluta. Bitcoin ha spianato la strada a tutto il resto di centinaia di criptovalute con una capitalizzazione di mercato di oltre $100 miliardi.`,
          },
          {
            img: `eth`,
            title: `Ethereum CFD`,
            text: `Indubbiamente la seconda criptovaluta più grande del mondo dopo il Bitcoin. Un gran numero di organizzazioni ben note ha attivamente supportato l’Ethereum, alcune delle quali sono Microsoft, JP Morgan e Intel.`,
          },
          {
            img: `ltc`,
            title: `Litecoin CFD`,
            text: `Progettato da un ex ingegnere di Google per migliorare la tecnologia Bitcoin, basato su un protocollo crittografico open source è in definitiva decentralizzato. Litecoin offre una elaborazione più rapida utilizzando il metodo di accelerazione SegWit per i tempi di transazione senza compromettere la tecnologia blockchain sottostante.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple è basato su un protocollo open source distribuito, che supporta anche i token ed è sia una rete di transazione che un token crittografico. La criptovaluta preferita dalle banche e la media dei trasferimenti di denaro globali ha subito un periodo di crescita.`,
          },
        ],
      },
    ],
    energiesDesc: `"Una materia prima è un bene fondamentale che è intercambiabile con altre merci dello stesso tipo. Le Materie prime sono spesso input utilizzati in produzione per altri beni e servizi. Un’altra definizione di una materia è un bene scambiato per il commercio. Ciò include qualsiasi prodotto che viene venduto a merce di scambio come il CBOE (Chicago Scambio Opzioni di Bordo)."`,
    energiesPopular: `Energie piu' Popolari`,
    energiesInfo: [
      {
        moreInfo: `Piu' Info su Energie`,
        points: [
          {
            title: `Petrolio Greggio`,
            text: `Il Petrolio ha una enorme influenza nei Sistemi economici e politici del Mondo.Il Petrolio greggio offre un’elevata liquidita e pertanto crea una eccellente opportunita’ di TradingCome molti altri Assets dipende dalla domanda e offerta.`,
          },
          {
            title: `Brent`,
            text: `Brent è un greggio leggero, che è di provenienza dal Mare del Nord. Conosciuto anche come Brent petrolio serve un grande prezzo di riferimento per acquisti di petrolio in tutto il mondo.`,
          },
          {
            title: `Natural Gas`,
            text: `Il gas naturale è uno delle più pulite e più sicure fonti energetiche disponibili nel mondo. Il gas naturale è in grande domanda durante la stagione invernale per soddisfare le esigenze di riscaldamento commerciali e domestiche. E ‘uno dei più interessanti materie prime.`,
          },
        ],
      },
    ],
    sharesDesc: `Negozia i prezzi delle azioni delle principali società nelle principali borse mondiali. I contratti per differenza (CFD) sono negoziati a margine che consentono ai trader di moltiplicare i rispettivi rendimenti. Anche piccoli movimenti di mercato possono comportare profitti o perdite. Quando i mercati tendono al ribasso, i trader possono andare short. TrioMarkets ™ attualmente fornisce una leva 1:5 sui CFD azionari al momento dell'iscrizione.`,
    sharesPopular: `Azioni piu' Popolari`,
    sharesInfo: [
      {
        moreInfo: `Piu' Info su Azioni`,
        text: `I prezzi delle azioni sono in gran parte determinati dale forze della domanda e dell'offerta. Una societa' che esibisce potenziali guadagni a lungo termine puo' attrarre piu' investitori ad acquistare le proprie azioni, determinandone un aumento nel valore delle stesse. Una societa' con una prospettiva ribassista, al contrario, attirerebbe molti piu' venditori che acquirenti, il che potrebbe comportare una pressione ribassista sui loro prezzi.`,
        textTwo: `Altri fattori possono causare volatilita' dei prezzi provocando improvvisi o brevi cambiamenti nello stesso prezzo. Alcuni esempi dei fattori che possono causare volatilita' nei prezzi sono relativi a: eventi politici, relazioni finanziarie e notizie economiche. Tuttavia non tutte le notizie o i rapporti riguardano tutti I titoli. Ad esempio, le azioni di societa' appartenenti al settore automobilistico potrebbero risentire di una mancanza di inventario per soddisfare le richieste dei potenziali acquirenti e/o un aumento nei prezzi dell'acciaio genererebbe un aumento imprevisto dei costi di produzione. I prezzi delle azioni possono anche essere guidati da cio' che e' noto come comportamento del branco, ossia la tendenza Assunta dagli individui a copiare l'azione di un gruppo piu' ampio.`,
      },
    ],
  },
  de: {
    marketsTop: [
      { desc: "Spread ab 0 Pips", img: "spread" },
      {
        desc: `Hebel bis zu ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "Hohe Ausführungs-<br>geschwindigkeit", img: "execution" },
      { desc: "130+ Produkte", img: "instruments" },
    ],
    forexDesc: `Der Devisenmarkt, oft auch FX genannt, bezeichnet den Prozess des Kaufs einer Währung gegen den Verkauf einer anderen. Bis vor kurzem war der Devisenhandel nur großen Finanzinstituten, Zentralbanken, Unternehmen, sehr wohlhabenden Personen und Hedgefonds vorbehalten. Jetzt kann Dank des Internets jeder mit Währungen traden.`,
    forexPopular: `Populäre FX Paare`,
    forexInfo: [
      {
        moreInfo: "Mehr Infos zu Forex",
        why: "Warum mit Forex traden?",
        points: [
          {
            text: "Weltweit meist gehandelter Markt mit einem täglichen Volumen von bis zu $6,6 Billionen Dollar in 2020",
            img: "1",
          },
          { text: "Enge Spreads/ Ultra schnelle Bearbeitung", img: "2" },
          {
            text: "Der Forex Markt präsentiert verschiedene Trading-Optionen mit steigenden und fallenden Marktpreisen",
            img: "3",
          },
        ],
        text: "<strong>Forex ist bekanntlich die häufigste gängigste Form des Online-Handels und bei TrioMarkets™ bieten wir Ihnen über 60 Ihrer bevorzugten Währungspaare, die Sie auf Ihrer anpassbaren MT4-Plattform handeln können.</strong>",
        pointsTwo: [
          {
            title: "Major",
            text: "Die Hauptwährugspaare sind die Meistgehandelten, zu denen auch der amerikanische US Dollars zählt. Zum Beispiel, kann man EUR/USD oder auch USD/JPY handeln.",
          },
          {
            title: "Minor",
            text: "Kleinere Währungspaare haben nicht den USD und werden als Crosses bezeichnet, wie zum Beispiel EUR/GBP oder EUR/JPY.",
          },
          {
            title: "Exotic",
            text: "Exotische Währungspaare können auch getradet werden, wie zum Beispiel das Paar USD/NOK. Diese Währungspaare bestehen aus einer Hauptwährung und einer kleinen Währung, der Währung eines Schwellenlandes.",
          },
        ],
        pointsThree: [
          {
            text: "Trading mit Forex bedeutet, dass zwei Währungen gegeneinander getradet werden. Dies bedeutet, dass Sie die Differenz zwischen dem Kauf und Verkauf (Spread) des jeweiligen Währungspaares traden.",
          },
          {
            text: "Tägliche Volatilität ist für die meisten Währungen relativ gering. Weltwährungen bewegen sich meistens weniger als ein Prozentpunkt pro Handelstag. Dies macht den FX Markt zu einem der geringsten Volatilitätsmärkte auf der Welt.",
          },
          {
            text: `Mit <strong>TrioMarkets™</strong>, garantieren wir Ihnen dass jede Transaktion vollautomatisch ausgeführt wird. Dies bedeutet dass Ihre Trades effizient und unmittelbar abgewickelt werden. Wir bezeichnen dies als "schnelles und zuverlässiges Traden."`,
          },
          {
            text: "100% automatisiert, ohne Requotes oder Ablehnungen, außer wenn eine Ober- und Untergrenze zum Schutz vor Preisvolatilität gesetzt wird. Kein Dealing Desk oder algorithmische Software-Intervention, welches das Traden für den Broker favorisiert.",
          },
        ],
      },
    ],
    indicesDesc: `Ein Index ist ein Portfolio von Aktien der Top-Unternehmen eines Landes, die die Entwicklung ihres Marktes oder ihrer Börse abbilden. Ein Aktienindex ist ein sehr wichtiger Bestandtteil unserer Finanzwelt.`,
    indicesPopular: `Bekannte Indizes`,
    indicesInfo: [
      {
        moreInfo: "Mehr Infos zu den Indizen",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor’s 500 ist ein amerikanischer Aktienmarktindex der als Index für 500 Aktienwerte dient. <br> Der S&P 500 wurde von S&P Dow Jones entwickelt und weiterhin betrieben. Er ist dafür konzipiert, als führender Indikator für US Aktienwerte zu dienen.`,
          },
          {
            title: `FTSE 100`,
            text: `Der Financial Times Stock Exchange 100 Index repräsentiert die 100 größten britischen Unternehmen, die auf die London Stock Exchange mit der höchsten Marktkapitalisierung gehandelt werden.`,
          },
          {
            title: `DOW JONES`,
            text: `Der Dow Jones Industrial Average wurde geschaffen, um einen klaren Überblick über den Aktienmarkt und somit auf die US Wirtschaft zu erhalten. <br> Der Index besteht aus 30 Konzernen, die im Bereich der Herstellung von Industrie und Konsumprodukten tätig sind.`,
          },
          {
            title: `DAX`,
            text: `Einer der beliebtesten Aktienindizes weltweit. Der Deutsche Aktien Index (DAX) umfasst die 30 wichtigsten Konzerne in Deutschland, die an der Frankfurter Börse gehandelt werden. Der DAX wird hauptsächlich als Performance Index berechnet, was den DAX Index einzigartig macht.`,
          },
          {
            title: `NASDAQ`,
            text: `Die National Association of Securities Dealers Automated Quotations (auch als Nasdaq bekannt) ist eine amerikanische Börse und stellt das zweitgrößte Börsenparkett der Welt dar, gemessen an der Marktkapitalisierung.`,
          },
          {
            title: `CAC40`,
            text: `Der CAC 40 stellt den Benchmark Index für die Pariser Börse dar und reflektiert die Kursentwicklung der 40 größten und am häufigsten gehandelten Aktien, die auf dem Euronext Paris gelistet sind. <br> Der Index macht 20% der Marktkapitalisierung in der Eurozone aus und stellt den am meisten genutzten Index dar.`,
          },
          {
            title: `EUROPE50`,
            text: `Der STOXX Europe 50 ist ein Aktienindex für europäische Aktien, der von STOXX Ltd. entwickelt wurde, einem Indexanbieter im Besitz der Gruppe Deutsche Börse und der SIX Group. Der Index wurde im Februar 1998 eingeführt.`,
          },
          {
            title: `SPAIN35`,
            text: `Der IBEX 35 (IBerian IndEX) ist der Benchmark-Börsenindex der Bolsa de Madrid, der wichtigsten Börse Spaniens. Der Index wurde 1992 eingeführt und wird von der Sociedad de Bolsas verwaltet und berechnet. Er ist ein nach der Marktkapitalisierung gewichteter Index, der die 35 liquidesten spanischen Aktien umfasst, die im allgemeinen Index der Madrider Börse gehandelt werden, und wird zweimal jährlich überprüft.`,
          },
        ],
      },
    ],
    metalsDesc: `"Ein Edelmetall wird als wertvoll angesehen, weil es selten ist und hat einen hohen wirtschaftlichen Wert hat. Metalle sind eine physische Ware und haben immer eine wichtige Rolle in der Historie gespielt. Die Nachfrage nach diesen Metallen ist weltweit gestiegen und hat daher die Preise in den vergangenen Jahren erhöht."`,
    metalsPopular: `Bekannte Edelmetalle`,
    metalsInfo: [
      {
        moreInfo: `Mehr Infos zu Edelmetallen`,
        points: [
          {
            title: `Silber`,
            text: `Silber stellt ein hochvolatiles Metall dar. In Anbetracht der Tatsache, dass Silber häufig in der Schmuckindustrie und Konsumprodukten benötigt wird, haben Angebot und Nachfrage dieses Metalls einen starken Einfluss auf die Preisentwicklung dieses Rohstoffes.`,
          },
          {
            title: `Gold`,
            text: `Gold ist der am häufigsten gehandelte Rohstoff. In Zeiten von Marktunsicherheiten und Finanzkrisen war Gold schon immer ein beliebter Vermögenswert bei den Anlegern.`,
          },
          {
            title: `Palladium`,
            text: `Palladium ist bekannt als ein chemisch stabiles Metall. Es gehört zu den wichtigsten internationalen Rohstoffen und ist bis zu 30 Mal seltener als Gold.`,
          },
          {
            title: `Platin`,
            text: `Platin ist eines der seltensten seltensten Metalle der Welt. Platin hat ganz besondere Eigenschaften, die in einer Vielzahl von Branchen sehr gefragt sind. Dies hat zu unvorhersehbaren Schwankungen von Angebot und Nachfrage geführt und somit zu großartigen Trading-Gelegenheiten führt.`,
          },
        ],
      },
    ],
    cryptosDesc: `Traden Sie heute die meist bekannten Kryptowährungen in CFD. TrioMarkets™ ist ein lizenzierter und regulierter Händler der CFDs in Kryptowährungen anbietet.`,
    cryptosPopular: `Bekannte Kryptowährungen`,
    cryptosInfo: [
      {
        why: `Warum ist der <strong>Kauf von Differenzkontrakten</strong> mit <strong>Kryptowährungen</strong> eine bessere Alternative <br> zum realen Kauf von Kryptos?`,
        points: [
          {
            img: `shield`,
            text: `Keine Möglichkeit der <br> <strong> Piraterie auf Ihre <br> Kryptowährungen-Wallet</strong>  `,
          },
          {
            img: `cloud`,
            text: `Keine <strong>Kaltlagerung </strong><br> der Kryptos notwendig`,
          },
          {
            img: `portfolio`,
            text: `Ein <strong>vertrauenswürdiger</strong> <br> und <strong>zuverlässiger</strong>  Händler bezahlt <br> die Gewinne an seine Kunden`,
          },
          {
            img: `security`,
            text: `<strong>Sicherheit</strong> der Kundenfonds <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> ist Mitglied des <br> Investor Compensation Fund</span>`,
          },
        ],
        moreInfo: `Mehr Infos zu den Kryptos`,
        pointsTwo: [
          {
            img: `btc`,
            title: `Bitcoin CFD"`,
            text: `Die erste und größte Kryptowährung – Bitcoin – ebnete den Weg für hunderte von weiteren Kryptowährungen mit einem Börsenwert von über $100 Milliarden.`,
          },
          {
            img: `eth`,
            title: `Ethereum CFD`,
            text: `Die zweite, zweifellos größte Kryptowährung der Welt nach Bitcoin. Eine große Anzahl von etablierten und gut bekannten Organisationen – unter denen auch Microsoft, JP Morgan und Intel – haben ihre Unterstützung für Ethereum gezeigt.`,
          },
          {
            img: `ltc`,
            title: `Litecoin CFD`,
            text: `Von einem ehemaligen Google-Ingenieur zur Verbesserung der Bitcoin-Technologie und mit einem kryptographischen Open-Source-Protokoll entworfen, ist diese Währung letztendlich dezentralisiert und bietet eine schnellere Aufbereitung, indem sie die SegWit-Beschleunigungsmethode für die Überweisungszeiten nutzt, ohne die zugrundeliegende Blockchain-Technologie zu gefährden.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple wurde auf einem dezentralisierten Open-Source-Protokoll gebaut, der auch Tokens unterstützt und zugleich ein Überweisungsnetzwerk und Krypto-Token ist. Die beliebteste Kryptowährung der Banken und ein Mittel für globale Geldtransfers, hat eine Wachstumsphase erlebt.`,
          },
        ],
      },
    ],
    energiesDesc: `Als Rohstoff wird ein Grunderzeugnis bezeichnet, das mit anderen Grunderzeugnissen desselben Typs austauschbar ist. Rohstoffe werden oft in der Herstellung von anderen Gütern oder Dienstleistungen eingesetzt. Anders definiert ist ein Rohstoff jegliches Produkt, das für den Handel getauscht werden kann. Dies beinhaltet jedes auf einer Rohstoffbörse gehandeltes Produkt, wie etwa CBOE (Chicago Board Options Exchange).`,
    energiesPopular: `Bekannte Energien`,
    energiesInfo: [
      {
        moreInfo: `Mehr Infos zu den Energien`,
        points: [
          {
            title: `Rohöl`,
            text: `Öl nimmt sowohl im weltweiten Wirtschaftssystem als auch im politischen System eine äußerst wichtige Rolle ein. Rohöl ist hochliquide und bietet somit hervorragende Handelsgelegenheiten an. Rohöl, wie auch viele andere Vermögenswerte, werden durch Angebot und Nachfrage beeinflusst.`,
          },
          {
            title: `Brent`,
            text: `Brent ist ein leichtes, aus der Nordsee gewonnenes Rohöl. Auch als Brent Petroleum bekannt, dient es als wichtiger Benchmark-Preis für Ölhandel weltweit.`,
          },
          {
            title: `Erdgas`,
            text: `Erdgas ist eine der weltweit saubersten und saubersten und sichersten Energiequellen. Im Winter besteht eine große Nachfrage nach Erdgas, um den Heizbedarf von Unternehmen und Privathaushalten zu decken. Es ist einer der attraktivsten weltweit gehandelten Rohstoffe auf dem Markt.`,
          },
        ],
      },
    ],
    sharesDesc: `Handeln Sie die Aktienkurse der großen Unternehmen an den weltweit führenden Börsen. Contracts For Difference (CFDs) werden auf Marge gehandelt und ermöglichen es den Tradern, ihre jeweiligen Erträge zu vervielfachen. Schon geringe Marktbewegungen können zu Gewinn oder Verlust führen. Wenn die Märkte einen Abwärtstrend aufweisen, können Händler Leerverkäufe tätigen. TrioMarkets ™ bietet derzeit einen Hebel von 1:5 auf Aktien-CFDs bei der Anmeldung.`,
    sharesPopular: `Bekannte Aktien`,
    sharesInfo: [
      {
        moreInfo: `Mehr Infos über die Aktien`,
        text: `Aktienkurse werden weitgehend durch die Kräfte von Angebot und Nachfrage bestimmt. Ein Unternehmen, das ein langfristiges Ertragspotenzial aufweist, kann mehr Anleger zum Kauf seiner Aktien bewegen, was zu einem Anstieg der Aktienkurse führt. Ein Unternehmen mit rückläufigen Aussichten hingegen kann mehr Verkäufer als Käufer anlocken, was zu einem Abwärtsdruck auf die Kurse führen kann.`,
        textTwo: `Es gibt weitere Faktoren die zu einer Volatilität führen können und somit plötzliche Preisveränderungen verursachen. Einige Beispiele hierfür sind politische oder wirtschaftliche Ereignisse. Jedoch beeinflussen nicht alle Nachrichten oder Berichte die Wertpapiere. Ein konkretes Beispiel ist, wenn die Firma einen neuen Vertrag unterschreibt oder verliert und somit die Aktienkurse beeinflusst werden. Die Aktienkurse können auch durch ein Herdenverhalten auf dem Markt bestimmt werden, wobei die meisten Investoren der Masse folgen, sei es bei einer Preissenkung oder einer Preiserhöhung.`,
      },
    ],
  },
  cn: {
    marketsTop: [
      { desc: "点差从 0 点开始", img: "spread" },
      {
        desc: `杠杆高达 ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "高执行速度", img: "execution" },
      { desc: "130 多种仪器", img: "instruments" },
    ],
    forexDesc: `外汇市场，通常被称为FX，这是购买一种货币对卖出另一种货币的过程。直到最近，外汇交易只适用于大型金融机构、中央银行、公司、非常富有的人和对冲基金。现在，由于互联网的出现，任何人都可以进行货币交易。`,
    forexPopular: `热门外汇货币对`,
    forexInfo: [
      {
        moreInfo: "有关外汇的更多信息",
        why: "为什么要交易货币？",
        points: [
          {
            text: "截至2020年，这是全球交易量最大的市场，日交易量达6.6万亿美元",
            img: "1",
          },
          { text: "极低点差/闪电般的快速执行", img: "2" },
          {
            text: "外汇市场在市场价格的上涨和下跌中提供了许多交易机会",
            img: "3",
          },
        ],
        text: "<strong>众所周知，外汇是最常见的在线交易形式，在TrioMarkets™，<br>我们为您提供 60多种您最喜欢的货币对，可在您的可定制 MT4平台上进行交易。</strong>",
        pointsTwo: [
          {
            title: "主要货币对",
            text: "主要货币对是最具流动性的货币对，包括美元。例如欧元/美元和美元/日元。",
          },
          {
            title: "次要货币对",
            text: "次要货币对没有美元, 被称为交叉货币对。例如欧元/英镑或欧元/日元。",
          },
          {
            title: "异国货币对",
            text: "我们也可以交易异国货币，例如美元/挪威克朗。这些货币对由主要货币对加上发展中经济体的货币组成。",
          },
        ],
        pointsThree: [
          { text: "外汇交易意味着您买入两种货币之间的差价或价差。" },
          {
            text: "大多数货币的每日波动性通常很小。一般来说, 世界货币在一个交易日内的波动不到一个百分点。这使得外汇市场成为世界上波动最小的市场之一。",
          },
          {
            text: "<strong>TrioMarkets™</strong>, 我们确保所有交易都是完全自动化的, 这意味着您的交易将得到高效执行和即时结算。我们称之为“快速可靠的交易”。",
          },
          {
            text: "100%自动化，无重新报价或拒绝，除非设置上限和下限以防止价格波动。没有交易台或算法软件干预来推动交易对经纪商有利。",
          },
        ],
      },
    ],
    indicesDesc: `指数是来自特定国家的顶级公司股票的投资组合, 用于跟踪其市场或交易所的表现。股票指数是我们金融世界中非常重要的一部分。`,
    indicesPopular: `热门指数`,
    indicesInfo: [
      {
        moreInfo: "有关指数的更多信息",
        points: [
          {
            title: `美国标准普尔500指数`,
            text: `标准普尔500指数是美国股票市场指数，是500只股票的指数。<br>标准普尔500指数由标准普尔道琼斯开发并继续维护。它旨在成为美国股市的领先指标。`,
          },
          {
            title: `英国富时100指数`,
            text: `金融时报证券交易所100指数代表了在伦敦证券交易所上市，市值最高的100家符合条件的最大英国公司。`,
          },
          {
            title: `美国道琼斯指数`,
            text: `道琼斯工业平均值的创建是为了提供对股票市场以及美国经济的清晰认识。<br>它由30家工业和消费品制造商组成。`,
          },
          {
            title: `德国DAX指数`,
            text: `作为全球最受欢迎的指数之一，DAX是一种德国股票指数，由在法兰克福证券交易所交易的30家主要德国公司组成。DAX主要作为业绩指标计算，使得DAX指数独一无二。`,
          },
          {
            title: `美国纳斯达克指数`,
            text: `全美证券交易商自动报价系统协会（简称纳斯达克）是一家美国证券交易所, 按市值排名世界第二。`,
          },
          {
            title: `法国CAC40指数`,
            text: `CAC40是巴黎证券交易所的基准指数，因为它反映了在巴黎泛欧交易所上市的40只最大和交易最活跃的股票的表现。<br>它占欧元区市值的20%，是使用最广泛的指数。`,
          },
          {
            title: `欧洲50指数`,
            text: `STOXX欧洲50指数是由STOXX有限公司开发的欧洲股票指数，该公司是由德意志交易所集团和SIX集团拥有的指数供应商。该指数于1998年2月推出。`,
          },
          {
            title: `西班牙35指数`,
            text: `IBEX 35（IBerian IndEX）是西班牙主要证券交易所马德里证券交易所（Bolsa de Madrid）的基准股市指数。该指数于1992年推出，由Sociedad de Bolsas管理和计算。它是一个市值加权指数，由马德里证券交易所总指数中交易量最大的35只西班牙股票组成，每年审查两次。`,
          },
        ],
      },
    ],
    metalsDesc: `“贵金属之所以珍贵，是因为它稀有且具有很高的经济价值”。金属是一种实物商品，在历史上一直扮演着重要的角色，并将继续受到重视。 全球对这些金属的需求增加，因此在过去几年推高了价格。`,
    metalsPopular: `热门金属`,
    metalsInfo: [
      {
        moreInfo: `有关金属的更多信息`,
        points: [
          {
            title: `白银`,
            text: `白银是一种极易挥发的金属。<br>鉴于白银在珠宝和消费品中的普遍用途，供应需求关系严重影响了这种商品的走势。`,
          },
          {
            title: `黄金`,
            text: `黄金是最受欢迎的交易商品。<br>在市场不确定性和金融危机时期，黄金一直是投资者最喜欢的资产。`,
          },
          {
            title: `钯`,
            text: `钯被认为是一种化学稳定的金属。<br>它是主要的国际贸易商品之一，众所周知，它比黄金稀有30倍。`,
          },
          {
            title: `铂`,
            text: `铂是世界上最稀有的金属之一。<br>铂金具有非常特殊的特性，在各个行业都有很大的需求，这导致了供需的剧烈波动。`,
          },
        ],
      },
    ],
    cryptosDesc: `立即交易加密货币中最受欢迎的差价合约！TrioMarkets™ 是一家获得许可和受监管的经纪商, 能在加密货币交易中提供差价合约。`,
    cryptosPopular: `热门加密货币`,
    cryptosInfo: [
      {
        why: `为什么交易加密货币<strong>差价合约</strong>比购买实物<strong>加密货币</strong>的更有优势?`,
        points: [
          {
            img: `shield`,
            text: `不用担心加密货币<br><strong>钱包被破解</strong>`,
          },
          {
            img: `cloud`,
            text: `不需要冷<strong>冷储存</strong><br>实际加密货币 `,
          },
          {
            img: `portfolio`,
            text: `<strong>可靠</strong><strong>受监管</strong><br>的经纪商为其客<br>户支付利润`,
          },
          {
            img: `security`,
            text: `为客户的资金提供<br><strong>安全保障</strong>。 <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong><br>是投资者赔偿基金的成员</span>`,
          },
        ],
        moreInfo: `有关加密货币的更多信息`,
        pointsTwo: [
          {
            img: `btc`,
            title: `比特币差价合约`,
            text: `作为第一个也是最大的加密货币，比特币为其他数百种市值超过1000亿美元的加密货币铺平了道路。`,
          },
          {
            img: `eth`,
            title: `以太坊差价合约`,
            text: `无可置疑的世界第二大加密货币, 仅次于比特币。大量成熟的知名组织积极支持以太坊, 其中少有微软、摩根大通和英特尔。`,
          },
          {
            img: `ltc`,
            title: `莱特币差价合约`,
            text: `由Google前工程师设计，以改进比特币技术，基于开源加密协议，最终是去中心化的，使用SegWit加速方法提供更快的处理速度，而不影响底层区块链技术。`,
          },
          {
            img: `xrp`,
            title: `瑞波币差价合约`,
            text: `瑞波币建立在分布式开源协议之上，该协议也支持代币，既是交易网络又是加密代币。它经历了一段增长期，是银行最常用的加密货币和全球汇款方式，。`,
          },
        ],
      },
    ],
    energiesDesc: `大宗商品是一种基本商品，可以与其他同类商品互换。大宗商品通常是用于生产其他商品和服务。大宗商品的另一个定义是为商业活动交换的任何商品。这包括在商品交易所, 如CBOE（芝加哥期权交易所)上交易的任何产品。`,
    energiesPopular: `热门能源`,
    energiesInfo: [
      {
        moreInfo: `有关能源的更多信息`,
        points: [
          {
            title: `原油`,
            text: `石油在世界经济和政治体系中占有重要地位。原油由于其高交易量而提供高流动性, 这反过来又为交易者提供了更多进入交易的机会。原油就像许多其他资产一样受到供求关系的影响。`,
          },
          {
            title: `布伦特原油`,
            text: `布伦特是一种来自北海的低硫轻质原油。也称为布伦特石油, 它是全球石油采购的主要基准价格。`,
          },
          {
            title: `天然气`,
            text: `天然气是世界上最清洁、最安全的能源供应之一。天然气在冬季需求最大，以满足商业和家庭供暖需求。它是世界上最具吸引力的全球贸易商品之一。`,
          },
        ],
      },
    ],
    sharesDesc: `在世界领先的交易所交易主要公司的股票价格。差价合约(CFD)是以保证金方式交易的，这使得交易者可以将各自的收益倍增。即使是微小的市场波动也会导致盈利或亏损。当市场呈下降趋势时，交易者可以做空。TrioMarkets ™目前在注册时对股票差价合约提供1：5的杠杆。 `,
    sharesPopular: `热门股票`,
    sharesInfo: [
      {
        moreInfo: `有关股票的更多信息`,
        text: `股价在很大程度上取决于供求关系。 一家表现出潜在长期收益的公司可能会吸引更多投资者购买其股票, 从而推动股价上涨。另一方面, 前景看跌的公司可能会吸引更多的卖家而不是买家, 这可能导致其价格面临下行压力。`,
        textTwo: `其他因素可能导致价格波动并导致价格突然或短暂的变化。这方面的一些例子包括政治事件、金融报告和经济新闻。但是，并非所有新闻或报告都会影响所有证券。例如，从事汽车行业的公司股票可能会因为以下原因遭殃：库存不足而无法满足买家需求和/或钢材价格上涨，从而导致生产成本意外增加。<br>"股票价格也可以由所谓的从众行为驱动，这是人们模仿更大群体行为的趋势。"`,
      },
    ],
  },
  es: {
    marketsTop: [
      { desc: "Spread desde 0 pips", img: "spread" },
      {
        desc: `Apalancamiento hasta to ${
          license == "global" ? "1:500" : "1:30"
        }`,
        img: "leverage",
      },
      { desc: "Alta Velocidad de Ejecución", img: "execution" },
      { desc: "130+ Instrumentos", img: "instruments" },
    ],
    forexDesc: `El mercado de divisas, a menudo denominado FX, es el proceso de compra de una divisa contra la venta de la otra. Hasta hace muy poco, el mercado de divisas sólo estaba al alcance de las grandes instituciones financieras, los bancos centrales, las empresas, las grandes fortunas y los fondos de cobertura. Ahora, gracias a Internet, cualquiera puede operar con divisas.`,
    forexPopular: `Pares de FX Populares`,
    forexInfo: [
      {
        moreInfo: "Elige una Plataforma",
        why: "Por qué operar con divisas?",
        points: [
          {
            text: "El mercado más negociado del mundo con un volumen diario de $ 6,6 billones a partir de 2020",
            img: "1",
          },
          { text: "Spreads ajustados / Ejecución ultrarrápida", img: "2" },
          {
            text: "El mercado de divisas presenta muchas oportunidades comerciales en el aumento y la caída del precio del mercado",
            img: "3",
          },
        ],
        text: "<strong>Se sabe que Forex es la forma más común de comercio en línea y en TrioMarkets™, le ofrecemos <br> más de 60 de sus pares de divisas favoritos negociables en su plataforma MT4 personalizable.</strong>",
        pointsTwo: [
          {
            title: "Major",
            text: "Los principales pares de divisas son los pares más líquidos para negociar, que incluyen el dólar Americano. Como el EUR/USD y el USD/JPY.",
          },
          {
            title: "Minor",
            text: "Los pares de divisas menores no tienen el USD y se conocen como cruces. Como el EUR/GBP o el EUR/JPY.",
          },
          {
            title: "Exotic",
            text: "También se pueden operar con pares de divisas exóticas, como USD/NOK. Estos pares de divisas se componen de un par de divisas principal junto con la moneda de una economía en desarrollo.",
          },
        ],
        pointsThree: [
          {
            text: "Operar en FX significa que está comprando la diferencia o el diferencial entre las dos monedas.",
          },
          {
            text: "La volatilidad diaria de la mayoría de las divisas suele ser pequeña. En general, las monedas mundiales se mueven menos de un punto porcentual durante un día de negociación. Esto hace que el mercado de divisas sea uno de los mercados menos volátiles del mundo.",
          },
          {
            text: `Con <strong>TrioMarkets™</strong>, nos aseguramos de que todas las transacciones estén completamente automatizadas, lo que significa que sus operaciones se ejecutan de manera eficiente y se liquidan al instante. A esto lo llamamos "comercio rápido y confiable".`,
          },
          {
            text: "100% automatizado sin recotizaciones ni rechazos a menos que se establezcan límites superior e inferior para proteger contra la volatilidad de los precios. No hay mesa de negociación ni intervención de software algorítmico para impulsar las operaciones a favor del bróker.",
          },
        ],
      },
    ],
    indicesDesc: `Un índice es una cartera de acciones de las principales empresas de un país en particular que rastrea el desempeño de su mercado o bolsa. Un índice bursátil es una parte muy importante de nuestro mundo financiero.`,
    indicesPopular: `Índices Populares`,
    indicesInfo: [
      {
        moreInfo: "Más información sobre Índices",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor's 500 es un índice bursátil Americano que es un índice de 500 acciones. <br> El S&P 500 fue desarrollado y sigue siendo mantenido por S&P Dow Jones. Está diseñado para ser un indicador adelantado de las acciones Americanos.`,
          },
          {
            title: `FTSE 100`,
            text: `El índice Financial Times Stock Exchange 100 representa las 100 empresas más grandes del Reino Unido que cotizan en la Bolsa de Valores de Londres con la capitalización de mercado más alta.`,
          },
          {
            title: `DOW JONES`,
            text: `El indice industrial Dow Jones se creó para brindar una visión clara del mercado de valores y, por lo tanto, de la economía estadounidense. <br> Se compone de 30 empresas de componentes que son fabricantes de bienes industriales y de consumo.`,
          },
          {
            title: `DAX`,
            text: `Uno de los índices más populares del mundo, DAX es un índice bursátil alemán que consta de las 30 principales empresas alemanas que cotizan en la bolsa de valores de Frankfurt. <br> DAX se calcula principalmente como un índice de rendimiento, lo que hace que el índice DAX sea único.`,
          },
          {
            title: `NASDAQ`,
            text: `La Asociación Nacional de Cotizaciones Automatizadas de Comerciantes de Valores (conocida como Nasdaq) es una bolsa de valores estadounidense que está clasificada como la segunda bolsa más grande del mundo por capitalización de mercado.`,
          },
          {
            title: `CAC40`,
            text: `CAC 40 Es un índice de referencia para la bolsa de valores de París, ya que refleja el rendimiento de las 40 acciones más grandes y negociadas más activamente que cotizan en Euronext Paris. <br> Representa el 20% de la capitalización de mercado en la zona euro y es el índice más utilizado.`,
          },
          {
            title: `EUROPE50`,
            text: `El STOXX Europe 50 es un índice bursátil de valores europeos diseñado por STOXX Ltd., un proveedor de índices propiedad de Deutsche Börse Group y SIX Group. El índice se introdujo en febrero de 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `El IBEX 35 (IBerian IndEX) es el índice bursátil de referencia de la Bolsa de Madrid, la principal bolsa española. Iniciado en 1992, el índice es administrado y calculado por la Sociedad de Bolsas. Es un índice ponderado por la capitalización bursátil que comprende los 35 valores españoles más líquidos negociados en el Índice General de la Bolsa de Madrid y se revisa dos veces.`,
          },
        ],
      },
    ],
    metalsDesc: `“Un metal precioso se considera precioso porque es raro y tiene un alto valor económico”. Los metales son un bien físico y siempre han jugado un papel importante en la historia y seguirán siendo valorados. La demanda de estos metales ha aumentado en todo el mundo. y por lo tanto ha hecho subir los precios en los últimos años.`,
    metalsPopular: `Metales Populares`,
    metalsInfo: [
      {
        moreInfo: `Más información sobre Metales`,
        points: [
          {
            title: `Plata`,
            text: `La plata es un metal altamente volátil. <br> Dado el uso común de la plata en joyería y productos de consumo, la oferta y la demanda influyen en gran medida en los movimientos de este producto.`,
          },
          {
            title: `Oro`,
            text: `El oro es la materia prima más negociada. <br> El oro siempre ha sido un activo favorito entre los inversores en tiempos de incertidumbre del mercado y crisis financiera.`,
          },
          {
            title: `Paladio`,
            text: `El paladio se considera un metal químicamente estable. <br> Es uno de los principales productos comercializados internacionalmente y se sabe que es hasta 30 veces más raro que el oro.`,
          },
          {
            title: `Platino`,
            text: `El platino es uno de los metales más raros del mundo. <br> El platino consiste en propiedades muy especiales que son de gran demanda en varias industrias. Esto ha llevado a fluctuaciones salvajes en la oferta y la demanda.`,
          },
        ],
      },
    ],
    cryptoDesc: `Opere con los CFD más populares en Cryptos hoy!  TrioMarkets™ es un corredor con licencia y regulado que ofrece CFD en el comercio de Cryptos.`,
    cryptosPopular: `Criptomonedas Populares`,
    cryptosInfo: [
      {
        why: `Por qué los <strong>CFDs</strong> sobre <strong>Criptomonedas</strong> son una buena alternativa <br> a la compra física de criptomonedas?`,
        points: [
          {
            img: `shield`,
            text: `No hay posibilidad de <br> <strong>Hacks de Billetera</strong> <br> de criptomonedas`,
          },
          {
            img: `cloud`,
            text: `No hay necesidad de <br> <strong>Almacenamiento</strong> <br> las Criptomonedas`,
          },
          {
            img: `portfolio`,
            text: `Un Broker <strong>Confiable</strong> y <strong>Regulado</strong> <br> paga ganancias para <br> sus clientes`,
          },
          {
            img: `security`,
            text: `Garantizar la  <strong>Seguridad</strong> <br>de los fondos de los clientes. <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> es miembro de la <br>Fondo de Compensación de Inversores</span>`,
          },
        ],
        moreInfo: `Más información sobre Criptos`,
        pointsTwo: [
          {
            img: `btc`,
            title: `Bitcoin CFD`,
            text: `La primera y más grande criptomoneda, Bitcoin allanó el camino para el resto de cientos de criptomonedas con una capitalización de mercado de más de $ 100 mil millones.`,
          },
          {
            img: `eth`,
            title: `Ethereum CFD`,
            text: `La segunda criptomoneda más grande del mundo, sin duda, después de Bitcoin. Una gran cantidad de organizaciones bien conocidas han apoyado activamente a Ethereum, algunas de las cuales son Microsoft, JP Morgan e Intel.`,
          },
          {
            img: `ltc`,
            title: `CFD Litecoin`,
            text: `Diseñado por un ex ingeniero de Google para mejorar la tecnología de Bitcoin, basado en un protocolo criptográfico de código abierto que en última instancia está descentralizado, ofrece un procesamiento más rápido utilizando el método de aceleración SegWit para los tiempos de transacción sin comprometer la tecnología de cadena de bloques subyacente.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple, se basa en un protocolo distribuido de código abierto, que también admite tokens y es tanto una red de transacciones como un token criptográfico. La criptomoneda favorita de los bancos y el medio de transferencia de dinero global ha experimentado un período de crecimiento.`,
          },
        ],
      },
    ],
    energiesDesc: `Una materia prima es un bien básico que es intercambiable con otras mercancías del mismo tipo. Los productos básicos son a menudo insumos utilizados en la producción de otros bienes y servicios. Otra definición de mercancía es cualquier bien intercambiado por comercio. Esto incluye cualquier producto que se negocie en una bolsa de productos básicos como la CBOE (bolsa de opciones de la junta de Chicago).`,
    energiesPopular: `Energías Populares`,
    energiesInfo: [
      {
        moreInfo: `Más información sobre Energías`,
        points: [
          {
            title: `Crude Oil`,
            text: `El petróleo tiene una enorme posición dentro de los sistemas económicos y políticos del mundo. El petróleo crudo ofrece una alta liquidez debido a su alto volumen de negociación, lo que a su vez brinda a los operadores más oportunidades de realizar una transacción. El petróleo crudo, al igual que muchos otros activos, se ve afectado por la oferta y la demanda.`,
          },
          {
            title: `Brent`,
            text: `Brent es un crudo ligero dulce que se obtiene del Mar del Norte. También conocido como petróleo Brent, sirve como un importante precio de referencia para las compras de petróleo en todo el mundo.`,
          },
          {
            title: `Gas Natural`,
            text: `El gas natural es uno de los suministros de energía más limpios y seguros disponibles en el mundo. El gas natural tiene mayor demanda durante la temporada de invierno para satisfacer las necesidades de calefacción comercial y doméstica. Es uno de los productos básicos comercializados a nivel mundial más atractivos del mundo.`,
          },
        ],
      },
    ],
    sharesDesc: `Opere con las cotizaciones bursátiles de las principales empresas en las principales bolsas del mundo. Los contratos por diferencia (CFD) se negocian con margen, lo que permite a los operadores multiplicar sus respectivos beneficios. Incluso los movimientos menores del mercado pueden dar lugar a beneficios o pérdidas. Cuando los mercados tienden a la baja, los operadores pueden ir en corto. TrioMarkets ™ actualmente ofrece apalancamiento 1:5 en Equity CFDs al registrarse.`,
    sharesPopular: `Acciones Populares`,
    sharesInfo: [
      {
        moreInfo: `Más información sobre Acciones`,
        text: `Los precios de las acciones están determinados en gran medida por las fuerzas de la oferta y la demanda. Una empresa que exhibe ganancias potenciales a largo plazo puede atraer a más inversionistas para que compren sus acciones, lo que genera un aumento en los precios de las acciones. Una empresa con una perspectiva bajista, por otro lado, puede atraer a más vendedores que compradores, lo que puede resultar en una presión a la baja sobre sus precios.`,
        textTwo: `Otros factores pueden provocar la volatilidad de los precios y provocar cambios repentinos o breves en el precio. Algunos ejemplos de esto incluyen eventos políticos, informes financieros y noticias económicas. Sin embargo, no todas las noticias o reportajes afectan a todos los valores. Por ejemplo, las existencias de empresas dedicadas a la industria automotriz, pueden sufrir una falta de inventario para satisfacer la demanda de los compradores y/o un aumento en el precio del acero, lo que provocará un aumento inesperado en los costos de producción. Los precios de las acciones también pueden verse impulsados por lo que se conoce como comportamiento de manada, que es la tendencia de las personas a imitar las acciones de un grupo más grande.`,
      },
    ],
  },
  vnm: {
    marketsTop: [
      { desc: "Chênh lệch từ 0 pips", img: "spread" },
      {
        desc: `Đòn bẩy lên đến ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "Tốc độ thực thi cao", img: "execution" },
      { desc: "Hơn 130 dụng cụ", img: "instruments" },
    ],
    forexDesc: `Thị trường ngoại hối, thường được gọi là FX, là quá trình mua một loại tiền tệ này so với việc bán loại tiền tệ kia. Cho đến rất gần đây, giao dịch ngoại hối chỉ dành cho các tổ chức tài chính lớn, ngân hàng trung ương, tập đoàn, những người rất giàu có và quỹ phòng hộ. Giờ đây, nhờ có internet, bất kỳ ai cũng có thể giao dịch tiền tệ. `,
    forexPopular: `Các cặp FX phổ biến`,
    forexInfo: [
      {
        moreInfo: "Thông tin thêm về Forex",
        why: "Tại sao nên giao dịch tiền tệ?",
        points: [
          {
            text: "Thị trường được giao dịch nhiều nhất trong thế giới với khối lượng hàng ngày là 6,6 nghìn tỷ đô la vào năm 2020 ",
            img: "1",
          },
          {
            text: "Spread cạnh tranh / Thực hiện lệnh nhanh như chớp",
            img: "2",
          },
          {
            text: "Thị trường Forex giới thiệu nhiều cơ hội giao dịch khi giá cả thị trường tăng và giảm ",
            img: "3",
          },
        ],
        text: "<strong>Forex được biết đến là hình thức giao dịch trực tuyến phổ biến và tại TrioMarkets™, chúng tôi <br> cung cấp cho bạn hơn 60 cặp tiền tệ yêu thích có thể giao dịch trên nền tảng MT4 có thể tùy chỉnh của bạn.</strong>",
        pointsTwo: [
          {
            title: "Chính",
            text: "Các cặp tiền tệ chính là hầu hết các cặp thanh khoản để giao dịch bao gồm Đô la Mỹ. Chẳng hạn như EUR / USD và USD / JPY.",
          },
          {
            title: "Cặp tiền tệ phụ",
            text: "Các cặp tiền nhỏ không có USD và được gọi là cặp chéo. Chẳng hạn như EUR / GBP hoặc EUR / JPY. ",
          },
          {
            title: "Kỳ lạ",
            text: "Các cặp tiền tệ kỳ lạ cũng có thể được giao dịch với chúng tôi chẳng hạn như USD / NOK. Các cặp tiền tệ này được tạo thành từ một cặp tiền tệ chính cùng với tiền tệ của một nền kinh tế đang phát triển. ",
          },
        ],
        pointsThree: [
          {
            text: "Giao dịch trên FX có nghĩa là bạn đang mua chênh lệch hoặc chênh lệch giữa hai loại tiền tệ.",
          },
          {
            text: "Biến động hàng ngày đối với hầu hết tiền tệ thường nhỏ. Nói chung, tiền tệ thế giới di chuyển ít hơn một điểm phần trăm trong một ngày giao dịch. Điều này làm cho thị trường ngoại hối trở thành một trong những thị trường ít biến động nhất trên thế giới.",
          },
          {
            text: "Với <strong>TrioMarkets™</strong> chúng tôi đảm bảo rằng tất cả các giao dịch hoàn toàn tự động, có nghĩa là các giao dịch của bạn được thực hiện một cách hiệu quả và thanh toán ngay lập tức. Chúng tôi gọi đây là “giao dịch nhanh chóng đáng tin cậy”.",
          },
          {
            text: "100% tự động không có báo giá lại hoặc từ chối trừ khi giới hạn trên và dưới được thiết lập để bảo vệ khỏi sự biến động giá. Không có bàn giao dịch hoặc sự can thiệp của phần mềm thuật toán để thúc đẩy giao dịch có lợi cho nhà môi giới.",
          },
        ],
      },
    ],
    indicesDesc: `Chỉ số là danh mục các cổ phiếu công  
    ty hàng đầu từ một quốc gia cụ thể theo dõi hoạt động của thị trường hoặc sàn giao dịch của họ. Chỉ số chứng khoán là một phần rất quan trọng trong thế giới tài chính của chúng tôi.`,
    indicesPopular: `Các chỉ số phổ biến`,
    indicesInfo: [
      {
        moreInfo: "Thông tin thêm về Chỉ số",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor’s 500 là chỉ số thị trường chứng khoán của Mỹ là chỉ số của 500 cổ phiếu. S&P 500 đã được phát triển và. <br> Tiếp tục được duy trì bởi S&P Dow Jones. Nó được thiết kế để trở thành một chỉ báo hàng đầu về chứng khoán Mỹ.`,
          },
          {
            title: `FTSE 100`,
            text: `Chỉ số 100 của Sở giao dịch chứng khoán Financial Times đại diện cho 100 công ty đủ điều kiện lớn nhất của Vương quốc Anh được giao dịch trên Sở giao dịch chứng khoán London với giá trị vốn hóa thị trường cao nhất.`,
          },
          {
            title: `DOW JONES`,
            text: `Chỉ số Trung bình Công nghiệp Dow Jones được tạo ra để cung cấp một cái nhìn rõ ràng về thị trường chứng khoán và do đó là nền kinh tế Hoa Kỳ. <br> Nó bao gồm 30 công ty thành phần là nhà sản xuất hàng công nghiệp và tiêu dùng.`,
          },
          {
            title: `DAX`,
            text: `Một trong những chỉ số phổ biến nhất trên toàn thế giới, DAX là một chỉ số chứng khoán của Đức bao gồm 30 công ty chính của Đức đang giao dịch trên thị trường chứng khoán Frankfurt. <br> DAX chủ yếu được tính như một chỉ số hiệu suất, làm cho chỉ số DAX trở thành duy nhất.`,
          },
          {
            title: `NASDAQ`,
            text: `Hiệp hội Báo giá Tự động của Hiệp hội Các Nhà kinh doanh Chứng khoán Quốc gia (được gọi là Nasdaq) là một sàn giao dịch chứng khoán của Mỹ được xếp hạng là sàn giao dịch lớn thứ hai trên thế giới theo vốn hóa thị trường.`,
          },
          {
            title: `CAC40`,
            text: `CAC 40 Là một chỉ số chuẩn cho thị trường chứng khoán Paris vì nó phản ánh hoạt động của 40 cổ phiếu lớn nhất và được giao dịch tích cực nhất được niêm yết trên Euronext Paris. <br> Nó chiếm 20% vốn hóa thị trường trong Khu vực đồng tiền chung châu Âu và là Chỉ số được sử dụng rộng rãi nhất. `,
          },
          {
            title: `EUROPE50`,
            text: `STOXX Europe 50 là chỉ số chứng khoán của các cổ phiếu Châu Âu được thiết kế bởi STOXX Ltd., một nhà cung cấp chỉ số thuộc sở hữu của Tập đoàn Deutsche Börse và Tập đoàn SIX. Chỉ số được giới thiệu vào tháng 2 năm 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `IBEX 35 (IBerian IndEX) là chỉ số thị trường chứng khoán chuẩn của Bolsa de Madrid, sàn giao dịch chứng khoán chính của Tây Ban Nha. Được khởi xướng vào năm 1992, chỉ số này do Sociedad de Bolsas quản lý và tính toán. Đây là một chỉ số trọng số vốn hóa thị trường bao gồm 35 cổ phiếu Tây Ban Nha có tính thanh khoản cao nhất được giao dịch trong Chỉ số Chung của Sở Giao dịch Chứng khoán Madrid và được xem xét hai lần.`,
          },
        ],
      },
    ],
    metalsDesc: `Một kim loại quý được coi là 
    quý vì nó hiếm và có giá trị kinh tế cao. Kim loại là hàng hóa vật chất luôn đóng một vai trò quan trọng trong lịch sử và sẽ tiếp tục có giá trị. Nhu cầu về những kim loại này đã tăng lên xung quanh thế giới, khiến giá cả tăng lên trong những năm gần đây".`,
    metalsPopular: `Kim loại phổ biến`,
    metalsInfo: [
      {
        moreInfo: `Thông tin thêm về kim loại`,
        points: [
          {
            title: `Bạc`,
            text: `Bạc là một kim loại cực kỳ dễ thanh khoản. Bởi vì bạc được sử dụng rộng rãi trong đồ trang sức và hàng tiêu dùng, cung và cầu có tác động đáng kể đến chuyển động giá của mặt hàng này.`,
          },
          {
            title: `Vàng`,
            text: `Vàng là hàng hóa được giao dịch phổ biến nhất. Vàng luôn là tài sản yêu thích của các nhà đầu tư trong thời kỳ thị trường bất ổn và khủng hoảng tài chính.`,
          },
          {
            title: `Paladi`,
            text: `Paladi được công nhận là một kim loại ổn định về mặt hóa học. Nó là một trong những hàng hóa quốc tế quan trọng nhất và hiếm hơn vàng tới 30 lần.`,
          },
          {
            title: `Bạch kim`,
            text: `Bạch kim là một trong những kim loại hiếm nhất thế giới. Bạch kim có những tính chất rất đặc biệt, có nhu cầu cao trong nhiều ngành công nghiệp. Điều này dẫn đến sự biến động cung và cầu không thể đoán trước.`,
          },
        ],
      },
    ],
    cryptosDesc: `Giao dịch các CFD phổ biến nhất trong Cryptos ngày hôm nay! TrioMarkets ™ là một nhà môi giới được cấp phép và được quản lý cung cấp CFD trong giao dịch Cryptos. `,
    cryptosPopular: `Tiền điện tử phổ biến`,
    cryptosInfo: [
      {
        why: `Tại sao <strong>CFDs</strong> trên <strong>Cryptos</strong> lại tốt thay thế cho việc <br> mua tiền điện tử?`,
        points: [
          {
            img: `shield`,
            text: `Không có cơ hội <br> về tiền điện tử <br> <strong>Hack ví</strong>`,
          },
          {
            img: `cloud`,
            text: `Không cần <br> <strong>kho lạnh thực tế</strong> <br> Không cần kho lạnh thực tế cho Cryptos`,
          },
          {
            img: `portfolio`,
            text: `<strong>Đáng tin cậy</strong> và được <strong>kiểm soát</strong> <br> nhà môi giới trả lợi nhuận cho khách hàng của mình`,
          },
          {
            img: `security`,
            text: `Cung cấp <strong>Bảo mật</strong> <br> của tiền của khách hàng. <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> là thành viên của <br>Quỹ bồi thường nhà đầu tư </span>.`,
          },
        ],
        moreInfo: `Thông tin thêm về Cryptos`,
        pointsTwo: [
          {
            img: `btc`,
            title: `Bitcoin CFD`,
            text: `Tiền điện tử đầu tiên và lớn nhất, Bitcoin đã mở đường cho tất cả phần còn lại của hàng trăm loại tiền điện tử có vốn hóa thị trường hơn 100 tỷ đô la.`,
          },
          {
            img: `eth`,
            title: `Ethereum CFD`,
            text: `Tiền điện tử lớn thứ hai, sau Bitcoin. Một số lượng lớn các tổ chức nổi tiếng được thành lập đã tích cực hỗ trợ Ethereum, một số ít trong số đó là Microsoft, JP Morgan và Intel.`,
          },
          {
            img: `ltc`,
            title: `CFD Litecoin`,
            text: `Được thiết kế bởi một kỹ sư cũ của Google để cải tiến công nghệ của Bitcoin, dựa trên một giao thức mật mã nguồn mở được phân cấp cuối cùng, cung cấp quá trình xử lý nhanh hơn bằng cách sử dụng phương pháp tăng tốc SegWit cho thời gian giao dịch mà không ảnh hưởng đến công nghệ blockchain cơ bản.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple dựa trên một giao thức mã nguồn mở phân tán hỗ trợ mã thông báo và hoạt động như một mạng lưới giao dịch và mã thông báo tiền điện tử. Loại tiền điện tử phổ biến nhất trong các ngân hàng và là phương tiện chuyển tiền toàn cầu đã trải qua một thời kỳ tăng trưởng.`,
          },
        ],
      },
    ],
    energiesDesc: `Hàng hóa là hàng hóa cơ bản có thể hoán đổi cho các hàng hóa khác cùng loại. Hàng hóa thường là đầu vào được sử dụng trong sản xuất đối với hàng hóa và dịch vụ khác. Định nghĩa khác cho hàng hóa là bất kỳ hàng hóa nào được trao đổi để buôn bán. trên một sàn giao dịch hàng hóa như CBOE (Chicago Board Options Exchange).`,
    energiesPopular: `Năng lượng phổ biến`,
    energiesInfo: [
      {
        moreInfo: `Thông tin thêm về Năng lượng`,
        points: [
          {
            title: `Dầu thô`,
            text: `Dầu giữ một vị trí quan trọng trong hệ thống kinh tế và chính trị toàn cầu. Do khối lượng giao dịch dầu thô cao, các thương nhân có nhiều cơ hội tham gia giao dịch hơn. Dầu thô, cũng như nhiều tài sản khác, chịu ảnh hưởng của cung và cầu.`,
          },
          {
            title: `Brent`,
            text: `Brent là một loại dầu thô ngọt, nhẹ có nguồn gốc từ Biển Bắc. Nó còn được gọi là dầu Brent và được coi là giá chuẩn chính để mua dầu trên toàn thế giới.`,
          },
          {
            title: `Khí tự nhiên`,
            text: `Khí đốt tự nhiên là một trong những nguồn năng lượng sạch và an toàn nhất trên thế giới. Trong mùa đông, khí đốt tự nhiên có nhu cầu cao để đáp ứng nhu cầu sưởi ấm cho thương mại và dân cư. Đây là một trong những mặt hàng giao dịch toàn cầu hấp dẫn nhất trên thị trường.`,
          },
        ],
      },
    ],
    sharesDesc: `Giao dịch giá cổ phiếu của các tập đoàn lớn trên các sàn giao dịch hàng đầu thế giới. Hợp đồng chênh lệch (CFD) được giao dịch ký quỹ cho phép các nhà giao dịch nhân lợi nhuận tương ứng của họ. Ngay cả những biến động thị trường nhỏ cũng có thể dẫn đến lãi hoặc lỗ. Khi thị trường đang có xu hướng đi xuống, các nhà giao dịch có thể bán khống. TrioMarkets™ hiện cung cấp đòn bẩy 1:5 đối với CFD Vốn chủ sở hữu khi đăng ký.`,
    sharesPopular: `Cổ phiếu phổ biến`,
    sharesInfo: [
      {
        moreInfo: `Thông tin thêm về Cổ phiếu`,
        text: `Giá cổ phiếu phần lớn được xác định bởi lực cung và cầu. Một công ty thể hiện lợi nhuận tiềm năng trong dài hạn có thể thu hút nhiều nhà đầu tư mua cổ phiếu của họ hơn, do đó làm tăng giá cổ phiếu. Một công ty có triển vọng giảm giá, ngược lại tay, có thể lôi kéo nhiều người bán hơn người mua, điều này có thể dẫn đến áp lực giảm giá của họ. `,
        textTwo: `Các yếu tố khác có thể gây ra sự biến động giá và gây ra những thay đổi đột ngột hoặc nhanh chóng về giá. Một số ví dụ về điều này bao gồm các sự kiện chính trị, báo cáo tài chính và tin tức kinh tế. Tuy nhiên, không phải tất cả các tin tức hoặc báo cáo đều ảnh hưởng đến tất cả chứng khoán. Ví dụ: cổ phiếu của các công ty tham gia vào ngành công nghiệp ô tô có thể bị thiếu hàng tồn kho để đáp ứng nhu cầu của người mua và / hoặc giá thép tăng khiến chi phí sản xuất tăng đột biến. Giá cổ phiếu cũng có thể được thúc đẩy bởi những gì được gọi là hành vi bầy đàn, đó là xu hướng mọi người bắt chước hành động của một nhóm lớn hơn.`,
      },
    ],
  },
  enUae: {
    marketsTop: [
      { desc: "Spread from 0 pips", img: "spread" },
      {
        desc: `Leverage up to ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "High Execution Speed", img: "execution" },
      { desc: "130+ Instruments", img: "instruments" },
    ],
    forexDesc: `Foreign exchange market, often called FX which is the process of buying one currency against the selling of the other. Until very recently, FX trading was only available for large financial institutions, central banks, corporations, the very wealthy and hedge funds. Now, thanks to the internet anyone can trade currencies.`,
    forexPopular: `Popular FX Pairs`,
    forexInfo: [
      {
        moreInfo: "More info about Forex",
        why: "Why Trade Currencies?",
        points: [
          {
            text: "Most traded market in the world with a daily volume of $6,6 trillion as of 2020",
            img: "1",
          },
          { text: "Tight spreads / Lightning fast execution", img: "2" },
          {
            text: "Forex Market presents many trading opportunities in rising and falling market price",
            img: "3",
          },
        ],
        text: "<strong>Forex is known to be the most common form of online trading and at TrioMarkets™, we offer <br> you 60+ of your favorite currency pairs tradable on your customizable MT4 platform.</strong>",
        pointsTwo: [
          {
            title: "Major",
            text: "Major currency pairs are the most liquid pairs to trade which include the US Dollar. Such as the EUR/ USD and the USD/JPY.",
          },
          {
            title: "Minor",
            text: "Minor currency pairs do not have the USD and are known as crosses. Such as the EUR/GBP or the EUR/JPY.",
          },
          {
            title: "Exotic",
            text: "Exotic currency pairs can also be traded with us such as, the USD/NOK. These currency pairs are made up of a major currency pair coupled with the currency of a developing economy.",
          },
        ],
        pointsThree: [
          {
            text: "Trading on FX means that you are buying the difference or spread between the two currencies.",
          },
          {
            text: "Daily volatility for most currencies is usually small. In general, world currencies move less than a percentage point during a trading day. This makes the FX market one of the least volatile markets in the world.",
          },
          {
            text: "With <strong>TrioMarkets™</strong> we ensure that all transactions are fully automated which means your trades are executed efficiently and settled instantly. We call this “fast reliable trading”.",
          },
          {
            text: "100% automated with no re-quotes or rejections unless upper and lower bounds are set to protect against price volatility. No dealing desk or algorithmic software intervention to push trades to the broker’s favor.",
          },
        ],
      },
    ],

    indicesDesc: `An index is a portfolio of top company stocks from a particular country that track the performance of their market or exchange. A stock index is a very important part of our financial world.`,
    indicesPopular: `Popular Indices`,
    indicesInfo: [
      {
        moreInfo: "More info about Indices",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor’s 500 is an American stock market index which is an index of 500 stocks. <br> The S&P 500 was developed and continues to be maintained by S&P Dow Jones. It is designed to be a leading indicator of US equities.`,
          },
          {
            title: `FTSE 100`,
            text: `The Financial Times Stock Exchange 100 Index represents the largest 100 qualifying UK companies traded on the London Stock Exchange with the highest market capitalization.`,
          },
          {
            title: `DOW JONES`,
            text: `The Dow Jones Industrial Average was created to provide a clear view of the stock market and therefore the US economy. <br> It consists of 30 component companies that are manufacturers of industrial and consumer goods.`,
          },
          {
            title: `DAX`,
            text: `One of the most popular indices worldwide, DAX is a German stock index which consists of the 30 main German companies which are trading on the Frankfurt stock exchange. <br> DAX is mainly calculated as a performance index, making the DAX index unique.`,
          },
          {
            title: `NASDAQ`,
            text: `The National Association of Securities Dealers Automated Quotations (known as Nasdaq) is an American stock exchange that is ranked the second largest exchange in the world by market capitalization.`,
          },
          {
            title: `CAC40`,
            text: `CAC 40 Is a benchmark index for the Paris stock exchange as it reflects the performance of the 40 largest and most actively traded shares listed on Euronext Paris. <br> It accounts for 20% of market capitalization in the Eurozone and is the most widely used Index.`,
          },
          {
            title: `EUROPE50`,
            text: `The STOXX Europe 50 is a stock index of European stocks designed by STOXX Ltd., an index provider owned by Deutsche Börse Group and SIX Group. The index was introduced in February 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `The IBEX 35 (IBerian IndEX) is the benchmark stock market index of the Bolsa de Madrid, Spain's principal stock exchange. Initiated in 1992, the index is administered and calculated by Sociedad de Bolsas. It is a market capitalization weighted index comprising the 35 most liquid Spanish stocks traded in the Madrid Stock Exchange General Index and is reviewed twice.`,
          },
        ],
      },
    ],

    metalsDesc: `"A precious metal is deemed precious because it is rare and has a high economic value." Metals are a physical commodity that have always played an important role in history and will continue to be valued. Demand for these metals has increased around the world, driving prices up in recent years`,
    metalsPopular: `Popular Metals`,
    metalsInfo: [
      {
        moreInfo: `More info about Metals`,
        points: [
          {
            title: `Silver`,
            text: `Silver is an extremely volatile metal. <br> Because silver is widely used in jewellery and consumer goods, supply and demand have a significant impact on price movement in this commodity.`,
          },
          {
            title: `Gold`,
            text: `Gold is the most popular commodity traded. <br> Gold has always been a favourite asset among investors during times of market uncertainty and financial crisis.`,
          },
          {
            title: `Palladium`,
            text: `Palladium is recognised as a chemically stable metal. <br> It is one of the most important international commodities and is up to 30 times rarer than gold.`,
          },
          {
            title: `Platinum`,
            text: `Platinum is one of the world's rarest metals. <br> Platinum has very special properties that are in high demand in a variety of industries. This has resulted in unpredictable supply and demand fluctuations.`,
          },
        ],
      },
    ],

    cryptosDesc: `Trade the most popular CFDs in Cryptos today! TrioMarkets™ is a licensed and regulated broker that offers CFDs in the Cryptos trade.`,
    cryptosPopular: `Popular Cryptocurrencies`,
    cryptosInfo: [
      {
        why: `Why <strong>CFDs</strong> on <strong>Cryptos</strong> is a good alternative <br> to physically buying cryptos?`,
        points: [
          {
            img: `shield`,
            text: `No Chance of <br> Cryptocurrency <br> <strong>Wallet Hacks</strong>`,
          },
          {
            img: `cloud`,
            text: `No Need of <br> <strong>Actual Cold Storage</strong> <br> for the Cryptos`,
          },
          {
            img: `portfolio`,
            text: `A <strong>Reliable</strong> and <strong>Regulated</strong> <br> broker pays out profits for its <br> clients`,
          },
          {
            img: `security`,
            text: `Providing <strong>Security</strong> <br> of the clients’ funds. <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> is a member of the <br>Investor Compensation Fund</span>`,
          },
        ],
        moreInfo: `More info about Cryptos`,
        pointsTwo: [
          {
            img: `btc`,
            title: `Bitcoin CFD`,
            text: `The first and largest cryptocurrency, Bitcoin paved the way for all the rest of hundreds of cryptocurrencies with a market cap of over $100 billion.`,
          },
          {
            img: `eth`,
            title: `Ethereum CFD`,
            text: `The undoubted world’s second-largest cryptocurrency, following Bitcoin. A large number of established well-known organizations have actively supported Ethereum few of which are Microsoft, JP Morgan, and Intel.`,
          },
          {
            img: `ltc`,
            title: `Litecoin CFD`,
            text: `Designed by a former Google engineer to improve upon Bitcoin’s technology, based on an open-source cryptographic protocol is ultimately decentralized, offers quicker processing using the SegWit speeding method for transaction times without compromising the underlying blockchain technology.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple is based on a distributed open-source protocol that supports tokens and functions as both a transaction network and a crypto token. The most popular cryptocurrency among banks and a means of global money transfers has experienced a period of growth.`,
          },
        ],
      },
    ],

    energiesDesc: `A commodity is a basic good that is interchangeable with other commodities of the same type. Commodities are often inputs used in production for other goods and services. Another definition for a commodity is any good exchanged for commerce. This includes any product that is traded on a commodity exchange like the CBOE (Chicago Board Options Exchange).`,
    energiesPopular: `Popular Energies`,
    energiesInfo: [
      {
        moreInfo: `More info about Energies`,
        points: [
          {
            title: `Crude Oil`,
            text: `Oil holds a significant position in the global economic and political systems. Because of the high volume of trading in crude oil, traders have more opportunities to enter a trade. Crude oil, like many other assets, is influenced by supply and demand.`,
          },
          {
            title: `Brent`,
            text: `Brent is a sweet, light crude oil derived from the North Sea. It is also known as Brent petroleum and serves as a major benchmark price for purchasing oil around the world.`,
          },
          {
            title: `Natural Gas`,
            text: `Natural gas is one of the world's cleanest and safest energy sources. During the winter, natural gas is in high demand to meet commercial and residential heating needs. It is one of the most appealing globally traded commodities on the market.`,
          },
        ],
      },
    ],

    sharesDesc: `Trade the stock prices of major corporations on the world's leading exchanges. Contracts For Difference (CFDs) are traded on margin which allow traders to multiply their respective returns. Even minor market movements can result in profit or loss. When the markets are trending downward, traders can go short. TrioMarkets ™ currently provides 1:5 leverage on Equity CFDs upon sign-up.`,
    sharesPopular: `Popular Shares`,
    sharesInfo: [
      {
        moreInfo: `More info about Shares`,
        text: `Share prices are largely determined by the forces of supply and demand. A company that exhibits potential long-term earnings may attract more investors to buy their shares, thereby having an increase in share prices. A company with a bearish outlook, on the other hand, may entice more sellers than buyers, which can result in downward pressure on their prices.`,
        textTwo: `Other factors can cause price volatility and cause sudden or brief changes in price. Some examples of this include political events, financial reports and economic news. However, not all news or reports affect all securities. For example, the stocks of companies engaged in the automobile industry, may suffer from a lack of inventory to meet buyers demand and/or a price increase in steel, which will cause an unexpected increase in the production costs. Stock prices can also be driven by what is known as herd behaviour, which is the tendency for people to copycat the action of a larger group.`,
      },
    ],
  },
  arb: {
    marketsTop: [
      { desc: "سبريد من 0 نقطة", img: "spread" },
      {
        desc: `رافعة مالية تصل إلى 500: 1`,
        img: "leverage",
      },
      { desc: "سرعة تنفيذ عالية", img: "execution" },
      { desc: "أكثر من 130 أداة", img: "instruments" },
    ],
    forexDesc: `سوق الصرف الأجنبي ، غالبًا ما يطلق عليه FX وهي عملية شراء عملة مقابل بيع عملة أخرى. حتى وقت قريب جدًا ، كان تداول العملات الأجنبية متاحًا فقط للمؤسسات المالية الكبيرة والبنوك المركزية والشركات وصناديق التحوط والأثرياء جدًا. الآن ، بفضل الإنترنت يمكن لأي شخص تداول العملات.`,
    forexPopular: `أزواج العملات الأجنبية الشائعة`,
    forexInfo: [
      {
        moreInfo: "فوركس المعلومات حول فوريكس",
        why: "لماذا يتم تداول العملات؟",
        points: [
          {
            text: "السوق الأكثر تداولًا في العالم بحجم يومي يبلغ 6.6 تريليون دولار اعتبارًا من عام 2020",
            img: "1",
          },
          { text: "سبريد ضيق / تنفيذ سريع", img: "2" },
          {
            text: "يقدم سوق الفوريكس العديد من فرص التداول في ارتفاع وانخفاض سعر السوق",
            img: "3",
          },
        ],
        text: "<strong> من المعروف أن الفوركس هو الشكل الأكثر شيوعًا للتداول عبر الإنترنت<br> في TrioMarkets نقدم لك أكثر من 60 زوج من أزواج العملات المفضلة لديك والقابلة للتداول عبر منصة MT4 القابلة للتخصيص</strong>",
        pointsTwo: [
          {
            title: "رئيسي",
            text: "أزواج العملات الرئيسية هي أزواج العملات الأكثر سيولة للتداول والتي تشمل الدولار الأمريكي. مثل زوج يورو / دولار أمريكي و دولار أمريكي / ين ياباني.",
          },
          {
            title: "ثانوي",
            text: "أزواج العملات الصغرى لا تملك الدولار الأمريكي وتعرف باسم أزواج العملات. مثل EUR / GBP أو EUR / JPY.",
          },
          {
            title: "غريب",
            text: "يمكن أيضًا تداول أزواج العملات النادرة معنا مثل USD / NOK. تتكون أزواج العملات هذه من زوج عملات رئيسي مقترن بعملة اقتصاد نامٍ.",
          },
        ],
        pointsThree: [
          {
            text: "التداول في العملات الأجنبية يعني أنك تشتري الفارق أو السبريد بين العملتين.",
          },
          {
            text: "عادة ما تكون التقلبات اليومية لمعظم العملات صغيرة. بشكل عام ، تتحرك العملات العالمية أقل من نقطة مئوية خلال يوم التداول. هذا يجعل سوق العملات الأجنبية أحد أقل الأسواق تقلبًا في العالم.",
          },
          {
            text: `مع <strong>TrioMarkets™</strong> نضمن أن جميع المعاملات مؤتمتة بالكامل مما يعني أن تداولاتك يتم تنفيذها بكفاءة وتسويتها على الفور. نسمي هذا "تداول سريع وموثوق".`,
          },
          {
            text: "آلية بنسبة 100٪ بدون إعادة تسعير أو رفض ما لم يتم تعيين الحدود العليا والسفلى للحماية من تقلبات الأسعار. لا يوجد مكتب تداول أو تدخل برمجي خوارزمي لدفع التداولات لصالح الوسيط.",
          },
        ],
      },
    ],

    indicesDesc: `المؤشر عبارة عن مجموعة من أهم أسهم الشركات من بلد معين والتي تتعقب أداء السوق أو البورصة. مؤشر الأسهم هو جزء مهم جدًا من عالمنا المالي.`,
    indicesPopular: `المؤشرات المعروفة`,
    indicesInfo: [
      {
        moreInfo: "مزيد من المعلومات حول المؤشرات",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor’s 500 هو مؤشر سوق الأسهم الأمريكية وهو مؤشر يضم 500 سهم. <br> تم تطوير S&P 500 وما زالت تحتفظ به S&P Dow Jones. تم تصميمه ليكون مؤشرًا رئيسيًا للأسهم الأمريكية.`,
          },
          {
            title: `FTSE 100`,
            text: `يمثل مؤشر Financial Times للأوراق المالية 100 أكبر 100 شركة بريطانية مؤهلة يتم تداولها في بورصة لندن بأعلى قيمة سوقية.`,
          },
          {
            title: `DOW JONES`,
            text: `تم إنشاء مؤشر داو جونز الصناعي لتوفير رؤية واضحة لسوق الأسهم وبالتالي الاقتصاد الأمريكي. وهي تتألف من 30 شركة مكونة من الشركات المصنعة للسلع الصناعية والاستهلاكية.`,
          },
          {
            title: `DAX`,
            text: `يعد DAX أحد أكثر المؤشرات شهرة في جميع أنحاء العالم ، وهو مؤشر أسهم ألماني يتكون من 30 شركة ألمانية رئيسية يتم تداولها في بورصة فرانكفورت للأوراق المالية. يتم حساب DAX بشكل أساسي كمؤشر أداء ، مما يجعل مؤشر DAX فريدًا.`,
          },
          {
            title: `NASDAQ`,
            text: `الرابطة الوطنية لعروض الأسعار الآلية لتجار الأوراق المالية (المعروفة باسم ناسداك) هي بورصة أمريكية مصنفة في المرتبة الثانية كأكبر بورصة في العالم من حيث القيمة السوقية.`,
          },
          {
            title: `CAC40`,
            text: `CAC 40 هو مؤشر مرجعي لبورصة باريس لأنه يعكس أداء أكبر 40 سهمًا وأكثرها تداولا مدرجة في Euronext Paris. يمثل 20٪ من القيمة السوقية في منطقة اليورو وهو المؤشر الأكثر استخدامًا.`,
          },
          {
            title: `EUROPE50`,
            text: `STOXX Europe 50 هو مؤشر للأسهم الأوروبية تم تصميمه بواسطة STOXX Ltd. ، مزود مؤشر مملوك لمجموعة Deutsche Börse Group و SIX Group. تم تقديم المؤشر في فبراير 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `IBEX 35 (IBerian IndEX) هو مؤشر سوق الأسهم القياسي لبورصة Bolsa de Madrid ، البورصة الرئيسية في إسبانيا. بدأ في عام 1992 ، ويدير المؤشر ويحسب من قبل سوسيداد دي بولساس. وهو مؤشر مرجح من حيث القيمة السوقية يضم 35 من الأسهم الإسبانية الأكثر سيولة المتداولة في المؤشر العام لبورصة مدريد ويتم مراجعته مرتين.`,
          },
        ],
      },
    ],

    metalsDesc: `"يعتبر المعدن ثمين للغاية لأنه نادر وله قيمة اقتصادية عالية." تعد المعادن سلعة مادية لعبت دائمًا دورًا مهمًا في التاريخ وسيستمر تقييمها. زاد الطلب على هذه المعادن في جميع أنحاء العالم ، مما أدى إلى ارتفاع الأسعار في السنوات الأخيرة`,
    metalsPopular: `المعادن المعروفة`,
    metalsInfo: [
      {
        moreInfo: `مزيد من المعلومات حول المعادن`,
        points: [
          {
            title: `الفضة`,
            text: `الفضة معدن شديد التقلب. نظرًا لاستخدام الفضة على نطاق واسع في المجوهرات والسلع الاستهلاكية ، فإن العرض والطلب لهما تأثير كبير على حركة الأسعار في هذه السلعة.`,
          },
          {
            title: `الذهب`,
            text: `الذهب هو السلعة الأكثر تداولاً. لطالما كان الذهب أحد الأصول المفضلة لدى المستثمرين في أوقات عدم اليقين في السوق والأزمات المالية.`,
          },
          {
            title: `البلاديوم`,
            text: `يُعرف البلاديوم بأنه معدن مستقر كيميائيًا. إنها واحدة من أهم السلع العالمية وهي أندر من الذهب بما يصل إلى 30 مرة.`,
          },
          {
            title: `البلاتينيوم`,
            text: `البلاتينيوم هو أحد أندر المعادن في العالم. يتمتع البلاتين بخصائص خاصة للغاية مطلوبة بشدة في مجموعة متنوعة من الصناعات. وقد أدى ذلك إلى تقلبات غير متوقعة في العرض والطلب.`,
          },
        ],
      },
    ],

    cryptosDesc: `تداول أكثر العقود مقابل الفروقات شيوعًا في العملات المشفرة اليوم! TrioMarkets ™ هي وسيط مرخص ومنظم يقدم عقود الفروقات في تداول العملات المشفرة.`,
    cryptosPopular: `العملات المشفرة الشعبية`,
    cryptosInfo: [
      {
        why: `لماذا تعد العقود مقابل الفروقات على العملات المشفرة بديلاً جيدًا لشراء العملات المشفرة فعليًا؟`,
        points: [
          {
            img: `shield`,
            text: `لا توجد فرصة لاختراق<strong> محفظة العملات المشفرة</strong>`,
          },
          {
            img: `cloud`,
            text: `لا حاجة <strong> للتخزين البارد الفعلي</strong> للعملات المشفرة
            `,
          },
          {
            img: `portfolio`,
            text: `وسيط <strong>موثوق ومنظم</strong> يدفع أرباحًا لعملائه`,
          },
          {
            img: `security`,
            text: `<strong>تأمين</strong> أموال العملاء.  <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> عضو في صندوق تعويض المستثمر</span>`,
          },
        ],
        moreInfo: `مزيد من المعلومات حول العملات المشفرة`,
        pointsTwo: [
          {
            img: `btc`,
            title: `بيتكوين CFD`,
            text: `"العملة المشفرة الأولى والأكبر
            مهدت Bitcoin الطريق لجميع ما تبقى من مئات العملات المشفرة بقيمة سوقية تزيد عن 100 مليار دولار"`,
          },
          {
            img: `eth`,
            title: `إيثريوم CFD`,
            text: `"ثاني أكبر عملة مشفرة في العالم بعد بيتكوين.
            دعم عدد كبير من المؤسسات العالمية عملة الإيثريوم  ومنها Microsoft و JP Morgan و Intel."`,
          },
          {
            img: `ltc`,
            title: `لايتكوين CFD`,
            text: `تم تصميمه بواسطة مهندس سابق في Google لتحسين تقنية Bitcoin ، استنادًا إلى بروتوكول تشفير مفتوح المصدر لا مركزي في النهاية ، ويوفر معالجة أسرع باستخدام طريقة SegWit للسرعة لأوقات المعاملات دون المساس بتقنية blockchain الأساسية.`,
          },
          {
            img: `xrp`,
            title: `ريبل CFD`,
            text: `يعتمد الريبل على بروتوكول مفتوح المصدر موزع يدعم الرموز المميزة ويعمل كشبكة معاملات ورمز مميز للتشفير. شهدت العملة المشفرة الأكثر شيوعًا بين البنوك ووسيلة تحويل الأموال العالمية فترة من النمو.`,
          },
        ],
      },
    ],

    energiesDesc: `السلعة هي سلعة أساسية يمكن استبدالها بسلع أخرى من نفس النوع. غالبًا ما تكون السلع عبارة عن مدخلات تستخدم في إنتاج سلع وخدمات أخرى. تعريف آخر للسلعة هو أي سلعة يتم تبادلها من أجل التجارة. يتضمن ذلك أي منتج يتم تداوله في بورصة سلع مثل CBOE (بورصة خيارات مجلس شيكاغو).`,
    energiesPopular: `أنواع طاقة معروفة`,
    energiesInfo: [
      {
        moreInfo: `مزيد من المعلومات حول الطاقات`,
        points: [
          {
            title: `زيت خام`,
            text: `يحتل النفط مكانة بارزة في الأنظمة الاقتصادية والسياسية العالمية. نظرًا لارتفاع حجم التداول في النفط الخام ، يتمتع المتداولون بفرص أكثر للدخول في التجارة. يتأثر النفط الخام ، مثل العديد من الأصول الأخرى ، بالعرض والطلب`,
          },
          {
            title: `برنت`,
            text: `برنت هو نفط خام حلو وخفيف مشتق من بحر الشمال. يُعرف أيضًا باسم نفط برنت ويعمل كسعر مرجعي رئيسي لشراء النفط حول العالم.`,
          },
          {
            title: `غاز طبيعي`,
            text: `الغاز الطبيعي هو أحد أنظف مصادر الطاقة في العالم وأكثرها أمانًا. خلال فصل الشتاء ، يزداد الطلب على الغاز الطبيعي لتلبية احتياجات التدفئة التجارية والسكنية. إنها واحدة من أكثر السلع المتداولة عالميًا في السوق.`,
          },
        ],
      },
    ],

    sharesDesc: `تداول في أسعار أسهم الشركات الكبرى في البورصات الرائدة في العالم. يتم تداول العقود مقابل الفروقات (CFDs) على الهامش الذي يسمح للمتداولين بمضاعفة عائداتهم الخاصة. حتى تحركات السوق الصغيرة يمكن أن تؤدي إلى الربح أو الخسارة. عندما تتجه الأسواق نحو الأسفل ، يمكن للمتداولين البيع. توفر TrioMarkets ™ حاليًا رافعة مالية 1: 5 على عقود الفروقات على الأسهم عند التسجيل.`,
    sharesPopular: `أسهم معروفة`,
    sharesInfo: [
      {
        moreInfo: `مزيد من المعلومات حول الأسهم`,
        text: `يتم تحديد أسعار الأسهم إلى حد كبير من قبل قوى العرض والطلب. قد تجذب الشركة التي تعرض أرباحًا محتملة على المدى الطويل المزيد من المستثمرين لشراء أسهمهم ، وبالتالي زيادة أسعار الأسهم. من ناحية أخرى ، قد تجذب الشركة ذات النظرة الهبوطية البائعين أكثر من المشترين ، مما قد يؤدي إلى ضغط هبوطي على أسعارها.`,
        textTwo: `يمكن أن تتسبب عوامل أخرى في تقلب الأسعار وتسبب تغيرات مفاجئة أو قصيرة في السعر. تتضمن بعض الأمثلة على ذلك الأحداث السياسية والتقارير المالية والأخبار الاقتصادية. ومع ذلك ، لا تؤثر جميع الأخبار أو التقارير على جميع الأوراق المالية. على سبيل المثال ، قد تعاني مخزونات الشركات العاملة في صناعة السيارات من نقص المخزون لتلبية طلب المشترين و / أو زيادة أسعار الفولاذ ، مما سيؤدي إلى زيادة غير متوقعة في تكاليف الإنتاج.يمكن أيضًا أن تكون أسعار الأسهم مدفوعة بما يعرف بسلوك القطيع ، وهو ميل الناس لتقليد عمل مجموعة أكبر.`,
      },
    ],
  },
  my: {
    marketsTop: [
      { desc: "Spread dari 0 pip", img: "spread" },
      {
        desc: `Leverage hingga ${license == "global" ? "1:500" : "1:30"}`,
        img: "leverage",
      },
      { desc: "Kelajuan Pelaksanaan yang Tinggi", img: "execution" },
      { desc: "130+ Instrumen", img: "instruments" },
    ],
    forexDesc: `Pasaran pertukaran asing, sering disebut FX yang merupakan proses membeli satu mata wang berbanding penjualan mata wang yang lain. Sehingga baru-baru ini, perdagangan FX hanya tersedia untuk institusi kewangan besar, bank pusat, syarikat, dana yang sangat kaya dan lindung nilai. Sekarang, terima kasih kepada internet, sesiapa sahaja boleh memperdagangkan mata wang.
    `,
    forexPopular: `Pasangan FX yang popular`,
    forexInfo: [
      {
        moreInfo: "Maklumat lanjut mengenai Forex",
        why: "Mengapa Mata Wang Perdagangan?",
        points: [
          {
            text: "Pasaran yang paling banyak diperdagangkan di dunia dengan jumlah harian $ 6,6 trilion pada tahun 2020",
            img: "1",
          },
          { text: "Tight spread / Kilat dengan pantas pelaksanaan", img: "2" },
          {
            text: "Pasaran Forex menyajikan banyak peluang perdagangan dalam kenaikan dan penurunan harga pasaran",
            img: "3",
          },
        ],
        text: "<strong>Forex diketahui paling banyak bentuk perdagangan dalam talian yang biasa dan di TrioMarkets ™ kami menawarkan <br> 60+ pasangan mata wang kegemaran anda yang boleh diperdagangkan di platform MT4 anda yang boleh disesuaikan.</strong>",
        pointsTwo: [
          {
            title: "Major",
            text: "Pasangan mata wang utama adalah kebanyakan pasangan cecair untuk diperdagangkan yang merangkumi Dolar AS. Seperti EUR / USD dan USD / JPY.",
          },
          {
            title: "Minor",
            text: "Pasangan mata wang kecil tidak mempunyai USD dan dikenali sebagai salib. Seperti EUR / GBP atau EUR / JPY.",
          },
          {
            title: "Exotic",
            text: "Pasangan mata wang eksotik juga boleh diperdagangkan dengan kami seperti, USD / NOK. Pasangan mata wang ini terdiri dari pasangan mata wang utama ditambah dengan mata wang ekonomi yang sedang berkembang.",
          },
        ],
        pointsThree: [
          {
            text: "Perdagangan di FX bermaksud anda membeli perbezaan atau penyebaran antara dua mata wang.",
          },
          {
            text: "Ketidakstabilan harian untuk kebanyakan mata wang biasanya kecil. Secara amnya, mata wang dunia bergerak kurang dari satu mata peratusan pada hari perdagangan. Ini menjadikan pasaran FX salah satu pasaran yang paling tidak stabil di dunia.",
          },
          {
            text: "Dengan <strong>TrioMarkets™</strong> kami memastikan bahawa semua transaksi automatik sepenuhnya yang bermaksud perdagangan anda dilaksanakan dengan cekap dan diselesaikan dengan serta-merta. Kami memanggil ini “ perdagangan pantas yang boleh dipercayai ”.",
          },
          {
            text: "100% automatik tanpa sebut harga semula atau penolakan kecuali batas atas dan bawah ditetapkan untuk melindungi daripada turun naik harga. Tidak ada campur tangan meja urus niaga atau perisian algoritma untuk mendorong perdagangan memihak kepada broker.",
          },
        ],
      },
    ],

    indicesDesc: `Indeks adalah portfolio teratas 
    stok syarikat dari negara tertentu yang mengesan prestasi pasaran atau pertukaran mereka. Indeks saham adalah bahagian yang sangat penting dalam dunia kewangan kita.`,
    indicesPopular: `Indeks Popular`,
    indicesInfo: [
      {
        moreInfo: "Maklumat lebih lanjut mengenai Indeks",
        points: [
          {
            title: `S&P 500`,
            text: `Standard & Poor's 500 adalah Indeks pasaran saham Amerika yang merupakan indeks 500 saham. <br> S&P 500 dikembangkan dan terus dikekalkan oleh S&P Dow Jones. Ia dirancang untuk menjadi petunjuk utama ekuiti AS.`,
          },
          {
            title: `FTSE 100`,
            text: `Saham Masa Kewangan Exchange 100 Index mewakili 100 syarikat UK yang memenuhi syarat terbesar yang diperdagangkan di Bursa Saham London dengan permodalan pasaran tertinggi.`,
          },
          {
            title: `JON DOW`,
            text: `Industri Dow Jones Purata diciptakan untuk memberikan pandangan yang jelas mengenai pasaran saham dan oleh itu ekonomi AS. Ia terdiri daripada 30 syarikat komponen yang merupakan pengeluar barang industri dan pengguna.`,
          },
          {
            title: `DAX`,
            text: `Salah satu yang paling popular indeks di seluruh dunia, DAX adalah indeks saham Jerman yang terdiri daripada 30 syarikat utama Jerman yang berdagang di bursa saham Frankfurt. <br> DAX terutamanya dikira sebagai indeks prestasi, menjadikan indeks DAX unik.`,
          },
          {
            title: `NASDAQ`,
            text: `Persatuan Nasional Penawaran Sekuriti Automatik ( dikenali sebagai Nasdaq ) adalah bursa saham Amerika yang berada di kedudukan pertukaran kedua terbesar di dunia dengan permodalan pasaran.`,
          },
          {
            title: `CAC40`,
            text: `CAC 40 Merupakan indeks penanda aras untuk bursa saham Paris kerana ia mencerminkan prestasi 40 saham terbesar dan paling aktif yang disenaraikan di Euronext Paris. Ini menyumbang 20% permodalan pasaran di zon Euro dan merupakan Indeks yang paling banyak digunakan.`,
          },
          {
            title: `EUROPE50`,
            text: `STOXX Europe 50 adalah indeks saham saham Eropah yang direka oleh STOXX Ltd., penyedia indeks yang dimiliki oleh Deutsche Börse Group dan SIX Group. Indeks ini diperkenalkan pada Februari 1998.`,
          },
          {
            title: `SPAIN35`,
            text: `IBEX 35 ( IBerian IndEX ) adalah indeks pasaran saham penanda aras Bolsa de Madrid, bursa saham utama Sepanyol. Dimulakan pada tahun 1992, indeks ditadbir dan dikira oleh Sociedad de Bolsas. Ini adalah indeks berwajaran kapitalisasi pasaran yang terdiri daripada 35 saham Sepanyol paling cair yang diperdagangkan dalam Indeks Umum Bursa Saham Madrid dan dikaji dua kali.`,
          },
        ],
      },
    ],

    metalsDesc: `Hasil logam berharga dianggap berharga kerana jarang berlaku dan mempunyai nilai ekonomi yang tinggi. Logam adalah komoditi fizikal yang selalu memainkan peranan penting dalam sejarah dan akan terus dihargai. Permintaan untuk logam ini telah meningkat di seluruh dunia, menaikkan harga dalam beberapa tahun kebelakangan ini"`,
    metalsPopular: `Logam Popular`,
    metalsInfo: [
      {
        moreInfo: `Maklumat lebih lanjut mengenai Logam`,
        points: [
          {
            title: `Perak`,
            text: `Perak adalah sangat logam yang tidak menentu. Kerana perak banyak digunakan dalam barang kemas dan barang pengguna, penawaran dan permintaan memberi kesan yang signifikan terhadap pergerakan harga dalam komoditi ini.`,
          },
          {
            title: `Emas`,
            text: `Emas adalah yang paling popular komoditi diperdagangkan. Emas selalu menjadi aset kegemaran di kalangan pelabur semasa ketidaktentuan pasaran dan krisis kewangan.`,
          },
          {
            title: `Paladium`,
            text: `Palladium diiktiraf sebagai logam stabil secara kimia. <br> Ini adalah salah satu komoditi antarabangsa yang paling penting dan hingga 30 kali lebih jarang daripada emas.`,
          },
          {
            title: `Platinum`,
            text: `Platinum adalah salah satu daripada logam paling jarang di dunia. Platinum mempunyai sifat yang sangat istimewa yang sangat diminati dalam pelbagai industri. Ini telah mengakibatkan turun naik penawaran dan permintaan yang tidak dapat diramalkan.`,
          },
        ],
      },
    ],

    cryptosDesc: `Berdagang CFD yang paling popular di Cryptos hari ini! TrioMarkets ™ adalah broker berlesen dan terkawal yang menawarkan CFD dalam perdagangan Cryptos.`,
    cryptosPopular: `Cryptocurrency popular`,
    cryptosInfo: [
      {
        why: `Mengapa <strong>CFDs</strong> di <strong>Cryptos</strong> adalah baik alternatif <br> untuk membeli cryptos secara fizikal?`,
        points: [
          {
            img: `shield`,
            text: `Tiada Peluang <br> Cryptocurrency <br> <strong>Hacks Dompet</strong>`,
          },
          {
            img: `cloud`,
            text: `Tidak Perlu <br> <strong>Penyimpanan Sejuk Sebenar</strong> <br> untuk Cryptos`,
          },
          {
            img: `portfolio`,
            text: `<strong>Boleh dipercayai</strong> dan <strong>Diatur</strong> <br> broker membayar keuntungan untuk <br> pelanggannya`,
          },
          {
            img: `security`,
            text: `Memberikan <strong>Keselamatan</strong> <br> pelanggan ’ dana. <br> <span style="font-size: .7rem; display: block; line-height: 1.2; padding-top: .2rem;"><strong>TrioMarkets&trade;</strong> adalah ahli <br> Dana Pampasan Pelabur</span>`,
          },
        ],
        moreInfo: `Maklumat lanjut mengenai Cryptos`,
        pointsTwo: [
          {
            img: `btc`,
            title: `CFD Bitcoin`,
            text: `Yang pertama dan terbesar cryptocurrency, Bitcoin membuka jalan untuk semua ratusan cryptocurrency yang lain dengan had pasaran lebih dari $ 100 bilion`,
          },
          {
            img: `eth`,
            title: `CFD Ethereum`,
            text: `Dunia yang tidak diragukan lagi cryptocurrency kedua terbesar, mengikuti Bitcoin. Sebilangan besar organisasi terkenal yang telah aktif menyokong Ethereum yang sebahagiannya adalah Microsoft, JP Morgan, dan Intel.
            `,
          },
          {
            img: `ltc`,
            title: `Litecoin CFD`,
            text: `Direka oleh bekas Google jurutera untuk meningkatkan teknologi Bitcoin, berdasarkan protokol kriptografi sumber terbuka akhirnya terdesentralisasi, menawarkan pemprosesan yang lebih cepat menggunakan kaedah kelajuan SegWit untuk masa transaksi tanpa menjejaskan teknologi blockchain yang mendasari.`,
          },
          {
            img: `xrp`,
            title: `Ripple CFD`,
            text: `Ripple berdasarkan pada a protokol sumber terbuka yang diedarkan yang menyokong token dan berfungsi sebagai rangkaian transaksi dan token crypto. Mata wang kript yang paling popular di kalangan bank dan kaedah pemindahan wang global telah mengalami tempoh pertumbuhan.`,
          },
        ],
      },
    ],

    energiesDesc: `Komoditi adalah barang asas yang boleh ditukar ganti dengan komoditi lain dari jenis yang sama. Komoditi sering menjadi input yang digunakan dalam pengeluaran untuk barang dan perkhidmatan lain. Definisi lain untuk komoditi adalah pertukaran yang baik untuk perdagangan. Ini termasuk produk yang diperdagangkan di bursa komoditi seperti CBOE ( Chicago Board Options Exchange ).`,
    energiesPopular: `Tenaga Popular`,
    energiesInfo: [
      {
        moreInfo: `Maklumat lanjut mengenai Energies`,
        points: [
          {
            title: `Minyak mentah`,
            text: `Minyak memegang kedudukan penting dalam sistem ekonomi dan politik global. Kerana jumlah perdagangan minyak mentah yang tinggi, peniaga mempunyai lebih banyak peluang untuk memasuki perdagangan. Minyak mentah, seperti banyak aset lain, dipengaruhi oleh penawaran dan permintaan`,
          },
          {
            title: `Brent`,
            text: `Brent adalah minyak mentah yang manis dan ringan berasal dari Laut Utara. Ia juga dikenal sebagai petroleum Brent dan berfungsi sebagai harga penanda aras utama untuk membeli minyak di seluruh dunia.`,
          },
          {
            title: `Gas Asli`,
            text: `Gas asli adalah salah satu dunia sumber tenaga paling bersih dan paling selamat. Semasa musim sejuk, gas asli sangat diminati untuk memenuhi keperluan pemanasan komersial dan kediaman. Ini adalah salah satu komoditi yang diperdagangkan secara global yang paling menarik di pasaran.`,
          },
        ],
      },
    ],

    sharesDesc: `Berdagang harga saham syarikat besar di bursa terkemuka di dunia. Kontrak Untuk Perbezaan ( CFD ) diperdagangkan pada margin yang membolehkan peniaga menggandakan pulangan masing-masing. Malah pergerakan pasaran kecil boleh mengakibatkan keuntungan atau kerugian. Apabila pasaran cenderung ke bawah, peniaga boleh menjadi pendek. TrioMarkets ™ kini memberikan leverage 1: 5 pada Ekuiti CFD semasa mendaftar.`,
    sharesPopular: `Saham Popular`,
    sharesInfo: [
      {
        moreInfo: `Maklumat lebih lanjut mengenai Saham`,
        text: `Harga saham sebahagian besarnya ditentukan oleh kekuatan penawaran dan permintaan. Syarikat yang mempamerkan pendapatan jangka panjang yang berpotensi dapat menarik lebih banyak pelabur untuk membeli saham mereka, sehingga mengalami kenaikan harga saham. Sebaliknya, syarikat dengan pandangan menurun, mungkin menarik lebih banyak penjual daripada pembeli, yang dapat mengakibatkan tekanan harga mereka turun.`,
        textTwo: `Faktor lain boleh menyebabkan turun naik harga dan menyebabkan perubahan harga secara tiba-tiba atau singkat. Beberapa contohnya termasuk peristiwa politik, laporan kewangan dan berita ekonomi. Walau bagaimanapun, tidak semua berita atau laporan mempengaruhi semua sekuriti. Sebagai contoh, stok syarikat yang terlibat dalam industri automobil, mungkin mengalami kekurangan inventori untuk memenuhi permintaan pembeli dan / atau kenaikan harga baja, yang akan menyebabkan kenaikan kos pengeluaran yang tidak dijangka. Harga saham juga boleh didorong oleh apa yang dikenali sebagai tingkah laku kawanan, yang merupakan kecenderungan orang untuk menyalin tindakan kumpulan yang lebih besar.`,
      },
    ],
  },
};
