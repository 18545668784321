<template>
  <div class="slider container-fluid p-0 position-relative">
    <Carousel :wrap-around="true" class="w-100">
      <slide
        v-for="(slide, idx) in sliderFilter"
        :key="idx"
        :style="`background-image: url('/img/slider/${checkArb ? 'arb/' : ''}${
          slide.img
        }.jpg')`"
        :class="`${checkArb ? 'rtl' : ''}`"
      >
        <div v-slidein class="row slide-bg w-100">
          <div
            class="col-12 col-md-7 col-lg-12 px-0 d-flex flex-column justify-content-center"
          >
            <h2
              :class="` fs-1 fw-bold text-gold mb-4 ${
                checkArb ? 'text-end' : 'text-start'
              }`"
              v-html="slide.title"
            ></h2>
            <div
              v-for="(desc, idx) in filterDescription(slide.desc)"
              :key="idx"
              class="d-flex flex-row align-items-center mb-3"
            >
              <picture v-if="slide.img != 'MobileAppSlider'">
                <img
                  src="/img/check.svg"
                  :class="`img-fluid mt-1 check ${checkArb ? 'ms-3' : ' me-3'}`"
                  alt="check"
                />
              </picture>
              <p class="m-0 text-start fs-5 text-white" v-html="desc.text"></p>
            </div>

            <div :class="checkArb ? 'text-end' : 'text-start'">
              <a
                v-if="slide.license !== 'uaeLang'"
                target="_blank"
                :href="slide.link ?? registerURL"
                class="btn btn-blue mt-4"
                >{{ $t(slide.button) }}</a
              >
            </div>

            <!-- <div :class="checkArb ? 'text-end' : 'text-start'">
              <a
                v-if="slide.license !== 'uaeLang'"
                target="_blank"
                :href="registerURL"
                class="btn btn-blue mt-4"
                >{{ $t("openAccount") }}</a
              >
              <a
                v-if="slide.license == 'uaeLang'"
                target="_blank"
                :href="`https://landing.triomarkets.com/${selectedLang}/ramadan-offer`"
                class="btn btn-blue mt-4"
                >{{ $t("goToOffer") }}</a
              >
            </div> -->
          </div>
        </div>
      </slide>
      <template #addons>
        <pagination class="position-absolute" />
      </template>
    </Carousel>
    <div class="row features position-absolute bottom-0 start-0 m-0 w-100">
      <div
        class="col-2 py-lg-3 py-2 px-lg-auto px-0"
        v-for="(feature, idx) in $tm('features')"
        :key="idx"
      >
        <p
          class="d-flex flex-column align-items-center justify-content-center text-center text-white fs-6 px-lg-1 lh-1 mb-0"
        >
          <span class="fs-2 mb-1"
            ><span class="text-gold">{{ feature.symb }}</span>
            <number
              ref="number1"
              :from="0"
              :to="
                selectedLang == 'my' && feature.first == '500'
                  ? '2000'
                  : feature.first
              "
              :format="theFormat"
              :duration="5"
              :delay="0"
              easing="Power1.easeOut"
              class="number-animation text-gold"
            /><span class="text-gold">{{ feature.more }}</span></span
          >

          {{ feature.second }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination } from "vue3-carousel";

export default {
  name: "Slider",
  data: () => ({}),
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  computed: {
    license() {
      return getters.license();
    },
    registerURL() {
      return getters.registerURL();
    },
    selectedLang() {
      return getters.selectedLang();
    },
    sliderFilter() {
      if (this.license == "eu" || this.license == "africa") {
        return this.$tm("slides").filter((slide) => {
          return (
            slide.img !== "promo" &&
            slide.img != "stock" &&
            slide.img != "partner"
          );
        });
      } else if (this.license == "global") {
        if (this.selectedLang === "enUae" || this.selectedLang === "arb") {
          return this.$tm("slides").filter((slide) => {
            return slide.img !== "stock";
          });
        } else {
          return this.$tm("slides");
        }
      } else {
        return this.$tm("slides");
      }
      // test
      // if (this.license == "eu") {
      //   return this.$tm("slides").filter((slide) => {
      //     return slide.img !== "promo";
      //   });
      // } else if (
      //   this.license == "global" &&
      //   this.selectedLang !== "enUae" &&
      //   this.selectedLang !== "arb"
      // ) {
      //   return this.$tm("slides").filter((slide) => {
      //     return slide.img !== "stock";
      //   });
      // } else {
      //   return this.$tm("slides").filter((slide) => {
      //     return slide.img !== "metals";
      //   });
      // }
    },
    checkArb() {
      return getters.checkArb();
    },
    checkUae() {
      return getters.checkUae();
    },
  },
  methods: {
    theFormat(number) {
      if (number == "0.0") {
        return number.toFixed(1);
      } else {
        return number.toFixed(0);
      }
    },
    completed() {
      console.log("Animation ends!");
    },
    playAnimation() {
      this.$refs.number2.play();
    },
    filterDescription(slide) {
      if (this.license === "eu") {
        return slide.filter((item) => item.show.includes(this.license));
      }
      return slide;
    },
  },
};
</script>
<style lang="scss" scoped>
.ramadan-title {
  width: 60%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
</style>
