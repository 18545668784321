<template>
  <div v-for="(item, idx) in $tm('trioXtendInfo')" :key="idx">
    <div class="container-fluid container-top accounts">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("xTend") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-8 col-md-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-4 d-lg-block d-none text-center">
            <img
              src="/img/platforms-menu/xtend/trioxtend.png"
              alt="imac"
              class="img-fluid my-4"
            />
          </div>
          <div class="col-12 text-center my-lg-2 my-5">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{
              $t("enjoyTrioxtend")
            }}</a>
          </div>
        </div>
        <div class="row d-flex justify-content-center mt-3">
          <div class="col-lg-12 col-md-8">
            <div class="row d-flex justify-content-center">
              <div
                v-for="(point, idx) in item.points"
                :key="idx"
                class="col-lg-2 col-sm-4 col-6 d-flex flex-column text-center mb-md-0 mb-3"
              >
                <img
                  :src="`/img/platforms-menu/xtend/${point.img}.svg`"
                  class="img-fluid icon my-2"
                  alt="icon"
                />
                <p
                  class="text-center text-white fs-5 lh-sm"
                  v-html="point.name"
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">
          {{ $t("keyFeatures") }}
        </h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10">
          <div class="row d-flex justify-content-center" v-scrollanimation>
            <div
              v-for="(point, idx) in item.pointsTwo[0]"
              :key="idx"
              class="col-md-6 col-sm-10 col-11 d-flex align-items-center my-2 points"
            >
              <div class="forex-box boxes-xtend text-center">
                <img
                  :src="`/img/platforms-menu/xtend/${point.img}.svg`"
                  class="img-fluid icon my-2"
                  alt="icon"
                />
                <p class="mb-lg-0 fs-5 lh-sm" v-html="point.text"></p>
              </div>
            </div>
            <div v-if="!showFeatures" class="col-12 text-center">
              <a
                @click.prevent="showFeatures = !showFeatures"
                class="btn btn-blue my-4 text-white"
                >{{ $t("showMore") }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="showFeatures"
        class="row d-flex justify-content-center"
        v-scrollanimation
      >
        <div class="col-lg-10">
          <div class="row d-flex justify-content-center">
            <div
              v-for="(point, idx) in item.pointsTwo[1]"
              :key="idx"
              class="col-md-6 col-sm-10 col-11 d-flex align-items-center my-2 points"
            >
              <div class="forex-box xtend text-center">
                <img
                  :src="`/img/platforms-menu/xtend/${point.img}.svg`"
                  class="img-fluid icon my-2"
                  alt="check icon"
                />
                <p class="mb-lg-0 fs-5 lh-sm" v-html="point.text"></p>
              </div>
            </div>
            <div class="col-12 text-center">
              <a
                @click.prevent="showFeatures = !showFeatures"
                @click="scrollToTop"
                class="btn btn-blue my-4 text-white"
                >{{ $t("showLess") }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid black-box xtend py-5 my-3">
      <div class="container">
        <div class="row">
          <div
            class="col-lg-6 d-flex align-items-center justify-content-center"
          >
            <img
              src="/img/platforms-menu/xtend/xtend_logo.png"
              alt="xtend-logo"
              class="img-fluid mb-lg-0 mb-5"
            />
          </div>
          <div class="col-lg-6 xtend-text">
            <h4 class="text-white text-uppercase">{{ item.title }}</h4>
            <p class="text-white" v-html="item.textTwo"></p>
            <a
              target="_blank"
              :href="`/img/platforms-menu/xtend/docs/TrioXtend_Manuals-TrioMarkets_${
                license == 'global' ? 'MU' : 'EU'
              }.zip`"
              class="btn btn-blue"
              >{{ $t("getAllTrioxtendManuals") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps stepStyle="dark" v-scrollanimation />
  </div>
</template>
<script>
import { getters, mutations } from "../store";
export default {
  name: "TrioXtend",
  data: () => ({
    showFeatures: false,
  }),
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    license() {
      return getters.license();
    },
  },
  mounted() {
    mutations.setBoxHeight("boxes-xtend", "0");
    window.onresize = () => {
      mutations.setBoxHeight("boxes-xtend", "0");
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 1000,
        behavior: "smooth",
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
