<template>
  <div
    v-for="(item, idx) in $tm('fundingWithdrawalsInfo')"
    :key="idx"
    id="fundWithdraw"
  >
    <div class="container-fluid container-top accounts">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("fundingWithdrawals") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center mb-1">
          <div
            class="col-lg-10 col-12 text-center text-white"
            v-html="item.text"
          ></div>
        </div>
        <div
          v-if="license == 'eu'"
          class="row animate__animated animate__slideInUp"
        >
          <div class="col-12">
            <carousel-3d
              :perspective="0"
              :height="550"
              :width="320"
              :space="300"
              :display="5"
            >
              <slide
                v-for="(slide, idx) in item.cardsEU"
                :key="idx"
                :index="idx"
              >
                <div class="box d-flex flex-column align-items-center">
                  <div
                    class="image-box d-flex align-items-center justify-content-center m-3"
                  >
                    <img
                      v-for="img in slide.img"
                      :key="img"
                      :src="`/img/accounts/${img}.svg`"
                      class="icon img-fluid"
                    />
                  </div>
                  <hr class="blue fw mbhr" />
                  <div v-if="idx !== 4 && slide.psp" class="col-12 my-3">
                    <p class="text-center text-primary fw-bold fs-5 mb-0">
                      PSP:
                    </p>
                    <p class="text-center fs-5">{{ slide.psp }}</p>
                  </div>
                  <div v-if="idx !== 4 && slide.country" class="col-12 my-3">
                    <p class="text-center text-primary fw-bold fs-5 mb-0">
                      {{ item.country }}
                    </p>
                    <p class="text-center fs-5">{{ slide.country }}</p>
                  </div>
                  <div class="col-12 px-0">
                    <p
                      v-if="idx !== 4"
                      class="text-center text-primary fw-bold fs-5 mb-0"
                    >
                      {{ item.regulator }}
                    </p>
                    <p
                      v-if="idx !== 4 || idx === 4"
                      class="text-center fs-5"
                      v-html="slide.regulator"
                    ></p>
                  </div>
                  <div v-if="idx === 4" class="col-12 text-center">
                    <a
                      href="/legal-documents/Bank_Details_EDR.pdf"
                      class="btn btn-blue mt-3"
                      target="_blank"
                      >{{ $t("viewBankDetails") }}</a
                    >
                  </div>
                </div>
              </slide>
            </carousel-3d>
          </div>
        </div>
        <div
          v-if="license == 'global'"
          class="row animate__animated animate__slideInUp"
        >
          <div class="col-12">
            <carousel-3d
              :perspective="0"
              :height="500"
              :width="320"
              :space="300"
              :display="5"
              class="global"
            >
              <slide
                v-for="(slide, idx) in item.cardsGlobal"
                :key="idx"
                :index="idx"
                class="global"
              >
                <div
                  class="box d-flex flex-column align-items-center text-center"
                >
                  <div
                    v-for="img in slide.img"
                    :key="img"
                    class="image-box global d-flex align-items-center justify-content-center m-3 w-75"
                  >
                    <img
                      :src="`/img/accounts/${img}.svg`"
                      class="icon img-fluid mb-4"
                    />
                  </div>
                  <div v-if="idx === 1" class="col-12 text-center">
                    <span v-html="slide.text"></span>
                    <a
                      target="_blank"
                      href="/legal-documents/Bank_details_Benor.pdf"
                      class="btn btn-blue mt-4"
                      >{{ $t("viewBankDetails") }}</a
                    >
                  </div>
                </div>
              </slide>
            </carousel-3d>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">{{ item.title }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10 col-12 text-center" v-html="item.textTwo"></div>
      </div>
      <div class="row d-flex justify-content-center" v-scrollanimation>
        <div class="col-12">
          <div class="row d-flex justify-content-center my-5">
            <div
              v-for="(point, idx) in item.points"
              :key="idx"
              class="col-xl-3 col-sm-6 col-12 text-center mb-xl-0 mb-4"
            >
              <div class="forex-box boxes-fw">
                <img
                  :src="`/img/accounts/${point.img}.svg`"
                  alt="icon"
                  class="img-fluid icon my-2"
                />
                <p class="text-center" v-html="point.text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row d-flex justify-content-center">
        <div class="col-12 px-lg-2 px-0">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active fw-bold fs-5 py-2"
                id="home-tab"
                data-bs-toggle="tab"
                data-bs-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
              >
                {{ item.deposits }}
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link fw-bold fs-5 py-2"
                id="profile-tab"
                data-bs-toggle="tab"
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
              >
                {{ item.withdrawals }}
              </button>
            </li>
          </ul>
          <div class="tab-content mb-2" id="myTabContent">
            <div
              class="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <table v-if="license == 'global'" class="w-100 deposit">
                <tbody>
                  <tr class="name-row">
                    <td
                      v-for="(col, idx) in item.fundingWithRowBenorDeposits[0]"
                      :key="idx"
                      class="text-center py-3"
                    >
                      <span class="fs-6" v-html="col.name"></span>
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in tableFilterAsiaDep" :key="idx">
                    <td class="td-img">
                      <img
                        v-for="(img, idx) in row.img"
                        :key="idx"
                        :src="`/img/accounts/fw-table/${img}.svg`"
                        alt="icon"
                        class=""
                      />
                    </td>
                    <td v-html="row.min" class="text-center"></td>
                    <td v-html="row.max" class="text-center"></td>
                    <td v-html="row.currency" class="text-center"></td>
                    <td v-html="row.fees" class="text-center"></td>
                    <td v-html="row.time" class="text-center"></td>
                  </tr>
                  <tr class="name-row">
                    <td
                      v-for="(col, idx) in item.fundingWithRowBenorDeposits[1]"
                      :key="idx"
                      class="text-center py-3"
                    >
                      <span class="fs-6" v-html="col.name"></span>
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in tableFilterDeposits" :key="idx">
                    <td class="td-img">
                      <div class="d-flex justify-content-center">
                        <img
                          v-for="(img, idx) in row.img"
                          :key="idx"
                          :src="`/img/accounts/fw-table/${img}.svg`"
                          alt="icon"
                          class=""
                        />
                      </div>
                    </td>
                    <td v-html="row.min" class="text-center"></td>
                    <td v-html="row.max" class="text-center"></td>
                    <td v-html="row.currency" class="text-center"></td>
                    <td v-html="row.fees" class="text-center"></td>
                    <td v-html="row.time" class="text-center"></td>
                  </tr>
                </tbody>
              </table>
              <table v-else class="w-100 deposit">
                <tbody>
                  <tr class="name-row">
                    <td
                      v-for="(col, idx) in item.fundingWithRow[0]"
                      :key="idx"
                      class="text-center py-3"
                    >
                      <span class="fs-6" v-html="col.name"></span>
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in tableFilterAsiaDep" :key="idx">
                    <td class="td-img">
                      <img
                        v-for="(img, idx) in row.img"
                        :key="idx"
                        :src="`/img/accounts/fw-table/${img}.svg`"
                        alt="icon"
                        class=""
                      />
                    </td>
                    <td v-html="row.min" class="text-center"></td>
                    <td v-html="row.currency" class="text-center"></td>
                    <td v-html="row.fees" class="text-center"></td>
                    <td v-html="row.time" class="text-center"></td>
                  </tr>
                  <tr class="name-row">
                    <td
                      v-for="(col, idx) in item.fundingWithRow[1]"
                      :key="idx"
                      class="text-center py-3"
                    >
                      <span class="fs-6" v-html="col.name"></span>
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in tableFilterDeposits" :key="idx">
                    <td class="td-img">
                      <div class="d-flex justify-content-center">
                        <img
                          v-for="(img, idx) in row.img"
                          :key="idx"
                          :src="`/img/accounts/fw-table/${img}.svg`"
                          alt="icon"
                          class=""
                        />
                      </div>
                    </td>
                    <td v-html="row.min" class="text-center"></td>
                    <td v-html="row.currency" class="text-center"></td>
                    <td v-html="row.fees" class="text-center"></td>
                    <td v-html="row.time" class="text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="tab-pane fade"
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <table class="w-100 withdraw">
                <tbody>
                  <tr class="name-row">
                    <td
                      v-for="(col, idx) in item.fundingWithRow[0]"
                      :key="idx"
                      class="text-center py-3"
                    >
                      <span class="fs-6" v-html="col.name"></span>
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in tableFilterAsiaWith" :key="idx">
                    <td class="td-img">
                      <img
                        v-for="(img, idx) in row.img"
                        :key="idx"
                        :src="`/img/accounts/fw-table/${img}.svg`"
                        alt="icon"
                        class=""
                      />
                    </td>
                    <td v-html="row.min" class="text-center"></td>
                    <td v-html="row.currency" class="text-center"></td>
                    <td v-html="row.fees" class="text-center"></td>
                    <td v-html="row.time" class="text-center"></td>
                  </tr>
                  <tr class="name-row">
                    <td
                      v-for="(col, idx) in item.fundingWithRow[1]"
                      :key="idx"
                      class="text-center py-3"
                    >
                      <span class="fs-6" v-html="col.name"></span>
                    </td>
                  </tr>
                  <tr v-for="(row, idx) in tableFilterWithdrawals" :key="idx">
                    <td class="td-img">
                      <div class="d-flex justify-content-center">
                        <img
                          v-for="(img, idx) in row.img"
                          :key="idx"
                          :src="`/img/accounts/fw-table/${img}.svg`"
                          alt="icon"
                          class=""
                        />
                      </div>
                    </td>
                    <td v-html="row.min" class="text-center"></td>
                    <td v-html="row.currency" class="text-center"></td>
                    <td v-html="row.fees" class="text-center"></td>
                    <td v-html="row.time" class="text-center"></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <p
            v-for="(point, idx) in item.fundingWithTable"
            :key="idx"
            class="explanation mb-0"
            v-html="point"
          ></p>
        </div>
      </div>
    </div>
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import Carousel3d from "../components/carousel/Carousel3d.vue";
import Slide from "../components/carousel/Slide.vue";
import { getters, mutations } from "../store";
export default {
  name: "FundingWithdrawals",
  components: {
    Carousel3d,
    Slide,
  },
  data: () => ({}),
  computed: {
    license() {
      return getters.license();
    },
    // fundWithRowBenorFilter() {
    //   return this.$tm("fundingWithdrawalsInfo")
    //     .map((element) => {
    //       return element;
    //     })
    //     .map((el) => {
    //       let filterRow = el.fundingWithRow[0]
    //       filterRow.splice(1, 0, "suka");
    //       filterRow.join()

    //       filterRow.filter((row) => {
    //         if (this.license == "eu") {
    //           return row.name !== "Maximum Amount";
    //         } else {
    //           return row.name;
    //         }
    //       });
    //       return {
    //         fundingWithRowNew: filterRow,
    //       };
    //     });
    // },
    tableFilterAsiaDep() {
      if (this.license == "eu") {
        return this.$tm("funWithTableContentDeposits[0]").filter((col) => {
          return col.name != "asia";
        });
      } else {
        return this.$tm("funWithTableContentDeposits[0]");
      }
    },
    tableFilterAsiaWith() {
      if (this.license == "eu") {
        return this.$tm("funWithTableContentWithdrawals[0]").filter((col) => {
          return col.name != "asia";
        });
      } else {
        return this.$tm("funWithTableContentWithdrawals[0]");
      }
    },
    tableFilterDeposits() {
      if (this.license == "global") {
        return this.$tm("funWithTableContentDeposits[1]").filter((col) => {
          return col.name != "skrill";
        });
      } else {
        return this.$tm("funWithTableContentDeposits[1]");
      }
    },
    tableFilterWithdrawals() {
      if (this.license == "global") {
        return this.$tm("funWithTableContentWithdrawals[1]").filter((col) => {
          return col.name != "skrill";
        });
      } else {
        return this.$tm("funWithTableContentWithdrawals[1]");
      }
    },
  },
  mounted() {
    mutations.setBoxHeight("boxes-fw", "2");
    window.onresize = () => {
      mutations.setBoxHeight("boxes-fw", "2");
    };
  },
};
</script>
<style lang="scss" scoped>
#myTabContent {
  overflow-y: auto;
  @media screen and (max-width: 500px) {
    td {
      min-width: 170px !important;
      font-size: 15px !important;
    }
    td span {
      font-size: 15px !important;
    }
  }
}
</style>
