export default {
  en: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  fr: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  it: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  de: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  es: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  cn: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  vnm: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  enUae: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
  arb: {
    articles: {
      title: "مقالات عامة",
      text: `مقالات عامة قائمة على المعرفة حول الفوركس والسلع والمؤشرات والأسهم وكل ما يتعلق باتجاهات السوق.`,
    },
    marketAnalysis: {
      title: "تحليل السوق",
      text: `أخبار السوق وتقويم السوق المحدث والمقالات اليومية`,
    },
    minutes: "دقائق",
    readAlso: "اقرأ أيضاً",
  },
  my: {
    articles: {
      title: "General Articles",
      text: `General knowledge-based articles about forex, commodities, indices, stocks and everything related to the market trends.`,
    },
    marketAnalysis: {
      title: "Market Analysis",
      text: `Market news, updated market calendar, and daily articles`,
    },
    minutes: "minutes",
    readAlso: "Read Also",
  },
};
