<template>
  <div id="faq">
    <div class="container-fluid container-top accounts faq">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("faq") }}
          </h2>
          <hr class="gold" />
        </div>
        <div class="row my-md-5 mb-5 d-flex justify-content-center">
          <div
            v-for="(section, idx) in $tm('faqInfo')"
            :key="idx"
            class="col-lg-6 pe-lg-5 col-md-7 col-sm-10 faq-section my-4"
          >
            <h2 class="text-justify text-gold">{{ section.title }}</h2>
            <hr class="gray" />
            <div
              v-for="(faq, index) in section.faqContent"
              :key="index"
              class="faq-item"
            >
              <div
                :class="faq.open ? 'fw-bold' : ''"
                @click="faq.open = !faq.open"
              >
                <div class="col-12">
                  <div class="row d-flex justify-content-start">
                    <div class="col-1 px-0">
                      <img
                        :src="`/img/company/${faq.open ? 'minus' : 'plus'}.svg`"
                        :alt="faq.q"
                        class="img-fluid icon faq"
                      />
                    </div>
                    <div class="col d-flex align-items-center">
                      <p class="mb-0 text-white fs-6">
                        {{ faq.q }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <i
                class="text-white fs-6 text-justify answer"
                v-if="faq.open"
                v-html="faq.a"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FAQ",
};
</script>
<style lang="scss" scoped>
</style>
