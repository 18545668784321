export default {
  en: {
    MobileApp: "TrioMarkets Mobile App",
    MobileAppText: `Welcome to the future of trading with the TrioMarkets Mobile App! Seamlessly designed to provide you with unparalleled convenience and functionality, our mobile app is your gateway to a world of financial opportunities. Whether you're a seasoned trader or just starting, our app is tailored to meet your every need. Experience the freedom to trade anytime, anywhere, with a user-centric design that puts the power of the financial markets in the palm of your hand.`,
    Download : `Download Now`,
    WhyChoose : `Why Choose TrioMarkets Mobile App?`,
    Choose1 : `User-Friendly Interface`,
    Choose2 : `Effortless Registration and Login`,
    Choose3 : `Secure Deposits and Withdrawals`,
    Choose4 : `Document Submission Made Easy`,
    Choose5 : `Interactive Questionnaires`,
    Choose6 : `Revolutionary TrioMarkets Trading Platform`,
    GetStarted : `Ready to Get Started?`,
    GetStartedText : `The TrioMarkets Mobile App is your passport to a new era of trading. <br/> Whether you're on the go or prefer the convenience of mobile trading, <br /> our app empowers you to take control of your financial future.`,
    DownloadText : `Download the TrioMarkets Mobile App now <br> and embark on a seamless  and rewarding trading experience.`
  },
  fr: {
    MobileApp: "Application mobile TrioMarkets",
    MobileAppText: `Bienvenue dans l'avenir du trading avec l'application mobile TrioMarkets ! Conçue de manière transparente pour vous offrir une commodité et une fonctionnalité inégalées, notre application mobile est votre passerelle vers un monde d'opportunités financières. Que vous soyez un trader chevronné ou que vous débutiez, notre application est conçue pour répondre à tous vos besoins. Vivez la liberté de trader à tout moment, n'importe où, avec une conception centrée sur l'utilisateur qui met le pouvoir des marchés financiers au creux de votre main.`,
    Download : `Télécharger l'application`,
    WhyChoose : `Pourquoi choisir l'application mobile TrioMarkets ?`,
    Choose1 : `Interface conviviale`,
    Choose2 : `Inscription et connexion sans effort`,
    Choose3 : `Dépôts et retraits sécurisés`,
    Choose4 : `Soumission de documents simplifiée`,
    Choose5 : `Questionnaires interactifs`,
    Choose6 : `Plateforme de trading révolutionnaire TrioMarkets`,
    GetStarted : `Prêt à commencer ?`,
    GetStartedText : `L'application mobile TrioMarkets est votre passeport pour une nouvelle ère de trading. <br/>Que vous soyez en déplacement ou que vous préfériez la commodité du trading mobile, <br /> notre application vous donne le pouvoir de prendre le contrôle de votre avenir financier.`,
    DownloadText : `Téléchargez dès maintenant l'application mobile TrioMarkets et lancez-vous dans une expérience de trading fluide et enrichissante.`

  },
  it: {
    MobileApp: "App mobile TrioMarkets",
    MobileAppText: `Benvenuto nel futuro del trading con l'app mobile TrioMarkets! Progettata in modo fluido per offrirti una comodità e una funzionalità senza pari, la nostra app mobile è il tuo passaporto per un mondo di opportunità finanziarie. Che tu sia un trader esperto o alle prime armi, la nostra app è progettata per soddisfare ogni tua esigenza. Vivi la libertà di fare trading in qualsiasi momento, ovunque, con un design centrato sull'utente che mette il potere dei mercati finanziari nel palmo della tua mano.`,
    Download : `Scarica ora`,
    WhyChoose : `Perché scegliere l'app mobile TrioMarkets?`,
    Choose1 : `Interfaccia utente amichevole`,
    Choose2 : `Registrazione e accesso senza sforzo`,
    Choose3 : `Depositi e prelievi sicuri`,
    Choose4 : `Sottomissione semplificata dei documenti`,
    Choose5 : `Questionari interattivi`,
    Choose6 : `Rivoluzionaria piattaforma di trading TrioMarkets`,
    GetStarted : `Pronto a iniziare?`,
    GetStartedText : `L'app mobile TrioMarkets è il tuo passaporto per una nuova era di trading. Che tu sia in movimento o preferisca la comodità del trading mobile, la nostra app ti dà il potere di prendere il controllo del tuo futuro finanziario.`,
    DownloadText : `Scarica subito l'app mobile TrioMarkets e intraprendi un'esperienza di trading senza intoppi e gratificante.`

  },
  de: {
    MobileApp: "TrioMarkets Mobile App",
    MobileAppText: `Willkommen in der Zukunft des Handels mit der TrioMarkets Mobile App! Nahtlos gestaltet, um Ihnen beispiellose Bequemlichkeit und Funktionalität zu bieten, ist unsere mobile App Ihr Tor zu einer Welt finanzieller Möglichkeiten. Ob Sie ein erfahrener Trader sind oder gerade erst anfangen, unsere App ist darauf ausgerichtet, all Ihren Bedürfnissen gerecht zu werden. Erleben Sie die Freiheit zu handeln, wann und wo Sie möchten, mit einem benutzerzentrierten Design, das die Kraft der Finanzmärkte in Ihre Handfläche legt.`,
    Download : `Jetzt herunterladen`,
    WhyChoose : `Warum die TrioMarkets Mobile App wählen?`,
    Choose1 : `Benutzerfreundliche Oberfläche`,
    Choose2 : `Mühelose Registrierung und Anmeldung`,
    Choose3 : `Sichere Ein- und Auszahlungen`,
    Choose4 : `Einfache Einreichung von Dokumenten`,
    Choose5 : `Interaktive Fragebögen`,
    Choose6 : `Revolutionäre TrioMarkets Handelsplattform`,
    GetStarted : `Bereit, loszulegen?`,
    GetStartedText : `Die TrioMarkets Mobile App ist Ihr Pass in eine neue Ära des Handels. Ob Sie unterwegs sind oder die Bequemlichkeit des mobilen Handels bevorzugen – unsere App gibt Ihnen die Kontrolle über Ihre finanzielle Zukunft.`,
    DownloadText : `Laden Sie die TrioMarkets Mobile App jetzt herunter und starten Sie in ein reibungsloses und belohnendes Handelserlebnis.`

  },
  es: {
    MobileApp: "Aplicación móvil TrioMarkets",
    MobileAppText: `Bienvenido al futuro del trading con la aplicación móvil de TrioMarkets! Diseñada de manera fluida para proporcionarte una comodidad y funcionalidad incomparables, nuestra aplicación móvil es tu puerta de entrada a un mundo de oportunidades financieras. Ya seas un trader experimentado o estés empezando, nuestra aplicación está diseñada para satisfacer todas tus necesidades. Experimenta la libertad de operar en cualquier momento y lugar, con un diseño centrado en el usuario que coloca el poder de los mercados financieros en la palma de tu mano.`,
    Download : `Descargar ahora`,
    WhyChoose : `Por qué elegir la aplicación móvil de TrioMarkets?`,
    Choose1 : `Interfaz fácil de usar`,
    Choose2 : `Registro y acceso sin esfuerzo`,
    Choose3 : `Depósitos y retiros seguros`,
    Choose4 : `Sencilla presentación de documentos`,
    Choose5 : `Cuestionarios interactivos`,
    Choose6 : `Revolucionaria plataforma de trading de TrioMarkets`,
    GetStarted : `Listo para empezar?`,
    GetStartedText : `La aplicación móvil de TrioMarkets es tu pasaporte a una nueva era de trading. Ya sea que estés en movimiento o prefieras la comodidad del trading móvil, nuestra aplicación te empodera para tomar el control de tu futuro financiero.`,
    DownloadText : `Descarga ahora la aplicación móvil de TrioMarkets y comienza una experiencia de trading fluida y gratificante.`
  },
  arb: {
    MobileApp: "تطبيق TrioMarkets للهواتف المحمولة",
    MobileAppText: `مرحبًا في مستقبل التداول مع تطبيق TrioMarkets للهواتف المحمولة! تم تصميمه بسلاسة لتوفير لك راحة ووظائف لا مثيل لها، تطبيقنا الجوال هو بوابتك إلى عالم من الفرص المالية. سواء كنت تاجرًا ذو خبرة أو مبتدئًا، فإن تطبيقنا مصمم لتلبية كل احتياجاتك. اختبر حرية التداول في أي وقت وفي أي مكان، مع تصميم يركز على تجربة المستخدم يضع قوة الأسواق المالية في راحة يدك.`,
    Download : `حمل الآن`,
    WhyChoose : `لماذا اختيار تطبيق TrioMarkets للهواتف المحمولة؟`,
    Choose1 : `واجهة سهلة الاستخدام`,
    Choose2 : `تسجيل وتسجيل الدخول بدون جهد`,
    Choose3 : `إيداعات وسحب آمنة`,
    Choose4 : `تبسيط تقديم المستندات`,
    Choose5 : `استبيانات تفاعلية`,
    Choose6 : `منصة تداول ثورية TrioMarkets`,
    GetStarted : `هل أنت جاهز للبدء؟`,
    GetStartedText : `تطبيق TrioMarkets للهواتف المحمولة هو جواز سفرك إلى عصر جديد من التداول. سواء كنت في التنقل أو تفضل راحة التداول عبر الهواتف المحمولة، يمكنك الآن السيطرة على مستقبلك المالي.`,
    DownloadText : `قم بتنزيل تطبيق TrioMarkets للهواتف المحمولة الآن وابدأ في تجربة تداول سلسة ومجزية.`
  },
  cn: {
    MobileApp: "TrioMarkets 移动应用",
    MobileAppText: `欢迎使用 TrioMarkets 移动应用进入交易的未来！我们的移动应用经过无缝设计，为您提供无与伦比的便利和功能，是您进入金融机会世界的门户。无论您是经验丰富的交易者还是刚刚入门，我们的应用都经过定制以满足您的一切需求。体验在任何时间、任何地点进行交易的自由，使用以用户为中心设计的界面，将金融市场的力量放在您的手掌之中。`,
    Download : `立即下载`,
    WhyChoose : `为什么选择 TrioMarkets 移动应用？`,
    Choose1 : `用户友好的界面`,
    Choose2 : `轻松的注册和登录`,
    Choose3 : `安全的存款和提款`,
    Choose4 : `简化的文件提交`,
    Choose5 : `交互式问卷`,
    Choose6 : `革命性的 TrioMarkets 交易平台`,
    GetStarted : `准备好开始了吗？`,
    GetStartedText : `TrioMarkets 移动应用是您进入交易新时代的通行证。无论您在路上还是喜欢使用移动设备进行交易，我们的应用都赋予您掌握财务未来的能力。`,
    DownloadText : `立即下载 TrioMarkets 移动应用，开始无缝且富有回报的交易体验。`

  },
  vnm: {
    MobileApp: "Ứng dụng di động TrioMarkets",
    MobileAppText: `Chào mừng bạn đến với tương lai của giao dịch với ứng dụng di động TrioMarkets! Được thiết kế mượt mà để mang đến cho bạn sự thuận tiện và chức năng không giới hạn, ứng dụng di động của chúng tôi là cánh cửa của bạn đến một thế giới đầy cơ hội tài chính. Cho dù bạn là một nhà giao dịch có kinh nghiệm hay mới bắt đầu, ứng dụng của chúng tôi được tùy chỉnh để đáp ứng mọi nhu cầu của bạn. Trải nghiệm tự do giao dịch bất cứ lúc nào, ở mọi nơi, với một thiết kế tập trung vào người dùng đặt sức mạnh của thị trường tài chính vào lòng bàn tay bạn.`,
    Download : `Tải ngay`,
    WhyChoose : `Tại sao chọn ứng dụng di động TrioMarkets?`,
    Choose1 : `Giao diện thân thiện với người dùng`,
    Choose2 : `Đăng ký và đăng nhập một cách dễ dàng`,
    Choose3 : `Gửi và rút tiền an toàn`,
    Choose4 : `Quy trình nộp tài liệu đơn giản`,
    Choose5 : `Bảng câu hỏi tương tác`,
    Choose6 : `Nền tảng giao dịch đột phá của TrioMarkets`,
    GetStarted : `Sẵn sàng bắt đầu?`,
    GetStartedText : `Ứng dụng di động TrioMarkets là hộ chiếu của bạn vào một kỷ nguyên mới của giao dịch. Cho dù bạn đang di chuyển hoặc ưa thích sự thuận lợi của giao dịch di động, ứng dụng của chúng tôi mang đến cho bạn sức mạnh để kiểm soát tương lai tài chính của mình.`,
    DownloadText : `Tải ngay ứng dụng di động TrioMarkets và bắt đầu một trải nghiệm giao dịch mượt mà và đầy động lực.`

  },
  my: {
    MobileApp: "Aplikasi Mudah Alih TrioMarkets",
    MobileAppText: `Selamat datang ke masa depan perdagangan dengan Aplikasi Mudah Alih TrioMarkets! Dibangunkan secara lancar untuk memberikan anda keselesaan dan fungsi yang tiada tandingan, aplikasi mudah alih kami adalah pintu masuk anda ke dunia peluang kewangan. Sama ada anda seorang pedagang berpengalaman atau baru bermula, aplikasi kami direka khas untuk memenuhi setiap keperluan anda. Nikmati kebebasan untuk berdagang pada bila-bila masa, di mana sahaja, dengan reka bentuk yang menumpukan kepada pengguna yang meletakkan kuasa pasaran kewangan di tapak tangan anda.`,
    Download : `Muat Turun Sekarang`,
    WhyChoose : `Mengapa Pilih Aplikasi Mudah Alih TrioMarkets?`,
    Choose1 : `Antara Muka Mesra Pengguna`,
    Choose2 : `Pendaftaran dan Log Masuk Tanpa Usaha`,
    Choose3 : `Deposit dan Pengeluaran yang Selamat`,
    Choose4 : `Penyerahan Dokumen yang Mudah`,
    Choose5 : `Soal Selidik Interaktif`,
    Choose6 : `Platform Perdagangan TrioMarkets yang Revolusioner`,
    GetStarted : `Sedia untuk Mula?`,
    GetStartedText : `Aplikasi Mudah Alih TrioMarkets adalah pasport anda ke era perdagangan baru. Sama ada anda dalam perjalanan atau lebih suka kemudahan perdagangan mudah alih, aplikasi kami memberi kuasa kepada anda untuk mengawal masa depan kewangan anda.`,
    DownloadText : `Muat turun Aplikasi Mudah Alih TrioMarkets sekarang dan mula pengalaman perdagangan yang lancar dan memuaskan.`

  },
};
