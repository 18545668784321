// let license =
//   location.host.includes(".eu") || location.host.includes("8080")
//     ? "eu"
//     : "global";
let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : location.host.includes(".co.za") || location.host.includes("8082")
    ? "africa"
    : "global";

export default {
  en: {
    slides: [
      {
        title: `Explore TrioMarkets Excellence`,
        img: "openAccount",
        desc: [
          {
            text: "A trusted name in the financial industry",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Transparent, secure, and regulated trading",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Your gateway to a world of opportunities",
            show: ["global", "africa"],
          },
          {
            text: "Tight Spreads from 0.0 Pips",
            show: ["global", "eu", "africa"],
          },
        ],
        license: "homeMobile",
        button: "Open Account",
      },
      {
        title: `Advanced Trading Technology `,
        img: "tech",
        desc: [
          {
            text: "Enhanced MetaTrader 4 platform",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Optimize your strategies with our tools",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Stay ahead in the fast-paced world of trading",
            show: ["global", "eu", "africa"],
          },
          {
            text:
              license === "eu"
                ? "Multiple Trading Strategies Allowed"
                : "All Trading Strategies Allowed",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Open Account",
      },
      {
        title: `Diversify with Stock CFDs`,
        img: "stock",
        desc: [
          { text: "Trade global stocks with ease" },
          { text: "Benefit from leverage and flexibility" },
          { text: "Explore the world of equity markets" },
        ],
        button: "Open Account",
      },
      {
        title: `Join Our Partner Program`,
        img: "partner",
        desc: [
          {
            text: "Become an Introducing Broker and earn commissions",
            show: ["global", "africa"],
          },
          {
            text: "Enroll as a CPA Affiliate with TrioMarkets",
            show: ["global", "africa"],
          },
          {
            text: "Grow your network with TrioMarkets",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Learn more",
        link: `https://www.triomarkets.${
          license === "eu" ? "eu" : "com"
        }/en/partnerships/${
          license === "eu" ? "cpa-affiliates" : "introducing-brokers"
        }`,
      },
      {
        title: `Exciting Promotions Await!`,
        img: "promo",
        desc: [
          {
            text: "Refer a friend and earn rewards",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Get cashback on your trades",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Discover more exclusive offers",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Learn more",
        link: "https://www.triomarkets.eu/en/partnerships/promotions",
      },
      // {
      //   title: `TrioMarkets Mobile App`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Unlock the future of trading with the TrioMarkets Mobile App. <br>Seamlessly designed for both novice and experienced traders, <br>our app empowers you to trade anytime, anywhere. Enjoy<br> a user-friendly interface, secure transactions,and access to  <br>the TrioMarkets trading platform <strong class='text-primary'>TrioTrader </strong>, featuring <br>cutting-edge developments in the world of online trading." },
      //   ],
      //   button: "Learn more",
      //   link: "https://www.triomarkets.eu/en/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Min Deposit" },
      {
        first: `10`,
        more: "+",
        second: "Funding Methods",
      },
      { first: "140", more: "+", second: "Tradable Instruments" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "FX Leverage",
      },
      { symb: "<", first: "90", second: "MS Execution Speed" },
    ],
  },
  fr: {
    slides: [
      {
        title: `Découvrez l'Excellence de TrioMarkets`,
        img: "openAccount",
        desc: [
          {
            text: "Un nom de confiance dans l'industrie financière",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Trading transparent, sécurisé et réglementé",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Votre porte d'entrée vers un monde d'opportunités",
            show: ["global", "africa"],
          },
          {
            text: "Spreads à partir de 0.0 Pips",
            show: ["global", "eu", "africa"],
          },
        ],
        license: "homeMobile",
        button: "Ouvrir un Compte",
      },
      {
        title: `Technologie de Trading Avancée`,
        img: "tech",
        desc: [
          {
            text: "Plateforme MetaTrader 4 améliorée",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Optimisez vos stratégies avec nos outils",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Restez en tête dans le monde effréné du trading",
            show: ["global", "eu", "africa"],
          },
          {
            text:
              license === "eu"
                ? "Stratégies de négociation multiples autorisées"
                : "Toutes les Stratégies de Trading Sont Autorisées",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Ouvrir un Compte",
      },
      {
        title: `Diversifiez-vous avec les CFD sur Actions`,
        img: "stock",
        desc: [
          {
            text: "Tradez des actions mondiales en toute simplicité",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Bénéficiez de l'effet de levier et de la flexibilité",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Explorez le monde des marchés boursiers",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Ouvrir un Compte",
      },
      {
        title: `Rejoignez Notre Programme de Partenariat`,
        img: "partner",
        desc: [
          {
            text: "Devenez Introducing Broker et recevez des commissions",
            show: ["global", "africa"],
          },
          {
            text: "Inscrivez-vous en tant qu'Affilié CPA chez TrioMarkets",
            show: ["global", "africa"],
          },
          {
            text: "Développez votre réseau avec TrioMarkets",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "En Savoir Plus",
        link: `https://www.triomarkets.${
          license === "eu" ? "eu" : "com"
        }/fr/partnerships/${
          license === "eu" ? "cpa-affiliates" : "introducing-brokers"
        }`,
      },
      {
        title: `Des Promotions Passionnantes Vous Attendent !`,
        img: "promo",
        desc: [
          {
            text: "Recommandez un ami et gagnez des récompenses",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Obtenez du cashback sur vos transactions",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Découvrez davantage d'offres exclusives",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "En Savoir Plus",
        link: "https://www.triomarkets.eu/fr/partnerships/promotions",
      },
      // {
      //   title: `Application mobile TrioMarkets`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Débloquez l'avenir du trading avec l'application mobile TrioMarkets. <br> Conçue de manière transparente pour les traders novices et expérimentés,<br> notre application vous permet de trader à tout moment, n'importe où.<br> Profitez  d'une interface conviviale,  de transactions sécurisées et del'accès <br> à la plateforme de trading TrioMarkets <strong class='text-primary'> TrioTrader </strong>,  présentant des <br>développements de pointe dans le monde du trading en ligne." },
      //   ],
      //   button: "En savoir plus",
      //   link: "https://www.triomarkets.eu/fr/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Min. Dépôt" },
      {
        first: `10`,
        more: "+",
        second: "Méthodes de Dépôts",
      },
      { first: "140", more: "+", second: "Produits de Trading" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Levier FX",
      },
      { symb: "<", first: "90", second: "MS Vitesse d'Execution" },
    ],
  },
  it: {
    slides: [
      {
        title: `Scopri l'Eccellenza di TrioMarkets`,
        img: "openAccount",
        desc: [
          {
            text: "Un nome di fiducia nell'industria finanziaria",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Trading trasparente, sicuro e regolamentato",
            show: ["global", "eu", "africa"],
          },
          {
            text: "La tua porta verso un mondo di opportunità",
            show: ["global", "africa"],
          },
          {
            text: "Spread stretti a partire da 0,0 Pips",
            show: ["global", "eu", "africa"],
          },
        ],
        license: "homeMobile",
        button: "Apri Conto",
      },
      {
        title: `Tecnologia di Trading Avanzata`,
        img: "tech",
        desc: [
          {
            text: "Piattaforma MetaTrader 4 migliorata",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Ottimizza le tue strategie con i nostri strumenti",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Resta al passo nel mondo frenetico del trading",
            show: ["global", "eu", "africa"],
          },
          {
            text:
              license === "eu"
                ? "Strategie di trading multiple consentite"
                : "Consentite tutte le strategie di trading",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Apri Conto",
      },
      {
        title: `Diversificati con i CFD sulle Azioni`,
        img: "stock",
        desc: [
          {
            text: "Fai trading su azioni globali con facilità",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Beneficia della leva finanziaria e della flessibilità",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Esplora il mondo dei mercati azionari",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Apri Conto",
      },
      {
        title: `Unisciti al Nostro Programma di Partnership`,
        img: "partner",
        desc: [
          {
            text: "Diventa un Introducing Broker e guadagna commissioni",
            show: ["global", "africa"],
          },
          {
            text: "Iscriviti come Affiliato CPA con TrioMarkets",
            show: ["global", "africa"],
          },
          {
            text: "Fate crescere la vostra rete con TrioMarkets",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Impara di Piu",
        link: `https://www.triomarkets.${
          license === "eu" ? "eu" : "com"
        }/it/partnerships/${
          license === "eu" ? "cpa-affiliates" : "introducing-brokers"
        }`,
      },
      {
        title: `Presenta un amico e guadagna ricompense`,
        img: "promo",
        desc: [
          {
            text: "Consiglia un amico e guadagna ricompense",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Ottieni cashback sulle tue operazioni",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Scopri altre offerte esclusive",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Impara di Piu",
        link: "https://www.triomarkets.eu/it/partnerships/promotions",
      },
      // {
      //   title: `App TrioMarkets Mobile`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Sblocca il futuro del trading con l'app mobile TrioMarkets. <br> Progettata in modo impeccabile per trader sia principianti <br> che esperti,la nostra app ti permette di fare trading in qualsiasi <br>momento e ovunque. Goditi un'interfaccia utente amichevole,  <br>transazionisicure e l'accesso alle nuove evoluzioni nella <br>piattaforma di trading TrioMarkets <strong class='text-primary'>TrioTrader </strong>." },
      //   ],
      //   button: "Per saperne di più",
      //   link: "https://www.triomarkets.eu/it/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Dep minimo" },
      {
        first: `10`,
        more: "+",
        second: "Metodi di Depositi",
      },
      { first: "140", more: "+", second: "Prodotti di trading" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Leva FX",
      },
      { symb: "<", first: "90", second: "Velocita' di Esecuzione" },
    ],
  },
  de: {
    slides: [
      {
        title: `Entdecken Sie TrioMarkets' Exzellenz`,
        img: "openAccount",
        desc: [
          {
            text: "Ein vertrauenswürdiger Name in der Finanzbranche ",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Transparentes, sicheres und reguliertes Traden",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Ihr Tor zu einer Welt voller Möglichkeiten",
            show: ["global", "africa"],
          },
          { text: "Spreads ab 0.0 Pips", show: ["global", "eu", "africa"] },
        ],
        license: "homeMobile",
        button: "Konto Erőffnen",
      },
      {
        title: `Moderne Tradingtechnologie`,
        img: "tech",
        desc: [
          {
            text: "Verbesserte MetaTrader 4 Plattform",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Optimieren Sie Ihre Strategien mit unseren Tools",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Bleiben Sie an der Spitze der schnelllebigen Welt des Tradens",
            show: ["global", "eu", "africa"],
          },
          {
            text:
              license === "eu"
                ? "Mehrere Handelsstrategien sind erlaubt"
                : "Alle Tradingstrategien sind erlaubt",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Konto Erőffnen",
      },
      {
        title: `Diversifizieren mit Aktien-CFDs`,
        img: "stock",
        desc: [
          {
            text: "Traden Sie mühelos Aktien weltweit",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Profitieren Sie von Hebelwirkung und Flexibilität",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Entdecken Sie die Welt der Aktienmärkte",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Konto Erőffnen",
      },
      {
        title: `Treten Sie unserem Partnerprogramm bei`,
        img: "partner",
        desc: [
          {
            text: "Werden Sie ein Introducing Broker und verdienen Sie Kommissionen",
            show: ["global", "africa"],
          },
          {
            text: "Melden Sie sich als CPA Affiliate bei TrioMarkets an",
            show: ["global", "africa"],
          },

          {
            text: "Erweitern Sie Ihr Netzwerk mit TrioMarkets",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Mehr Erfahren",
        link: `https://www.triomarkets.${
          license === "eu" ? "eu" : "com"
        }/de/partnerships/${
          license === "eu" ? "cpa-affiliates" : "introducing-brokers"
        }`,
      },
      {
        title: `Aufregende Aktionen warten!`,
        img: "promo",
        desc: [
          {
            text: " Empfehlen Sie einen Freund und verdienen Sie Prämien",
            show: ["global", "eu", "africa"],
          },
          {
            text: " Erhalten Sie Cashback für Ihre Trades",
            show: ["global", "eu", "africa"],
          },
          {
            text: " Entdecken Sie weitere exklusive Angebote",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Mehr Erfahren",
        link: "https://www.triomarkets.com/de/partnerships/promotions",
      },
      // {
      //   title: `TrioMarkets Mobile App`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Entdecken Sie die Zukunft des Handels mit der TrioMarkets Mobile App. <br> Nahtlos gestaltet für Anfänger und erfahrene Händler ermöglicht Ihnen  <br>unsere App, jederzeit und überall zu handeln. Genießen Sie eine benutzerfreundliche <br>Oberfläche,  sichere Transaktionen und Zugang zu den neuen Entwicklungen in<br> der TrioMarkets Handelsplattform <strong class='text-primary'>TrioTrader </strong>." },
      //   ],
      //   button: "Mehr erfahren",
      //   link: "https://www.triomarkets.eu/de/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Min. Einzahlung" },
      {
        first: `10`,
        more: "+",
        second: "Einzahlungsmethoden",
      },
      { first: "140", more: "+", second: "Handelsinstrumente " },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "FX Hebelverhältnis",
      },
      { symb: "<", first: "90", second: "MS Ausführungsgeschwindigkeit" },
    ],
  },
  cn: {
    slides: [
      {
        title: `探索 TrioMarkets 的卓越表现`,
        img: "openAccount",
        desc: [
          { text: "金融行业值得信赖的品牌" },
          { text: "透明、安全且受监管的交易" },
          { text: "通往充满机遇的世界的大门" },
          { text: "低至 0.0 点的点差" },
        ],
        license: "homeMobile",
        button: "开立账户",
      },
      {
        title: `先进的交易技术`,
        img: "tech",
        desc: [
          { text: "增强的 MetaTrader 4 平台" },
          { text: "使用我们的工具优化您的策略" },
          { text: "在快节奏的交易世界中保持领先地位" },
          { text: "允许所有交易策略" },
        ],
        button: "开立账户",
      },
      {
        title: `通过股票差价合约实现多元化`,
        img: "stock",
        desc: [
          { text: "轻松交易全球股票" },
          { text: "受益于杠杆作用和灵活性" },
          { text: "探索股票市场的世界" },
        ],
        button: "开立账户",
      },
      {
        title: `加入我们的合作伙伴计划`,
        img: "partner",
        desc: [
          { text: "成为介绍经纪商并赚取佣金" },
          { text: "注册成为 TrioMarkets 的 CPA 联盟会员" },
          { text: "通过 TrioMarkets 拓展您的网络和收入" },
        ],
        button: "En Savoir Plus",
        link: "https://www.triomarkets.com/cn/partnerships/introducing-brokers",
      },
      {
        title: `激动人心的促销活动等待您！`,
        img: "promo",
        desc: [
          { text: "推荐朋友并获得奖励" },
          { text: "获得交易现金返还" },
          { text: "发现更多独家优惠" },
        ],
        button: "En Savoir Plus",
        link: "https://www.triomarkets.eu/cn/partnerships/promotions",
      },
      // {
      //   title: `TrioMarkets 移动应用`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "使用 TrioMarkets 移动应用开启交易的未来。我们的应用经过精心设计，<br>适用于新手和经验丰富的交易者，使您能够随时随地进行交易。<br>享受用户友好的界面、安全的交易以及在 TrioMarkets 交易平台 <strong class='text-primary'>TrioTrader </strong>,  <br>中的在线交易领域的前沿发展。" },
      //   ],
      //   button: "了解更多",
      //   link: "https://www.triomarkets.eu/cn/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "点差" },
      { symb: "$", first: "100", second: "最低存款" },
      { first: `10`, more: "+", second: "入金方式" },
      { first: "140", more: "+", second: "可交易工具" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "外汇杠杆",
      },
      { symb: "<", first: "90", second: "毫秒级执行速度" },
    ],
  },
  es: {
    slides: [
      {
        title: `Explora la Excelencia de TrioMarkets`,
        img: "openAccount",
        desc: [
          {
            text: "Un nombre de confianza en la industria financiera",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Trading transparente, seguro y regulado",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Tu puerta de acceso a un mundo de oportunidades",
            show: ["global", "africa"],
          },
          {
            text: "Spreads ajustados a partir de 0.0 Pips",
            show: ["global", "eu", "africa"],
          },
        ],
        license: "homeMobile",
        button: "Abrir Cuenta",
      },
      {
        title: `Tecnología de Trading Avanzada`,
        img: "tech",
        desc: [
          {
            text: "Plataforma MetaTrader 4 mejorada",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Optimiza tus estrategias con nuestras herramientas",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Mantente a la vanguardia en el mundo acelerado del trading",
            show: ["global", "eu", "africa"],
          },
          {
            text:
              license === "eu"
                ? "Múltiples estrategias de negociación permitidas"
                : "Se permiten todas las estrategias de trading",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Abrir Cuenta",
      },
      {
        title: `Diversifica con CFDs de Acciones`,
        img: "stock",
        desc: [
          {
            text: "Opera acciones globales con facilidad",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Benefíciate del apalancamiento y la flexibilidad",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Explora el mundo de los mercados de acciones",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Abrir Cuenta",
      },
      {
        title: `Únete a Nuestro Programa de Socios`,
        img: "partner",
        desc: [
          {
            text: "Conviértete en un Introducing Broker y gana comisiones",
            show: ["global", "africa"],
          },
          {
            text: "Inscríbete como Afiliado CPA con TrioMarkets",
            show: ["global", "africa"],
          },
          {
            text: "Haz crecer tu red con TrioMarkets",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Aprende Más",
        link: `https://www.triomarkets.${
          license === "eu" ? "eu" : "com"
        }/es/partnerships/${
          license === "eu" ? "cpa-affiliates" : "introducing-brokers"
        }`,
      },
      {
        title: `Emocionantes Promociones Te Esperan!`,
        img: "promo",
        desc: [
          {
            text: "Recomienda a un amigo y gana recompensas.",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Obtén cashback en tus operaciones.",
            show: ["global", "eu", "africa"],
          },
          {
            text: "Descubre más ofertas exclusivas.",
            show: ["global", "eu", "africa"],
          },
        ],
        button: "Aprende Más",
        link: "https://www.triomarkets.eu/es/partnerships/promotions",
      },
      // {
      //   title: `Aplicación móvil TrioMarkets`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Desbloquea el futuro del trading con la aplicación móvil TrioMarkets. <br> Diseñada de manera impecable para traders novatos y experimentados, <br> nuestra  aplicación te permite operar en cualquier momento y lugar. Disfruta<br>  de una  interfaz amigable,transacciones seguras y acceso a las nuevas<br> evoluciones en la plataforma de trading TrioMarkets<strong class='text-primary'>TrioTrader </strong>." },
      //   ],
      //   button: "Más información",
      //   link: "https://www.triomarkets.eu/es/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "pips de Diferenciales" },
      { symb: "$", first: "100", second: "Depósito Mínimo" },
      {
        first: `10`,
        more: "+",
        second: "Métodos Depositos o Más",
      },
      { first: "140", more: "+", second: "Instrumentos de Trading o Más" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Apalancamiento",
      },
      { symb: "<", first: "90", second: "MS Velocidad de Ejecución" },
    ],
  },
  vnm: {
    slides: [
      {
        title: `Khám phá sự xuất sắc của TrioMarkets`,
        img: "openAccount",
        desc: [
          { text: "Một cái tên đáng tin cậy trong ngành tài chính" },
          { text: "Giao dịch minh bạch, an toàn và được quản lý" },
          { text: "Cánh cổng dẫn bạn đến thế giới cơ hội" },
          { text: "Chênh lệch thấp từ 0,0 Pip" },
        ],
        license: "homeMobile",
        button: "Mở Tài Khoản",
      },
      {
        title: `Công nghệ giao dịch tiên tiến`,
        img: "tech",
        desc: [
          { text: "Nền tảng MetaTrader 4 nâng cao" },
          {
            text: "Tối ưu hóa chiến lược của bạn bằng các công cụ của chúng tôi",
          },
          {
            text: "Luôn dẫn đầu trong thế giới giao dịch phát triển nhanh chóng",
          },
          { text: "Cho phép tất cả các chiến lược giao dịch" },
        ],
        button: "Mở Tài Khoản",
      },
      {
        title: `Đa dạng hóa với CFD cổ phiếu`,
        img: "stock",
        desc: [
          { text: "Giao dịch chứng khoán toàn cầu một cách dễ dàng" },
          { text: "Hưởng lợi từ đòn bẩy và tính linh hoạt" },
          { text: "Khám phá thế giới thị trường chứng khoán" },
        ],
        button: "Mở Tài Khoản",
      },
      {
        title: `Tham gia chương trình đối tác của chúng tôi`,
        img: "partner",
        desc: [
          { text: "Trở thành Người giới thiệu và kiếm tiền hoa hồng" },
          { text: "Đăng ký làm Cộng tác viên CPA của TrioMarkets" },
          { text: "Phát triển mạng lưới và doanh thu của bạn với TrioMarkets" },
        ],
        button: "Tìm hiểu thêm",
        link: "https://www.triomarkets.com/vnm/partnerships/introducing-brokers",
      },
      {
        title: `Khuyến mãi hấp dẫn đang chờ đợi!`,
        img: "promo",
        desc: [
          { text: "Giới thiệu bạn bè và kiếm phần thưởng" },
          { text: "Nhận tiền hoàn lại cho các giao dịch của bạn" },
          { text: "Khám phá thêm các ưu đãi độc quyền" },
        ],
        button: "Tìm hiểu thêm",
        link: "https://www.triomarkets.com/vnm/partnerships/promotions",
      },
      // {
      //   title: `Ứng dụng di động TrioMarkets`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Mở khóa tương lai giao dịch với ứng dụng di động TrioMarkets.<br>Thiết kế một cách mượt mà để phục vụ cả những người giao dịch mới <br>và có kinh nghiệm,  ứng dụng của chúng tôi giúp bạn giao dịch bất cứ lúc  <br>nào, ở bất kỳ đâu. Trải nghiệm giao diện thân thiện với người dùng, giao dịch <br>an toàn và truy cập vào các  tiến triển mới trong thế giới giao dịch <br>trực tuyến của TrioMarkets <strong class='text-primary'>TrioTrader </strong>." },
      //   ],
      //   button: "Tìm Hiểu Thêm",
      //   link: "https://www.triomarkets.eu/vnm/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Tiền gửi tối thiểu" },
      {
        first: `10`,
        more: "+",
        second: "Phương thức nạp tiền",
      },
      { first: "140", more: "+", second: "Công cụ có thể giao dịch" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Đòn bẩy FX",
      },
      { symb: "<", first: "90", second: "Tốc độ thực thi MS" },
    ],
  },
  enUae: {
    slides: [
      {
        title: `Explore TrioMarkets Excellence`,
        img: "openAccount",
        desc: [
          { text: "A trusted name in the financial industry" },
          { text: "Transparent, secure, and regulated trading" },
          { text: "Your gateway to a world of opportunities" },
          { text: "Tight Spreads from 0.0 Pips" },
        ],
        license: "homeMobile",
        button: "Open Account",
      },
      {
        title: `Swap-Free Trading`,
        img: "swap",
        desc: [
          { text: "Access interest-free trading accounts" },
          { text: "Ideal for those who follow Shariah principles" },
          { text: "Trade with peace of mind" },
        ],
        button: `Learn More`,
        link: "https://www.triomarkets.com/enUae/accounts/swap-free",
      },
      {
        title: `Advanced Trading Technology `,
        img: "tech",
        desc: [
          { text: "Enhanced MetaTrader 4 platform" },
          { text: "Optimize your strategies with our tools" },
          { text: "Stay ahead in the fast-paced world of trading" },
          { text: "All Trading Strategies Allowed" },
        ],
        button: "Open Account",
      },
      {
        title: `Join Our Partner Program`,
        img: "partner",
        desc: [
          { text: "Become an Introducing Broker and earn commissions" },
          { text: "Enroll as a CPA Affiliate with TrioMarkets" },
          { text: "Grow your network and income with TrioMarkets" },
        ],
        button: "Learn more",
        link: `https://www.triomarkets.${
          license === "eu" ? "eu" : "com"
        }/en/partnerships/${
          license === "eu" ? "cpa-affiliates" : "introducing-brokers"
        }`,
      },
      {
        title: `Exciting Promotions Await!`,
        img: "promo",
        desc: [
          { text: "Refer a friend and earn rewards" },
          { text: "Get cashback on your trades" },
          { text: "Discover more exclusive offers" },
        ],
        button: "Learn more",
        link: "https://www.triomarkets.eu/enUae/partnerships/promotions",
      },
      // {
      //   title: `TrioMarkets Mobile App`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Unlock the future of trading with the TrioMarkets Mobile App. <br>Seamlessly designed for both novice and experienced traders, <br>our app empowers you to trade anytime, anywhere. Enjoy<br> a user-friendly interface, secure transactions,and access to  <br>the TrioMarkets trading platform <strong class='text-primary'>TrioTrader </strong>, featuring <br>cutting-edge developments in the world of online trading." },
      //   ],
      //   button: "Learn more",
      //   link: "https://www.triomarkets.eu/enUae/platforms/mobile-app",
      // },
      // // {
      // //   title: `Crypto Funding / Withdraw`,
      // //   img: "cryptos",
      // //   desc: [
      // //     { text: "Deposit/Withdraw with - BTC, ETH, XRP, USDT +" },
      // //     { text: "Access to QR Code" },
      // //     { text: "Fast, Easy & Functional" },
      // //   ],
      // //   license: "",
      // // },
      // {
      //   title: `TrioMarkets™ - True ECN Broker`,
      //   img: "brand",
      //   desc: [
      //     { text: "Quick Execution 90 MS" },
      //     { text: "Trustworthy, Transparent, Trading" },
      //     { text: "Tight Spreads from 0.0 pips" },
      //   ],
      //   license: "homeMobile",
      // },
      // {
      //   title: `Cutting Edge Technology`,
      //   img: "tech",
      //   desc: [
      //     { text: "Enhanced MetaTrader 4" },
      //     { text: "Unique Technical & Fundamental <br> Analysis" },
      //     { text: "ALL Trading Strategies Allowed" },
      //   ],
      //   license: "",
      // },
      // {
      //   title: `Trade & Earn`,
      //   img: "cashback",
      //   desc: [
      //     { text: "Apply for our Cashback program" },
      //     { text: "Terms & Conditions Apply" },
      //   ],
      //   license: "global",
      // },
      // {
      //   title: `Trade Hot CFD Commodities`,
      //   img: "metals",
      //   desc: [
      //     { text: "Gold" },
      //     { text: "Crude" },
      //     { text: "Silver" },
      //     { text: "Gas & more" },
      //   ],
      //   license: "eu",
      // },
      // {
      //   title: `Invest on Top CFD US Stocks`,
      //   img: "cube",
      //   desc: [
      //     { text: "Amazon" },
      //     { text: "Google" },
      //     { text: "Apple" },
      //     { text: "Pfizer & more" },
      //   ],
      // },
    ],
    features: [
      { first: "0.0", second: "pips Spreads" },
      { symb: "$", first: "100", second: "Min Deposit" },
      {
        first: `10`,
        more: "+",
        second: "Funding Methods",
      },
      { first: "140", more: "+", second: "Tradable Instruments" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "FX Leverage",
      },
      { symb: "<", first: "90", second: "MS Execution Speed" },
    ],
  },
  arb: {
    slides: [
      {
        title: `استكشف مميزات Triomarkets`,
        img: "openAccount",
        desc: [
          { text: "استكشف مميزات تريوماركتس" },
          { text: "تداول شفاف و امن و منظم" },
          { text: "بوابتك الي عالم من الفرص" },
          { text: "فروق أسعار قليله تبدأ من 0.0 نقطة" },
        ],
        license: "homeMobile",
        button: "فتح حساب",
      },
      {
        title: `تداول بدون عمولات أو فوائد تبييت`,
        img: "swap",
        desc: [
          { text: "تداول الاسهم العالمية بسهولة" },
          { text: "الاستفاده من الرافعه المالية و المرونة" },
          { text: "استكشاف عالم أسواق الاسهم" },
        ],
        button: "تعرف على المزيد",
        link: "https://www.triomarkets.com/arb/accounts/swap-free",
      },
      {
        title: `تكنولوجيا التداول المتقدمه`,
        img: "tech",
        desc: [
          { text: "MetaTrader 4" },
          { text: "تحسين استراتيجياتك باستخدام أدواتنا" },
          { text: "جميع استراتيجيات التداول مسموح بها" },
          { text: "ابقي معانا في عالم التداول المتقدم السريع" },
        ],
        button: "فتح حساب",
      },
      {
        title: `انضم الي برنامج الشركاء`,
        img: "partner",
        desc: [
          { text: "كن وسيطا معانا و اكسب عمولات" },
          { text: "نظام CPA مع تريوماركتس" },
          { text: "قم بزيادة عملائك و دخلك مع Triomarkets " },
        ],
        button: "تعرف على المزيد",
        link: "https://www.triomarkets.com/arb/partnerships/introducing-brokers",
      },
      {
        title: `العروض الحصرية في انتظارك`,
        img: "promo",
        desc: [
          { text: " قم بدعوة صديق و احصل علي" },
          { text: " احصل على استرداد نقدي علي تداولاتك" },
          { text: " اكتشف المزيد من العروض الحصرية " },
        ],
        button: "تعرف على المزيد",
        link: "https://www.triomarkets.com/arb/partnerships/promotions",
      },
      // {
      //   title: `تطبيق TrioMarkets للهواتف المحمولة`,
      //   img: "MobileAppSlider",
      //   desc: [
      //      { text: "افتح أفق المستقبل في التداول مع تطبيق TrioMarkets للهواتف <br>  المحمولة. مصمم بسلاسة لكل من التجار الجدد والمحترفين، يمنحك <br> تطبيقنا القدرة على التداول في أي وقت وفي أي مكان. استمتع بو <br>اجهةالاستخدام، وتحويلات آمنة، والوصول إلى المنصة الجديدة في<br>  تطويرات TrioMarkets TrioTrader في عالم التداول عبر الإنترنت." },
      //   ],
      //   button: "معرفة المزيد",
      //   link: "https://www.triomarkets.eu/arb/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "هوامش النقاط" },
      { symb: "$", first: "100", second: "الحد الأدنى للإيداع" },
      {
        first: `10`,
        more: "+",
        second: "طرق التمويل",
      },
      { first: "140", more: "+", second: "الأدوات القابلة للتداول" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "الرافعة المالية في الفوركس",
      },
      { symb: "<", first: "90", second: "سرعة التنفيذ بجزء من الثانية" },
    ],
  },
  my: {
    slides: [
      {
        title: `Terokai Kecemerlangan TrioMarkets`,
        img: "openAccount",
        desc: [
          { text: "Nama yang dipercayai dalam industri kewangan" },
          { text: "Perdagangan yang telus, selamat dan terkawal" },
          { text: "Pintu masuk anda ke dunia peluang" },
          { text: "Spread Ketat daripada 0.0 Pips" },
        ],
        license: "homeMobile",
        button: "Buka Akaun",
      },
      {
        title: `Teknologi Dagangan Termaju`,
        img: "tech",
        desc: [
          { text: "Platform MetaTrader 4 yang dipertingkatkan" },
          { text: "Optimumkan strategi anda dengan alatan kami" },
          { text: "Kekal ke hadapan dalam dunia perdagangan yang pantas" },
          { text: "Semua Strategi Dagangan Dibenarkan" },
        ],
        button: "Buka Akaun",
      },
      {
        title: `Pelbagaikan dengan CFD Saham`,
        img: "stock",
        desc: [
          { text: "Berdagang saham global dengan mudah" },
          { text: "Manfaat daripada leverage dan fleksibiliti" },
          { text: "Terokai dunia pasaran ekuiti" },
        ],
        button: "Buka Akaun",
      },
      {
        title: `Sertai Program Rakan Kongsi Kami`,
        img: "partner",
        desc: [
          { text: "Menjadi Broker Pengenalan dan dapatkan komisen" },
          { text: "Daftar sebagai Ahli Gabungan CPA dengan TrioMarkets" },
          {
            text: "Kembangkan rangkaian dan pendapatan anda dengan TrioMarkets",
          },
        ],
        button: "Ketahui Lebih Lanjut",
        link: "https://www.triomarkets.com/my/partnerships/introducing-brokers",
      },
      {
        title: `Promosi yang menarik menunggu!`,
        img: "promo",
        desc: [
          { text: "Rujuk rakan dan dapatkan ganjaran" },
          { text: "Dapatkan pulangan tunai pada dagangan anda" },
          { text: "Temui lebih banyak tawaran eksklusif" },
        ],
        button: "Ketahui Lebih Lanjut",
        link: "https://www.triomarkets.com/my/partnerships/promotions",
      },
      // {
      //   title: `Aplikasi Mudah Alih TrioMarkets`,
      //   img: "MobileAppSlider",
      //   desc: [
      //     { text: "Buka kunci masa depan perdagangan dengan Aplikasi Mudah Alih TrioMarkets. <br> Direka dengan lancar untuk peniaga pemula dan berpengalaman, aplikasi kami  <br>memberikan kuasa kepada anda untuk berdagang pada bila-bila masa,<br> di mana-mana sahaja. Nikmati antara muka mesra pengguna, urus niaga yang<br> selamat, dan akses kepada perkembangan baru dalam platform perdagangan<br>TrioMarkets <strong class='text-primary'>TrioTrader </strong>." },
      //   ],
      //   button: "Ketahui Lebih Lanjut",
      //   link: "https://www.triomarkets.eu/my/platforms/mobile-app",
      // },
    ],
    features: [
      { first: "0.0", second: "pip Spread" },
      { symb: "$", first: "100", second: "Deposit Min" },
      {
        first: `10`,
        more: "+",
        second: "Kaedah Pembiayaan",
      },
      { first: "140", more: "+", second: "Instrumen Boleh Dagangan" },
      {
        symb: "1:",
        first: `${license == "global" ? "500" : "30"}`,
        second: "Leverage FX",
      },
      { symb: "<", first: "90", second: "Kelajuan Pelaksanaan MS" },
    ],
  },
};
