export default {
  en: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  fr: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  it: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  de: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  cn: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  es: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  vnm: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  enUae: {
    goToOffer: "Go to Offer",
    prizeCategories: "Prize Categories",
    tradingLots: "Trading Lots",
    depositPromotion: "Deposit",
    howToJoin: "How To Join",
    howToJoinSteps: [
      { num: "1", name: "Register & Verify" },
      { num: "2", name: "Deposit" },
      { num: "3", name: "Start Trading" },
    ],
    joinNow: "Join Now",
  },
  arb: {
    goToOffer: "انتقل إلى العرض",
    prizeCategories: "فئات الجوائز",
    tradingLots: "حصص التداول",
    depositPromotion: "إيداع",
    howToJoin: "كيف تنضم",
    howToJoinSteps: [
      { num: "1", name: "سجل وتحقق" },
      { num: "2", name: "ابدأ بالإيداع" },
      { num: "3", name: "ابدأ التداول" },
    ],
    joinNow: "اشترك الآن",
  },
};
