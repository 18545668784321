<template>
  <div class="container-fluid gray-box py-5">
    <div class="container">
      <div class="row d-flex justify-content-center mb-2">
        <h2 class="col-12 text-center title" v-html="$t('stepsEarnings')"></h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10 col-12">
          <div class="row d-flex justify-content-center">
            <div
              v-for="(step, idx) in $tm('stepsEarningsPoints')"
              :key="idx"
              class="col-sm-4 col-8 text-center my-sm-5 my-3"
            >
              <img
                :src="`/img/steps/${step.img}.svg`"
                alt="icon"
                class="img-fluid icon my-2"
              />
              <p class="fs-4 fw-bold" v-html="step.name"></p>
              <p class="text-center fs-6" v-html="step.text"></p>
            </div>
            <div class="col-12 text-center">
              <a :href="registerURL" target="_blank" class="btn btn-blue text text-white">{{
                $t("becomeIB")
              }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StepEarnings",
};
</script>

<style lang="scss" scoped></style>
