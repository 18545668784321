<template>
  <div class="trading-condition">
    <div class="row">
      <div class="col-12 condition mb-4">
        <button
          @click="openCondition = !openCondition"
          :class="`btn btn-blue w-100 ${openCondition == true ? 'active' : ''}`"
        >
          <h2 class="mb-0 py-2">{{ condition.name }}</h2>
        </button>
      </div>
    </div>
    <div v-if="openCondition" class="openCondition mb-4" v-scrollanimation>
      <table class="w-100">
        <tr v-if="license == 'global'">
          <th
            v-for="(item, idx) in filterColumns()"
            :key="idx"
            class="text-center"
            v-html="item"
          ></th>
        </tr>
        <tr v-if="license == 'eu'">
          <th
            v-for="(item, idx) in filterColumns()"
            :key="idx"
            class="text-center"
            v-html="item"
          ></th>
        </tr>
        <tr
          v-for="(ticker, idx) in condition.conditionInfo"
          :key="idx"
          class="condition-info"
        >
          <td class="text-center">{{ ticker.symbol }}</td>
          <td class="text-center">{{ ticker.description }}</td>
          <td class="text-center">{{ ticker.maxLevRetail }}</td>
          <td class="text-center" v-if="license == 'eu'">
            {{ ticker.maxLevProfessional }}
          </td>
          <td class="text-center">{{ ticker.minTradeSize }}</td>
          <td class="text-center">{{ ticker.mintepSize }}</td>
          <td class="text-center">{{ ticker.contractSize }}</td>
          <td class="text-center">{{ ticker.long }}</td>
          <td class="text-center">{{ ticker.short }}</td>
          <td class="text-center">{{ ticker.tradingHours }}</td>
          <td v-if="dividendColumns" class="text-center">
            {{ ticker.todayDividendLong ? ticker.todayDividendLong : "0" }}
          </td>
          <td v-if="dividendColumns" class="text-center">
            {{ ticker.todayDividendShort ? ticker.todayDividendShort : "0" }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "TradingCondition",
  data: () => ({
    openCondition: false,
  }),
  props: {
    condition: Object,
  },
  computed: {
    license() {
      return getters.license();
    },
    name() {
      return this.condition.name;
    },
    columns() {
      return this.license === "eu"
        ? this.$tm("tradingConditionsColsEu")
        : this.$tm("tradingConditionsColsGlobal");
    },
    dividendColumns() {
      return this.name === "Indices" || this.name === "Shares";
    },
  },
  methods: {
    filterColumns() {
      let newColumns = [...this.columns];
      if (["Indices", "Shares"].includes(this.name)) {
        return this.columns;
      } else {
        return newColumns.splice(0, this.license === "eu" ? 10 : 9);
      }
    },
  },
};
</script>
