export default {
  en: {
    platformsTitle: "Professional Trading Platforms",
    choosePlatform: `Choose a Platform`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "We are proud to use the award-winning Meta Trader 4 platform. Giving traders over 50 charting tools and built-in indicators to guide their trading strategy in a customizable, efficient and simple platform.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      
      },
      {
        name: "Mobile Trading",
        desc: "Trade on the go! MT4 is a state-of-the-art Mobile Trading application available through App Store.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "Web Trader",
        desc: "Trading is easier than ever with our MetaTrader 4 Web Trader platform. It’s a web based MT4 that you can use on any computer or mobile device with no installations.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Allows money managers to handle multiple accounts   using one main account.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  fr: {
    platformsTitle: "Plateformes de Trading Professionnelles",
    choosePlatform: `Choisir une Plateforme`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "Nous sommes fiers d’utiliser la plateforme MetaTrader4. Les traders peuvent choisir parmi plus de 50 outils graphiques et indicateurs intégrés.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "Trading Mobile",
        desc: "Tradez peu importe où vous êtes! L’application mobile MetaTrader 4 est disponible sur Google App ou l'App Store.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "Web Trader",
        desc: "Le WebTrader est un supplément à votre MT4 et permet de trader depuis n’importe quel ordinateur, tablette ou smartphone.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Gérer plusieurs comptes à partir d’un seul.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  it: {
    platformsTitle: `Piattaforma Trading Professionale`,
    choosePlatform: `Scegli una Piattaforma`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "Siamo orgogliosi di utilizzare la pluripremiata piattaforma MetaTrader 4. Dare ai trader oltre 50 strumenti grafici e indicatori integrati tra cui scegliere.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "Mobile Trading",
        desc: "Fai trading in movimento! L'MT4 è un'applicazione di trading mobile all'avanguardia disponibile tramite l'App Store.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "Web Trader",
        desc: "Il WebTrader è una piattaforma MT4 basata sul web che ti consente di fare trading da qualsiasi computer, tablet o dispositivo mobile senza installazioni.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Gestire più account tramite un account principale.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  de: {
    platformsTitle: `Professionelle Trading-Plattformen`,
    choosePlatform: `Wähle eine Plattform`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "Wir sind stolz darauf die preisgekrönte Plattform Meta Trader 4 zu verwenden. Sie bietet Händlern über 50 Charting-Tools und integrierte Indikatoren zur Auswahl.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "Mobiles Traden",
        desc: "Mobiles Traden! MetaTrader 4 ist eine einzigartige mobile Handelsapp verfügbar im Apple und Android Store.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "Web Trader",
        desc: "Der WebTrader ist eine webbasierte MT4-Plattform, die es Ihnen ermöglicht von Ihrem Computer, Tablet oder Handy aus ohne zusätzliche Installation zu traden.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Ermöglicht es, mehrere Konten über ein Hauptkonto zu verwalten.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  cn: {
    platformsTitle: `专业的交易平台`,
    choosePlatform: `选择平台`,
    platform: [
      {
        name: "MetaTrader 4",
        desc: "我们很自豪能够使用屡获殊荣的MetaTrader4平台。为交易者提供50多种图表工具和内置指标可供选择",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "移动交易",
        desc: "随时随地交易！MT4是最先进的移动交易应用程序，可通过App Store获得。",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "网页交易",
        desc: "WebTrader是一个基于网络的MT4平台，让您无需安装即可从任何电脑、平板电脑或移动设备进行交易。",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "允许资金经理通过一个主账户管理多个账户。",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  es: {
    platformsTitle: `Plataformas de negociación profesionales`,
    choosePlatform: `Elija una Plataforma`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "Estamos orgullosos de utilizar la galardonada plataforma MetaTrader 4. Ofreciendo a los comerciantes más de 50 herramientas de gráficos e indicadores incorporados para elegir.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "Trading Móvil",
        desc: "Opere siempre! El MT4 es una aplicación móvil de comercio de última generación disponible a través de la App Store.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "WebTrader",
        desc: "WebTrader es una plataforma MT4 basada en la web que le permite operar desde cualquier computadora, tableta o dispositivo móvil sin instalaciones.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Permite a los administradores de dinero manejar múltiples cuentas a través de una cuenta principal.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  vnm: {
    platformsTitle: `Nền tảng giao dịch chuyên nghiệp`,
    choosePlatform: `Chọn một nền tảng`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "Chúng tôi tự hào sử dụng giải thưởng chiến thắng nền tảng MetaTrader 4. Cung cấp cho các nhà giao dịch hơn 50 công cụ biểu đồ và chỉ báo tích hợp để lựa chọn. ",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "Giao dịch di động",
        desc: "Giao dịch khi đang di chuyển! MT4 là một ứng dụng Giao dịch Di động hiện đại có sẵn thông qua App Store. ",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "Web Trader",
        desc: "WebTrader là một trang web Nền tảng MT4 cho phép bạn giao dịch từ bất kỳ máy tính hoặc thiết bị di động nào mà không cần cài đặt. ",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Cho phép người quản lý tiền xử lý nhiều tài khoản thông qua một tài khoản chính. ",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  enUae: {
    platformsTitle: "Professional Trading Platforms",
    choosePlatform: `Choose a Platform`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "We are proud to use the award-winning Meta Trader 4 platform. Giving traders over 50 charting tools and built-in indicators to guide their trading strategy in a customizable, efficient and simple platform.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "Mobile Trading",
        desc: "Trade on the go! MT4 is a state-of-the-art Mobile Trading application available through App Store.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "Web Trader",
        desc: "Trading is easier than ever with our MetaTrader 4 Web Trader platform. It’s a web based MT4 that you can use on any computer or mobile device with no installations.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Allows money managers to handle multiple accounts   using one main account.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  arb: {
    platformsTitle: "منصات تداول احترافية",
    choosePlatform: `اختر منصة`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "نحن فخورون باستخدام منصة MetaTrader 4 الحائزة على جوائز. منح المتداولين أكثر من 50 أداة رسم بياني ومؤشرات مدمجة للاختيار من بينها.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "التداول عبر الجوال",
        desc: "تداول أثناء التنقل! MT4 هو تطبيق تداول متطور عبر الهاتف المتحرك متاح من خلال متجر التطبيقات.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "ويب تريدر",
        desc: "ويب تريدر هي منصة MT4 على شبكة الإنترنت تتيح لك التداول من أي جهاز كمبيوتر أو جهاز محمول دون تثبيت.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "PAMM/MAM Trading",
        desc: "يسمح لمديري الأموال بالتعامل مع حسابات متعددة من خلال حساب رئيسي واحد.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
  my: {
    platformsTitle: "Platform Perdagangan Profesional",
    choosePlatform: `Pilih Platform`,
    platform: [
      {
        name: "Meta Trader 4",
        desc: "Kami bangga menggunakan anugerah tersebut memenangi platform MetaTrader 4. Memberi pedagang lebih daripada 50 alat grafik dan petunjuk terbina dalam untuk dipilih.",
        link: "meta-trader",
        homePosition: "left",
        img: "mt4",
        imgDark: "mt4-gold",
      },
      {
        name: "Perdagangan Mudah Alih",
        desc: "Perdagangan dalam perjalanan! MT4 adalah aplikasi Perdagangan Mudah Alih terkini yang tersedia melalui App Store.",
        link: "mobile-trading",
        homePosition: "left",
        img: "mobile",
        imgDark: "mobile-gold",
      },
      {
        name: "WebTrader",
        desc: "WebTrader Adalah berasaskan web Platform MT4 yang membolehkan anda berdagang dari komputer atau peranti mudah alih tanpa pemasangan.",
        link: "web-trader",
        homePosition: "right",
        img: "webtrader",
        imgDark: "webtrader-gold",
      },
      {
        name: "MAM/PAMM",
        desc: "Membolehkan pengurus wang pergi mengendalikan beberapa akaun melalui satu akaun utama.",
        link: "mam",
        homePosition: "right",
        img: "mam",
        imgDark: "mam-gold",
      },
    ],
  },
};
