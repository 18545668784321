<template>
  <div v-for="(item, idx) in $tm('cpaInfo')" :key="idx">
    <div class="container-fluid container-top accounts d-flex align-items-center half cpa">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5 mb-2">
          <h2 class="col-12 text-center title text-gold">
            {{ item.head }}
          </h2>
        </div>
        <div class="row">
          <div class="col-12">
            <h2 class="text-white text-center" v-html="item.titleTwo"></h2>
          </div>
          <div class="col-12 text-center my-4">
            <img
              src="/img/partnerships/cpa/desktop.png"
              alt="ipad"
              class="img-fluid"
            />
          </div>
          <div class="col-12 text-center pb-md-5">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{
              $t("becomeCPA")
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="row d-flex justify-content-center mb-2">
        <h2 class="col-12 title text-center">
          {{ item.title }}
        </h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          class="col-lg-8 col-md-10 col-12 text-center"
          v-html="item.text"
        ></div>
      </div>
    </div>
    <div class="container-fluid gray-box py-5">
      <div class="container">
        <div class="row d-flex flex-column align-items-center">
          <h2 class="col-lg-8 col-md-10 col-12 title text-center">
            {{ item.titleThree }}
          </h2>
          <hr class="blue" />
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-10 text-center my-4">
            <img
              src="/img/partnerships/cpa/kubiki.png"
              alt="image"
              class="img-fluid"
            />
          </div>
          <div
            class="col-lg-8 col-md-10 col-12 text-center"
            v-html="item.textTwo"
          ></div>
        </div>
      </div>
    </div>
    <div class="conatiner py-5">
      <div class="row d-flex justify-content-center mb-4">
        <h2 class="col-12 text-center title" v-html="item.benefits"></h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center" v-scrollanimation>
        <div class="col-lg-8 col-11">
          <div class="row d-flex justify-content-center">
            <div
              v-for="(point, idx) in item.points"
              :key="idx"
              class="col-lg-4 col-6 text-center"
            >
              <img
                :src="`/img/partnerships/cpa/${point.img}.svg`"
                alt="icon"
                class="img-fluid icon my-2"
              />
              <p class="text-center fs-5" v-html="point.name"></p>
            </div>
          </div>
        </div>
        <div class="col-12 text-center mt-4">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{
            $t("becomeCPA")
          }}</a>
        </div>
      </div>
    </div>
    <div class="container-fluid black-box py-5">
      <div class="row d-flex justify-content-center">
        <h1 class="col-12 text-center text-capitalize text-white fs-bold">
          {{ $t("upTo") }}
        </h1>
        <div class="col-12 text-center" v-scrollanimation>
          <img src="/img/partnerships/1000.png" alt="$1000" class="img-fluid" />
        </div>
        <h1 class="col-12 text-center text-capitalize text-white fs-bold">
          {{ item.cpa }}
        </h1>
        <p
          class="col-lg-8 col-md-10 col-12 text-white text-center fs-5 mb-0"
          v-html="item.textThree"
        ></p>
        <h1 class="col-12 text-center text-capitalize text-gold fs-bold">
          {{ item.titleFour }}
        </h1>
        <p
          class="col-lg-8 col-md-10 col-12 text-white text-center fs-5 mb-0"
          v-html="item.textFour"
        ></p>
        <div class="col-12 text-center mt-4">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{
            $t("becomeCPA")
          }}</a>
        </div>
      </div>
    </div>
    <StepsEarnings v-scrollanimation />
    <Form v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
import StepsEarnings from "../components/StepsEarnings.vue";
import Form from "../components/Form.vue";
export default {
  name: "CPA",
  components: {
    StepsEarnings,
    Form,
  },
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
};
</script>
<style lang="scss" scoped></style>
