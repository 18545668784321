let license =
  location.host.includes(".eu") || location.host.includes("8080")
    ? "eu"
    : "global";
let ecn = license == "global" ? "- ECN" : "";
let leverage = license == "global" ? `1:500` : `1:30`;
export default {
  en: {
    accountTypes: "Account Types",
    minimumFirst: "Minimun first deposit",
    speed: "Avg Execution Speed",
    minDeposit: `Min. Deposit`,
    commission: `Commission per side`,
    leverage: `Leverage`,
    tradeVol: `Min. Trade Volume`,
    execution: "Execution Quality",
    spread: `Spread`,
    currency: `Account Currency`,
    instruments: `Instruments`,
    marginCall: `Margin Call/Stop Out`,
    commodities: "Commodities",
    orEquivalent: "or equivalent",
    lot: "lot",
    second: "second",
    commissions: [
      {
        name: "Basic",
        minDeposit: "100",
        commission: `0 COMMISSION`,
        leverage: `up to ${leverage}`,
        execution: "no rejection/no requotes",
        spread: "2.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "basic",
      },
      {
        name: "Standard",
        minDeposit: "5000",
        commission: `0 COMMISSION`,
        leverage: `up to ${leverage}`,
        execution: "no rejection/no requotes",
        spread: "1.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "standard",
      },
      {
        name: "Advanced",
        minDeposit: "25,000",
        commission: `0 COMMISSION`,
        leverage: `up to ${leverage} `,
        execution: "no rejection/no requotes",
        spread: "1.1 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "advanced",
      },
      {
        name: `Premium ${ecn}`,
        minDeposit: "50,000",
        commission: `$4`,
        leverage: `up to ${leverage} `,
        execution: "no rejection/no requotes",
        spread: "0.0 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "premium",
      },
    ],
  },
  fr: {
    ccountTypes: "Types de Compte",
    minimumFirst: "Premier dépôt minimum",
    speed: "Vitesse d'exécution moyenne",
    minDeposit: `Min. Dépôt`,
    commission: `Commision par côté`,
    leverage: `Effet de levier`,
    tradeVol: `Volume min. Volume de transaction`,
    execution: "Qualité d'exécution",
    spread: `Spread`,
    currency: `Devise du compte`,
    instruments: `Instruments`,
    marginCall: `Appel de marge/Stop Out`,
    commodities: "Matières premières",
    orEquivalent: "ou l'équivalent",
    lot: "lot",
    second: "seconde",
    commissions: [
      {
        name: "Basic",
        minDeposit: "100",
        commission: `0 COMMISSION`,
        leverage: `Jusqu'à ${leverage}`,
        execution: "aucun rejet/aucune requote",
        spread: "2.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "basic",
      },
      {
        name: "Standard",
        minDeposit: "5000",
        commission: `0 COMMISSION`,
        leverage: `Jusqu'à ${leverage}`,
        execution: "aucun rejet/aucune requote",
        spread: "1.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "standard",
      },
      {
        name: "Advanced",
        minDeposit: "25,000",
        commission: `0 COMMISSION`,
        leverage: `Jusqu'à ${leverage} `,
        execution: "aucun rejet/aucune requote",
        spread: "1.1 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "advanced",
      },
      {
        name: `Premium ${ecn}`,
        minDeposit: "50,000",
        commission: `$4`,
        leverage: `Jusqu'à ${leverage} `,
        execution: "aucun rejet/aucune requote",
        spread: "0.0 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "premium",
      },
    ],
  },
  it: {
    accountTypes: "Tipi di Conto",
    minimumFirst: "Primo deposito minimo",
    speed: "Velocità di esecuzione media",
    minDeposit: `Deposito min.`,
    commission: `Commissione per parte`,
    leverage: `Leva`,
    tradeVol: `Min. Volume degli scambi`,
    execution: "Qualità dell'esecuzione",
    spread: `Spread`,
    currency: `Conto Valuta`,
    instruments: `Strumenti`,
    marginCall: `Chiamata di margine/Stop Out`,
    commodities: "Materie prime",
    orEquivalent: "o equivalente",
    lot: "lotto",
    second: "secondo",
    commissions: [
      {
        name: "Basic",
        minDeposit: "100",
        commission: `0 COMMISSION`,
        leverage: `Fina a ${leverage}`,
        execution: "nessun rifiuto/nessuna richiesta",
        spread: "2.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "basic",
      },
      {
        name: "Standard",
        minDeposit: "5000",
        commission: `0 COMMISSION`,
        leverage: `Fina a ${leverage}`,
        execution: "nessun rifiuto/nessuna richiesta",
        spread: "1.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "standard",
      },
      {
        name: "Advanced",
        minDeposit: "25,000",
        commission: `0 COMMISSION`,
        leverage: `Fina a ${leverage} `,
        execution: "nessun rifiuto/nessuna richiesta",
        spread: "1.1 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "advanced",
      },
      {
        name: `Premium ${ecn}`,
        minDeposit: "50,000",
        commission: `$4`,
        leverage: `Fina a ${leverage} `,
        execution: "nessun rifiuto/nessuna richiesta",
        spread: "0.0 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "premium",
      },
    ],
  },
  de: {
    accountTypes: "Kontotypen",
    minimumFirst: "Minimale Ersteinzahlung",
    speed: "Durchschnittliche Ausführungsgeschwindigkeit",
    minDeposit: `Min. Einzahlung`,
    commission: `Provision pro Seite`,
    leverage: `Hebel`,
    tradeVol: `Min. Handelsvolumen`,
    execution: "Qualität der Ausführung",
    spread: `Spread`,
    currency: `Konto Währung`,
    instruments: `Instrumente`,
    marginCall: `Margin Call/Stop Out`,
    commodities: "Rohstoffe",
    orEquivalent: "oder gleichwertige",
    lot: "lot",
    second: "zweite",
    commissions: [
      {
        name: "Basic",
        minDeposit: "100",
        commission: `0 COMMISSION`,
        leverage: `bis zu ${leverage}`,
        execution: "keine Ablehnung/keine Requotes",
        spread: "2.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "basic",
      },
      {
        name: "Standard",
        minDeposit: "5000",
        commission: `0 COMMISSION`,
        leverage: `bis zu ${leverage}`,
        execution: "keine Ablehnung/keine Requotes",
        spread: "1.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "standard",
      },
      {
        name: "Advanced",
        minDeposit: "25,000",
        commission: `0 COMMISSION`,
        leverage: `bis zu ${leverage} `,
        execution: "keine Ablehnung/keine Requotes",
        spread: "1.1 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "advanced",
      },
      {
        name: `Premium ${ecn}`,
        minDeposit: "50,000",
        commission: `$4`,
        leverage: `bis zu ${leverage} `,
        execution: "keine Ablehnung/keine Requotes",
        spread: "0.0 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "premium",
      },
    ],
  },
  cn: {
    accountTypes: "账户类型",
    minimumFirst: "最低首次入金",
    speed: "平均执行速度",
    minDeposit: `最低入金`,
    commission: `单边佣金`,
    leverage: `杠杆`,
    tradeVol: `最小交易量`,
    execution: "执行质量",
    spread: `点差`,
    currency: `账户币种`,
    instruments: `交易产品`,
    marginCall: `追加保证金/强制平仓`,
    commodities: "大宗商品",
    orEquivalent: "或相当的",
    lot: "手数",
    second: "秒",
    commissions: [
      {
        name: "标准账户",
        minDeposit: "500",
        commission: `0 佣金`,
        leverage: `最高 1:500`,
        execution: "无拒绝/无重新报价",
        spread: "1.4",
        currency: "USD",
        marginCall: "120% / 50%",
        class: "standard",
      },
      // {
      //   name: "高级账户",
      //   minDeposit: "10,000",
      //   commission: `0 佣金`,
      //   leverage: `最高 1:500`,
      //   execution: "无拒绝/无重新报价",
      //   spread: "1.1 起",
      //   currency: "USD",
      //   marginCall: "120% / 50%",
      //   class: "advanced",
      // },
      {
        name: `ECN账户`,
        minDeposit: "20,000",
        commission: `$3.5`,
        leverage: `最高 1:500`,
        execution: "无拒绝/无重新报价",
        spread: "0.0",
        currency: "USD",
        marginCall: "120% / 50%",
        class: "premium",
      },
    ],
  },
  es: {
    accountTypes: "Tipos de Cuenta",
    minimumFirst: "Primer depósito mínimo",
    speed: "Velocidad media de ejecución",
    minDeposit: `Mínimo Depósito`,
    commission: `Comisión por lado`,
    leverage: `Apalancamiento`,
    tradeVol: `Mínimo. Volumen de operaciones`,
    execution: "Calidad de ejecución",
    spread: `Spread`,
    currency: `Cuenta Moneda`,
    instruments: `Instrumentos`,
    marginCall: `Llamada de Margen/Stop Out`,
    commodities: "Productos básicos",
    orEquivalent: "o su equivalente",
    lot: "lote",
    second: "segundo",
    commissions: [
      {
        name: "Basic",
        minDeposit: "100",
        commission: `0 COMISIÓN`,
        leverage: `hasta ${leverage}`,
        execution: "sin rechazo/sin recotizaciones",
        spread: "2.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "basic",
      },
      {
        name: "Standard",
        minDeposit: "5000",
        commission: `0 COMISIÓN`,
        leverage: `hasta ${leverage}`,
        execution: "sin rechazo/sin recotizaciones",
        spread: "1.4 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "standard",
      },
      {
        name: "Advanced",
        minDeposit: "25,000",
        commission: `0 COMISIÓN`,
        leverage: `hasta ${leverage} `,
        execution: "sin rechazo/sin recotizaciones",
        spread: "1.1 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "advanced",
      },
      {
        name: `Premium ${ecn}`,
        minDeposit: "50,000",
        commission: `$4`,
        leverage: `hasta ${leverage} `,
        execution: "sin rechazo/sin recotizaciones",
        spread: "0.0 pips",
        currency: "USD-EUR",
        currencyEU: "USD-EUR-GBP-CHF",
        marginCall: "120% / 50%",
        class: "premium",
      },
    ],
  },
  vnm: {
    accountTypes: "Các loại tài khoản",
    minimumFirst: "Số tiền gửi đầu tiên tối thiểu",
    speed: "Tốc độ thực thi trung bình",
    minDeposit: `Min. Tiền gửi`,
    commission: `Hoa hồng mỗi bên`,
    leverage: `Tận dụng`,
    tradeVol: `Min. Khối lượng giao dịch`,
    execution: "Chất lượng thực thi",
    spread: `Truyền đi`,
    currency: `Đơn vị tiền tệ của tài khoản`,
    instruments: `Dụng cụ`,
    marginCall: `Cuộc gọi ký quỹ / Dừng ra`,
    commodities: "Hàng hóa",
    orEquivalent: "hoặc tương đương",
    lot: "rất nhiều",
    second: "thứ hai",
    commissions: [
      {
        name: "Xu",
        minDeposit: "10",
        commission: `0 GIAO LƯU`,
        leverage: `lên đến 1:1000`,
        execution: "không từ chối / không yêu cầu",
        spread: "2.4 pips",
        currency: "USD",
        marginCall: "120% / 50%",
        class: "basic",
        time: `0.1`,
        seconds: `thứ hai`,
      },
      {
        name: "Tiêu chuẩn",
        minDeposit: "500",
        commission: `0 GIAO LƯU`,
        leverage: `lên đến 1:1000`,
        execution: "không từ chối / không yêu cầu",
        spread: "1.4 pips",
        currency: "USD",
        marginCall: "120% / 50%",
        class: "standard",
        time: `0.80`,
        seconds: `phần nghìn giây`,
      },
      // {
      //   name: "Advanced",
      //   minDeposit: "25,000",
      //   commission: `0 GIAO LƯU`,
      //   leverage: `lên đến 1:500 `,
      //   execution: "không từ chối / không yêu cầu",
      //   spread: "1.1 pips",
      //   marginCall: "120% / 50%",
      //   class: "advanced",
      // },
      // {
      //   name: `Premium - ECN`,
      //   minDeposit: "50,000",
      //   commission: `$4`,
      //   leverage: `lên đến 1:500 `,
      //   execution: "không từ chối / không yêu cầu",
      //   spread: "0.0 pips",
      //   marginCall: "120% / 50%",
      //   class: "premium",
      // },
    ],
  },
  enUae: {
    accountTypes: "Account Types",
    minimumFirst: "Minimun first deposit",
    speed: "Avg Execution Speed",
    minDeposit: `Min. Deposit`,
    commission: `Commission per side`,
    leverage: `Leverage`,
    tradeVol: `Min. Trade Volume`,
    execution: "Execution Quality",
    spread: `Spread`,
    currency: `Account Currency`,
    instruments: `Instruments`,
    marginCall: `Margin Call/Stop Out`,
    commodities: "Commodities",
    orEquivalent: "or equivalent",
    lot: "lot",
    second: "second",
    commissions: [
      {
        name: "Standard",
        minDeposit: "100",
        commission: `0 COMMISSION`,
        leverage: `up to ${leverage} `,
        execution: "no rejection/no requotes",
        spread: "1.1 pips",
        currency: "USD-EUR",
        marginCall: "120% / 50%",
        class: "basic",
      },
      {
        name: "Advanced",
        minDeposit: "10,000",
        commission: `0 COMMISSION`,
        leverage: `up to ${leverage} `,
        execution: "no rejection/no requotes",
        spread: "0.8 pips",
        currency: "USD-EUR",
        marginCall: "120% / 50%",
        class: "standard",
      },
      {
        name: `Premium  ${ecn}`,
        minDeposit: "20,000",
        commission: `$3`,
        leverage: `up to ${leverage} `,
        execution: "no rejection/no requotes",
        spread: "0.0 pips",
        currency: "USD-EUR",
        marginCall: "120% / 50%",
        class: "advanced",
      },
      // {
      //   name: `Swap Free`,
      //   minDeposit: "1000",
      //   commission: `0 COMMISSION`,
      //   leverage: `up to ${leverage} `,
      //   execution: "no rejection/no requotes",
      //   spread: "1.5 pips",
      //   marginCall: "120% / 50%",
      //   class: "premium",
      // },
    ],
  },
  arb: {
    accountTypes: "أنواع الحسابات",
    minimumFirst: "الحد الأدنى للإيداع الأول",
    speed: "متوسط سرعة التنفيذ",
    minDeposit: `الحد الأدنى للإيداع`,
    commission: `العمولة لكل جانب`,
    leverage: `تأثير ايجابي`,
    tradeVol: `الحد الأدنى لحجم التداول`,
    execution: "جودة التنفيذ",
    spread: `الانتشار`,
    currency: `عملة الحساب`,
    instruments: `الأدوات`,
    marginCall: `نداء الهامش / الوقف الاضطراري`,
    commodities: "السلع",
    orEquivalent: "أو ما يعادلها",
    lot: "حصة",
    second: "الثاني",
    commissions: [
      {
        name: "Standard",
        minDeposit: "100",
        commission: `0 عمولة`,
        leverage: `يصل إلى  ${leverage}`,
        execution: "لا رفض / لا إعادة تسعير",
        spread: "1.1 pips",
        currency: "USD-EUR",
        marginCall: "120% / 50%",
        class: "basic",
      },
      {
        name: "Advanced",
        minDeposit: "10,000",
        commission: `0 عمولة`,
        leverage: `يصل إلى  ${leverage}`,
        execution: "لا رفض / لا إعادة تسعير",
        spread: "0.8 pips",
        currency: "USD-EUR",
        marginCall: "120% / 50%",
        class: "standard",
      },
      {
        name: "Premium - ECN",
        minDeposit: "20,000",
        commission: `$3`,
        leverage: `يصل إلى  ${leverage}`,
        execution: "لا رفض / لا إعادة تسعير",
        spread: "0.0 pips",
        currency: "USD-EUR",
        marginCall: "120% / 50%",
        class: "advanced",
      },
      // {
      //   name: `Swap Free`,
      //   minDeposit: "1000",
      //   commission: `0 عمولة`,
      //   leverage: `يصل إلى `,
      //   execution: "لا رفض / لا إعادة تسعير",
      //   spread: "1.5 pips",
      //   marginCall: "120% / 50%",
      //   class: "premium",
      // },
    ],
  },
  my: {
    accountTypes: "Jenis Akaun",
    minimumFirst: "Minimun deposit pertama",
    speed: "Purata Kelajuan Pelaksanaan",
    minDeposit: `Min. Deposit`,
    commission: `Suruhanjaya setiap pihak`,
    leverage: `Leverage`,
    tradeVol: `Min. Jumlah dagangan`,
    execution: "Kualiti Pelaksanaan",
    spread: `Spread`,
    currency: `Mata Wang Akaun`,
    instruments: `Instrumen`,
    marginCall: `Panggilan Margin / Berhenti`,
    commodities: "Komoditi",
    orEquivalent: "atau setaraf",
    lot: "banyak",
    second: "saat",
    commissions: [
      {
        name: "Standard",
        minDeposit: "10",
        commission: `0 Komisyen`,
        leverage: `hingga 1:2000`,
        execution: "tidak ada penolakan / tidak ada permintaan",
        spread: "1.2 pips",
        currency: "USD",
        marginCall: "50% / 20%",
        class: "basic",
      },
      {
        name: "TrioCent",
        minDeposit: "10",
        commission: `0 Komisyen`,
        leverage: `hingga 1:2000`,
        execution: "tidak ada penolakan / tidak ada permintaan",
        spread: "1.2 pips",
        currency: "USD",
        marginCall: "50% / 20%",
        class: "standard",
      },
      {
        name: "TrioECN",
        minDeposit: "100",
        commission: `$3`,
        leverage: `hingga 1:2000`,
        execution: "tidak ada penolakan / tidak ada permintaan",
        spread: "0.0 pips",
        currency: "USD",
        marginCall: "50% / 20%",
        class: "advanced",
      },
      {
        name: `VIP`,
        minDeposit: "1000",
        commission: `0 Komisyen`,
        leverage: `hingga 1:1000`,
        execution: "tidak ada penolakan / tidak ada permintaan",
        spread: "0.6 pips",
        currency: "USD-EUR",
        marginCall: "50% / 20%",
        class: "premium",
      },
    ],
  },
};
