<template>
  <div class="container-fluid container-top accounts mobileApp">
    <div class="container-fluid mobile-bg">
      <div class="container accounts-info p-5 mt-5">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="text-center title text-white">
            {{ $t("MobileApp") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row justify-content-center">
          <div class="text-center text-white col-md-6 justify-content-center">
            {{ $t("MobileAppText") }}
          </div>
          <div class="justify-content-center mt-4">
            <div class="row d-flex justify-content-center">
              <h5 class="text-white text-center">{{ $t("Download") }}</h5>
              <div class="d-flex justify-content-center text-center app">
                <a target="_blank" href="/">
                  <img
                    src="/img/platforms/MobileApp/AppstoreWhite.svg"
                    alt="download-apple"
                    class="img-fluid"
                  />
                </a>
                <a target="_blank" href="/">
                  <img
                    src="/img/platforms/MobileApp/PlaystoreWhite.svg"
                    alt="download-play-store"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center justify-content-center p-5 ml-5" v-scrollanimation>
    <img
      src="/img/platforms/MobileApp/LogoDark.svg"
      alt="Logo Dark"
      class="img-fluid"
      id="LogoDark"
    />
  </div>
  <div :class="`container ${checkArb ? 'flip-content' : ''}`" v-scrollanimation>
    <div class="row d-flex justify-content-center">
      <h2 class="text-center">{{ $t("WhyChoose") }}</h2>
      <hr class="blue" />
    </div>
    <div class="row d-flex justify-content-center">
      <div class="col-lg-8">
        <div class="row d-flex justify-content-center my-lg-5 my-4">
          <div
            class="col-md-6 col-sm-10 col-11 mb-lg-2 mb-4 points"
            v-for="(check, id) in CheckList"
            :key="id"
          >
            <div class="feature-box d-flex points align-items-center">
              <img
                src="/img/check.svg"
                class="img-fluid check mb-lg-0 mb-2 mt-lg-0 mt-3 me-lg-3"
                alt="check"
              />
              <p class="mb-lg-0 fs-5 lh-sm" v-html="$t(check.text)"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    :class="`container  p-5 text-center ${checkArb ? 'flip-content' : ''}`"
    v-scrollanimation
  >
    <div class="row d-flex justify-content-center text-center">
      <h2 class="text-center">{{ $t("GetStarted") }}</h2>
      <hr class="blue" />
    </div>
    <div class="d-flex">
      <div class="col-md-7 p-2 mt-3" id="textMobile">
        <div id="textMobile" class="fs-5" v-html="$t('GetStartedText')"></div>
        <div class="row d-flex mt-5">
          <div
            :class="`${checkArb ? ' ' : ' '}`"
            v-html="$t('DownloadText')"
            id="DownloadText"
          ></div>
          <div class="col-lg-6 col-md-8 mt-2">
            <div class="row d-flex justify-content-start">
              <div class="col-6 mb-sm-0 mb-3 text-start">
                <a target="_blank" href="/" class="">
                  <img
                    src="/img/platforms/MobileApp/app-store.svg"
                    alt="download-apple"
                    class="img-fluid"
                  />
                </a>
              </div>
              <div class="col-6 mb-sm-0 mb-4 text-start">
                <a target="_blank" href="/" class="">
                  <img
                    src="/img/platforms/MobileApp/google-play.svg"
                    alt="download-play-store"
                    class="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative">
        <div
          :class="`posoition-absolute d-flex top-50 ${
            checkArb ? 'start-0' : 'end-0'
          }`"
        >
          <!-- <img
            src="/img/platforms/MobileApp/ShowApp.png"
            class="img-fluid d-none d-sm-block"
            alt="Show App"
            id="mobileImg"
          /> -->
          <img
            src="/img/platforms/MobileApp/screenshots.png"
            class="img-fluid d-none d-sm-block"
            alt="Show App"
          />
        </div>
      </div>
    </div>
  </div>
  <Accounts v-scrollanimation />
  <Platforms v-scrollanimation />
  <Steps v-scrollanimation />
</template>
<script>
import { getters } from "../store";
export default {
  data() {
    return {
      CheckList: [
        { text: "Choose1" },
        { text: "Choose2" },
        { text: "Choose3" },
        { text: "Choose4" },
        { text: "Choose5" },
        { text: "Choose6" },
      ],
    };
  },
  computed: {
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
