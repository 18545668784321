<template>
  <div v-for="(item, idx) in $tm('swappFreeInfo')" :key="idx">
    <div class="container-fluid container-top accounts swappFree">
        
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ item.title }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-md-8 col-lg-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row d-flex justify-content-center">
        <div class="col-12 text-trio text-center fw-bold fs-6">
          {{ item.noCharge }}
        </div>
        <div class="col-md-8 col-lg-10 col-12 text-trio text-center mt-4">
          {{ item.tradingSwapp }}
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center text-trio">
          {{ item.table }}
        </h2>
        <hr class="blue mbhr" />
      </div>
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <table>
            <thead>
              <tr>
                <th class="text-white">{{ item.symbols }}</th>
                <th class="text-white">{{ item.charge }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, idx) in item.items" :key="idx">
                <td>{{ row.s }}</td>
                <td>{{ row.num }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="col-12 text-trio text-center mt-2"
          v-html="item.exotic"
        ></div>
      </div>
    </div>
    <div class="container-fluid gray-box py-5">
      <div class="row d-flex justify-content-center">
        <p class="text-center text-trio fw-bold">{{ item.offer }}</p>
        <p class="col-md-8 col-lg-10 col-12 text-center text-trio">{{ item.countries }}</p>
        <p class="col-md-8 col-lg-10 col-12 text-center text-trio">{{ item.clients }}</p>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
  </div>
</template>
<script>
export default {
  name: "Swapp Free",
};
</script>
<style lang="scss" scoped>
$trio: #00162c;
$footer-bg: #f2f3f4;
table {
  text-align: center;
  thead {
    background: $trio;
    th {
      padding: 2rem 4rem;
      text-align: center;
    }
  }
  tr {
    &:nth-child(2n) {
      background-color: $footer-bg;
    }
  }
  td {
    padding: 0.5rem;
  }
}
</style>
