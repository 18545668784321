export default {
  en: {
    liveQuotes: "Live Quotes",
    liveQuotesRow: [
      { name: "Symbol" },
      { name: "Bid" },
      { name: "Ask" },
      { name: "Trend" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "Prices above are subject to our website terms and conditions. Prices are indicative only.",
    liveQuotesTabs: [
      {
        title: "Forex",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Indices",
        class: "",
        icon: "safe",
      },
      {
        title: "Commodites",
        class: "",
        icon: "commodites",
      },
      {
        title: "Crypto",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Shares",
        class: "",
        icon: "shares",
      },
    ],
    sell: `SELL`,
    buy: `BUY`,
    low: `Low`,
    high: `High`,
    swapShort: `Swap Short`,
    swapLong: `Swap Long`,
    hoursPriceRange: `24 hours price change (GMT +0)`,
  },
  fr: {
    liveQuotes: "Cotations en Temps Réel",
    liveQuotesRow: [
      { name: "Symbole" },
      { name: "Demande" },
      { name: "Offre" },
      { name: "Tendance" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "Les prix ci-dessus sont soumis aux conditions générales de notre site Web. Les prix sont à titre indicatif seulement.",
    liveQuotesTabs: [
      {
        title: "Forex",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Indices",
        class: "",
        icon: "safe",
      },
      {
        title: "Marchandises",
        class: "",
        icon: "commodites",
      },
      {
        title: "Crypto",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Actions",
        class: "",
        icon: "shares",
      },
    ],
    sell: `VENDRE`,
    buy: `ACHETER`,
    low: `Bas`,
    high: `Haut`,
    swapShort: `Swap Short`,
    swapLong: `Swap Long`,
    hoursPriceRange: `Changement de prix sur 24 heures (GMT +0)`,
  },
  it: {
    liveQuotes: "Quotazioni in Tempo Reale",
    liveQuotesRow: [
      { name: "Simbolo" },
      { name: "Domanda" },
      { name: "Offerta" },
      { name: "Tendenza" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "I prezzi sopra indicati sono soggetti ai termini e alle condizioni del nostro sito web. I prezzi sono solo indicativi.",
    liveQuotesTabs: [
      {
        title: "Forex",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Indici",
        class: "",
        icon: "safe",
      },
      {
        title: "Merci",
        class: "",
        icon: "commodites",
      },
      {
        title: "Cripto",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Azioni",
        class: "",
        icon: "shares",
      },
    ],
    sell: `VENDI`,
    buy: `COMPRA`,
    low: `Basso`,
    high: `Alto`,
    swapShort: `Swap Short`,
    swapLong: `Swap Long`,
    hoursPriceRange: `Cambio prezzo 24 ore (GMT +0)`,
  },
  de: {
    liveQuotes: "Live Kurse",
    liveQuotesRow: [
      { name: "Symbole" },
      { name: "Bid" },
      { name: "Ask" },
      { name: "Tendenz" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "Die oben genannten Preise unterliegen den Allegmeinen Geschäftsbedingungen auf unserer Website und dienen nur als Indikation.",
    liveQuotesTabs: [
      {
        title: "Devisenhandel",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Indizes",
        class: "",
        icon: "safe",
      },
      {
        title: "Rohstoffe",
        class: "",
        icon: "commodites",
      },
      {
        title: "Kryptowährungen",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Aktien",
        class: "",
        icon: "shares",
      },
    ],
    sell: `VERKAUFEN`,
    buy: `KAUFEN`,
    low: `Tief`,
    high: `Hoch`,
    swapShort: `Swap Short`,
    swapLong: `Swap Long`,
    hoursPriceRange: `24h Preisveränderungen (GMT+0)`,
  },
  cn: {
    liveQuotes: "实时报价",
    liveQuotesRow: [
      { name: "符号" },
      { name: "卖价" },
      { name: "买价" },
      { name: "趋向" },
      { name: "点差" },
    ],
    liveQuotesDisclaimer: "以上价格受我们网站条款和条件的约束。价格仅供参考。",
    liveQuotesTabs: [
      {
        title: "外汇",
        class: "active",
        icon: "arrows",
      },
      {
        title: "指数",
        class: "",
        icon: "safe",
      },
      {
        title: "商品",
        class: "",
        icon: "commodites",
      },
      {
        title: "加密货币",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "股票",
        class: "",
        icon: "shares",
      },
    ],
    sell: `卖`,
    buy: `买`,
    low: `低的`,
    high: `高的`,
    swapShort: `空头隔夜利息`,
    swapLong: `多头隔夜利息`,
    hoursPriceRange: `24小时价格变化 (GMT+0)`,
  },
  es: {
    liveQuotes: "Cotizaciones en Vivo",
    liveQuotesRow: [
      { name: "Símbolo" },
      { name: "Bid" },
      { name: "Ask" },
      { name: "Tendencia" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "Los precios anteriores están sujetos a los términos y condiciones de nuestro sitio web. Los precios son solo indicativos.",
    liveQuotesTabs: [
      {
        title: "Forex",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Índices",
        class: "",
        icon: "safe",
      },
      {
        title: "Materias primas",
        class: "",
        icon: "commodites",
      },
      {
        title: "Cripto",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Acciones",
        class: "",
        icon: "shares",
      },
    ],
    sell: `VENDER`,
    buy: `COMPRAR`,
    low: `Bajo`,
    high: `Alto`,
    swapShort: `Swap Short`,
    swapLong: `Swap Long`,
    hoursPriceRange: `Cambio de precio 24 horas (GMT +0)`,
  },
  vnm: {
    liveQuotes: "Báo giá trực tiếp",
    liveQuotesRow: [
      { name: "Mã giao dịch" },
      { name: "Bid" },
      { name: "Ask" },
      { name: "Xu hướng" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "Giá trên là tùy thuộc vào các điều khoản và điều kiện trang web của chúng tôi. Giá chỉ mang tính chất tham khảo ",
    liveQuotesTabs: [
      {
        title: "Ngoại hối",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Chỉ số",
        class: "",
        icon: "safe",
      },
      {
        title: "Hàng hóa",
        class: "",
        icon: "commodites",
      },
      {
        title: "Tiền điện tử",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Cổ phiếu",
        class: "",
        icon: "shares",
      },
    ],
    sell: `BÁN`,
    buy: `MUA`,
    low: `Thấp`,
    high: `Cao`,
    swapShort: `Swap Short`,
    swapLong: `Swap Long`,
    hoursPriceRange: `Giá thay đổi trong 24 giờ (GMT +0) `,
  },
  enUae: {
    liveQuotes: "Live Quotes",
    liveQuotesRow: [
      { name: "Symbol" },
      { name: "Bid" },
      { name: "Ask" },
      { name: "Trend" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "Prices above are subject to our website terms and conditions. Prices are indicative only.",
    liveQuotesTabs: [
      {
        title: "Forex",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Indices",
        class: "",
        icon: "safe",
      },
      {
        title: "Commodites",
        class: "",
        icon: "commodites",
      },
      {
        title: "Crypto",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Shares",
        class: "",
        icon: "shares",
      },
    ],
    sell: `SELL`,
    buy: `BUY`,
    low: `Low`,
    high: `High`,
    swapShort: `Swap Short`,
    swapLong: `Swap Long`,
    hoursPriceRange: `24 hours price change (GMT +0)`,
  },
  arb: {
    liveQuotes: "اقتباسات مباشرة",
    liveQuotesRow: [
      { name: "الرمز" },
      { name: "مُنَاقَصَة" },
      { name: "طلب" },
      { name: "تريند" },
      { name: "الانتشار" },
    ],
    liveQuotesDisclaimer:
      "الأسعار أعلاه تخضع لشروط وأحكام موقعنا. الأسعار إرشادية فقط.",
    liveQuotesTabs: [
      {
        title: "فوركس",
        class: "active",
        icon: "arrows",
      },
      {
        title: "المؤشرات",
        class: "",
        icon: "safe",
      },
      {
        title: "Commodites",
        class: "",
        icon: "commodites",
      },
      {
        title: `العملات المشفرة`,
        class: "",
        icon: "bitcoin",
      },
      {
        title: "الأسهم",
        class: "",
        icon: "shares",
      },
    ],
    sell: `بيع`,
    buy: `شراء`,
    low: `منخفض`,
    high: `مرتفع`,
    swapShort: `مبادلة قصيرة`,
    swapLong: `مبادلة طويلة`,
    hoursPriceRange: `تغيير السعر على مدار 24 ساعة (GMT +0)`,
  },
  my: {
    liveQuotes: "Petikan Langsung",
    liveQuotesRow: [
      { name: "Simbol" },
      { name: "Beli" },
      { name: "Jual" },
      { name: "Trend" },
      { name: "Spread" },
    ],
    liveQuotesDisclaimer:
      "Harga di atas adalah tertakluk kepada terma dan syarat laman web kami. Harga hanya menunjukkan.",
    liveQuotesTabs: [
      {
        title: "Forex",
        class: "active",
        icon: "arrows",
      },
      {
        title: "Indeks",
        class: "",
        icon: "safe",
      },
      {
        title: "Komoditi",
        class: "",
        icon: "commodites",
      },
      {
        title: "Cryptos",
        class: "",
        icon: "bitcoin",
      },
      {
        title: "Saham",
        class: "",
        icon: "shares",
      },
    ],
    sell: `JUAL`,
    buy: `BELI`,
    low: `Rendah`,
    high: `Tinggi`,
    swapShort: `Tukar Pendek`,
    swapLong: `Tukar Panjang`,
    hoursPriceRange: `Perubahan harga 24 jam ( GMT + 0 )`,
  },
};
