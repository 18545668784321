<template>
  <div v-for="(item, idx) in $tm('meetUsInfo')" :key="idx" id="meetUs">
    <div
      :class="`container-fluid container-top accounts meet-us ${
        license == 'global' ? 'global' : 'global'
      }`"
    >
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ item.aboutUs }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row">
          <div
            v-for="(text, idx) in item.whoWeAre"
            :key="idx"
            class="col-lg-4 col-12 mb-lg-0 mb-4"
          >
            <p :class="`text-white fs-4 mb-0 ${checkArb ? 'text-right' : ' '}`">
              {{ text.q }}
            </p>
            <p class="text-white fs-6 text-justify">{{ text.a }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="license == 'eu'" class="container" id="team">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">
          {{ item.team }}
        </h2>
        <hr class="blue mbhr" />
      </div>
      <div class="row d-flex justify-content-center my-5">
        <div class="col-md-10 col-11">
          <div class="row d-flex justify-content-center">
            <div
              v-for="(person, idx) in chuvaki"
              :key="idx"
              class="col-lg-4 col-md-6"
            >
              <div class="person">
                <div class="inside">
                  <div class="photo">
                    <picture
                      ><img
                        :src="`/img/company/meet-us/people/${person.img}`"
                        :alt="person.name"
                        class="img-fluid"
                    /></picture>
                  </div>
                  <div class="person-heading">
                    <h3>{{ `${person.name}` }}</h3>
                    <p>{{ person.title }}</p>
                  </div>
                </div>
                <div class="person-desc">
                  <p class="px-4">{{ person.text }}</p>
                </div>
                <div class="person-social">
                  <a target="_blank" :href="person.linkedin">
                    <img
                      src="/img/company/meet-us/people/linkedin.svg"
                      alt="LinkedIn"
                    />
                  </a>
                  <a target="_blank" :href="person.email">
                    <img
                      src="/img/company/meet-us/people/mail.svg"
                      alt="Mail"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div v-if="license == 'eu'" class="container-fluid black-box timeline py-5">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title text-white">
          {{ item.history }}
        </h2>
        <hr class="gold mbhr" />
      </div>
      <div class="container d-md-none my-5 position-relative">
        <div class="vl position-absolute"></div>
        <div class="row d-flex-justify-content-center">
          <div
            v-for="(box, idx) in timelineMobile"
            :key="idx"
            class="col-12 d-flex justify-content-center mb-3"
          >
            <div class="timeline-box mobile">
              <h3 class="mb-0" v-html="box.date"></h3>
              <hr class="hr timeline" />
              <p class="mb-0" v-html="box.text"></p>
              <div class="dot mobile"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-block d-none">
        <div class="container">
          <div class="row d-flex justify-content-between">
            <div
              v-for="(box, idx) in item.timeline[0]"
              :key="idx"
              class="col-md-2 boxes-top p-0"
            >
              <div class="timeline-box top">
                <h3 class="mb-0" v-html="box.date"></h3>
                <hr class="hr timeline" />
                <p class="mb-0" v-html="box.text"></p>
                <div class="dot dot-top"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="dotted"></div>
        <div class="container mb-5">
          <div class="row d-flex justify-content-between">
            <div
              v-for="(box, idx) in item.timeline[1]"
              :key="idx"
              class="col-md-2 boxes-bot p-0"
            >
              <div class="timeline-box bot">
                <h3 class="mb-0" v-html="box.date"></h3>
                <hr class="hr timeline" />
                <p class="mb-0" v-html="box.text"></p>
                <div class="dot dot-bot"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="license == 'global'" class="container my-5">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">{{ item.ourValues }}</h2>
        <hr class="blue mbhr" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          v-for="(t, idx) in item.threeTs"
          :key="idx"
          class="col-lg-4 col-md-7 col-sm-9 col-12 mb-lg-0 mb-4"
        >
          <div
            class="forex-box box-about text-center d-flex flex-column justify-content-between"
          >
            <div>
              <span class="text-primary fw-bold fs-4">{{ t.name }}</span>
              <p class="text-trio fs-6">{{ t.desc }}</p>
            </div>

            <div>
              <img
                :src="`/img/company/meet-us/${t.img}.svg`"
                class="img-fluid icon meet-us"
                :alt="t.name"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid black-box concept py-5">
      <div class="container">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ item.businessConcept }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-10 col-12 text-center text-white"
            v-html="item.textFour"
          ></div>
          <div class="col-12 d-flex justify-content-center my-4">
            <a @click="videoModal = true" class="btn btn-blue text-white"
              >&#9654;</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="videoModal == true" id="conseptVideo" class="position-fixed">
      <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/GutX7r9nuZA?controls=0&autoplay=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
      <a
        @click="videoModal = false"
        class="close-video text-primary position-absolute"
        >&times;</a
      >
    </div>
    <div class="container" v-scrollanimation>
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">{{ $t("keyFeatures") }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-12 col-md-8 col-sm-10 col-12">
          <div class="row d-flex justify-content-center my-5">
            <div
              class="col-lg-3 col-sm-6 col-11 mb-lg-0 mb-4"
              v-for="(feature, idx) in $tm('meetUsFeatures')"
              :key="idx"
            >
              <FeatureMeetUs :feature="feature" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import renderMeta from "../SEO";
import { getters, mutations } from "../store";
import FeatureMeetUs from "../components/FeatureMeetUs.vue";
export default {
  name: "MeetUs",
  metaInfo() {
    return renderMeta(
      "About us",
      `Triomarkets is about forex in your field of interest. Meet our team`
    );
  },
  data: () => ({
    videoModal: false,
    chuvaki: [
      // {
      //   name: "A. Kalashnikov",
      //   title: "Chief Executive Officer",
      //   text: "“Treat your employees the way you want your employees to treat your customers, it’s a chain reaction that starts from the top management! “",
      //   linkedin: "https://www.linkedin.com/in/andrey-i-kalashnikov/",
      //   email: "#",
      //   img: "chuvak1.jpg",
      // },
      // {
      //   name: "M. Mazzacco",
      //   title: "Head of Brokerage",
      //   text: "“In trading, you can't stop loss from happening but you can avoid big loss by using stop loss in your trades.”",
      //   linkedin: "https://www.linkedin.com/in/mauro-mazzacco/",
      //   email: "mailto:maurom@triomarkets.com",
      //   img: "chuvak2.jpg",
      // },
      {
        name: "L. Charalambous",
        title: "Head of Compliance & AML",
        text: "“Maintaining and ever evolving a firm foundation with some of our core ideals, knowledge, teamwork, and compliance success is unavoidable.”",
        linkedin: "https://www.linkedin.com/in/iliana-charalambous-082494b5/",
        email: "mailto:lianac@triomarkets.com",
        img: "chuvak3.jpg",
      },
    ],
  }),
  components: { FeatureMeetUs },
  computed: {
    license() {
      return getters.license();
    },
    registerURL() {
      return getters.registerURL();
    },
    timelineMobile() {
      let newArray = [];
      [...this.$tm("meetUsInfo")[0].timeline].reduce((a, b) => {
        return a.map((v, i) => {
          newArray.push(v, b[i]);
        });
      });
      return newArray;
    },
    selectedLang() {
      return getters.selectedLang();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  mounted() {
    mutations.setBoxHeight("box-about", "1");
    window.onresize = () => {
      mutations.setBoxHeight("box-about", "1");
    };
  },
};
</script>
<style lang="scss" scoped></style>
