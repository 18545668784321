<template>
  <div>
    <MarketsTop
      :market="{ video: 'shares', title: $t('shares'), desc: $t('sharesDesc') }"
    />
    <div class="container-fluid">
      <div class="row d-flex justify-content-center mt-5">
        <div class="col-xl-9 col-12">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-8 col-11 px-sm-3 px-0">
              <Quotes
                v-slidein
                :symbols="[
                  'AAPL_US',
                  'NFLX_US',
                  'AMZN_US',
                  'MSFT_US',
                  'NVDA_US',
                ]"
                :product="{ popular: $t('forexPopular') }"
              />
            </div>
            <div class="col-xl-4 col-11">
              <QuoteDetails v-slideinright />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, idx) in $tm('sharesInfo')" :key="idx" class="container">
      <div class="row d-flex justify-content-center my-5">
        <h2 class="col-12 title text-center">{{ item.moreInfo }}</h2>
        <hr class="blue m-0" />
      </div>
      <div class="row d-flex justify-content-center">
        <div v-scrollanimation class="col-lg-8 col-sm-10 col-12 text-center">
          <div class="forex-box shares mb-5" v-html="item.text"></div>
        </div>
        <div v-scrollanimation class="col-lg-8 col-sm-10 col-12 text-center">
          <div class="forex-box shares" v-html="item.textTwo"></div>
        </div>
        <div class="col-12 text-center my-5">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{
            $t("tradeNow")
          }}</a>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps stepStyle="dark" v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
import MarketsTop from "../components/MarketsTop.vue";
import Quotes from "../components/Quotes.vue";
import QuoteDetails from "../components/QuoteDetails.vue";
export default {
  name: "Shares",
  components: {
    MarketsTop,
    Quotes,
    QuoteDetails,
  },
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
