<template>
  <div id="legalDocs">
    <div class="container-fluid container-top accounts legal-docs">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("kidsDocuments") }}
          </h2>
          <hr class="gold" />
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-3 col-md-7 col-sm-10 search">
            <input
              type="text"
              v-model="search"
              name="search"
              placeholder="i.e. KIDs"
            />
            <img src="/img/search.svg" alt="Search KIDs document" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">KIDs</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center my-md-5 my-4">
        <div
          v-for="(kid, idx) in filteredTerms"
          :key="idx"
          class="col-xl-3 col-lg-4 col-md-5 col-sm-8 col-12 text-center mb-4"
        >
          <div class="legal-doc">
            <a :href="kid.link" class="body">
              <h3 class="fw-bold fs-5" v-html="kid.name"></h3>
              <div class="buttonset">
                <a
                  :href="kid.link"
                  target="_blank"
                  class="btn border-blue mt-3"
                  >{{ $t("legalDocsOpen") }}</a
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <Steps stepStyle="dark" />
  </div>
</template>
<script>
export default {
  name: "KIDsDocuments",
  data: () => ({
    search: "",
  }),
  computed: {
    filteredTerms() {
      return this.$tm("legalKIDS").filter(({ name }) => {
        return name.match(new RegExp(this.search, "i"));
      });
    },
  },
};
</script>
