<template>
  <div :class="`container-fluid position-relative steps`" id="steps">
    <div class="row d-md-block d-none" id="screens">
      <div class="col-12 d-flex justify-content-center">
        <img src="/img/steps/3stepsNEW.png" alt="screens" class="img-fluid" />
      </div>
    </div>
    <div class="row d-flex justify-content-center mb-5">
      <h2 class="col-12 title text-center pt-md-0 pt-5">
        {{ $t("stepsForTrading") }}
      </h2>
      <hr class="blue" />
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10">
          <div class="row pb-5">
            <div
              v-for="(step, idx) in $tm('stepsForTradingPoints')"
              :key="idx"
              class="col-md-4 d-flex flex-column align-items-center"
            >
              <img
                :src="`/img/steps/${step.img}.svg`"
                class="img-fluid icon my-2"
                alt="step icon"
              />
              <span class="text-center fs-4 fw-bold">{{ step.title }}</span>
              <p class="text-center fs-5">{{ step.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Steps",
  props: {
    stepStyle: String,
    bgColor: String,
  },
};
</script>
<style lang="scss" scoped></style>
