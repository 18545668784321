export default {
  en: {
    keyFeatures: "Major Key Features",
    advantages: "Advantages",
    TitleNewSection: "TrioMarkets allows you to trade MT4 on nearly any device",
    NewSectionText1:
      "Discover online trading as it should be with MetaTrader on your desktop.",
    NewSectionTitle1: "MT4 for PC",
    NewSectionTitle2: "MT4 for Mac",
    NewSectionText2:
      "MT4 stands out as the premier trading platform for Mac, offering advanced features and a plethora of technical analysis tools.",
    PhoneTitle1: "MT4 for Android",
    PhoneText1:
      "Experience the full range of MetaTrader features with flexibility and portability by installing MT4 for Android.",
    PhoneTitle2: "MT4 for Iphone",
    PhoneText2:
      "Tailored and designed for iOS, the MT4 for iPhone app seamlessly integrates all the functionalities of MT4 for convenient on-the-go trading.",
    metaTraderInfo: [
      {
        text: `TrioMarkets™ is dedicated to providing our clients with cutting-edge trading technology. This is why we are pleased to use the award-winning MT4 (MetaTrader 4). MT4 is the most used platform in the world, becoming practically the standard in the world of online trading. Very powerful, it offers a large choice of graphs and analysis tools. It can be installed for free on any computer regardless of operating system.<br>The MT4 can be easily installed on any computer and gives you access to all of our trading assets. The MT4 platform also gives our traders over 50 charting tools and built-in indicators to choose from. Trades can be executed manually or automatically.`,
        points: [
          { img: "real-time", name: "Real Time <br> Tools" },
          { img: "customizable", name: "Customizable <br> Features" },
          { img: "one-click", name: "One Click <br> Trading Options" },
        ],
        pointsTwo: [
          "Trading Forex, commodities and indices from one single platform.",
          "The ability to place limit and stop levels from one (1) pip on all the Forex major pairs.",
          "Our platform offers anonymous FX trading.",
          "The MT4 interface is fully customizable and gives you the ability to monitor your investments. ",
          "There is no trading conflicts between the client and the broker.",
          "Able to set stop losses and take profit while opening another trade.",
          "Option to trade mini lots, also called 0.01 lots.",
          "There are no trading restrictions.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend is a suite of market-informed apps created for TrioMarkets™ traders to optimize their trading ability`,
        points: [
          { img: "1", name: `Innovative <br> Management Tools` },
          { img: "2", name: `Advanced Trade <br> Execution` },
          { img: "3", name: `Sentiment <br> Trading` },
          { img: "4", name: `Sophisticated <br> Alarms` },
          { img: "5", name: `News and <br> Market data` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "A great personal trading assistant, featuring event-based alerts on trade activity, market news, price action, and could perform automated actions, such as sending emails, SMS, or opening trades.",
            },
            {
              img: "02",
              text: "Now traders can connect to news, economic calendars, live signals and get educated all at the same time thanks to the MT4 platform.",
            },
            {
              img: "03",
              text: "Compares the correlation between two assets, providing the opportunity for quick trade execution based on real-time comparisons between markets.",
            },
            {
              img: "04",
              text: "Provides traders with valuable insights into market information on the correlation between assets in different markets, allowing traders to assess the risks they might face in the market.",
            },
            {
              img: "05",
              text: "Powerful analysis tool in Excel based sheet on real-time market data, which enables traders to monitor, evaluate, and report data directly from the MT4.",
            },
            {
              img: "06",
              text: "Provides full control over market watch prices, and all trading account activities. Traders can manage their accounts, orders and executions from one convenient window.",
            },
          ],
          [
            {
              img: "07",
              text: "Create pending and market order templates for ultra-fast execution. The Mini Terminal is a stand-alone smaller version of the Trade Terminal, focusing on a single market.",
            },
            {
              img: "08",
              text: "Offers live sentiment data across markets, enabling traders to be updated real-time on market sentiment and trade more effectively. The app also shows historic sentiment stats alongside market prices.",
            },
            {
              img: "09",
              text: "Shows live and pending trading session times across the world map indicating key markets and peak liquidity times and markers for future news announcements.",
            },
            {
              img: "10",
              text: "Provides traders the opportunity to place hidden pending orders, stop losses and take profits, which will only appear in their MT4 account when actual market execution occurs.",
            },
            {
              img: "11",
              text: "Displays a variety of different tick charts and boasts a one-click trading functionality allowing traders the opportunity to execute short-term trades swiftly and precisely. This tool is perfect for scalpers.",
            },
            {
              img: "12",
              text: "A fully functional, all-in-one app offering traders speedy order execution and position management. It features, among others advanced stop-loss and take profit components, advanced close-all functions, in-depth order calculations (pip value, size), order templates, and OCO orders.",
            },
            {
              img: "13",
              text: "An Indicator package filled with highly sought after chart indicators, featuring Renko, Order History, Pivot, Chart Group, Candle Countdown, Freehand Drawing and many more.",
            },
          ],
        ],
        title: "Available Manuals",
        textTwo:
          "All MT4 TrioXtend apps are fully supported by comprehensive user guides, and are available to traders using MT4 TrioXtend.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets™ makes it easy for you to trade no matter where you are. As long as you have an internet connection you are able to trade and get all of you market analysis on the MetaTrader 4 state of the art Mobile Application.",
        points: [
          "Market and conditional entry orders",
          "Set stop and limit orders",
          "The ability to trade from multiple accounts",
          "Live Quotes",
          "Interactive Charts",
          "Real time charting",
          "Multiple time frames",
          "Over 30 technical indicators",
          "The ability to check your balance, equity",
          "Our mobile trader auto syncs to desktop",
          "Edit Open Positions",
          "Trading History",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "TrioMarkets™ is constantly bringing the most cutting-edge trading technologies to our valued traders to help them with their daily trades. From our award winning and technologically advanced Meta Trader 4 Platform (MT4) to our new MT4 add-on tool, the MT4 Web Trader. The WebTrader is an addition to your MT4 that allows you to trade from any computer or mobile smart device. All you require is an intern.<br>The WebTrader is an addition for your MT4 Trader and allows you to trade from any computer or mobile smart device. All you need is an internet connection.",
        title: "Why WebTrader?",
        textTwo: `Today's volatile market means online traders need an advanced trading platform that is designed to give real time financial markets. From the Forex markets to CFDs and commodities. TrioMarkets™ WebTrader gives you this advantage with high speed trade executions.<br>This advanced additional tool for the MT4 trader gives our online traders a unique way to make money in the Forex markets. No need to install additional software, very simply just add it to your MT4 account.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "In the abundance of opportunities you lack time and skills to invest? Invest smartly by following the performance of profitable traders and harness their successes. Copy their approved trading strategies and learn from their expertise. TrioMarkets™ joins the social trading game and wants you to extend your trading reach.",
        multiply: "Multiply your trading <br> experience and your gains",
        points: [
          { img: "easy-use", name: "Easy to Use" },
          { img: "replication", name: "Auto Replication" },
          { img: "risk", name: "Risk Management" },
          { img: "vps", name: "Free VPS" },
        ],
        title: "Only the best is good enough",
        textTwo:
          "You want to trade with confidence in a profitable and automated environment? Social trading is easily accessible and gives you the opportunity to choose the most profitable traders. The Marketplace provides a search tool and a filter function which make you determine the Months since inception, Risk Ratio, Maximum Loss, and Profitability. Find the trader who fits perfectly your trading and investing needs and capture winnings while you relax.",
        titleTwo: "Sharing is caring",
        textThree:
          "Take the chance to become a signal trader with growing experience and expertise and drive gains with every newbie who copies your trades. The more followers copy your strategies, the higher your pay-out will be. Take advantage of the social proof of a community with high value.",
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (Financial Information Exchange) API (Application Programming Interface) is an electronic communications protocol for financial information exchange.<br>Trading behaviors are evolving. High frequency trading and latency arbitrage is vastly becoming popular. Speed is very essential for these new emerging strategies. Changes demand new advanced technologies.<br>FIX API allows any trader to connect directly to our liquidity pool with a minimized latency and cost. Minimized latency allows the trader to realize their unique trading strategies.`,

        textTwo: `<p>TrioMarkets™ exclusively offers a FIX API to its institutional clients and those engaging in high-volume trading.</p>`,
        points: [
          "Low cost multi-tier liquidity",
          "Ability to connect with various <br> platform like Ctrader or Ninja Trader",
          "Exceptional Execution Speed",
          "Custom build platform compatibility",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `VPS (Virtual Private Server) hosting allows Forex traders to use the virtual environment on the hosting company's servers to run the MetaTrader expert advisors.<br>Trading with Expert Advisor and special algorithms has become very common. Speed and persistence are 2 key elements for a trading system to operate properly.<br>It is not possible to expect a successful performance if your expert advisors is having connectivity issues. As a trader, you need to make sure that your expert advisors is up and running at all times.`,
        textTwo: `<p class="text-center">Like the persistence, speed is very important and distance to the trading servers plays a very decisive role on the execution. The closer you are to our servers, the faster your execution request will be delivered and your trading system will perform in a better way.</p><strong class="text-center">Therefore, we offer you a VPS. </strong><p class="text-center mt-2">Through the VPS, you can throw trades as you are in London even though you would be sitting in Australia. Even if you switch off your computer, your Expert Advisor would continue to perform according to the settings you previously set.</p>`,
        title: "VPS Advantages",
        points: [
          "Trade at any time of day. System will execute the trades for you and therefore there is no need to stay by your PC.",
          "VPS server systems are checked regularly to make sure that they are functioning properly so you will almost never encounter any technical issues.",
          "A VPS can execute your trades more quickly than your computer can because it is much faster at transmitting the orders. The result is that you experience less of a delay and less slippage.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets™ has partnered with ForexVPS.net and HokoCloud.</p><p class="text-center text-white">They both have a high quality product with 100% uptime guarantee. If you want to run your expert advisors continuously without any interruptions then Forex VPS or HokoCloud hosting for MetaTrader is what you really need because the VPS is always online.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `We allow the handling of multiple accounts through one master account using PAMM/MAM Application which is used by Money Managers.<br>It allocates master trades proportionally to the sub accounts by using various allocation methods such as equity, PL or balance.<br>The most popular allocation method among trades is equity. It allocates master trades to the sub accounts based on the amount of their equity.`,
        textTwo: `<strong class="text-center text-uppercase">EQUITY ALLOCATION METHOD:</strong><p class="text-center mt-3">Trader opens 1 lot on EURUSD and equity status was 10k, 20k, 30k, 15k, and 25k in each sub account. PAMM/MAM allocates the 1lot proportionally to each sub account as 0.1, 0.2, 0.3, 0.15 and 0.25. This is the main reason why equity method is mostly used, because it is solid and easy to implement.</p><p class="text-center">There are various advantages to trading forex with a PAMM/MAM application. One of the most unique advantages is slippage interception. Since the sub accounts only mirror the master account pricing, it is impossible to see price discrepancies. It is guaranteed that all sub trades will be entered from the master trade price.</p><p class="text-center">We believe this is a feature that other similar applications simply cannot offer. Of course, this is not the only advantage.</p>`,
        title: "PAMM/MAM Advantages",
        point: [
          "Direct market liquidity for Master Accounts",
          "Commission Monitoring",
          "Full view over Master and Sub Trades",
          "Various Reporting Options",
          "Slippage Interception between Sub Accounts",
          "Easy EA Implementation",
          "Full control over client accounts through one",
          "All trade types are allowed",
        ],
        titleTwo: "PAMM/MAM Model",
        mamModel: "modelen",
        mamModelPhone: "modelPhen",
      },
    ],
  },
  fr: {
    keyFeatures: "Caractéristiques Principales",
    advantages: "Avantages",

    TitleNewSection:
      "TrioMarkets vous permet de trader MT4 sur presque n'importe quel appareil",
    NewSectionText1:
      "Découvrez le trading en ligne tel qu'il devrait être avec MetaTrader sur votre ordinateur de bureau.",
    NewSectionTitle1: "MT4 pour PC",
    NewSectionTitle2: "MT4 pour Mac",
    NewSectionText2:
      "MT4 se distingue comme la première plateforme de trading pour Mac, offrant des fonctionnalités avancées et une pléthore d'outils d'analyse technique.",
    PhoneTitle1: "MT4 pour Android",
    PhoneText1:
      "Adaptée et conçue pour iOS, l'application MT4 pour iPhone de TrioMarkets intègre de manière transparente toutes les fonctionnalités de MT4 pour un trading pratique en déplacement.",
    PhoneTitle2: "MT4 pour Iphone",
    PhoneText2:
      "Découvrez l'ensemble des fonctionnalités de MetaTrader avec flexibilité et portabilité en installant MT4 pour Android.",

    metaTraderInfo: [
      {
        text: `TrioMarkets™ fournit les meilleures technologies de trading à ses clients. A ce titre, nous proposons à tous nos traders d'utiliser la plateforme MT4 (Meta Trader 4). MT4 est la plateforme la plus utilisée dans le monde, devenant pratiquement la norme dans le monde du trading en ligne. Très puissante, elle offre un grands choix de graphiques et d'outils d'analyse. Elle peut être installée gratuitement sur tout ordinateur quel que soit le système d'exploitation.<br>Le MT4 peut être facilement installé sur n'importe quel ordinateur et vous donne accès à tous nos actifs de trading. La plate-forme MT4 offre également à nos traders plus de 50 outils graphiques et indicateurs intégrés parmi lesquels choisir. Les transactions peuvent être exécutées manuellement ou automatiquement.`,
        points: [
          { img: "real-time", name: "Outils de <br> Gestion Innovants" },
          { img: "customizable", name: "Execution de <br> Trading Avancée" },
          { img: "one-click", name: "Tendance <br> de Marché" },
        ],
        pointsTwo: [
          "Trading sur Forex, matières premières et indices boursiers sur la même plateforme.",
          "Possibilité de placer des niveaux de limite et de stop à partir de 1 pip sur toutes les principales paires de devises.",
          "Trading FX anonyme.",
          "L'interface de la MT4 est entièrement personnalisable, elle vous donne la possibilité de suivre vos investissements avec précision.",
          "Pas de conflit d'intérêts entre le client et le broker.",
          "Possibilité de placer des « stop loss » et « take profit » tout en ouvrant simultanément une autre transaction.",
          "Option de mini-lots (0.01 Lots).",
          "Pas de restriction de stratégies de trading.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend est une suite d'apps informées du marché, créées pour les traders de TrioMarkets™ afin d'optimiser leur capacité de trading`,
        points: [
          { img: "1", name: `Outils de <br> Gestion Innovants` },
          { img: "2", name: `Exécution Avancée <br> des Trades` },
          { img: "3", name: `Sentiment <br> de Trading` },
          { img: "4", name: `Alarmes <br> Sophistiquées` },
          { img: "5", name: `Actualités et Données <br> sur le Marché` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "Un excellent assistant commercial personnel, avec des alertes basées sur des événements sur l'activité commerciale, les nouvelles du marché, l'action des prix, et a la capacité d'effectuer des actions automatisées, telles que l'envoi d'e-mails, de SMS ou l'ouverture de transactions.",
            },
            {
              img: "02",
              text: "Offrant aux commerçants la possibilité de se connecter aux actualités, au calendrier économique, aux signaux en direct et de s'instruire à partir de la plate-forme MT4.",
            },
            {
              img: "03",
              text: "Compare la corrélation entre deux actifs, offrant la possibilité d'une exécution rapide des transactions basée sur des comparaisons en temps réel entre les marchés.",
            },
            {
              img: "04",
              text: "Fournit aux traders des informations précieuses sur les informations de marché sur la corrélation entre les actifs sur différents marchés, permettant aux traders d'évaluer rapidement leur risque d'exposition au marché.",
            },
            {
              img: "05",
              text: "Puissant outil d'analyse dans Excel basé sur des données de marché en temps réel, permettant aux traders de surveiller, d'évaluer et de rapporter des données directement à partir du MT4.",
            },
            {
              img: "06",
              text: "Fournit un contrôle total sur les prix de surveillance du marché et sur toutes les activités du compte de trading. Les traders peuvent gérer leur compte, leurs ordres et leurs exécutions à partir d'une fenêtre pratique.",
            },
          ],
          [
            {
              img: "07",
              text: "Créez et programmez des modèles d'ordres pour une exécution ultra-rapide. Le Mini Terminal est une version autonome plus petite du terminal de Trading MT4, axée sur un seul marché.",
            },
            {
              img: "08",
              text: "Offre des données sur le sentiment du marché, permettant aux traders d'être informé en temps réel sur le positionnement sur le marché des autres traders et trader plus efficacement. L'application affiche également des historiques sur le sentiment du marché ainsi que sur les prix.",
            },
            {
              img: "09",
              text: "Affiche les heures des sessions en cours ou à venir sur la carte du monde, en indiquant les principaux marchés, les périodes de pointe de liquidité, ainsi que des indicateurs concernant les publications à venir sur les marchés.",
            },
            {
              img: "10",
              text: "Offre aux traders la possibilité de placer des ordres cachés en attente, des stop loss et des take profits, qui n'apparaîtront sur leur compte MT4 qu'au moment de leur exécution réelle.",
            },
            {
              img: "11",
              text: "Présente une large variété de graphiques et propose une fonctionnalité de trading en 1 clic permettant aux traders d'exécuter rapidement des ordres à court terme et avec précision. Cet outil est parfait pour les scalpeurs.",
            },
            {
              img: "12",
              text: "Une application tout-en-un parfaitement fonctionnelle offrant aux traders une exécution rapide des ordres et une gestion des positions. Elle comprend, entre autres, des options avancées de stop-loss et take profit, des fonctions de clôture avancées, des calculs d'ordres détaillés (valeur du pip, taille), des modèles d'ordre et des ordres OCO.",
            },
            {
              img: "13",
              text: "Ensemble d'indicateurs comprenant des indicateurs graphiques détaillés, mettant en vedette Renko, historiques des ordres, Pivot, Groupe de graphiques, chronomètre de bougies, dessins à main levée… et bien plus encore.",
            },
          ],
        ],
        title: "Manuels Disponibles ",
        textTwo:
          "Toutes les applications MT4 TrioXtend sont expliquées dans des guides d'utilisation complets, faciles d'accès, disponibles pour les traders utilisant MT4 TrioXtend.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets™ vous permet de rester connecté aux marchés. Depuis l'application mobile Meta Trader 4, vous profitez de la meilleure expérience de trading mobile pour trader vos actifs préférés.",
        points: [
          "Ordres à exécution immédiate et ordres différés",
          "Définir des limites de pertes et le niveau de prise de profits",
          "Possibilité de Trader depuis plusieurs comptes",
          "Cours en temps réel",
          "Graphiques interactifs et en temps réel",
          "Graphique en temps réel",
          "Multiple time frames",
          "Plus de 30 indicateurs techniques",
          "Possibilité de vérifier votre solde, balance, marge et plus encore",
          "Plateforme mobile parfaitement synchronisée avec votre ordinateur",
          "Modifier des positions ouvertes",
          "Historique de Trading",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: `TrioMarkets™ apporte toujours le meilleur en matière de technologies de trading, pour aider nos traders dans leurs transactions quotidiennes. C'est la raison pour laquelle nous avons introduit la MT4 WebTrader, outil incontournable de notre gamme de plateformes.<br> Le WebTrader est un complément à la plateforme MT4 qui permet de trader de n'importe où et depuis n'importe quel appareil. Tout ce dont vous avez besoin est d'une connexion Internet.`,
        title: "Pourquoi choisir MT4 WebTrader?",
        textTwo: `La volatilité du marché actuel nécessite un accès aux cours de marché en temps réel. TrioMarkets™ WebTrader donne à nos clients cet avantage avec des exécutions à grande vitesse et compétitives.<br>Le WebTrader, c'est le futur du trading. Il n'y a aucun besoin d'installer de logiciel supplémentaire, il suffit simplement d'ajouter votre compte MT4.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "Dans l'abondance des opportunités, vous manquez de temps et de compétences pour investir? Investissez intelligemment en suivant les performances des traders rentables et exploitez leurs succès. Copiez leurs stratégies de trading approuvées et apprenez de leur expertise. TrioMarkets rejoint la tendance du trading social et souhaite que vous étendiez votre portée de trading.",
        multiply: "Multipliez votre expérience <br> de trading et vos gains",
        points: [
          { img: "easy-use", name: "Facile à Utiliser" },
          { img: "replication", name: "Copie Automatique" },
          { img: "risk", name: "Risk Management" },
          { img: "vps", name: "VPS Gratuit" },
        ],
        title: "Seul le meilleur est assez bon",
        textTwo: `Vous souhaitez trader en toute confiance dans un environnement rentable et automatisé? Le trading social est facilement accessible et vous donne la possibilité de choisir les traders les plus rentables. L'onglet Marketplace fournit un outil de recherche et une fonction de filtre qui vous permet de déterminer le ratio de risque, la perte maximale et la rentabilité. Trouvez le trader qui correspond parfaitement à vos besoins de trading et d'investissement et empochez les gains pendant que vous vous détendez.`,
        titleTwo: "Partager, c'est s'intéresser",
        textThree: `Vous souhaitez trader en toute confiance dans un environnement rentable et automatisé? Le trading social est facilement accessible et vous donne la possibilité de choisir les traders les plus rentables. L'onglet Marketplace fournit un outil de recherche et une fonction de filtre qui vous permet de déterminer le ratio de risque, la perte maximale et la rentabilité. Trouvez le trader qui correspond parfaitement à vos besoins de trading et d'investissement et empochez les gains pendant que vous vous détendez.`,
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (Financial Information Exchange) API (interface de programmation d'application) est un protocole de communication électronique pour l'échange d'informations financières.<br>Les habitudes de trading évoluent. Le trading à haute fréquence et l'arbitrage de latence sont de plus en plus populaires. La vitesse est essentielle pour ces nouvelles stratégies. Ces changements exigent de nouvelles technologies de pointe.<br>Fix API permet à tout trader de se connecter directement à notre pool de liquidités avec un minimum de latence et de coûts.`,

        textTwo: `<p>TrioMarkets™ propose exclusivement une API FIX à ses clients institutionnels et à ceux qui effectuent des transactions à volume élevé.</p>`,
        points: [
          "Faible coût de liquidité multi-tiers",
          "Possibilité de connexion au travers différentes plateformes telles que : cTrader, NinjaTrader",
          "Vitesse d'exécution exceptionnelle",
          "Compatibilité de plateforme de construction personnalisée",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `Un VPS (Virtual Private Server, ou serveur privé virtuel) permet aux traders d'utiliser un environnement virtuel sur les serveurs d'une société d'hébergement, pour exécuter les experts advisors de la Meta Trader.<br>Le trading avec Expert Advisor et des algorithmes spéciaux est devenu très courant. La vitesse et la persévérance sont 2 éléments clés pour qu'un système de trading fonctionne correctement.<br>Il n'est pas possible de s'attendre à une performance réussie si votre expert rencontre des problèmes de connectivité. En tant que trader, vous devez vous assurer que votre expert est opérationnel à tout moment.`,
        textTwo: `<p class="text-center">Tout comme la persistance, la vitesse est très importante et la distance aux serveurs de trading joue un rôle très déterminant sur l'exécution. Plus vous êtes proche de nos serveurs, plus votre demande d'exécution sera livrée rapidement et votre système de trading fonctionnera mieux.</p><strong class="text-center">Par conséquent, nous vous proposons un VPS.</strong><p class="text-center mt-3">Grâce au VPS, vous pouvez trader comme à Londres ou en Australie. Même si vous éteignez votre ordinateur, votre Expert Advisor continuera à fonctionner selon les paramètres que vous avez définis précédemment.</p>`,
        title: "Les Avantages d'un VPS",
        points: [
          "Trader à tout moment de la journée. Le système exécutera les transactions pour vous et il n'est donc pas nécessaire de rester à côté de votre PC.",
          "Les serveurs VPS sont extrêmement sécurisés, beaucoup plus que votre environnement personnel. Ils sont régulièrement vérifiés afin que jamais vous ne rencontriez de problèmes techniques.",
          "Un VPS peut exécuter vos transactions plus rapidement que votre ordinateur car il transmet les ordres beaucoup plus rapidement. Le résultat est que vous rencontrez moins de retard et moins de slippage.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets™ en partenariat avec ForexVPS.net et HokoCloud.</p><p class="text-center text-white">Les deux proposent un produit de haute qualité avec 100% garanti de flux constant. Vous désirez que vos EAs soient connectés en continu ? Forex VPS  et HokoCloud (Hébergement pour MT4) sont ce dont vous avez besoin car le VPS est en ligne sans discontinuité.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `TrioMarkets™ autorise la gestion de plusieurs comptes via un compte principal à l'aide de l'application PAMM/MAM (Multi Account Manager), utilisée par de nombreux gestionnaires de fonds.<br>Cet outil permet d'allouer les trades d'un compte mère, proportionnellement aux comptes filles selon diverses méthodes d'allocations telles que : l'équité, P/L ou balance de compte.<br>La méthode la plus populaire est l'équité. Elle alloue les trades du compte mère aux comptes filles en fonction du montant de leur équité.`,
        textTwo: `<strong class="text-center text-uppercase">MÉTHODE DE L'ÉQUITÉ:</strong><p class="text-center mt-3">Le trader ouvre 1 lot sur EURUSD et la réparation des fonds étant 10k, 20k, 30k, 15ket 25k dans chaque sous compte. Le PAMM/MAM allouera le volume de 1 lot proportionnellement à chaque sous comptes : 0.1, 0.2, 0.3, 0.15 et 0.25. Ceci est la principale raison pour laquelle la méthode de l'équité est utilisée. Simple et efficace.</p><p class="text-center">Il y a plusieurs avantages de trader le forex avec une application PAMM/MAM. Un des avantages est l'interception de slippage. Étant donné que les sous comptes sont le reflet du compte principal, il est impossible de voir les écarts de prix. Il est garanti que tous les comptes filles obtiendront le même prix d'exécution que le compte mère.</p><p class="text-center">Nous pensons que ceci est une caractéristique que d'autres applications similaires peuvent tout simplement ne pas offrir. Bien entendu, ce ne sont pas les seuls avantages.</p>`,
        title: "Avantages PAMM/MAM",
        point: [
          "Liquidité de marché direct pour les Comptes Master",
          "Contrôle des commissions",
          "Vue d'ensemble des comptes Master et comptes Sub",
          "Différentes options d'analyses de compte",
          "Slippage Interception pour les Comptes Sub",
          "Implémentation facilitée des EAs",
          "Contrôle total des comptes clients par le biais d'un compte",
          "Tous types de stratégies de trading autorisés",
        ],
        titleTwo: "PAMM/MAM Modèle",
        mamModel: "modelfr",
        mamModelPhone: "modelPhfr",
      },
    ],
  },
  it: {
    keyFeatures: "Caratteristiche Principali",
    advantages: "Vantaggi",

    TitleNewSection:
      "TrioMarkets ti consente di fare trading con MT4 su praticamente qualsiasi dispositivo",
    NewSectionText1:
      "Entdecken Sie den Online-Handel, wie er sein sollte, mit MetaTrader auf Ihrem Desktop.",
    NewSectionTitle1: "MT4 für PC",
    NewSectionTitle2: "MT4 für Mac",
    NewSectionText2:
      "MT4 zeichnet sich als führende Handelsplattform für Mac aus und bietet fortschrittliche Funktionen sowie eine Vielzahl von technischen Analysewerkzeugen.",
    PhoneTitle1: "MT4 für Android",
    PhoneText1:
      "Sperimenta l'intera gamma di funzionalità di MetaTrader con flessibilità e portabilità installando MT4 per Android.",
    PhoneTitle2: "MT4 für Iphone",
    PhoneText2:
      "Progettata su misura per iOS, l'app MT4 per iPhone di TrioMarkets integra in modo fluido tutte le funzionalità di MT4 per un comodo trading in movimento.",

    metaTraderInfo: [
      {
        text: `TrioMarkets™ è dedicato a 6fornire ai nostri clienti una tecnologia di trading all'avanguardia. Questo è il motivo per cui siamo lieti di utilizzare il pluripremiato MT4 (MetaTrader 4). MT4 è la piattaforma più utilizzata al mondo, diventando praticamente lo standard nel mondo del trading online. Molto potente, offre un'ampia scelta di grafici e strumenti di analisi. Può essere installato gratuitamente su qualsiasi computer indipendentemente dal sistema operativo.<br>La MT4 può essere facilmente installata in qualsiasi computer e permette di accedere a tutte le nostre attività di trading. La piattaforma MT4 fornisce ai nostri trader più di 50 strumenti grafici e indicatori built-in tra cui scegliere. Le operazioni possono essere eseguite manualmente o automaticamente.`,
        points: [
          { img: "real-time", name: "Strumenti di <br> Gestione Innovativi" },
          {
            img: "customizable",
            name: "Esecuzione di <br> Negozazione  Avanzata",
          },
          { img: "one-click", name: "Sentimento <br> di Trading" },
        ],
        pointsTwo: [
          "Trading Forex, Materie Prime e Indici da una singola piattaforma.",
          "Possibilità di impostare limiti e livelli da un (1) pip su tutte le principali coppie Forex.",
          "La nostra piattaforma offre trading FX anonimo.",
          "L'interfaccia MT4 è completamente personalizzabile e ti dà la possibilità di monitorare i tuoi investimenti con precisione.",
          "Non ci sono conflitti di negoziazione tra il Cliente e il Broker.",
          "Possibilità di impostare stop loss e take profit durante l'apertura di un'altra negoziazione contemporaneamente.",
          "Opzione per investire in mini lotti, chiamato anche 0,01 lotti.",
          "Non ci sono restrizioni di trading.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend è una suite di applicazioni informate sul mercato create per i trader di TrioMarkets™ per ottimizzare la loro capacità di trading.`,
        points: [
          { img: "1", name: `Strumenti <br> di Gestione Innovativi` },
          { img: "2", name: `Esecuzione di <br> Trading Avanzato` },
          { img: "3", name: `Sentimento <br> di Trading` },
          { img: "4", name: `Sofisticati <br> Allarmi` },
          { img: "5", name: `Notizie e Dati <br> di Mercato` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "Un grande assistente personale per il trading, con avvisi basati su eventi su attività commerciali, notizie di mercato, azioni sui prezzi e ha la capacità di eseguire azioni automatizzate, come l'invio di e-mail, SMS o l'apertura di negoziazioni.",
            },
            {
              img: "02",
              text: "Offrendo ai trader la possibilità di connettersi a notizie, calendario economico, segnali in tempo reale e di essere istruiti, tutto dalla piattaforma MT4.",
            },
            {
              img: "03",
              text: "Confronta la correlazione tra due asset, offrendo l'opportunità di una rapida esecuzione degli scambi sulla base di confronti in tempo reale tra i mercati.",
            },
            {
              img: "04",
              text: "Fornisce ai trader informazioni preziose sulle informazioni di mercato sulla correlazione tra asset in diversi mercati, consentendo ai trader di valutare rapidamente il rischio di esposizione al mercato.",
            },
            {
              img: "05",
              text: "Potente strumento di analisi in Excel basato su dati di mercato in tempo reale, che consente ai trader di monitorare, valutare e riportare i dati direttamente dall'MT4.",
            },
            {
              img: "06",
              text: "Fornisce il pieno controllo sui prezzi di mercato e su tutte le attività del conto di trading. I trader possono gestire il proprio conto, gli ordini e le esecuzioni da una comoda finestra.",
            },
          ],
          [
            {
              img: "07",
              text: "Creare modelli di ordine pendente e di mercato per un'esecuzione ultra-veloce. Il Mini Terminal è una versione più piccola del Trade Terminal, incentrata su un mercato unico.",
            },
            {
              img: "08",
              text: "Fornisce il sentiment dal vivo dei mercati, consentendo ai trader di essere aggiornati in tempo reale sul sentimento di mercato e sul trade in modo più efficace. L'applicazione mostra anche statistiche storiche di sentiment accanto ai prezzi di mercato.",
            },
            {
              img: "09",
              text: "Mostra le sessioni di trading in diretta sulla mappa mondiale indicando i mercati chiave e i tempi di liquidità con gli indicatori di punta per annunci futuri di notizie.",
            },
            {
              img: "10",
              text: "Fornisce ai trader l'opportunità di mettere ordini pendenti nascosti, stop loss e take profit che appaiono solo nel loro conto MT4 quando si verifica l'effettiva esecuzione del mercato.",
            },
            {
              img: "11",
              text: "Visualizza una varietà di grafici diversi di tick e vanta una funzionalità di scambio con un solo click che consente agli operatori di eseguire rapidamente e con precisione le operazioni a breve termine. Questo strumento è perfetto per scalper.",
            },
            {
              img: "12",
              text: "Un'applicazione completamente funzionale, tutto in uno ,che offre agli operatori una rapida esecuzione degli ordini e una migliore gestione delle posizioni. Esso dispone, tra le altre, di componenti avanzate di stop-loss e take profit, di close-all, calcoli inerienti all'ordine (valore del pip, dimensioni, ecc…), modelli di ordine e ordini OCO.",
            },
            {
              img: "13",
              text: "Un pacchetto di indicatori grafici molto ricercati, con Renko, Storia Ordine, Pivot, Chart Group, Candela conto alla rovescia, Disegno a mano libera e molti altri.",
            },
          ],
        ],
        title: "Manuali Disponibli",
        textTwo:
          "Tutte le applicazioni MT4 TrioXtend sono completamente supportate da guide utente complete che sono a disposizione degli operatori che utilizzano MT4 TrioXtend.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets™ rende facile per te il trading indipendentemente da dove sei. Finchè hai una connessione a internet sei in grado di fare trading e ottenere tutte le analisi di mercato sull'Applicazione mobile di MetaTrader 4.",
        points: [
          "Ordini di esecuzione immediata e ordini differiti",
          "Imposta ordini stop e limit",
          "La possibilità di fare trading da più account",
          "Prezzo in tempo reale",
          "Grafica interattiva e in tempo reale",
          "Grafici in tempo reale",
          "Tempi multipli",
          "Più di 30 indicatori tecnici",
          "La possibilità di controllare il tuo saldo, capitale proprio e altro",
          "Piattaforma mobile perfettamente sincronizzata con il tuo computer",
          "Modifica le posizioni aperte",
          "Storia del Trading",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: `TrioMarkets™ mette a disposizione sempre le migliori tecnologie di trading per aiutare i nostri trader a tradare quotidianamente. Dalla nostra premiata piattaforma MetaTrader 4 (MT4) al nostro nuovo strumento aggiuntivo MT4, il MT4 WebTrader.<br>Il WebTrader è una aggiunta per l'utilizzatore di MT4 e consente di fare traidng da qualsiasi computer o dispositivo mobile. Serve solo una connessione internet.`,
        title: "Perché scegliere MT4 WebTrader?",
        textTwo: `Il mercato volatile di oggi significa che i trader hanno bisogno di una piattaforma di trading avanzata progettata per offrire mercati finanziari in tempo reale. TrioMarkets™ WebTrader ti offre questo vantaggio con esecuzioni di trading ad alta velocità.<br>Il WebTrader è il futuro del trading. Non è necessario installare alcun software aggiuntivo, basta aggiungere il tuo account MT4.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "Nell'abbondanza di opportunità ti mancano tempo e capacità per investire? Investi in modo intelligente seguendo le prestazioni di trader redditizi e sfruttando i loro successi. Copia le loro strategie di trading approvate e impara dalla loro esperienza. TrioMarkets si unisce alla tecnologia del social trading e desidera che tu estenda la tua portata di trading.",
        multiply:
          "Moltiplica la tua esperienza <br> di trading ei tuoi guadagni",
        points: [
          { img: "easy-use", name: "Facilke da Usare" },
          { img: "replication", name: "Replica Automatica" },
          { img: "risk", name: "Gestione del Rischio" },
          { img: "vps", name: "VPS Gratuita" },
        ],
        title: "Solo il meglio è abbastanza buono",
        textTwo: `Vuoi fare trading con fiducia in un ambiente redditizio e automatizzato? Il social trading è facilmente accessibile e ti dà l'opportunità di scegliere i trader più redditizi. Il Marketplace fornisce uno strumento di ricerca e una funzione di filtro che ti consentono di determinare i mesi dall'inizio, il rapporto di rischio, la perdita massima e la redditività. Trova il trader che si adatta perfettamente alle tue esigenze di trading e investimento e cattura i guadagni mentre ti rilassi.`,
        titleTwo: "La condivisione è la cura",
        textThree: `Cogli l'occasione per diventare un trader di segnali con esperienza e competenza crescenti e guadagna con ogni principiante che copia le tue operazioni. Più follower copiano le tue strategie, più alto sarà il tuo pay-out. Approfitta della prova sociale di una comunità di alto valore.`,
      },
    ],
    fixApiInfo: [
      {
        text: `Fix (Financial Information Exchange) API (Application Programming Interface) è un protocollo di comunicazione elettronica per lo scambio di informazioni finanziarie. Il trading si stanno evolvendo.<br>Il trading ad alta frequenza e il latency arbitrage stanno diventando molto popolari. La velocità è essenziale in queste nuove strategie emergenti. Questi cambiamenti dunque richiedono nuove tecnologie avanzate.<br>Fix API consente a qualsiasi operatore di connettersi direttamente al nostro pool di liquidità con costi e latenza ridotti. La latenza minimizzata consente così ai trader di realizzare le loro uniche strategie di trading.`,

        textTwo: `<p>TrioMarkets™ offre esclusivamente una API FIX ai suoi clienti istituzionali e a coloro che effettuano trading ad alto volume.</p>`,
        points: [
          "Liquidità a più livelli",
          "Possibilità di connettersi con le varie piattaforme come cTrader o Ninja Trader",
          "Eccezionale velocità di esecuzione",
          "Compatibilità con piattaforma personalizzata",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `L'hosting VPS (Virtual Private Server) consente ai trader Forex di utilizzare l'ambiente virtuale sui server della società di hosting per eseguire gli expert advisor su MetaTrader.<br>Il trading con Expert Advisor e algoritmi speciali è diventato molto comune. Velocità e persistenza sono 2 elementi chiave per un corretto funzionamento di un sistema di trading.<br>Non è possibile aspettarsi prestazioni di successo se l'expert ha problemi di connettività. Come trader devi assicurarti che il tuo expert sia sempre attivo e funzionante in ogni momento.`,
        textTwo: `<p class="text-center">Come la persistenza, la velocità è molto importante e la distanza dai server di trading svolge un ruolo molto determinante sull'esecuzione. Più si è vicini ai nostri server, più velocemente sarà la richiesta di esecuzione e il tuo sistema di trading funzionerà in modo migliore.</p><strong class="text-center">Pertanto, vi offriamo un VPS.</strong><p class="text-center mt-3">Attraverso la VPS, puoi eseguire ordini come se fossi a Londra anche se sei seduto in Australia. Anche se si spegne il computer, l'Expert Advisor continuerà a funzionare secondo le impostazioni precedentemente impostate.</p>`,
        title: "I vantaggi della VPS",
        points: [
          "Fai trading in qualsiasi momento della giornata. Il sistema eseguirà le operazioni per te e quindi non è necessario rimanere al PC.",
          "I sistemi server VPS vengono controllati regolarmente per assicurarsi che funzionino correttamente in modo da non incontrare mai problemi tecnici.",
          "Una VPS può eseguire Ie tue negoziazioni più rapidamente del computer perché è molto più veloce nel trasmettere gli ordini. Il risultato è che si verifica meno ritardo e meno slippage.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets™ ha stretto una partnership con ForexVPS.net e HokoCloud.</p><p class="text-center text-white">Entrambi hanno un prodotto di alta qualità con garanzia di uptime del 100%. Se vuoi gestire i tuoi sistemi automatici  in modo continuo senza interruzioni, allora Forex VPS o HokoCloud hosting per MetaTrader è ciò di cui hai veramente bisogno perché il VPS è sempre online.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `È permessa la gestione di più conti utilizzando l'Applicazione PAMM/MAM tramite un account principale che viene gestita da Money Managers autorizzati.<br>Essa distribuisce le operazioni aperte-chiuse nel conto master in maniera proporzionale ai conti secondari utilizzando vari criteri di assegnazione a seconda dell'equity, del profitto/perdita o del saldo.<br>Il metodo più utilizzato nell'allocazione dei trade del conto master ai conti secondari è quello che si basa sulla loro equity.`,
        textTwo: `<strong class="text-center text-uppercase">METODO "EQUITY ALLOCATION:</strong><p class="text-center mt-3">Il Master trader apre 1 lotto su EURUSD e lo stato di equity è 10k - 20k - 30k - 15k - 25k in ogni conto secondario. La PAMM/MAM assegnerà il volume (1 lotto) in proporzione ad ogni conto secondario, cioè 0,1 - 0,2 - 0,3 - 0,15 - 0,25. Questo è il motivo principale per cui il metodo di equity allocation è il più utilizzato, perché è solido e facile da implementare.</p><p class="text-center">Ci sono diversi vantaggi nel trading con un'Applicazione PAMM/MAM. Uno dei vantaggi più singolari è l'eliminazione dello slippage. Dal momento che i conti secondari rispecchiano solo il prezzo dell'conto master è dunque impossibile vedere differenze di prezzo. Vi è pertanto la garanzia che le operazioni nei conti secondari verranno aperte al prezzo deciso dal Master trader.</p><p class="text-center">Crediamo che questa sia una caratteristica che altre applicazioni simili semplicemente non possono offrire. Naturalmente, questo non è l'unico vantaggio.</p>`,
        title: "Vantaggi PAMM/MAM",
        point: [
          "Diretta liquidità del mercato per i conti master",
          "Monitoraggio della Commissione",
          "Perfetta corrispondenza tra Master e Sub-Trade",
          "Varie opzioni di reporting",
          "Eliminazione dello slippage tra i conti secondari",
          "Facile implementazione EA",
          "Controllo completo sui conti del cliente tramite un conto",
          "Sono consentiti tutti tipi di trading",
        ],
        titleTwo: "PAMM/MAM Model",
        mamModel: "modelit",
        mamModelPhone: "modelPhit",
      },
    ],
  },
  de: {
    keyFeatures: "Hauptmerkmale",
    advantages: "Vorteile",

    TitleNewSection:
      "TrioMarkets ermöglicht es Ihnen, MT4 auf praktisch jedem Gerät zu handeln",
    NewSectionText1:
      "Entdecken Sie den Online-Handel, wie er sein sollte, mit MetaTrader auf Ihrem Desktop.",
    NewSectionTitle1: "MT4 für PC",
    NewSectionTitle2: "MT4 für Mac",
    NewSectionText2:
      "MT4 zeichnet sich als führende Handelsplattform für Mac aus und bietet fortschrittliche Funktionen sowie eine Vielzahl von technischen Analysewerkzeugen.",
    PhoneTitle1: "MT4 für Android",
    PhoneText1:
      "Speziell für iOS entwickelt, integriert die MT4-App für das iPhone von TrioMarkets nahtlos alle Funktionen von MT4 für bequemes Trading unterwegs.",
    PhoneTitle2: "MT4 für Iphone",
    PhoneText2:
      "Erleben Sie die gesamte Bandbreite der MetaTrader-Funktionen mit Flexibilität und Portabilität durch die Installation von MT4 für Android.",

    metaTraderInfo: [
      {
        text: `TrioMarkets™ hat es sich zur Aufgabe gemacht, unseren Kunden modernste Handelstechnologie zur Verfügung zu stellen. Aus diesem Grund setzen wir gerne den preisgekrönten MT4 (MetaTrader 4) ein. MT4 ist die meistgenutzte Plattform der Welt und wird praktisch zum Standard in der Welt des Online-Handels. Es ist sehr leistungsfähig und bietet eine große Auswahl an Diagrammen und Analysewerkzeugen. Es kann kostenlos auf jedem Computer unabhängig vom Betriebssystem installiert werden.<br>Der MT4 lässt sich sehr einfach auf jedem Computer installieren und gibt Ihnen Zugriff auf all unsere Trading-Instrumente.  Die MT4-Plattform bietet unseren Tradern außerdem über 50 Charting-Tools und integrierte Indikatoren zur Auswahl. Trades können manuell oder automatisch ausgeführt werden.`,
        points: [
          { img: "real-time", name: "Innovative <br> Management-Tools" },
          {
            img: "customizable",
            name: "Hochentwickelte <br> Handelsausführung",
          },
          { img: "one-click", name: "Sentiment-Trading" },
        ],
        pointsTwo: [
          "Forex, Rohstoffe und Indizes auf einer einzigen Plattform traden.",
          "Die Möglichkeit Limit- und Stop-Levels einzurichten, beginnend mit 1 Pip auf jedes Forex-Hauptwährungspaar.",
          "Unsere Plattform bietet anonymes Forex-Traden an.",
          "Die MT4-Oberfläche ist vollständig personalisierbar und bietet Ihnen die Möglichkeit, Ihre Investitionen zu überwachen.",
          "Es gibt keine Trading-Konflikte zwischen den Kunden und den Händlern.",
          "Die Möglichkeit Verluststops und Gewinneinnahmen einzurichten, während Sie zur gleichen Zeit einen weiteren Trade eröffnen.",
          "Option um Mini Lots zu handeln (0.01 Lots).",
          "Es gibt keine Trading-Einschränkungen.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend ist eine Reihe von marktbasierten Anwendungen, die für TrioMarkets™-Händler entwickelt wurden, um ihre Handelsfähigkeiten zu optimieren`,
        points: [
          { img: "1", name: `Innovative <br> Management-Tools` },
          { img: "2", name: `Hochentwickelte <br> Handelsausführung` },
          { img: "3", name: `Sentiment-Trading` },
          { img: "4", name: `Hochentwickelte <br> Alarmfunktionen` },
          { img: "5", name: `Nachrichten <br> und Marktdaten` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "Ein großartiger persönlicher Handelsassistent, Marktnachrichten und Kursbewegungen sowie der Möglichkeit, automatisierte Handlungen wie das Versenden von E-Mails und SMS oder das Eröffnen von Trades durchzuführen.",
            },
            {
              img: "02",
              text: "Bietet Tradern die Möglichkeit, Nachrichten, Wirtschaftskalender und Live-Signale abzurufen und sich über die MT4-Plattform zu informieren.",
            },
            {
              img: "03",
              text: "Vergleicht die Korrelation zwischen zwei Vermögenswerten und bietet die Möglichkeit einer schnellen Trading-Ausführung auf der Grundlage von Echtzeitvergleichen zwischen Märkten.",
            },
            {
              img: "04",
              text: "Bietet Tradern wertvolle Einblicke in Marktinformationen über die Korrelation zwischen Vermögenswerten in verschiedenen Märkten und ermöglicht ihnen so eine schnelle Einschätzung ihres Marktrisikos.",
            },
            {
              img: "05",
              text: "Leistungsstarkes Analyse-Tool in Excel, das auf Echtzeit-Marktdaten basiert und es Händlern ermöglicht, Daten direkt aus dem MT4 zu überwachen, auszuwerten und zu reporten.",
            },
            {
              img: "06",
              text: "Bietet volle Kontrolle über Marktpreise und alle Handelskontoaktivitäten. Trader können ihr Konto, ihre Aufträge und Ausführungen bequem von einem Fenster aus verwalten.",
            },
          ],
          [
            {
              img: "07",
              text: "Erstellt Pending- und Market-Order-Vorlagen für eine ultraschnelle Ausführung. Das Mini-Terminal stellt eine eigenständige kleinere Version des Trade-Terminals dar, mit dem Fokus auf einen einzelnen Markt.",
            },
            {
              img: "08",
              text: "Bietet Live-Sentiment-Daten über Märkte, so dass Trader in Echtzeit über die Marktstimmung informiert werden und effektiver handeln können. Die App zeigt auch historische Sentiment-Statistiken neben den Marktpreisen.",
            },
            {
              img: "09",
              text: "Zeigt die Zeiten der Live - und anstehenden Tradingsitzungen auf einer Weltkarte an. So werden die wichtigsten Märkte, Spitzenliquiditätszeiten, sowie Markierungen für zukünftige Nachrichtenankündigungen angezeigt.",
            },
            {
              img: "10",
              text: "Bietet Tradern die Möglichkeit, versteckte Pending Orders, Stop Losses und Take Profits zu platzieren, die erst dann in ihrem MT4-Konto erscheinen, wenn die tatsächliche Marktausführung stattfindet.",
            },
            {
              img: "11",
              text: "Zeigt eine Vielzahl von verschiedenen Tick-Charts an und verfügt über eine Ein-Klick-Handelsfunktionalität, die es Tradern erlaubt kurzfristige Trades schnell und präzise auszuführen. Dieses Tool eignet sich perfekt für Scalper.",
            },
            {
              img: "12",
              text: "Eine voll funktionsfähige All-in-One-App, die Tradern eine schnelle Orderausführung und Positionsverwaltung bietet. Sie bietet u. a. erweiterte Stop-Loss- und Take-Profit-Komponenten, erweiterte Close-All-Funktionen, detaillierte Orderberechnungen (Pip-Wert, Größe), Ordervorlagen und OCO-Orders.",
            },
            {
              img: "13",
              text: "Ein Indikatoren-Paket mit sehr gefragten Chart-Indikatoren wie z.B Renko, Auftragshistorie, Pivot, Diagrammen, Candle Countdown, Freehand Drawing und vielem mehr.",
            },
          ],
        ],
        title: "Verfügbare Gebrauchsanweisungen",
        textTwo:
          "Alle MT4 Trio Xtend Apps werden durch umfassende Handbücher unterstützt und stehen Tradern, die MT4 Trio Xtend nutzen, zur Verfügung.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets™ erlaubt es Ihnen, egal von wo aus, bequem zu traden. Hierzu müssen Sie nur eine Internetverbindung haben, um traden zu können. Desweiteren bekommen Sie Ihre ausführliche Marktanalyse von der MetaTrader 4 Applikation.",
        points: [
          "Markt- und Einstiegsbedingungen",
          "Stop- und Limit-Order setzen",
          "Möglichkeit, von mehreren Konten aus zu traden",
          "Echtzeit-Kurse",
          "Interaktive Grafiken",
          "Echtzeit-Grafiken",
          "Mehrere Zeitfenster",
          "Mehr als 30 technische Indikatoren",
          "Die Möglichkeit Ihren Kontostand, Ihr Eigenkapital und mehr zu überprüfen",
          "Unser mobiler Trader wird automatisch mit dem Desktop synchronisiert",
          "Offene Positionen ändern",
          "Handlungsverlauf",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "TrioMarkets™ hat die besten Handelstechnologien, um unsere geschätzten Trader bei ihren täglichen Geschäften zu unterstützen. Von unserer preisgekrönten und technologischfortgeschrittenen Meta Trader 4 Plattform (MT4) bis hin zu unserem neuen MT4 Zusatz-Tool, dem MT4 WebTrader.<br> Der WebTrader ist eine Ergänzung für Ihren MT4 Trader und ermöglicht es Ihnen, von jedem Computer oder mobilen Smartphone aus zu traden. Alles, was Sie brauchen, ist eine Internetverbindung.",
        title: "Warum WebTrader?",
        textTwo: `Die heutige Marktvolatilität bedeutet, dass Online-Trader eine fortschrittliche Handelsplattform benötigen, um die finanziellen Märkte in Echtzeit zu beobachten. Von Forex-Märkten, über CFDs, bis hin zu Rohstoffen. Der WebTrader von TrioMarkets bietet Ihnen den Vorteil von Hochgeschwindigkeits-Handelsausführungen.<br>Dieses fortschrittliche Zusatz-Tool für den MT4-Trader bietet unseren Online-Tradern eine einzigartige Möglichkeit, Geld auf den Forex-Märkten zu verdienen. Sie müssen keine zusätzliche Software installieren, sondern fügen es ganz einfach zu Ihrem MT4-Konto hinzu.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "In der Fülle an Möglichkeiten fehlt es Ihnen an Zeit und den nötigen Fähigkeiten zum Investieren? Seien Sie smart und folgen der Performance profitabler Trader, um sich ihre Erfolge zunutze zu machen. Kopieren Sie ihre Trading-Strategien und lernen Sie von ihrer Expertise. TrioMarkets steigt in das Social Trading ein und möchte auch Ihre Trading-Reichweite erweitern.",
        multiply:
          "Vervielfachen Sie Ihre <br> Trading-Erfahrung und Ihre Gewinne",
        points: [
          { img: "easy-use", name: "Einfache Bedienung" },
          { img: "replication", name: "Auto-Replikation" },
          { img: "risk", name: "Risiko-Management" },
          { img: "vps", name: "Kostenloser VPS" },
        ],
        title: "Nur das Beste ist gut genug",
        textTwo:
          "Sie wollen selbstbewusst in einem profitablen und automatisierten Umfeld traden? Social Trading ist leicht zugänglich und gibt Ihnen die Möglichkeit, für sich die profitabelsten Trader auszuwählen. Der Marktplatz bietet ein Suchwerkzeug und eine Filterfunktion, mit der Sie Monate seit Gründung, Risikoverhältnis, maximalen Verlust und Profitabilität bestimmen können. Finden Sie den Trader, der perfekt zu Ihren Handels- und Investitionsbedürfnissen passt und fahren Sie Gewinne ein, während Sie sich entspannt zurücklehnen.",
        titleTwo: "Wir teilen Erfolge",
        textThree:
          "Werden Sie Dank wachsender Erfahrung und Expertise zum Signal-Trader und erzielen Sie mit jedem Neuling, der Ihre Trades kopiert, Gewinne. Je mehr Follower Ihre Strategien kopieren, desto höher wird Ihre Auszahlung sein. Profitieren Sie von der Wertschöpfung einer erfolgreichen Community.",
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (Financial Information Exchange) API (Application Programming Interface) ist ein elektronisches Kommunikationsprotokoll für den Austausch von Finanzinformationen.<br>Das Trading-Verhalten entwickelt sich weiter. Hochfrequenzhandel und Latenz-Arbitrage werden immer beliebter. Geschwindigkeit ist für diese neu entstehenden Strategien sehr wichtig. Die Veränderungen erfordern neue fortschrittliche Technologien.<br>FIX API ermöglicht jedem Trader eine direkte Verbindung zu unserem Liquiditätspool mit minimierter Latenz und Kosten. Die minimierte Latenz erlaubt es dem Trader, seine einzigartigen Handelsstrategien zu realisieren.`,

        textTwo: `<p>TrioMarkets™ bietet ausschließlich seinen institutionellen Kunden und denen, die im Hochvolumenhandel tätig sind, eine FIX-API.</p>`,
        points: [
          "Multi-Tier Liquidität zu niedrigen Kosten",
          "Fähigkeit zur Verbindung mit verschiedenen Plattformen wie dem CTrader oder Ninja Trader",
          "Besonders schnelle Ausführung",
          "Eigens entwickelte Plattform Kompatibilität",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `VPS-Hosting (Virtual Private Server) ermöglicht es Forex-Händlern, die virtuelle Umgebung auf den Servern des Hosting-Unternehmens zu nutzen, um die MetaTrader-Expertenberater auszuführen.<br>Das Traden mithilfe von Expertenberater und speziellen Algorithmen ist in letzter Zeit sehr verbreitet geworden. Geschwindigkeit und Ausdauer sind zwei der Schlüssellemente für ein gut funktionierendes Handelssystem.<br>Man kann keine erfolgreiche Performance erwarten, wenn Ihr Expertenberaterprogramm Verbindungsschwierigkeiten hat. Als Trader sollten Sie immer sicherstellen, dass Ihr Expertenberater zu jeder Zeit läuft.`,
        textTwo: `<p class="text-center">Ebenso wie Ausdauer ist auch die Geschwindigkeit von höchster Wichtigkeit. Die Distanz zu den Handelsservern spielt hier bei der Tradingausführung eine ganz entscheidende Rolle. Je näher Sie an unseren Servern sind, desto schneller wird Ihr Tradingauftrag ausgeführt und Ihr Tradingsystem somit besser arbeiten.</p><strong class="text-center">Aus diesem Grund bieten wir VPS an.</strong><p class="text-center mt-3">Mithilfe von VPS können Sie Trades ausführen, als ob Sie in London wären, obwohl Sie tatsächlich in Australien sitzen. Selbst wenn Sie Ihren Computer ausschalten, wird Ihr Expertenberater weiterhin nach den von Ihnen gesetzten Parametern weitertraden.</p>`,
        title: "Vorteile eines VPS",
        points: [
          "Handeln Sie zu jeder Tageszeit. Das System wird die Trades für Sie ausführen, daher ist es nicht notwendig an Ihrem Computer zu bleiben.",
          "VPS-Serversysteme werden regelmäßig überprüft, um sicherzustellen, dass sie ordnungsgemäß laufen und Ihnen keine technischen Schwierigkeiten bereiten.",
          "Ein VPS kann Ihre Trades schneller ausführen als Ihr Computer, weil es um einiges schneller darin ist, Orderaufträge weiterzuleiten. Das Ergebnis ist weniger Verzögerung und weniger Abweichung.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets™ ist eine Partnerschaft mit ForexVPS.net und HokoCloud eingegangen.</p><p class="text-center text-white">Beide Unternehmen bieten ein qualitativ hochwertiges Produkt und 100% Betriebszeitgarantie. Wenn Sie Ihre Expertenberater ohne jegliche Unterbrechung laufen lassen wollen, ist Forex VPS oder HokoCloud für den MetaTrader genau was Sie brauchen, weil der VPS immer online ist.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `Wir ermöglichen die Verwaltung mehrerer Konten über ein Master-Konto mit der PAMM/MAM-Anwendung, die von Money Managern verwendet wird.<br>Eine PAMM/MAM verteilt Trades auf einem Stammkonto anteilig den Unterkonten zu, indem verschiedene Allokationsmethoden genutzt werden, z. B. Aktienkapital, GuV oder Balance.<br>Die beliebteste Allokationsmethode unter Tradern ist die auf Aktienkapital basierende. Sie ordnet Master-Trades den Unterkonten basierend auf der Höhe ihres Eigenkapitals zu.`,
        textTwo: `<strong class="text-center text-uppercase">Eigenkapital Allokationsmethode:</strong><p class="text-center mt-3">Der Trader eröffnet 1 Lot auf EURUSD und der Eigenkapitalstatus war 10k, 20k, 30k, 15k und 25k in jedem Unterkonto. PAMM/MAM teilt das 1 Lot proportional zu jedem Unterkonto als 0.1, 0.2, 0.3, 0.15 und 0.25 zu. Dies ist der Hauptgrund, warum die Equity-Methode am häufigsten verwendet wird, da sie solide und einfach zu implementieren ist.</p><p class="text-center">Der Devisenhandel durch PAMM/MAM hat viele Vorteile. Einer der einzigartigsten ist die Verzögerungsunterbrechung. Da die Unterkonten die Preisgestaltung des Stammkontos nur widerspiegeln, ist es unmöglich, Preisabweichungen zu sehen. Es ist also garantiert, dass alle Unterhändel von dem Stammhandel eingetragen werden.</p><p class="text-center">Unseres Erachtens nach, handelt es sich hier um eine Funktion, die andere ähnliche Applikationen einfach nicht anbieten können. Natürlich ist das nicht der einzige Vorteil.</p>`,
        title: "Vorteile PAMM/MAM",
        point: [
          "Direkte Marktliquidität für die Stammkonten",
          "Überwachung der Provision",
          "Gesamtansicht über die Master und Unterkonten",
          "Verschiedene Berichtsoptionen",
          "Überwachung der Abweichung zwischen den Unterkonten",
          "Leichter EA Einsatz",
          "Vollständige Kontrolle über die Kundenkonten durch ein Konto",
          "Alle Handelsarten sind erlaubt",
        ],
        titleTwo: "PAMM/MAM Modell",
        mamModel: "modelde",
        mamModelPhone: "modelPhde",
      },
    ],
  },
  cn: {
    keyFeatures: "主要特点",
    advantages: "优势",

    TitleNewSection: "TrioMarkets允许您在几乎任何设备上进行MT4交易",
    NewSectionText1: "在您的桌面上通过MetaTrader发现应有的在线交易方式。",
    NewSectionTitle1: "适用于PC的MT4",
    NewSectionTitle2: "适用于Mac的MT4",
    NewSectionText2:
      "MT4以其先进的功能和众多的技术分析工具成为Mac的首选交易平台。",
    PhoneTitle1: "适用于Android的MT4",
    PhoneText1:
      "通过安装MT4 for Android，随时随地体验MetaTrader的全部主要功能，灵活便捷。",
    PhoneTitle2: "适用于iPhone的MT4",
    PhoneText2:
      "专为iOS量身定制，TrioMarkets的MT4 iPhone应用将MT4的所有功能无缝集成，方便您在移动中进行交易",

    metaTraderInfo: [
      {
        text: `TrioMarkets™ 致力于为我们的客户提供尖端的交易技术。这就是为什么我们很高兴使用屡获殊荣的MT4 (MetaTrader 4)。 MT4 易于安装在任何计算机上，并提供对我们所有交易资产的访问。我们的交易者还可以从 MT4平台上的50多种图表工具和内置指标中进行选择。交易可以手动或自动执行。<br>MT4可以很容易地安装在任何计算机上，让您可以访问我们所有的交易资产。MT4平台还为我们的交易者提供了50多种图表工具和内置指标供您选择。交易可以手动或自动执行。`,
        points: [
          { img: "real-time", name: "实时 <br> 工具" },
          { img: "customizable", name: "可定制 <br> 的功能" },
          { img: "one-click", name: "一键 <br> 交易选项" },
        ],
        pointsTwo: [
          "从单一平台交易外汇、商品和指数。",
          "能够在所有外汇主要货币对上设置一 (1) 个点的限价单和止损单。",
          "我们的平台提供匿名外汇交易。",
          "MT4 界面是完全可定制的，让您能够监控您的投资。",
          "客户与经纪商之间不存在交易冲突。",
          "能够在开设另一笔交易的同时设置止损和获利。",
          "可以交易迷你手数，也就是0.01手。",
          "没有交易限制。",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend 是一套面向市场的应用程序，专为 Triomarkets 交易者创建，以优化他们成功交易的能力。<br>MT4 TrioXtend 为交易者提供尖端工具作为 MT4 交易平台的扩展，为新手和有经验的交易者提供了作为专业人士进行交易的独特机会。`,
        points: [
          { img: "1", name: `创新 <br> 管理工具` },
          { img: "2", name: `高级<br> 交易执行` },
          { img: "3", name: `情绪交易` },
          { img: "4", name: `复杂的 <br> 警报` },
          { img: "5", name: `新闻和 <br> 市场数据` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "完美的个人交易助手，具有基于事件的交易活动、市场新闻、价格行为警报，并能够执行自动化操作，例如发送电子邮件、短信或开仓交易。",
            },
            {
              img: "02",
              text: "为交易者提供从MT4平台连接到新闻、经济日历、实时信号和接受教育的能力。",
            },
            {
              img: "03",
              text: "比较两种资产之间的相关性，提供基于市场之间实时比较的快速交易执行机会。",
            },
            {
              img: "04",
              text: "为交易者提供有价值的市场信息，了解不同市场中资产之间的相关性，使交易者能够快速评估其市场风险。",
            },
            {
              img: "05",
              text: "基于实时市场数据的Excel强大的分析工具，使交易者能够直接从MT4监控、评估和报告数据。",
            },
            {
              img: "06",
              text: "提供对市场观察价格的全面控制，以及所有交易账户活动。交易者可以从一个方便的窗口管理他们的账户、订单和执行情况。",
            },
          ],
          [
            {
              img: "07",
              text: "创建挂单和市价单模板以实现超快速执行。迷你终端是交易终端的独立较小版本，专注于单一市场。",
            },
            {
              img: "08",
              text: "提供跨市场的实时情绪数据，使交易者能够实时更新市场情绪并更有效地进行交易。该应用程序还显示历史情绪统计数据以及市场价格。",
            },
            {
              img: "09",
              text: "显示世界地图上的实时和待定交易时段时间，指示关键市场和流动性高峰时间以及未来新闻公告的标记。",
            },
            {
              img: "10",
              text: "为交易者提供设置隐藏挂单、止损和止盈的机会，这些只有在实际市场执行发生时才会出现在他们的 MT4 账户中。",
            },
            {
              img: "11",
              text: "显示各种不同的报价图表，并拥有一键交易功能，让交易者有机会快速准确地执行短期交易。这个工具非常适合剥头皮者。",
            },
            {
              img: "12",
              text: "一款功能齐全的一体化应用程序，为交易者提供快速的订单执行和头寸管理。它具有高级止损和止盈组件、高级关闭所有功能、深入的订单计算（点值、大小）、订单模板和 OCO 订单等功能。",
            },
            {
              img: "13",
              text: "一个包含备受追捧的图表指标的指标包，包括砖形图、订单历史、枢轴、图表组、蜡烛倒计时、手绘等等。",
            },
          ],
        ],
        title: "可用手册",
        textTwo:
          "所有 MT4 TrioXtend 应用程序都得到全面用户指南的全面支持，并且可供使用 MT4 TrioXtend 的交易者使用。",
      },
    ],
    mobileTradingInfo: [
      {
        text: "无论您身在何处, TrioMarkets™ 都能让您轻松进行交易。只要您有互联网连接，您就可以在 MetaTrader 4 最先进的移动应用程序上进行交易并获得所有市场分析。",
        points: [
          "市价单和条件单",
          "设置止损和限价单",
          "从多个账户进行交易的能力",
          "实时报价",
          "交互式图表",
          "实时图表",
          "多个时间范围",
          "30多项技术指标",
          "能够检查您的余额、净值等",
          "我们的移动交易终端自动同步到台式电脑",
          "编辑持仓交易头寸",
          "交易历史",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "TrioMarkets™ 始终采用最好的交易技术来帮助我们尊贵的交易者进行日常交易。从我们屡获殊荣且技术先进的MetaTrader 4平台 (MT4) 到我们新的MT4附加工具MT4 WebTrader。<br>WebTrader是您MT4 Trader的附加功能，允许您从任何电脑或移动智能设备进行交易。您所需要的只是互联网连接。",
        title: "为什么选择WebTrader？",
        textTwo: `当今动荡的市场意味着在线交易者需要一个旨在提供实时金融市场的先进交易平台。从外汇市场到差价合约和商品。TrioMarkets™ WebTrader 通过高速交易执行为您提供这一优势。这种适用于 MT4 交易者的高级附加工具为我们的在线交易者提供了一种在外汇市场上赚钱的独特方式。无需安装额外软件，只需将其添加到您的 MT4 账户即可`,
      },
    ],
    socialTradingInfo: [
      {
        text: "在众多机会中, 您缺乏投资的时间和技能? 通过跟踪盈利交易者的表现并利用他们的成功来明智地投资。复制他们批准的交易策略并学习他们的专业知识。TrioMarkets™ 加入社交交易世界，希望您扩大交易范围。",
        multiply: "成倍增加您的交易 <br> 经验和收益",
        points: [
          { img: "easy-use", name: "使用方便" },
          { img: "replication", name: "自动复制" },
          { img: "risk", name: "风险管理" },
          { img: "vps", name: "免费 VPS" },
        ],
        title: "只有最好的才足够好",
        textTwo:
          "您想在有利可图的自动化环境中充满信心地进行交易吗？社交交易很容易采用，让您有机会选择最有利可图的交易者。市场提供了一个搜索工具和一个过滤功能，让您可以确定自成立以来的月份数、风险比率、最大损失和盈利能力。找到最适合您的交易和投资需求的交易者，并在您放松的同时赚取收益。",
        titleTwo: "分享即是关怀",
        textThree:
          "抓住机会成为具有不断增长经验和专业知识的信号交易员，并从每个复制您交易的新手那里获得收益。追随者复制你的策略越多，你的回报就越高。",
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (金融信息交换) API (应用程序编程接口）是一种用于金融信息交换的电子通信协议。<br>交易行为正在演变。高频交易和延迟套利正变得越来越流行。速度对于这些新兴战略来说非常重要。变化需要新的先进技术。<br>FIX API 允许任何交易者以最小的延迟和成本直接连接到我们的流动性池。最小的延迟允许交易者实现他们独特的交易策略。`,

        textTwo: `<p>TrioMarkets™专为其机构客户和高交易量客户提供FIX API服务。</p>`,
        points: [
          "低成本多层次流动性",
          "能够连接各种平台，如 Ctrader 或 Ninja Trader",
          "卓越的执行速度",
          "自定义构建平台兼容性",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `VPS(虚拟专用服务器)托管允许外汇交易者使用托管公司服务器上的虚拟环境来运行 MetaTrader 专家顾问。<br>使用 Expert Advisor 和特殊算法进行交易已变得非常普遍。速度和持久性是交易系统正常运行的两个关键要素。<br>如果您的专家顾问功能遇到连接问题，就不可能期望成功。作为交易者，您需要确保您的智能交易系统始终正常运行。`,
        textTwo: `<p class="text-center">与持久性一样，速度非常重要，与交易服务器的距离对执行起着非常决定性的作用。您离我们的服务器越近，您的执行请求就会越快交付，您的交易系统就会以更好的方式运行。</p><strong class="text-center mt-3">因此，我们为您提供 VPS。</strong><p class="text-center">通过VPS, 即使您在澳大利亚, 您也可以像在伦敦一样进行交易。即使您关闭电脑, 您的“EA 交易”仍会根据您之前设置的设置继续运行</p>`,
        title: "VPS优势",
        points: [
          "在一天中的任何时间进行交易。系统将为您执行交易，因此您无需待在您的电脑旁。",
          "VPS 服务器系统会定期检查以确保它们正常运行，因此您几乎不会遇到任何技术问题。",
          "VPS 可以比您的电脑更快地执行您的交易，因为它传输订单的速度要快得多。结果是您遇到更少的延迟和更少的滑点。 ",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets™ 已与 ForexVPS.net 和 HokoCloud 合作。</p><p class="text-center text-white">他们都有高质量的产品，保证 100% 的正常运行时间。如果您想不间断地连续运行您的专家顾问，那么 MetaTrader 的Forex VPS 或 HokoCloud 托管是您真正需要的，因为VPS始终在线。</p>`,
      },
    ],
    mamInfo: [
      {
        text: `我们允许资金经理使用PAMM/MAM应用程序，通过一个主账户管理多个账户。<br>它通过使用账户净值、盈亏或余额等各种分配方法将主账户交易按比例分配到子账户。<br>最热门的分配方式是账户净值。它根据子账户的净值金额分配主账户交易。`,
        textTwo: `<strong class="text-center text-uppercase">账户净值分配方式：</strong><p class="text-center mt-3">交易者在EURUSD上开立1手，每个子账户的净值状态为10k、20k、30k、15k和 25k。MAM将1手按比例分配给每个子账户，分别为0.1、0.2、0.3、0.15和0.25。这是账户净值分配方式应用较多的主要原因，因为它扎实且易于实施。</p><p class="text-center">这是金额权益法应用较多的主要原因，因为它扎实且易于实施。使用 MAM 应用程序进行外汇交易有多种优势。最独特的优势之一是滑点拦截。由于子账户仅反映主账户的定价，因此不可能看到价格差异。保证所有子交易都将从主交易价格输入。 </p><p class="text-center">我们相信这是其他类似应用程序根本无法提供的功能。当然，这并不是唯一的优势。</p>`,
        title: "PAMM/MAM的优势",
        point: [
          "主账户直接对接市场流动性",
          "佣金监控",
          "主账户和子账户的完整交易概述",
          "各种报告选项",
          "子账户间的滑点拦截",
          "简单的 EA 实施",
          "通过一个账户完全控制客户帐户",
          "允许所有交易类型",
        ],
        titleTwo: "MAM模型",
        mamModel: "modelcn",
        mamModelPhone: "modelPhcn",
      },
    ],
  },
  es: {
    keyFeatures: "Características Clave",
    advantages: "Ventajas",

    TitleNewSection:
      "TrioMarkets te permite operar con MT4 en casi cualquier dispositivo",
    NewSectionText1:
      "Descubre el trading en línea como debería ser con MetaTrader en tu escritorio.",
    NewSectionTitle1: "MT4 para PC",
    NewSectionTitle2: "MT4 para Mac",
    NewSectionText2:
      "MT4 se destaca como la principal plataforma de trading para Mac, ofreciendo funciones avanzadas y una gran cantidad de herramientas de análisis técnico.",
    PhoneTitle1: "MT4 para Android",
    PhoneText1:
      "Experimenta la gama completa de funciones de MetaTrader con flexibilidad y portabilidad al instalar MT4 para Android.",
    PhoneTitle2: "MT4 para iPhone",
    PhoneText2:
      "Adaptada y diseñada para iOS, la aplicación MT4 para iPhone de TrioMarkets integra de manera perfecta todas las funcionalidades de MT4 para realizar operaciones de forma conveniente mientras te desplazas.",

    metaTraderInfo: [
      {
        text: `TrioMarkets™ se dedica a 6proporcionar a nuestros clientes tecnología comercial de vanguardia. Es por eso que nos complace utilizar el galardonado MT4 (MetaTrader 4). MT4 es la plataforma más utilizada del mundo, convirtiéndose prácticamente en el estándar en el mundo del trading online. Muy potente, ofrece una gran variedad de gráficos y herramientas de análisis. Se puede instalar de forma gratuita en cualquier ordenador independientemente del sistema operativo.<br>El MT4 se puede instalar fácilmente en cualquier computadora y le brinda acceso a todos nuestros activos comerciales. La plataforma MT4 también brinda a nuestros operadores más de 50 herramientas de gráficos e indicadores incorporados para elegir. Las operaciones se pueden ejecutar de forma manual o automática.`,
        points: [
          { img: "real-time", name: "Herramientas en <br> Tiempo Real" },
          { img: "customizable", name: "Funciones <br> Personalizables" },
          { img: "one-click", name: "Opciones de Negociación con un Clic" },
        ],
        pointsTwo: [
          "Trading de divisas, materias primas e índices desde una única plataforma.",
          "La capacidad de colocar niveles de límite y parada desde un (1) pip en todos los pares principales de Forex.",
          "La nuestra plataforma ofrece comercio anónimo de FX.",
          "La interfaz MT4 es totalmente personalizable y le brinda la posibilidad de monitorear sus inversiones.",
          "No hay conflictos de trading entre el cliente y el corredor.",
          "Capaz de establecer stop loss y obtener ganancias al abrir otra operación simultáneamente.",
          "Opción de operar con mini lotes, también llamados lotes de 0.01.",
          "No hay restricciones de trading.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend es una suite de apps informadas por el mercado creadas para que los traders de TrioMarkets™ optimicen su capacidad de trading`,
        points: [
          { img: "1", name: `Herramientas de <br> Gestión Innovadoras` },
          { img: "2", name: `Ejecución de <br> Trading Avanzada` },
          { img: "3", name: `Trading <br> de Sentimiento` },
          { img: "4", name: `Alarmas <br> Sofisticadas` },
          { img: "5", name: `Noticias y <br> Datos de Mercado` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "Un gran asistente comercial personal, con alertas basadas en eventos sobre la actividad comercial, noticias del mercado, acción de los precios, y tiene la capacidad de realizar acciones automatizadas, como el envío de correos electrónicos, SMS, o la apertura de operaciones.",
            },
            {
              img: "02",
              text: "Ofrece a los operadores la posibilidad de conectarse a las noticias, al calendario económico, a las señales en directo y de formarse, todo ello desde la plataforma MT4.",
            },
            {
              img: "03",
              text: "Compara la correlación entre dos activos, brindando la oportunidad de ejecutar operaciones rápidamente basándose en comparaciones en tiempo real entre mercados.",
            },
            {
              img: "04",
              text: "Proporciona a los operadores información valiosa sobre la correlación entre activos en diferentes mercados, lo que les permite evaluar rápidamente su riesgo de exposición al mercado.",
            },
            {
              img: "05",
              text: "Potente herramienta de análisis en Excel basada en datos de mercado en tiempo real, que permite a los operadores supervisar, evaluar y elaborar informes de datos directamente desde la MT4.",
            },
            {
              img: "06",
              text: "Proporciona un control total sobre los precios de vigilancia del mercado, y toda la actividad de la cuenta de operaciones. Los operadores pueden gestionar su cuenta, órdenes y ejecuciones desde una cómoda ventana.",
            },
          ],
          [
            {
              img: "07",
              text: "Cree plantillas de órdenes pendientes y de mercado para una ejecución ultrarrápida. La Mini Terminal es una versión más pequeña e independiente de Trade Terminal, que se centra en un solo mercado.",
            },
            {
              img: "08",
              text: "Ofrece datos de sentimiento en vivo en todos los mercados, lo que permite a los operadores estar actualizados en tiempo real sobre el sentimiento del mercado y operar de manera más efectiva. La aplicación también muestra estadísticas históricas de sentimiento junto con los precios del mercado.",
            },
            {
              img: "09",
              text: "Muestra los tiempos de las sesiones de negociación en vivo y pendientes en todo el mapa mundial que indican los mercados clave y los tiempos de máxima liquidez y los marcadores para futuros anuncios de noticias.",
            },
            {
              img: "10",
              text: "Brinda a los operadores la oportunidad de colocar órdenes pendientes ocultas, detener pérdidas y tomar ganancias, que solo aparecerán en su cuenta MT4 cuando ocurra la ejecución real del mercado.",
            },
            {
              img: "11",
              text: "Muestra una variedad de gráficos de ticks diferentes y cuenta con una funcionalidad de negociación con un solo clic que permite a los operadores la oportunidad de ejecutar transacciones a corto plazo de forma rápida y precisa. Esta herramienta es perfecta para revendedores.",
            },
            {
              img: "12",
              text: "Una aplicación todo en uno completamente funcional que ofrece a los operadores una rápida ejecución de órdenes y gestión de posiciones. Incluye, entre otros, componentes avanzados de stop-loss y take profit, funciones avanzadas de cierre total, cálculos detallados de órdenes (valor de pip, tamaño), plantillas de órdenes y órdenes OCO.",
            },
            {
              img: "13",
              text: "Un paquete de indicadores repleto de indicadores de gráficos muy solicitados, que incluye Renko, historial de pedidos, pivote, grupo de gráficos, cuenta regresiva de velas, dibujo a mano alzada y muchos más.",
            },
          ],
        ],
        title: "Manuales Disponibles",
        textTwo:
          "Todas las aplicaciones de MT4 TrioXtend son totalmente compatibles con guías de usuario completas y están disponibles para los comerciantes que utilizan MT4 TrioXtend.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets™ le facilita el comercio sin importar dónde se encuentre. Siempre que tenga una conexión a Internet, puede operar y obtener todos sus análisis de mercado en la aplicación móvil de última generación MetaTrader 4.",
        points: [
          "Órdenes de mercado y de entrada condicional",
          "Establecer órdenes de stop y límite",
          "La capacidad de operar desde múltiples cuentas",
          "Cotizaciones en vivo",
          "Gráficos interactivos",
          "Gráficos en tiempo real",
          "Múltiples marcos de tiempo",
          "Más de 30 indicadores técnicos",
          "La capacidad de verificar su saldo, capital y más",
          "Nuestro móvil trader se sincroniza automáticamente con el escritorio",
          "Editar posiciones abiertas",
          "Historial de Trading",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "TrioMarkets™ ofrece constantemente lo más tecnologías comerciales de vanguardia a nuestro valioso comerciantes para ayudarlos con sus operaciones diarias. De nuestro galardonado y tecnológicamente avanzado Meta Trader 4 Platform (MT4) a nuestro nuevo complemento MT4 herramienta, el MT4 WebTrader. El Web Trader es un adicional a su MT4 que le permite operar desde cualquier computadora o dispositivo móvil inteligente. Todo lo que necesita es un pasante.<br>WebTrader es una adición a su METATrader y le permite operar desde cualquier computadora o dispositivo móvil inteligente. Todo lo que necesita es una conexión a Internet.",
        title: "Por qué WebTrader?",
        textTwo: `El mercado volátil de hoy significa que los comerciantes en línea necesitan una plataforma comercial avanzada que está diseñada para dar a los mercados financieros en tiempo real de la divisa mercados a CFD y materias primas. Web de TrioMarkets™ Trader te da esta ventaja con alta velocidad ejecuciones comerciales.<br>Esta herramienta adicional avanzada para el comerciante MT4 ofrece a nuestros comerciantes en línea una forma única de ganar dinero en los mercados de divisas. No es necesario instalar adicional software, simplemente agréguelo a su MT4 cuenta.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "Te falta tiempo y habilidades para invertir? Invierte inteligentemente siguiendo el rendimiento de los comerciantes rentables y el aprovechamiento sus éxitos. Copie sus estrategias comerciales aprobadas y aprender de su experiencia. TrioMarkets se une a la juego de comercio social y quiere que amplíes tu alcance comercial.",
        multiply: "Multiplique el su trading <br> experiencia y tus logros",
        points: [
          { img: "easy-use", name: "Fácil de Usar" },
          { img: "replication", name: "Replicación Automática" },
          { img: "risk", name: "Gestión de Riesgos" },
          { img: "vps", name: "VPS Gratis" },
        ],
        title: "Solo lo mejor es lo suficientemente bueno",
        textTwo:
          "Quiere operar con confianza en un entorno rentable y automatizado? El comercio social es fácilmente accesible y le brinda la oportunidad de elegir el comerciantes más rentables. El Marketplace proporciona una herramienta de búsqueda y un filtro.función que le permite determinar los meses desde el inicio, la relación de riesgo, Máxima Pérdida y Rentabilidad. Encuentre el comerciante que se adapte perfectamente a su comercio e invertir y capturar ganancias mientras se relaja.",
        titleTwo: "Compartir es demostrar interés",
        textThree:
          "Aproveche la oportunidad de convertirse en un comerciante de señales con experiencia y conocimientos cada vez mayores y genere ganancias con cada novato que copie sus operaciones. Cuantos más seguidores copie sus estrategias, mayor será su pago. Aprovecha la prueba social de una comunidad de alto valor”.",
      },
    ],
    fixApiInfo: [
      {
        text: `API FIX (intercambio de informacion financiera) Interfaz de programacionde aplicaciones es un protocolo de comunicaciones electronicas para el intercambio de informacion financiera.<br>Los comportamientos comerciales están evolucionando. Alta frecuencia el comercio y el arbitraje de latencia se están volviendo muy populares. La velocidad es muy esencial para estas nuevas estrategias emergentes. Los cambios exigen nuevas tecnologías avanzadas.<br>FIX API permite que cualquier comerciante se conecte directamente a nuestro fondo de liquidez con una latencia y un coste minimizados. La latencia minimizada permite al comerciante realizar sus estrategias comerciales únicas.`,

        textTwo: `<p>TrioMarkets™ proporciona exclusivamente una API FIX a sus clientes institucionales y a aquellos que realizan operaciones de alto volumen.</p>`,
        points: [
          "Liquidez multinivel de bajo coste",
          "Capacidad de conectarse con varias plataformas como Ctrader o Comerciante Ninja",
          "Velocidad de ejecución excepcional",
          "Compatibilidad con plataforma de construcción personalizada",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `El alojamiento VPS (servidor privado virtual) permite a los comerciantes de Forex utilizar el entorno virtual en los servidores de la empresa de alojamiento para ejecutar el Asesores expertos de MetaTrader.<br>El comercio con Expert Advisor y algoritmos especiales se ha vuelto muy común. Velocidad y la persistencia son 2 elementos clave para que un sistema comercial funcione correctamente.<br>No es posible esperar un desempeño exitoso si sus asesores expertos tienen conectividad asuntos. Como comerciante, debe asegurarse de que sus asesores expertos estén funcionando en todo momento.`,
        textTwo: `<p class="text-center">Al igual que la persistencia, la velocidad es muy importante y la distancia al comercio servidores juega un papel muy decisivo en la ejecución. Cuanto más cerca estés a nuestros servidores, más rápido se entregará su solicitud de ejecución y su sistema comercial funcionará de una mejor manera.</p><strong class="text-center">Por eso, te ofrecemos un VPS.</strong><p class="text-center mt-3">A través del VPS, puede realizar transacciones como si estuviera en Londres, aunque estarías sentado en Australia. Incluso si apaga su computadora, su Asesor Experto seguirá funcionando de acuerdo con la configuración que configuró previamente.</p>`,
        title: "Ventajas VPS",
        points: [
          "Opera en cualquier momento del día. El sistema ejecutará las operaciones por ti y por lo tanto, no hay necesidad de permanecer junto a su PC.",
          "Los sistemas de servidores VPS se revisan regularmente para asegurarse de que estén funcionando correctamente, por lo que casi nunca encontrará problemas técnicos.",
          "Un VPS puede ejecutar sus operaciones más rápido que su computadora porque es mucho más rápido en la transmisión de las órdenes. El resultado es que experimenta menos demoras y menos deslizamientos.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets™ se ha asociado con ForexVPS.net y HokoCloud.</p><p class="text-center text-white">Ambos tienen un producto de alta calidad con una garantía de tiempo de actividad del 100%. Si desea ejecutar sus asesores expertos continuamente sin interrupciones, lo que realmente necesita es Forex VPS o alojamiento HokoCloud para MetaTrader porque el VPS está siempre en línea.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `Permitimos el manejo de múltiples cuentas a través de una cuenta maestra utilizando la aplicación PAMM/MAM que utilizan los administradores de dinero.<br>Asigna operaciones maestras proporcionalmente a las subcuentas utilizando varios métodos de asignación, como equidad, PL o saldo.<br>El método de asignación más popular entre las operaciones es equidad. Asigna operaciones maestras a las subcuentas en función del monto de su capital.`,
        textTwo: `<strong class="text-center text-uppercase">MÉTODO DE ASIGNACIÓN PATRIMONIAL:</strong><p class="text-center mt-3">El comerciante abre 1 lote en EURUSD y estado de acciones era 10k, 20k, 30k, 15k y 25k en cada subcuenta. PAMM/MAM asigna el lote 1 proporcionalmente a cada subcuenta como 0.1, 0.2, 0.3, 0.15 y 0.25. Esta es la razón principal por la que se utiliza principalmente el método de la participación, porque es sólido y fácil de implementar.</p><p class="text-center">Hay varias ventajas en el comercio de divisas con una aplicación PAMM/MAM. Una de las ventajas más singulares es la intercepción de deslizamiento. Dado que las subcuentas solo reflejan los precios de la cuenta maestra, es imposible ver las discrepancias de precios. Se garantiza que todas las operaciones secundarias se ingresarán desde el precio comercial principal.</p><p class="text-center">Creemos que esta es una característica que otros aplicaciones similares simplemente no pueden ofrecer. Por supuesto, esta no es la única ventaja.</p>`,
        title: "Ventajas de PAMM/MAM",
        point: [
          "Liquidez directa de mercado para Cuentas Maestras",
          "Seguimiento de comisiones",
          "Vista completa sobre Master y Sub Trades",
          "Varias opciones de Informes",
          "Intersección de Deslizamiento entre Subcuentas",
          "Fácil implementación de EA",
          "Control total sobre las cuentas de los clientes a través de uno",
          "Todos los tipos de trading están permitidos",
        ],
        titleTwo: "Modelo PAMM/MAM",
        mamModel: "modeles",
        mamModelPhone: "modelPhes",
      },
    ],
  },
  vnm: {
    keyFeatures: "Các tính năng chính",
    advantages: "Thuận lợi",

    TitleNewSection:
      "TrioMarkets cho phép bạn giao dịch MT4 trên gần như mọi thiết bị",
    NewSectionText1:
      "Khám phá giao dịch trực tuyến theo cách mà nó nên là với MetaTrader trên máy tính cá nhân của bạn.",
    NewSectionTitle1: "MT4 cho PC",
    NewSectionTitle2: "MT4 para Mac",
    NewSectionText2:
      "MT4 nổi bật như là nền tảng giao dịch hàng đầu cho Mac, cung cấp các tính năng tiên tiến và nhiều công cụ phân tích kỹ thuật.",
    PhoneTitle1: "MT4 cho Android",
    PhoneText1:
      "Trải nghiệm toàn bộ tính năng của MetaTrader với sự linh hoạt và tính di động bằng cách tải về MT4 cho Android.",
    PhoneTitle2: "MT4 cho iPhone",
    PhoneText2:
      "Được tinh chỉnh và thiết kế đặc biệt cho iOS, ứng dụng MT4 cho iPhone từ TrioMarkets tích hợp mượt mà tất cả các chức năng của MT4 để hỗ trợ giao dịch thuận lợi khi di chuyển.",

    metaTraderInfo: [
      {
        text: `TrioMarkets ™ dành riêng cho việc 6 cung cấp cho khách hàng của chúng tôi công nghệ giao dịch tiên tiến. Đây là lý do tại sao chúng tôi rất vui khi sử dụng MT4 (MetaTrader 4) từng đoạt giải thưởng. MT4 là nền tảng được sử dụng nhiều nhất trên thế giới, trở thành tiêu chuẩn thực tế trong thế giới giao dịch trực tuyến. Rất mạnh mẽ, nó cung cấp nhiều lựa chọn về đồ thị và công cụ phân tích. Nó có thể được cài đặt miễn phí trên mọi máy tính bất kể hệ điều hành.<br>MT4 có thể được cài đặt dễ dàng trên bất kỳ máy tính nào và cho phép bạn truy cập vào tất cả các tài sản giao dịch của chúng tôi. Nền tảng MT4 cũng cung cấp cho các nhà giao dịch của chúng tôi hơn 50 công cụ biểu đồ và chỉ báo tích hợp để lựa chọn. Các giao dịch có thể được thực hiện theo cách thủ công hoặc tự động.`,
        points: [
          { img: "real-time", name: "Công cụ thời <br> gian thực" },
          { img: "customizable", name: "Các tính năng có <br> thể tùy chỉnh" },
          {
            img: "one-click",
            name: "Tùy chọn giao dịch <br> một cú nhấp chuột",
          },
        ],
        pointsTwo: [
          "Giao dịch Forex, hàng hóa và các <br> chỉ số từ một nền tảng duy nhất. ",
          "Khả năng đặt giới hạn và dừng <br> mức từ một (1) pip trên tất cả các cặp chính Forex. ",
          "Nền tảng của chúng tôi cung cấp <br> giao dịch  FX ẩn danh.",
          "Giao diện MT4 hoàn toàn có thể tùy chỉnh và cung cấp <br> cho bạn khả năng giám sát các khoản đầu tư của mình. ",
          "Không có giao dịch xung đột <br> giữa khách hàng và nhà môi giới.",
          "Có thể đặt cắt lỗ và chốt lời <br> trong khi mở một giao dịch khác. ",
          "Tùy chọn để giao dịch các lot nhỏ, <br> còn được gọi là 0,01 lot. ",
          "Không có hạn chế giao dịch.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend là một bộ
        các ứng dụng thông tin về thị trường được tạo cho các nhà giao dịch TrioMarkets ™ để tối ưu hóa khả năng giao dịch thành công của họ. <br>Cung cấp cho các nhà giao dịch tiên tiến là một công cụ mở rộng cho nền tảng giao dịch MT4, MT4 TrioXtend mang đến cho cả những nhà giao dịch mới bắt đầu và có kinh nghiệm cơ hội duy nhất để giao dịch như những chuyên gia.`,
        points: [
          { img: "1", name: `Công cụ quản <br> lý sáng tạo` },
          { img: "2", name: `Thực hiện giao <br> dịch nâng cao` },
          { img: "3", name: `Giao dịch <br> Sentiment` },
          { img: "4", name: `Báo động <br> tiên tiến` },
          { img: "5", name: `Dữ liệu tin <br> tức và thị trường` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "Trợ lý giao dịch cá nhân hoàn hảo, có các cảnh báo dựa trên sự kiện về hoạt động giao dịch, tin tức thị trường, hành động giá và có khả năng thực hiện các hành động tự động, chẳng hạn như gửi email, SMS hoặc mở giao dịch.",
            },
            {
              img: "02",
              text: "Cung cấp cho các nhà giao dịch khả năng kết nối với tin tức, lịch kinh tế, tín hiệu trực tiếp và được hướng dẫn tất cả từ nền tảng MT4.",
            },
            {
              img: "03",
              text: "So sánh mối tương quan giữa hai tài sản, tạo cơ hội thực hiện giao dịch nhanh chóng dựa trên so sánh thời gian thực giữa các thị trường.",
            },
            {
              img: "04",
              text: "Cung cấp cho nhà giao dịch cái nhìn sâu sắc có giá trị về thông tin thị trường về mối tương quan giữa các tài sản ở các thị trường khác nhau, cho phép nhà giao dịch nhanh chóng đánh giá rủi ro tiếp xúc thị trường của họ.",
            },
            {
              img: "05",
              text: "Công cụ phân tích mạnh mẽ trong Excel dựa trên dữ liệu thị trường theo thời gian thực, cho phép các nhà giao dịch theo dõi, đánh giá và báo cáo dữ liệu trực tiếp từ MT4.",
            },
            {
              img: "06",
              text: "Cung cấp toàn quyền kiểm soát giá theo dõi thị trường và tất cả hoạt động của tài khoản giao dịch. Nhà giao dịch có thể quản lý tài khoản, lệnh và khớp lệnh của họ từ một cửa sổ thuận tiện.",
            },
          ],
          [
            {
              img: "07",
              text: "Tạo các mẫu lệnh thị trường và đang chờ xử lý để thực hiện cực nhanh. Mini Terminal là một phiên bản nhỏ hơn độc lập của Trade Terminal, tập trung vào một thị trường duy nhất.",
            },
            {
              img: "08",
              text: "Cung cấp dữ liệu tâm lý trực tiếp trên các thị trường, cho phép các nhà giao dịch được cập nhật theo thời gian thực về tâm lý thị trường và giao dịch hiệu quả hơn. Ứng dụng cũng hiển thị số liệu thống kê tâm lý lịch sử cùng với giá thị trường.",
            },
            {
              img: "09",
              text: "Hiển thị thời gian phiên giao dịch trực tiếp và đang chờ xử lý trên bản đồ thế giới cho biết các thị trường chính, thời gian thanh khoản cao nhất và các điểm đánh dấu cho các thông báo tin tức trong tương lai.",
            },
            {
              img: "10",
              text: "Cung cấp cho các nhà giao dịch cơ hội đặt các lệnh chờ ẩn, dừng lỗ và chốt lời. Những lệnh này sẽ chỉ xuất hiện trong tài khoản MT4 của họ khi thực hiện khớp lệnh trên thị trường.",
            },
            {
              img: "11",
              text: "Hiển thị nhiều loại biểu đồ đánh dấu khác nhau và tự hào có chức năng giao dịch bằng một cú nhấp chuột cho phép các nhà giao dịch có cơ hội thực hiện các giao dịch ngắn hạn một cách nhanh chóng và chính xác. Công cụ này hoàn hảo cho những người mở rộng quy mô.",
            },
            {
              img: "12",
              text: "Một ứng dụng tất cả trong một đầy đủ chức năng, cung cấp cho các nhà giao dịch thực hiện lệnh nhanh chóng và quản lý vị trí. Nó có các thành phần cắt lỗ và chốt lời tiên tiến, các chức năng đóng tất cả nâng cao, tính toán đơn hàng chuyên sâu (giá trị pip, kích thước ), đơn đặt hàng mẫu và đơn đặt hàng OCO.",
            },
            {
              img: "13",
              text: "Một gói Chỉ báo chứa đầy các chỉ báo biểu đồ được săn đón nhiều, có Renko, Lịch sử đặt hàng, Xoay vòng, Nhóm biểu đồ, Đếm ngược nến, Vẽ tay tự do và nhiều hơn nữa.",
            },
          ],
        ],
        title: "Sách hướng dẫn có sẵn",
        textTwo:
          "Tất cả các ứng dụng MT4 TrioXtend đều được hỗ trợ đầy đủ bởi các hướng dẫn sử dụng toàn diện và có sẵn cho các nhà giao dịch sử dụng MT4 TrioXtend. ",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets ™ giúp bạn dễ dàng có thể giao dịch bất kể bạn ở đâu. Miễn là bạn có kết nối internet, bạn có thể giao dịch và nhận tất cả các phân tích thị trường của bạn trên Ứng dụng di động hiện đại MetaTrader 4. ",
        points: [
          "Lệnh Thị trường và có điều kiện",
          "Đặt lệnh dừng và giới hạn",
          "Khả năng giao dịch từ Nhiều tài khoản",
          "Báo giá trực tiếp",
          "Biểu đồ tương tác",
          "Biểu đồ thời gian thực",
          "Nhiều khung thời gian",
          "Hơn 30 chỉ báo kỹ thuật",
          "Khả năng kiểm tra số dư, vốn chủ sở hữu ",
          "Nhà giao dịch di động của chúng tôi tự động đồng bộ hóa đến máy tính để bàn ",
          "Chỉnh sửa vị trí đang mở",
          "Lịch sử giao dịch",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "TrioMarkets™ liên tục mang đến những công nghệ giao dịch tiên tiến nhất cho các nhà giao dịch có giá trị của chúng tôi để giúp họ thực hiện các giao dịch hàng ngày. Từ Nền tảng MetaTrader 4 đã giành giải thưởng và công nghệ tiên tiến của chúng tôi đến công cụ bổ sung MT4 mới của chúng tôi, MT4 WebTrader. WebTrader là một phần bổ sung cho MT4 của bạn, cho phép bạn giao dịch từ bất kỳ máy tính hoặc thiết bị di động thông minh nào. Tất cả những gì bạn yêu cầu là một thực tập sinh. <br>Web Trader là một phần bổ sung cho MT4 Trader của bạn và cho phép bạn giao dịch từ bất kỳ máy tính hoặc thiết bị di động thông minh nào. Tất cả những gì bạn cần là kết nối internet.",
        title: "Tại sao lại sử dụng WebTrader?",
        textTwo: `Thị trường biến động ngày nay có nghĩa là các nhà giao dịch trực tuyến cần một nền tảng giao dịch tiên tiến được thiết kế để cung cấp cho thị trường tài chính theo thời gian thực. Từ thị trường Ngoại hối đến CFD và hàng hóa. TrioMarkets ™ WebTrader mang đến cho bạn lợi thế này với việc thực hiện giao dịch tốc độ cao<br>Công cụ bổ sung nâng cao này cho nhà giao dịch MT4 mang đến cho các nhà giao dịch trực tuyến của chúng tôi một cách độc đáo để kiếm tiền trên thị trường ngoại hối. Không cần cài đặt phần mềm bổ sung, rất đơn giản chỉ cần thêm nó vào tài khoản MT4 của bạn.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "Trong vô vàn cơ hội, bạn thiếu thời gian và kỹ năng để đầu tư? Hãy đầu tư thông minh bằng cách theo dõi hoạt động của các nhà giao dịch có lợi nhuận và khai thác thành công của họ. Sao chép các chiến lược giao dịch đã được phê duyệt của họ và học hỏi từ kiến thức chuyên môn của họ. TrioMarkets ™ tham gia trò chơi giao dịch xã hội và muốn bạn mở rộng phạm vi giao dịch của bạn. ",
        multiply: "Nhân lên giao <br> dịch của bạn",
        points: [
          { img: "easy-use", name: "Dễ sử dụng" },
          { img: "replication", name: "Tự động sao chép" },
          { img: "risk", name: "Quản lý rủi ro" },
          { img: "vps", name: "VPS miễn phí" },
        ],
        title: "Chỉ điều tốt nhất là đủ tốt",
        textTwo:
          "Bạn có muốn tự tin giao dịch trong một môi trường tự động và có lợi nhuận không? Giao dịch xã hội có thể dễ dàng truy cập và mang lại cho bạn cơ hội chọn những nhà giao dịch có lợi nhất. Thị trường cung cấp công cụ tìm kiếm và chức năng lọc giúp bạn xác định các Tháng kể từ khi thành lập, Tỷ lệ rủi ro, Lỗ tối đa và Khả năng sinh lời. Tìm nhà giao dịch phù hợp hoàn hảo với nhu cầu giao dịch và đầu tư của bạn và nắm bắt tiền thắng trong khi bạn thư giãn. ",
        titleTwo: "Chia sẻ là quan tâm",
        textThree:
          "Hãy nắm lấy cơ hội để trở thành một nhà giao dịch tín hiệu với kinh nghiệm và chuyên môn ngày càng tăng, đồng thời thúc đẩy lợi nhuận với mọi người mới sao chép giao dịch của bạn. Càng nhiều người theo dõi sao chép chiến lược của bạn, khoản chi trả của bạn càng cao. Hãy tận dụng bằng chứng xã hội của một cộng đồng với giá trị cao. ",
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (Financial Information Exchange) API (Giao diện lập trình ứng dụng) là một giao thức truyền thông điện tử để trao đổi thông tin tài chính.<br>Các hành vi giao dịch đang phát triển. Giao dịch tần suất cao và chênh lệch giá độ trễ đang trở nên phổ biến. Tốc độ là rất cần thiết cho các chiến lược mới nổi này. Thay đổi đòi hỏi các công nghệ tiên tiến mới.<br> FIX API cho phép bất kỳ nhà giao dịch nào kết nối trực tiếp với nhóm thanh khoản của chúng tôi với độ trễ và chi phí được giảm thiểu. Độ trễ giảm thiểu cho phép nhà giao dịch thực hiện các chiến lược giao dịch độc đáo của họ.`,

        textTwo: `<p>TrioMarkets™ cung cấp độc quyền API FIX cho khách hàng tổ chức và những người tham gia giao dịch ở mức cao.</p>`,
        points: [
          "Thanh khoản nhiều tầng chi phí thấp",
          "Khả năng kết nối với nhiều nền tảng khác nhau như Ctrader hoặc Ninja Trader",
          "Tốc độ thực thi vượt trội",
          "Khả năng tương thích nền tảng xây dựng tùy chỉnh",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `Máy chủ lưu trữ VPS (Máy chủ ảo riêng) cho phép các nhà giao dịch ngoại hối sử dụng môi trường ảo trên máy chủ của công ty lưu trữ để chạy các cố vấn chuyên gia MetaTrader.<br>Giao dịch với Cố vấn Chuyên gia và các thuật toán đặc biệt đã trở nên rất phổ biến. Tốc độ và sự bền bỉ là 2 yếu tố quan trọng để một hệ thống giao dịch hoạt động tốt.<br>Không thể mong đợi một hiệu suất thành công nếu các cố vấn chuyên môn của bạn đang gặp vấn đề về kết nối. Là một nhà kinh doanh, bạn cần đảm bảo rằng các cố vấn chuyên gia của mình luôn hoạt động và hiệu quả.`,
        textTwo: `<p class="text-center">Giống như sự bền bỉ, tốc độ rất quan trọng và khoảng cách đến các máy chủ giao dịch đóng vai trò rất quyết định đối với việc thực hiện. Bạn càng ở gần máy chủ của chúng tôi, yêu cầu thực hiện của bạn sẽ được gửi nhanh hơn và hệ thống giao dịch của bạn sẽ hoạt động theo cách tốt hơn . </p><strong class="text-center">Do đó, chúng tôi cung cấp cho bạn một VPS.</strong><p class="text-center mt-3">Thông qua VPS, bạn có thể thực hiện các giao dịch như đang ở London ngay cả khi bạn đang ở Úc. Ngay cả khi bạn tắt máy tính, Cố vấn Chuyên gia của bạn sẽ tiếp tục thực hiện theo các cài đặt bạn đã đặt trước đó.</p>`,
        title: "Ưu điểm của VPS",
        points: [
          "Giao dịch vào bất kỳ thời điểm nào trong ngày. Hệ thống sẽ thực hiện các giao dịch cho bạn và do đó bạn không cần phải ở bên máy tính của mình.",
          "Hệ thống máy chủ VPS được kiểm tra thường xuyên để đảm bảo rằng chúng hoạt động bình thường, do đó bạn hầu như sẽ không gặp bất kỳ vấn đề kỹ thuật nào.",
          "Một VPS có thể thực hiện các giao dịch của bạn nhanh hơn so với máy tính của bạn vì nó truyền lệnh nhanh hơn nhiều. Kết quả là bạn ít gặp phải sự chậm trễ và ít trượt hơn.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets ™ đã hợp tác với ForexVPS.net và HokoCloud. </p><p class="text-center text-white">Cả hai đều có sản phẩm chất lượng cao với đảm bảo 100% thời gian hoạt động. Nếu bạn muốn điều hành các cố vấn chuyên môn của mình liên tục mà không bị gián đoạn thì Forex VPS hoặc HokoCloud lưu trữ cho MetaTrader là những gì bạn thực sự cần vì VPS luôn trực tuyến.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `Chúng tôi cho phép xử lý nhiều tài khoản thông qua một tài khoản chính sử dụng Ứng dụng PAMM / MAM được Người quản lý tiền sử dụng.<br>Nó phân bổ các giao dịch chính tỷ lệ với các tài khoản phụ bằng cách sử dụng các phương pháp phân bổ khác nhau như vốn chủ sở hữu, PL hoặc số dư. <br>Phân bổ phổ biến nhất giữa các ngành nghề là vốn chủ sở hữu. Nó phân bổ các giao dịch chính cho các tài khoản phụ dựa trên số vốn chủ sở hữu của họ.`,
        textTwo: `<strong class="text-center text-uppercase">PHƯƠNG THỨC PHÂN PHỐI CỔ PHIẾU:</strong><p class="text-center mt-3">Nhà giao dịch mở 1 lô trên EURUSD và trạng thái vốn chủ sở hữu là 10k, 20k, 30k, 15k và 25k trong mỗi tài khoản phụ. PAMM / MAM phân bổ 1 ô theo tỷ lệ cho mỗi tài khoản phụ là 0,1, 0,2, 0,3, 0,15 và 0,25. Đây là lý do chính tại sao phương pháp vốn chủ sở hữu hầu hết được sử dụng, vì nó chắc chắn và dễ thực hiện. </p><p class="text-center">Có nhiều lợi thế khác nhau để giao dịch ngoại hối với ứng dụng PAMM / MAM. Một trong những lợi thế độc đáo nhất là đánh chặn trượt. Vì các tài khoản phụ chỉ phản ánh giá của tài khoản chính, nên không thể thấy sự khác biệt về giá. Đảm bảo rằng tất cả các giao dịch phụ sẽ được nhập từ giá giao dịch chính. </p><p class="text-center">Chúng tôi tin rằng đây là một tính năng mà các ứng dụng tương tự khác không thể cung cấp. Tất nhiên, đây không phải là lợi thế duy nhất. </p>`,
        title: "Ưu điểm của PAMM / MAM",
        point: [
          "Thanh khoản thị trường trực tiếp cho Tài khoản Chính",
          "Giám sát hoa hồng",
          "Toàn cảnh trên Master và Giao dịch phụ ",
          "Các tùy chọn báo cáo khác nhau",
          "Đánh chặn trượt giữa Tài khoản phụ",
          "Thực hiện EA dễ dàng",
          "Toàn quyền kiểm soát khách hàng tài khoản thông qua một",
          "Tất cả các loại giao dịch đều được phép",
        ],
        titleTwo: "Mô hình PAMM / MAM",
        mamModel: "modelvnm",
        mamModelPhone: "modelPhvnm",
      },
    ],
  },
  enUae: {
    keyFeatures: "Key Features",
    advantages: "Advantages",
    TitleNewSection: "TrioMarkets allows you to trade MT4 on nearly any device",
    NewSectionText1:
      "Discover online trading as it should be with MetaTrader on your desktop.",
    NewSectionTitle1: "MT4 for PC",
    NewSectionTitle2: "MT4 for Mac",
    NewSectionText2:
      "MT4 stands out as the premier trading platform for Mac, offering advanced features and a plethora of technical analysis tools.",
    PhoneTitle1: "MT4 for Android",
    PhoneText1:
      "Experience the full range of MetaTrader features with flexibility and portability by installing MT4 for Android.",
    PhoneTitle2: "MT4 for Iphone",
    PhoneText2:
      "Tailored and designed for iOS, the MT4 for iPhone app seamlessly integrates all the functionalities of MT4 for convenient on-the-go trading.",
    metaTraderInfo: [
      {
        text: `TrioMarkets™ is dedicated to providing our clients with cutting-edge trading technology. This is why we are pleased to use the award-winning MT4 (MetaTrader 4). MT4 is the most used platform in the world, becoming practically the standard in the world of online trading. Very powerful, it offers a large choice of graphs and analysis tools. It can be installed for free on any computer regardless of operating system.<br>The MT4 can be easily installed on any computer and gives you access to all of our trading assets. The MT4 platform also gives our traders over 50 charting tools and built-in indicators to choose from. Trades can be executed manually or automatically.`,
        points: [
          { img: "real-time", name: "Real Time <br> Tools" },
          { img: "customizable", name: "Customizable <br> Features" },
          { img: "one-click", name: "One Click <br> Trading Options" },
        ],
        pointsTwo: [
          "Trading Forex, commodities and indices from one single platform.",
          "The ability to place limit and stop levels from one (1) pip on all the Forex major pairs.",
          "Our platform offers anonymous FX trading.",
          "The MT4 interface is fully customizable and gives you the ability to monitor your investments. ",
          "There is no trading conflicts between the client and the broker.",
          "Able to set stop losses and take profit while opening another trade.",
          "Option to trade mini lots, also called 0.01 lots.",
          "There are no trading restrictions.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend is a suite of market-informed apps created for TrioMarkets™ traders to optimize their trading ability`,
        points: [
          { img: "1", name: `Innovative <br> Management Tools` },
          { img: "2", name: `Advanced Trade <br> Execution` },
          { img: "3", name: `Sentiment <br> Trading` },
          { img: "4", name: `Sophisticated <br> Alarms` },
          { img: "5", name: `News and <br> Market data` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "The perfect personal trading assistant, featuring event based alerts on trade activity, market news, price action, and has the ability to perform automated actions, such as sending emails, SMS, or opening trades.",
            },
            {
              img: "02",
              text: "Offering traders the ability to connect to news, economic calendar, live signals and to get educated all from the MT4 platform.",
            },
            {
              img: "03",
              text: "Compares the correlation between two assets, providing the opportunity for quick trade execution based on real-time comparisons between markets.",
            },
            {
              img: "04",
              text: "Provides traders with valuable insight into market information on the correlation between assets in different markets, allowing traders to quickly assess their market exposure risk.",
            },
            {
              img: "05",
              text: "Powerful analysis tool in Excel based on real-time market data, enabling traders to monitor, evaluate, and report data directly from the MT4.",
            },
            {
              img: "06",
              text: "Provides full control over market watch prices, and all trading account activity. Traders can manage their account, orders and executions from one convenient window.",
            },
          ],
          [
            {
              img: "07",
              text: "Create pending and market order templates for ultra-fast execution. The Mini Terminal is a stand-alone smaller version of the Trade Terminal, focusing on a single market.",
            },
            {
              img: "08",
              text: "Offers live sentiment data across markets, enabling traders to be updated real-time on market sentiment and trade more effectively. The app also shows historic sentiment stats alongside market prices.",
            },
            {
              img: "09",
              text: "hows live and pending trading session times across the world map indicating key markets and peak liquidity times and markers for future news announcements.",
            },
            {
              img: "10",
              text: "Provides traders the opportunity to place hidden pending orders, stop losses and take profits, which will only appear in their MT4 account when actual market execution occurs.",
            },
            {
              img: "11",
              text: "Displays a variety of different tick charts and boasts a one-click trading functionality allowing traders the opportunity to execute short-term trades swiftly and precisely. This tool is perfect for scalpers.",
            },
            {
              img: "12",
              text: "A fully functional, all-in-one app offering traders speedy order execution and position management. It features, among others advanced stop-loss and take profit components, advanced close-all functions, in-depth order calculations (pip value, size), order templates, and OCO orders.",
            },
            {
              img: "13",
              text: "An Indicator package filled with highly sought after chart indicators, featuring Renko, Order History, Pivot, Chart Group, Candle Countdown, Freehand Drawing and many more.",
            },
          ],
        ],
        title: "Available Manuals",
        textTwo:
          "All MT4 TrioXtend apps are fully supported by comprehensive user guides, and are available to traders using MT4 TrioXtend.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets™ makes it easy for you to trade no matter where you are. As long as you have an internet connection you are able to trade and get all of you market analysis on the MetaTrader 4 state of the art Mobile Application.",
        points: [
          "Market and conditional entry orders",
          "Set stop and limit orders",
          "The ability to trade from multiple accounts",
          "Live Quotes",
          "Interactive Charts",
          "Real time charting",
          "Multiple time frames",
          "Over 30 technical indicators",
          "The ability to check your balance, equity",
          "Our mobile trader auto syncs to desktop",
          "Edit Open Positions",
          "Trading History",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "TrioMarkets™ is constantly bringing the most cutting-edge trading technologies to our valued traders to help them with their daily trades. From our award winning and technologically advanced Meta Trader 4 Platform (MT4) to our new MT4 add-on tool, the MT4 Web Trader. The WebTrader is an addition to your MT4 that allows you to trade from any computer or mobile smart device. All you require is an intern.<br>The WebTrader is an addition for your MT4 Trader and allows you to trade from any computer or mobile smart device. All you need is an internet connection.",
        title: "Why WebTrader?",
        textTwo: `Today's volatile market means online traders need an advanced trading platform that is designed to give real time financial markets. From the Forex markets to CFDs and commodities. TrioMarkets™ WebTrader gives you this advantage with high speed trade executions.<br>This advanced additional tool for the MT4 trader gives our online traders a unique way to make money in the Forex markets. No need to install additional software, very simply just add it to your MT4 account.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "In the abundance of opportunities you lack time and skills to invest? Invest smartly by following the performance of profitable traders and harness their successes. Copy their approved trading strategies and learn from their expertise. TrioMarkets™ joins the social trading game and wants you to extend your trading reach.",
        multiply: "Multiply your trading <br> experience and your gains",
        points: [
          { img: "easy-use", name: "Easy to Use" },
          { img: "replication", name: "Auto Replication" },
          { img: "risk", name: "Risk Management" },
          { img: "vps", name: "Free VPS" },
        ],
        title: "Only the best is good enough",
        textTwo:
          "You want to trade with confidence in a profitable and automated environment? Social trading is easily accessible and gives you the opportunity to choose the most profitable traders. The Marketplace provides a search tool and a filter function which make you determine the Months since inception, Risk Ratio, Maximum Loss, and Profitability. Find the trader who fits perfectly your trading and investing needs and capture winnings while you relax.",
        titleTwo: "Sharing is caring",
        textThree:
          "Take the chance to become a signal trader with growing experience and expertise and drive gains with every newbie who copies your trades. The more followers copy your strategies, the higher your pay-out will be. Take advantage of the social proof of a community with high value.",
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (Financial Information Exchange) API (Application Programming Interface) is an electronic communications protocol for financial information exchange.<br>Trading behaviors are evolving. High frequency trading and latency arbitrage is vastly becoming popular. Speed is very essential for these new emerging strategies. Changes demand new advanced technologies.<br>FIX API allows any trader to connect directly to our liquidity pool with a minimized latency and cost. Minimized latency allows the trader to realize their unique trading strategies.`,

        textTwo: `<p>TrioMarkets™ exclusively offers a FIX API to its institutional clients and those engaging in high-volume trading.</p>`,
        points: [
          "Low cost multi-tier liquidity",
          "Ability to connect with various <br> platform like Ctrader or Ninja Trader",
          "Exceptional Execution Speed",
          "Custom build platform compatibility",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `VPS (Virtual Private Server) hosting allows Forex traders to use the virtual environment on the hosting company's servers to run the MetaTrader expert advisors.<br>Trading with Expert Advisor and special algorithms has become very common. Speed and persistence are 2 key elements for a trading system to operate properly.<br>It is not possible to expect a successful performance if your expert advisors is having connectivity issues. As a trader, you need to make sure that your expert advisors is up and running at all times.`,
        textTwo: `<p class="text-center">Like the persistence, speed is very important and distance to the trading servers plays a very decisive role on the execution. The closer you are to our servers, the faster your execution request will be delivered and your trading system will perform in a better way.</p><strong class="text-center">Therefore, we offer you a VPS. </strong><p class="text-center mt-2">Through the VPS, you can throw trades as you are in London even though you would be sitting in Australia. Even if you switch off your computer, your Expert Advisor would continue to perform according to the settings you previously set.</p>`,
        title: "VPS Advantages",
        points: [
          "Trade at any time of day. System will execute the trades for you and therefore there is no need to stay by your PC.",
          "VPS server systems are checked regularly to make sure that they are functioning properly so you will almost never encounter any technical issues.",
          "A VPS can execute your trades more quickly than your computer can because it is much faster at transmitting the orders. The result is that you experience less of a delay and less slippage.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets™ has partnered with ForexVPS.net and HokoCloud.</p><p class="text-center text-white">They both have a high quality product with 100% uptime guarantee. If you want to run your expert advisors continuously without any interruptions then Forex VPS or HokoCloud hosting for MetaTrader is what you really need because the VPS is always online.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `We allow the handling of multiple accounts through one master account using PAMM/MAM Application which is used by Money Managers.<br>It allocates master trades proportionally to the sub accounts by using various allocation methods such as equity, PL or balance.<br>The most popular allocation method among trades is equity. It allocates master trades to the sub accounts based on the amount of their equity.`,
        textTwo: `<strong class="text-center text-uppercase">EQUITY ALLOCATION METHOD:</strong><p class="text-center mt-3">Trader opens 1 lot on EURUSD and equity status was 10k, 20k, 30k, 15k, and 25k in each sub account. PAMM/MAM allocates the 1lot proportionally to each sub account as 0.1, 0.2, 0.3, 0.15 and 0.25. This is the main reason why equity method is mostly used, because it is solid and easy to implement.</p><p class="text-center">There are various advantages to trading forex with a PAMM/MAM application. One of the most unique advantages is slippage interception. Since the sub accounts only mirror the master account pricing, it is impossible to see price discrepancies. It is guaranteed that all sub trades will be entered from the master trade price.</p><p class="text-center">We believe this is a feature that other similar applications simply cannot offer. Of course, this is not the only advantage.</p>`,
        title: "PAMM/MAM Advantages",
        point: [
          "Direct market liquidity for Master Accounts",
          "Commission Monitoring",
          "Full view over Master and Sub Trades",
          "Various Reporting Options",
          "Slippage Interception between Sub Accounts",
          "Easy EA Implementation",
          "Full control over client accounts through one",
          "All trade types are allowed",
        ],
        titleTwo: "PAMM/MAM Model",
        mamModel: "modelen",
        mamModelPhone: "modelPhen",
      },
    ],
  },
  arb: {
    keyFeatures: "الميزات الرئيسية",
    advantages: "المميزات",

    TitleNewSection: "تتيح TrioMarkets لك التداول عبر MT4 على معظم الأجهزة",
    NewSectionText1:
      "اكتشف التداول عبر الإنترنت كما ينبغي أن يكون مع MetaTrader على سطح مكتبك.",
    NewSectionTitle1: "MT4 للكمبيوتر الشخصي",
    NewSectionTitle2: "MT4 لنظام Mac",
    NewSectionText2:
      "يبرز MT4 كأفضل منصة تداول لنظام Mac، حيث تقدم ميزات متقدمة ومجموعة واسعة من أدوات التحليل الفني.",
    PhoneTitle1: "MT4 لنظام Android",
    PhoneText1:
      "اختبر مجموعة كاملة من ميزات MetaTrader بمرونة وقابلية للنقل عند تثبيت MT4 لنظام Android.",
    PhoneTitle2: "MT4 لنظام iPhone",
    PhoneText2:
      "مصممة ومصممة خصيصًا لنظام iOS، تدمج تطبيق MT4 لنظام iPhone من TrioMarkets بسلاسة جميع وظائف MT4 لتسهيل التداول أثناء التنقل.",

    metaTraderInfo: [
      {
        text: `تكرس شركة TrioMarkets ™ جهودها لتزويد عملائنا بأحدث تقنيات التداول. هذا هو السبب في أننا سعداء باستخدام MT4 الحائز على جائزة (MetaTrader 4). MT4 سهل التثبيت على أي جهاز كمبيوتر ويوفر الوصول إلى جميع أصول التداول الخاصة بنا. يمكن لمتداولينا أيضًا الاختيار من بين أكثر من 50 أداة رسم بياني ومؤشرات مدمجة على منصة MT4. يمكن تنفيذ الصفقات إما يدويًا أو تلقائيًا<br>يمكن تثبيت MT4 بسهولة على أي جهاز كمبيوتر ويتيح لك الوصول إلى جميع أصول التداول الخاصة بنا. توفر منصة MT4 أيضًا للمتداولين لدينا أكثر من 50 أداة رسم بياني ومؤشرات مدمجة للاختيار من بينها. يمكن تنفيذ الصفقات يدويًا أو تلقائيًا.`,
        points: [
          { img: "real-time", name: "أدوات <br> الوقت الفعلي" },
          { img: "customizable", name: "ميزات <br> قابلة للتخصيص" },
          { img: "one-click", name: "خيارات التداول <br> بنقرة واحدة" },
        ],
        pointsTwo: [
          "تداول العملات الأجنبية والسلع والمؤشرات من منصة واحدة.",
          "القدرة على وضع حدود وإيقاف <br> عند نقطة واحدة (1) على جميع أزواج العملات الأجنبية الرئيسية.",
          "تقدم منصتنا خدمة تداول العملات الأجنبية عبر الإنترنت بشكل مجهول.",
          "واجهة MT4 قابلة للتخصيص بالكامل وتمنحك القدرة على مراقبة استثماراتك. ",
          "لا توجد صراعات تجارية بين العميل والوسيط.",
          "قادرة على تحديد وقف الخسائر وجني الأرباح أثناء فتح صفقة أخرى.",
          "خيار لتداول العقود المصغرة ، ويسمى أيضًا 0.01 عقد.",
          "لا توجد قيود على التداول.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend عبارة عن مجموعة من التطبيقات المستندة إلى السوق والتي تم إنشاؤها لمتداولي TrioMarkets ™ لتحسين قدرتهم على التداول بنجاح.<br>يوفر MT4 TrioXtend للمتداولين أدوات متطورة كامتداد لمنصة التداول MT4 ، ويمنح المتداولين المبتدئين وذوي الخبرة فرصة فريدة للتداول كمحترفين.`,
        points: [
          { img: "1", name: `أدوات الإدارة <br> المبتكرة` },
          { img: "2", name: `تنفيذ <br>التجارة المتقدم` },
          { img: "3", name: `التداول <br>العاطفي` },
          { img: "4", name: `إنذارات <br>متطورة` },
          { img: "5", name: `الأخبار <br>وبيانات السوق` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "مساعد التداول الشخصي المثالي ، والذي يتميز بتنبيهات تستند إلى الأحداث بشأن النشاط التجاري ، وأخبار السوق ، وحركة الأسعار ، ولديه القدرة على تنفيذ الإجراءات الآلية ، مثل إرسال رسائل البريد الإلكتروني ، أو الرسائل القصيرة ، أو فتح الصفقات.",
            },
            {
              img: "02",
              text: "تقدم للمتداولين القدرة على الاتصال بالأخبار والتقويم الاقتصادي والإشارات الحية وتثقيف الجميع من منصة MT4.",
            },
            {
              img: "03",
              text: "يقارن العلاقة بين أصلين ، مما يوفر الفرصة لتنفيذ التجارة بسرعة بناءً على مقارنات في الوقت الفعلي بين الأسواق.",
            },
            {
              img: "04",
              text: "يزود المتداولين بمعلومات قيمة حول معلومات السوق حول العلاقة بين الأصول في الأسواق المختلفة ، مما يسمح للمتداولين بتقييم مخاطر تعرضهم للسوق بسرعة.",
            },
            {
              img: "05",
              text: "أداة تحليل قوية في Excel تستند إلى بيانات السوق في الوقت الفعلي ، مما يمكّن المتداولين من مراقبة البيانات وتقييمها والإبلاغ عنها مباشرةً من MT4.",
            },
            {
              img: "06",
              text: "يوفر تحكمًا كاملاً في أسعار مراقبة السوق وجميع أنشطة حسابات التداول. يمكن للمتداولين إدارة حساباتهم وأوامرهم وعمليات التنفيذ من نافذة واحدة مريحة.",
            },
          ],
          [
            {
              img: "07",
              text: "قم بإنشاء قوالب أوامر السوق المعلقة لتنفيذ سريع للغاية. Mini Terminal هي نسخة أصغر قائمة بذاتها من Trade Terminal ، تركز على سوق واحدة.",
            },
            {
              img: "08",
              text: "يقدم بيانات المعنويات الحية عبر الأسواق ، مما يمكّن المتداولين من تحديث معنويات السوق في الوقت الفعلي والتداول بشكل أكثر فعالية. يعرض التطبيق أيضًا إحصاءات المشاعر التاريخية جنبًا إلى جنب مع أسعار السوق.",
            },
            {
              img: "09",
              text: "يعرض أوقات جلسات التداول الحية والمعلقة عبر خريطة العالم التي تشير إلى الأسواق الرئيسية وأوقات ذروة السيولة وعلامات لإعلانات الأخبار المستقبلية.",
            },
            {
              img: "10",
              text: "يوفر للمتداولين الفرصة لوضع أوامر معلقة مخفية وإيقاف الخسائر وجني الأرباح ، والتي ستظهر فقط في حساب MT4 الخاص بهم عند حدوث التنفيذ الفعلي للسوق.",
            },
            {
              img: "11",
              text: "يعرض مجموعة متنوعة من الرسوم البيانية المختلفة ويفتخر بوظيفة التداول بنقرة واحدة مما يتيح للمتداولين فرصة تنفيذ صفقات قصيرة الأجل بسرعة ودقة. هذه الأداة مثالية للمضاربين.",
            },
            {
              img: "12",
              text: "تطبيق شامل كامل الوظائف يوفر للمتداولين تنفيذ سريع للأوامر وإدارة المراكز. إنه يتميز ، من بين أمور أخرى ، بمكونات متطورة لإيقاف الخسارة وجني الأرباح ، ووظائف إغلاق متقدمة ، وحسابات أوامر متعمقة (قيمة النقطة ، والحجم) ، وقوالب الطلبات ، وأوامر OCO.",
            },
            {
              img: "13",
              text: "حزمة مؤشرات مليئة بمؤشرات الرسوم البيانية المرغوبة للغاية ، والتي تضم رينكو ، وسجل الطلبات ، والمحور ، ومجموعة المخططات ، والعد التنازلي للشموع ، والرسم اليدوي وغيرها الكثير.",
            },
          ],
        ],
        title: "الكتيبات المتاحة",
        textTwo:
          "جميع تطبيقات MT4 TrioXtend مدعومة بالكامل بواسطة أدلة مستخدم شاملة ، وهي متاحة للمتداولين الذين يستخدمون MT4 TrioXtend.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "تسهل عليك TrioMarkets ™ التداول أينما كنت. طالما لديك اتصال بالإنترنت ، يمكنك التداول والحصول على جميع تحليلات السوق على تطبيق MetaTrader 4 المتطور للهاتف المحمول.",
        points: [
          "أوامر السوق والدخول المشروط",
          "تعيين أوامر الإيقاف والحد",
          "القدرة على التداول من حسابات متعددة",
          "اقتباسات حية",
          "الرسوم البيانية التفاعلية",
          "الرسوم البيانية في الوقت الحقيقي",
          "عدة أطر زمنية",
          "أكثر من 30 مؤشرًا فنيًا",
          "القدرة على التحقق من رصيدك والسيولة",
          "يقوم التداول المحمول لدينا بالمزامنة التلقائية لسطح المكتب",
          "تحرير الوظائف المفتوحة",
          "تاريخ التداول",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "تقدم TrioMarkets ™ باستمرار أحدث تقنيات التداول لمتداولينا الكرام لمساعدتهم في تداولاتهم اليومية. من منصة Meta Trader 4 الحائزة على جوائز والمتقدمة تقنيًا (MT4) إلى أداة MT4 الإضافية الجديدة ، MT4 WebTrader.<br>WebTrader هو إضافة لـ MT4 Trader الخاص بك ويسمح لك بالتداول من أي جهاز كمبيوتر أو جهاز ذكي محمول. كل ما تحتاجه هو اتصال بالإنترنت.",
        title: "لماذا WebTrader؟",
        textTwo: `يعني السوق المتقلب اليوم أن المتداولين عبر الإنترنت يحتاجون إلى منصة تداول متقدمة مصممة لمنح الأسواق المالية في الوقت الفعلي. من أسواق الفوركس إلى عقود الفروقات والسلع. تمنحك منصة TrioMarkets ™ WebTrader هذه الميزة من خلال عمليات تنفيذ التجارة عالية السرعة<br>توفر هذه الأداة الإضافية المتقدمة لمتداول MT4 Trader للمتداولين عبر الإنترنت طريقة فريدة لكسب المال في أسواق الفوركس. لا حاجة لتثبيت برامج إضافية ، ما عليك سوى إضافته إلى حساب MT4 الخاص بك.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "في ظل وفرة الفرص ، تفتقر إلى الوقت والمهارات اللازمة للاستثمار؟ استثمر بذكاء من خلال متابعة أداء المتداولين المربحين وتسخير نجاحاتهم. انسخ استراتيجيات التداول المعتمدة الخاصة بهم وتعلم من خبراتهم. انضمت TrioMarkets ™ إلى لعبة التداول الاجتماعي وتريد منك توسيع نطاق تداولك.",
        multiply: "ضاعف التداول الخاص بك <br> الخبرة والمكاسب",
        points: [
          { img: "easy-use", name: "سهل الاستخدام" },
          { img: "replication", name: "نسخ تلقائي" },
          { img: "risk", name: "إدارة المخاطر" },
          { img: "vps", name: "VPS مجاني" },
        ],
        title: "فقط الأفضل هو ما يكفي",
        textTwo:
          "هل تريد التداول بثقة في بيئة مؤتمتة ومربحة؟ يمكن الوصول إلى التداول الاجتماعي بسهولة ويمنحك الفرصة لاختيار المتداولين الأكثر ربحية. يوفر السوق أداة بحث ووظيفة تصفية تجعلك تحدد الأشهر منذ البداية ، ونسبة المخاطر ، والحد الأقصى للخسارة ، والربحية. ابحث عن المتداول الذي يناسب تمامًا احتياجاتك التجارية والاستثمارية واحصل على المكاسب أثناء الاسترخاء.",
        titleTwo: "شارك التجربة",
        textThree:
          "اغتنم الفرصة لتصبح تاجر إشارات يتمتع بخبرة وخبرة متزايدة وحقق مكاسب مع كل مبتدئ ينسخ تداولاتك. كلما زاد عدد المتابعين الذين نسخوا استراتيجياتك ، زادت أرباحك. استفد من الدليل الاجتماعي لمجتمع ذي قيمة عالية.",
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (تبادل المعلومات المالية) API (واجهة برمجة التطبيقات) هو بروتوكول اتصالات إلكتروني لتبادل المعلومات المالية.<br>تتطور سلوكيات التداول. أصبح التداول عالي التردد ومراجحة زمن الوصول شائعًا على نطاق واسع. السرعة ضرورية جدًا لهذه الاستراتيجيات الناشئة الجديدة. تتطلب التغييرات تقنيات متقدمة جديدة.<br>تسمح FIX API لأي متداول بالاتصال مباشرة بمجمع السيولة لدينا بأقل زمن انتقال وتكلفة. يسمح زمن الوصول المصغر للمتداول بإدراك استراتيجيات التداول الفريدة الخاصة به.`,

        textTwo: `<p>تقدم TrioMarkets™ واجهة برمجة تطبيقات FIX بشكل حصري لعملائها المؤسسيين ولأولئك الذين يشاركون في التداول بحجم كبير.</p>`,
        points: [
          "سيولة منخفضة التكلفة ومتعددة المستويات",
          "القدرة على الاتصال بمنصات مختلفة مثل Ctrader أو Ninja Trader",
          "سرعة تنفيذ استثنائية",
          "توافق منصة البناء المخصصة",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `تسمح استضافة VPS (Virtual Private Server) لمتداولي الفوركس باستخدام البيئة الافتراضية على خوادم الشركة المضيفة لتشغيل المستشارين الخبراء في MetaTrader.<br>أصبح التداول مع المستشارين الخبراء والخوارزميات الخاصة أمرًا شائعًا جدًا. السرعة والمثابرة عنصران أساسيان لنظام التداول لكي يعمل بشكل صحيح.<br>لا يمكن توقع أداء ناجح إذا كان المستشارون الخبراء لديك يواجهون مشكلات في الاتصال. بصفتك متداولًا ، فأنت بحاجة إلى التأكد من أن المستشارين الخبراء لديك يعملون في جميع الأوقات.`,
        textTwo: `<p class="text-center">مثل المثابرة ، تعد السرعة مهمة جدًا وتلعب المسافة إلى خوادم التداول دورًا حاسمًا للغاية في التنفيذ. كلما اقتربت من خوادمنا ، زادت سرعة تسليم طلب التنفيذ وسيعمل نظام التداول الخاص بك بطريقة أفضل.</p><strong class="text-center">لذلك ، نقدم لك VPS. </strong><p class="text-center mt-2">من خلال الخادم الافتراضي الخاص (VPS) ، يمكنك إجراء الصفقات كما لو كنت في لندن حتى لو كنت جالسًا في أستراليا. حتى إذا قمت بإيقاف تشغيل جهاز الكمبيوتر الخاص بك ، فسيستمر Expert Advisor في العمل وفقًا للإعدادات التي قمت بتعيينها مسبقًا.</p>`,
        title: "مزايا VPS",
        points: [
          "تداول في أي وقت من اليوم. سيقوم النظام بتنفيذ الصفقات نيابة عنك وبالتالي ليست هناك حاجة للبقاء بجوار جهاز الكمبيوتر الخاص بك.",
          "يتم فحص أنظمة خادم VPS بانتظام للتأكد من أنها تعمل بشكل صحيح حتى لا تواجه أي مشاكل فنية تقريبًا.",
          "يمكن لخادم VPS تنفيذ تداولاتك بشكل أسرع من جهاز الكمبيوتر الخاص بك لأنه أسرع بكثير في إرسال الطلبات. والنتيجة هي أنك تعاني من تأخير أقل وانزلاق أقل.",
        ],
        textThree: `<p class="text-center text-white fs-6">دخلت TrioMarkets ™ في شراكة مع ForexVPS.net و HokoCloud.</p><p class="text-center text-white">كلاهما لديه منتج عالي الجودة مع ضمان وقت تشغيل بنسبة 100٪. إذا كنت ترغب في تشغيل المستشارين الخبراء بشكل مستمر دون أي انقطاع ، فإن استضافة Forex VPS أو HokoCloud لـ MetaTrader هي ما تحتاجه حقًا لأن VPS متصل بالإنترنت دائمًا.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `نسمح بالتعامل مع حسابات متعددة من خلال حساب رئيسي واحد باستخدام تطبيق PAMM / MAM الذي يستخدمه مديرو الأموال.<br>يخصص الصفقات الرئيسية بشكل متناسب مع الحسابات الفرعية باستخدام طرق التخصيص المختلفة مثل حقوق الملكية أو PL أو الرصيد.<br>طريقة التخصيص الأكثر شيوعًا بين الصفقات هي الأسهم. يخصص الصفقات الرئيسية للحسابات الفرعية بناءً على مقدار حقوق الملكية الخاصة بهم.`,
        textTwo: `<strong class="text-center text-uppercase">طريقة تخصيص حقوق الملكية:</strong><p class="text-center mt-3">قام المتداول بفتح 1 لوت على زوج العملات EURUSD وكانت حالة حقوق الملكية 10 آلاف و 20 ألف و 30 ألف و 15 ألف و 25 ألفًا في كل حساب فرعي. يخصص PAMM / MAM 1lot بالتناسب مع كل حساب فرعي مثل 0.1 و 0.2 و 0.3 و 0.15 و 0.25. هذا هو السبب الرئيسي وراء استخدام طريقة الأسهم في الغالب ، فهي متينة وسهلة التنفيذ.</p><p class="text-center">هناك العديد من المزايا لتداول العملات الأجنبية باستخدام تطبيق PAMM / MAM. واحدة من أكثر المزايا الفريدة هي اعتراض الانزلاق. نظرًا لأن الحسابات الفرعية تعكس فقط تسعير الحساب الرئيسي ، فمن المستحيل رؤية تباينات الأسعار. إنه مضمون أن جميع التداولات الفرعية سيتم إدخالها من سعر التداول الرئيسي.</p><p class="text-center">نعتقد أن هذه ميزة لا يمكن أن تقدمها التطبيقات المماثلة الأخرى. بالطبع ، هذه ليست الميزة الوحيدة.</p>`,
        title: "مزايا PAMM / MAM",
        point: [
          "سيولة السوق المباشرة للحسابات الرئيسية",
          "مراقبة العمولات",
          "عرض كامل للماستر الحسابات الفرعية",
          "خيارات مختلفة لإعداد التقارير",
          "تقاطع الانزلاق السعري بين الحسابات الفرعية",
          "سهولة تنفيذ EA",
          "السيطرة الكاملة على حسابات العملاء من خلال حساب واحد",
          "مسموح بجميع أنواع التداول",
        ],
        titleTwo: "نموذج PAMM / MAM",
        mamModel: "modelarb",
        mamModelPhone: "modelPhen",
      },
    ],
  },
  my: {
    keyFeatures: "Major Key Features",
    advantages: "Kelebihan",

    TitleNewSection:
      "TrioMarkets membolehkan anda berdagang MT4 hampir di mana-mana peranti",
    NewSectionText1:
      "Terokai perdagangan dalam talian sebagaimana mestinya dengan MetaTrader di desktop anda.",
    NewSectionTitle1: "MT4 untuk PC",
    NewSectionTitle2: "MT4 untuk Mac",
    NewSectionText2:
      "MT4 menonjol sebagai platform perdagangan utama untuk Mac, menawarkan ciri-ciri yang canggih dan pelbagai alat analisis teknikal.",
    PhoneTitle1: "MT4 untuk Android",
    PhoneText1:
      "Alami keseluruhan ciri-ciri MetaTrader dengan fleksibiliti dan mudah alih dengan memuat turun MT4 untuk Android.",
    PhoneTitle2: "MT4 untuk iPhone",
    PhoneText2:
      "Didedahkan dan direka khas untuk iOS, aplikasi MT4 untuk iPhone daripada TrioMarkets menggabungkan semua fungsi MT4 dengan kemudahan untuk membantu anda berdagang di mana-mana.",

    metaTraderInfo: [
      {
        text: `TrioMarkets™ didedikasikan untuk menyediakan teknologi perdagangan canggih kepada pelanggan kami. Inilah sebabnya mengapa kami dengan senang hati menggunakan MT4 ( MetaTrader 4 ) yang memenangi anugerah. MT4 mudah dipasang di komputer mana pun dan menyediakan akses ke semua aset perdagangan kami. Pedagang kami juga boleh memilih lebih dari 50 alat grafik dan petunjuk terbina dalam di platform MT4. Perdagangan boleh dilaksanakan secara manual atau automatik. MT4 boleh dengan mudah dipasang di mana-mana komputer dan memberi anda akses ke semua aset perdagangan kami. Platform MT4 juga memberi pedagang lebih daripada 50 alat grafik dan petunjuk terbina dalam untuk dipilih. Perdagangan dapat dijalankan secara manual atau automatik.`,
        points: [
          { img: "real-time", name: "Alat masa <br> nyata" },
          { img: "customizable", name: "Ciri yang <br> disesuaikan" },
          { img: "one-click", name: "Pilihan perdagangan <br> satu klik" },
        ],
        pointsTwo: [
          "Trading Forex, komoditi dan indeks dari satu platform tunggal.",
          "Keupayaan untuk meletakkan had dan hentikan tahap dari satu (1) pada semua pasangan utama Forex.",
          "Platform kami menawarkan tanpa nama Perdagangan FX.",
          "Antara muka MT4 sepenuhnya disesuaikan dan memberi anda kemampuan untuk memantau pelaburan anda.",
          "Tidak ada perdagangan konflik antara pelanggan dan broker.",
          "Mampu menetapkan kerugian berhenti dan mengambil keuntungan semasa membuka perdagangan lain.",
          "Pilihan untuk memperdagangkan lot mini, juga dipanggil 0.01 lot.",
          "Tidak ada perdagangan sekatan.",
        ],
      },
    ],
    trioXtendInfo: [
      {
        text: `MT4 Trio Xtend adalah suite dari aplikasi yang dimaklumkan pasaran yang dibuat untuk pedagang TrioMarkets ™ untuk mengoptimumkan kemampuan mereka untuk berdagang dengan jayanya.<br>Menawarkan peniaga yang canggih alat sebagai peluasan ke platform perdagangan MT4, MT4 TrioXtend memberi peluang unik kepada peniaga baru dan berpengalaman untuk berdagang sebagai profesional.`,
        points: [
          { img: "1", name: `Alat Pengurusan <br> Inovatif` },
          { img: "2", name: `Pelaksanaan <br> Perdagangan Lanjutan` },
          { img: "3", name: `Perdagangan <br> Sentimen` },
          { img: "4", name: `Penggera <br> yang Canggih` },
          { img: "5", name: `Data Berita <br> dan Pasaran` },
        ],
        pointsTwo: [
          [
            {
              img: "01",
              text: "Pembantu perdagangan peribadi yang sempurna, yang menampilkan amaran berdasarkan peristiwa mengenai aktiviti perdagangan, berita pasaran, tindakan harga, dan memiliki kemampuan untuk melakukan tindakan automatik, seperti mengirim e-mel, SMS, atau membuka perdagangan.",
            },
            {
              img: "02",
              text: "Menawarkan kemampuan kepada peniaga untuk berhubung dengan berita, kalendar ekonomi, isyarat langsung dan mendapat pendidikan dari platform MT4.",
            },
            {
              img: "03",
              text: "Membandingkan korelasi antara dua aset, memberi peluang untuk pelaksanaan perdagangan cepat berdasarkan perbandingan masa nyata antara pasaran.",
            },
            {
              img: "04",
              text: "Memberi pedagang wawasan berharga mengenai maklumat pasaran mengenai korelasi antara aset di pasaran yang berbeza, yang membolehkan peniaga menilai risiko pendedahan pasaran mereka dengan cepat.",
            },
            {
              img: "05",
              text: "Alat analisis yang kuat di Excel berdasarkan data pasaran masa nyata, yang membolehkan pedagang memantau, menilai, dan melaporkan data secara langsung dari MT4.",
            },
            {
              img: "06",
              text: "Menyediakan kawalan penuh terhadap harga jam tangan pasaran, dan semua aktiviti akaun perdagangan. Peniaga boleh menguruskan akaun, pesanan dan pelaksanaan mereka dari satu tetingkap yang mudah.",
            },
          ],
          [
            {
              img: "07",
              text: "Buat belum selesai dan pasaran templat pesanan untuk pelaksanaan ultra cepat. Terminal Mini adalah versi Terminal Perdagangan yang lebih kecil yang berdiri sendiri, dengan fokus pada satu pasaran.",
            },
            {
              img: "08",
              text: "Menawarkan data sentimen langsung di seluruh pasaran, membolehkan peniaga dikemas kini secara real-time mengenai sentimen dan perdagangan pasaran dengan lebih berkesan. Aplikasi ini juga menunjukkan statistik sentimen bersejarah di samping harga pasaran.",
            },
            {
              img: "09",
              text: "Menunjukkan secara langsung dan belum selesai waktu sesi perdagangan di seluruh peta dunia yang menunjukkan pasaran utama dan masa dan penanda kecairan puncak untuk pengumuman berita masa depan.",
            },
            {
              img: "10",
              text: "Menyediakan peniaga kepada peluang untuk membuat pesanan tertunda tersembunyi, menghentikan kerugian dan mengambil keuntungan, yang hanya akan muncul di akaun MT4 mereka apabila pelaksanaan pasaran sebenar berlaku.",
            },
            {
              img: "11",
              text: "Menampilkan pelbagai carta kutu yang berbeza dan mempunyai fungsi perdagangan satu klik yang membolehkan peniaga berpeluang untuk melakukan perdagangan jangka pendek dengan cepat dan tepat. Alat ini sangat sesuai untuk scalpers.",
            },
            {
              img: "12",
              text: "Seluruh berfungsi, semua-dalam-satu aplikasi yang menawarkan pelaksanaan pesanan cepat dan pengurusan kedudukan kepada peniaga. Ia mempunyai, antara lain komponen stop-loss canggih dan mengambil keuntungan, fungsi close-all canggih, pengiraan pesanan mendalam ( nilai pip, ukuran ), templat pesanan, dan pesanan OCO.",
            },
            {
              img: "13",
              text: "Pakej Petunjuk diisi dengan petunjuk carta yang sangat dicari, yang menampilkan Renko, Sejarah Pesanan, Pivot, Chart Group, Candle Countdown, Freehand Drawing dan banyak lagi.",
            },
          ],
        ],
        title: "Manual yang ada",
        textTwo:
          "Semua aplikasi MT4 TrioXtend adalah disokong sepenuhnya oleh panduan pengguna yang komprehensif, dan tersedia untuk peniaga yang menggunakan MT4 TrioXtend.",
      },
    ],
    mobileTradingInfo: [
      {
        text: "TrioMarkets ™ menjadikannya mudah untuk anda berniaga tidak kira di mana anda berada. Selagi anda mempunyai sambungan internet, anda dapat berdagang dan mendapatkan semua analisis pasaran anda di Aplikasi Mudah Alih MetaTrader 4 canggih.",
        points: [
          "Pasaran dan kemasukan bersyarat pesanan",
          "Tetapkan pesanan berhenti dan had",
          "Keupayaan untuk berdagang dari pelbagai akaun",
          "Petikan Langsung",
          "Carta Interaktif",
          "Pencetakan masa nyata",
          "Bingkai masa berganda",
          "Lebih 30 petunjuk teknikal",
          "Keupayaan untuk memeriksa anda baki, ekuiti",
          "Segerakkan automatik peniaga mudah alih kami ke desktop",
          "Edit Posisi Terbuka",
          "Sejarah Perdagangan",
        ],
      },
    ],
    webTraderInfo: [
      {
        text: "TrioMarkets ™ sentiasa membawa teknologi perdagangan paling canggih kepada peniaga kami yang dihargai untuk membantu mereka dalam perdagangan harian mereka. Dari Meta Trader 4 Platform yang memenangi anugerah dan berteknologi maju ( MT4 ) hingga alat tambahan MT4 baru kami, MT4 WebTrader. <br>WebTrader adalah tambahan untuk Pedagang MT4 anda dan membolehkan anda berdagang dari mana-mana komputer atau peranti pintar mudah alih. Yang anda perlukan hanyalah sambungan internet.",
        title: "Mengapa WebTrader?",
        textTwo: `Pasaran yang tidak menentu hari ini bermaksud peniaga dalam talian memerlukan platform perdagangan canggih yang direka untuk memberi pasaran kewangan masa nyata. Dari pasaran Forex hingga CFD dan komoditi. TrioMarkets ™ WebTrader memberi anda kelebihan ini dengan pelaksanaan perdagangan berkelajuan tinggi<br>Alat tambahan canggih ini untuk MT4 Trader memberikan pedagang dalam talian kami cara unik untuk menjana wang di pasaran Forex. Tidak perlu memasang perisian tambahan, hanya tambahkan ke akaun MT4 anda.`,
      },
    ],
    socialTradingInfo: [
      {
        text: "Dalam banyaknya peluang yang anda kekurangan masa dan kemahiran untuk melabur? Melabur dengan bijak dengan mengikuti prestasi peniaga yang menguntungkan dan memanfaatkan kejayaan mereka. Salin strategi perdagangan mereka yang diluluskan dan pelajari dari kepakaran mereka. TrioMarkets ™ menyertai permainan perdagangan sosial dan mahu anda memperluas jangkauan perdagangan anda.",
        multiply:
          "Gandakan perdagangan anda <br> pengalaman dan keuntungan anda",
        points: [
          { img: "easy-use", name: "Mudah digunakan" },
          { img: "replication", name: "Replikasi Auto" },
          { img: "risk", name: "Pengurusan Risiko" },
          { img: "vps", name: "VPS percuma" },
        ],
        title: "Hanya yang terbaik cukup baik",
        textTwo:
          "Anda mahu berniaga dengan keyakinan terhadap persekitaran yang menguntungkan dan automatik? Perdagangan sosial mudah diakses dan memberi anda peluang untuk memilih peniaga yang paling menguntungkan. Marketplace menyediakan alat carian dan fungsi penapis yang membuat anda menentukan Bulan sejak awal, Nisbah Risiko, Kehilangan Maksimum, dan Keuntungan. Cari peniaga yang sesuai dengan keperluan perdagangan dan pelaburan anda dan tangkap kemenangan semasa anda berehat.",
        titleTwo: "Berkongsi adalah penyayang",
        textThree:
          "Ambil peluang untuk menjadi peniaga isyarat dengan pengalaman dan kepakaran yang semakin meningkat dan mendorong keuntungan dengan setiap pemula yang menyalin perdagangan anda. Semakin banyak pengikut menyalin strategi anda, semakin tinggi pembayaran anda. Manfaatkan bukti sosial komuniti dengan nilai tinggi.",
      },
    ],
    fixApiInfo: [
      {
        text: `FIX (Maklumat Kewangan Exchange) API (Antaramuka Pengaturcaraan Aplikasi) adalah protokol komunikasi elektronik untuk pertukaran maklumat kewangan.<br>Tingkah laku perdagangan adalah berkembang. Arbitraj perdagangan dan latensi frekuensi tinggi menjadi popular. Kelajuan sangat penting untuk strategi baru yang baru muncul ini. Perubahan menuntut teknologi canggih baru.<br>FIX API membenarkan mana-mana peniaga sambung terus ke kumpulan kecairan kami dengan kependaman dan kos yang minimum. Latensi minimum membolehkan pedagang merealisasikan strategi perdagangan unik mereka.`,

        textTwo: `<p>TrioMarkets™ menyediakan API FIX secara eksklusif kepada pelanggan institusi dan mereka yang terlibat dalam perdagangan volum tinggi.</p>`,
        points: [
          "Kecairan pelbagai peringkat kos rendah",
          "Kebolehan untuk berhubung dengan <br> pelbagai platform seperti Ctrader atau Ninja Trader",
          "Kelajuan Pelaksanaan Luar Biasa",
          "Platform binaan khusus keserasian",
        ],
      },
    ],
    vpsInfo: [
      {
        text: `VPS (Pelayan Peribadi Maya) hosting membolehkan pedagang Forex menggunakan persekitaran maya di pelayan syarikat hosting untuk menjalankan penasihat pakar MetaTrader.<br>Berdagang dengan Penasihat Pakar dan algoritma khas telah menjadi sangat biasa. Kelajuan dan ketekunan adalah 2 elemen penting untuk sistem perdagangan beroperasi dengan baik.<br>Tidak mungkin untuk mengharapkan prestasi yang berjaya sekiranya penasihat pakar anda menghadapi masalah kesambungan. Sebagai peniaga, anda perlu memastikan bahawa penasihat pakar anda sentiasa beroperasi dan beroperasi setiap saat.`,
        textTwo: `<p class="text-center">Seperti kegigihan, kepantasan 
        sangat penting dan jarak ke pelayan perdagangan memainkan peranan yang sangat menentukan dalam pelaksanaannya. Semakin dekat anda dengan pelayan kami, semakin cepat permintaan pelaksanaan anda akan dihantar dan sistem perdagangan anda akan berfungsi dengan lebih baik.</p><strong class="text-center">Oleh itu, kami menawarkan VPS kepada anda. </strong><p class="text-center mt-2">Melalui VPS, anda boleh membuang perdagangan seperti anda di London walaupun anda akan duduk di Australia. Walaupun anda mematikan komputer, Penasihat Pakar anda akan terus melakukan mengikut tetapan yang telah anda tetapkan sebelumnya.</p>`,
        title: "Kelebihan VPS",
        points: [
          "Perdagangan pada bila-bila masa sepanjang hari. Sistem akan melaksanakan perdagangan untuk anda dan oleh itu tidak perlu tinggal di PC anda.",
          "Sistem pelayan VPS adalah diperiksa secara berkala untuk memastikan bahawa ia berfungsi dengan baik sehingga anda hampir tidak akan pernah menghadapi masalah teknikal.",
          "VPS dapat menjalankan perdagangan anda lebih cepat daripada komputer anda kerana lebih pantas menghantar pesanan. Hasilnya ialah anda mengalami kelewatan dan kekurangan.",
        ],
        textThree: `<p class="text-center text-white fs-6">TrioMarkets ™ telah bekerjasama dengan ForexVPS.net dan HokoCloud.</p><p class="text-center text-white">Mereka berdua mempunyai tinggi 
        produk berkualiti dengan jaminan masa operasi 100. Sekiranya anda ingin menjalankan penasihat pakar anda secara berterusan tanpa gangguan, maka hosting Forex VPS atau HokoCloud untuk MetaTrader adalah perkara yang sangat anda perlukan kerana VPS sentiasa dalam talian.</p>`,
      },
    ],
    mamInfo: [
      {
        text: `Kami membenarkan pengendalian beberapa akaun melalui satu akaun induk menggunakan Aplikasi PAMM / MAM yang digunakan oleh Pengurus Wang.<br>Ini memperuntukkan perdagangan induk secara berkadar dengan sub akaun dengan menggunakan pelbagai kaedah peruntukan seperti ekuiti, PL atau baki.<br>Peruntukan paling popular kaedah antara perdagangan adalah ekuiti. Ini memperuntukkan perdagangan induk ke sub akaun berdasarkan jumlah ekuiti mereka.`,
        textTwo: `<strong class="text-center text-uppercase">KAEDAH PERUNTUKAN EKUITI:</strong><p class="text-center mt-3">Trader membuka 1 lot pada EURUSD dan status ekuiti adalah 10k, 20k, 30k, 15k, dan 25k di setiap sub akaun. PAMM / MAM memperuntukkan 1 lot secara berkadar untuk setiap sub akaun sebagai 0.1, 0.2, 0.3, 0.15 dan 0.25. Inilah sebab utama mengapa kaedah ekuiti digunakan, kerana ia kukuh dan mudah dilaksanakan.</p><p class="text-center">Terdapat pelbagai kelebihan 
        untuk memperdagangkan forex dengan aplikasi PAMM / MAM. Salah satu kelebihan yang paling unik adalah pemintas selipar. Oleh kerana sub akaun hanya mencerminkan harga akaun induk, mustahil untuk melihat perbezaan harga. Dijamin semua perdagangan akan dimasukkan dari harga perdagangan induk.</p><p class="text-center">Kami percaya ini adalah ciri yang tidak dapat ditawarkan oleh aplikasi serupa yang lain. Sudah tentu, ini bukan satu-satunya kelebihan.</p>`,
        title: "Kelebihan PAMM/MAM",
        point: [
          "Kecairan pasaran langsung untuk Akaun Induk",
          "Pemantauan Suruhanjaya",
          "Pandangan penuh terhadap Guru dan Sub Perdagangan",
          "Pelbagai Pilihan Pelaporan",
          "Pintas Slippage antara Sub Akaun",
          "Pelaksanaan EA Mudah",
          "Kawalan penuh terhadap pelanggan akaun melalui satu",
          "Semua jenis perdagangan dibenarkan",
        ],
        titleTwo: "Model PAMM/MAM",
        mamModel: "modelen",
        mamModelPhone: "modelPhen",
      },
    ],
  },
};
