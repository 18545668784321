<template>
  <div id="news">
    <div class="container-fluid container-top news accounts">
      <div class="container">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ pageDetails.title }}
          </h2>
          <hr class="gold" />
        </div>
        <div class="row d-flex justify-content-center">
          <p class="col-xl-6 col-lg-8 col-md-10 col-12 text-center text-white">
            {{ pageDetails.text }}
          </p>
        </div>
      </div>
    </div>
    <div class="container my-5">
      <div class="row mb-4">
        <div class="col-12 news-menu">
          <router-link :to="`/${$route.params.lang}/news/articles`"
            :class="`text-trio news-link ${checkArb ? 'ms-3' : 'me-3'}`">{{ $t("articles.title") }}
          </router-link>
          <router-link :to="`/${$route.params.lang}/news/marketAnalysis`" class="text-trio news-link">{{
            $t("marketAnalysis.title") }}
          </router-link>
        </div>
      </div>
      <div v-if="existingArticles" class="row mt-4">
        <div class="col-12">
          <div class="row mt-4">
            <div :class="[idx <= 0 ? 'col-lg-12' : 'col-lg-4', 'col-sm-6 mb-4']" v-for="(item, idx) in news" :key="idx">
              <div :class="`news-card ${checkArb ? 'rtl' : ''}`">
                <a :href="item.slug" @click.prevent="goToArticle(item)"
                  :class="[idx <= 0 ? 'news-card preview-card d-flex' : '']">
                  <img :src="item.image.file" :alt="item.image.name" :class="['img-fluid', idx <= 0 ? 'preview' : '']" />
                  <div :class="[
                    idx <= 0 ? 'news-content-box p-lg-5 ' : '',
                    'd-flex flex-column justify-content-between p-4 ',
                  ]">
                    <div>
                      <p :class="[idx <= 0 ? '' : '']">
                        {{ item.createdAt }}
                      </p>
                      <h2 :class="` ${[idx > 0 ? 'fs-4 news-content-text' : '']}`">
                        {{ item.title }}
                      </h2>
                      <div v-if="idx <= 0" class="d-none d-lg-block"
                        v-html="`${item.content.substr(0, contentLength)}...`"></div>
                    </div>
                    <a :href="item.slug" @click.prevent="goToArticle(item)" class="d-flex read-more">{{ readMoreLang }}
                      <div class="position-relative ms-2">
                        <img :src="`/img/news/${checkArb
                          ? 'read-more-arrow-left'
                          : 'read-more-arrow'
                          }.svg`" class="read-more-arrow arrow-icon position-relative" alt="arrow" />
                        <svg width="100" height="100" class="position-absolute">
                          <circle cx="16" cy="16" r="15.2" stroke="#007bff" stroke-width="1" fill="transparent"
                            class="arrow-icon--circle" />
                        </svg>
                      </div>
                    </a>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="noArticles">
        <p class="text-center fs-4 text">No Articles</p>
      </div>
      <div v-if="loading" class="row mt-5 mb-4">
        <div class="col-12 text-center">
          <img src="/img/logo-top.svg" alt="TrioMarkets logo" class="rotate-center" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getters, mutations } from "../store";
export default {
  name: "News",
  data: () => ({
    news: [],
    loading: false,
    windowWidth: window.innerWidth,
  }),
  mounted() {
    this.getBlogs();

    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  computed: {
    pageDetails() {
      return this.$tm(this.$route.meta.page);
    },
    selectedLang() {
      return getters.selectedLang();
    },
    contentLength() {
      return 100;
    },
    // contentLength() {
    //   if (this.windowWidth > 1300) {
    //     return 800;
    //   } else if (this.windowWidth > 1150) {
    //     return 400;
    //   } else {
    //     return 200;
    //   }
    // },
    checkArb() {
      return getters.checkArb();
    },
    readMoreLang() {
      if (this.checkArb) {
        return "عرض المزيد";
      } else {
        return "Read More";
      }
    },
    existingArticles() {
      return this.news.length > 0;
    },
    noArticles() {
      return !this.existingArticles && !this.loading;
    },
    selectedArticleLang() {
      return getters.selectedArticleLang();
    },
  },
  watch: {
    $route() {
      this.getBlogs();
    },
  },
  methods: {
    getBlogs() {
      this.loading = true;
      axios({
        url: `${this.$apiURL()}/hub/blog`,
        params: {
          section: this.$route.meta.section,
          lang: this.selectedLang == "arb" ? "arb" : "en",
        },
      })
        .then(({ data }) => {
          this.loading = false;
          this.news = data.blogsList;
          setTimeout(() => {
            mutations.setBoxHeight("news-content-text", "3");
          }, 50);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToArticle(article) {
      mutations.setArticle(this.$route.meta.section, article);
      this.$router.push({
        name: "Article",
        params: { page: this.$route.meta.page, title: article.slug },
      });
    },
  },
};
</script>
