<template>
  <div v-for="(item, idx) in $tm('promotionsInfo')" :key="idx" id="promotions">
    <div class="container-fluid container-top accounts promotions pb-5">
      <div class="container accounts-info mb-5">
        <div class="row d-flex justify-content-center mt-5 mb-2">
          <h2 class="col-12 text-center title text-gold mb-4 pb-2">
            {{ $t("promotions") }}
          </h2>
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-8 col-md-10 col-12 text-center text-white" v-html="item.text"></div>
          <div class="col-lg-8 col-md-10 col-12 text-center text-white" v-html="item.textTwo"></div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row promotionRow bonus mt-5 py-4" v-if="selectedLang !== 'my'">
        <div class="col-md-8 d-flex flex-column justify-content-center px-5">
          <p class="text-white fw-bold fs-1 promotions-title">
            {{ item.regAndProf }}
          </p>
          <p class="text-white">
            {{ item.bonusText }}
          </p>
        </div>
        <div class="col-md-4 d-flex flex-column justify-content-center align-items-end pr-md-5">
          <div class="d-flex flex-column text-center">
            <a target="_blank" :href="registerURL" class="btn btn-blue mb-2">{{
              $t("getYourBonus")
            }}</a>
            <a v-if="selectedLang !== 'my'" target="_blank" href="/legal-documents/benor/FTD_Bonus_10.pdf"
              class="text-white">{{ $t("termsConditions") }}</a>
            <a v-else target="_blank" :href="`/legal-documents/benor/promotions/${item.promotionPDF[0]}`"
              class="text-white">{{ $t("termsConditions") }}</a>
          </div>
        </div>
      </div>
      <div class="row promotionRow refere mt-5 py-5">
        <div class="col-md-4 d-flex flex-column justify-content-center align-items-start pl-md-5">
          <div class="d-flex flex-column text-center">
            <a target="_blank" :href="registerURL" class="btn btn-blue mb-2">{{
              $t("refereFriendNow")
            }}</a>
            <a v-if="selectedLang !== 'my'" target="_blank"
              href="/legal-documents/benor/legal_documents_Benor_Refer_a_friend.pdf" class="text-white">{{
                $t("termsConditions") }}</a>
            <a v-else target="_blank" :href="`/legal-documents/benor/promotions/${item.promotionPDF[1]}`"
              class="text-white">{{ $t("termsConditions") }}</a>
          </div>
        </div>
        <div class="col-md-8 d-flex flex-column justify-content-center px-5">
          <p class="text-white fw-bold fs-1 text-end promotions-title">
            {{ item.title }}
          </p>
          <p class="text-white text-end fs-6 fw-bold">
            {{ item.textThree }}
          </p>
          <p class="text-white text-end">
            {{ item.textFour }}
          </p>
        </div>
      </div>
      <div class="row promotionRow cashback mt-5 py-4" v-if="selectedLang !== 'my'">
        <div class="col-md-8 d-flex flex-column justify-content-center px-5">
          <p class="text-white fw-bold fs-1 promotions-title">
            {{ item.titleTwo }}
          </p>
          <p class="text-white fs-6 fw-bold">
            {{ item.textFive }}
          </p>
          <p class="text-white">
            {{ item.textSix }}
          </p>
        </div>
        <div class="col-md-4 d-flex flex-column justify-content-center align-items-end pr-md-5">
          <div class="d-flex flex-column text-center">
            <a target="_blank" :href="registerURL" class="btn btn-blue mb-2">{{
              $t("getCashback")
            }}</a>
            <a target="_blank" href="/legal-documents/benor/legal_documents_Benor_Cashback_2022.pdf" class="text-white">{{
              $t("termsConditions") }}</a>
          </div>
        </div>
      </div>
    </div>
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import { getters, mutations } from "../store";
export default {
  name: "Promotions",
  firstBannerHeight: null,
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    selectedLang() {
      return getters.selectedLang();
    },
  },
  mounted() {
    mutations.setBoxHeight("promotionRow", "1");
    window.onresize = () => {
      mutations.setBoxHeight("promotionRow", "1");
    };
  },
};
</script>
<style lang="scss" scoped>
.container-top.accounts.platforms.half {
  height: auto;
  padding-top: 5% !important;
  padding-bottom: 0;
  background-color: #f2f3f4;

  &::after,
  &::before {
    height: 80%;
  }
}

.black-box {

  &::before,
  &::after {
    height: 90%;
  }

  padding-bottom: 18%;
}

#stepsPromotion {
  position: relative;
  z-index: 0 !important;
  margin-top: -18%;
}

@media screen and (max-width: 1200px) {
  .black-box {
    padding-bottom: 25%;
  }

  #stepsPromotion {
    margin-top: -25%;
  }
}

@media screen and (max-width: 992px) {
  .black-box {
    padding-bottom: 35%;
  }

  #stepsPromotion {
    margin-top: -35%;
  }
}

@media screen and (max-width: 768px) {
  .black-box {
    padding-bottom: 3rem;
    padding-top: 3rem;

    &::before,
    &::after {
      height: 100%;
    }
  }

  #stepsPromotion {
    margin-top: 0%;
  }
}
</style>
