export default (title = "", image = "", url = "", description = "") => {
  return {
    title: `TrioMarkets | ${title}`,
    meta: [
      {
        property: "og:title",
        content: `TrioMarkets | ${title}`,
      },
      {
        property: "og:image",
        content: image,
      },
      {
        property: "og:url",
        content: url,
      },
      {
        name: "description",
        content: description,
      },
      { property: "og:site_name", content: `TrioMarkets | ${title}` },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" },
      { "http-equiv": "Content-Type", content: "text/html; charset=utf-8" },
    ],
  };
};
