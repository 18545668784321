<template>
  <div class="container-fluid menu-mobile">
    <div
      :class="`navbar-mobile fixed-top w-100 d-flex justify-content-between align-items-center py-3 ${
        scrolled < 50 && !$route.meta.bg ? '' : 'scrolled'
      } animate__animated animate__slideInDown`"
    >
      <div @click="showMenu" class="hamburger">
        <img
          :src="`/img/${
            scrolled < 50 && !$route.meta.bg ? 'burger' : 'burger-dark'
          }.svg`"
          alt="menu"
        />
      </div>
      <router-link
        :to="`/${$route.params.lang}/`"
        class="navbar-brand m-0 h-100"
      >
        <!-- <img
          :src="`/img/${
            scrolled < 50 && !$route.meta.bg ? 'santa_logo' : 'santa_logo_dark'
          }.svg`"
          alt="TrioMarkets Logo"
        /> -->
        <img
          :src="`/img/${
            scrolled < 50 && !$route.meta.bg ? 'logo' : 'logo-dark'
          }.svg`"
          alt="TrioMarkets Logo"
        />
      </router-link>
      <div class="mobile-login">
        <a
          :href="loginURL"
          target="_blank"
          :class="`${
            scrolled < 50 && !$route.meta.bg ? 'text-white' : 'text-trio'
          } fw-normal`"
          v-html="$t('login')"
        ></a>
      </div>
    </div>
    <div
      v-if="menu"
      :class="`menu w-100 d-flex flex-column align-items-center ${menuClass}`"
    >
      <div
        class="menu-top d-flex justify-content-between align-items-center mb-4 mt-5"
      >
        <router-link
          :to="`/${$route.params.lang}/`"
          class="navbar-brand m-0 h-100"
        >
          <img :src="`/img/logo.svg`" alt="TrioMarkets Logo" />
        </router-link>

        <div class="d-flex">
          <a
            :href="loginURL"
            target="_blank"
            class="text-white fw-normal"
            v-html="$t('login')"
          ></a>
          <span class="text-white mx-2">|</span>
          <div @click="hideMenu" class="close-menu">
            <img src="/img/navbar-mobile/menu-close.svg" alt="close-menu" />
          </div>
        </div>
      </div>
      <div
        v-for="(menuItem, idx) in filterMenu.filter(
          (menu) => menu.submenu.length > 0
        )"
        :key="idx"
        class="menu-items d-flex flex-column align-items-center w-100"
      >
        <a
          class="menu-head text-white d-flex justify-content-between align-items-center py-sm-3 py-2"
          @click="
            currentSubMenuID == idx
              ? (currentSubMenuID = null)
              : (currentSubMenuID = idx)
          "
        >
          <p class="mb-0 text-white fs-4 fw-normal">
            {{ menuItem.title }}
          </p>
          <img
            :src="`/img/navbar-mobile/${
              currentSubMenuID == idx ? 'menu-arrow' : 'menu-arrow-down'
            }.svg`"
            alt="arrow"
          />
        </a>
        <ul
          v-if="currentSubMenuID == idx"
          class="d-flex flex-column align-items-end text-white p-0"
        >
          <li
            @click="hideMenu()"
            v-for="(subMenuItem, index) in menuItem.submenu"
            :key="index"
            :class="`animate__animated animate__fadeInLeft text-white p-2 px-3 w-100 ${
              checkArb ? 'text-end' : ''
            }`"
            :style="`animation-delay:${subMenuItem.animationDelay};`"
          >
            <router-link
              class="text-white fs-6 fw-normal"
              :to="`/${$route.params.lang}/${subMenuItem.link}`"
              >{{ subMenuItem.title }}</router-link
            >
          </li>
        </ul>
      </div>
      <div class="mobMenu-lang position-relative" id="mobMenu-lang">
        <Language :menu="menu" />
      </div>
      <div class="menu-bottom mt-3">
        <a :href="registerURL" target="_blank" class="btn w-100 btn-success">{{
          $t("realAccount")
        }}</a>

        <div class="menu-social d-flex justify-content-between mt-4">
          <a
            v-for="(social, idx) in socials"
            :key="idx"
            target="_blank"
            :href="social.link"
            ><img :src="`/img/navbar-mobile/${social.img}.svg`" alt="" class=""
          /></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Language from "./Language";
import { getters, mutations } from "../store";
export default {
  name: "NavbarMobile",
  data: () => ({
    scrolled: 0,
    menu: false,
    currentSubMenuID: null,
    menuClass: "",
    socials: [
      {
        name: "facebook",
        img: "fb",
        link: "https://m.facebook.com/triomarkets",
      },
      {
        name: "twitter",
        img: "twitter",
        link: "https://mobile.twitter.com/TrioMarkets",
      },
      {
        name: "linkedin",
        img: "linkedin",
        link: "https://www.linkedin.com/company/triomarkets",
      },
      {
        name: "youtube",
        img: "youtube",
        link: "https://m.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw",
      },
      {
        name: "instagram",
        img: "insta",
        link: "https://www.instagram.com/triomarkets/",
      },
    ],
  }),
  components: {
    Language,
  },
  computed: {
    license() {
      return getters.license();
    },
    loginURL() {
      return getters.loginURL();
    },
    registerURL() {
      return getters.registerURL();
    },
    filterMenu() {
      return this.$tm("menuItems")
        .map((element) => {
          return element;
        })
        .map((el) => {
          let filterArray = el.submenu.filter((sub) => {
            if (this.license == "global") {
              return sub.license !== "eu";
            } else if (this.license == "eu") {
              return sub.license !== "global";
            } else {
              return sub.license;
            }
          });

          return {
            title: el.title,
            submenu: filterArray,
          };
        });
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    menu() {
      if (this.menu) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "scroll";
      }
    },
  },
  methods: {
    handleScroll() {
      this.scrolled = window.pageYOffset;
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
    showMenu() {
      this.menu = true;
      this.menuClass = "s-in";
      mutations.updateDisclaimer(true);
    },
    hideMenu() {
      this.menu = false;
      this.menuClass = "s-out";
      mutations.updateDisclaimer(false);
    },
  },
};
</script>
<style lang="scss" scoped></style>
