export default {
  en: {
    realAccount: `Real Account`,
    demoAccount: `Demo Account`,
    signIn: `Sign In`,
    register: `Register`,
    login: `Login`,
    openAccount: `Open Account`,
    openLiveAccount: `Open Live Account`,
    learnMore: `Learn More`,
    subscribe: `Subscribe`,
    tradeNow: `Trade Now`,
    readMore: `Read More`,
    readLess: `Read Less`,
    openInstitutionalAccount: `Open Institutional Account`,
    downloadNow: `Download Now`,
    enjoyTrioxtend: `Enjoy Trioxtend`,
    getAllTrioxtendManuals: `Get All TrioXend Manuals`,
    accessWebTrader: `Access Web Trader`,
    joinTheCommunity: `Join The Community`,
    showMore: `Show More`,
    showLess: `Show Less`,
    getStarted: `Get Started`,
    submitYourMessage: `Submit your Message`,
    companyAccount: "Company Account",
    clientsAccount: "Clients Account",
    sendMessage: "Send Message",
    becomeIB: "Become an IB",
    becomeCPA: "Become a CPA Referral",
    getYourBonus: "Get your Bonus",
    refereFriendNow: "Refer a Friend Now",
    getCashback: "Get your CashBack",
    accept: "Accept",
    downloadPDF: "Download PDF",
    viewBankDetails: "View Bank Details",
    hideBankDetails: "Hide Bank Details",
    legalDocsOpen: "Open",
  },
  fr: {
    realAccount: `Compte Réel`,
    demoAccount: `Compte Démo`,
    signIn: `Identifiez-vous`,
    register: `S'inscrire`,
    login: `S'identifier`,
    openAccount: `Ouvrir un Compte`,
    openLiveAccount: `Ouvrir un Compte Réel`,
    learnMore: `En Savoir Plus`,
    subscribe: `Inscription`,
    tradeNow: `Trader Maintenant`,
    readMore: `Plus`,
    readLess: `Lire Moins`,
    openInstitutionalAccount: `Ouvrez un Compte Institutionnel`,
    downloadNow: `Télécharger Maintenant`,
    enjoyTrioxtend: `Profitez de Trioxtend`,
    getAllTrioxtendManuals: `Obtenir tous les manuels TrioXend`,
    accessWebTrader: `Accéder au Web Trader`,
    joinTheCommunity: `Rejoignez la Communauté`,
    showMore: `Montre Plus`,
    showLess: `Montrer Moins`,
    getStarted: `Commencer`,
    submitYourMessage: `Soumettez votre Message`,
    companyAccount: "Compte d'Entreprise",
    clientsAccount: "Compte Client",
    sendMessage: "Envoyer le Message",
    becomeIB: `Devenez un IB`,
    becomeCPA: "Devenir une Référence CPA",
    getYourBonus: "Obtenez votre Bonus",
    refereFriendNow: "Parrainez un Ami Maintenant",
    getCashback: "Obtenez votre CashBack",
    accept: "Acceptez",
    downloadPDF: "Télécharger le PDF",
    viewBankDetails: "Voir Coordonnées Bancaires",
    hideBankDetails: "Masquer Coordonnées Bancaires",
    legalDocsOpen: "Ouvrir",
  },
  it: {
    realAccount: `Conto reale`,
    demoAccount: `Conto Demo`,
    signIn: `Registrati`,
    register: `Iscriviti ora`,
    login: `Login`,
    openAccount: `Apri Conto`,
    openLiveAccount: `Apri un Conto Live`,
    learnMore: `Impara di Piu'`,
    subscribe: `Iscriviti`,
    tradeNow: `Negozia Ora`,
    readMore: `Leggi di Piu'`,
    readLess: `Leggi Meno`,
    openInstitutionalAccount: `Apri un Conto Istituzionale`,
    downloadNow: `Scarica Ora`,
    enjoyTrioxtend: `Divertiti con Trioxtend`,
    getAllTrioxtendManuals: `Ottieni tutti i manuali di TrioXend`,
    accessWebTrader: `Accedi a Web Trader`,
    joinTheCommunity: `Unisciti alla Comunità`,
    showMore: `Mostra di Più`,
    showLess: `Mostra Meno`,
    getStarted: `Iniziare`,
    submitYourMessage: `Invia il tuo Messaggio`,
    companyAccount: "Conto Aziendale",
    clientsAccount: "Conto Clienti",
    sendMessage: "Invia Messaggio",
    becomeIB: "Diventa un IB",
    becomeCPA: "Diventa un Referente CPA",
    getYourBonus: "Ottenere il bonus",
    refereFriendNow: "Presenta un Amico Ora",
    getCashback: "Ottieni il tuo CashBack",
    accept: "Accetta",
    downloadPDF: "Scarica il PDF",
    viewBankDetails: "Visualizza Dettagli Bancari",
    hideBankDetails: "Nascondi i Dettagli Bancari",
    legalDocsOpen: "Aprire",
  },
  de: {
    realAccount: `Live-Konto`,
    demoAccount: `Demo-Konto`,
    signIn: `Einloggen`,
    register: `Registrieren`,
    login: `Anmelden`,
    openAccount: `Konto eröffnen`,
    openLiveAccount: `Live-Konto eröffnen`,
    learnMore: `Mehr erfahren`,
    subscribe: `Abonnieren`,
    tradeNow: `Jetzt traden`,
    readMore: `Mehr lesen`,
    readLess: `Weniger Lesen`,
    openInstitutionalAccount: `Eröffnen Sie ein Institutionelles Konto`,
    downloadNow: `Jetzt herunterladen`,
    enjoyTrioxtend: `Erleben Sie TrioExtend`,
    getAllTrioxtendManuals: `Get All TrioXend Manuals`,
    accessWebTrader: `Greifen Sie auf WebTrader zu`,
    joinTheCommunity: `Der Gemeinschaft beitreten`,
    showMore: `Mehr Zeigen`,
    showLess: `Weniger Zeigen`,
    getStarted: `Loslegen`,
    submitYourMessage: `Ihre Nachricht einreichen`,
    companyAccount: "Unternehmenskonto",
    clientsAccount: "Kundenkonto",
    sendMessage: "Nachricht senden",
    becomeIB: "Werden Sie IB",
    becomeCPA: "Werden Sie ein CPA Referral",
    getYourBonus: "Holen Sie sich Ihren Bonus",
    refereFriendNow: "Werben Sie jetzt einen Freund",
    getCashback: "Holen Sie sich Ihr CashBack",
    accept: "Akzeptieren",
    downloadPDF: "PDF downloaden",
    viewBankDetails: "Bankdaten einsehen",
    hideBankDetails: "Bankdaten verbergen",
    legalDocsOpen: "Öffnen",
  },
  cn: {
    realAccount: `真实账户`,
    demoAccount: `模拟账户`,
    signIn: `登录`,
    register: `注册`,
    login: `登录`,
    openAccount: `开立账户`,
    openLiveAccount: `开立真实账户`,
    learnMore: `了解更多`,
    subscribe: `订阅`,
    tradeNow: `立即交易`,
    readMore: `阅读更多`,
    readLess: `阅读较少`,
    openInstitutionalAccount: `开立机构账户`,
    downloadNow: `现在下载`,
    enjoyTrioxtend: `享用Trioxtend`,
    getAllTrioxtendManuals: `获取所有TrioXend手册`,
    accessWebTrader: `访问网页交易`,
    joinTheCommunity: `加入社区`,
    showMore: `显示更多`,
    showLess: `显示较少`,
    getStarted: `开始`,
    submitYourMessage: `提交您的信息`,
    companyAccount: "公司账户",
    clientsAccount: "客户账户",
    sendMessage: "发信息",
    becomeIB: "成为一名IB",
    becomeCPA: "成为注册会计师推荐人",
    getYourBonus: "获取您的奖金",
    refereFriendNow: "现在推荐朋友",
    getCashback: "获得现金返还",
    accept: "接受",
    downloadPDF: "下载PDF",
    viewBankDetails: "查看银行详情",
    hideBankDetails: "隐藏银行详情",
    legalDocsOpen: "打开",
  },
  es: {
    realAccount: `Cuenta real`,
    demoAccount: `Cuenta de demostración`,
    signIn: `Iniciar sesión`,
    register: `Registrarse`,
    login: `Acceso`,
    openAccount: `Abrir Cuenta`,
    openLiveAccount: `Abrir Cuenta Real`,
    learnMore: `Aprende Más`,
    subscribe: `Suscribir`,
    tradeNow: `Negocia Ahora`,
    readMore: `Leer Mas`,
    readLess: `Leer Menos`,
    openInstitutionalAccount: `Abrir Cuenta Institucional`,
    downloadNow: `Lee mas`,
    enjoyTrioxtend: `Disfruta de Trioxtend`,
    getAllTrioxtendManuals: `Obtenga todos los manuales de TrioXend`,
    accessWebTrader: `Acceder a Web Trader`,
    joinTheCommunity: `Unete a la Communidad`,
    showMore: `Mostrar Más`,
    showLess: `Muestra Menos`,
    getStarted: `Empezar`,
    submitYourMessage: `Envía tu Mensaje`,
    companyAccount: "Cuenta de la Compañia",
    clientsAccount: "Cuenta de Clientes",
    sendMessage: "Enviar Mensaje",
    becomeIB: "Conviértase en un IB",
    becomeCPA: "Conviértase en una Referencia de CPA",
    getYourBonus: "Obtenga su bonificación",
    refereFriendNow: "Recomienda a un Amigo Ahora",
    getCashback: "Consigue tu CashBack",
    accept: "Aceptar",
    downloadPDF: "Descargar PDF",
    viewBankDetails: "Ver Detalles Bancarios",
    hideBankDetails: "Ocultar Detalles Bancarios",
    legalDocsOpen: "Abierto",
  },
  vnm: {
    realAccount: `Tài khoản thực`,
    demoAccount: `Tài khoản Demo`,
    signIn: `Đăng nhập`,
    register: `Đăng ký`,
    login: `Đăng nhập`,
    openAccount: `Mở tài khoản`,
    openLiveAccount: `Mở tài khoản trực tiếp`,
    learnMore: `Tìm hiểu thêm`,
    subscribe: `Theo dõi`,
    tradeNow: `Giao dịch ngay`,
    readMore: `Đọc thêm`,
    readLess: `Đọc ít hơn`,
    openInstitutionalAccount: `Mở tài khoản tổ chức`,
    downloadNow: `Tải ngay`,
    enjoyTrioxtend: `Thưởng thức Trioxtend`,
    getAllTrioxtendManuals: `Nhận tất cả sách hướng dẫn sử dụng TrioXend`,
    accessWebTrader: `Truy cập Web Trader`,
    joinTheCommunity: `Tham gia vào cộng đồng`,
    showMore: `Cho xem nhiều hơn`,
    showLess: `Hiện ít hơn`,
    getStarted: `Bắt đầu`,
    submitYourMessage: `Gửi tin nhắn của bạn`,
    companyAccount: "Tài khoản công ty",
    clientsAccount: "Tài khoản khách hàng",
    sendMessage: "Gửi tin nhắn",
    becomeIB: "Trở thành IB",
    becomeCPA: "Trở thành Người giới thiệu CPA",
    getYourBonus: "Nhận tiền thưởng của bạn",
    refereFriendNow: "Giới thiệu bạn bè ngay bây giờ",
    getCashback: "Nhận CashBack của bạn",
    accept: "Chấp nhận",
    downloadPDF: "Tải PDF",
    viewBankDetails: "Xem chi tiết Ngân Hàng",
    hideBankDetails: "Ẩn chi tiết Ngân Hàng ",
    legalDocsOpen: "Mở",
  },
  enUae: {
    realAccount: `Real Account`,
    demoAccount: `Demo Account`,
    signIn: `Sign In`,
    register: `Register`,
    login: `Login`,
    openAccount: `Open Account`,
    openLiveAccount: `Open Live Account`,
    learnMore: `Learn More`,
    subscribe: `Subscribe`,
    tradeNow: `Trade Now`,
    readMore: `Read More`,
    readLess: `Read Less`,
    openInstitutionalAccount: `Open Institutional Account`,
    downloadNow: `Download Now`,
    enjoyTrioxtend: `Enjoy Trioxtend`,
    getAllTrioxtendManuals: `Get All TrioXend Manuals`,
    accessWebTrader: `Access Web Trader`,
    joinTheCommunity: `Join The Community`,
    showMore: `Show More`,
    showLess: `Show Less`,
    getStarted: `Get Started`,
    submitYourMessage: `Submit your Message`,
    companyAccount: "Company Account",
    clientsAccount: "Clients Account",
    sendMessage: "Send Message",
    becomeIB: "Become an IB",
    becomeCPA: "Become a CPA Referral",
    getYourBonus: "Get your Bonus",
    refereFriendNow: "Refer a Friend Now",
    getCashback: "Get your CashBack",
    accept: "Accept",
    downloadPDF: "Download PDF",
    viewBankDetails: "View Bank Details",
    hideBankDetails: "Hide Bank Details",
    legalDocsOpen: "Open",
  },
  arb: {
    realAccount: `حساب حقيقي`,
    demoAccount: `حساب تجريبي`,
    signIn: `تسجيل`,
    register: `إنشاء حساب`,
    login: (ctx) =>
      ctx.named("windowWidth") >= 1200 ? `تسجيل الدخول` : `تسجيل <br>الدخول`,
    openAccount: `فتح حساب`,
    openLiveAccount: `فتح حساب حقيقي`,
    learnMore: `تعرف على المزيد`,
    subscribe: `اشترك`,
    tradeNow: `تداول الآن`,
    readMore: `عرض المزيد`,
    readLess: `عرض أقل`,
    openInstitutionalAccount: `فتح حساب مؤسسي`,
    downloadNow: `حمّل الان`,
    enjoyTrioxtend: `استمتع بـ Trioxtend`,
    getAllTrioxtendManuals: `احصل على جميع كتيبات TrioXend`,
    accessWebTrader: `احصل على تداول الويب`,
    joinTheCommunity: `انضم إلى المجتمع`,
    showMore: `عرض المزيد`,
    showLess: `عرض القليل`,
    getStarted: `ابدأ الآن`,
    submitYourMessage: `عرض الرسالة`,
    companyAccount: "حساب الشركة",
    clientsAccount: "حساب العملاء",
    sendMessage: "إرسال",
    becomeIB: "كن وسيطًا معرفًا",
    becomeCPA: "كن محول اكتساب",
    getYourBonus: "احصل على مكافأتك",
    refereFriendNow: "حوّل صديق الآن",
    getCashback: "استرجع مبلغاً",
    accept: "Accept",
    downloadPDF: "حمّل بي دي اف",
    viewBankDetails: "عرض تفاصيل البنك",
    hideBankDetails: "إخفاء تفاصيل البنك",
    legalDocsOpen: "فتح حساب",
  },
  my: {
    realAccount: `Akaun Sebenar`,
    demoAccount: `Akaun Demo`,
    signIn: `Masuk`,
    register: `Daftar`,
    login: `Log masuk`,
    openAccount: `Buka Akaun`,
    openLiveAccount: `Buka Akaun Langsung`,
    learnMore: `Ketahui Lebih Lanjut`,
    subscribe: `Langgan`,
    tradeNow: `Berdagang Sekarang`,
    readMore: `Baca Lagi`,
    readLess: `Baca Kurang`,
    openInstitutionalAccount: `Buka Akaun Institusi`,
    downloadNow: `Muat turun sekarang`,
    enjoyTrioxtend: `Nikmati Trioxtend`,
    getAllTrioxtendManuals: `Dapatkan Semua Manual TrioXend`,
    accessWebTrader: `Akses Peniaga Web`,
    joinTheCommunity: `Sertailah Komuniti`,
    showMore: `Tunjukkan lebih banyak`,
    showLess: `Tunjukkan Kurang`,
    getStarted: `Bermula`,
    submitYourMessage: `Kirimkan Mesej anda`,
    companyAccount: "Akaun Syarikat",
    clientsAccount: "Akaun Pelanggan",
    sendMessage: "Hantar Mesej",
    becomeIB: "Menjadi IB",
    becomeCPA: "Menjadi Rujukan CPA",
    getYourBonus: "Dapatkan Bonus anda",
    refereFriendNow: "Rujuk Rakan Sekarang",
    getCashback: "Dapatkan Wang Tunai anda",
    accept: "Terima",
    downloadPDF: "Muat turun PDF",
    viewBankDetails: "Lihat Butiran Bank",
    hideBankDetails: "Sembunyikan Butiran Bank",
    legalDocsOpen: "Terbuka",
  },
};
