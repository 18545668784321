<template>
  <div
    :class="`navbar fixed-top w-100 ${
      scrolled < 50 && !$route.meta.bg ? '' : 'scrolled'
    } animate__animated animate__slideInDown`"
  >
    <router-link
      :to="`/${$route.params.lang}/`"
      class="py-2 me-2 h-100 logo-wrapper"
    >
      <!-- <img
        :src="`/img/${
          scrolled < 50 && !$route.meta.bg ? 'santa_logo' : 'santa_logo_dark'
        }.svg`"
        class="logo"
        alt="TrioMarkets Logo"
      /> -->
      <img
        :src="`/img/${
          scrolled < 50 && !$route.meta.bg ? 'logo' : 'logo-dark'
        }.svg`"
        class="logo"
        alt="TrioMarkets Logo"
      />
    </router-link>
    <ul
      :class="`d-flex align-items-center justify-content-around me-auto h-100 mb-0 nav-items ps-0 ms-3 ${
        checkArb ? 'text-end rtl' : ''
      }`"
    >
      <a class="nav-item">
        <router-link
          :to="`/${$route.params.lang}/markets/forex`"
          class="nav-link"
          >{{ $t("markets") }}</router-link
        >
        <div class="submenu">
          <ul>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/markets/forex`">{{
                $t("forex")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/markets/indices`">{{
                $t("indices")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/markets/metals`">{{
                $t("metals")
              }}</router-link>
            </li>
          </ul>
          <ul>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/markets/cryptos`">{{
                $t("cryptos")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/markets/energies`">{{
                $t("energies")
              }}</router-link>
            </li>
          </ul>
          <ul>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/markets/shares`">{{
                $t("shares")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/markets/trading-conditions`"
                >{{ $t("tradingConditions") }}</router-link
              >
            </li>
          </ul>
        </div>
      </a>
      <a class="nav-item">
        <router-link
          :to="`/${$route.params.lang}/accounts/accounts-overview`"
          class="nav-link"
          >{{ $t("accounts") }}</router-link
        >
        <div class="submenu">
          <ul>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/accounts/accounts-overview`"
                >{{ $t("accountsOverview") }}</router-link
              >
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/accounts/hni`">{{
                $t("hni")
              }}</router-link>
            </li>
            <li v-if="license == 'eu'" class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/accounts/professional-traders`"
                >{{ $t("proTraders") }}</router-link
              >
            </li>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/accounts/funding-and-withdrawals`"
                >{{ $t("fundingWithdrawals") }}</router-link
              >
            </li>
          </ul>
          <ul>
            <li v-if="checkUae" class="submenu-item">
              <router-link :to="`/${$route.params.lang}/accounts/swap-free`">{{
                $t("swappFree")
              }}</router-link>
            </li>
          </ul>
        </div>
      </a>
      <a class="nav-item">
        <router-link
          :to="`/${$route.params.lang}/platforms/meta-trader`"
          class="nav-link"
          >{{ $t("platforms") }}</router-link
        >
        <div class="submenu">
          <ul>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/platforms/meta-trader`"
                >{{ $t("metaTrader") }}</router-link
              >
            </li>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/platforms/trio-xtend`"
                >{{ $t("xTend") }}</router-link
              >
            </li>
            <!-- <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/platforms/mobile-trading`"
                >{{ $t("mobileTrading") }}</router-link
              >
            </li> -->
          </ul>
          <ul>
            <!-- <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/platforms/mobile-app`"
                >{{ $t("mApp") }}</router-link
              >
            </li> -->
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/platforms/web-trader`"
                >{{ $t("webTrader") }}</router-link
              >
            </li>
            <!-- <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/platforms/social-trading`"
                >{{ $t("socialTrading") }}</router-link
              >
            </li> -->
          </ul>
          <ul>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/platforms/fix-api`">{{
                $t("fixApi")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/platforms/vps`">{{
                $t("vps")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/platforms/mam`">{{
                $t("mam")
              }}</router-link>
            </li>
          </ul>
        </div>
      </a>
      <a class="nav-item">
        <router-link
          :to="`/${$route.params.lang}/company/meet-us`"
          class="nav-link"
          >{{ $t("company") }}</router-link
        >
        <div class="submenu">
          <ul>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/company/meet-us`">{{
                $t("aboutUs")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/company/segregated-funds`"
                >{{ $t("segregatedFunds") }}</router-link
              >
            </li>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/company/license-and-regulations`"
                >{{ $t("licenseRegulations") }}</router-link
              >
            </li>
          </ul>
          <ul>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/company/legal-documents`"
                >{{ $t("legalDocuments") }}</router-link
              >
            </li>
            <li v-if="license === 'eu'" class="submenu-item">
              <router-link :to="`/${$route.params.lang}/company/kids`">{{
                $t("kidsDocuments")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/company/contact`">{{
                $t("contact")
              }}</router-link>
            </li>
          </ul>
          <ul>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/company/glossary`">{{
                $t("glossary")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/company/faq`">{{
                $t("faq")
              }}</router-link>
            </li>
          </ul>
        </div>
      </a>
      <a v-if="license !== 'eu'" class="nav-item">
        <router-link
          :to="`/${$route.params.lang}/partnerships/introducing-brokers`"
          class="nav-link"
          >{{ $t("partnerships") }}</router-link
        >
        <div class="submenu">
          <ul>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/partnerships/introducing-brokers`"
                >{{ $t("introducingBroker") }}</router-link
              >
            </li>
            <li class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/partnerships/cpa-affiliates`"
                >{{ $t("cpa") }}</router-link
              >
            </li>
            <li v-if="license == 'global'" class="submenu-item">
              <router-link
                :to="`/${$route.params.lang}/partnerships/promotions`"
                >{{ $t("promotions") }}</router-link
              >
            </li>
          </ul>
        </div>
      </a>
      <a v-if="license == 'global'" class="nav-item">
        <router-link
          :to="`/${$route.params.lang}/news/articles`"
          class="nav-link"
          >{{ $t("news") }}</router-link
        >
        <div class="submenu">
          <ul>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/news/articles`">{{
                $t("articles.title")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/news/marketAnalysis`">{{
                $t("marketAnalysis.title")
              }}</router-link>
            </li>
            <li class="submenu-item">
              <router-link :to="`/${$route.params.lang}/news/economicCalendar`"
                >Economic Calendar</router-link
              >
            </li>
          </ul>
        </div>
      </a>
    </ul>
    <div class="nav-right d-flex justify-content-between align-items-center">
      <div class="buttons-inline">
        <a :href="registerURL" target="_blank" class="btn small btn-nav me-2">{{
          $t("register")
        }}</a>
        <a
          :href="loginURL"
          target="_blank"
          class="btn small btn-nav mr-1"
          v-html="$t('login', { windowWidth: windowWidth })"
        ></a>
      </div>
      <Language class="ms-2" />
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
import Language from "./Language";
export default {
  name: "Navbar",
  data: () => ({
    scrolled: 0,
    windowWidth: window.innerWidth,
  }),
  components: {
    Language,
  },
  computed: {
    license() {
      return getters.license();
    },
    loginURL() {
      return getters.loginURL();
    },
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
    checkUae() {
      return getters.checkUae();
    },
    // pageDetails() {
    //   return this.$tm(this.$route.meta.page);
    // },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
  methods: {
    handleScroll() {
      this.scrolled = window.pageYOffset;
    },
    destroyed() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style scoped lang="scss"></style>
