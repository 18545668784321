<template>
  <div class="container d-none d-lg-block">
    <hr />
    <div class="row wire pt-5 pb-4">
      <div class="col-12 d-flex justify-content-between align-items-center">
        <img
          v-for="(img, idx) in filterWire"
          :key="idx"
          :src="`/img/footer/${img.img}.svg`"
          :alt="img.name"
          class="img-fluid"
        />
      </div>
    </div>
  </div>
  <div class="container-fluid blue-box p-5 mt-5" id="footer">
    <div class="container mt-4 pe-5">
      <div class="row social">
        <div
          class="col-lg-3 col-12 d-flex align-items-center logo-col mt-lg-0 mt-4"
        >
          <img
            src="/img/logo.svg"
            alt="TrioMarkets logo"
            class="img-fluid logo"
          />
        </div>
        <div
          v-if="!checkUae && isBenor"
          class="col-lg-3 col-12 d-flex align-items-center px-0 social-icons mt-lg-0 mt-4"
        >
          <a
            v-for="(icon, idx) in socials"
            :key="idx"
            :href="icon.link"
            target="_blank"
            class="social-icon d-flex flex-column p-0"
          >
            <img
              :src="`/img/footer/${icon.img}.svg`"
              :alt="icon.name"
              class="si-normal"
            />
            <img
              :src="`/img/footer/${icon.hoverImg}.svg`"
              :alt="icon.name"
              class="si-hover"
            />
          </a>
        </div>
        <div
          v-if="checkUae"
          class="col-lg-3 col-12 d-flex align-items-center px-0 social-icons mt-lg-0 mt-4"
        >
          <a
            v-for="(icon, idx) in socialsUae"
            :key="idx"
            :href="icon.link"
            target="_blank"
            class="social-icon d-flex flex-column p-0"
          >
            <img
              :src="`/img/footer/${icon.img}.svg`"
              :alt="icon.name"
              class="si-normal"
            />
            <img
              :src="`/img/footer/${icon.hoverImg}.svg`"
              :alt="icon.name"
              class="si-hover"
            />
          </a>
        </div>
        <div
          v-if="!checkUae"
          class="col-lg-3 col-6 d-flex align-items-center justify-content-center phone pe-5"
        >
          <a
            class="pointer d-flex align-items-center ltr"
            :href="`tel:${
              license == 'global' || license == 'africa'
                ? '+44 20 376 936 49'
                : '+357 22 222737'
            }`"
            ><img
              class="me-xxl-3 me-2 my-lg-0 my-3 ms-1 footer-icon"
              src="/img/footer/call-White.svg"
              alt="phone icon"
            />
            <span class="d-none d-sm-block text-white">{{
              $t("contactPhone", { license: license })
            }}</span></a
          >
        </div>
        <div
          v-if="checkUae"
          class="col-lg-3 col-6 d-flex align-items-center justify-content-center phone"
        >
          <a
            class="pointer d-flex align-items-center ltr"
            href="tel:+971 4 242 1234"
            ><img
              class="me-xxl-3 me-2 my-lg-0 my-3 ms-1 footer-icon"
              src="/img/footer/call-White.svg"
              alt="phone icon"
            />
            <span class="d-none d-sm-block text-white">+971 4 242 1234</span></a
          >
        </div>
        <div
          class="col-lg-3 col-6 d-flex align-items-center justify-content-end email pe-5"
        >
          <a
            target="_blank"
            :href="
              license == 'global' || license == 'africa'
                ? 'mailto:info@triomarketsglobal.com'
                : 'mailto:info@triomarkets.eu'
            "
            class="d-flex email align-items-center p-0 ltr"
          >
            <img
              class="me-xxl-3 me-2 my-lg-0 my-3 footer-icon ms-1"
              src="/img/footer/email-White.svg"
              alt="email icon"
            />
            <span class="d-none d-sm-block text-white">{{
              license == "global" || license == "africa"
                ? "info@triomarketsglobal.com"
                : "info@triomarkets.eu"
            }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="container footer-menu mt-3">
      <div class="row desk-menu">
        <div class="col-12 pe-0 mb-5">
          <table class="w-100">
            <thead>
              <tr>
                <th
                  :class="`fs-6 text-white pb-2 pe-3 ${textEnd}`"
                  v-for="(item, idx) in filterMenu"
                  :key="idx"
                >
                  {{ item.title }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  v-for="(item, idx) in filterMenu"
                  :key="idx"
                  class="pe-lg-3 pe-2"
                >
                  <tr
                    v-for="(link, i) in item.submenu"
                    :key="i"
                    :class="textEnd"
                  >
                    <router-link
                      class="text-white fw-normal"
                      :to="`/${$route.params.lang}/${link.link}`"
                      >{{ link.title }}</router-link
                    >
                  </tr>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="license == 'eu'" class="container mt-5 mb-3 awards">
      <div class="row mb-5">
        <div class="col-12 d-flex justify-content-center">
          <img
            src="/img/awards/eu_awards.svg"
            class="img-fluid d-none d-md-block"
            alt="awards"
          />
          <!-- <img
            src="/img/awards-ph.svg"
            class="img-fluid d-md-none"
            alt="awards"
          /> -->
        </div>
      </div>
    </div>
    <div v-else-if="license == 'global'">
      <div class="row mb-5">
        <div class="col-12 d-flex justify-content-center">
          <img
            src="/img/awards/benor/AWARDS_7-5.svg"
            class="img-fluid d-none d-md-block"
            style="width: 600px; height: 100px"
            alt="awards"
          />
        </div>
      </div>
    </div>
    <div class="container footer-bottom">
      <div class="d-flex pe-0 pe-lg-auto">
        <div>
          <router-link
            :to="`/${$route.params.lang}/company/legal-documents`"
            class="mb-2 text-white"
            >{{ $t("termsConditions") }}</router-link
          >
          |
          <router-link
            :to="`/${$route.params.lang}/company/legal-documents`"
            class="mb-2 text-white"
            >{{ $t("riskDisclosurePolicy") }}</router-link
          >
          |
          <router-link
            :to="`/${$route.params.lang}/company/legal-documents`"
            class="text-white"
            >{{ $t("privacyPolicy") }}</router-link
          >
          |
          <router-link
            v-if="license === 'africa'"
            :to="`/${$route.params.lang}/company/legal-documents`"
            class="text-white"
            >{{ $t("faisDisclosure") }}</router-link
          >
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-12 pt-4">
          <p
            class="text-justify"
            v-html="$t('riskWarningFooter', { license: license })"
          ></p>
        </div>
        <div v-if="license !== 'eu'" class="col-12 pt-3">
          <p class="text-justify" v-html="$t('triomarketsAfricaFooter2')"></p>
        </div>
        <div class="col-12 pt-3">
          <p
            class="text-justify"
            v-html="$t('triomarketsFooter', { license: license })"
          ></p>
        </div>
        <div v-if="license === 'africa'">
          <p
            class="text-justify mb-3"
            v-html="$t('triomarketsAfricaFooter')"
          ></p>
        </div>
        <div v-if="license == 'eu'" class="col-12">
          <p class="text-justify" v-html="$t('edrDisclaimer')"></p>
        </div>
        <div v-if="license == 'global'" class="col-12">
          <p class="text-justify" v-html="$t('benorDisclaimer')"></p>
        </div>
        <div v-if="license == 'global'" class="col-12">
          <p class="text-justify" v-html="$t('triomarketsDubai')"></p>
        </div>
        <div v-if="license == 'global'" class="col-12">
          <p class="text-justify" v-html="$t('paymentAgent')"></p>
        </div>
        <div class="col-md-12 pt-2">
          <p
            class="text-justify mb-0"
            v-html="$t('riskWarning', { license: license })"
          ></p>
        </div>
        <div v-if="license !== 'africa'" class="col-md-12">
          <p
            class="text-justify"
            v-html="$t('regionalRestrictions', { license: license })"
          ></p>
        </div>
        <div v-else class="col-md-12">
          <p class="text-justify" v-html="$t('regionalAfricaRestrictions')"></p>
        </div>
        <div class="col-md-12 pt-2 text-white">
          <p v-if="license !== 'africa'" class="text-justify mb-0 text-white">
            <strong class="text-white">
              {{
                license == "global"
                  ? "BENOR CAPITAL LIMITED"
                  : "EDR FINANCIAL LIMITED"
              }}</strong
            >
            {{ $t("footerAddress", { license: license }) }}
          </p>
          <p
            v-else
            v-html="$tm('footerAfricaAdress')"
            class="text-justify mb-0"
          ></p>
        </div>
        <div v-if="license == 'eu'" class="col-md-12">
          <p class="text-justify m-0" v-html="$t('footerTrademark')"></p>
        </div>
        <div class="col-md-12">
          <p class="text-justify">&copy;{{ $t("allRightsReserved") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import { getters } from "../store";
export default {
  name: "Footer",
  data: () => ({
    email: "",
    currentSubMenuID: null,
    wire: [
      { img: "visa", name: "visa" },
      { img: "master", name: "mastercard" },
      { img: "wire", name: "wire transfer" },
      { img: "skrill", name: "skrill" },
      { img: "neteller", name: "neteller" },
      // { img: "bit", name: "bitcoin" },
      { img: "perfectmoney", name: "perfectmoney" },
    ],
    socials: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/triomarkets",
        img: "facebook-NewWhite",
        hoverImg: "fb-hover",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/TrioMarkets",
        img: "x_twitter-NewWhite",
        hoverImg: "x_hover",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/triomarkets/mycompany/",
        img: "linkedin-NewWhite",
        hoverImg: "linkedin-hover",
      },
      {
        name: "YouTube",
        link: "https://www.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw",
        img: "youtube-NewWhite",
        hoverImg: "youtube-hover",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/triomarkets/",
        img: "Instagram-NewWhiteS",
        hoverImg: "insta-hover",
      },
    ],
    socialsUae: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/triomarketsmena",
        img: "facebook-NewWhite",
        hoverImg: "fb-hover",
      },
      {
        name: "Twitter",
        link: "https://twitter.com/TriomarketsMena",
        img: "x_twitter-NewWhite",
        hoverImg: "x_hover",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/triomarketsmena/",
        img: "linkedin-NewWhite",
        hoverImg: "linkedin-hover",
      },
      {
        name: "YouTube",
        link: "https://www.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw",
        img: "youtube-NewWhite",
        hoverImg: "youtube-hover",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/triomarkets/",
        img: "Instagram-NewWhiteS",
        hoverImg: "insta-hover",
      },
      // {
      //   name: "TikTok",
      //   link: "https://www.tiktok.com/@triomarketsmena",
      //   img: "tik-tok",
      //   hoverImg: "tik-tok-hover",
      // },
    ],
  }),
  computed: {
    license() {
      return getters.license();
    },
    filterWire() {
      if (this.license === "global") {
        return this.wire.filter((img) => {
          return img.name !== "skrill";
        });
      } else if (this.license === "eu") {
        return this.wire.filter((img) => {
          return img.name !== "perfectmoney";
        });
      } else {
        return this.wire;
      }
    },
    filterMenu() {
      return this.$tm("menuItems")
        .map((element) => {
          return element;
        })
        .map((el) => {
          let filterArray = el.submenu.filter((sub) => {
            if (this.license == "global" || this.license == "africa") {
              return sub.license !== "eu";
            } else if (this.license == "eu") {
              return sub.license !== "global" || sub.license == "africa";
            } else {
              return sub.license;
            }
          });

          return {
            title: el.title,
            submenu: filterArray,
          };
        });
    },
    checkArb() {
      return getters.checkArb();
    },
    textEnd() {
      if (this.checkArb) {
        return "text-end";
      } else {
        return "";
      }
    },
    checkUae() {
      return getters.checkUae();
    },
    isBenor() {
      return this.license === "global";
    },
  },
};
</script>
<style lang="scss" scoped></style>
