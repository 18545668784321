<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content }}</template>
  </metainfo>
  <div v-if="siteStatus" class="container-fluid p-0" id="app">
    <Cookies v-if="!cookiesEnabled" />
    <Regulation
      v-if="
        !regulationAccepted &&
        cookiesEnabled &&
        isEurope == true &&
        license == 'global'
      "
    />
    <Navbar id="navbar" />
    <NavbarMobile v-if="mobileMenu == true" id="navbarMobile" />
    <router-view></router-view>
    <Footer />
    <Disclaimer v-if="windowWidth > 769 && !hideDisclaimer" />
    <div id="liveAgent-chat" class="liveChat pointer">
      <img
        src="/img/headset.svg"
        alt="livechat"
        class="img-fluid"
        onclick="LiveChatWidget.call('maximize')"
      />
    </div>
    <div
      class="scroll-up pointer animate__animated animate__slideInRight"
      v-scroll-to="{
        el: '#app',
        duration: 500,
        easing: 'ease-in-out',
      }"
      v-if="scrolled >= 500"
    >
      <img src="/img/scroll-up.svg" alt="scroll-up" class="img-fluid pointer" />
    </div>
  </div>
  <Maintenance v-if="!siteStatus" />
</template>

<script>
let LiveChatWidget = {};
setTimeout(() => {
  if (location.host.includes(".eu")) {
    window.__lc = window.__lc || {};
    window.__lc.license = 7576881;
    (function (n, t, c) {
      function i(n) {
        return e._h ? e._h.apply(null, n) : e._q.push(n);
      }
      var e = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
          i(["on", c.call(arguments)]);
        },
        once: function () {
          i(["once", c.call(arguments)]);
        },
        off: function () {
          i(["off", c.call(arguments)]);
        },
        get: function () {
          if (!e._h)
            throw new Error(
              "[LiveChatWidget] You can't use getters before load."
            );
          return i(["get", c.call(arguments)]);
        },
        call: function () {
          i(["call", c.call(arguments)]);
        },
        init: function () {
          var n = t.createElement("script");
          (n.async = !0),
            (n.type = "text/javascript"),
            (n.src = "https://cdn.livechatinc.com/tracking.js"),
            t.head.appendChild(n);
        },
      };
      !n.__lc.asyncInit && e.init(), (n.LiveChatWidget = n.LiveChatWidget || e);
    })(window, document, [].slice);

    LiveChatWidget.call;

    let webchat = document.getElementById("convrs-chat-channel-container");
    if (webchat) {
      webchat.remove();
    }
  } else {
    let liveAgent = document.getElementById("liveAgent-chat");
    if (liveAgent) {
      liveAgent.remove();
    }
    setTimeout(() => {
      document
        .getElementById("convrs-chat-channel-container")
        .classList.add("converse_chat");
    }, 200);
  }
}, 1000);
import axios from "axios";
import renderMeta from "./SEO";
import { getters, mutations } from "./store";
import Navbar from "./components/Navbar";
import NavbarMobile from "./components/NavbarMobile";
import Footer from "./components/Footer.vue";
import Disclaimer from "./components/Disclaimer";
import Regulation from "./components/Regulation.vue";
import Cookies from "./components/Cookies.vue";
import Maintenance from "./components/Maintenance.vue";
export default {
  name: "App",
  metaInfo() {
    return renderMeta(
      "Home",
      `Triomarkets is about forex in your field of interest. Our vision is to help people share their knowledge, 
      work, projects, papers and ideas and build their network through what they do rather where they live, study or work.`
    );
  },
  data: () => ({
    scrolled: 0,
    isEurope: false,
    siteStatus: true,
    mobileMenu: false,
    liveChat: LiveChatWidget,
    windowWidth: window.innerWidth,
    isProduction: process.env.NODE_ENV !== "development",
  }),
  components: {
    Navbar,
    NavbarMobile,
    Footer,
    Disclaimer,
    Regulation,
    Cookies,
    Maintenance,
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeMount() {
    if (this.isProduction) {
      this.checkCountry();
      this.checkSiteStatus();
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.showMobileMenu();
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    hideDisclaimer() {
      return getters.toggleDisclaimer();
    },
    license() {
      return getters.license();
    },
    host() {
      return window.location.host;
    },
    cookiesEnabled() {
      return getters.cookiesEnabled();
    },
    regulationAccepted() {
      return getters.regulationAccepted();
    },
    languages() {
      return getters.languages();
    },
    euCountries() {
      return getters.euCountries();
    },
    menaCountries() {
      return getters.menaCountries();
    },
    asiaCountries() {
      return getters.asiaCountries();
    },
  },
  watch: {
    windowWidth() {
      this.showMobileMenu();
    },
  },
  methods: {
    checkSiteStatus() {
      axios({
        method: "GET",
        url: `${this.$apiURL()}/hub/maintenance/checkSite/${this.host}`,
      })
        .then(({ data }) => {
          if (data.success) {
            this.siteStatus = data.status;
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    checkCountry() {
      let selectedLanguage = window.sessionStorage.getItem("language");
      if (!selectedLanguage) {
        axios({
          method: "GET",
          url: "https://api.ipgeolocation.io/ipgeo?apiKey=596eeed312b24701964e77b25aa28625",
        })
          .then(({ data }) => {
            if (data.country_code2) {
              let countryISOCode = data.country_code2;
              this.$root.$i18n.locale = countryISOCode.toLowerCase();
              if (
                this.menaCountries.includes(countryISOCode) &&
                selectedLanguage !== "arb"
              ) {
                this.$root.$i18n.locale = "enUae";
              } else if (
                this.asiaCountries.includes(countryISOCode) &&
                window.location.host.includes("eu")
              ) {
                this.$root.$i18n.locale = "cn";
                window.location.replace("https://triomarkets.com/cn/");
              } else if (this.asiaCountries.includes(countryISOCode)) {
                this.$root.$i18n.locale = "cn";
              } else {
                this.$root.$i18n.locale =
                  this.languages.find(
                    ({ code }) => code === countryISOCode.toLowerCase()
                  )?.code || "en";
              }
              this.isEurope = this.euCountries.some(({ code }) => {
                return code === countryISOCode;
              });
              window.sessionStorage.setItem("isEU", this.isEurope);
              mutations.changeLanguage(this.$root.$i18n.locale);
              this.$router.replace({
                name: this.$route.name,
                params: { lang: this.$root.$i18n.locale },
                query: this.$route.query,
              });
              document.documentElement.setAttribute(
                "lang",
                this.$root.$i18n.locale
              );
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      } else {
        this.$root.$i18n.locale = selectedLanguage;
      }
    },
    handleScroll() {
      this.scrolled = window.pageYOffset;
    },
    showMobileMenu() {
      if (window.innerWidth <= 1200) {
        this.mobileMenu = true;
      } else {
        this.mobileMenu = false;
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  overflow-x: hidden;
}

@media only screen and (max-width: 1200px) {
  #navbar {
    display: none;
  }
}

.scroll-up {
  position: fixed;
  top: 85%;
  right: 0.5%;
  width: 56px;
  height: 56px;

  @media screen and (max-width: 600px) {
    width: 50px;
    right: 3%;
    top: 90%;
  }
}

.liveChat {
  position: fixed;
  z-index: 999;
  top: 60%;
  right: -4.5rem;
  transition: 0.5s ease-in-out;

  &:hover {
    transform: translateX(-10px);
  }

  @media screen and (max-width: 600px) {
    top: 77%;
    right: -6rem;

    img {
      width: 70%;
    }
  }
}

//CONVERS SHIT
.convrs-chat-mainicon2 {
  margin-bottom: 0 !important;
}

.converse_chat {
  bottom: 10rem !important;
  right: 0.5% !important;
}
</style>
