<template>
  <div v-for="(item, idx) in $tm('webTraderInfo')" :key="idx">
    <div class="container-fluid container-top accounts half web">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("webTrader") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-8 col-md-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
          <div class="col-12 text-center my-4">
            <a
              target="_blank"
              :href="
                license == 'global'
                  ? 'https://triomarkets.com/webtrader/index.html'
                  : 'https://triomarkets.eu/webtrader/index.html'
              "
              class="btn btn-blue"
              >{{ $t("accessWebTrader") }}</a
            >
          </div>
          <div class="col-12 d-md-block d-none text-center">
            <img
              src="/img/platforms-menu/air-gold.png"
              alt="imac"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">{{ item.title }}</h2>
        <hr class="blue mbhr" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          class="col-lg-8 col-md-10 col-12 text-center mb-3"
          v-html="item.textTwo"
        ></div>
        <div class="col-12 text-center my-4">
          <a
            target="_blank"
            :href="
              license == 'global'
                ? 'https://triomarkets.com/webtrader'
                : 'https://triomarkets.eu/webtrader'
            "
            class="btn btn-blue"
            >{{ $t("accessWebTrader") }}</a
          >
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "WebTrader",
  computed: {
    license() {
      return getters.license();
    },
  },
};
</script>
<style lang="scss" scoped></style>
