<template>
  <div id="ecCalendar">
    <div class="container-fluid container-top news accounts">
      <div class="container">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">Economic Calendar</h2>
          <hr class="gold" />
        </div>
      </div>
    </div>
    <div class="container d-flex justify-content-center py-5">
      <iframe
        src="https://sslecal2.investing.com?columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=25,32,6,37,72,22,17,39,14,10,35,43,56,36,110,11,26,12,4,5&calType=day&timeZone=20&lang=1"
        width="650"
        height="467"
        frameborder="0"
        allowtransparency="true"
        marginwidth="0"
        marginheight="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  name: "Economic Calendar",
};
</script>

<style lang="scss">
</style>
