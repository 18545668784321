<template>
  <div v-for="(item, idx) in $tm('contactInfo')" :key="idx" id="contact">
    <div class="container-fluid container-top accounts contact">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("contact") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-8 col-md-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
        </div>
        <div class="row d-flex justify-content-center my-md-5 mt-4">
          <div class="col-xl-8 col-lg-10">
            <div class="row d-flex justify-content-center position-relative">
              <div
                v-for="(point, idx) in item.points"
                :key="idx"
                class="col-md-4 text-center mb-md-0 mb-4"
              >
                <img
                  :src="`/img/company/contact/${point.img}.svg`"
                  :alt="item.name"
                  class="img-fluid my-2 icon"
                />
                <p class="text-white fs-6" v-html="point.name"></p>
                <a
                  target="_blank"
                  :href="point.link"
                  class="btn btn-blue"
                  v-html="point.text"
                ></a>
                <p v-if="license == 'eu'" class="text-white fs-6">
                  {{ point.fax }}
                </p>
              </div>
              <div v-if="checkArb" class="col-md-4 text-center mb-md-0 mb-4">
                <img
                  :src="`/img/company/contact/phone.svg`"
                  class="img-fluid my-2 icon"
                />
                <p class="text-white fs-6">الدعم عبر الهاتف</p>
                <a
                  target="_blank"
                  class="btn btn-blue"
                  style="direction: ltr !important"
                  >+44 20 376 936 49</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="row d-flex justify-content-center"
          id="supportDesk"
          v-if="license === 'global'"
        >
          <div class="col-xl-12 col-md-10 col-sm-8">
            <div class="row d-flex justify-content-center">
              <div
                v-for="(point, idx) in phonesFilter"
                :key="idx"
                class="col-xl-2 col-md-4 col-sm-6 text-center px-2"
              >
                <img
                  :src="`/img/lang/${point.img}.svg`"
                  :alt="item.name"
                  class="img-fluid my-2 icon"
                />
                <p class="text-gold fs-5 mb-0" v-html="point.name"></p>
                <p class="text-white fs-5 phones">{{ phones[idx] }}</p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="license == 'eu'" class="row d-md-none">
          <div class="col-12 text-center">
            <img
              src="/img/company/contact/pin.svg"
              alt="map-pin"
              class="img-fluid icon my-2"
            />
            <p class="text-center text-white fs-6">
              Centro Office 301 | Grigori Affxentiou 11 4003 Limassol | Cyprus
            </p>
          </div>
        </div>
        <div v-if="license == 'global'" class="row d-md-none">
          <div class="col-12 text-center">
            <img
              src="/img/company/contact/pin.svg"
              alt="map-pin"
              class="img-fluid icon my-2"
            />
            <p class="text-center text-white fs-6">
              The Catalyst Level 2 | Suite 201 Plot 40 <br />
              Silicon Avenue Ebene | Mauritius
            </p>
          </div>
        </div>
        <div v-if="license !== 'eu'" class="row d-flex justify-content-center">
          <div class="col-12 d-flex justify-content-center">
            <a
              target="_blank"
              :href="social.link"
              v-for="(social, idx) in socials"
              :key="idx"
            >
              <img
                :src="`/img/company/contact/${social.img}.svg`"
                :alt="social.name"
                class="img-fluid icon p-md-1 mx-md-1 mx-2"
              />
            </a>
          </div>
        </div>
        <div class="row my-5">
          <div
            class="col-sm-7 px-0"
            v-for="(input, idx) in $tm('partnerFormInputs')"
            :key="idx"
          >
            <form @submit.prevent class="contact-form p-4 rounded-left">
              <div class="row">
                <div class="col-md-6">
                  <input
                    type="text"
                    name="First Name"
                    :placeholder="input.firstName"
                    @blur="errors.fname = ''"
                    v-model="contact_data.fname"
                  />
                  <div class="text-danger mt-2">
                    {{ errors.fname }}
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    type="text"
                    name="Last Name"
                    :placeholder="input.lastName"
                    @blur="errors.lname = ''"
                    v-model="contact_data.lname"
                  />
                  <div class="text-danger mt-2">
                    {{ errors.lname }}
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    type="text"
                    name="Email"
                    :placeholder="input.email"
                    @blur="errors.email = ''"
                    v-model="contact_data.email"
                  />
                  <div class="text-danger mt-2">
                    {{ errors.email }}
                  </div>
                </div>
                <div class="col-md-6">
                  <input
                    type="text"
                    name="Phone Number"
                    :placeholder="input.phone"
                    @blur="errors.phone = ''"
                    v-model="contact_data.phone"
                  />
                  <div class="text-danger mt-2">
                    {{ errors.phone }}
                  </div>
                </div>
                <div class="col-12">
                  <select
                    name="Department"
                    v-model="contact_data.department"
                    @change="errors.department = ''"
                  >
                    <option value="" disabled selected>
                      {{ input.department }}
                    </option>
                    <option value="General">{{ input.general }}</option>
                    <option value="Back office">{{ input.backOffice }}</option>
                    <option value="Support">{{ input.support }}</option>
                    <option value="Financial">{{ input.financial }}</option>
                  </select>
                  <div class="text-danger mt-2">
                    {{ errors.department }}
                  </div>
                </div>
                <div class="col-12 my-3">
                  <textarea
                    name="text"
                    rows="5"
                    :placeholder="input.message"
                    @blur="errors.message = ''"
                    v-model="contact_data.message"
                    class="p-3"
                  ></textarea>
                  <div class="text-danger mt-2">
                    {{ errors.message }}
                  </div>
                </div>
                <div class="col-12 text-center">
                  <a href="#" class="btn btn-blue" @click.prevent="contactUs">{{
                    $t("sendMessage")
                  }}</a>
                </div>
              </div>
            </form>
          </div>
          <div class="col-sm-5 px-0 rounded-right" id="contactMap">
            <iframe
              class="position-relative"
              v-if="license == 'global'"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3743.3338328631785!2d57.48546961539744!3d-20.244985186428142!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c5b1faee2b3190x375adea3a052605c!2sToukan+Corporate+Services+Limited!5e0!3m2!1sen!2smu!4v1549873680272"
              width="100%"
              height="100%"
              frameborder="0"
              allowfullscreen
            >
            </iframe>
            <div
              class="text-center d-flex flex-column justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100 rounded-right"
              id="mapOverlay"
              v-if="overlay == true"
              @mouseenter="overlay = false"
              @mouseleave="overlay = true"
            >
              <img src="/img/company/contact/pin.svg" alt="Pin" />
              <p v-if="license == 'eu'" class="fw-bold text-white fs-6">
                Centro Imperio 301 | Grigori Afxentiou 11 <br />
                4003 Limassol | Cyprus
              </p>
              <p v-else class="fw-bold text-white fs-6">
                The Catalyst Level 2 | Suite 201 Plot 40 <br />
                Silicon Avenue Ebene | Mauritius
              </p>
            </div>
          </div>
        </div>
        <div class="d-md-none mb-5">
          <div class="row d-flex justify-content-center">
            <div class="col-sm-10">
              <div class="row d-flex justify-content-center">
                <div
                  v-for="(point, idx) in phonesFilter"
                  :key="idx"
                  class="col-sm-6 text-center"
                >
                  <img
                    :src="`/img/lang/${point.img}.svg`"
                    :alt="item.name"
                    class="img-fluid my-2 icon"
                  />
                  <p class="text-gold fs-5 mb-0" v-html="point.name"></p>
                  <p class="text-white fs-5 phones">{{ phones[idx] }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import DG from "2gis-maps";
import { getters } from "../store";
import renderMeta from "../SEO";
export default {
  name: "Contact",
  metaInfo() {
    return renderMeta("Contact", "Triomarkets Contact Page");
  },
  data: () => ({
    socials: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/triomarkets",
        img: "fb",
      },
      {
        name: "X",
        link: "https://twitter.com/TrioMarkets",
        img: "X-Blue",
      },
      {
        name: "LinkedIn",
        link: "https://www.linkedin.com/company/triomarkets/mycompany/",
        img: "linkedin",
      },
      {
        name: "YouTube",
        link: "https://www.youtube.com/channel/UC_wQCTAHWrmDJqN0V7xbuBw",
        img: "youtube",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/triomarkets/",
        img: "insta",
      },
    ],
    overlay: true,
    contact_data: {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      department: "",
      message: "",
    },
    errors: {},
  }),
  mounted() {
    if (this.license == "eu") {
      const map = DG.map("contactMap", {
        center: [34.697238, 33.049884],
        zoom: 18,
      });
      const dgIcon = DG.icon({
        iconUrl: "../../img/company/contact/logo.png",
        iconSize: [32, 32],
      });
      DG.marker([34.697238, 33.04965], { icon: dgIcon })
        .addTo(map)
        .bindPopup(
          `TrioMarkets <br> <a target="_blank" href="triomarkets.eu">triomarkets.eu</a> <br> Centro Imperio <br> Grigori Afxentiou 11 <br> Office 301 <br> 4003 Limassol, Cyprus`
        );
      DG.popup([34.697238, 33.04965])
        .setLatLng([34.697238, 33.04965])
        .setContent(
          `TrioMarkets <br> <a target="_blank" href="https://triomarkets.eu">triomarkets.eu</a> <br> Centro Imperio <br> Grigori Afxentiou 11 <br> Office 301 <br> 4003 Limassol, Cyprus`
        );
    }
  },
  computed: {
    license() {
      return getters.license();
    },
    phones() {
      if (this.license == "global") {
        return [
          "+44 20 376 936 49",
          "+33 3 75 77 37 52",
          "+39 03 321 320 002",
          "+49 61 718 913 051",
          "+971 4 242 1234",
        ];
      } else {
        return [
          "+44 20 376 936 46",
          "+33 3 75 77 39 95",
          "+39 03 321 320 001",
          "+49 61 718 913 050",
        ];
      }
    },
    phonesFilter() {
      if (this.license == "eu") {
        return this.$tm("contactInfo")[0].pointsTwo.filter((desk) => {
          return desk.img !== "uae";
        });
      } else {
        // return this.$tm("contactInfo")[0].pointsTwo;
        return this.$tm("contactInfo")[0].pointsTwo.filter((desk) => {
          return desk.img !== "uae";
        });
      }
    },
    checkArb() {
      return getters.checkArb();
    },
  },

  methods: {
    contactUs() {
      axios({
        url: `${this.$apiURL()}/email/contact`,
        method: "post",
        data: this.contact_data,
      })
        .then(({ data }) => {
          this.errors = {};
          if (data.errors) {
            data.errors.map(({ name, message }) => {
              this.errors[name] = message;
            });
          } else {
            alert(data.message);
            if (data.success) {
              this.contact_data = {
                fname: "",
                lname: "",
                email: "",
                phone: "",
                department: "",
                message: "",
              };
            }
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
  },
};
</script>
