<template>
  <div class="quote-details">
    <div class="row avg-price pr d-flex justify-between align-items-center">
      <h2
        :class="`col-5 p-0 m-0 ${textEnd} ${
          selectedSymbol.trend == 'dn' ? 'text-danger' : 'text-success'
        }`"
      >
        {{ selectedSymbol.ask }}
      </h2>
      <div class="col-2 price-direction d-flex flex-row alidn-items-center pe-md-3 pe-0">
        <div>
          <div
            :class="`fs-6 lh-1 d-block price-arrow ${
              selectedSymbol.trend == 'dn'
                ? 'down'
                : 'up'
            }`"
          >
            <p></p>
          </div>
          <p
            :class="`price-change m-1 fs-6 lh-1 d-block ${
              selectedSymbol.trend == 'dn' ? 'text-danger' : 'text-success'
            }`"
          >
            {{ percentage }}%
          </p>
        </div>
      </div>
      <h2 class="col-5 m-sm-0 mt-2 d-flex justify-content-end ticker-name px-0">
        {{ selectedSymbol.ticker }}
      </h2>
    </div>
    <div class="row">
      <div
        :class="`col-6 left fs-6 pt-2 px-0 ${checkArb ? 'text-end rtl' : ''}`"
      >
        <span class="bold opaque">{{ $t("swapShort") }}:</span>
        <br />
        <span :class="selectedSwap < 0 ? 'text-danger' : 'text-success'"
          >{{ selectedSwap[0] ? selectedSwap[0].short : "..." }} pts</span
        >
      </div>
      <div
        :class="`col-6 right fs-6 pt-2  px-0 ${
          checkArb ? 'text-left' : 'text-right'
        }`"
      >
        <span class="bold opaque">{{ $t("swapLong") }}:</span>
        <br />
        <span :class="selectedSwap < 0 ? 'text-danger' : 'text-success'"
          >{{ selectedSwap[0] ? selectedSwap[0].long : "..." }} pts</span
        >
      </div>
    </div>
    <div class="row buy-sell position-relative py-3">
      <div
        class="spread-wrapper position-absolute top-0 bottom-0 h-100 w-100 d-flex align-items-center justify-content-center"
      >
        <div class="spread pointer p-3 rounded curved">
          <span class="faded">{{ selectedSymbol.spread }}</span>
        </div>
      </div>
      <div :class="`col-6 b ps-1 pe-0  ${textEnd}`">
        <div :class="`buy py-2 px-3 curved  ${checkArb ? 'rounded-left' : 'rounded-right'}`">
          <p class="text-white m-0 p-0 fs-5">{{ $t("sell") }}</p>
          <p class="buy-sell-price text-white m-0 p-0 fw-light">
            {{ selectedSymbol.bid }}
          </p>
        </div>
      </div>
      <div
        :class="`col-6 s pe-1 ps-0 ${checkArb ? 'text-start' : 'text-right'}`"
      >
        <div :class="`sell py-2 px-3 curved ${checkArb ? 'rounded-right' : 'rounded-left'}`">
          <p class="text-white m-0 p-0 fs-5">{{ $t("buy") }}</p>
          <p class="buy-sell-price text-white m-0 p-0 fw-light">
            {{ selectedSymbol.ask }}
          </p>
        </div>
      </div>
    </div>
    <div class="row">
      <div
        :class="`col-6 left pt-0 pl-0 ${textEnd} `"
        style="border: none"
      >
        <span class="bold opaque">{{ $t("low") }}: {{ quoteDetails.low }}</span>
      </div>
      <div
        :class="`col-6 right pt-0 pr-0 ${
          checkArb ? 'text-start' : 'text-right'
        }`"
      >
        <span class="bold opaque"
          >{{ $t("high") }}: {{ quoteDetails.high }}</span
        >
      </div>
    </div>
    <div class="volatility w-100 px-0 pb-0 pt-4">
      <h5 class="text-center m-0 p-0">{{ $t("hoursPriceRange") }}</h5>
      <apexchart
        class="w-100 apexchart"
        ref="chart"
        height="200px"
        :type="'line'"
        :options="options"
        :series="series"
      ></apexchart>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { getters } from "../store";
export default {
  data() {
    return {
      quoteDetails: {
        lastPrice: 0,
        low: 0,
        high: 0,
      },
      swapsList: [],
      options: {
        noDate: {
          text: "Loading chart...",
        },
        markers: {
          size: 5,
          colors: undefined,
          strokeColors: "#fff",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [],
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: true,
          hover: {
            size: 8,
            sizeOffset: 3,
          },
        },
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
          easing: "linear",
        },
        stroke: {
          curve: "smooth",
          width: 1,
        },
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          yAxisIndex: 0,
          type: "datetime",
          labels: {
            show: true,
            hideOverlappingLabels: true,
            showDuplicates: true,
            datetimeUTC: true,
            format: "HH:mm",
          },
          categories: [],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          x: {
            format: "HH:mm",
          },
        },
      },
      series: [
        {
          name: "Price",
          data: [],
        },
      ],
    };
  },
  created() {
    this.getSwaps();
  },
  computed: {
    selectedSymbol() {
      return getters.currentSymbol();
    },
    selectedSwap() {
      return this.swapsList.filter((swap) => {
        return swap.symbol === this.selectedSymbol.ticker;
      });
    },
    percentage() {
      const currentPrice = this.selectedSymbol.ask;
      const subs = currentPrice - this.quoteDetails.lastPrice;
      let result = (subs / currentPrice) * 100;
      return result.toFixed(2) ?? 0;
    },
    checkArb() {
      return getters.checkArb();
    },
    textEnd() {
      if (this.checkArb) {
        return "text-end";
      } else {
        return "";
      }
    },
  },
  watch: {
    selectedSymbol: {
      handler() {
        this.getQuote();
        this.getQuotes();
      },
    },
  },

  methods: {
    getSwaps() {
      axios.get(`${this.$apiURL()}/hub/swaps`).then(({ data }) => {
        this.swapsList = data.swapsList;
      });
      setTimeout(() => {
        this.getQuotes();
      }, 2000);
    },
    getQuotes() {
      axios
        .get(
          `${this.$apiURL()}/hub/quotes/hourly/${this.selectedSymbol.ticker}`
        )
        .then(({ data }) => {
          this.series[0].data = [];
          this.options.xaxis.categories = [];
          data.quotesList.map((quote) => {
            if (quote) {
              this.options.xaxis.categories.push(quote.timestamp);
              this.series[0].data.push(quote.hourlyQuotes[0].BID);
            }
          });
          this.$refs.chart.updateSeries([{ data: this.series[0].data }]);
          this.$refs.chart.updateOptions(this.options);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getQuote() {
      axios
        .get(`${this.$apiURL()}/hub/quotes/${this.selectedSymbol.ticker}`)
        .then(({ data }) => {
          this.quoteDetails = {
            lastPrice: data.quotesList[0].ASK,
            low: data.quotesList[0].LOW,
            high: data.quotesList[0].HIGH,
          };
        });
    },
  },
};
</script>
