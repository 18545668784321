export default {
  en: {
    partnerFormTitle: "Have a question?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">If you'd like to know more about our partners program, please email us on:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">or send us your message and one of our Affiliate Managers will get back to you soon.</p>`,
    partnerFormInputs: [
      {
        fullName: "Full Name",
        email: "Email",
        message: "Message",
        messageSent: "Your message was successfully sent",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone Number",
        department: "Choose Department",
        general: "General equiries",
        backOffice: "Back Office",
        support: "Support",
        financial: "Financial",
      },
    ],
  },
  fr: {
    partnerFormTitle: "Vous avez une question?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">Si vous souhaitez en savoir plus sur notre programme de partenariat, veuillez nous envoyer un e-mail à l'adresse:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">ou envoyez-nous votre message et l'un de nos responsables Partenaires vous répondra bientôt.</p>`,
    partnerFormInputs: [
      {
        fullName: "Nom Complet",
        email: "Email",
        message: "Message",
        messageSent: "Votre message a bien été envoyé",
        firstName: "Prénom",
        lastName: "Nom de Famille",
        phone: "Numéro de Téléphone",
        department: "Choisir le Service",
        general: "Général",
        backOffice: "Back Office",
        support: "Support Technique",
        financial: "Financier",
      },
    ],
  },
  it: {
    partnerFormTitle: "Hai domande?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">Se desideri saperne di più sul nostro programma partner, inviaci un'email a:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">oppure inviaci il tuo messaggio e uno dei nostri Affiliate Manager ti risponderà al più presto.</p>`,
    partnerFormInputs: [
      {
        fullName: "Nome Completo",
        email: "Email",
        message: "Messaggio",
        messageSent: "Il tuo messaggio è stato inviato correttamente",
        firstName: "Nome",
        lastName: "Cognome",
        phone: "Numero di Telefono",
        department: "Scegli il Servizio",
        general: "Domande di Carattere Generale",
        backOffice: "Back Office",
        support: "Supporto",
        financial: "Finanziario",
      },
    ],
  },
  de: {
    partnerFormTitle: "Haben Sie eine Frage?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">Wenn Sie gerne mehr über unser Partner-Programm erfahren möchten, dann schreiben Sie uns bitte eine E-Mail an:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">oder senden Sie uns eine Nachricht und einer unserer Partnermanager wird sich bei Ihnen in Kürze melden.</p>`,
    partnerFormInputs: [
      {
        fullName: "Vollständiger Name",
        email: "E-Mail",
        message: "Nachricht",
        messageSent: "Ihre Nachricht wurde erfolgreich gesendet",
        firstName: "Vorname",
        lastName: "Nachname",
        phone: "Telefonnummer",
        department: "Abteilung Auswählen",
        general: "Allgemeine Anfragen",
        backOffice: "Back Office",
        support: "Supporto",
        financial: "Finanzen",
      },
    ],
  },
  cn: {
    partnerFormTitle: "有一个问题？",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">如果您想了解有关我们合作伙伴计划的更多信息，请发送电子邮件至：</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">或将您的信息发送给我们，我们的客户经理将很快与您联系。</p>`,
    partnerFormInputs: [
      {
        fullName: "全名",
        email: "电子邮件",
        message: "信息",
        messageSent: "您的消息已成功发送",
        firstName: "名字",
        lastName: "姓名",
        phone: "电话号码",
        department: "选择部门",
        general: "一般咨询",
        backOffice: "后台",
        support: "支持",
        financial: "金融",
      },
    ],
  },
  es: {
    partnerFormTitle: "Tengo una pregunta?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">Si desea saber más acerca de nuestros socios programa, envíenos un correo electrónico a:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">o envíenos su mensaje y uno de nuestros Afiliados Los gerentes se pondrán en contacto con usted pronto.</p>`,
    partnerFormInputs: [
      {
        fullName: "Nombre Completo",
        email: "Correo Electrónico",
        message: "Mensaje",
        messageSent: "Su mensaje fue enviado con éxito",
        firstName: "Nombre",
        lastName: "Apellido",
        phone: "Número de Teléfono",
        department: "Elija Departamento",
        general: "Consultas Generales",
        backOffice: "Back Office",
        support: "Apoyo",
        financial: "Financiero",
      },
    ],
  },
  vnm: {
    partnerFormTitle: "Có một câu hỏi ?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">Nếu bạn muốn biết thêm về chương trình đối tác của chúng tôi, vui lòng gửi email cho chúng tôi theo địa chỉ:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">hoặc gửi cho chúng tôi tin nhắn của bạn và một trong những Người quản lý đơn vị liên kết của chúng tôi sẽ sớm liên hệ lại với bạn.</p>`,
    partnerFormInputs: [
      {
        fullName: "Họ và tên",
        email: "E-mail",
        message: "Tin nhắn",
        messageSent: "Tin nhắn của bạn đã được gửi thành công",
        firstName: "Tên",
        lastName: "Họ",
        phone: "Số điện thoại",
        department: "Chọn bộ phận",
        general: "Hỗ trợ chung",
        backOffice: "BVăn phòng hỗ trợ",
        support: "Hỗ trợ",
        financial: "Tài chính",
      },
    ],
  },
  enUae: {
    partnerFormTitle: "Have a question?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">If you'd like to know more about our partners program, please email us on:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">or send us your message and one of our Affiliate Managers will get back to you soon.</p>`,
    partnerFormInputs: [
      {
        fullName: "Full Name",
        email: "Email",
        message: "Message",
        messageSent: "Your message was successfully sent",
        firstName: "First Name",
        lastName: "Last Name",
        phone: "Phone Number",
        department: "Choose Department",
        general: "General equiries",
        backOffice: "Back Office",
        support: "Support",
        financial: "Financial",
      },
    ],
  },
  arb: {
    partnerFormTitle: "هل لديك سؤال؟",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">إذا كنت ترغب في معرفة المزيد عن برنامج الشركاء ، يرجى مراسلتنا عبر البريد الإلكتروني على:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">أو أرسل لنا رسالتك وسيقوم أحد المديرين التابعين لنا بالرد عليك قريبًا.</p>`,
    partnerFormInputs: [
      {
        fullName: "الاسم الكامل",
        email: "البريد الإلكتروني",
        message: "رسالة",
        messageSent: "تم إرسال رسالتك بنجاح",
        firstName: "الاسم الأول*",
        lastName: "الاسم الأخير*",
        phone: "رقم الهاتف*",
        department: "اختر القسم",
        general: "استفسارات عامة",
        backOffice: "مكتب back office",
        support: "الدعم",
        financial: "الأمور المالية",
      },
    ],
  },
  my: {
    partnerFormTitle: "Ada soalan ?",
    partnerFormText: (ctx) =>
      `<p class="text-center mb-0">Sekiranya anda ingin mengetahui lebih lanjut mengenai kami program rakan kongsi, sila hantarkan e-mel kepada kami di:</p><strong>${
        ctx.named("license") === "global"
          ? "info@triomarketsglobal.com"
          : "info@triomarkets.eu"
      }</strong><br><p class="text-center">atau hantarkan mesej anda dan salah satu daripada kami Pengurus Afiliasi akan menghubungi anda tidak lama lagi.</p>`,
    partnerFormInputs: [
      {
        fullName: "Nama penuh",
        email: "E-mel",
        message: "Mesej",
        messageSent: "Mesej anda berjaya dihantar",
        firstName: "Nama pertama",
        lastName: "Nama Belakang",
        phone: "Nombor telefon",
        department: "Pilih Jabatan",
        general: "Persamaan am",
        backOffice: "Pejabat Belakang",
        support: "Sokongan",
        financial: "Kewangan",
      },
    ],
  },
};
