import { createRouter, createWebHistory } from "vue-router";
import MenuPassThrough from "../views/MenuPassThrough.vue";

import Home from "../views/Home.vue";

import Forex from "../views/Forex.vue";
import Indices from "../views/Indices.vue";
import Metals from "../views/Metals.vue";
import Cryptos from "../views/Cryptos.vue";
import Energies from "../views/Energies.vue";
import Shares from "../views/Shares.vue";
import TradingConditions from "../views/TradingConditions.vue";

import AccountsOverview from "../views/AccountsOverview.vue";
import HNI from "../views/HNI.vue";
import ProTraders from "../views/ProTraders.vue";
import FundingWithdrawals from "../views/FundingWithdrawals.vue";
import SwapFree from "../views/SwapFree.vue";

import MetaTrader from "../views/MetaTrader.vue";
import TrioXtend from "../views/TrioXtend.vue";
import MobileApp from "../views/MobileApp.vue";
import MobileTrading from "../views/MobileTrading.vue";
import WebTrader from "../views/WebTrader.vue";
// import SocialTrading from "../views/SocialTrading.vue";
import FixAPI from "../views/FixAPI.vue";
import VPS from "../views/VPS.vue";
import MAM from "../views/MAM.vue";

import MeetUs from "../views/MeetUs.vue";
import SegregatedFunds from "../views/SegregatedFunds.vue";
import LicenseRegulation from "../views/LicenseRegulation.vue";
import LegalDocuments from "../views/LegalDocuments.vue";
import Kids from "../views/Kids.vue";
import Contact from "../views/Contact.vue";
import Glossary from "../views/Glossary.vue";
import FAQ from "../views/FAQ.vue";

import IntroducingBroker from "../views/IntroducingBroker.vue";
import CPA from "../views/CPA.vue";
import Promotions from "../views/Promotions.vue";

import PromotionPage from "../views/PromotionPage.vue";

// import Articles from "../views/Articles.vue";
// import MarketAnalysis from "../views/MarketAnalysis.vue";

import Article from "../views/Article.vue";
import Blog from "../views/Blog.vue";
import EconomicCalendar from "../views/EconomicCalendar.vue";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    redirect: `/${window.sessionStorage.getItem("language") ?? "en"}/`,
  },
  {
    path: "/:lang/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home",
      lang: "en",
    },
  },
  {
    path: "/:lang/promotionPage",
    name: "PromotionPage",
    component: PromotionPage,
    meta: {
      title: "PromotionPage",
    },
  },
  {
    path: "/:lang/markets",
    name: "Markets",
    component: MenuPassThrough,
    redirect: "/",
    children: [
      {
        path: "forex",
        name: "Forex",
        component: Forex,
        meta: { title: "Forex" },
      },
      {
        path: "indices",
        name: "Indices",
        component: Indices,
        meta: { title: "Indices" },
      },
      {
        path: "metals",
        name: "Metals",
        component: Metals,
        meta: { title: "Metals" },
      },
      {
        path: "cryptos",
        name: "Cryptos",
        component: Cryptos,
        meta: { title: "Cryptos" },
      },
      {
        path: "energies",
        name: "Energies",
        component: Energies,
        meta: { title: "Energies" },
      },
      {
        path: "shares",
        name: "Shares",
        component: Shares,
        meta: { title: "Shares" },
      },
      {
        path: "trading-conditions",
        name: "TradingConditions",
        component: TradingConditions,
        meta: { title: "TradingConditions", bg: "white" },
      },
    ],
  },
  {
    path: "/:lang/accounts",
    name: "Accounts",
    component: MenuPassThrough,
    redirect: "/",
    children: [
      {
        path: "accounts-overview",
        name: "AccountTypes",
        component: AccountsOverview,
        meta: { title: "Accounts Overview" },
      },
      {
        path: "hni",
        name: "HNI",
        component: HNI,
        meta: { title: "HNI & Institutional Trading" },
      },
      {
        path: "professional-traders",
        name: "ProTraders",
        component: ProTraders,
        meta: { title: "Professional Traders" },
      },
      {
        path: "funding-and-withdrawals",
        name: "FundingWithdrawals",
        component: FundingWithdrawals,
        meta: { title: "Funding and Withdrawals" },
      },
      {
        path: "swap-free",
        name: "SwapFree",
        component: SwapFree,
        meta: { title: "Swap Free" },
      },
    ],
  },
  {
    path: "/:lang/platforms",
    name: "Platforms",
    component: MenuPassThrough,
    redirect: "/",
    children: [
      {
        path: "meta-trader",
        name: "MetaTrader",
        component: MetaTrader,
        meta: { title: "Meta Trader 4" },
      },
      {
        path: "trio-xtend",
        name: "TrioXtend",
        component: TrioXtend,
        meta: { title: "MT4 TrioXtend" },
      },
      {
        path: "mobile-app",
        name: "MobileApp",
        component: MobileApp,
        meta: { title: "Mobile App" },
      },
      {
        path: "mobile-trading",
        name: "MobileTrading",
        component: MobileTrading,
        meta: { title: "Mobile Trading" },
      },
      {
        path: "web-trader",
        name: "WebTrader",
        component: WebTrader,
        meta: { title: "Web Trader" },
      },
      // {
      //   path: "social-trading",
      //   name: "SocialTrading",
      //   component: SocialTrading,
      //   meta: { title: "Social Trading" },
      // },
      {
        path: "fix-api",
        name: "FixAPI",
        component: FixAPI,
        meta: { title: "Fix API" },
      },
      {
        path: "vps",
        name: "VPS",
        component: VPS,
        meta: { title: "VPS" },
      },
      {
        path: "mam",
        name: "MAM",
        component: MAM,
        meta: { title: "MAM" },
      },
    ],
  },
  {
    path: "/:lang/company",
    name: "Company",
    component: MenuPassThrough,
    redirect: "/",
    children: [
      {
        path: "meet-us",
        name: "MeetUs",
        component: MeetUs,
        meta: { title: "Meet Us" },
      },
      {
        path: "segregated-funds",
        name: "SegregatedFunds",
        component: SegregatedFunds,
        meta: { title: "Segregated Funds" },
      },
      {
        path: "license-and-regulations",
        name: "LicenseRegulation",
        component: LicenseRegulation,
        meta: { title: "Licenses & Regulations" },
      },
      {
        path: "legal-documents",
        name: "LegalDocuments",
        component: LegalDocuments,
        meta: { title: "Legal Documents" },
      },
      {
        path: "kids",
        name: "KIDs",
        component: Kids,
        meta: { title: "KIDs Documents" },
      },
      {
        path: "contact",
        name: "Contact",
        component: Contact,
        meta: { title: "Contact" },
      },
      {
        path: "glossary",
        name: "Glossary",
        component: Glossary,
        meta: { title: "Glossary" },
      },
      {
        path: "faq",
        name: "FAQ",
        component: FAQ,
        meta: { title: "FAQ" },
      },
    ],
  },
  {
    path: "/:lang/partnerships",
    name: "Partnerships",
    component: MenuPassThrough,
    redirect: "/",
    children: [
      {
        path: "introducing-brokers",
        name: "IntroducingBroker",
        component: IntroducingBroker,
        meta: { title: "Introducing Broker" },
      },
      {
        path: "cpa-affiliates",
        name: "CPA",
        component: CPA,
        meta: { title: "CPA" },
      },
      {
        path: "promotions",
        name: "Promotions",
        component: Promotions,
        meta: { title: "Promotions" },
      },
    ],
  },
  {
    path: "/:lang/news",
    name: "News",
    component: MenuPassThrough,
    redirect: "/",
    children: [
      {
        path: "articles",
        name: "General Articles",
        component: Blog,
        meta: { title: "Articles", page: "articles", section: "General" },
      },
      {
        path: "marketAnalysis",
        name: "Market Analysis",
        component: Blog,
        meta: {
          title: "Market Analysis",
          page: "marketAnalysis",
          section: "Marketing",
        },
      },
      {
        path: "economicCalendar",
        name: "Economic Calendar",
        component: EconomicCalendar,
        meta: {
          title: "Economic Calendar",
        },
      },
      {
        path: ":page/article/:title",
        name: "Article",
        component: Article,
        meta: { title: "Article", bg: "white" },
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
