<template>
  <div v-for="(item, idx) in $tm('hniInfo')" :key="idx">
    <div class="container-fluid container-top accounts">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ item.title }}
          </h2>
          <hr class="gold mbhr" />
          <p class="col-12 text-center text-gold fs-5 fw-bold">
            {{ item.text }}
          </p>
          <div
            class="col-lg-10 col-12 text-center text-white"
            v-html="item.textTwo"
          ></div>
          <div class="col-lg-10 col-12 d-flex justify-content-center">
            <div class="row w-100 my-lg-5 my-3">
              <div
                v-for="(point, idx) in item.points"
                :key="idx"
                class="col-lg-4 points d-flex align-items-center justify-content-center"
              >
                <img
                  src="/img/check.svg"
                  class="img-fluid check me-lg-3 mb-lg-0 mb-2 mt-lg-0 mt-3"
                  alt="check icon"
                />
                <p class="text-white mb-lg-0 fs-5">{{ point.text }}</p>
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <a target="_blank" :href="registerUR" class="btn btn-blue">{{
              $t("openInstitutionalAccount")
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 title text-center">{{ item.titleTwo }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-lg-10 col-12" v-html="item.textTree"></div>
        <div class="col-lg-10 offset-lg-1">
          <div class="row d-flex justify-content-center my-5">
            <div
              v-for="(point, idx) in item.pointsTwo"
              :key="idx"
              class="col-md-6 col-sm-8 col-10 mb-lg-2 mb-4"
            >
              <div class="feature-box hni d-flex align-items-center">
                <img
                  src="/img/check.svg"
                  class="img-fluid check me-lg-3 mb-lg-0 mb-2 mt-lg-0 mt-3"
                  alt="check icon"
                />
                <p class="mb-lg-0 fs-5" v-html="point.text"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "HNI",
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
};
</script>
<style lang="scss" scoped></style>
