<template>
  <div>
    <MarketsTop
      :market="{ video: 'forex', title: $t('forex'), desc: $t('forexDesc') }"
    />
    <div class="container-fluid">
      <div class="row d-flex justify-content-center mt-5">
        <div class="col-xl-9 col-12">
          <div class="row d-flex justify-content-center">
            <div class="col-xl-8 col-11 px-sm-3 px-0">
              <Quotes
                v-slidein
                :symbols="[
                  'EURUSD',
                  'GBPUSD',
                  'AUDUSD',
                  'USDJPY',
                  'EURGBP',
                  'USDCHF',
                  'NZDUSD',
                ]"
                :product="{ popular: $t('forexPopular') }"
              />
            </div>
            <div class="col-xl-4 col-11">
              <QuoteDetails v-slideinright />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-for="(item, idx) in $tm('forexInfo')" :key="idx" class="container">
      <div class="row d-flex justify-content-center my-5">
        <h2 class="col-12 title text-center">{{ item.moreInfo }}</h2>
        <p class="fw-bold text-center fs-6">{{ item.why }}</p>
        <hr class="blue m-0" />
      </div>
      <div class="row d-flex justify-content-center">
        <div
          v-for="(desc, idx) in item.points"
          :key="idx"
          v-scrollanimation
          class="
            col-md-4
            mb-lg-0 mb-4
            text-center
            d-flex
            flex-column
            align-items-center
          "
        >
          <img
            :src="`/img/markets/forex/${desc.img}.svg`"
            class="img-fluid icon mb-3"
            alt="check icon"
          />
          <p>{{ desc.text }}</p>
        </div>
        <div class="col-12 text-center my-5" v-html="item.text"></div>
        <div
          v-for="(desc, idx) in item.pointsTwo"
          :key="idx"
          v-scrollanimation
          class="
            col-lg-4 col-sm-10 col-12
            mb-5
            d-flex
            flex-column
            align-items-center
            text-center
            mb-5
          "
        >
          <div class="forex-box boxes-forex position-relative">
            <h3 class="text-white">{{ desc.title }}</h3>
            <p class="text-white">{{ desc.text }}</p>
          </div>
        </div>
        <div
          v-for="(desc, idx) in item.pointsThree"
          :key="idx"
          class="col-10 d-flex flex-column align-items-center text-center"
        >
          <p v-html="desc.text" class="mb-1"></p>
        </div>
        <div class="col-12 text-center my-5">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{
            $t("tradeNow")
          }}</a>
        </div>
      </div>
    </div>
    <Accounts />
    <Platforms v-scrollanimation />
    <Steps stepStyle="dark" v-scrollanimation />
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import MarketsTop from "../components/MarketsTop.vue";
import Quotes from "../components/Quotes.vue";
import QuoteDetails from "../components/QuoteDetails.vue";
export default {
  name: "Forex",
  components: {
    MarketsTop,
    Quotes,
    QuoteDetails,
  },
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
  mounted() {
    mutations.setBoxHeight("boxes-forex","2");
    window.onresize = () => {
      mutations.setBoxHeight("boxes-forex","2");
    };
  },
};
</script>
