<template>
  <div class="maincontainer">
    <Slider id="slider" class="d-md-block d-none" />
    <div :class="`container-fluid position-relative`" id="homeMobile">
      <div class="row">
        <div
          class="col-12 px-5 text"
          v-for="(slide, idx) in mobileFilter"
          :key="idx"
        >
          <h2 class="text-center text-gold mb-4" v-html="slide.title"></h2>
          <div
            v-for="(desc, idx) in slide.desc"
            :key="idx"
            class="d-flex flex-row justify-content-center mb-2 ltr"
          >
            <picture
              class="col-sm-4 col-2 px-0 d-flex justify-content-end align-items-center"
            >
              <img
                src="/img/check.svg"
                alt="check"
                class="img-fluid me-3 check"
              />
            </picture>
            <p class="col-sm-8 col-10 text-start px-0 mb-0 fs-5 text-white">
              {{ desc.text }}
            </p>
          </div>
          <div class="text-center">
            <a target="_blank" :href="registerURL" class="btn btn-blue mt-4">{{
              $t("openAccount")
            }}</a>
          </div>
        </div>
        <div class="homeMobile-img position-absolute px-0">
          <img
            src="/img/fingerprint-mobile.png"
            class="img-fluid"
            alt="triomarkets"
          />
        </div>
      </div>
    </div>
    <!-- <div
      :class="`container  AppText text-center ${
        checkArb ? 'flip-content' : ''
      }`"
      v-scrollanimation
    >
      <div class="row d-flex justify-content-center mt-5 text-center">
        <h2 class="text-center">{{ $t("TitleApp") }}</h2>
        <hr class="blue" />
      </div>
      <div class="d-flex">
        <div class="position-relative">
          <div
            :class="` posoition-absolute d-flex top-50   ${
              checkArb ? 'start-0' : 'end-0'
            }`"
          >
            <img
              src="/img/MobileS.png"
              class="img-fluid d-none d-md-block"
              alt="Show App"
              id="mobileImg"
            />
          </div>
        </div>
        <div class="col-md-7 text-center justify-content-center">
          <div class="p-5 fs-5">
            {{ $t("AppText") }}
          </div>
          <div class="row d-flex p-3 text-center justify-content-center">
            <h5 :class="`justify-content-center  ${checkArb ? ' ' : ' '}`">
              {{ $t("Download") }}
            </h5>
            <div class="col-lg-6 col-md-8">
              <div class="row d-flex justify-content-center">
                <div class="col-6 mb-sm-0 mb-3 text-center">
                  <a target="_blank" href="/" class="">
                    <img
                      src="/img/platforms/MobileApp/app-store.svg"
                      alt="download-apple"
                      class="img-fluid"
                    />
                  </a>
                </div>
                <div class="col-6 mb-sm-0 mb-4 text-center">
                  <a target="_blank" href="/" class="">
                    <img
                      src="/img/platforms/MobileApp/google-play.svg"
                      alt="download-play-store"
                      class="img-fluid"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div id="essentials" class="container-fluid" v-scrollanimation>
      <div class="container">
        <div class="row d-flex justify-content-center my-5">
          <h2 class="col-12 title text-center">
            {{ $t("essentialsForTrading") }}
          </h2>
          <hr class="blue" />
        </div>
        <div class="row">
          <div
            v-for="(essential, idx) in $tm('essentials').filter((item) =>
              item.show.includes(this.license)
            )"
            :key="idx"
            class="col-md-3 col-6 d-flex flex-column justify-content-center align-items-center text-center mb-md-5 mb-4"
          >
            <img
              :src="`/img/essentials/${essential.img}.svg`"
              class="img-fluid icon"
              alt="essential-icon"
            />
            <span v-html="essential.name" class="p-2 fs-5"></span>
          </div>
        </div>
      </div>
    </div>
    <div
      id="platformsHome"
      class="container-fluid d-flex align-items-center position-relative"
      v-scrollanimation
    >
      <video v-if="!checkArb" autoplay muted loop class="d-none d-xl-block">
        <source
          id="videoPhone"
          src="/img/video/iphoneVideo.mp4"
          type="video/mp4"
        />
      </video>
      <video v-if="checkArb" autoplay muted loop class="d-none d-xl-block">
        <source
          id="videoPhone"
          src="/img/video/iphoneHomeLgArb.mp4"
          type="video/mp4"
        />
      </video>
      <video
        v-if="!checkArb"
        autoplay
        muted
        loop
        class="d-none d-lg-block d-xl-none"
      >
        <source
          id="videoPhoneSmaller"
          src="/img/video/iphone-home-lg.mp4"
          type="video/mp4"
        />
      </video>
      <video
        v-if="checkArb"
        autoplay
        muted
        loop
        class="d-none d-lg-block d-xl-none"
      >
        <source
          id="videoPhoneSmaller"
          src="/img/video/iphoneHomeMdArb.mp4"
          type="video/mp4"
        />
      </video>
      <div class="container">
        <div class="row d-flex justify-content-center my-5">
          <h2 class="col-12 title text-center">
            {{ $t("platformsTitle") }}
          </h2>
          <hr class="blue" />
        </div>
        <div class="row platform-set pt-lg-5 pt-3">
          <div class="col-lg-4 col-md-6 col-10">
            <div
              class="platform pe-md-4 pe-0 pe-lg-0"
              v-for="(platform, idx) in platformsLeft"
              v-slidein
              :key="idx"
            >
              <h3 :class="`fw-bold ${textEnd}`">
                {{ platform.name }}
              </h3>
              <p :class="`fs-5 lh-sm  ${textEnd}`">
                {{ platform.desc }}
              </p>
              <router-link
                :to="`/${$route.params.lang}/platforms/${platform.link}`"
                class="btn btn-blue"
                >{{ $t("learnMore") }}</router-link
              >
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-10 text-start platform">
            <div
              class="platform ps-md-4 ps-0 ps-lg-0"
              v-slideinright
              v-for="(platform, idx) in platformsRight"
              :key="idx"
            >
              <h3 :class="`fw-bold ${textEnd}`">
                {{ platform.name }}
              </h3>
              <p :class="`fs-5 lh-sm ${textEnd}`">
                {{ platform.desc }}
              </p>
              <router-link
                :to="`/${$route.params.lang}/platforms/${platform.link}`"
                class="btn btn-blue"
                >{{ $t("learnMore") }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="qoutes" class="container-fluid" v-scrollanimation>
      <div class="row d-flex justify-content-center">
        <div class="col-xl-9 col-12">
          <div class="row d-flex justify-content-around">
            <div class="col-4 d-none d-lg-block top-trading">
              <h2
                :class="`title mb-4 ${textEnd}`"
                v-html="$t('topTradingEnvironment')"
              ></h2>
              <div
                v-slidein
                v-for="(point, idx) in $tm('topTradingEnvironmentPoints')"
                :key="idx"
                class="d-flex align-items-center text-start my-5"
              >
                <img
                  src="/img/check.svg"
                  :class="`img-fluid  ${checkArb ? 'ms-3' : 'me-3'}  check `"
                  alt="check"
                />
                <p v-html="point.name" class="m-0 fs-5"></p>
              </div>
            </div>
            <div class="col-lg-8 col-md-10">
              <LiveQuotes />
            </div>
          </div>
        </div>
      </div>
    </div>
    <HomeMarkets v-scrollanimation />
    <div id="threeSteps" class="container-fluid mb-5" v-scrollanimation>
      <div class="container">
        <div class="row d-flex justify-content-center my-5">
          <h2 class="col-12 title text-center">{{ $t("stepsForTrading") }}</h2>
          <hr class="blue" />
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-xl-8 col-lg-9 col-12">
            <div class="row">
              <div
                v-for="(step, idx) in $tm('stepsForTradingPoints')"
                :key="idx"
                class="col-sm-4 d-flex flex-column align-items-center justify-items-center"
              >
                <img
                  :src="`/img/steps/${step.img}.svg`"
                  :alt="step.title"
                  class="icon my-2"
                />
                <span class="text-center fs-4 fw-bold">{{ step.title }}</span>
                <p class="text-center fs-5" v-html="step.desc"></p>
              </div>
            </div>
          </div>
          <div class="col-12 d-none d-sm-block mt-4 text-center">
            <img
              src="/img/steps/iphone.png"
              class="img-fluid"
              alt="iphone image"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
import Slider from "../components/Slider.vue";
import LiveQuotes from "../components/LiveQuotes.vue";
import HomeMarkets from "../components/HomeMarkets.vue";
// import LatestNews from "../components/LatestNews.vue";
import renderMeta from "../SEO";
export default {
  name: "Home",
  metaInfo() {
    return renderMeta(
      "Home",
      `Triomarkets is about forex in your field of interest. Our vision is to help people share their knowledge, 
      work, projects, papers and ideas and build their network through what they do rather where they live, study or work.`
    );
  },
  data: () => ({}),
  components: {
    Slider,
    LiveQuotes,
    HomeMarkets,
    // LatestNews,
  },
  computed: {
    license() {
      return getters.license();
    },
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
    selectedLang() {
      return getters.selectedLang();
    },
    textEnd() {
      if (this.checkArb) {
        return "text-end";
      } else {
        return "";
      }
    },
    checkUae() {
      return getters.checkUae();
    },
    mobileFilter() {
      return this.$tm("slides").filter((slide) => {
        return slide.license === "homeMobile";
      });
    },

    platformsLeft() {
      return this.$tm("platform").filter((platform) => {
        return platform.homePosition !== "right";
      });
    },
    platformsRight() {
      return this.$tm("platform").filter((platform) => {
        return platform.homePosition !== "left";
      });
    },
    mobileDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
