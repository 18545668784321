export default {
  en: {
    stepsForTrading: `3 Steps for Trading`,
    stepsForTradingPoints: [
      {
        title: `Register`,
        desc: `Create your account`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Verify`,
        desc: `Verify your identity`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Trade`,
        desc: `Fund & Trade`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 Steps to Join Social Trading`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Get to Know" },
      { img: "make-choice", name: "Make a Choice" },
      { img: "start-invest", name: "Start to Invest" },
    ],
    stepsEarnings: `3 Steps to Start Earnings`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "Sign Up",
        text: "Create your partner account & get access to a unique tracking link",
      },
      {
        img: "make-choice",
        name: "Refer",
        text: "Refer clients using our marketing materials such as banners & landing pages",
      },
      {
        img: "start-invest",
        name: "Earn",
        text: "Earn your CPA up to $1000 per client referred",
      },
    ],
  },
  fr: {
    stepsForTrading: `3 Étapes pour Trader`,
    stepsForTradingPoints: [
      {
        title: `S'inscrire`,
        desc: `Créez votre compte`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Vérifier`,
        desc: `Vérifiez votre identité`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Trader`,
        desc: `Déposez & Tradez`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 Étapes pour Rejoindre le Trading Social`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Faites Connaissance" },
      { img: "make-choice", name: "Faites un Choix" },
      { img: "start-invest", name: "Commencez à Investir" },
    ],
    stepsEarnings: `Générer en 3 Étapes`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "S'Inscrire",
        text: "Créez votre compte partenaire et accédez à un lien de référencement",
      },
      {
        img: "make-choice",
        name: "Référer",
        text: `Référez des clients à l'aide de nos outils marketing tels que des bannières et landing pages`,
      },
      {
        img: "start-invest",
        name: "Gagner",
        text: `Jusqu'à $1000 CPA par client référé`,
      },
    ],
  },
  it: {
    stepsForTrading: `3 Passaggi per il Trading`,
    stepsForTradingPoints: [
      {
        title: `Registrati`,
        desc: `Crea il tuo conto`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Verifica`,
        desc: `Verificare la propria identità`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Negozia`,
        desc: `Deposita e Negozia`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 Passaggi per Partecipare al Social Trading`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Impara a Conoscere" },
      { img: "make-choice", name: "Fai una Scelta" },
      { img: "start-invest", name: "Inizia a Investire" },
    ],
    stepsEarnings: `3 Passaggi per Iniziare a Guadagnare`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "Iscriviti",
        text: "Crea il tuo account partner e accedi a un link di riferimento",
      },
      {
        img: "make-choice",
        name: "Riferimento",
        text: "Invita i clienti utilizzando i nostri materiali di marketing come banner e pagine di destinazione",
      },
      {
        img: "start-invest",
        name: "Guadagna",
        text: "Guadagna il tuo CPA fino a $ 1000 per cliente segnalato",
      },
    ],
  },
  de: {
    stepsForTrading: `3 Schritte zum Traden`,
    stepsForTradingPoints: [
      {
        title: `Registrieren`,
        desc: `Konto erstellen`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Verifizieren`,
        desc: `Überprüfen Sie Ihre Identität`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Traden`,
        desc: `Einzahlen & Traden`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `In 3 Schritten zum Social Trading`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Entdecken" },
      { img: "make-choice", name: "Auswählen" },
      { img: "start-invest", name: "Investieren" },
    ],
    stepsEarnings: `3 Schritte, um Geld zu Verdienen`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "Sich registrieren",
        text: "Partnerkonto eröffnen & Zugang zu einem einmaligen Trading-Link erhalten",
      },
      {
        img: "make-choice",
        name: "Empfehlen",
        text: "Empfehlen Sie Kunden mit unseren Marketingmaterialien wie Bannern und Landing Pages",
      },
      {
        img: "start-invest",
        name: "Verdienen",
        text: "Verdienen Sie bis zu $1000 CPA pro empfohlenem Kunden",
      },
    ],
  },
  cn: {
    stepsForTrading: `交易的3个步骤`,
    stepsForTradingPoints: [
      {
        title: `注册`,
        desc: `创建您的帐户`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `验证`,
        desc: `验证您的身份`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `交易`,
        desc: `入金与交易`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `加入社交交易的3个步骤`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "了解" },
      { img: "make-choice", name: "选择" },
      { img: "start-invest", name: "开始投资" },
    ],
    stepsEarnings: `开始盈利的3个步骤`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "注册",
        text: "创建您的合作伙伴帐户并获取您的专属推荐链接",
      },
      {
        img: "make-choice",
        name: "推荐",
        text: "使用我们的营销材料(例如横幅和登录页面)来推荐客户",
      },
      {
        img: "start-invest",
        name: "赚取佣金",
        text: "赚取CPA推荐成功账户佣金, 可获得高达1000美元的推荐费",
      },
    ],
  },
  es: {
    stepsForTrading: `3 Pasos para Operar`,
    stepsForTradingPoints: [
      {
        title: `Registrarse`,
        desc: `Crea tu Cuenta`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Verificar`,
        desc: `Verifique su identidad`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Trade`,
        desc: `Deposita y Trade`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 Pasos para Unirse al Trading Social`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Llegar a Saber" },
      { img: "make-choice", name: "Tomar una Decisión" },
      { img: "start-invest", name: "Comience a Invertir" },
    ],
    stepsEarnings: `3 Pasos para Empezar a Ganar`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "Inscribirse",
        text: "Cree su cuenta de socio y obtenga acceso a un enlace de seguimiento único",
      },
      {
        img: "make-choice",
        name: "Referir",
        text: "Recomiende clientes usando nuestro marketing materiales como banners y páginas de destino",
      },
      {
        img: "start-invest",
        name: "Ganar",
        text: "Gana tu CPA hasta $1000 por cliente referido",
      },
    ],
  },
  vnm: {
    stepsForTrading: `3 bước giao dịch`,
    stepsForTradingPoints: [
      {
        title: `Đăng ký`,
        desc: `Tạo tài khoản của bạn`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Xác minh`,
        desc: `Xác thực email của bạn`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Giao dịch`,
        desc: `Quỹ & Thương mại`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 bước để tham gia giao dịch xã hội`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Tìm hiểu" },
      { img: "make-choice", name: "Lựa chọn" },
      { img: "start-invest", name: "Bắt đầu đầu tư" },
    ],
    stepsEarnings: `3 bước để bắt đầu thu nhập`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "Đăng ký",
        text: "Tạo tài khoản đối tác của bạn & có quyền truy cập vào một liên kết theo dõi duy nhất",
      },
      {
        img: "make-choice",
        name: "Tham khảo",
        text: "Giới thiệu khách hàng bằng cách sử dụng tài liệu tiếp thị như biểu ngữ và trang đích",
      },
      {
        img: "start-invest",
        name: "Kiếm tiền",
        text: "Kiếm CPA của bạn lên đến $ 1000 mỗi khách hàng được giới thiệu",
      },
    ],
  },
  enUae: {
    stepsForTrading: `3 Steps for Trading`,
    stepsForTradingPoints: [
      {
        title: `Register`,
        desc: `Create your account`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Verify`,
        desc: `Verify your identity`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Trade`,
        desc: `Fund & Trade`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 Steps to Join Social Trading`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Get to Know" },
      { img: "make-choice", name: "Make a Choice" },
      { img: "start-invest", name: "Start to Invest" },
    ],
    stepsEarnings: `3 Steps to Start Earnings`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "Sign Up",
        text: "Create your partner account & get access to a unique tracking link",
      },
      {
        img: "make-choice",
        name: "Refer",
        text: "Refer clients using our marketing materials such as banners & landing pages",
      },
      {
        img: "start-invest",
        name: "Earn",
        text: "Earn your CPA up to $1000 per client referred",
      },
    ],
  },
  arb: {
    stepsForTrading: ` 3 خطوات للتداول`,
    stepsForTradingPoints: [
      {
        title: `سجل`,
        desc: `أنشئ حسابك`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `أكّد بريدك الإلكتروني`,
        desc: `تحقق من صحة بريدك الإلكتروني`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `تداول`,
        desc: `موّل حسابك وابدأ التداول`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 خطوات للانضمام الى التداول الاجتماعي`,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "تعرف على" },
      { img: "make-choice", name: "قم بالاختيار" },
      { img: "start-invest", name: "ابدأ بالاستثمار" },
    ],
    stepsEarnings: `3 خطوات لبدء عملية تحقيق الأرباح`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "سجل",
        text: "أنشئ حساب الشريك الخاص بك واحصل على وصول إلى رابط تتبع فريد",
      },
      {
        img: "make-choice",
        name: "أشير",
        text: "قم بإحالة العملاء باستخدام موادنا التسويقية مثل اللافتات والصفحات المقصودة",
      },
      {
        img: "start-invest",
        name: "اكسب",
        text: "اربح تكلفة الاكتساب حتى 1000 دولار لكل عميل محال",
      },
    ],
  },
  my: {
    stepsForTrading: `3 Langkah untuk Berdagang`,
    stepsForTradingPoints: [
      {
        title: `Daftar`,
        desc: `Buat akaun anda`,
        img: `register`,
        imgDark: `register-gold`,
      },
      {
        title: `Sahkan`,
        desc: `Sahkan e-mel anda`,
        img: `verify`,
        imgDark: `verify-gold`,
      },
      {
        title: `Perdagangan`,
        desc: `Dana & Perdagangan`,
        img: `trade`,
        imgDark: `trade-gold`,
      },
    ],
    stepsSocialTrading: `3 Langkah untuk Bergabung dengan Perdagangan Sosial    `,
    stepsSocialTradingPoints: [
      { img: "get-know", name: "Kenali" },
      { img: "make-choice", name: "Buat Pilihan" },
      { img: "start-invest", name: "Mula Melabur" },
    ],
    stepsEarnings: `3 Langkah Memulakan Pendapatan`,
    stepsEarningsPoints: [
      {
        img: "get-know",
        name: "Daftar",
        text: "Buat akaun rakan kongsi anda & dapatkan akses ke pautan penjejakan yang unik",
      },
      {
        img: "make-choice",
        name: "Rujuk",
        text: "Rujuk pelanggan menggunakan kami bahan pemasaran seperti sepanduk & halaman arahan",
      },
      {
        img: "start-invest",
        name: "Dapatkan",
        text: "Hasilkan CPA anda hingga $ 1000 per pelanggan dirujuk",
      },
    ],
  },
};
