<template>
  <div id="glossary">
    <div class="container-fluid container-top accounts glossary">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("glossary") }}
          </h2>
          <hr class="gold" />
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-3 col-md-7 col-sm-10 search">
            <input
              type="text"
              v-model="search"
              name="search"
              placeholder="Search"
              class="text-center"
            />
            <img src="/img/search.svg" alt="Search Glossary" />
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-12">
            <Carousel
              :settings="settings"
              :breakpoints="breakpoints"
              class="glossary"
            >
              <slide v-for="(slide, idx) in glossary" :key="idx">
                <div
                  :class="slide.active ? 'letter letter-active' : 'letter'"
                  @click="activateLetter(slide), (currentLetter = idx)"
                >
                  <p class="text-white pointer fs-5 pt-3">{{ slide.letter }}</p>
                </div>
              </slide>
              <template #addons>
                <Navigation />
              </template>
            </Carousel>
          </div>
        </div>
        <div class="row pt-4 pb-2 glossary-items">
          <div
            v-for="(item, idx) in wordSearch"
            :key="idx"
            class="col-md-4 col-sm-6"
          >
            <p
              @click="currentItem = idx"
              :class="`pointer text-white fs-5 ${
                currentItem == idx ? 'active' : ''
              }`"
            >
              {{ item.name }}
            </p>
          </div>
        </div>
        <div class="row border-top py-4">
          <div class="col-12">
            <p class="text-white text-justify fs-5" v-if="currentItem != null">
              {{ glossary[currentLetter].items[currentItem].desc }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
export default {
  name: "Glossary",
  data: () => ({
    currentLetter: 0,
    currentItem: null,
    search: "",
    breakpoints: {
      992: {
        itemsToShow: 26,
        itemsToScroll: 0,
        snapAlign: "start",
        mouseDrag: false,
      },
      768: {
        itemsToShow: 16,
        itemsToScroll: 3,
        snapAlign: "start",
      },
      574: {
        itemsToShow: 10,
        itemsToScroll: 3,
        snapAlign: "start",
      },
      400: {
        itemsToShow: 7,
        itemsToScroll: 2,
        snapAlign: "start",
      },
      350: {
        itemsToShow: 5,
        itemsToScroll: 1,
        snapAlign: "start",
      },
    },
    settings: {
      snapAlign: "start",
      // wrapAround: true,
    },
    glossary: [
      {
        active: true,
        letter: "A",
        items: [
          {
            name: "Aussie",
            desc: "The “Ozzie”; refers to the AUD/USD pair.",
          },
          {
            name: "AUS 200",
            desc: "This is the name of the Australian Securities Exchange or S&P/ASX 200. It is an index of 200 Australian companies.",
          },
          {
            name: "Analyst",
            desc: "Is a professional in the financial industry who analyzes data, investments and financial instruments. He then gives clients buy, sell or hold recommendations.",
          },
          {
            name: "Arbitrage",
            desc: "This is when you buy or sell a financial instrument to take advantage of small price movements between markets.",
          },
          {
            name: "At or better",
            desc: "Trade order to sell or buy at a certain price or better.",
          },
          {
            name: "Asian session",
            desc: "23:00 – 08:00 GMT (Tokyo).",
          },
          {
            name: "Adjustment",
            desc: "This is an action by a country’s central bank towards economic policy in order to correct a payment imbalance or its foreign currency exchange rate.",
          },
          {
            name: "At best",
            desc: "A trading instruction to sell or buy at the best possible price.",
          },
          {
            name: "Apreciation",
            desc: "Price appreciates when it strengthens or rises in value.",
          },
          {
            name: "Accrual",
            desc: "On a balance sheet, it represents liabilities as well as non-cash assets. It is used in accrual based accounting. Account types include accounts payable, future tax liability and future interest rate expenses In the Forex world, it is a premium or discount regarding forward exchange transactions. This is in relation to deposit swap deals (also known as interest arbitrage).",
          },
        ],
      },
      {
        active: false,
        letter: "B",
        items: [
          {
            name: "Buy",
            desc: "Traders who expect the price of a financial instrument or market to rise. They typically hold long positions.",
          },
          {
            name: "Bullish / Bull market",
            desc: "This is when there are positive trends on the markets, and when people are buying.",
          },
          {
            name: "Book",
            desc: "Represents a trades total position opened and closed. This is a history of all of the trader’s trades.",
          },
          {
            name: "BOJ",
            desc: "Bank of Japan, the central bank of Japan.",
          },
          {
            name: "Bundesbank",
            desc: "Traders who expect the price of a financial instrument or market to rise. They typically hold long positions.",
          },
          {
            name: "Buck",
            desc: "Market slang for 1 million units of a dollar-based currency pair or for the US dollar in general.",
          },
          {
            name: "Bond",
            desc: "A debt instrument sold by government, municipalities and corporations over specific periods of time to raise money for capital projects.",
          },
          {
            name: "Bulls",
            desc: "Also known as margin. Definition: Traders who expect the price of a financial instrument or market to rise. They typically hold long positions.",
          },
          {
            name: "Broker / Dealer",
            desc: "This is a firm, or individual who acts as an intermediary to bring sellers and buyers together. A broker works for a fee or a commission. A dealer commits his own capital (money) to make trades. He hopes to make a profit by closing the position when he sells it to another party.",
          },
          {
            name: "Bollinger bands",
            desc: "This is a technical analysis tool that portrays a two standard deviations on either side of a simple moving average. This is used to help calculate resistance and support levels.",
          },
        ],
      },
      {
        active: false,
        letter: "C",
        items: [
          {
            name: "Currency symbols",
            desc: "A three-letter symbol that represents a specific currency. A good example is the US Dollar (USD).",
          },
          {
            name: "Crown currencies",
            desc: "These are: AD (Canadian Dollar), Aussie (Australian Dollar), Sterling (British Pound) and Kiwi (New Zealand Dollar) – countries off the British Commonwealth.",
          },
          {
            name: "CPI",
            desc: "Consumer Price Index. Measures inflation on the consumer level.",
          },
          {
            name: "Convergence of MAs",
            desc: "A technical condition that describes moving averages for different time periods moving towards each other. This tends to forecast price consolidation.",
          },
          {
            name: "Currency risk",
            desc: "The chances of an adverse change in Forex rates.",
          },
          {
            name: "Cross",
            desc: "A pair of currencies that does not include the USD like the GBP/JPY or EUR/GBP.",
          },
          {
            name: "Counterparty",
            desc: "One of the participants in a financial transaction.",
          },
          {
            name: "Currency pair",
            desc: "The two currencies that make up Forex. For example the EUR/USD or GP/USD.",
          },
          {
            name: "Crater",
            desc: "A financial market that is ready to sell-off hard.",
          },
          {
            name: "Counter currency",
            desc: "The second listed currency in a currency pair. In the EUR/USD, the USD is the counter currency.",
          },
        ],
      },
      {
        active: false,
        letter: "D",
        items: [
          {
            name: "DXY$Y",
            desc: "Symbol for US Dollar Index.",
          },
          {
            name: "DJIA or Dow",
            desc: "Abbreviation for the Dow Jones Industrial Average or US30.",
          },
          {
            name: "Divergence",
            desc: "In technical analysis, a situation where price and momentum move in opposite directions, such as prices rising while momentum is falling. Divergence is considered either positive (bullish) or negative (bearish); both kinds of divergence signal major shifts in price direction. Positive/bullish divergence occurs when the price of a security makes a new low while the momentum indicator starts to climb upward. Negative/bearish divergence happens when the price of the security makes a new high, but the indicator fails to do the same and instead moves lower. Divergences frequently occur in extended price moves and frequently resolve with the price reversing direction to follow the momentum indicator.",
          },
          {
            name: "Derivative",
            desc: "A financial contract whose value is based on the value of an underlying asset.",
          },
          {
            name: "Downtrend",
            desc: "Price action consisting of lower-lows and lower-highs.",
          },
          {
            name: "Dividend",
            desc: "The amount of a company’s earning distributed to its shareholders.",
          },
          {
            name: "Discount rate",
            desc: "Interest rate that an eligible depository institution is charged. This is charged when the institution borrows short-term funds directly from the central bank of a country.",
          },
          {
            name: "Dove",
            desc: "Dovish refers to data or a policy view that suggests easier monetary policy.",
          },
          {
            name: "Divergence of Moving Averages (MA)",
            desc: "A technical condition that describes moving averages moving in the opposite direction from each other. This is used to help forecast a price trend.",
          },
          {
            name: "Devaluation",
            desc: "When a pegged currency is allowed to weaken or depreciate due to fiscal policy and actions taken by the central bank or government.",
          },
        ],
      },
      {
        active: false,
        letter: "E",
        items: [
          {
            name: "Extended",
            desc: "A financial market that is thought to have moved too far, too fast up or down.",
          },
          {
            name: "EX-dividend",
            desc: "A share bought where the buyer forgoes the right to receive the next dividend scheduled. Instead, the seller of the instrument will receive the dividend. Expert Advisors are programs that allow automation of analytical and trading processes in the MT4 platform.",
          },
          {
            name: "EURO",
            desc: "The currency of the Eurozone.",
          },
          {
            name: "End of day order (EOD)",
            desc: "An order to buy or sell at a specified price. This position will close at the end of the business dat. Typically at 1700 EST.",
          },
          {
            name: "Exporters",
            desc: "Corporations who sell goods internationally. These make them sellers of commodity currencies like the USD/JPY or the AUD/USD.",
          },
          {
            name: "European session",
            desc: "07:00 – 16:00 (London time).",
          },
          {
            name: "Equity",
            desc: "Equity is the the value of the shares issued by a company.",
          },
          {
            name: "Expiry date and price",
            desc: "The precise date and time when an option will expire (Expires 1000 ET, 1500 Tokyo time). These time periods frequently see an increase in trading volumes as hedge funds begin to unwind in the spot market and shift positions held.",
          },
          {
            name: "European Monetary Union (EMU)",
            desc: "The official name for the group countries under the currency block called the EU to enact policies that aims to coordinate economic and fiscal strategy.",
          },
          {
            name: "ESTX50",
            desc: "A name for the Euronext 50 index.",
          },
        ],
      },
      {
        active: false,
        letter: "F",
        items: [
          {
            name: "FX",
            desc: "Foreign Exchange.",
          },
          {
            name: "Funds",
            desc: "Refers to hedge funds active in financial market.",
          },
          {
            name: "Free Margin",
            desc: "The amount of equity contributed by a customer as a percentage of the current market value of the securities held in a trading account. In other words, Equity less Margin equals your free margin or Margin plus Free Margin equals Equity.",
          },
          {
            name: "Foreign exchange (Forex, FX)",
            desc: "The buying of one currency and selling of another. The global market is referred to as the Forex (Fx)market.",
          },
          {
            name: "Futures contract",
            desc: "An obligation to exchange a good or instrument at a set price and specified quantity grade at a future date.",
          },
          {
            name: "Fundamental analysis",
            desc: "The assessment of all information available on a tradable product to determine its future outlook and therefore predict where the price is heading. Often non-measurable and subjective assessments, as well as quantifiable measurements, are made in fundamental analysis.",
          },
          {
            name: "Forward points",
            desc: "The pips added to or subtracted from the current exchange rate. Used to set the forward price.",
          },
          {
            name: "Future",
            desc: "An agreement between two parties to execute a transaction at a specified time in the future. The price of the future is agreed in the present.",
          },
          {
            name: "FTSE 100",
            desc: "The name of the UK 100 Index.",
          },
          {
            name: "Forward",
            desc: "The specified exchange rate for a Fx contract to be settled at an agreed future date.",
          },
        ],
      },
      {
        active: false,
        letter: "G",
        items: [
          {
            name: "Guaranteed stop",
            desc: "This is a stop-loss order guaranteed to close your position at a certain price level. You do not want the price to move above or below that point.",
          },
          {
            name: "Greenback",
            desc: "Also known as the US dollar.",
          },
          {
            name: "Good for day",
            desc: "A trading order that will expire at the end of the business day if it is not filled at a particular price level.",
          },
          {
            name: "Going long",
            desc: "The purchase of a Forex pair for investment or speculation. Your expectation is to see a price increase.",
          },
          {
            name: "Guaranteed order",
            desc: "A trade that protects a trader against the market gapping. It guarantees to fill your order at a specified price.",
          },
          {
            name: "Good ‘til date",
            desc: "A trading order expires on the date you choose.",
          },
          {
            name: "Gold contract",
            desc: "The unit of trading gold is one contract. A contract is equal to 100 troy ounces.",
          },
          {
            name: "Gross domestic product (GDP)",
            desc: "Total value of a country’s output, income or expenditure produced. These goods are produced within its physical borders.",
          },
          {
            name: "Good ’til cancelled order (GTC)",
            desc: "An order to buy or sell at a specified price. This order remains open until filled or until canceled by the trader.",
          },
          {
            name: "Going short",
            desc: "The selling of a Forex pair. Your expectation is for the price to go down.",
          },
        ],
      },
      {
        active: false,
        letter: "H",
        items: [
          {
            name: "Hit the bid",
            desc: "Selling a Fx posiiton at the current market bid.",
          },
          {
            name: "Handle",
            desc: "Every 100 pips in the FX market starting with 000.",
          },
          {
            name: "Hedge",
            desc: "A position or combination of positions that reduces the risk of your primary position.",
          },
          {
            name: "Hawk – hawkish",
            desc: "Central bankers are ‘hawkish’ when they believe that higher interest rates are needed. This is how they combat inflation or restrain rapid economic growth.",
          },
        ],
      },
      {
        active: false,
        letter: "I",
        items: [
          {
            name: "ISM non-manufacturing",
            desc: "An index that surveys service sector firms for outlook. This represents 80% of the US economy not covered by the ISM Manufacturing Report A reading over 50 indicates expansion.",
          },
          {
            name: "Interbank rates",
            desc: "The Foreign Exchange rates which large international banks quote to each other.",
          },
          {
            name: "Inflation",
            desc: "An economic condition whereby prices for consumer goods rise. Negatively impacts purchasing power.",
          },
          {
            name: "IMM session",
            desc: "8:00am – 3:00pm New York.",
          },
          {
            name: "ISM manufacturing index",
            desc: "An index that assesses the state of the US manufacturing sector. This is done by a survey of executives on expectations for future production, new orders, inventories, employment and deliveries. A reading over 50 indicates expansion.",
          },
          {
            name: "IPO",
            desc: "A private company’s initial offer of stock to the public. This is the abbreviation of Initial Public Offering.",
          },
          {
            name: "Industrial production",
            desc: "Measures the total value of output produced by manufacturers, mines and utilities. This data reacts quickly to the expansions and contractions in the business cycle. This is as a leading or forward indicator of employment and personal income.",
          },
          {
            name: "INX",
            desc: "Symbol for S&P 500 Index.",
          },
          {
            name: "Initial margin requirement",
            desc: "An Initial Margin Requirement refers to the percentage of equity required when an investor opens a position.",
          },
          {
            name: "INDU",
            desc: "Another abbreviation for the Dow Jones Industrial Average (DJIA).",
          },
        ],
      },
      {
        active: false,
        letter: "J",
        items: [
          {
            name: "JPN225",
            desc: "The NIKKEI index.",
          },
        ],
      },
      {
        active: false,
        letter: "K",
        items: [
          {
            name: "Kiwi",
            desc: "NZD/USD.",
          },
          {
            name: "Keep the powder dry",
            desc: "To limit your trades due to inclement trading conditions. This is used in choppy market conditions or narrow markets. Usually stand aside till better conditions come up.",
          },
        ],
      },
      {
        active: false,
        letter: "L",
        items: [
          {
            name: "Lot",
            desc: "A unit to measure the amount of the Fx deal. Corresponds to an integer number of lots.",
          },
          {
            name: "Long position",
            desc: "A position that appreciates in value if market price increases. When the base currency in the Forex pair is bought, the position is said to be long. You want the base currency to strengthen.",
          },
          {
            name: "Liquidation",
            desc: "The closing of an existing position through the execution of an offsetting transaction.",
          },
          {
            name: "LIBOR",
            desc: "The London Inter-Bank Offered Rate. Used for international lending of capital.",
          },
          {
            name: "Loonie",
            desc: "USD/CAD.",
          },
          {
            name: "London session",
            desc: "08:00 – 17:00 (London time).",
          },
          {
            name: "Liquid market",
            desc: "A market which has sufficient numbers of buyers and sellers. Enables smooth trading conditions.",
          },
          {
            name: "Longs",
            desc: "Traders who have bought a product.",
          },
          {
            name: "Liquidity",
            desc: "The ability of a market to accept large transactions with minimal impact on price.",
          },
          {
            name: "Limits / Limit order",
            desc: "A trading order that seeks to buy at lower price than the current market. Also refers to an order to sell at higher price than the current market.",
          },
        ],
      },
      {
        active: false,
        letter: "M",
        items: [
          {
            name: "Momentum",
            desc: "A series of technical studies (RSI, MACD, Stochastic, Momentum) that measures the rate of price changes.",
          },
          {
            name: "Maturity",
            desc: "The date for settlement or expiry of a financial product.",
          },
          {
            name: "Market order",
            desc: "An order to buy or sell at the current price.",
          },
          {
            name: "Manufacturing production",
            desc: "An economic measure of the total output of the manufacturing. This data only measures the 13 sub sectors directly relating to manufacturing. Manufacturing makes up 80% of of the Industrial Production readin.",
          },
          {
            name: "MoM",
            desc: "Abbreviation for month over month.",
          },
          {
            name: "Mark-to-market",
            desc: "Process of re-evaluating all open positions in light of current market prices. This determins margin requirements.",
          },
          {
            name: "Market maker",
            desc: "A dealer who regularly quotes both bid and ask prices. He or she makes a two-sided market for any financial product.",
          },
          {
            name: "Models",
            desc: "Synonymous with black box. A trading systems, with Meta Trader 4 that automatically buy and sell based on technical analysis.",
          },
          {
            name: "Market risk",
            desc: "Exposure to changes in market prices.",
          },
          {
            name: "Market capitalization",
            desc: "The total value of a listed company.",
          },
        ],
      },
      {
        active: false,
        letter: "N",
        items: [
          {
            name: "NYA.X",
            desc: "Trading symbol for NYSE Composite Index.",
          },
          {
            name: "NAS100",
            desc: "The NASDAQ 100 index. Tech heavy index",
          },
          {
            name: "New York session",
            desc: "8:00am – 5:00pm (EST).",
          },
          {
            name: "Net position",
            desc: "The amount of currency that is bought or sold. Has not yet been offset by opposite transactions",
          },
        ],
      },
      {
        active: false,
        letter: "O",
        items: [
          {
            name: "Order book",
            desc: "A system used to show the depth of the market of traders willing to buy and sell at prices beyond the best available market price.",
          },
          {
            name: "Open order",
            desc: "An order that will be executed when a market moves to its designated price. See Good ’til Cancelled Orders.",
          },
          {
            name: "Offsetting transaction",
            desc: "A trade that cancels or offsets some or all of the market risk of an open position.",
          },
          {
            name: "Overnight position",
            desc: "A trade that remains open until the next business day.",
          },
          {
            name: "Order",
            desc: "An instruction to execute a trade.",
          },
          {
            name: "One cancels the other order (OCO)",
            desc: "A trade order for two orders one part is executed, the other is cancelled at the same time.",
          },
          {
            name: "Over the counter (OTC)",
            desc: "Used to describe any transaction that is not conducted via an exchange.",
          },
          {
            name: "Open position",
            desc: "An active trade with corresponding unrealized P&L. This positon has not been offset by an equal and opposite deal.",
          },
          {
            name: "On top",
            desc: "Attempting to sell at the current market order price.",
          },
        ],
      },
      {
        active: false,
        letter: "P",
        items: [
          {
            name: "Paid",
            desc: "Refers to the offer side of the market dealing.",
          },
          {
            name: "Purchasing managers index services (France, Germany, Eurozone, UK)",
            desc: "Measures the outlook of purchasing managers in the service sector. A reading above 50 represents expansion. Similar to the US managers PMI.",
          },
          {
            name: "Premium",
            desc: "The amount by which the forward or futures price exceeds the spot price.",
          },
          {
            name: "Political risk",
            desc: "Exposure to changes in governmental policy. Could be adverse on an investor’s position.",
          },
          {
            name: "Purchasing managers index (PMI)",
            desc: "An economic indicator which indicates the performance of manufacturing companies within a country.",
          },
          {
            name: "Profit",
            desc: "The difference between the cost price and the sale price, occurs when the price sold is higher than the price bought.",
          },
          {
            name: "Position",
            desc: "The net total holdings of a given product.",
          },
          {
            name: "Pips",
            desc: "The smallest unit of price for any foreign currency. A pip refer to digits added or subtracted from the fourth decimal place. For Definition: 0.0001.",
          },
          {
            name: "Pullback",
            desc: "The tendency of a trending market to retrace a portion of the gains. The market will then resume its course of gians.",
          },
          {
            name: "Price transparency",
            desc: "Describes quotes to which every market participant has equal access.",
          },
          {
            name: "Portfolio",
            desc: "A collection of investments owned by an entity.",
          },
        ],
      },
      {
        active: false,
        letter: "Q",
        items: [
          {
            name: "Quantitative easing",
            desc: "When a central bank injects money into an economy by purchasing deb instruments like government issued bonds. Used to stimulate growth.",
          },
          {
            name: "Quote",
            desc: "An indicative market price, normally used for information purposes only.",
          },
        ],
      },
      {
        active: false,
        letter: "R",
        items: [
          {
            name: "RUT",
            desc: "The Russell 2000 Index.",
          },
          {
            name: "Rollover",
            desc: "A rollover is closing of an open position for today’s value date and then reopening the same position on the next day’s date. This will reflect the price and the interest rate differential between the Fx pair. In the spot Forex market, trades must be settled in two business days.",
          },
          {
            name: "Requote",
            desc: "A requote in the Forex world means that the broker is not able to process an order or trade based upon the price initially entered, because the price has already changed. Requotes usually happen when the market volatility is high.",
          },
          {
            name: "Retail investor",
            desc: "An individual investor who trades with money from personal wealth. This is the online trader.",
          },
          {
            name: "Running profit / loss",
            desc: "The status of a trader’s open positions. This is an accounting of the unrealized money you would gain or lose should you close all your open positions.",
          },
          {
            name: "Risk management",
            desc: "Using financial analysis as well as hedging or other trading techniques to reduce exposure to various types of risk",
          },
          {
            name: "Revaluation",
            desc: "When a pegged currency is allowed to strengthen or rise as a result of central bank intervention into the Forex market.",
          },
          {
            name: "Round trip",
            desc: "A trade that has been opened and subsequently closed by an equal and opposite deal.",
          },
          {
            name: "Risk",
            desc: "Exposure to uncertain change, most often used with a negative connotation of adverse change.",
          },
          {
            name: "Retail sales",
            desc: "Measures the monthly retail sales of all goods and services sold by retailers based on a sampling of different types and sizes. This is a forward indicator of consumer spending and inflation and is a key determinant of economic growth",
          },
        ],
      },
      {
        active: false,
        letter: "S",
        items: [
          {
            name: "SWISSIE",
            desc: "USD/CHF.",
          },
          {
            name: "Support levels",
            desc: "A technique used in technical analysis that indicates a specific price ceiling. When these levels are reached, you can expect a price correction. Opposite of resistance.",
          },
          {
            name: "Stop loss order",
            desc: "This is an order placed to sell below the current price. This is used to close a long position. It is also used to buy above the current price. It is then used to close a short position. Stop loss orders are an important risk management tool. They are used to protect your downside risk.",
          },
          {
            name: "Stop loss hunting",
            desc: "When the Forex market is looking for a certain level that is believed to be heavy with stops. If stops are triggered, then the price of the market will jump as stop-loss orders are triggered.",
          },
          {
            name: "Swap",
            desc: "A currency swap is the simultaneous sale and purchase of the same amount of a given currency. This is done by a central bank intervening in the Forex market at a forward exchange rate.",
          },
          {
            name: "Support",
            desc: "A price that acts as a floor for past or future price movements.",
          },
          {
            name: "Stop entry order",
            desc: "This is an order placed to buy above the current price. It is also an order to sell below the current price. These orders are useful if you believe the market is heading in one direction.",
          },
          {
            name: "Suspended trading",
            desc: "A temporary halt in the trading of a product.",
          },
          {
            name: "STP",
            desc: "STP refers to initiative used by trading companies in the financial world to optimize the speed at which transactions are processed.",
          },
          {
            name: "Stop order",
            desc: "A stop order is an order to buy or sell once a pre-defined price is reached. When the price is reached, the stop order becomes a market order. It is then executed at the best available price.",
          },
        ],
      },
      {
        active: false,
        letter: "T",
        items: [
          {
            name: "TYO10",
            desc: "The CBOE 10-Year Treasury Yield Index.",
          },
          {
            name: "Trend",
            desc: "Price movement that produces a net change in value.",
          },
          {
            name: "Trading range",
            desc: "The range between the highest and lowest price. For example: 52-week trading range.",
          },
          {
            name: "Trading bid",
            desc: "A Fx pair is moving higher. Bids keep entering the market and therefore the price move higher.",
          },
          {
            name: "Two-way price",
            desc: "When both a bid and offer rate is quoted for a Forex transaction.",
          },
          {
            name: "Transaction cost",
            desc: "The cost of buying or selling a Forex pair.",
          },
          {
            name: "Trading heavy",
            desc: "A market that feels like it wants to move lower. This is a market that is heavily offered that will not rally.",
          },
          {
            name: "Turnover",
            desc: "The total money value or volume of all executed transactions in a given time period.",
          },
          {
            name: "Trailing stop",
            desc: "A trailing stop allows a trade to continue to gain in value when the market price moves in a favorable direction. The position will close if the market price suddenly moves in an unfavorable direction. This order may not necessarily limit your losses.",
          },
          {
            name: "Trading halt",
            desc: "A postponement to trading that is not a suspension from trading.",
          },
        ],
      },
      {
        active: false,
        letter: "U",
        items: [
          {
            name: "US prime rate",
            desc: "The interest rate at which US banks will lend to corporate customers. Usually large corporations and not small and mid-size businesses.",
          },
          {
            name: "Uptick",
            desc: "A new price quote at a price higher than the preceding quote.",
          },
          {
            name: "Underlying",
            desc: "The actual traded market from where the price of a product is derived.",
          },
          {
            name: "UK OIL",
            desc: "Brent Crude Oil.",
          },
          {
            name: "US OIL",
            desc: "WTI Crude Oil.",
          },
          {
            name: "Unrealized gain/loss",
            desc: "The theoretical gain or loss on open positions valued at current market rates. Unrealized Gains or Losses become Profits and or Losses when the position is closed.",
          },
          {
            name: "UK producers price index output",
            desc: "Measures the rate of inflation experienced by manufacturers when selling goods and services.",
          },
          {
            name: "US30",
            desc: "The Dow Jones index.",
          },
          {
            name: "Unemployment rate",
            desc: "Measures the total workforce that is unemployed. These are people actively seeking employment. Is a key measure and is a percentage of the labor force.",
          },
          {
            name: "UK producers’ price index input",
            desc: "Measures the rate of inflation experienced by manufacturers when purchasing materials and services. This is a closely watched forward looking indicator.",
          },
        ],
      },
      {
        active: false,
        letter: "V",
        items: [
          {
            name: "Volatility",
            desc: "Referring to active markets that often present trade opportunities.",
          },
          {
            name: "Value",
            desc: "Value is a value... Doh.",
          },
          {
            name: "VIX or Volatility index",
            desc: "This shows the market’s expectation of 30-day volatility. It is constructed using the trading volatility of a wide range of S&P 500 index options. The VIX is heavily used and watched. It is a measure of market risk and is referred to as the “investor fear gauge.”",
          },
          {
            name: "Value date",
            desc: "Also known as the maturity date. This is the date which counterparts to a financial transaction agree to settle their respective obligations. For spot Forex transactions, the value date is two business days forward.",
          },
        ],
      },
      {
        active: false,
        letter: "W",
        items: [
          {
            name: "WSJ",
            desc: "The Wall Street Journal.",
          },
          {
            name: "Whipsaw",
            desc: "Slang for a highly volatile market where a sharp price movement is then quickly followed by a sharp reversal.",
          },
          {
            name: "Working order",
            desc: "Where a limit order has been requested but not yet filled.",
          },
          {
            name: "Wedge chart pattern",
            desc: "Chart formation that shows a narrowing price range over time, where price highs in an ascending wedge decrease incrementally. The opposite of descending pattern.",
          },
          {
            name: "Wholesale prices",
            desc: "Measures the changes in prices paid by retailers for finished goods. It is a forward looking tool and inflationary pressures typically show earlier than in retail prices.",
          },
          {
            name: "Wall Street",
            desc: "Wall Street is a street in lower Manhattan that is the original home of the New York Stock Exchange and the historic headquarters of the largest U.S. brokerages and investment banks. The term Wall Street is also used as a collective name for the financial and investment community, which includes stock exchanges and large banks, brokerages, securities and underwriting firms, and big businesses.",
          },
        ],
      },
      {
        active: false,
        letter: "X",
        items: [
          {
            name: "XAX.X",
            desc: "The AMEX Composite Index.",
          },
          {
            name: "XAU/USD",
            desc: "The Gold Index.",
          },
          {
            name: "XAG/USD",
            desc: "The Silver Index.",
          },
        ],
      },
      {
        active: false,
        letter: "Y",
        items: [
          {
            name: "Yuan",
            desc: "The Yuan is the base unit of currency in China and is also called the Renminbi.",
          },
          {
            name: "YoY",
            desc: "Year over year.",
          },
          {
            name: "Yard",
            desc: "A billion units.",
          },
          {
            name: "Yield",
            desc: "The percentage return from an investment.",
          },
        ],
      },
      {
        active: false,
        letter: "Z",
        items: [
          {
            name: "ZAR",
            desc: "ZAR is the currency used in South Africa, called Rand.",
          },
        ],
      },
    ],
  }),
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  computed: {
    wordSearch() {
      let currentLetter = this.currentLetter;
      return this.glossary[currentLetter].items.filter((item) => {
        return item.name.match(new RegExp(this.search, "i"));
      });
    },
  },
  methods: {
    activateLetter(letter) {
      this.glossary.forEach((slide) => {
        slide.active = false;
      });
      letter.active = true;
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
