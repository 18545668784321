<template>
  <div
    @mouseenter="kubik.class = '-rotate'"
    @mouseleave="kubik.class = ''"
    class="cube-wrapper"
  >
    <div :class="`front${kubik.class}`">
      <div id="iceberg">
        <img :src="`/img/company/meet-us/${feature.img}.jpg`" class="img-fluid" :alt="feature.title" />
      </div>
      <div class="inner">
        <p class="text-white">{{feature.title}}</p>
      </div>
    </div>
    <div :class="`back${kubik.class}`">
      <div class="inner">
        <p class="text-white fs-6" v-if="kubik.class != ''">
          {{ feature.text }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FeatureMeetUs",
  props: {
    feature: Object,
  },
  data: () => ({
    kubik: {
      class: "",
    },
  }),
};
</script>
<style scoped lang="scss">
$trio: #00162c;
$base-white:#fff;
.cube-wrapper {
  cursor: pointer;
  transform-style: preserve-3d;
  perspective: 1000px;
}
.front,
.back {
  background-size: cover;
  background-position: center;
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  backface-visibility: hidden;
  text-align: center;
  height: auto;
  border-radius: 10px;
  color: #fff;
  font-size: 1.5rem;
}

.back {
  background: $trio;
  background: linear-gradient(45deg, #00162c 0%, #596a72 100%);
  font-size: 1rem;
}
.back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner {
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  outline: 1px solid transparent;
  perspective: inherit;
  z-index: 2;
}

.cube-wrapper .back {
  transform: rotateY(180deg);
  transform-style: preserve-3d;
}

.cube-wrapper .front {
  transform: rotateY(0deg);
  transform-style: preserve-3d;
}

.back-rotate {
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: $trio;
  background: linear-gradient(45deg, #00162c 0%, #596a72 100%);
  background-size: cover;
  background-position: center;
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  backface-visibility: hidden;
  text-align: center;
  height: 100%;
  border-radius: 10px;
  font-size: 1.5rem;
  margin-bottom: -10px;
}

.front-rotate {
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  transform: rotateY(-180deg);
  transform-style: preserve-3d;
  background-size: cover;
  background-position: center;
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  backface-visibility: hidden;
  text-align: center;
  height: auto;
  border-radius: 10px;
  color: #fff;
  font-size: 1.5rem;
}

.front .inner p,
.front-rotate .inner p {
  font-size: 2rem;
  margin-bottom: 0;
  position: relative;
  font-weight: bold;
}

.front .inner span,
.front-rotate .inner span {
  font-weight: bold;
  font-size: 2rem;
}

</style>
