export default {
  en: {
    TitleApp: `TrioMarkets Mobile App`,
    AppText: `Unlock the future of trading with the TrioMarkets Mobile App. Seamlessly designed for both novice and experienced traders, our app empowers you to trade anytime, anywhere. Enjoy a user-friendly interface, secure transactions, and access to the revolutionary TrioMarkets Trading Platform.`,
    essentialsForTrading: "Essentials for Trading",
    essentials: [
      {
        name: "License & <br> Regulation",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Segregated <br> Client Funds",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Hyper Fast <br> Execution",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Dark Pool <br> Liquidities",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Exceptional <br> Tight Spreads",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Quick Withdrawal <br> Process",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Responsive <br> Customer Service",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "No Conflicts <br> of Interests",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Top Trading Environment",
    topTradingEnvironmentPoints: [
      { name: "Security & Server Protection" },
      { name: "Ultra Tight Spreads" },
      { name: "Super Fast Speed of Execution" },
      { name: "Extended MT4 Tools" },
    ],
    marketsHome: [
      {
        name: "Shares",
        img: "shares",
        link: "/markets/shares",
        text: "Trade the major companies' stock prices on the world's leading markets.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ offers you 60+ of your favourite currency pairs. Trade Major, Minor & Exotic pairs.",
      },
      {
        name: "Indices",
        img: "indices",
        link: "/markets/indices",
        text: "Access the most popular Indices on MetaTrader 4 such as Nasdaq, S&P, Dow Jones and FTSE.",
      },
      {
        name: "Energies",
        img: "energies",
        link: "/markets/energies",
        text: "Get involved with the world's greatest demands; Natural Gas, Crude and Brent Oil.",
      },
      // {
      //   name: "Cryptos",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Diversify you portfolio with Cryptocurrencies CFDs such as Bitcoin, Ethereum, Litecoin & XRP.",
      // },
      {
        name: "Metals",
        img: "metals",
        link: "/markets/metals",
        text: "Get involved with the world's greatest demandes; Natural Gas, Crude and Brent Oil.",
      },
    ],
  },
  fr: {
    TitleApp: `Application mobile TrioMarkets`,
    AppText: `Débloquez l'avenir du trading avec l'application mobile TrioMarkets. Conçue de manière transparente pour les traders novices et expérimentés, notre application vous permet de trader à tout moment, n'importe où. Profitez d'une interface conviviale, de transactions sécurisées et de l'accès à la plateforme de trading révolutionnaire TrioMarkets.`,
    essentialsForTrading: "Essentiels de Trading",
    essentials: [
      {
        name: "Licence & <br> Régulations",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Comptes <br> Clients Ségrégués",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Exécution <br> Ultra Rapide",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Groupe de <br> Fournisseurs de Liquidités",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Spreads <br> Ultra Serrés",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Méthode de <br> Retrait Rapide",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Service <br> Client Réactif",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Pas de <br> Conflits d’Intérêt",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Environnement de Trading Sophistiqué",
    topTradingEnvironmentPoints: [
      { name: "Sécurité & Protection du Serveur" },
      { name: "Spreads Ultra Serrés" },
      { name: "Vitesse d'exécution Ultra Rapide" },
      { name: "Outils MT4 Avancés" },
    ],
    marketsHome: [
      {
        name: "Shares",
        img: "shares",
        link: "/markets/shares",
        text: "Tradez les cours des actions des grandes entreprises sur les principaux marchés mondiaux.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ vous donne accès à plus de 60 de vos paires de devises préférées. Tradez des paires majeures, mineures et exotiques..",
      },
      {
        name: "Indices",
        img: "indices",
        link: "/markets/indices",
        text: "Accédez aux indices les plus populaires sur MetaTrader 4 tels que Nasdaq, S&P, Dow Jones et FTSE.",
      },
      {
        name: "Énergies",
        img: "energies",
        link: "/markets/energies",
        text: "Participez aux plus grandes demandes du monde; Gaz naturel, pétrole brut et Brent.",
      },
      // {
      //   name: "Cryptos",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Diversifiez votre portefeuille avec des CFD sur crypto-monnaies tels que Bitcoin, Ethereum, Litecoin et XRP.",
      // },
      {
        name: "Métaux",
        img: "metals",
        link: "/markets/metals",
        text: "Faites l'expérience du trading de métaux précieux comme l'or et l'argent, le palladium et le platine avec une vitesse d'exécution ultra-rapide.",
      },
    ],
  },
  it: {
    TitleApp: `App mobile di TrioMarkets`,
    AppText: `Sblocca il futuro del trading con l'app mobile di TrioMarkets. Progettata in modo fluido sia per i trader principianti che per quelli esperti, la nostra app ti permette di fare trading in qualsiasi momento, ovunque tu sia. Goditi un'interfaccia utente amichevole, transazioni sicure e l'accesso alla rivoluzionaria piattaforma di trading di TrioMarkets.`,
    essentialsForTrading: "Essenziali per il Trading",
    essentials: [
      {
        name: "Regolamentazione <br> e Licenza",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Fondi Clienti <br> Separati",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Esecuzione <br> Rapida",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Liquidità di <br> Primo Livello",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Spread <br> Eccezionalmente Ristretti",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Prelievi <br> Veloci",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Rapido <br> Servizio Clienti",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "No Conflitto <br> di Interessi",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Ottimo Ambiente di Trading",
    topTradingEnvironmentPoints: [
      { name: "Sicurezza e Protezione del Server" },
      { name: "Spread Ultra Stretti" },
      { name: "Velocità di Esecuzione Super Veloce" },
      { name: "Strumenti MT4 Estesi" },
    ],
    marketsHome: [
      {
        name: "Azioni",
        img: "shares",
        link: "/markets/shares",
        text: "Trada sui prezzi delle azioni delle maggiori Società nei principali mercati mondiali.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ ti offre 60+ delle tue coppie di valute preferite. Trada valute Major, Minor & Exotic.",
      },
      {
        name: "Indici",
        img: "indices",
        link: "/markets/indices",
        text: "Accedi ai principali Indici su MetaTrader 4 come Nasdaq, S&P, Dow Jones and FTSE.",
      },
      {
        name: "Energetici",
        img: "energies",
        link: "/markets/energies",
        text: "Partecipa alle materie prime più richieste del mondo; Gas naturale, Greggio e Petrolio Brent.",
      },
      // {
      //   name: "Cripto",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Diversifica il tuo portfolio con CFD delle criptovalute come Bitcoin, Ethereum, Litecoin e XRP.",
      // },
      {
        name: "Metalli",
        img: "metals",
        link: "/markets/metals",
        text: "Sperimenta il trading sui metalli prezioni come Oro & Argento, Palladio & Platino con una velocità istantanea di esecuzione.",
      },
    ],
  },
  de: {
    TitleApp: `TrioMarkets Mobile App`,
    AppText: `Entsperren Sie die Zukunft des Handels mit der TrioMarkets Mobile App. Nahtlos gestaltet für sowohl Anfänger als auch erfahrene Trader, ermöglicht Ihnen unsere App, jederzeit und überall zu handeln. Genießen Sie eine benutzerfreundliche Oberfläche, sichere Transaktionen und Zugang zur revolutionären TrioMarkets Trading-Plattform.`,
    essentialsForTrading: "Grundlagen für den Handel",
    essentials: [
      {
        name: "Lizenzen & <br> Regulierungen",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Segregierte <br> Kundenkonten",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Hyperschnelle <br> Ausführung",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Keine <br> Nachschusspflicht",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Außergewöhnlich <br> enge Spreads",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Zügige <br> Auszahlungen",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Schneller <br> Kundenservice",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Keine <br> Interessenkonflikte",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Top Tradingumgebung",
    topTradingEnvironmentPoints: [
      { name: "Sicherheit & Serverschutz" },
      { name: "Ultra-enge Spreads" },
      { name: "Superschnelle Ausführungsgeschwindigkeit" },
      { name: "Verbesserte MT4 Tools" },
    ],
    marketsHome: [
      {
        name: "Aktien",
        img: "shares",
        link: "/markets/shares",
        text: "Handeln Sie mit den Aktienkursen der großen Unternehmen auf den weltweit führenden Märkten.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ bietet Ihnen mehr als 60 Ihrer bevorzugten Währungspaare. Handeln Sie Major, Minor & Exotic Paare.",
      },
      {
        name: "Indizes",
        img: "indices",
        link: "/markets/indices",
        text: "Greifen Sie auf die beliebtesten Indizes von MetaTrader 4 wie Nasdaq, S & P, Dow Jones und FTSE zu.",
      },
      {
        name: "Energie",
        img: "energies",
        link: "/markets/energies",
        text: "Beteiligen Sie sich an den größten Nachfragen der Welt. Erdgas, Rohöl und Brentöl.",
      },
      // {
      //   name: "Kryptowährungen",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Diversifizieren Sie Ihr Portfolio mit Kryptowährungen-CFDs wie Bitcoin, Ethereum, Litecoin und XRP.",
      // },
      {
        name: "Edelmetalle",
        img: "metals",
        link: "/markets/metals",
        text: "Erleben Sie den Handel mit Edelmetallen wie Gold & Silber, Palladium & Platin mit blitzschneller Ausführungsgeschwindigkeit.",
      },
    ],
  },
  cn: {
    TitleApp: `TrioMarkets 移动应用`,
    AppText: `使用 TrioMarkets 移动应用解锁交易的未来。我们的应用经过无缝设计，适用于初学者和有经验的交易者，让您随时随地进行交易。享受用户友好的界面、安全的交易和对革命性的 TrioMarkets 交易平台的访问。`,
    essentialsForTrading: "交易要点",
    essentials: [
      { name: "牌照<br>与监管", img: "lnr", show: ["global", "africa"] },
      {
        name: "客户<br>资金隔离 ",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      { name: "极低<br>点差", img: "hfe", show: ["global", "eu", "africa"] },
      { name: "快速出金<br>流程", img: "dpl", show: ["global", "africa"] },
      { name: "超快<br>执行", img: "ets", show: ["global", "eu", "africa"] },
      { name: "暗池<br>流动性", img: "qwp", show: ["global", "eu", "africa"] },
      {
        name: "专业<br>的客户服务",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      { name: "无利益<br>冲突", img: "nci", show: ["global", "africa"] },
    ],
    topTradingEnvironment: "顶级交易环境 ",
    topTradingEnvironmentPoints: [
      { name: "安全与服务器保护" },
      { name: "超低点差" },
      { name: "超快的执行速度" },
      { name: "MT4扩展工具" },
    ],
    marketsHome: [
      {
        name: "股票",
        img: "shares",
        link: "/markets/shares",
        text: "在全球主要金融市场上交易知名上市公司的股票。",
      },
      {
        name: "外汇",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ 为您提供60多种您最喜欢的货币对。交易主要，次要和异国货币对。",
      },
      {
        name: "指数",
        img: "indices",
        link: "/markets/indices",
        text: "交易MetaTrader 4上最受欢迎的指数，例如纳斯达克、标准普尔、道琼斯和富时指数。",
      },
      {
        name: "能源",
        img: "energies",
        link: "/markets/energies",
        text: "参与全球最大供需市场；天然气、原油和布伦特石油。",
      },
      // {
      //   name: "加密货币",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "使用加密货币差价合约使您的投资组合多样化，比如比特币、以太坊、莱特币和XRP。",
      // },
      {
        name: "金属",
        img: "metals",
        link: "/markets/metals",
        text: "体验以闪电般的执行速度交易黄金和白银，钯和铂等贵金属。",
      },
    ],
  },
  es: {
    TitleApp: `Aplicación móvil TrioMarkets`,
    AppText: `Desbloquea el futuro del trading con la aplicación móvil de TrioMarkets. Diseñada de forma fluida tanto para traders principiantes como experimentados, nuestra aplicación te permite operar en cualquier momento y lugar. Disfruta de una interfaz fácil de usar, transacciones seguras y acceso a la revolucionaria plataforma de trading de TrioMarkets.`,
    essentialsForTrading: "Elementos Esenciales para el Trading",
    essentials: [
      {
        name: "Licencia y <br /> Reglamento",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Fondos de <br /> Clientes Segregados",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Ejecución <br /> Hiperrápida",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Liquidez de <br /> Dark Pool",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Spreads Ajustados <br /> Excepcionales",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Proceso de <br /> Retiro Rápido",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Servicio al <br /> Cliente Rapido",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Sin Conflictos <br /> de Intereses",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Entorno de Trading Superior",
    topTradingEnvironmentPoints: [
      { name: "Seguridad y Protección del Servidor" },
      { name: "Spreads Ultra Ajustados" },
      { name: "Velocidad de Ejecución Súper Rápida" },
      { name: "Herramientas MT4 Extendidas" },
    ],
    marketsHome: [
      {
        name: "Acciones",
        img: "shares",
        link: "/markets/shares",
        text: "Opere los precios de las acciones de las principales empresas en los principales mercados del mundo.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ le ofrece más de 60 de sus pares de divisas favoritos. Intercambie pares mayores, menores y exóticos.",
      },
      {
        name: "Índices",
        img: "indices",
        link: "/markets/indices",
        text: "Acceda a los índices más populares en MetaTrader 4, como Nasdaq, S&P, Dow Jones y FTSE.",
      },
      {
        name: "Energías",
        img: "energies",
        link: "/markets/energies",
        text: "Participar con las mayores demandas del mundo; Gas Natural, Crudo y Petróleo Brent.",
      },
      // {
      //   name: "Criptos",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Diversifique su cartera con CFD de criptomonedas como Bitcoin, Ethereum, Litecoin y XRP.",
      // },
      {
        name: "Metales",
        img: "metals",
        link: "/markets/metals",
        text: "Experimente el comercio de metales preciosos como oro y plata, paladio y platino con una velocidad de ejecución ultrarrápida.",
      },
    ],
  },
  vnm: {
    TitleApp: `Ứng dụng di động TrioMarkets`,
    AppText: `Mở khóa tương lai của giao dịch với ứng dụng di động TrioMarkets. Thiết kế mượt mà cho cả những người giao dịch mới và có kinh nghiệm, ứng dụng của chúng tôi giúp bạn giao dịch bất cứ lúc nào, ở bất kỳ đâu. Tận hưởng giao diện thân thiện với người dùng, giao dịch an toàn và truy cập vào nền tảng giao dịch đột phá của TrioMarkets.`,
    essentialsForTrading: "Những điều cần thiết để giao dịch",
    essentials: [
      {
        name: "Giấy phép & <br> Quy định ",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Quỹ khách hàng <br> được tách biệt",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Thực thi <br> siêu nhanh",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Thanh khoản <br> Dark Pool",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Spread cạnh tranh <br> vượt trội",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Quy trình rút <br> tiền nhanh chóng",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Dịch vụ khách hàng <br> đáp ứng nhanh",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Không có xung <br> đột lợi ích",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Môi trường giao dịch hàng đầu",
    topTradingEnvironmentPoints: [
      { name: "Bảo mật & Bảo vệ Máy chủ" },
      { name: "Spread cạnh tranh vượt trội" },
      { name: "Tốc độ thực thi siêu nhanh" },
      { name: "Công cụ MT4 mở rộng" },
    ],
    marketsHome: [
      {
        name: "Cổ phiếu",
        img: "shares",
        link: "/markets/shares",
        text: "Giao dịch giá cổ phiếu của các công ty lớn trên các thị trường hàng đầu thế giới.",
      },
      {
        name: "Ngoại hối",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets ™ cung cấp cho bạn hơn 60 cặp tiền tệ yêu thích của bạn. Các cặp giao dịch chính, phụ và ngoại lai.",
      },
      {
        name: "Chỉ số",
        img: "indices",
        link: "/markets/indices",
        text: "Truy cập các Chỉ số phổ biến nhất trên MetaTrader 4 như Nasdaq, S&P, Dow Jones và FTSE. ",
      },
      {
        name: "Năng lượng",
        img: "energies",
        link: "/markets/energies",
        text: "Tham gia với những người vĩ đại nhất thế giới; Khí tự nhiên, dầu thô và dầu Brent. ",
      },
      // {
      //   name: "Tiền điện tử",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Đa dạng hóa danh mục đầu tư của bạn với Tiền điện tử CFD như Bitcoin, Ethereum, Litecoin & XRP. ",
      // },
      {
        name: "Kim loại",
        img: "metals",
        link: "/markets/metals",
        text: "Kinh nghiệm giao dịch kim loại quý như Vàng & Bạc, Paladi & Bạch kim với tốc độ thực thi cực nhanh. ",
      },
    ],
  },
  enUae: {
    TitleApp: `TrioMarkets Mobile App`,
    AppText: `Unlock the future of trading with the TrioMarkets Mobile App. Seamlessly designed for both novice and experienced traders, our app empowers you to trade anytime, anywhere. Enjoy a user-friendly interface, secure transactions, and access to the revolutionary TrioMarkets Trading Platform.`,
    essentialsForTrading: "Essentials for Trading",
    essentials: [
      {
        name: "License & <br> Regulation",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Segregated <br> Client Funds",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Hyper Fast <br> Execution",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Dark Pool <br> Liquidities",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Exceptional <br> Tight Spreads",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Quick Withdrawal <br> Process",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Responsive <br> Customer Service",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "No Conflicts <br> of Interests",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Top Trading Environment",
    topTradingEnvironmentPoints: [
      { name: "Security & Server Protection" },
      { name: "Ultra Tight Spreads" },
      { name: "Super Fast Speed of Execution" },
      { name: "Extended MT4 Tools" },
    ],
    marketsHome: [
      {
        name: "Shares",
        img: "shares",
        link: "/markets/shares",
        text: "Trade the major companies' stock prices on the world's leading markets.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets™ offers you 60+ of your favourite currency pairs. Trade Major, Minor & Exotic pairs.",
      },
      {
        name: "Indices",
        img: "indices",
        link: "/markets/indices",
        text: "Access the most popular Indices on MetaTrader 4 such as Nasdaq, S&P, Dow Jones and FTSE.",
      },
      {
        name: "Energies",
        img: "energies",
        link: "/markets/energies",
        text: "Get involved with the world's greatest demands; Natural Gas, Crude and Brent Oil.",
      },
      // {
      //   name: "Cryptos",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Diversify you portfolio with Cryptocurrencies CFDs such as Bitcoin, Ethereum, Litecoin & XRP.",
      // },
      {
        name: "Metals",
        img: "metals",
        link: "/markets/metals",
        text: "Get involved with the world's greatest demandes; Natural Gas, Crude and Brent Oil.",
      },
    ],
  },
  arb: {
    TitleApp: `تطبيق TrioMarkets للهواتف المحمولة`,
    AppText: `افتح باب مستقبل التداول مع تطبيق TrioMarkets للهواتف المحمولة. صُممت التطبيق بسلاسة لكل من التجار المبتدئين والمتمرسين، حيث يمكنك التداول في أي وقت وفي أي مكان. استمتع بواجهة سهلة الاستخدام ومعاملات آمنة والوصول إلى منصة التداول الثورية TrioMarkets.`,
    essentialsForTrading: "أساسيات التداول",
    essentials: [
      {
        name: "التراخيص <br> واللوائح",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "أموال العملاء<br> المنفصلة",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "فروق أسعار ضيقة <br>استثنائية",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "عملية <br>السحب السريع",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "سرعة عالية <br>في التنفيذ",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      { name: "السيولة المظلمة", img: "qwp", show: ["global", "eu", "africa"] },
      {
        name: "خدمة العملاء <br>سريعة الاستجابة",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "لا تضارب <br>في المصالح",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "بيئة تداول مميزة",
    topTradingEnvironmentPoints: [
      { name: "أمن وحماية السيرفر" },
      { name: "فروق أسعار ضيقة للغاية" },
      { name: "سرعة فائقة في التنفيذ" },
      { name: "أدوات MT4 الموسعة" },
    ],
    marketsHome: [
      {
        name: "الأسهم",
        img: "shares",
        link: "/markets/shares",
        text: "تداول على أسعار أسهم الشركات الكبرى في الأسواق الرائدة في العالم.",
      },
      {
        name: "فوركس",
        img: "forex",
        link: "/markets/forex",
        text: "تقدم لك TrioMarkets™ أكثر من 60 زوجًا من أزواج العملات المفضلة لديك. أزواج التجارة الرئيسية والصغرى والغريبة.",
      },
      {
        name: "المؤشرات",
        img: "indices",
        link: "/markets/indices",
        text: "قم بالوصول إلى المؤشرات الأكثر شيوعًا على MetaTrader 4 مثل Nasdaq و S&P و Dow Jones و FTSE.",
      },
      {
        name: "الطاقة",
        img: "energies",
        link: "/markets/energies",
        text: "شارك في أكبر مطالب العالم ، الغاز الطبيعي والنفط الخام ونفط برنت.",
      },
      // {
      //   name: "العملات المشفرة",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "نوّع محفظتك باستخدام عقود الفروقات للعملات المشفرة مثل Bitcoin و Ethereum و Litecoin و XRP.",
      // },
      {
        name: "المعادن",
        img: "metals",
        link: "/markets/metals",
        text: "جرب تداول المعادن الثمينة مثل الذهب والفضة والبلاديوم والبلاتينيوم بسرعة تنفيذ فائقة.",
      },
    ],
  },
  my: {
    TitleApp: `Aplikasi Mudah Alih TrioMarkets`,
    AppText: `Buka kunci masa depan perdagangan dengan Aplikasi Mudah Alih TrioMarkets. Dibangunkan dengan lancar untuk pedagang pemula dan berpengalaman, aplikasi kami memberdayakan anda untuk berdagang pada bila-bila masa, di mana sahaja. Nikmati antara muka mesra pengguna, urus niaga yang selamat, dan akses ke Platform Perdagangan TrioMarkets yang revolusioner.`,
    essentialsForTrading: "Keperluan untuk Perdagangan",
    essentials: [
      {
        name: "Lesen & <br> Peraturan",
        img: "lnr",
        show: ["global", "africa"],
      },
      {
        name: "Dana Pelanggan <br> Terpisah",
        img: "scf",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Penyebaran Ketat <br> Luar Biasa",
        img: "hfe",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Proses <br> Pengeluaran Pantas",
        img: "dpl",
        show: ["global", "africa"],
      },
      {
        name: "Pelaksanaan <br> Cepat Hyper",
        img: "ets",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Cecair <br> Kolam Gelap",
        img: "qwp",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Perkhidmatan <br> Pelanggan Responsif",
        img: "rcs",
        show: ["global", "eu", "africa"],
      },
      {
        name: "Tiada Konflik <br> Kepentingan",
        img: "nci",
        show: ["global", "africa"],
      },
    ],
    topTradingEnvironment: "Persekitaran Perdagangan Teratas",
    topTradingEnvironmentPoints: [
      { name: "Keselamatan & Perlindungan Pelayan" },
      { name: "Penyebaran Ultra Ketat" },
      { name: "Kelajuan Pelaksanaan Super Cepat" },
      { name: "Alat MT4 yang diperluas" },
    ],
    marketsHome: [
      {
        name: "Saham",
        img: "shares",
        link: "/markets/shares",
        text: "Perdagangan harga saham syarikat utama pada pasaran terkemuka di dunia.",
      },
      {
        name: "Forex",
        img: "forex",
        link: "/markets/forex",
        text: "TrioMarkets ™ menawarkan 60 + kegemaran anda pasangan mata wang. Berdagang pasangan Major, Minor & Exotic.",
      },
      {
        name: "Indeks",
        img: "indices",
        link: "/markets/indices",
        text: "Akses Indeks yang paling popular di MetaTrader 4 seperti Nasdaq, S&P, Dow Jones dan FTSE.",
      },
      {
        name: "Tenaga",
        img: "energies",
        link: "/markets/energies",
        text: "Terdapat dengan yang terhebat di dunia tuntutan; Gas Asli, Minyak Mentah dan Brent.",
      },
      // {
      //   name: "Cryptos",
      //   img: "crypto",
      //   link: "/markets/cryptos",
      //   text: "Pelbagai portfolio anda dengan Cryptocurrency CFD seperti Bitcoin, Ethereum, Litecoin & XRP.",
      // },
      {
        name: "Logam",
        img: "metals",
        link: "/markets/metals",
        text: "Pengalaman memperdagangkan logam berharga seperti Emas & Perak, Paladium & Platinum dengan kelajuan pelaksanaan sepantas kilat.",
      },
    ],
  },
};
