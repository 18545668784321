<template>
  <div
    class="container-fluid regulation-wrapper position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center cookies"
  >
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div
          :class="`col-xl-7 col-lg-8 col-md-12 popup-container py-3 d-flex justify-content-center ${checkArb ? 'rtl':''}`" 
        >
          <span class="d-flex align-items-center justify-content-center fs-5"
            ><p class="mb-0 fs-5 me-2" v-html="$t('cookies')"></p>
            <a
              target="_blank"
              :href="
                license == 'global'
                  ? 'https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Privacy_Policy.pdf'
                  : 'https://www.triomarkets.eu/legal-documents/EDR/Privacy-Policy.pdf'
              "
              class="text-primary me-3"
              >{{ $t("privacyPolicy") }}</a
            ></span
          >
          <a @click="acceptCookies()" class="btn btn-blue py-1 mt-md-0 mt-3 mr-1">{{
            $t("accept")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
export default {
  name: "Cookies",
  methods: {
    acceptCookies() {
      mutations.setCookies();
    },
  },
  computed: {
    license() {
      return getters.license();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
