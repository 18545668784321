<template>
  <div v-for="(item, idx) in $tm('segregatedFundsInfo')" :key="idx">
    <div class="container-fluid container-top accounts platforms">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("segregatedFunds") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div class="col-lg-4 col-sm-6 d-lg-block d-none">
            <img
              src="/img/company/safe-l.png"
              alt="safe"
              class="img-fluid pb-5"
            />
            <!-- <a :href="registerURL" target="_blank" class="btn btn-blue w-100">{{
              $t("companyAccount")
            }}</a> -->
          </div>
          <div
            class="col-lg-4 col-sm-10 col-12 text-white text-center d-flex flex-column mt-lg-5 pt-xl-5 seg-text"
            v-html="item.text"
          ></div>
          <div class="col-sm-6 d-lg-none my-sm-4 my-2">
            <a :href="registerURL" target="_blank" class="btn btn-blue w-100">{{
              $t("companyAccount")
            }}</a>
          </div>
          <div class="col-lg-4 col-sm-6">
            <img
              src="/img/company/safe-r.png"
              alt="safe"
              class="img-fluid pb-5 d-lg-block d-none"
            />
            <!-- <a
              :href="registerURL"
              target="_blank"
              class="btn btn-blue w-100 my-lg-0 my-sm-4 my-2"
              >{{ $t("clientsAccount") }}</a
            > -->
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">{{ $t("moreInfo") }}</h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center my-4">
        <div class="col-md-6 col-sm-10 col-12 mb-md-0 mb-4">
          <div class="forex-box seg-funds" v-html="item.textTwo"></div>
          <p class="mt-5 fs-6" v-html="item.textFour"></p>
        </div>
        <div class="col-md-6 col-sm-10 col-12">
          <div class="forex-box seg-funds mb-5" v-html="item.textThree"></div>
          <div class="row d-flex justify-content-center">
            <div class="col-12">
              <div
                v-for="(point, idx) in item.points"
                :key="idx"
                class="d-flex"
              >
                <img
                  src="/img/check.svg"
                  alt="check"
                  class="img-fluid check me-3"
                />
                <p class="fs-6">{{ point }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <a :href="registerURL" target="_blank" class="btn btn-blue my-5">{{
            $t("openLiveAccount")
          }}</a>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms platformStyle="dark" v-scrollanimation />
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import { getters, mutations } from "../store";
export default {
  name: "SegregatedFunds",
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  mounted() {
    window.onresize = () => {
      mutations.setBoxHeight("seg-funds", "0");
    };
  },
};
</script>
<style lang="scss" scoped></style>
