<template>
  <div v-if="existingArticle" id="article" class="container-fluid">
    <div class="container">
      <div class="row">
        <div class="col-12 fw-bold text-capitalize">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0">
              <li class="breadcrumb-item">
                <router-link
                  :to="`/${$route.params.lang}/news/articles`"
                  class="breadcrumb-item fs-6 text-trio"
                  >{{ $t("news") }}</router-link
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a class="fs-6 text-trio" href="#" @click="goToPage">{{
                  page
                }}</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-lg-8">
          <div :class="checkArb ? `text-end` : ''">
            <span>{{ article.details.readingTime }} {{ $t("minutes") }}</span>
            <p class="fs-3 fw-bold">{{ article.details.title }}</p>
            <div v-html="article.details.content" class="text-justify"></div>
            <p>{{ article.details.createdAt }}</p>
          </div>
        </div>
        <div class="col-lg-4 my-lg-0 my-4">
          <div :class="`recomended gray-box p-4 ${checkArb ? 'text-end' : ''}`">
            <p class="fs-5 fw-bold">{{ $t("readAlso") }}</p>
            <ul class="list-group list-group-flush px-0">
              <li
                class="list-group-item gray-box px-0 d-flex flex-column justify-content-between"
                v-for="(item, index) in relativeBlogList"
                :key="index"
              >
                <a
                  class="text-trio"
                  :href="item.slug"
                  @click.prevent="goToArticle(item)"
                >
                  <div>{{ item.title }}</div>
                  <div
                    class="fw-normal"
                    v-html="`${item.content.substr(0, contentLength)}...`"
                  ></div> </a
                ><span>{{ item.createdAt }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import renderMeta from "../SEO";
import { getters, mutations } from "../store";
export default {
  name: "Article",
  metaInfo() {
    return renderMeta(
      this.article.details.title ?? "",
      `${this.$apiURL()}/uploads/images/${
        this.article.details.image.generatedName
      }`,
      window.location.href,
      this.article.details.title ?? ""
    );
  },
  data() {
    return {
      path: {
        slug: "",
        type: "",
        section: "",
      },
      relativeBlogList: [],
      windowWidth: window.innerWidth,
    };
  },
  created() {
    if (!this.existingArticle) {
      let url = location.pathname;
      let [slug, type, section] = [
        url.split("/")[5],
        url.split("/")[3],
        url.split("/")[4],
      ];
      section = type === "articles" ? "General" : "MarketAnalysis";
      this.path = { slug, type, section, lang: this.selectedLang };
      this.getArticle();
    } else {
      window.onresize = () => {
        this.windowWidth = window.innerWidth;
      };
    }
  },
  mounted() {
    this.getRelativeBlogs();
  },
  computed: {
    article() {
      return getters.selectedArticle();
    },
    existingArticle() {
      return "_id" in this.article.details;
    },
    page() {
      return this.$route.params.page.split(/(?=[A-Z])/).join(" ");
    },
    selectedLang() {
      return getters.selectedLang();
    },
    contentLength() {
      if (this.windowWidth < 992) {
        return 200;
      } else {
        return 120;
      }
    },
    checkArb() {
      return getters.checkArb();
    },
  },
  methods: {
    goToArticle(article) {
      mutations.setArticle(article.section, article);
      this.$router.push({
        name: "Article",
        params: { page: this.$route.params.page, title: article.slug },
        replace: true,
      });
      this.getRelativeBlogs();
    },
    getArticle() {
      axios({
        url: `${this.$apiURL()}/hub/blog/article`,
        params: this.path,
      })
        .then(({ data }) => {
          this.goToArticle(data.article);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRelativeBlogs() {
      axios({
        url: `${this.$apiURL()}/hub/blog/relativeBlogs`,
        params: {
          id: this.article.details._id,
          section: this.article.section,
          lang: this.selectedLang,
        },
      })
        .then(({ data }) => {
          this.relativeBlogList = data.blogsList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.breadcrumb {
  background: #f2f3f4;
}
.ql-align-center {
  text-align: center;
}
.ql-align-right {
  text-align: right;
}
.ql-align-justify {
  text-align: justify;
}
</style>
