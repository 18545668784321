<template>
  <div v-for="(item, idx) in $tm('mobileTradingInfo')" :key="idx">
    <div class="container-fluid container-top accounts half">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("mobileTrading") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-8 col-md-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
          <div class="col-12 text-center mt-5">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{
              $t("openLiveAccount")
            }}</a>
          </div>
          <div class="col-12 text-center mb-3">
            <img
              src="/img/platforms-menu/iphone.png"
              alt="iphone"
              class="img-fluid"
            />
          </div>
          <div class="col-lg-6 col-md-8">
            <div class="row d-flex justify-content-center">
              <div class="col-6 mb-sm-0 mb-3 text-center">
                <a
                  target="_blank"
                  href="https://www.metatrader4.com/en/trading-platform/help/userguide/install_mac"
                  class="d-none d-lg-block"
                >
                  <img
                    src="/img/platforms-menu/download-mac.svg"
                    alt="download-mac"
                    class="img-fluid"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://download.mql5.com/cdn/mobile/mt4/android?server=TrioMarkets-Demo%20Server,TrioMarkets-Live%20Server"
                  class="d-block d-lg-none"
                >
                  <img
                    src="/img/platforms-menu/app-store.svg"
                    alt="download-apple"
                    class="img-fluid"
                  />
                </a>
              </div>
              <div class="col-6 text-center">
                <a
                  target="_blank"
                  href="https://download.mql5.com/cdn/mobile/mt4/ios?server=TrioMarkets-Demo%20Server,TrioMarkets-Live%20Server"
                  class="d-none d-lg-block"
                  ><img
                    src="/img/platforms-menu/download-windows.svg"
                    alt="download-windows"
                    class="img-fluid"
                  />
                </a>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4"
                  class="d-block d-lg-none"
                  ><img
                    src="/img/platforms-menu/google-play.svg"
                    alt="download-play-store"
                    class="img-fluid"
                /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">
          {{ $t("keyFeatures") }}
        </h2>
        <hr class="blue mbhr" />
      </div>
      <div
        class="row d-flex justify-content-center my-lg-5 my-4"
        v-scrollanimation
      >
        <div class="col-lg-10 offset-lg-1">
          <div class="row d-flex justify-content-center">
            <div
              v-for="(point, idx) in item.points"
              :key="idx"
              class="col-md-6 col-sm-10 col-11 mb-lg-2 mb-4 points"
            >
              <div
                class="feature-box mobile-trading d-flex points align-items-center"
              >
                <img
                  src="/img/check.svg"
                  :class="`img-fluid check mb-lg-0 mb-2 mt-lg-0 mt-3 ${
                    checkArb ? ' ms-lg-3' : ' me-lg-3'
                  }`"
                  alt="check"
                />
                <p class="mb-lg-0 fs-5 lh-1" v-html="point"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center my-5">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{
            $t("openLiveAccount")
          }}</a>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps stepStyle="dark" v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "MobileTrading",
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped></style>
