<template>
  <div
    class="container-fluid text-center animate__animated animate__slideInUp"
    id="disclaimer"
  >
    <div class="row">
      <div class="col-lg-4 col-md-3 pe-md-0 disclaimer-carousel">
        <Carousel
          :items-to-scroll="9"
          :wrap-around="true"
          :breakpoints="breakpoints"
          v-if="quotes.length > 0"
        >
          <slide v-for="quote in quotes" :key="quote.SYMBOL">
            <div class="block d-flex">
              <picture class="d-flex align-items-center">
                <img
                  :src="`/img/disclaimer/${quote.SYMBOL}.svg`"
                  :alt="quote.SYMBOL"
                  class="pr-md-3 pr-2 img-fluid"
                />
              </picture>

              <div class="text-white">
                <span class="text-white fw-bold">{{ quote.SYMBOL }}</span>
                <br />
                {{
                  quote.SYMBOL == "EURUSD" || quote.SYMBOL == "GBPUSD"
                    ? quote.BID.toFixed(4)
                    : quote.BID
                }}
                <span
                  :style="`font-size: .7rem; color:${
                    quote.DIRECTION == 0 ? 'red' : 'green'
                  }`"
                  >{{ quote.DIRECTION == 0 ? "&#8595;" : "&#8593;" }}
                </span>
              </div>
            </div>
          </slide>
        </Carousel>
      </div>
      <div
        class="disclaimer-text col-lg-8 col-md-9 d-flex align-items-center justify-content-between"
      >
        <span
          :class="` text-white px-md-4 m-0  ${
            checkArb ? 'text-end' : 'text-start'
          }`"
        >
          <span
            class="text-white"
            v-html="$t('disclaimer', { license: license })"
          ></span>
          <a target="_blank" :href="registerURL" class="btn mobile d-xl-none">{{
            $t("openAccount")
          }}</a>
        </span>

        <div class="mb-1 d-none d-xl-block">
          <a target="_blank" :href="registerURL" class="btn success">{{
            $t("openAccount")
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters } from "../store";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import axios from "axios";
export default {
  name: "Disclaimer",
  data: () => ({
    quotes: [],
    breakpoints: {
      1371: {
        itemsToShow: 3,
      },
      1000: {
        itemsToShow: 2,
        snapAlign: "left",
      },
      768: {
        temsToShow: 1,
      },
      540: {
        itemsToShow: 3,
      },
      350: {
        itemsToShow: 3,
        snapAlign: "left",
      },
    },
  }),
  components: {
    Carousel,
    Slide,
  },
  async created() {
    await axios
      .get(`${this.$apiURL()}/hub/quotes`)
      .then(({ data }) => {
        this.quotes = data.quotesList;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  computed: {
    license() {
      return getters.license();
    },
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
