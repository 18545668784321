<template>
  <div class="container-fluid" id="homeMarkets">
    <div class="row d-flex justify-content-center mt-5">
      <h2 class="col-12 title text-center">
        {{ $t("markets") }}
      </h2>
      <hr class="blue" />
    </div>
    <div class="row">
      <div class="col-12">
        <carousel-3d
          :perspective="0"
          :height="750"
          :width="400"
          :space="300"
          :display="5"
        >
          <slide
            v-for="(market, index) in $tm('marketsHome')"
            :key="index"
            :index="index"
            class="border-0"
          >
            <div style="z-index: -1" class="top-image">
              <img :src="`/img/home-markets/${market.img}.jpg`" alt="market" />
            </div>
            <div class="holder p-sm-1 p-0">
              <router-link
                :to="`/${$route.params.lang}/markets/${market.name}`"
              >
                <h4 class="pb-sm-1">{{ market.name }}</h4>
              </router-link>
              <p class="mx-3">{{ market.text }}</p>
              <div class="buttonset">
                <router-link
                  :to="`/${$route.params.lang}${market.link}`"
                  class="btn btn-blue mt-3"
                  >{{ $t("tradeNow") }}</router-link
                >
              </div>
            </div>
          </slide>
        </carousel-3d>
      </div>
    </div>
  </div>
</template>
<script>
import Carousel3d from "./carousel/Carousel3d.vue";
import Slide from "./carousel/Slide.vue";
export default {
  name: "HomeMarkets",
  components: {
    Carousel3d,
    Slide,
  },
};
</script>
<style lang="scss" scoped>
</style>
