<template>
  <div id="lnr">
    <div :class="`container-fluid container-top accounts lnr`">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("licenseRegulations") }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div v-if="license == 'eu'" class="row d-md-block d-none">
          <div class="col-12 d-flex justify-content-center position-relative">
            <img src="/img/company/lnr/world.png" alt="map" class="img-fluid" />
            <div
              v-for="(item, idx) in flagFilter"
              :key="idx"
              :class="`dot position-absolute ${item.class}`"
            >
              <div class="hover-dot">
                <div class="country-box text-right">
                  <img
                    :src="`/img/company/lnr/${item.flag}.png`"
                    alt="flag"
                    class="img-fluid flag"
                  />
                  <!-- <img
                    :src="`/img/company/lnr/${item.logo}`"
                    alt="logo"
                    class="img-fluid my-1"
                  /> -->
                  <div class="ms-5">
                    <strong>Reg.Authority:</strong>
                    <p v-html="item.text"></p>
                    <p><strong>Reg.No.: </strong>{{ item.reg }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="row d-md-block d-none">
          <div class="col-12 d-flex justify-content-center position-relative">
            <img
              v-if="license === 'global'"
              src="/img/company/lnr/worldMap_benor.svg"
              alt="map"
              class="img-fluid"
            />
            <img
              v-if="license === 'africa'"
              src="/img/company/lnr/worldMap_africa.svg"
              alt="map"
              class="img-fluid"
            />
          </div>
        </div>
        <div
          v-if="license == 'eu'"
          class="row d-flex justify-content-center d-md-none mt-4"
        >
          <div v-for="(item, idx) in flagFilter" :key="idx" class="col-12 mb-4">
            <div
              class="d-flex justify-content-between align-items-center country-body-mobile"
            >
              <img
                :src="`/img/company/lnr/mobile/${item.flagMob}.svg`"
                :alt="item.flag"
                class="img-fluid flag-mobile"
              />

              <div class="text-right">
                <strong class="text-white">Reg.Authority</strong>
                <p class="text-white" v-html="item.text"></p>
                <strong class="text-white">Reg.Number:</strong><br />
                <a target="_blank" :href="item.link">{{ item.reg }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div
        v-for="(text, idx) in $tm('licenseRegulationInfo')"
        :key="idx"
        class="row"
      >
        <div v-if="license === 'africa'" class="col-12 mt-5">
          <div v-for="(item, idx) in africaFilter" :key="idx" class="cysec">
            <img
              :src="`/img/company/lnr/${item.flag}.png`"
              :alt="item.flag"
              class="img-fluid reg-flag top"
            />
            <div class="cysec-body">
              <img
                :src="`/img/company/lnr/${item.logo}`"
                alt="logo"
                class="img-fluid"
              />
              <div
                class="cysec-desc fs-6 text-justify"
                v-html="text.africa"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="benorAfrica" class="col-12 mt-5">
          <div v-for="(item, idx) in maurFilter" :key="idx" class="cysec">
            <div class="text-center">
              <img
                :src="`/img/company/lnr/${item.logo}`"
                alt="logo"
                class="img-fluid d-xl-none d-xxl-block d-lg-none d-md-none"
                id="licenseimg"
              />
            </div>
            <img
              :src="`/img/company/lnr/${item.flag}.png`"
              :alt="item.flag"
              class="img-fluid reg-flag top d-md-block d-none"
            />
            <div class="cysec-body">
              <img
                :src="`/img/company/lnr/${item.logo}`"
                alt="logo"
                class="img-fluid d-md-block d-none"
              />
              <div class="cysec-desc" v-html="text.global"></div>
            </div>
          </div>
        </div>
        <!-- <div v-if="license == 'global'" class="col-12 mt-5"> -->
        <div v-if="benorAfrica" class="col-12 mt-5">
          <div v-for="(item, idx) in dubaiFilter" :key="idx" class="cysec">
            <div class="text-center">
              <img
                :src="`/img/company/lnr/${item.logo}`"
                alt="logo"
                class="img-fluid d-xl-none d-xxl-block d-lg-none d-md-none"
              />
            </div>
            <img
              :src="`/img/company/lnr/${item.flag}.png`"
              :alt="item.flag"
              class="img-fluid reg-flag top d-md-block d-none"
            />
            <div class="cysec-body">
              <img
                :src="`/img/company/lnr/${item.logo}`"
                alt="logo"
                class="img-fluid d-md-block d-none"
              />
              <div
                class="cysec-desc fs-6 text-justify"
                v-html="text.dubai"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="license == 'eu'" class="col-12 mt-5 d-md-block d-none">
          <div v-for="(item, idx) in cyFilter" :key="idx" class="cysec">
            <img
              :src="`/img/company/lnr/${item.flag}.png`"
              :alt="item.flag"
              class="img-fluid reg-flag top"
            />
            <div class="cysec-body">
              <!-- <img
                :src="`/img/company/lnr/${item.logo}`"
                alt="logo"
                class="img-fluid"
              /> -->
              <div
                :class="`cysec-desc ${checkArb ? 'text-end' : ''}`"
                v-html="text.eu"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-md-block d-none">
        <div v-if="license == 'eu'" class="row mt-5 pt-5">
          <div
            v-for="(item, idx) in regulationsFilter"
            :key="idx"
            class="col-xl-4 col-md-6 mb-5"
          >
            <div class="regulation">
              <img
                :src="`/img/company/lnr/${item.flag}.png`"
                :alt="item.flag"
                class="img-fluid reg-flag"
              />
              <div class="regulation-body text-right">
                <!-- <img
                  :src="`/img/company/lnr/${item.logo}`"
                  :alt="item.flag"
                  class="img-fluid reg-logo"
                /> -->
                <div class="ms-5">
                  <strong>Reg.Authority</strong>
                  <p v-html="item.text"></p>
                  <strong>Reg.Number</strong><br />
                  <a target="_blank" :href="item.link">{{ item.reg }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      v-for="(item, idx) in $tm('licenseRegulationInfo')"
      :key="idx"
      class="container d-md-none"
    >
      <div class="row d-flex justify-content-center mt-5 mb-3">
        <h2 class="col-12 text-center title">
          {{ item.title }}
        </h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center mb-5">
        <div
          v-for="(point, idx) in item.importantLinks"
          :key="idx"
          class="col-sm-10"
        >
          <router-link
            to="/company/legal-documents"
            class="btn w-100 border-blue fs-5 mb-3"
            >{{ point }}</router-link
          >
        </div>
      </div>
    </div> -->
    <Steps />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "LicenseRegulation",
  data: () => ({
    mapPoints: [
      {
        flag: "maur",
        flagMob: "maur",
        logo: "maurlogo.svg",
        text: `FSC <br>(Financial Services Commission)`,
        reg: "160496",
        link: "https://www.fscmauritius.org/en/supervision/register-of-licensees/register-of-licensees-details?licence_no=C118023678&key=&cat=_GB&code=",
        class: "mauritius",
      },
      {
        flag: "dubai",
        flagMob: "dubai",
        logo: "DED.png",
        text: `DED <br> (The Department of Economy & Tourism in Dubai)`,
        reg: "1082819",
        link: "https://www.consob.it/web/area-pubblica/imprese-investimento-senza-succursale#_48_INSTANCE_oYblco9xeIFT_%3Dhttp%253A%252F%252Fwww.consob.it%252Fdocuments%252F46180%252F46181%252FCOM_115_IT.html%252F2b5e19cf-e3c9-4d87-8847-e9003e7a40b7%253F",
        class: "dubai",
      },
      {
        flag: "africa",
        flagMob: "africa",
        logo: "fsca.png",
        text: ``,
        reg: "53065",
        link: "",
        class: "africa",
      },
      {
        flag: "cyprus",
        flagMob: "cy",
        logo: "CYSEC.png",
        text: "CySEC <br> (Cyprus Securities and Exchange commission)",
        reg: "268/15",
        link: "https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/71266/",
        class: "cyprus",
      },
      {
        flag: "germany",
        flagMob: "ger",
        logo: "BaFin.png",
        text: `BaFin <br>(Bundesanstalt für Finanzdienstleistungs<br>-aufsicht)`,
        reg: "143165",
        link: "https://portal.mvp.bafin.de/database/InstInfo/institutDetails.do?cmd=loadInstitutAction&institutId=143165",
        class: "germany",
      },
      {
        flag: "spain",
        flagMob: "spain",
        logo: "CNMV.png",
        text: `CNMV <br> (Comisión Nacional del Mercado de Valores)`,
        reg: "3946",
        link: "http://www.cnmv.es/portal/Consultas/ESI/ESISExtranjerasLP.aspx?tipo=CLP&numero=3946",
        class: "spain",
      },
      {
        flag: "portugal",
        flagMob: "por",
        logo: "CMVM.png",
        text: `CMVM <br> (Comissão do Mercado de Valores Mobiliários)`,
        reg: "Link",
        link: "https://web3.cmvm.pt/english/sdi/ifs/LPS/lps_dtl.cfm?num_ent=%26%23%24WU%21-Q%3D%0A&tip_if=%24%3E%23%3A%3B%3C%20%20%20%0A",
        class: "portugal",
      },
      // {
      //   flag: "uk",
      //   flagMob: "eng",
      //   logo: "FCA.png",
      //   text: `FCA <br> (Financial Conduct Authority)`,
      //   reg: "702759",
      //   link: "https://register.fca.org.uk/s/",
      //   class: "uk",
      // },
      {
        flag: "neth",
        flagMob: "neth",
        logo: "AFM.svg",
        text: `AFM <br> (Dutch Authority for the Financial Markets)`,
        reg: "Link",
        link: "https://www.afm.nl/en/sector/registers/vergunningenregisters/beleggingsondernemingen/details?id=16F179F6-F61F-E511-BA3E-005056BE011C&KeyWords=EDR",
        class: "neth",
      },
      {
        flag: "france",
        flagMob: "fr",
        logo: "ACPR.svg",
        text: `ACPR <br>(Banque De France)`,
        reg: "60724",
        link: "https://www.regafi.fr/spip.php?page=results&type=simple&id_secteur=3&lang=en&denomination=edr+financial",
        class: "france",
      },
      {
        flag: "italy",
        flagMob: "ital",
        logo: "CONSOB.png",
        text: `CONSOB <br>(Commissione Nazionale per le Società e la Borsa)`,
        reg: "4175",
        link: "https://www.consob.it/web/area-pubblica/imprese-investimento-senza-succursale#_48_INSTANCE_oYblco9xeIFT_%3Dhttp%253A%252F%252Fwww.consob.it%252Fdocuments%252F46180%252F46181%252FCOM_115_IT.html%252F2b5e19cf-e3c9-4d87-8847-e9003e7a40b7%253F",
        class: "italy",
      },
    ],
  }),
  computed: {
    license() {
      return getters.license();
    },
    benorAfrica() {
      return this.license === "global" || this.license === "africa";
    },
    flagFilter() {
      if (this.license == "eu") {
        return this.mapPoints.filter((item) => {
          return (
            item.flag !== "maur" &&
            item.flag !== "dubai" &&
            item.flag !== "africa"
          );
        });
      } else if (this.benorAfrica) {
        // } else if (this.license == 'global') {
        return this.mapPoints.filter((item) => {
          return item.flag !== "cyprus";
        });
      } else {
        return this.mapPoints;
      }
    },
    cyFilter() {
      return this.mapPoints.filter((item) => {
        return item.flag === "cyprus";
      });
    },
    maurFilter() {
      return this.mapPoints.filter((item) => {
        return item.flag === "maur";
      });
    },
    dubaiFilter() {
      return this.mapPoints.filter((item) => {
        return item.flag === "dubai";
      });
    },
    africaFilter() {
      return this.mapPoints.filter((item) => {
        return item.flag === "africa";
      });
    },
    regulationsFilter() {
      return this.mapPoints.filter((item) => {
        return (
          item.flag !== "maur" &&
          item.flag !== "cyprus" &&
          item.flag !== "dubai" &&
          item.flag !== "africa"
        );
      });
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped></style>
