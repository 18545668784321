const slideInRightObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("slide-right");
        slideInRightObserver.unobserve(entry.target);
      }
    });
  });
  export default {
    mounted(el) {
      el.classList.add("before-slide-right");
      slideInRightObserver.observe(el);
    },
  };