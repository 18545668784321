export default {
  en: {
    upTo: "Up To",
    introducingBrokerInfo: [
      {
        title: "Become an Introducing Broker with TrioMarkets™",
        titleTwo: "Earn more as a TrioMarkets™ Introducing Broker",
        text: "Earn competitive rebates on client referrals with our dedicated account managers helping you along the way. The TrioMarkets™ IB program is designed to offer individuals and corporate clients an opportunity to earn ongoing revenue from the trading activity of their clients.",
        titleThree: "The more clients you sign up, the more income you get",
        textTwo:
          "As a Partner of TrioMarkets™, you will have a dedicated partner support. Our multilingual Support Team will assist you as you grow your business. The TrioMarkets™ Partner Portal provides you data on introduced clients and give you access to professional Marketing Materials such as Banner, Landing Pages and more.",
        benefits: "Benefits",
        points: [
          { img: "1", name: "Flexible rebates <br> paid daily" },
          { img: "2", name: "Lifetime rebates <br> on clients" },
          { img: "3", name: "Tracking <br> Technology" },
          { img: "4", name: "Marketing <br> Materials" },
          { img: "5", name: "Fast <br> on-boarding" },
          { img: "6", name: "No upfront <br> investment" },
        ],
        perLot: "Per Lot",
        textThree: "Choose TrioMarkets™ as Your Strategic Affiliate Partner!",
        titleFour: "Trustworthy Transparent Partner",
        textFour:
          "Our multi-tiered, progressive commission structure is designed to support your growth, helping you earn on every referred customer for life.",
      },
    ],
    cpaInfo: [
      {
        head: "CPA Affiliate Program",
        title: "What is TrioMarkets™ Referral Program?",
        titleTwo: "Become a CPA Referral with TrioMarkets™",
        text: "The TrioMarkets™ Referral Program is a highly profitable and risk-free partnership model, which allows you to generate profits by attracting a broad audience through the wide range of media tools we provide you with. By becoming a partner of TrioMarkets™, you will receive a payout for every activated customer.",
        titleThree:
          "The more customers you attract to trading, the more impressive your profit will be.",
        textTwo:
          "As a Partner of TrioMarkets™, you will have a dedicated partner support. Our multilingual Support Team will assist you as you grow your business. The TrioMarkets™ Partner Portal provides you data on introduced clients and give you access to professional Marketing Materials such as Banner, Landing Pages and more.",
        benefits: "Benefits of Joining us",
        points: [
          { img: "1", name: "Reliable <br> Broker" },
          { img: "2", name: "Security" },
          { img: "3", name: "Multi-level <br> Payout structure" },
          { img: "4", name: "Promotional <br> Materials" },
          { img: "5", name: "Advanced real-time <br> Tracking Technology" },
          { img: "6", name: "Monthly <br> Payout" },
        ],
        cpa: "CPA",
        textThree: "Choose TrioMarkets™ as Your Strategic Affiliate Partner!",
        titleFour: "Trustworthy Transparent Partner",
        textFour:
          "We provide a multi-tiered CPA structure with the flexibility to suit a diverse range of referral setups.",
      },
    ],
    promotionsInfo: [
      {
        text: "It is vital to us that our clients are constantly aware of new productions and promotions that we have. We believe that a promotion is a key element in putting across the benefits of our product and service to our customers. It is important for us to create awareness and to connect with clients so this sector will provide you with information on promotions and competitions. Choose what suits your trading.",
        textTwo:
          "See below all existing promotions and competitions for TrioMarkets™",
        bonus: "Bonus",
        regAndProf: `10% "Credit to Cash" Bonus`,
        bonusText:
          "10% credit bonus will be issued on each new deposit above 1,000 USD",
        title: "Refer a friend",
        textThree: "The more friends you bring the more benefits you get",
        textFour:
          "We have specifically created our Referral Program as a way of thanking you for the introductions of friends and family. Easily gain more by bringing friends and family to trade with TrioMarkets™ and earn up to 1000€/$/£.",
        titleTwo: "Cashback",
        textFive: `Get more out of your trading with CashBack at TrioMarkets™`,
        textSix:
          "Every trader receives up to 15% for every lot traded with TrioMarkets™. Trade more lots and gain more from the rebate.",
      },
    ],
  },
  fr: {
    upTo: "Jusqu'à",
    introducingBrokerInfo: [
      {
        title: "Devenez Apporteur d'Affaires avec TrioMarkets™",
        titleTwo:
          "Gagnez toujours plus en devenant Apporteur d'Affaires pour TrioMarkets™",
        text: "Bénéficiez de réductions compétitives sur les références de clients grâce à nos gestionnaires de compte dédiés qui vous aident tout au long du processus. Le programme TrioMarkets™ IB est conçu pour offrir aux particuliers et aux entreprises la possibilité de générer des revenus continus grâce au trading de leurs clients.",
        titleThree:
          "Plus vous référer des clients, plus vous generez des revenus",
        textTwo:
          "En tant que partenaire de TrioMarkets™, vous bénéficierez d'un support partenaire dédié. Notre équipe d'assistance multilingue vous aidera à développer votre business. Le portail partenaire de TrioMarkets™ vous fournit des données sur les clients introduits et vous donne accès à des outils marketing tels que des bannières, des Landing Pages et plus encore.",
        benefits: "Avantages",
        points: [
          { img: "1", name: "Remises flexibles <br> payées quotidiennement" },
          { img: "2", name: "Réductions sur <br> client à vie" },
          { img: "3", name: "Technologie <br> de suivi" },
          { img: "4", name: "Outils <br> Marketing" },
          { img: "5", name: "Intégration <br> Rapide" },
          { img: "6", name: "Pas d'Investissement <br> prealable" },
        ],
        perLot: "Par Lot",
        textThree:
          "Choisissez TrioMarkets™ comme votre Partenaire Stratégique !",
        titleFour: "Confiance, Transparence, Fiabilité",
        textFour:
          "Notre structure de commissions progressives à plusieurs niveaux est conçue pour soutenir votre croissance et vous aider à gagner à vie sur chaque client référé.",
      },
    ],
    cpaInfo: [
      {
        head: "Programe Affilié au CPA",
        title: "Devenez Affilié au CPA avec TrioMarkets™ ",
        titleTwo: "Qu'est-ce que le programme de parrainage TrioMarkets™?",
        text: "Le programme de parrainage TrioMarkets™ est un modèle de partenariat hautement rentable et sans risque, qui vous permet de générer des profits en attirant un large public grâce au large éventail d'outils marketing que nous mettons à votre disposition. En devenant partenaire de TrioMarkets™, vous recevrez un paiement pour chaque client activé.",
        titleThree:
          "Plus vous attirez de clients dans le trading, plus vos bénéfices seront impressionnants.",
        textTwo:
          "En tant que partenaire de TrioMarkets™, vous bénéficierez d'un support partenaire dédié. Notre équipe d'assistance multilingue vous aidera à développer votre business. Le portail des partenaires de TrioMarkets™ vous fournit des données sur les clients introduits et vous donne accès à des outils marketing professionnels tels que des bannières, des Landing Pages et plus encore.",
        benefits: "Avantages de nous Rejoindre",
        points: [
          { img: "1", name: "Broker Fiable" },
          { img: "2", name: "Sécurité" },
          { img: "3", name: "Structure de <br> paiement à plusieurs niveaux" },
          { img: "4", name: "Matériel <br> promotionnel" },
          { img: "5", name: "Technologie de <br> suivi en temps réel" },
          { img: "6", name: "Paiement <br> mensuel" },
        ],
        cpa: "CPA",
        textThree:
          "Choisissez TrioMarkets™ comme votre Partenaire Stratégique !",
        titleFour: "Confiance, Transparence, Fiabilité",
        textFour:
          "Nous fournissons une structure CPA à plusieurs niveaux avec la flexibilité nécessaire pour convenir à vos besoins.",
      },
    ],
    promotionsInfo: [
      {
        text: "Il est essentiel pour nous que nos clients soient constamment informés des nouvelles productions et promotions que nous proposons. Nous croyons qu'une promotion est un élément clé pour faire connaître les avantages de nos produits et services à nos clients. Il est important pour nous de sensibiliser et de nouer des relations avec les clients afin que ce secteur vous fournisse des informations sur les promotions et les concours. Choisissez ce qui vous convient le mieux.",
        textTwo:
          "Voir ci-dessous toutes les promotions et concours proposés par TrioMarkets™",
        bonus: "Bonus",
        regAndProf:
          "Inscrivez-vous et profitez de notre bonus de premier dépôt.",
        bonusText:
          "Recevez un bonus de crédit de 10 % pour votre premier dépôt supérieur à 1 000 USD/EUR. Le bonus maximum par client est de 1 000 USD/EUR et sera crédité dans un délai d'un jour ouvrable.",
        title: "Parrainer un ami",
        textThree: "PLUS VOUS PARRAINEZ D'AMIS, PLUS VOUS GAGNEZ",
        textFour:
          "Nous avons spécifiquement créé notre programme de parrainage afin de vous remercier pour les présentations d'amis et de famille. Gagnez facilement plus en invitant vos amis et votre famille à trader avec TrioMarkets™ et gagnez jusqu'à 1 000 €/$/£.",
        titleTwo: "Cashback",
        textFive: `Tirez le meilleur parti de votre trading avec le CashBack sur TrioMarkets™`,
        textSix: `Chaque client reçoit jusqu' à 15% pour chaque lot tradé avec TrioMarkets™. Tradez plus de lots et bénéficiez davantage de la remise.`,
      },
    ],
  },
  it: {
    upTo: "Fino",
    introducingBrokerInfo: [
      {
        title: "Diventa un Introducing Broker con TrioMarkets™",
        titleTwo:
          "Guadagna sempre di più diventando un Introducer Broker  per TrioMarkets™",
        text: "Guadagna commissionicompetitive sulle segnalazioni dei clienti con i nostri account manager dedicati che ti aiutano lungo il percorso. Il programma TrioMarkets™ IB è progettato per offrire a privati e clienti aziendali l'opportunità di guadagnare entrate continue dall'attività di trading dei loro clienti.",
        titleThree: "Più clienti ti iscrivi, più guadagni ottieni",
        textTwo:
          "In qualità di partner di TrioMarkets™, avrai un supporto partner dedicato. Il nostro team di supporto multilingue ti assisterà nella crescita della tua attività. Il portale per i partner di TrioMarkets™ ti fornisce dati sui clienti presentati e ti dà accesso a materiali di marketing professionali come banner, pagine di destinazione e altro ancora.",
        benefits: "Vantaggi",
        points: [
          { img: "1", name: "Commissioni flessibili <br> pagati giornalmente" },
          { img: "2", name: "Commissioni a <br> vita sui clienti" },
          { img: "3", name: "Tecnologia <br> di monitoraggio" },
          { img: "4", name: "Materiale <br> di Marketing" },
          { img: "5", name: "FIntegrazioni <br> rapide" },
          { img: "6", name: "Nessun <br> investimento iniziale" },
        ],
        perLot: "Per Lotto",
        textThree:
          "Scegli TrioMarkets™ come tuo partner di affiliazione strategico!",
        titleFour: "Partner Trasparente Affidabile",
        textFour:
          "La nostra struttura di commissioni progressiva a più livelli è progettata per supportare la tua crescita, aiutandoti a guadagnare su ogni cliente segnalato per tutta la vita.",
      },
    ],
    cpaInfo: [
      {
        head: "Programma affiliazione CPA",
        title: "Diventa un referral CPA con TrioMarkets™",
        titleTwo: "Cos'è il programma referral di TrioMarkets™?",
        text: "Il TrioMarkets™ Referral Program è un modello di partnership altamente redditizio e privo di rischi, che ti consente di generare profitti attirando un vasto pubblico attraverso l'ampia gamma di strumenti multimediali che ti offriamo. Diventando un partner di TrioMarkets™, riceverai un pagamento per ogni cliente attivato.",
        titleThree:
          "Più clienti attiri nel trading, più impressionante sarà il tuo profitto.",
        textTwo:
          "In qualità di partner di TrioMarkets™, avrai un supporto partner dedicato. Il nostro team di supporto multilingue ti assisterà nella crescita della tua attività. Il portale per i partner di TrioMarkets™ ti fornisce dati sui clienti presentati e ti dà accesso a materiali di marketing professionali come banner, pagine di destinazione e altro ancora.",
        benefits: "Vantaggi di Unirsi a noi",
        points: [
          { img: "1", name: "Broker <br> affidabile" },
          { img: "2", name: "Sicurezza" },
          { img: "3", name: "Struttura dei <br> pagamenti multilivello" },
          { img: "4", name: "Materiale <br> promozionale" },
          {
            img: "5",
            name: "Tecnologia avanzata di <br> monitoraggio in tempo reale",
          },
          { img: "6", name: "Pagamento <br> mensile" },
        ],
        cpa: "CPA",
        textThree:
          "Scegli TrioMarkets™ come tuo partner di affiliazione strategico!",
        titleFour: "Partner trasparente affidabile",
        textFour:
          "Forniamo una struttura CPA a più livelli con la flessibilità necessaria per adattarsi a una vasta gamma di configurazioni di referral.",
      },
    ],
    promotionsInfo: [
      {
        text: "Per noi è fondamentale che i nostri clienti siano costantemente consapevoli delle nuove produzioni e promozioni che abbiamo. Crediamo che una promozione sia un elemento chiave per trasmettere i vantaggi del nostro prodotto e servizio ai nostri clienti. Per noi è importante creare consapevolezza e entrare in contatto con i clienti, quindi questo settore ti fornirà informazioni su promozioni e concorsi. Scegli ciò che si adatta al tuo trading.",
        textTwo:
          "Vedi sotto tutte le promozioni e le competizioni esistenti per TrioMarkets™",
        bonus: "Bonus",
        regAndProf: `Bonus del 10% "Credit to Cash`,
        bonusText:
          "Il 10% di bonus di credito verrà emesso su ogni nuovo deposito superiore a 1.000 dollari USA.",
        title: "Presenta un amico",
        textThree: "PIÙ AMICI PORTI PIÙ BENEFICI OTTIENI",
        textFour:
          "Abbiamo creato appositamente il nostro Programma referral per ringraziarti delle presentazioni di amici e familiari. Guadagna facilmente di più portando amici e familiari a fare trading con TrioMarkets™ e guadagna fino a 1000 € / $ / £.",
        titleTwo: "Cashback",
        textFive: `Ottieni di più dal tuo trading con CashBack su TrioMarkets™`,
        textSix:
          "Ogni trader riceve fino al 15% per ogni lotto scambiato con TrioMarkets™. Scambia più lotti e guadagna di più dallo sconto.",
      },
    ],
  },
  de: {
    upTo: "Bis zu",
    introducingBrokerInfo: [
      {
        title: "Werden Sie IB Introducing Broker mit TrioMarkets™",
        titleTwo:
          "Verdienen Sie mehr als IB Introducing Broker von TrioMarkets™",
        text: "Verdienen Sie wettbewerbsfähige Rabates auf Kundenempfehlungen mit unseren engagierten Account-Managern, die Ihnen auf dem Weg helfen. Das Triomarkets IB-Programm wurde entwickelt, um Privatpersonen und Firmenkunden die Möglichkeit zu bieten, laufende Einnahmen aus den Trading-Aktivitäten ihrer Kunden zu erzielen.",
        titleThree:
          "Je mehr Kunden Sie anmelden, desto mehr Einkommen erhalten Sie",
        textTwo:
          "Als Partner von TrioMarkets, profitieren Sie von einem engagierten Partner-Support. Hierbei unterstützt Sie unser mehrsprachiges Kundenbetreuerteam, bei dem Ausbau Ihrer Geschäfte. Das TrioMarkets Partnerportal liefert Ihnen Daten über eingeführte Kunden und gibt Ihnen Zugang zu professionellem Marketingmaterialien wie zum Beispiel Banner, Zielseiten und vielem mehr.",
        benefits: "Vorteile",
        points: [
          {
            img: "1",
            name: "Flexible Rabates die <br> täglich ausgezahlt werden",
          },
          { img: "2", name: "Lebenslange <br> Kunden-Rabates" },
          { img: "3", name: "Technologisches <br> Erfassungssystem" },
          { img: "4", name: "Marketing <br> Materialien" },
          { img: "5", name: "Schnelle <br> Integrierung" },
          { img: "6", name: "Keine <br> Vorleistung" },
        ],
        perLot: "Pro Lot",
        textThree: "Wählen Sie TrioMarkets™ als Strategiepartner!",
        titleFour: "Vertrauenswürdiger und Transparenter Partner",
        textFour:
          "Unser mehrstufiges Konzept mit einer progressiven Provisionsstruktur ist darauf ausgelegt, Ihr Wachstum zu unterstützen und Ihnen dabei zu helfen, an jedem geworbenen Kunden ein Leben lang zu verdienen.",
      },
    ],
    cpaInfo: [
      {
        head: "CPA Partner Programm",
        title: "Werden Sie ein CPA Vermittler von TrioMarkets™",
        titleTwo: "Was ist das TrioMarkets™ Vermittlerprogramm?",
        text: "Das TrioMarkets™ Vermittlerprogramm ist ein sehr profitabeles und risikofreies Partnerprogramm, das es Ihnen ermöglicht, Gewinne zu erzielen und dabei ein breites Publikum aufzubauen mithilfe der bereitgestellten Marketinginstrumente. Als Partner von TrioMarkets™ bekommen Sie eine Auszahlung für jeden aktivierten Kunden.",
        titleThree:
          "Je mehr Kunden Sie davon überzeugen können, mit dem Handeln anzufangen, desto beeindruckender wird Ihr Gewinn sein.",
        textTwo:
          "Als Partner von TrioMarkets™, profitieren Sie von einem engagierten Partner-Support. Hierbei unterstützt Sie unser mehrsprachiges Kundenbetreuerteam, bei dem Ausbau Ihrer Geschäfte. Das TrioMarkets™ Partnerportal liefert Ihnen Daten über eingeführte Kunden und gibt Ihnen Zugang zu professionellem Marketingmaterialien wie zum Beispiel Banner, Landing Pages und vielem mehr.",
        benefits: "Vorteile einer Zusammenarbeit",
        points: [
          { img: "1", name: "Zuverlässiger <br> Broker" },
          { img: "2", name: "Sicherheit" },
          { img: "3", name: "Mehrstufige <br> Auszahlungsstruktur" },
          { img: "4", name: "Verkaufsfördernde <br> Materalien" },
          {
            img: "5",
            name: "Fortgeschrittene <br> Verfolgungstechnologie in Echtzeit",
          },
          { img: "6", name: "Monatliche <br> Auszahlung" },
        ],
        cpa: "CPA",
        textThree:
          "Wählen Sie TrioMarkets™ als Ihren strategischen Handelspartner",
        titleFour: "Vertrauenswürdiger, Transparenter Partner",
        textFour:
          "Wir bieten Ihnen eine mehrstufige CPA-Struktur mit einem vielfältigen Angebot, welches sich Ihren Bedürfnisse anpasst.",
      },
    ],
    promotionsInfo: [
      {
        text: "Für uns ist es wichtig, dass unsere Kunden ständig über neue Produkte und Werbeaktionen informiert sind. Wir glauben, dass eine Promotion ein Schlüsselelement ist, um unseren Kunden die Vorteile unserer Produkte und Dienstleistungen zu vermitteln. Für uns ist es wichtig, Bewusstsein zu schaffen und mit den Kunden in Verbindung zu treten, damit dieser Bereich Sie über Werbeaktionen und Wettbewerbe informiert. Wählen Sie, was zu Ihnen passt.",
        textTwo:
          "Sehen Sie hier alle bestehenden Aktionen und Wettbewerbe von TrioMarkets™",
        bonus: "Bonus",
        regAndProf: `10% "Kredit zu Bargeld" Bonus`,
        bonusText:
          "10% Kreditbonus wird auf jede neue Einzahlung über 1.000 USD gewährt",
        title: "Empfehlen Sie einen Freund",
        textThree:
          "JE MEHR FREUNDE SIE EMPFEHLEN,<br> DESTO MEHR VORTEILE BEKOMMEN SIE",
        textFour:
          "Wir haben unser Empfehlungsprogramm speziell als Dankeschön für die Vorstellung von Freunden und Familie entwickelt. Gewinnen Sie ganz einfach mehr, indem Sie Freunde und Familie zum Traden mit TrioMarkets einladen und verdienen Sie bis zu 1000€.",
        titleTwo: "Cashback",
        textFive: `Holen Sie mehr aus Ihrem Trading <br> mit dem Cashback von TrioMarkets™`,
        textSix:
          "Jeder Trader erhält bis zu 15% für jedes gehandelnde Lot mit TrioMarkets™. Handeln Sie mehr Lots und erhalten Sie höhere Rebates.",
      },
    ],
  },
  cn: {
    upTo: "最高",
    introducingBrokerInfo: [
      {
        title: "成为TrioMarkets™的介绍经纪商",
        titleTwo: "作为TrioMarkets™的介绍经纪商赚取更多",
        text: "通过我们的专职客户经理在此过程中为您提供帮助, 您可以通过客户推荐获得有竞争力的返佣。TrioMarkets™ IB 计划旨在为个人和企业客户提供从其客户的交易活动中赚取持续收入的机会。",
        titleThree: "您注册的客户越多，您获得的收入就越多",
        textTwo:
          "作为TrioMarkets™的合作伙伴, 您将获得专门的合作伙伴支持。我们的多语种支持团队将在您发展业务时为您提供帮助。TrioMarkets™合作伙伴门户为您提供介绍客户的数据，并让您获取专业的营销材料，例如横幅、登陆页面等。",
        benefits: "好处",
        points: [
          { img: "1", name: "每天支付的<br>灵活返佣" },
          { img: "2", name: "客户<br>终身返佣" },
          { img: "3", name: "追踪技术" },
          { img: "4", name: "营销<br>材料" },
          { img: "5", name: "快速<br>开户" },
          { img: "6", name: "无前期<br>投资" },
        ],
        perLot: "每手",
        textThree: "选择 TrioMarkets™ 作为您的战略合作伙伴！",
        titleFour: "值得信赖且信息透明的合作伙伴 ",
        textFour:
          "我们多层次、进取的佣金结构旨在支持您的成长，帮助您从每一位推荐的客户身上赚取终身收益。",
      },
    ],
    cpaInfo: [
      {
        head: "CPA联盟计划",
        title: "成为 TrioMarkets™ 的 CPA 推荐人 ",
        titleTwo: "什么是 TrioMarkets™ 推荐计划？",
        text: "TrioMarkets™推荐计划是一种高利润且无风险的合作模式，它允许您通过我们为您提供的各种媒体工具吸引广泛的受众来产生利润。通过成为TrioMarkets™的合作伙伴，您将收到每位激活客户的佣金。",
        titleThree: "您介绍的交易客户越多，您的利润就越可观。",
        textTwo:
          "作为TrioMarkets™的合作伙伴，您将获得专门的合作伙伴支持。我们的多语种支持团队将在您发展业务时为您提供帮助。TrioMarkets™合作伙伴门户为您提供介绍客户的数据，并让您获取专业的营销材料，例如横幅、登陆页面等。",
        benefits: "加入我们的好处",
        points: [
          { img: "1", name: "可靠<br>的经纪商" },
          { img: "2", name: "安全" },
          { img: "3", name: "多层<br>返佣结构" },
          { img: "4", name: "宣传<br>资料" },
          { img: "5", name: "先进的<br>实时追踪技术" },
          { img: "6", name: "每月<br>支付返佣" },
        ],
        cpa: "CPA",
        textThree: "选择 TrioMarkets™ 作为您的战略合作伙伴！",
        titleFour: "值得信赖且信息透明的合作伙伴",
        textFour: "我们提供多层次的CPA返佣结构，可以灵活地适应各种推荐设置。",
      },
    ],
    promotionsInfo: [
      {
        text: "我们的客户不断了解我们的新产品和促销活动，这对我们来说至关重要。我们相信，促销是将我们的产品和服务的优势传递给客户的关键因素。 对我们而言，提高知名度并与客户建立联系非常重要，因此该部门将为您提供有关促销和比赛的信息。选择适合您的交易方式。",
        textTwo: "查看下方所有现有的 TrioMarkets™ 促销和竞赛 ",
        bonus: "奖金",
        regAndProf: `10%“信用转现金”红利`,
        bonusText: "每笔超过1,000美金的新入金将获得10%的信用赠金",
        title: "介绍个朋友",
        textThree: "你带来的朋友越多，你得到的好处就越多",
        textFour:
          "我们专门创建了我们的推荐计划，以感谢您介绍朋友和家人。推荐朋友和家人在 TrioMarkets™ 交易，轻松获得更多收益，最高可赚取 1000 欧元/美元/英镑。",
        titleTwo: "返现款",
        textFive: `在 TrioMarkets™ 交易, 使用Cash-back, 从中可获得返现款的收益`,
        textSix:
          "对于在 Triomarkets 进行的每一手交易，每位交易者可获得高达 15% 的收益。交易更多手数并从返利中获得更多收益。",
      },
    ],
  },
  es: {
    upTo: "Hasta",
    introducingBrokerInfo: [
      {
        title: "Conviértete en un broker introductorio con TrioMarkets™",
        titleTwo: "Gane más como bróker introductorio de TrioMarkets™",
        text: "Obtenga reembolsos competitivos en referencias de clientes con nuestros gerentes de cuenta dedicados lo ayudan en el camino. El programa TrioMarkets™ IB está diseñado para ofrecer a los clientes individuales y corporativos la oportunidad de obtener ingresos continuos de la actividad comercial de sus clientes.",
        titleThree: "Cuantos más clientes registres, más ingresos obtendrás",
        textTwo:
          "Como socio de TrioMarkets™, tendrá una soporte de socios dedicado. Nuestro equipo de soporte multilingüe lo ayudará a hacer crecer su negocio. El portal de socios de TrioMarkets™ le proporciona datos sobre los clientes presentados y le brinda acceso a materiales de marketing profesionales, como banners, páginas de destino y más.",
        benefits: "Beneficios",
        points: [
          { img: "1", name: "Reembolsos flexibles <br> pagados diariamente" },
          { img: "2", name: "Reembolsos de por <br> vida en los clientes" },
          { img: "3", name: "Tecnología de <br> Seguimiento" },
          { img: "4", name: "Elementos de <br> Marketing" },
          { img: "5", name: "Incorporación <br> Rápida" },
          { img: "6", name: "Sin inversión <br> inicial" },
        ],
        perLot: "Por Lote",
        textThree: "Elija TrioMarkets™ como su afiliado estratégico Compañero!",
        titleFour: "Socio Transparente Confiable",
        textFour:
          "Nuestra estructura de comisiones progresivas de varios niveles está diseñado para respaldar su crecimiento, ayudándolo a ganar de por vida con cada cliente referido.",
      },
    ],
    cpaInfo: [
      {
        head: "Programa de Afiliados de CPA",
        title: "Conviértase en un CPA de referencia con TrioMarkets™",
        titleTwo: "Qué es el programa de referidos de TrioMarkets™?",
        text: "El programa de referidos de TrioMarkets™ es un programa altamente modelo de asociación rentable y sin riesgos, que le permite generar ganancias atrayendo a una amplia audiencia a través de la amplia gama de herramientas de medios que ponemos a su disposición. Al convertirse en socio de TrioMarkets™, recibirá un pago por cada cliente activado.",
        titleThree:
          "Cuantos más clientes atraiga al Trading, más impresionantes serán sus ganancias.",
        textTwo:
          "Como socio de TrioMarkets™, tendrá una soporte de socios dedicado. Nuestro equipo de soporte multilingüe lo ayudará a hacer crecer su negocio. El portal de socios de TrioMarkets™ le proporciona datos sobre los clientes presentados y le brinda acceso a materiales de marketing profesionales, como banners, páginas de destino y más.",
        benefits: "Beneficios de Unirse a nosotros",
        points: [
          { img: "1", name: "Broker <br> Confiable" },
          { img: "2", name: "Seguridad" },
          { img: "3", name: "Estructura de <br> Pago Multinivel" },
          { img: "4", name: "Materiales <br> Promocionales" },
          {
            img: "5",
            name: "Tecnología Avanzada de <br> Seguimiento en tiempo real",
          },
          { img: "6", name: "Pago <br> Mensual" },
        ],
        cpa: "CPA",
        textThree: "Elija TrioMarkets™ como su socio afiliado estratégico!",
        titleFour: "Socio transparente confiable",
        textFour:
          "Proporcionamos una estructura de CPA de varios niveles con la flexibilidad para adaptarse a una amplia gama de configuraciones de referencia.",
      },
    ],
    promotionsInfo: [
      {
        text: "Es vital para nosotros que nuestros clientes estén constantemente enterado de las nuevas producciones y promociones que tenemos. Creemos que una promoción es un elemento clave para transmitir los beneficios de nuestro producto y servicio a nuestros clientes. Para nosotros es importante crear conciencia y conectar con los clientes para que este sector le brinde información sobre promociones y concursos. Elija lo que se adapte a lo que está negociando.",
        textTwo:
          "Vea a continuación todas las promociones existentes y concursos para TrioMarkets™",
        bonus: "Bono",
        regAndProf: `10% de bonificación "Crédito a efectivo"`,
        bonusText:
          "Se emitirá una bonificación de crédito del 10% por cada nuevo depósito superior a 1.000 USD.",
        title: "Recomendar a un Amigo",
        textThree: "CUANTOS MAS AMIGOS TRAES MAS BENEFICIOS USTED OBTIENE",
        textFour:
          "Hemos creado específicamente nuestro Referral Programe como una forma de agradecerle por las presentaciones de amigos y familiares. Gane más fácilmente invitando a amigos y familiares a comerciar con TrioMarkets™ y gane hasta 1000€/$/£.",
        titleTwo: "Devolución de dinero",
        textFive: `Aproveche al máximo sus operaciones con CashBack en TrioMarkets™`,
        textSix:
          "Cada Trader recibe hasta un 15% por cada lote negociado con TrioMarkets™. Opere más lotes y gane más con el reembolso. Obtener el tu CashBack.",
      },
    ],
  },
  vnm: {
    upTo: "Lên đến",
    introducingBrokerInfo: [
      {
        title: "Trở thành Đối tác giới thiệu với TrioMarkets ™",
        titleTwo:
          "Kiếm nhiều tiền hơn với tư cách là Nhà giới thiệu TrioMarkets ™",
        text: "Kiếm các khoản giảm giá cạnh tranh khi được khách hàng giới thiệu với những người quản lý tài khoản tận tâm của chúng tôi sẽ giúp bạn trong suốt chặng đường. Chương trình IB của TrioMarkets ™ được thiết kế để cung cấp cho các cá nhân và khách hàng doanh nghiệp cơ hội kiếm được doanh thu liên tục từ hoạt động giao dịch của khách hàng của họ.",
        titleThree:
          "Bạn càng đăng ký nhiều khách hàng, bạn càng nhận được nhiều thu nhập",
        textTwo:
          "Với tư cách là Đối tác của TrioMarkets ™, bạn sẽ có được sự hỗ trợ tận tình của đối tác. Nhóm Hỗ trợ đa ngôn ngữ của chúng tôi sẽ hỗ trợ bạn khi bạn phát triển doanh nghiệp của mình. Cổng Đối tác TrioMarkets ™ cung cấp cho bạn dữ liệu về các khách hàng đã giới thiệu và cho phép bạn truy cập vào các Tài liệu Tiếp thị chuyên nghiệp, chẳng hạn như Biểu ngữ, Trang đích và hơn thế nữa. ",
        benefits: "Những lợi ích",
        points: [
          {
            img: "1",
            name: "Các khoản giảm giá linh hoạt được thanh toán hàng ngày",
          },
          { img: "2", name: "Giảm giá trọn đời <br> cho khách hàng" },
          { img: "3", name: "Công nghệ <br> theo dõi" },
          { img: "4", name: "Tài liệu <br> tiếp thị" },
          { img: "5", name: "Thiết <br> lập nhanh" },
          { img: "6", name: "Không cần đầu <br> tư trả trước" },
        ],
        perLot: "Mỗi Lot",
        textThree:
          "Chọn TrioMarkets ™ làm Đối tác liên kết chiến lược của bạn!",
        titleFour: "Đối tác minh bạch đáng tin cậy",
        textFour:
          "Cơ cấu hoa hồng lũy   tiến nhiều tầng của chúng tôi được thiết kế để hỗ trợ sự phát triển của bạn, giúp bạn kiếm được tiền từ mỗi khách hàng được giới thiệu suốt đời.",
      },
    ],
    cpaInfo: [
      {
        head: "Chương trình liên kết CPA",
        title: "Trở thành Người giới thiệu CPA với TrioMarkets ™",
        titleTwo: "Chương trình giới thiệu TrioMarkets ™ là gì?",
        text: "Chương trình giới thiệu TrioMarkets ™ là một mô hình hợp tác có lợi nhuận cao và không có rủi ro, cho phép bạn tạo ra lợi nhuận bằng cách thu hút nhiều đối tượng thông qua nhiều công cụ truyền thông mà chúng tôi cung cấp cho bạn. Bằng cách trở thành đối tác của TrioMarkets ™, bạn sẽ nhận một khoản thanh toán cho mỗi khách hàng được kích hoạt. ",
        titleThree:
          "Bạn càng thu hút được nhiều khách hàng để giao dịch, lợi nhuận của bạn sẽ càng ấn tượng.",
        textTwo:
          "Với tư cách là Đối tác của TrioMarkets ™, bạn sẽ có được sự hỗ trợ tận tình của đối tác. Nhóm Hỗ trợ đa ngôn ngữ của chúng tôi sẽ hỗ trợ bạn khi bạn phát triển doanh nghiệp của mình. Cổng Đối tác TrioMarkets™ cung cấp cho bạn dữ liệu về các khách hàng đã giới thiệu và cho phép bạn truy cập vào các Tài liệu Tiếp thị chuyên nghiệp, chẳng hạn như Biểu ngữ, Trang đích và hơn thế nữa.",
        benefits: "Lợi ích khi tham gia cùng chúng tôi",
        points: [
          { img: "1", name: "Nhà môi giới <br> đáng tin cậy" },
          { img: "2", name: "Bảo vệ" },
          { img: "3", name: "Cơ cấu thanh <br> toán đa cấp" },
          { img: "4", name: "Tài liệu <br> quảng cáo" },
          {
            img: "5",
            name: "Công nghệ theo dõi <br> thời gian thực tiên tiến",
          },
          { img: "6", name: "Khoản thanh <br> toán hàng tháng" },
        ],
        cpa: "CPA",
        textThree:
          "Chọn TrioMarkets ™ làm Đối tác liên kết chiến lược của bạn!",
        titleFour: "Đối tác minh bạch đáng tin cậy",
        textFour:
          "Chúng tôi cung cấp cấu trúc CPA nhiều tầng với tính linh hoạt để phù hợp với nhiều loại thiết lập giới thiệu khác nhau.",
      },
    ],
    promotionsInfo: [
      {
        text: "Điều quan trọng đối với chúng tôi là khách hàng của chúng tôi thường xuyên nhận thức được các sản phẩm và chương trình khuyến mãi mới mà chúng tôi có. Chúng tôi tin rằng khuyến mại là một yếu tố quan trọng trong việc mang lại lợi ích của sản phẩm và dịch vụ của chúng tôi cho khách hàng. Điều quan trọng là chúng tôi phải tạo ra nhận thức và kết nối với khách hàng để lĩnh vực này sẽ cung cấp cho bạn thông tin về các chương trình khuyến mãi và cuộc thi. Chọn những gì phù hợp với bạn đang giao dịch.",
        textTwo:
          "Xem bên dưới tất cả các chương trình khuyến mãi và cuộc thi hiện có cho TrioMarkets™",
        bonus: "Thưởng",
        regAndProf: `10% tiền thưởng "Tín dụng vào tiền mặt"`,
        bonusText:
          "Phần thưởng tín dụng 10% sẽ được cấp cho mỗi khoản tiền gửi mới trên 1.000 USD",
        title: "Tham khảo một người bạn",
        textThree: "BẠN BÈ MANG LẠI NHIỀU LỢI ÍCH HƠN BẠN NHẬN ĐƯỢC",
        textFour:
          "Chúng tôi đã đặc biệt tạo ra Chương trình giới thiệu của mình như một cách để cảm ơn bạn vì sự giới thiệu của bạn bè và gia đình. Dễ dàng kiếm được nhiều tiền hơn bằng cách đưa bạn bè và gia đình đến giao dịch với TrioMarkets ™ và kiếm được tới 1000 € / $ / £.",
        titleTwo: "Cashback",
        textFive: `Khai thác nhiều hơn công việc giao dịch của bạn với CashBack tại TrioMarkets™`,
        textSix: `Mỗi nhà giao dịch nhận được tới 15% cho mỗi lô giao dịch với TrioMarkets™. Giao dịch nhiều lô hơn và kiếm được nhiều hơn từ khoản giảm giá.`,
      },
    ],
  },
  enUae: {
    upTo: "Up To",
    introducingBrokerInfo: [
      {
        title: "Become an Introducing Broker with TrioMarkets™",
        titleTwo: "Earn more as a TrioMarkets™ Introducing Broker",
        text: "Earn competitive rebates on client referrals with our dedicated account managers helping you along the way. The TrioMarkets™ IB program is designed to offer individuals and corporate clients an opportunity to earn ongoing revenue from the trading activity of their clients.",
        titleThree: "The more clients you sign up, the more income you get",
        textTwo:
          "As a Partner of TrioMarkets™, you will have a dedicated partner support. Our multilingual Support Team will assist you as you grow your business. The TrioMarkets™ Partner Portal provides you data on introduced clients and give you access to professional Marketing Materials such as Banner, Landing Pages and more.",
        benefits: "Benefits",
        points: [
          { img: "1", name: "Flexible rebates <br> paid daily" },
          { img: "2", name: "Lifetime rebates <br> on clients" },
          { img: "3", name: "Tracking <br> Technology" },
          { img: "4", name: "Marketing <br> Materials" },
          { img: "5", name: "Fast <br> on-boarding" },
          { img: "6", name: "No upfront <br> investment" },
        ],
        perLot: "Per Lot",
        textThree: "Choose TrioMarkets™ as Your Strategic Affiliate Partner!",
        titleFour: "Trustworthy Transparent Partner",
        textFour:
          "Our multi-tiered, progressive commission structure is designed to support your growth, helping you earn on every referred customer for life.",
      },
    ],
    cpaInfo: [
      {
        head: "CPA Affiliate Program",
        title: "What is TrioMarkets™ Referral Program?",
        titleTwo: "Become a CPA Referral with TrioMarkets™",
        text: "The TrioMarkets™ Referral Program is a highly profitable and risk-free partnership model, which allows you to generate profits by attracting a broad audience through the wide range of media tools we provide you with. By becoming a partner of TrioMarkets™, you will receive a payout for every activated customer.",
        titleThree:
          "The more customers you attract to trading, the more impressive your profit will be.",
        textTwo:
          "As a Partner of TrioMarkets™, you will have a dedicated partner support. Our multilingual Support Team will assist you as you grow your business. The TrioMarkets™ Partner Portal provides you data on introduced clients and give you access to professional Marketing Materials such as Banner, Landing Pages and more.",
        benefits: "Benefits of Joining us",
        points: [
          { img: "1", name: "Reliable <br> Broker" },
          { img: "2", name: "Security" },
          { img: "3", name: "Multi-level <br> Payout structure" },
          { img: "4", name: "Promotional <br> Materials" },
          { img: "5", name: "Advanced real-time <br> Tracking Technology" },
          { img: "6", name: "Monthly <br> Payout" },
        ],
        cpa: "CPA",
        textThree: "Choose TrioMarkets™ as Your Strategic Affiliate Partner!",
        titleFour: "Trustworthy Transparent Partner",
        textFour:
          "We provide a multi-tiered CPA structure with the flexibility to suit a diverse range of referral setups.",
      },
    ],
    promotionsInfo: [
      {
        text: "It is vital to us that our clients are constantly aware of new productions and promotions that we have. We believe that a promotion is a key element in putting across the benefits of our product and service to our customers. It is important for us to create awareness and to connect with clients so this sector will provide you with information on promotions and competitions. Choose what suits your trading.",
        textTwo:
          "See below all existing promotions and competitions for TrioMarkets™",
        bonus: "Bonus",
        regAndProf: `10% "Credit to Cash" Bonus`,
        bonusText:
          "10% credit bonus will be issued on each new deposit above 1,000 USD",
        title: "Refer a friend",
        textThree: "The more friends you bring the more benefits you get",
        textFour:
          "We have specifically created our Referral Program as a way of thanking you for the introductions of friends and family. Easily gain more by bringing friends and family to trade with TrioMarkets™ and earn up to 1000€/$/£.",
        titleTwo: "Cashback",
        textFive: `Get more out of your trading with CashBack at TrioMarkets™`,
        textSix:
          "Every trader receives up to 15% for every lot traded with TrioMarkets™. Trade more lots and gain more from the rebate.",
      },
    ],
  },
  arb: {
    upTo: "يصل إلى",
    introducingBrokerInfo: [
      {
        title: "كن وسيطًا معرّفًا مع TrioMarkets™",
        titleTwo: "اكسب المزيد بصفتك وسيط معرّف لـ TrioMarkets ™",
        text: "اربح خصومات تنافسية على إحالات العملاء من خلال مديري الحسابات المخصصين لدينا لمساعدتك على طول الطريق. تم تصميم برنامج TrioMarkets ™ IB ليوفر للعملاء الأفراد والشركات فرصة لكسب عائد مستمر من نشاط التداول لعملائهم.",
        titleThree:
          "كلما زاد عدد العملاء الذين اشتركت معهم ، زاد الدخل الذي تحصل عليه",
        textTwo:
          "بصفتك شريكًا في TrioMarkets ™ ، سيكون لديك دعم شريك مخصص. سيساعدك فريق الدعم متعدد اللغات لدينا أثناء تنمية عملك. تزودك بوابة شركاء TrioMarkets ™ ببيانات عن العملاء المقدمين وتمنحك الوصول إلى مواد تسويقية احترافية مثل اللافتات والصفحات المقصودة والمزيد.",
        benefits: "الفوائد",
        points: [
          { img: "1", name: "حسومات مرنة<br> تدفع يوميا" },
          { img: "2", name: "حسومات مدى الحياة<br> على العملاء" },
          { img: "3", name: "تكنولوجيا <br>التتبع" },
          { img: "4", name: "مواد<br> تسويقية" },
          { img: "5", name: "صعود <br>سريع" },
          { img: "6", name: "لا يوجد استثمار <br>مقدماt" },
        ],
        perLot: "لكل لوت",
        textThree: "اختر TrioMarkets ™ كشريك استراتيجي تابع لك!",
        titleFour: "شريك جدير بالثقة يتسم بالشفافية",
        textFour:
          "تم تصميم هيكل العمولة التدريجي متعدد المستويات الخاص بنا لدعم نموك ، ومساعدتك على تحقيق أرباح من كل عميل تتم إحالته مدى الحياة.",
      },
    ],
    cpaInfo: [
      {
        head: "برنامج شراكة CPA",
        title: "كن محيل CPA مع TrioMarkets™",
        titleTwo: "ما هو برنامج الإحالة في TrioMarkets™؟",
        text: "برنامج الإحالة في TrioMarkets ™ هو نموذج شراكة مربح للغاية وخالي من المخاطر ، والذي يسمح لك بجني الأرباح من خلال جذب جمهور عريض من خلال مجموعة واسعة من أدوات الوسائط التي نقدمها لك. عندما تصبح شريكًا لـ TrioMarkets ™ ، ستتلقى عائدًا عن كل عميل نشط.",
        titleThree:
          "كلما زاد عدد العملاء الذين تجذبهم للتداول ، سيكون ربحك أكثر إثارة للإعجاب.",
        textTwo:
          "بصفتك شريكًا في TrioMarkets ™ ، سيكون لديك دعم شريك مخصص. سيساعدك فريق الدعم متعدد اللغات لدينا أثناء تنمية عملك. توفر لك بوابة شركاء TrioMarkets ™ بيانات عن العملاء المقدمين وتمنحك الوصول إلى مواد تسويقية احترافية مثل Banner و Landing Pages والمزيد.",
        benefits: "فوائد الانضمام إلينا",
        points: [
          { img: "1", name: "وسيط<br> موثوق" },
          { img: "2", name: "الأمن" },
          { img: "3", name: "هيكل الدفع <br>متعدد المستويات" },
          { img: "4", name: "المواد<br> الترويجية" },
          { img: "5", name: "تقنية التتبع في <br>الوقت الحقيقي المتقدمة" },
          { img: "6", name: "دفع تعويضات<br> شهرية" },
        ],
        cpa: "CPA",
        textThree: "اختر TrioMarkets ™ كشريك استراتيجي لك!",
        titleFour: "شريك جدير بالثقة يتسم بالشفافية",
        textFour:
          "نحن نقدم بنية CPA متعددة المستويات مع المرونة لتناسب مجموعة متنوعة من إعدادات الإحالة.",
      },
    ],
    promotionsInfo: [
      {
        text: "من المهم بالنسبة لنا أن يكون عملاؤنا على دراية مستمرة بالإنتاج والعروض الترويجية الجديدة التي نمتلكها. نحن نؤمن بأن الترويج هو عنصر أساسي في تقديم فوائد منتجاتنا وخدماتنا لعملائنا. من المهم بالنسبة لنا خلق الوعي والتواصل مع العملاء حتى يزودك هذا القطاع بمعلومات عن العروض الترويجية والمسابقات. اختر ما يناسبك.",
        textTwo:
          "انظر أدناه جميع العروض الترويجية والمسابقات الحالية لشركة TrioMarkets™",
        bonus: "علاوة",
        regAndProf: `10٪ بونص "ائتمان إلى نقدي`,
        bonusText: `سيتم إصدار مكافأة ائتمانية بنسبة 10٪ على كل إيداع جديد يزيد عن 1،000 دولار أمريكي`,
        title: "قم بإحالة صديق",
        textThree: "كلما زاد عدد الأصدقاء ، زادت الفوائد التي تحصل عليها.",
        textFour:
          "لقد أنشأنا برنامج الإحالة الخاص بنا على وجه التحديد كوسيلة لشكرك على مقدمات الأصدقاء والعائلة. احصل على المزيد بسهولة عن طريق جلب الأصدقاء والعائلة للتداول مع TrioMarkets ™ وكسب ما يصل إلى 1000 يورو / دولار / جنيه إسترليني.",
        titleTwo: "استرداد النقود",
        textFive: `حقق أقصى استفادة من تداولاتك مع CashBack في TrioMarkets ™.`,
        textSix:
          "يتلقى كل متداول ما يصل إلى 15٪ عن كل لوت يتم تداوله مع TrioMarkets™. تداول المزيد من اللوت واكسب المزيد من الخصم.",
      },
    ],
  },
  my: {
    upTo: "Sehingga",
    introducingBrokerInfo: [
      {
        title: "Jadilah Broker Pengenalan dengan TrioMarkets™",
        titleTwo:
          "Pakai lebih banyak sebagai TrioMarkets ™ Memperkenalkan Broker",
        text: "Hasilkan rebat kompetitif pada rujukan pelanggan dengan pengurus akaun khusus kami membantu anda sepanjang perjalanan. Program TrioMarkets ™ IB dirancang untuk memberi peluang kepada individu dan pelanggan korporat untuk memperoleh pendapatan berterusan dari aktiviti perdagangan pelanggan mereka.",
        titleThree:
          "Semakin banyak pelanggan yang anda daftarkan, semakin banyak pendapatan yang anda dapat",
        textTwo:
          "Sebagai Rakan TrioMarkets™, anda akan mendapat sokongan rakan kongsi yang berdedikasi. Pasukan Sokongan pelbagai bahasa kami akan membantu anda semasa anda mengembangkan perniagaan. Portal Rakan TrioMarkets ™ memberi anda data mengenai pelanggan yang diperkenalkan dan memberi anda akses kepada Bahan Pemasaran profesional seperti Banner, Landing Pages dan banyak lagi.",
        benefits: "Faedah",
        points: [
          { img: "1", name: "Rebat fleksibel <br> dibayar setiap hari" },
          { img: "2", name: "Rebat seumur <br> hidupl untuk pelanggan" },
          { img: "3", name: "Teknologil <br> Penjejakan" },
          { img: "4", name: "Bahanl <br> Pemasaran" },
          { img: "5", name: "Naikl <br> cepat" },
          { img: "6", name: "NTiadal <br> pelaburan awal" },
        ],
        perLot: "Setiap Lot",
        textThree:
          "Pilih TrioMarkets ™ sebagai milik anda Rakan Afiliasi Strategik!",
        titleFour: "Rakan Telus yang Boleh Dipercayai",
        textFour:
          "Kami pelbagai peringkat, progresif struktur komisen dirancang untuk menyokong pertumbuhan anda, membantu anda memperoleh setiap pelanggan yang dirujuk seumur hidup.",
      },
    ],
    cpaInfo: [
      {
        head: "Program Gabungan CPA",
        title: "Apa itu TrioMarkets ™ Rujukan Program?",
        titleTwo: "Jadilah Rujukan CPA dengan TrioMarkets™",
        text: "Rujukan TrioMarkets™ Program adalah model perkongsian yang sangat menguntungkan dan bebas risiko, yang membolehkan anda menjana keuntungan dengan menarik khalayak luas melalui pelbagai alat media yang kami sediakan untuk anda. Dengan menjadi rakan kongsi TrioMarkets ™, anda akan menerima pembayaran untuk setiap pelanggan yang diaktifkan.",
        titleThree:
          "Semakin banyak pelanggan yang anda tarik untuk berdagang, semakin mengagumkan keuntungan anda.",
        textTwo:
          "Sebagai Rakan TrioMarkets™, anda akan mendapat sokongan rakan kongsi yang berdedikasi. Pasukan Sokongan pelbagai bahasa kami akan membantu anda semasa anda mengembangkan perniagaan. Portal Rakan TrioMarkets ™ memberi anda data mengenai pelanggan yang diperkenalkan dan memberi anda akses kepada Bahan Pemasaran profesional seperti Banner, Landing Pages dan banyak lagi.",
        benefits: "Faedah Bergabung dengan kami",
        points: [
          { img: "1", name: "Broker yang <br> boleh dipercayai" },
          { img: "2", name: "Keselamatan" },
          { img: "3", name: "Struktur Pembayaran <br> pelbagai peringkat" },
          { img: "4", name: "Bahan <br> Promosi" },
          { img: "5", name: "Penjejakan masa nyata <br> yang maju Teknologi" },
          { img: "6", name: "Pembayaran <br> Bulanan" },
        ],
        cpa: "CPA",
        textThree:
          "Pilih TrioMarkets ™ sebagai milik anda Rakan Afiliasi Strategik!",
        titleFour: "Rakan Telus yang Boleh Dipercayai",
        textFour:
          "Kami menyediakan pelbagai peringkat Struktur CPA dengan fleksibiliti untuk memenuhi pelbagai susunan rujukan.",
      },
    ],
    promotionsInfo: [
      {
        text: "Sangat penting bagi kita bahawa kita pelanggan sentiasa mengetahui pengeluaran dan promosi baru yang kami ada. Kami percaya bahawa promosi adalah elemen utama dalam memberikan manfaat produk dan perkhidmatan kami kepada pelanggan kami. Penting bagi kita untuk memberi kesedaran dan berhubung dengan pelanggan supaya sektor ini memberi anda maklumat mengenai promosi dan pertandingan. Pilih yang sesuai dengan perdagangan anda.",
        textTwo:
          "Lihat di bawah semua yang ada promosi dan pertandingan untuk TrioMarkets™",
        bonus: "Bonus",
        regAndProf: `Promosi Pendaftaran 50 USD`,
        bonusText: "",
        title: "Bonus 100% Infiniti",
        textThree: "",
        textFour: "",
        titleTwo: "",
        textFive: "",
        textSix: "",
        promotionPDF: [
          "$50-welcome-bonus-malaysian.pdf",
          "infinity-100-bonus-malaysian.pdf",
        ],
      },
    ],
    // promotionsInfo: [
    //   {
    //     text: "Sangat penting bagi kita bahawa kita pelanggan sentiasa mengetahui pengeluaran dan promosi baru yang kami ada. Kami percaya bahawa promosi adalah elemen utama dalam memberikan manfaat produk dan perkhidmatan kami kepada pelanggan kami. Penting bagi kita untuk memberi kesedaran dan berhubung dengan pelanggan supaya sektor ini memberi anda maklumat mengenai promosi dan pertandingan. Pilih yang sesuai dengan perdagangan anda.",
    //     textTwo:
    //       "Lihat di bawah semua yang ada promosi dan pertandingan untuk TrioMarkets™",
    //     bonus: "Bonus",
    //     regAndProf: `Bonus "Kredit ke Tunai" 10`,
    //     bonusText:
    //       "Bonus kredit 10% akan dikeluarkan pada setiap deposit baru melebihi 1,000 USD",
    //     title: "Rujuk Rakan",
    //     textThree:
    //       "Semakin banyak rakan yang anda bawa semakin banyak faedah yang anda dapat. ",
    //     textFour:
    //       "Kami telah membuat secara khusus Program Rujukan kami sebagai cara mengucapkan terima kasih atas perkenalan rakan dan keluarga. Dapatkan lebih banyak keuntungan dengan membawa rakan dan keluarga berdagang dengan TrioMarkets™ dan memperoleh sehingga 1000€/$/£.",
    //     titleTwo: "Wang tunai",
    //     textFive: `Dapatkan lebih banyak daripada perdagangan anda
    //     dengan CashBack di TrioMarkets™.`,
    //     textSix:
    //       "Setiap peniaga menerima hingga 15% untuk setiap lot yang diperdagangkan dengan TrioMarkets ™. Berdagang lebih banyak dan dapatkan lebih banyak daripada rebat.",
    //   },
    // ],
  },
};
