<template>
  <div v-for="(item, idx) in $tm('proTradersInfo')" :key="idx">
    <div class="container-fluid container-top accounts pro-traders">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("proTraders") }}
          </h2>
          <hr class="gold" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-10 col-12 text-white text-center mb-md-2 px-sm-auto px-0"
            v-html="item.text"
          ></div>
          <div
            v-if="textOne"
            class="col-lg-10 col-12 text-white text-center px-sm-auto px-0"
            v-html="item.textOne"
          ></div>
          <a @click.prevent="textOne = !textOne" class="col-12 text-center d-md-none text-primary d-md-none" v-html="textOne == false ? $t('readMore') : $t('readLess')"></a>
          <div class="col-12 d-flex justify-content-center my-4">
            <a
              target="_blank"
              :href="registerURL"
              class="btn btn-blue mb-lg-0 mb-4"
              >{{ $t("openAccount") }}</a
            >
          </div>
          <div class="col-9 text-center d-lg-block d-none">
            <img
              src="/img/accounts/tablet.png"
              alt="tablet"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container pro-traders-info">
      <div class="row d-flex justify-content-center mt-5 mb-2">
        <h2 class="col-12 text-center title">
          {{ item.unwaived }}
        </h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center my-5" v-scrollanimation>
        <div
          v-for="(point, idx) in item.points"
          :key="idx"
          class="col-lg-4 col-md-8 col-sm-10 col-12 mb-lg-0 mb-4 text-center"
        >
          <div class="forex-box box-proTraders">
            <img
              :src="`/img/accounts/${point.img}.svg`"
              alt="icon"
              class="img-fluid icon my-2"
            />
            <h4 class="text-center" v-html="point.name"></h4>
            <p class="text-center" v-html="point.text"></p>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-5 mb-2">
        <h2 class="col-12 text-center title">
          {{ item.waived }}
        </h2>
        <hr class="blue" />
      </div>
      <div class="row d-flex justify-content-center my-5" v-scrollanimation>
        <div
          v-for="(point, idx) in item.pointsTwo"
          :key="idx"
          class="col-lg-6 col-md-8 col-sm-10 col-12 text-center"
        >
          <div class="forex-box pro mb-4">
            <img
              :src="`/img/accounts/${point.img}.svg`"
              alt="icon"
              class="img-fluid icon my-2"
            />
            <h4 class="text-center" v-html="point.name"></h4>
            <p class="text-center" v-html="point.text"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid black-box pro py-5">
      <div class="container">
        <div class="row d-flex justify-content-center mt-5 mb-3">
          <h2
            class="col-12 text-center title text-white"
            v-html="item.title"
          ></h2>
          <hr class="gold" />
        </div>
        <div class="row d-flex justify-content-center" v-slidein>
          <div
            class="col-lg-10 offset-xl-1 col-12 text-gold fw-bold fs-5"
            v-html="item.textTwo"
          ></div>
          <ul class="col-lg-10 offset-xl-1 col-12">
            <li
              v-for="(point, idx) in item.pointsTree"
              :key="idx"
              class="text-white"
            >
              {{ point }}
            </li>
          </ul>
          <div
            class="col-lg-10 offset-xl-1 col-12 text-gold"
            v-html="item.textThree"
          ></div>
          <div class="col-12 d-flex justify-content-center my-4">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{
              $t("openAccount")
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import { getters,mutations } from "../store";
export default {
  name: "ProTraders",
  data: () => ({
    textOne: true,
    windowWidth: window.innerWidth,
  }),
  watch: {
    windowWidth: function () {
      this.textOneMobile();
    },
  },
  computed: {
    registerURL() {
      return getters.registerURL();
    },
  },
  methods: {
    textOneMobile() {
      if (window.innerWidth < 768) {
        this.textOne = false;
      } else {
        this.textOne = true;
      }
    },
  },
  mounted() {
    
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      mutations.setBoxHeight("box-proTraders","1");
      mutations.setBoxHeight("pro","1");
    };
    this.textOneMobile();
    mutations.setBoxHeight("box-proTraders","1");
    mutations.setBoxHeight("pro","1");
  },
};
</script>
<style lang="scss" scoped></style>
