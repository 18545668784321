<template>
  <div v-for="(item, idx) in $tm('mamInfo')" :key="idx">
    <div class="container-fluid container-top accounts half mam">
      <div class="container accounts-info">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ $t("mam") }} Trading
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-flex justify-content-center">
          <div
            class="col-lg-8 col-md-10 col-12 text-white text-center"
            v-html="item.text"
          ></div>
          <div class="col-12 text-center mt-4">
            <a target="_blank" :href="registerURL" class="btn btn-blue">{{
              $t("openLiveAccount")
            }}</a>
          </div>
          <div class="col-8 d-md-block d-none text-center">
            <img
              src="/img/platforms-menu/mam/imacs.png"
              alt="imac"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-md-none mt-5">
      <div class="row d-flex justify-content-center">
        <div
          class="col-lg-8 col-md-10 col-12 text-center"
          v-html="item.textTwo"
        ></div>
      </div>
      <div class="row d-flex justify-content-center mt-5">
        <h2 class="col-12 text-center title">
          {{ item.title }}
        </h2>
        <hr class="blue mbhr" />
      </div>
      <div class="row d-flex justify-content-center" v-scrollanimation>
        <div class="col-lg-10 offset-lg-1">
          <div class="row d-flex justify-content-center my-lg-5 my-4">
            <div
              v-for="(point, idx) in item.point"
              :key="idx"
              class="col-md-6 col-sm-10 col-11 mb-lg-2 mb-4 points"
            >
              <div class="feature-box d-flex points align-items-center">
                <img
                  src="/img/check.svg"
                  :class="`img-fluid check mb-lg-0 mb-2 mt-lg-0 mt-3  ${
                    checkArb ? ' ms-lg-3' : ' me-lg-3'
                  }`"
                  alt="check"
                />
                <p class="mb-lg-0 fs-5 lh-sm" v-html="point"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center">
          <a target="_blank" :href="registerURL" class="btn btn-blue">{{
            $t("openLiveAccount")
          }}</a>
        </div>
      </div>
    </div>
    <div class="container-fluid black-box pt-4 pb-5 my-5">
      <div class="container">
        <div class="row d-flex justify-content-center mt-5">
          <h2 class="col-12 text-center title text-white">
            {{ item.titleTwo }}
          </h2>
          <hr class="gold mbhr" />
        </div>
        <div class="row d-md-block d-none" v-scrollanimation>
          <div class="col-12 text-center">
            <img
              :src="`/img/platforms-menu/mam/${item.mamModel}.svg`"
              alt="mam-model"
              class="img-fluid"
            />
          </div>
        </div>
        <div class="row d-md-block d-none" v-scrollanimation>
          <div class="col-12">
            <div class="row d-flex justify-content-center">
              <div
                v-for="(icon, idx) in icons"
                :key="idx"
                class="col-2 d-flex justify-content-center"
              >
                <img
                  :src="`/img/platforms-menu/mam/${icon.img}.svg`"
                  class="img-fluid icon"
                  alt="icon"
                />
                <div
                  :class="`d-flex flex-column align-items-start justify-content-center  ${
                    checkArb ? ' me-2' : 'ms-2'
                  }`"
                >
                  <strong class="text-white text-center">{{
                    icon.bold
                  }}</strong>
                  <p class="text-white text-center mb-0">{{ icon.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-md-none" v-scrollanimation>
          <div class="col-12 text-center">
            <img
              :src="`/img/platforms-menu/mam/${item.mamModelPhone}.svg`"
              alt="mam-model"
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <Accounts v-scrollanimation />
    <Platforms v-scrollanimation />
    <Steps v-scrollanimation />
  </div>
</template>
<script>
import { getters } from "../store";
export default {
  name: "MAM",
  data: () => ({
    icons: [
      { img: "01", text: "5000$", bold: "50%" },
      { img: "02", text: "400$", bold: "4%" },
      { img: "03", text: "600$", bold: "6%" },
      { img: "04", text: "3000$", bold: "30%" },
      { img: "05", text: "1000$", bold: "10%" },
    ],
  }),
  computed: {
    registerURL() {
      return getters.registerURL();
    },
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped></style>
