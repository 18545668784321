import { reactive } from "vue";

const checkLicense = () => {
  if (location.host.includes(".eu") || location.host.includes("8080")) {
    return "eu";
  } else if (
    location.host.includes(".co.za") ||
    location.host.includes("8082")
  ) {
    return "africa";
  } else {
    return "global";
  }
};

export const store = reactive({
  selectedLang: window.sessionStorage.getItem("language") ?? "en",
  license: checkLicense(),
  currentSymbol: {},
  selectedArticle: {
    section: "",
    details: {},
  },
  hideDisclaimer: false,
  cookiesEnabled: false,
  regulationAccepted: false,
  checkArb: false,
  checkUae: false,
  textEnd: false,
  languages: [
    {
      name: "English",
      translateName: "English",
      code: "en",
    },
    {
      name: "French",
      translateName: "Français",
      code: "fr",
    },
    {
      name: "Italian",
      translateName: "Italiano",
      code: "it",
    },
    {
      name: "German",
      translateName: "Deutsch",
      code: "de",
    },
    {
      name: "Chinese",
      translateName: "简体中文",
      code: "cn",
    },
    {
      name: "Spanish",
      translateName: "Española",
      code: "es",
    },
    {
      name: "Vietnamese",
      translateName: "Tiếng Việt",
      code: "vnm",
    },
    {
      name: "English",
      translateName: "English",
      code: "enUae",
    },
    {
      name: "Arabic",
      translateName: "العربية",
      code: "arb",
    },
    {
      name: "Malay",
      translateName: "Melayu",
      code: "my",
    },
  ],
  euCountries: [
    { country: "Austria", code: "AT" },
    { country: "Belgium", code: "BE" },
    { country: "Bulgaria", code: "BG" },
    { country: "Croatia", code: "HR" },
    { country: "Cyprus", code: "CY" },
    { country: "Czech Republic", code: "CZ" },
    { country: "Denmark", code: "DK" },
    { country: "Estonia", code: "EE" },
    { country: "Finland", code: "FI" },
    { country: "France", code: "FR" },
    { country: "Germany", code: "DE" },
    { country: "Greece", code: "GR" },
    { country: "Hungary", code: "HU" },
    { country: "Ireland", code: "IE" },
    { country: "Italy", code: "IT" },
    { country: "Latvia", code: "LV" },
    { country: "Lithuania", code: "LT" },
    { country: "Luxembourg", code: "LU" },
    { country: "Malta", code: "MT" },
    { country: "Netherlands", code: "NL" },
    { country: "Poland", code: "PL" },
    { country: "Portugal", code: "PT" },
    { country: "Romania", code: "RO" },
    { country: "Slovakia", code: "SK" },
    { country: "Slovenia", code: "SI" },
    { country: "Spain", code: "ES" },
    { country: "Sweden", code: "SE" },
  ],
  menaCountries: ["BH", "KW", "OM", "QA", "SA", "AE", "EG"],
  asiaCountries: ["CN", "HK", "TW", "MO"],
  selectedArticleLang: "en",
});

export const getters = {
  license() {
    return store.license;
  },
  selectedLang() {
    return store.selectedLang;
  },
  loginURL() {
    return `https://secure.triomarkets.${
      store.license === "eu" ? "eu" : "com"
    }/${store.selectedLang}/login`;
  },
  registerURL() {
    return `https://secure.triomarkets.${
      store.license === "eu" ? "eu" : "com"
    }/${store.selectedLang}/register`;
  },
  currentSymbol() {
    return store.currentSymbol;
  },
  toggleDisclaimer() {
    return store.hideDisclaimer;
  },
  cookiesEnabled() {
    return store.cookiesEnabled || localStorage.getItem("cookies");
  },
  regulationAccepted() {
    return store.regulationAccepted || localStorage.getItem("regulation");
  },
  checkArb() {
    return store.checkArb;
  },
  checkUae() {
    return store.checkUae;
  },
  selectedArticle() {
    return store.selectedArticle;
  },
  languages() {
    return store.languages;
  },
  euCountries() {
    return store.euCountries;
  },
  menaCountries() {
    return store.menaCountries;
  },
  asiaCountries() {
    return store.asiaCountries;
  },
  selectedArticleLang() {
    return store.selectedArticleLang;
  },
};

export const mutations = {
  changeLanguage(lang) {
    store.selectedLang = lang;
    window.sessionStorage.setItem("language", lang);
    if (store.selectedLang === "arb") {
      store.checkArb = true;
      store.checkUae = true;
    } else if (store.selectedLang === "enUae") {
      store.checkUae = true;
      store.checkArb = false;
    } else {
      store.checkArb = false;
      store.checkUae = false;
    }
  },
  setCurrentSymbol(symbol) {
    store.currentSymbol = symbol;
  },
  updateCurrentSymbol(symbol) {
    store.low = symbol.LOW;
    store.high = symbol.HIGH;
  },
  updateDisclaimer(hide) {
    store.hideDisclaimer = hide;
  },
  setCookies() {
    store.cookiesEnabled = true;
    localStorage.setItem("cookies", "accepted");
  },
  setRegulation() {
    store.regulationAccepted = true;
    localStorage.setItem("regulation", "accepted");
  },
  setArticle(articleSection, articleDetails) {
    store.selectedArticle = {
      section: articleSection,
      details: articleDetails,
    };
  },
  setBoxHeight(boxClass, referenceId) {
    let allEl = document.querySelectorAll(`.${boxClass}`);
    let referenceHeight = allEl[referenceId].offsetHeight;
    for (let i = 0; i < allEl.length; i++) {
      allEl[i].style.height = `${referenceHeight}px`;
    }
  },
  selectedArticleLang() {
    if (store.selectedLang == "arb") {
      store.selectedArticleLang = "arb";
    } else {
      store.selectedArticleLang = "en";
    }
  },
};
