import navbar from "./navbar";
import home from "./home";
import callToAction from "./callToAction";
import slider from "./slider";
import platforms from "./platforms";
import quotes from "./quotes";
import steps from "./steps";
import footer from "./footer";
import markets from "./markets";
import accountTypes from "./accountTypes";
import tradingConditions from "./tradingConditions";
import accounts from "./accounts";
import platformsMenu from "./platformsMenu";
import form from "./form";
import company from "./company";
import partnerships from "./partnerships";
import regulation from "./regulation";
import promotionPage from "./promotionPage";
import news from "./news";
import mobileapp from "./mobileapp";

const sources = [
  navbar,
  home,
  callToAction,
  slider,
  platforms,
  quotes,
  steps,
  footer,
  markets,
  accountTypes,
  tradingConditions,
  accounts,
  platformsMenu,
  form,
  company,
  partnerships,
  regulation,
  promotionPage,
  news,
  mobileapp,
];
export default sources.reduce((acc, source) => {
  for (let key in source) {
    acc[key] = { ...(acc[key] || {}), ...source[key] };
  }
  return acc;
}, {});
