<template>
  <div
    class="container-fluid regulation-wrapper position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center"
  >
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-10 col-lg-12 col-md-10 col-12">
          <div class="row popup-container position-relative rounded">
            <div class="col-md-5 popup-left d-flex justify-content-center rounded-left">
              <img
                src="/img/logo-vertical-white.svg"
                alt="TrioMarkets logo"
                class="img-fluid mb-4"
              />
            </div>

            <div
              class="col-lg-6 offset-lg-1 col-md-11 offset-md-1 popup-right position-relative text-center p-5 rounded-right"
            >
              <div class="row">
                <div class="col-6" id="languageRegulation"><Language /></div>
                <div :class="`col-6 ${checkArb ? 'd-flex justify-content-end':''}`">
                  <a
                    @click="acceptRegulation()"
                    class="close d-flex justify-content-end pointer"
                  >
                    <img src="/img/regulation/close.svg" alt="close" />
                  </a>
                </div>
                <div class="col-12 text-center pt-4 d-md-none flag-mobile">
                  <img
                    src="/img/regulation/maur-flag-regulation.png"
                    alt="Mauritius flag"
                    class="img-fluid"
                  />
                </div>
              </div>
              <h4 class="fw-bold">{{ $t("dearClient") }}</h4>
              <p class="fs-6 text-justify" v-html="$t('regulationText')"></p>
              <p class="fs-5 fw-bold">{{ $t("goTo") }}</p>
              <a @click="cancel()" class="btn btn-blue w-25">triomarkets.eu</a>
              <div class="position-absolute flag">
                <img src="/img/company/lnr/maur.png" class="" alt="mauritius" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getters, mutations } from "../store";
import Language from "./Language.vue";
export default {
  name: "Regulation",
  methods: {
    acceptRegulation() {
      mutations.setRegulation();
    },
    cancel() {
      window.location.replace("https://triomarkets.eu" + this.$route.path);
    },
  },
  components: {
    Language,
  },
  computed: {
    checkArb() {
      return getters.checkArb();
    },
  },
};
</script>
<style lang="scss" scoped></style>
