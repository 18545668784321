export default {
  en: {
    dearClient: "Dear Client!",
    regulationText: `<strong>Benor Capital Ltd </strong> is authorised and regulated by the Financial Services Commissions (FSC) of Mauritius (Licence Number<strong> C118023678</strong>). Please note that this website is not directed at EU residents as it may contain information on services and products not subject to product intervention measures. Please visit the website of our EU regulated entity triomarkets.eu. By Staying on <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Terms and Conditions</a>) you confirm that this is a decision based on your sole and absolute discretion without any solicitation and/or recommendation from our side.`,
    goTo: "Go to",
    cookies: "This website uses cookies.",
  },
  fr: {
    dearClient: "Cher Client!",
    regulationText: `<strong>Benor Capital Ltd</strong> est autorisée et réglementée par la Financial Services Commissions (FSC) de Maurice (Numéro de Licence <strong>C118023678</strong>). Veuillez noter que ce site Web ne s'adresse pas aux résidents de l'UE car il peut contenir des informations sur des services et des produits non soumis aux mesures d'intervention sur les produits. Veuillez visiter le site Web de notre entité réglementée par l'UE triomarkets.eu. En restant sur <strong>Benor Capital Ltd</strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Termes et Conditions</a>) vous confirmez qu'il s'agit d'une décision basée sur votre seule et absolue discrétion sans aucune sollicitation et/ou recommandation de notre part.`,
    goTo: `Aller sur`,
    cookies: "Ce site web utilise des cookies.",
  },
  it: {
    dearClient: "Caro Cliente!",
    regulationText: `<strong>Benor Capital Ltd</strong> è autorizzata e regolamentata dalla Financial Services Commissions (FSC) di Mauritius (numero di licenza <strong>C118023678</strong>). Si prega di notare che questo sito non è rivolto ai residenti nell'UE in quanto può contenere informazioni su servizi e prodotti non soggetti a misure di intervento sui prodotti. Si prega di visitare il sito web della nostra entità regolamentata UE triomarkets.eu. Rimanendo su <strong>Benor Capital Ltd</strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Termini e condizioni</a>) confermi che questa è una decisione basata sulla tua esclusiva e assoluta discrezione senza alcuna sollecitazione e/o raccomandazione da parte nostra.`,
    goTo: `Vai nel`,
    cookies: "Questo sito usa i cookies.",
  },
  de: {
    dearClient: "Lieber Kunde!",
    regulationText: `<strong>Benor Capital Ltd</strong> ist autorisiert und wird durch die Financial Services Commissions (FSC) von Mauritius (Lizenznummer <strong>C118023678</strong>) reguliert. Bitte beachten Sie, dass diese Website sich nicht an in der EU ansässige Personen richtet, da sie Informationen über Dienstleistungen und Produkte enthalten kann, die nicht den Produktinterventionsmaßnahmen unterliegen. Bitte besuchen Sie die Website unseres EU-regulierten Unternehmens triomarkets.eu. Indem Sie bei <strong>Benor Capital Ltd</strong>. zu eröffnen (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Allgemeine Geschäftsbedingungen</a>), bestätigen Sie, dass dies eine Entscheidung ist, die auf Ihrem alleinigen und absoluten Ermessen basiert, ohne Aufforderung und/oder Empfehlung von unserer Seite.`,
    goTo: `Gehen Sie zu`,
    cookies: "Diese Seite verwendet Cookies.",
  },
  cn: {
    dearClient: "尊敬的客户！",
    regulationText: `<strong>Benor Capital Ltd </strong> 由毛里求斯金融服务委员会  (FSC) 授权和监管 (许可证号<strong> C118023678</strong>)。请注意，本网站不针对欧盟居民，因为它可能包含不受产品干预措施约束的服务和产品的信息。请访问我们受欧盟监管的实体triomarkets.eu的网站。通过继续操作，您同意在<strong>Benor Capital Ltd</strong>开设账户 (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">条款和条件</a>)，并且您确认这是基于您的唯一和绝对酌情决定权的决定，没有我们方面的任何请求和/或建议。`,
    goTo: `去`,
    cookies: "本网站使用cookies。",
  },
  es: {
    dearClient: "Estimado Cliente!",
    regulationText: `<strong>Benor Capital Ltd </strong> es autorizado y regulado por las Comisiones de Servicios Financieros (FSC) de Mauricio (Número de licencia <strong> C118023678</strong>). Tenga en cuenta que este sitio web no es dirigido a residentes de la UE ya que puede contener información sobre servicios y productos no sujetos a medidas de intervención de productos. Visite el sitio web de nuestra entidad regulada de la UE triomarkets.eu. Al continuar, acepta abrir una cuenta con <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Términos y Condiciones</a>) y usted confirma que esta es una decisión basada en su única y absoluta discreción sin ninguna solicitud o recomendación de nuestra parte.`,
    goTo: `Ir`,
    cookies: "Este sitio web utiliza cookies.",
  },
  vnm: {
    dearClient: "Kính gửi Quý khách hàng!",
    regulationText: `<strong>Benor Capital Ltd </strong> được ủy quyền và quản lý bởi Ủy ban Dịch vụ Tài chính (FSC) của Mauritius (Số Giấy phép <strong> C118023678</strong>). Xin lưu ý rằng trang web này không hướng đến các cư dân EU vì nó có thể chứa thông tin về các dịch vụ và sản phẩm không bị áp dụng các biện pháp can thiệp vào sản phẩm. Vui lòng truy cập trang web của pháp nhân do EU quản lý của chúng tôi triomarkets.eu. Bằng cách tiếp tục, bạn đồng ý mở một tài khoản với <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Điều khoản và Điều kiện</a>) và bạn xác nhận rằng đây là quyết định dựa trên toàn quyền quyết định của bạn mà không có bất kỳ lời mời chào và / hoặc khuyến nghị nào từ phía chúng tôi. `,
    goTo: `Đi đến`,
    cookies: "Trang web này sử dụng cookie.",
  },
  enUae: {
    dearClient: "Dear Client!",
    regulationText: `<strong>Benor Capital Ltd </strong> is authorised and regulated by the Financial Services Commissions (FSC) of Mauritius (Licence Number<strong> C118023678</strong>). Please note that this website is not directed at EU residents as it may contain information on services and products not subject to product intervention measures. Please visit the website of our EU regulated entity triomarkets.eu. By Staying on <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Terms and Conditions</a>) you confirm that this is a decision based on your sole and absolute discretion without any solicitation and/or recommendation from our side.`,
    goTo: "Go to",
    cookies: "This website uses cookies.",
  },
  arb: {
    dearClient: "عميلنا العزيز!",
    regulationText: `<strong>Benor Capital Ltd </strong> مرخصة ومنظمة من قبل لجنة الخدمات المالية (FSC) في موريشيوس (رقم الترخيص<strong> C118023678</strong>). Please note that this website is not directed at EU residents as it may contain information on services and products not subject to product intervention measures. Please visit the website of our EU regulated entity triomarkets.eu. By Staying on <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Terms and Conditions</a>) you confirm that this is a decision based on your sole and absolute discretion without any solicitation and/or recommendation from our side.
    يرجى ملاحظة أن موقع الويب هذا غير موجه إلى المقيمين في الاتحاد الأوروبي لأنه قد يحتوي على معلومات حول الخدمات والمنتجات التي لا تخضع لتدابير التدخل في المنتج. يرجى زيارة موقع الويب الخاص بكياننا الخاضع للتنظيم في الاتحاد الأوروبي triomarkets.eu. من خلال المتابعة ، فإنك توافق على فتح حساب مع <strong>Benor Capital Ltd</strong> <a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">(الشروط والأحكام) </a>وتؤكد أن هذا قرار يعتمد على تقديرك الوحيد والمطلق دون أي التماس و / أو توصية من جانبنا.`,
    goTo: "الذهاب إلى",
    cookies: "هذا الموقع يستخدم الكوكيز.",
  },
  my: {
    dearClient: "Pelanggan yang dihormati!",
    regulationText: `<strong>Benor Capital Ltd </strong> adalah dibenarkan dan dikawal selia oleh Suruhanjaya Perkhidmatan Kewangan (FSC) Mauritius (Nombor Lesen<strong> C118023678</strong>). Harap maklum bahawa laman web ini tidak ditujukan kepada penduduk EU kerana mungkin mengandungi maklumat mengenai perkhidmatan dan produk yang tidak dikenakan tindakan campur tangan produk. Sila lawati laman web triomarkets.eu entiti yang dikawal selia oleh EU kami. Dengan meneruskan, anda bersetuju untuk membuka akaun dengan <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Terma dan Syarat</a>) dan anda mengesahkan bahawa ini adalah keputusan berdasarkan budi bicara anda sendiri dan mutlak tanpa sebarang permintaan dan/atau cadangan dari pihak kami.`,
    goTo: "Pergi ke",
    cookies: "Laman web ini menggunakan kuki.",
  },
};
